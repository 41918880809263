import { prosemirrorJSONToYDoc } from '@gamma-app/y-prosemirror'
import { getSchema, JSONContent } from '@tiptap/core'
import { fromUint8Array } from 'js-base64'
import { encodeStateAsUpdate } from 'yjs'

import { getBaseExtensions } from '../EditorCore'

const deleteCardId = (node: JSONContent) => {
  if (node.type === 'card' && node.attrs) {
    delete node.attrs.id
  }
}

/**
 * Prune the card IDs from the provided JSON content
 */
export const pruneCardIds = (root: JSONContent) => {
  const remapContent = (node: JSONContent) =>
    node.content?.map((child: JSONContent) => {
      if (!child.content) return child
      const newChild = { ...child, attrs: { ...child.attrs } }
      deleteCardId(newChild)
      newChild.content = remapContent(newChild)
      return newChild
    })
  deleteCardId(root)
  return Object.assign({}, root, { content: remapContent(root) })
}

/**
 * Takes a JSON object, converts it to a prosemirror YDoc using
 * the EditorCore's schema, and returns a base64 encoded
 * version of it that we can use as a snapshot.
 */
export const jsonToYDocSnapshot = (content: JSONContent): string => {
  // Convert the JSON to a YDoc that adhere's to our schema.
  // IMPORTANT: The 'default' YDoc field here is specified by the Collaboration Extension:
  // https://github.com/ueberdosis/tiptap/blob/122f0821aaa020777e3ed9731af1aa9a75d524ed/packages/extension-collaboration/src/collaboration.ts#L46
  // The y-prosemirror default value is 'prosemirror'
  const baseExtensions = getBaseExtensions()
  const schema = getSchema(baseExtensions)
  const ydoc = prosemirrorJSONToYDoc(schema, content, 'default')
  const documentState = encodeStateAsUpdate(ydoc) // is a Uint8Array

  // Transform Uint8Array to a Base64-String
  return fromUint8Array(documentState)
}
