import { Button, Text } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from '@lingui/macro'
import { NodeViewProps } from '@tiptap/react'
import { useCallback } from 'react'

import { useAppSelector } from 'modules/redux'
import { HEADING_CLASS } from 'modules/theming/styles/heading'
import {
  selectEditable,
  selectIsStatic,
  selectToggleExpanded,
} from 'modules/tiptap_editor/reducer'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { NodeViewContent } from '../../react'
import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { isHeadingStyle } from '../Font/utils'
import { LEFT_PADDING } from './constants'
import { findToggleDecoration } from './TogglePlugin'
import { setToggleOpen } from './utils'
export const ToggleSummaryView = (nodeViewProps: NodeViewProps) => {
  const { node, decorations } = nodeViewProps
  const { level, fontSize } = node.attrs
  const { toggleId = '', isEmpty } = findToggleDecoration(decorations)

  const isEditable = useAppSelector(selectEditable)
  const isStatic = useAppSelector(selectIsStatic)
  const isOpen = useAppSelector(selectToggleExpanded(toggleId)) || isStatic

  const toggleOpen = useCallback(() => {
    setToggleOpen(toggleId, !isOpen)
  }, [toggleId, isOpen])

  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps} hideComments={!isOpen}>
      <Text
        as="div"
        position="relative"
        onClick={isEditable ? undefined : toggleOpen}
        cursor={isEditable ? undefined : 'pointer'}
      >
        <Button
          left={`calc(0.125em - ${LEFT_PADDING})`} // Ensures focus/highlight ring has some padding
          position="absolute"
          variant="ghost"
          colorScheme="gray"
          color={isEmpty ? 'gray.400' : undefined}
          onClick={isEditable ? toggleOpen : undefined}
          contentEditable={false}
          onMouseDown={preventDefaultToAvoidBlur}
          // Align with text
          fontSize="1em"
          lineHeight="inherit"
          height="100%" // Ensures the toggle is vertically centered on the line
          // Square
          px={2}
          ml={-2}
          minW="unset"
          data-testid="toggle-button"
        >
          <Text
            transform={`rotate(${isOpen ? 90 : 0}deg)`}
            transformOrigin="center"
            transitionProperty="transform"
            transitionDuration="normal"
            fontSize="0.75em"
          >
            <FontAwesomeIcon icon={solid('play')} />
          </Text>
        </Button>
        <NodeViewContent
          level={level}
          placeholder={t`Untitled`}
          data-font-size={fontSize}
          className={cx(isHeadingStyle(fontSize) && HEADING_CLASS)}
        ></NodeViewContent>
      </Text>
    </AnnotatableNodeViewWrapper>
  )
}
