import { mergeAttributes, Node } from '@tiptap/core'

import { getImageTextDescription } from 'modules/media/utils/imageResultUtils'
import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'
import {
  BackgroundOptions,
  BackgroundType,
} from 'modules/tiptap_editor/styles/types'
import { configureJSONAttribute } from 'modules/tiptap_editor/utils'

import {
  addBookmarkStorage,
  getAttrsFromBookmark,
  bookmarkNode,
} from '../../../AI/Sal/utils/bookmark'
import { attrsOrDecorationsChanged } from '../../../updateFns'
import { CardLayoutItemView } from '../CardLayoutItemView'

export const CardAccentLayoutItem = Node.create({
  name: 'cardAccentLayoutItem',
  group: 'cardLayoutItemGroup',
  selectable: false,
  atom: true,
  draggable: false,
  imageKeyPath: 'background.image',

  addAttributes() {
    return {
      itemId: {
        default: 'accent',
      },
      background: {
        default: {
          type: BackgroundType.NONE,
        },
        ...configureJSONAttribute('background'),
      },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(CardLayoutItemView, {
      update: attrsOrDecorationsChanged,
    })
  },

  addStorage: addBookmarkStorage,

  parseHTML() {
    return [
      // From AI
      // In chat, we use a bookmark to represent the place an image goes without actually
      // sending the image metadata over the wire.
      {
        tag: 'div[class=card-accent-layout-item][bookmark]',
        getAttrs: getAttrsFromBookmark(this.storage),
      },
      // Regular
      {
        tag: 'div[class=card-accent-layout-item]',
      },
    ]
  },

  renderHTMLforAI({ node }) {
    const bookmark = bookmarkNode(this.storage, node)
    const background = node.attrs.background as BackgroundOptions
    const alt = background.image && getImageTextDescription(background.image)
    return ['div', { bookmark, class: 'card-accent-layout-item', alt }]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, { class: 'card-accent-layout-item' }),
    ]
  },
})
