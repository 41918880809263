import { Flex, Heading, Stack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { NodeViewProps } from '@tiptap/core'
import { useEffect } from 'react'

import { User } from 'modules/api'

import { BlockReaction } from '../../types'
import {
  REACTION_COUNT_TO_SHOW,
  useDomNodeOffsetFromTop,
} from '../BlockCommentsButtons/hooks'
import { ReactionButton } from '../BlockCommentsButtons/ReactionButton'
import { BaseCommentPopup } from './BaseCommentPopup'

type ReactionsOverflowPopupProps = {
  onCancel: () => void
  reactions: BlockReaction[]
  onClickExistingReaction: (blockReaction: BlockReaction) => void
  editor: NodeViewProps['editor']
  getPos: NodeViewProps['getPos']
  user?: User
  userCanComment: boolean
  blockAllowsCommenting: boolean
  isMobile: boolean
}
export const ReactionsOverflowPopup: React.FC<ReactionsOverflowPopupProps> = ({
  onCancel,
  reactions,
  onClickExistingReaction,
  editor,
  getPos,
  user,
  userCanComment,
  blockAllowsCommenting,
  isMobile,
}) => {
  const offsetFromTop = useDomNodeOffsetFromTop({
    editor,
    getPos,
  })

  // we dont know the position, so wait until useLayoutEffect before rendering
  const overflowReactions = reactions.slice(
    isMobile ? 0 : REACTION_COUNT_TO_SHOW
  )
  const reactionButtons = overflowReactions.map((reaction) => {
    return (
      <ReactionButton
        key={reaction.emoji}
        isMobile={true}
        onClick={(ev) => {
          onClickExistingReaction(reaction)
          ev.stopPropagation()
        }}
        mr={2}
        mb={4}
        reaction={reaction}
        user={user}
        canReact={userCanComment && blockAllowsCommenting}
        badgeCount={reaction.count}
        emoji={reaction.emoji}
      />
    )
  })

  useEffect(() => {
    if (overflowReactions.length === 0) {
      onCancel()
    }
  }, [onCancel, overflowReactions])

  if (offsetFromTop === null) {
    return null
  }

  const inner = (
    <Stack spacing={4}>
      <Heading size="md">
        <Trans comment="This refers to reactions to comments. They are emoji buttons.">
          Reactions:
        </Trans>
      </Heading>
      <Flex flexWrap="wrap">{reactionButtons}</Flex>
    </Stack>
  )
  if (isMobile) {
    return inner
  }

  return (
    <BaseCommentPopup offsetFromTop={offsetFromTop}>
      <Flex p={4} px={2} pb={0} flexWrap="wrap">
        {reactionButtons}
      </Flex>
    </BaseCommentPopup>
  )
}
