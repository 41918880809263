import { Center, CenterProps, TooltipProps } from '@chakra-ui/react'
import { GammaTooltip } from '@gamma-app/ui'
import React from 'react'

import { useGetColorsForDragHandle } from './useGetColorsForDragHandle'

export type BaseDragHandleProps = {
  icon?: string | JSX.Element
  isVisible?: boolean
  isSelected?: boolean
  label?: TooltipProps['label']
} & CenterProps

export const BaseDragHandle: React.FC<BaseDragHandleProps> = ({
  icon = '⋮',
  isVisible,
  isSelected,
  label,
  onClick,
  onDragStart,
  ...centerProps
}) => {
  const { color, bg, hoverColor, hoverBg, borderColor } =
    useGetColorsForDragHandle(isSelected)

  return (
    <GammaTooltip placement="top" label={isSelected ? undefined : label}>
      <Center
        position="absolute"
        // default width+height
        width="16px"
        height="22px"
        // Styles should match GlobalDragHandle styles in globals.scss
        fontWeight="900"
        fontFamily="Inter, sans-serif"
        background={bg}
        backdropFilter="blur(8px)"
        border="1px solid"
        borderColor={borderColor}
        color={color}
        borderRadius="sm"
        boxShadow="sm"
        fontSize="16px"
        cursor="pointer"
        // Hidden unless `isSelected` is true, you hover directly, or you hover a `drag-handle-hover` parent. See editorStyles.ts
        opacity={isSelected || isVisible ? 1 : 0}
        _hover={{
          color: hoverColor,
          background: hoverBg,
          opacity: 1,
        }}
        {...centerProps}
        className="drag-handle"
        // Handle events
        onClick={onClick}
        onDragStart={onDragStart}
        draggable={true}
        contentEditable={false}
        // Larger hover target
        _after={{
          position: 'absolute',
          content: '""',
          inset: -2,
        }}
      >
        {icon}
      </Center>
    </GammaTooltip>
  )
}
