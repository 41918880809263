import { Editor } from '@tiptap/core'
import { Node } from 'prosemirror-model'

import { findTopCardNodeParent } from '../cardNavigationUtils'

export const addCardNotes = (editor: Editor, cardPos: number) => {
  const node = editor.state.doc.nodeAt(cardPos)

  if (!node || node.lastChild?.type.name === 'cardNotes') return

  const insertPos = cardPos + node.nodeSize - 1

  editor?.commands.insertContentAt(
    { from: insertPos, to: insertPos },
    { type: 'cardNotes', content: [{ type: 'paragraph' }] },
    { updateSelection: false }
  )
}

export const hasCardNotes = (node: Node) =>
  node.lastChild?.type.name === 'cardNotes'

/**
 * Checks if the current editor selection is within the topmost parent card of a given position.
 */
export const isSelectionWithinTopCardAtPos = (editor: Editor, pos: number) => {
  const parentCard = findTopCardNodeParent(editor, pos)

  if (!parentCard || !parentCard.pos) {
    return false
  }
  const $parentCard = editor.state.doc.resolve(parentCard.pos)
  const start = $parentCard.pos
  const end = start + parentCard.node.nodeSize

  const selection = editor.state.selection
  return selection.from >= start && selection.to <= end
}

export const isCardNotesEditor = (editor: Editor) =>
  editor.schema.topNodeType.name == 'cardNotes'
