import { Editor } from '@tiptap/core'
import { Attrs, MarkType, Node, NodeType } from 'prosemirror-model'
import { NodeSelection, Selection, TextSelection } from 'prosemirror-state'
import { useCallback } from 'react'

import { ImageType } from 'modules/media/apis/transloadit'
import { getStore, useAppStore } from 'modules/redux'
import { UpdateNodeAttrsAnnotationEvent } from 'modules/tiptap_editor/extensions/Annotatable/AnnotationExtension/types'
import {
  isMediaNodeType,
  resizeImageToFitViewport,
} from 'modules/tiptap_editor/extensions/media/utils'
import { isSmartLayoutNode } from 'modules/tiptap_editor/extensions/SmartLayout/isSmartLayoutCellNode'
import {
  findExtensionFromNodeType,
  fragmentToArray,
} from 'modules/tiptap_editor/utils'

import { MediaSourcesMap } from '../../../extensions/media/MediaSources'
import { setIsEditingMedia } from '../../../reducer'

type SelectedMedia = {
  attrs: Record<string, any>
  type: NodeType | MarkType
  editType?: ImageType // Only used for images, to determine how CustomImage replaces content, and how Transloadit resizes
  mediaType?: 'image' | 'embed'
  accesoryKeyPath?: string
}

export const getSelectedMedia = (
  editor: Editor,
  selection: Selection,
  freshNode?: Node
): SelectedMedia | null => {
  if (selection instanceof NodeSelection) {
    const node = freshNode ?? selection.node
    const extension = findExtensionFromNodeType(editor, node.type)

    if (extension && extension.config.accessoryImageKey) {
      const imgKey = extension.config.accessoryImageKey
      const attrs = node.attrs[imgKey] ?? {}
      // If there's no source set on this cell, default to the source from other cells
      if (!attrs.source && isSmartLayoutNode(selection.$from.parent)) {
        const otherCellAttrs = fragmentToArray(
          selection.$from.parent.content
        ).find((n) => n.attrs[imgKey]?.source)
        attrs.source = otherCellAttrs?.attrs[imgKey]?.source
      }

      return {
        attrs,
        type: node.type,
        editType: 'accessory',
        mediaType: 'image',
        accesoryKeyPath: imgKey,
      }
    }
    return {
      editType: 'node',
      ...node,
    }
  }

  if (
    selection instanceof TextSelection &&
    selection.to - selection.from === 1 &&
    selection.$from.nodeAfter
  ) {
    // Sometimes ProseMirror breaks a NodeSelection and it turns into a TextSelection over the same range. We should still detect those as selected media nodes
    const node = freshNode ?? selection.$from.nodeAfter
    return { editType: 'node', ...node }
  } else if (selection instanceof TextSelection && editor.isActive('link')) {
    return {
      attrs: editor.getAttributes('link'),
      type: editor.schema.marks.link,
      mediaType: 'embed',
    }
  }
  return null
}

type UpdateSelectedMediaArgs = {
  editor: Editor
  selection: Selection
  sourceKey: string
  attrs: Record<string, any>
  nodeName?: string
  resetAttrs?: boolean
  resizeToFit?: boolean
}

// Updates the attributes of the node or mark
// and changes the node type (eg Embed vs. Video vs. Image) if specified or required by the attrs
export const updateSelectedMedia = ({
  editor,
  selection,
  sourceKey,
  attrs,
  nodeName,
  resetAttrs,
  resizeToFit,
}: UpdateSelectedMediaArgs) => {
  const { from: pos } = selection
  const selectedMedia = getSelectedMedia(editor, selection)

  let baseAttrs: Attrs
  if (!selectedMedia) {
    console.error('[updateSelectedMedia] No selectedMedia to update')
    return
  } else if (resetAttrs) {
    baseAttrs = {}
  } else if (selectedMedia.type instanceof MarkType) {
    // Todo: implement refetching mark attrs
    baseAttrs = selectedMedia.attrs
  } else {
    // The node may have changed or moved since the selection was made,
    // so we refetch it
    // Todo: handle the case where the node moves during this time
    const freshNode = editor.state.doc.nodeAt(pos)
    if (!freshNode || freshNode.type !== selectedMedia.type) {
      console.error('[updateSelectedMedia] Node has moved, cant update')
      return
    }
    baseAttrs = getSelectedMedia(editor, selection, freshNode)?.attrs || {}
  }

  // We need the node's current attrs because `setNodeMarkup` doesn't merge them
  let newAttrs: Record<string, any> = {
    ...baseAttrs, // Start from the old attributes
    source: sourceKey, // Fill in the source if it's not provided
    ...attrs, // Provided attrs take precedence
  }

  if (resizeToFit && selectedMedia.editType === 'node') {
    newAttrs = resizeImageToFitViewport(newAttrs)
  }

  if (selectedMedia?.type.name === 'link') {
    // Links use href, but iframely and media providers assume everything is sourceUrl,
    // so this maps it only for links.
    newAttrs.href = newAttrs.href || newAttrs.sourceUrl
    const { from, to } = selection
    editor
      .chain()
      .extendMarkRange('link')
      .updateAttributes('link', newAttrs)
      .setTextSelection({ from, to })
      .run()
    return
  } else if (selectedMedia.accesoryKeyPath) {
    editor
      .chain()
      .updateAttributesAtPos(pos, { [selectedMedia.accesoryKeyPath]: newAttrs })
      .run()
    return
  }

  // Update the node type to match these attrs, if a node name hasn't been specified
  const newSource = MediaSourcesMap[newAttrs.source]
  const newNodeName = nodeName || newSource.nodeName
  if (!newNodeName) return

  editor.commands.command(({ tr, state }) => {
    const newNode = state.schema.nodes[newNodeName]
    if (!newNode) return false
    // since we are replacing all markup here move any annotation
    // on the media to it's new RelativePosition
    const payload: UpdateNodeAttrsAnnotationEvent = {
      type: 'update-node-attrs',
      pos: pos,
    }
    tr.setNodeMarkup(pos, newNode, newAttrs)
      .setSelection(NodeSelection.create(tr.doc, pos))
      .setMeta('annotationEvent', payload)

    return true
  })
}

export const canEditInMediaDrawer = (
  editor: Editor,
  type: NodeType | MarkType
) => {
  const extension = findExtensionFromNodeType(editor, type)
  if (extension && extension.config.accessoryImageKey) {
    return true
  } else if (type instanceof NodeType) {
    return isMediaNodeType(type)
  } else if (type instanceof MarkType) {
    return type.name === 'link'
  } else {
    return false
  }
}

export const openMediaDrawer = () => {
  const store = getStore()
  if (!store) {
    console.error(
      `[MediaDrawer].openMediaDrawer: can't open because getStore() returned ${store}`
    )
    return
  }
  store.dispatch(setIsEditingMedia({ isEditingMedia: true }))
}

export const useToggleMediaDrawer = () => {
  const store = useAppStore()
  return useCallback(
    (isEditingMedia: boolean) => {
      store.dispatch(setIsEditingMedia({ isEditingMedia }))
    },
    [store]
  )
}
