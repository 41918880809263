import { ChatCompletionInput, ChatMessage } from 'modules/api'
import { featureFlags } from 'modules/featureFlags/FeatureFlagProvider'

import {
  APIOptions,
  fetchChatCompletion,
  ChatCompletionParams,
} from '../openai'

export type ChatCompletionPromptGenerateOptions<T extends string = string> = {
  variables: Record<T, any>
  history?: ChatMessage[]
  params?: ChatCompletionParams
  interactionId?: string
}

// TODO(jordan): should this just generate ChatCompletionInput
export type ChatCompletionGenerateResult = {
  messages: ChatCompletionInput['messages']
  params: NonNullable<ChatCompletionInput['params']>
}

export interface ChatCompletionPrompt<T extends string = string> {
  prepare(
    options: ChatCompletionPromptGenerateOptions<T>
  ): ChatCompletionGenerateResult
}

// TODO consolidate this with fetchChatCompletion
export const runChatCompletionPrompt = async (
  input: ChatCompletionGenerateResult,
  interactionId: string,
  options: APIOptions = {}
): Promise<string | undefined> => {
  const retries = featureFlags.get('aiRequestRetries').chat
  options.retries = options.retries ?? retries
  const result = await fetchChatCompletion(
    {
      ...input,
      interactionId,
    },
    options
  )
  return result?.chatCompletion?.[0].content
}
