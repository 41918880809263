import { Flex } from '@chakra-ui/react'
import { DarkModeProvider } from '@gamma-app/ui'
import { Editor } from '@tiptap/core'
import { memo } from 'react'

import { useIsThemeDark } from 'modules/theming/hooks'

import { useInsertWidgetVisible } from './hooks'
import { InsertWidgetInner } from './InsertWidgetInner'

const InsertWidgetComponent = ({ editor }: { editor: Editor }) => {
  const isDark = useIsThemeDark()
  const isVisible = useInsertWidgetVisible()
  if (!isVisible) return null

  return (
    <DarkModeProvider isDark={isDark}>
      <Flex
        position="fixed"
        w="0%"
        h="0%"
        right={6}
        top="50%"
        alignItems="center"
        // Over the memo contents and drag handle, but under other popovers like the comments list
        // Note that the flyout InsertWidgetButtons are at a higher zIndex via a portal
        zIndex="sticky"
        data-testid="insert-widget-wrapper"
      >
        <Flex
          position="absolute"
          right={0}
          data-guider-highlight="insert-widget"
          direction="column"
          className="insert-widget"
        >
          <InsertWidgetInner editor={editor} />
        </Flex>
      </Flex>
    </DarkModeProvider>
  )
}

export const InsertWidget = memo(InsertWidgetComponent)
