import { callOrReturn, getExtensionField, mergeAttributes } from '@tiptap/core'
import ImageNode from '@tiptap/extension-image'
import { NodeSelection } from 'prosemirror-state'

import { AI_PARSED_ATTRIBUTE } from 'modules/ai/transform/constants'
import { getImageTextDescription } from 'modules/media/utils/imageResultUtils'
import { getStore } from 'modules/redux'
import { configureIdAttribute } from 'modules/tiptap_editor/plugins/uniqueAttribute/configureIdAttribute'
import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'

import { configureJSONAttribute } from '../../../utils'
import {
  addBookmarkStorage,
  bookmarkNode,
  getAttrsFromBookmark,
} from '../../AI/Sal/utils/bookmark'
import { ExtensionPriorityMap } from '../../constants'
import { attrsOrDecorationsChanged } from '../../updateFns'
import { generateMediaId } from '../UniqueMediaId'
import { eventEmitter } from './eventEmitter'
import { ImageViewContainer } from './ImageViewContainer'
import { selectIsCropping } from './reducer'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    imageCommands: {
      /**
       * Sets the image clipping back to default
       */
      resetImageClip: () => ReturnType
      /**
       * Sets the image scale back to default
       */
      resetImageScale: () => ReturnType
    }
  }
}

export const DEFAULT_RESIZE_STATE = {
  clipType: null,
  clipPath: null,
  clipAspectRatio: null,
  width: null,
} as const

export const Image = ImageNode.extend({
  selectable: true,
  draggable: true,
  group: 'block media',
  priority: ExtensionPriorityMap.Image,

  expandable: true,

  addOptions() {
    return {
      ...this.parent?.(), // Extend parent options
      placeholders: {},
    }
  },

  addStorage: addBookmarkStorage,

  addNodeView() {
    return ReactNodeViewRenderer(ImageViewContainer, {
      update: attrsOrDecorationsChanged,
    })
  },

  extendNodeSchema(extension) {
    return {
      accessoryImageKey: callOrReturn(
        getExtensionField(extension, 'accessoryImageKey', extension)
      ),
      imageKeyPath:
        callOrReturn(getExtensionField(extension, 'imageKeyPath', extension)) ??
        '',
    }
  },

  parseHTML() {
    return [
      // From clipboard
      {
        tag: 'img[src], img[tempUrl]',
      },
      // From AI
      // In chat, we use a bookmark to represent the place an image goes without actually
      // sending the image metadata over the wire.
      {
        tag: 'img[bookmark]',
        getAttrs: getAttrsFromBookmark(this.storage),
      },
      // In the docWizard, we get image search queries, and then run them in
      // transformHtmlFromAI to get the actual image src.
      {
        tag: `img[${AI_PARSED_ATTRIBUTE}]`,
        getAttrs: (el: HTMLElement) => {
          try {
            const aiTransformedAttrs = el.getAttribute(AI_PARSED_ATTRIBUTE)
            const attrs = JSON.parse(aiTransformedAttrs || '')
            return attrs
          } catch (err) {
            console.error(
              `[Image] Error parsing ${AI_PARSED_ATTRIBUTE} attrs`,
              el,
              err
            )
            return false
          }
        },
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'img',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        loading: 'lazy',
      }),
    ]
  },
  renderHTMLforAI({ node }) {
    const bookmark = bookmarkNode(this.storage, node)
    const alt = getImageTextDescription(node.attrs)
    return ['img', { bookmark, alt }]
  },

  addAttributes() {
    return {
      id: configureIdAttribute(generateMediaId),
      savedMediaId: {},
      src: {},
      tempUrl: {},
      uploadStatus: {},
      meta: {
        ...configureJSONAttribute('meta'),
      },
      providerMeta: {
        ...configureJSONAttribute('providerMeta'),
      },
      aiParams: {
        ...configureJSONAttribute('aiParams'),
      },
      loadImageParams: {
        ...configureJSONAttribute('aiParams'),
      },
      loadImageStatus: {},
      loadImageId: {},
      name: {},
      query: {},
      source: {},
      showPlaceholder: {},
      fullWidthBlock: {
        default: false,
      },
      resize: {
        ...configureJSONAttribute('resize'),
        default: DEFAULT_RESIZE_STATE,
      },
    }
  },

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        const selection = editor.state.selection
        if (
          selection instanceof NodeSelection &&
          selection.node.type.name === ImageNode.name
        ) {
          const isCropping = selectIsCropping(getStore().getState())
          const { id } = selection.node.attrs
          if (isCropping) {
            eventEmitter.emit('endClip', { id, confirm: true })
            return true
          }
        }
        return false
      },
    }
  },

  addCommands() {
    const nodeName = this.name
    const getSelectedImage = (state) => {
      const { selection } = state
      return selection instanceof NodeSelection &&
        selection.node.type.name === nodeName
        ? selection.node
        : undefined
    }
    return {
      resetImageClip:
        () =>
        ({ chain, state }) => {
          const selectedNode = getSelectedImage(state)
          if (!selectedNode) {
            console.warn(
              '[resetImageClip] Selected node is not an image: ',
              state.selection
            )
            return true
          }

          const { clipPath, clipType, clipAspectRatio } = DEFAULT_RESIZE_STATE
          return chain()
            .updateAttributes(nodeName, {
              resize: {
                ...selectedNode.attrs.resize,
                clipPath,
                clipType,
                clipAspectRatio,
              },
            })
            .run()
        },
      resetImageScale:
        () =>
        ({ chain, state }) => {
          const selectedNode = getSelectedImage(state)
          if (!selectedNode) {
            console.warn(
              '[resetImageClip] Selected node is not an image: ',
              state.selection
            )
            return true
          }

          return chain()
            .updateAttributes(nodeName, {
              resize: {
                ...selectedNode.attrs.resize,
                width: null,
                isAuto: null,
              },
            })
            .run()
        },
    }
  },
})
