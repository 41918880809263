import { Box, Center, Flex, FlexProps } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Trans } from '@lingui/macro'
import { NodeViewProps } from '@tiptap/core'
import { useCallback } from 'react'
import SVG from 'react-inlinesvg'

import { ImageAttrs } from 'modules/media/types/Image'
import { UploadStatus } from 'modules/media/types/ImageUpload'
import { useAppSelector } from 'modules/redux'
import { openMediaDrawer } from 'modules/tiptap_editor/components/drawers/MediaDrawer/utils'
import { selectEditable } from 'modules/tiptap_editor/reducer'
import { preventDefaultToAvoidBlur } from 'utils/handlers'
import {
  backgroundImageFromUrls,
  isGammaCDNUrl,
  resizeAndProxyImageUrl,
  THUMBNAIL_RESIZE_PARAMS,
} from 'utils/image'

import { getBackgroundPosFromBackgroundImageAttrs } from '../../../styles/backgroundStyles'
import { ImageShape } from '../../SmartLayout/options/types'
import {
  MediaPlaceholderErrorUploadingTag,
  MediaPlaceholderSpinner,
} from '../Placeholder'
import { MediaPlaceholderImage } from '../Placeholder/MediaPlaceholderImage'
import { isSVG } from '../utils'

type AccessoryImageProps = {
  image: ImageAttrs | null
  imageShape?: ImageShape
  imageSx?: FlexProps
} & NodeViewProps

// Renders an image as an "accessory" to another block, stored on that block's `image' attr
// Distinct from `ImageView`, which is used for the image node type

export const AccessoryImage = ({
  image,
  editor,
  getPos,
  imageShape,
  imageSx,
}: AccessoryImageProps) => {
  const editImage = useCallback(() => {
    editor.commands.selectNodeAtPos(getPos(), false)
    openMediaDrawer()
  }, [editor, getPos])

  const isImageSVG = isSVG(image?.src || image?.tempUrl)

  const showPlaceholder =
    !image || image.showPlaceholder || (!image.src && !image.tempUrl)
  const loadingProvider =
    (image && image.loadImageParams?.provider) ?? undefined

  // dont show loading indicator in samll editors
  const isLoading =
    image && image.loadImageStatus === 'queued' && !editor.isThumbnail

  const { src, tempUrl, uploadStatus, meta } = image || {}
  const isEditable = useAppSelector(selectEditable)

  const resizedSrc =
    src &&
    resizeAndProxyImageUrl(
      src,
      editor.isThumbnail
        ? THUMBNAIL_RESIZE_PARAMS
        : { width: 1000, height: 1000 },
      meta
    )

  const needsFallback = src && !isGammaCDNUrl(src)
  const backgroundImage = backgroundImageFromUrls(
    resizedSrc,
    // For external URLs, resizing wont always work, so load the original as a fallback
    needsFallback ? src : undefined,
    tempUrl
  )
  const backgroundPosition = getBackgroundPosFromBackgroundImageAttrs(image)

  const imageStyles = {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: isImageSVG ? undefined : backgroundImage,
    backgroundPosition,
    width: '100%',
    height: '100%',
    borderRadius:
      imageShape === 'circle' && !isImageSVG
        ? 'full'
        : 'var(--box-border-radius)',
    transitionProperty: 'border-radius',
    transitionDuration: 'normal',
    ...imageSx,
  }

  return (
    <Box position="relative" cursor="default" contentEditable={false} h="100%">
      {isEditable && (
        <GammaTooltip placement="top" label={<Trans>Edit image</Trans>}>
          <Center
            position="absolute"
            inset="0"
            color="white"
            backgroundColor="blackAlpha.400"
            opacity="0"
            transitionProperty="opacity, border-radius"
            transitionDuration="fast"
            _hover={{ opacity: 0.8 }}
            borderRadius={imageStyles.borderRadius}
            cursor="pointer"
            onClick={editImage}
            onMouseDown={preventDefaultToAvoidBlur}
            zIndex="1"
          >
            {!showPlaceholder && (
              <FontAwesomeIcon icon={regular('image')} size="2x" />
            )}
          </Center>
        </GammaTooltip>
      )}
      {showPlaceholder ? (
        <MediaPlaceholderImage
          {...imageStyles}
          isLoading={!!isLoading}
          loadingProvider={loadingProvider}
          icon={regular('image')}
          iconSize="1x"
        />
      ) : isImageSVG ? (
        <Center
          {...imageStyles}
          objectFit="contain"
          color="var(--icon-color)"
          sx={{
            svg: {
              width: '100%',
              height: '100%',
            },
            aspectRatio: '1 / 1',
          }}
        >
          <SVG src={src || tempUrl || ''} />
        </Center>
      ) : (
        <Flex
          data-content-reference
          data-change-focus-point-el
          className="card-layout-cell-bg"
          position="relative"
          sx={imageStyles}
        />
      )}
      {/* Upload status indicators */}
      {uploadStatus == UploadStatus.Uploading && isEditable && (
        <MediaPlaceholderSpinner />
      )}
      {uploadStatus == UploadStatus.Error && (
        <MediaPlaceholderErrorUploadingTag />
      )}
    </Box>
  )
}
