import {
  Editor,
  ExtendedRegExpMatchArray,
  InputRule,
  InputRuleMatch,
  mergeAttributes,
  Node,
  nodeInputRule,
} from '@tiptap/core'
import { PluginKey } from 'prosemirror-state'
import { forwardRef, MutableRefObject } from 'react'

import {
  EmojiObject,
  getEmojiObjectFromId,
  getIdFromNative,
  getNativeFromEmojiObject,
  isEmojiDataLoaded,
} from 'modules/emoji'
import { nodePasteRule } from 'modules/tiptap_editor/utils/nodePasteRule'

import { ExtensionPriorityMap } from '../constants'
import { MigrateFunction } from '../Migrate'
import { createSuggestionExtension } from '../Suggestion'
import { activateSuggestion } from '../Suggestion/suggestionHelpers'
import { EmojiDropdown } from './EmojiDropdown'
import { EMOJI_REGEX } from './regex'
import { EmojiNodeAttrs } from './types'
import { handleReplaceNativeEmojis } from './utils'
const inputRegex = /:([a-zA-Z0-9_+-]+):$/

// See https://next.tiptap.dev/api/nodes/emoji
// and https://www.tiptap.dev/api/utilities/suggestion/#suggestion
// and ./SlashMenu.ts

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    emoji: {
      openEmojiPicker: (insertChar?: boolean) => ReturnType
    }
  }
}

// This regex pattern is used for the input rule (only) and needs the $ to match just what's typed
const EMOJI_REGEX_FOR_INPUT = new RegExp(EMOJI_REGEX.source + '$', 'g')

const EmojiMenuKey = new PluginKey('EmojiMenu')

const TRIGGER_CHARACTER = ':'

const getAttributesNative = (
  match: ExtendedRegExpMatchArray
): EmojiNodeAttrs => {
  const id = getIdFromNative(match[0])
  return {
    id,
    native: match[0],
  }
}

const getAttributesString = (emojiObject: EmojiObject): EmojiNodeAttrs => {
  const native = getNativeFromEmojiObject(emojiObject)
  return {
    id: emojiObject.id,
    native,
  }
}

export const EmojiNode = Node.create({
  name: 'emoji',
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      id: {},
      native: {},
    }
  },

  parseHTML() {
    return [
      {
        tag: 'span.emoji:not([id])',
        // Ignore the node that matches this rule, but do parse its content.
        // This is required in case people paste an emoji from a third-party website
        // that has our "emoji" class but no id or native attributes.
        // <h1><span class="emoji">❤️</span> Red heart</h1>
        skip: true,
      },
      {
        tag: 'span[class=emoji]',
      },
    ]
  },

  addCommands() {
    return {
      openEmojiPicker:
        (insertChar = true) =>
        ({ commands, tr, editor }) => {
          activateSuggestion(editor, tr)(EmojiMenuKey, TRIGGER_CHARACTER)
          if (insertChar) {
            return commands.insertContent(TRIGGER_CHARACTER)
          }
          return true
        },
    }
  },

  addInputRules() {
    return [
      /**
       * ID/KEYWORD-BASED INPUT RULE: Based on emoji ID, i.e. `:<emojiId>:`
       * Example input: `:apple:`
       * This input rule comes directly from @tiptap-pro/extension-emoji
       */
      new InputRule({
        find: inputRegex,
        handler: ({ range, match, commands }) => {
          const name = match[1]
          const emojiObject = getEmojiObjectFromId(name)
          if (!emojiObject) {
            return
          }
          const attrs = getAttributesString(emojiObject)
          commands.insertContentAt(range, {
            type: 'emoji',
            attrs,
          })
        },
      }),
      /**
       * NATIVE INPUT RULE: Based on native emoji, entered via OS keyboard
       * Example input: `🍎`
       */
      nodeInputRule({
        find: (text: string) => {
          const matches = [...text.matchAll(EMOJI_REGEX_FOR_INPUT)]
          if (!matches || matches.length === 0) {
            return null
          }

          const firstRes = matches[0]
          const result: InputRuleMatch = {
            index: firstRes.index ?? -1,
            text: firstRes[0],
            match: firstRes,
          }

          console.debug('[EmojiNode] JAMES nodeInputRule', { matches, result })

          return result
        },
        type: this.type,
        getAttributes: getAttributesNative,
      }),
    ]
  },

  addPasteRules() {
    return [
      nodePasteRule({
        find: EMOJI_REGEX,
        type: this.type,
        getAttributes: getAttributesNative,
      }),
    ]
  },

  renderHTML({ HTMLAttributes, node }) {
    return [
      'span',
      mergeAttributes(HTMLAttributes, { class: 'emoji' }),
      `${node.attrs.native || '�'}`,
    ]
  },

  renderHTMLforAI({ node }) {
    return node.attrs.native || ''
  },

  renderText({ node }) {
    return `${node.attrs.native || '�'}`
  },
})

interface EmojiMenuProps {
  editor: Editor
  query: string
}

const EmojiMenu = (
  { query, editor }: EmojiMenuProps,
  ref: MutableRefObject<any>
) => {
  if (!editor.isEditable) return null // Don't render the emoji menu at all if it's a read only editor
  return (
    <EmojiDropdown
      ref={ref}
      query={query}
      onSelect={(emojiObject: EmojiObject) => {
        const { id } = emojiObject
        const native = getNativeFromEmojiObject(emojiObject)
        const selection = editor.state.selection
        const emojiNode = {
          type: 'emoji',
          attrs: {
            id,
            native,
          },
        }
        editor
          .chain()
          .deleteRange({
            from: selection.from - query.length - 1, // -1 for the slash
            to: selection.to,
          })
          // As of April 2022, we'll insert a Node of type emoji rather than the native emoji.
          // This will help us with:
          //   - The URI Malformed error.
          //   - Support custom emoji. See https://linear.app/gamma-app/issue/G-947/support-custom-emoji
          //   - Emoji display in PDFs
          //   - G-425/emojis-in-headings-on-the-gamma-memo-theme
          //   - G-1248/inline-emojis-are-too-big-and-bumping-up-against-adjacent-text
          .insertContent(emojiNode)
          .insertContent(' ')
          .run()
      }}
    />
  )
}

export const EmojiShortcuts = createSuggestionExtension({
  name: 'emojiShortcuts',
  char: TRIGGER_CHARACTER,
  pluginKey: EmojiMenuKey,
  MenuComponent: forwardRef(EmojiMenu),
  priority: ExtensionPriorityMap.EmojiShortcuts,
})

export const migrateNativeEmojis: MigrateFunction = ({
  node,
  pos,
  tr,
  schema,
}): boolean => {
  if (!isEmojiDataLoaded()) return false
  if (!node.isText || !node.text) return true
  const marks = node.marks.map((mark) => mark.toJSON())
  const { replaced, nodes } = handleReplaceNativeEmojis(node.text, marks)

  try {
    if (replaced) {
      tr.replaceWith(
        tr.mapping.map(pos),
        tr.mapping.map(pos + node.nodeSize),
        nodes.map((json) => schema.nodeFromJSON(json))
      )
    }
  } catch (err) {
    console.error('[migratePlugin] Error migrating emoji', {
      err,
      pos,
      text: node.text,
      node,
      nodes,
    })
  }

  return true
}
