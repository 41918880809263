import { Button, Divider, Flex, VStack } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { useCallback } from 'react'

import {
  SegmentEvents,
  useAnalytics,
  useAnalyticsEffect,
} from 'modules/segment'

import { ColorPickerSystemSection } from './ColorPickerSystemSection'
import { ColorPickerThemeSection } from './ColorPickerThemeSection'

type ColorPickerMenuProps = {
  currentColor: string | null
  setColor: (color: string | null) => void
  unsetColor?: () => void
  source: string // For analytics, tracking where the menu is used
}

export const ColorPickerMenuInner = ({
  currentColor,
  setColor,
  unsetColor = () => setColor(null),
  source,
}: ColorPickerMenuProps) => {
  const analytics = useAnalytics()
  const setColorAndTrack = useCallback(
    (newColor: string) => {
      analytics?.track(SegmentEvents.COLOR_PICKER_SET_COLOR, {
        color: newColor,
        source,
      })
      setColor(newColor)
    },
    [setColor, analytics, source]
  )
  const unsetColorAndTrack = useCallback(() => {
    analytics?.track(SegmentEvents.COLOR_PICKER_RESET, { source })
    unsetColor()
  }, [unsetColor, analytics, source])

  useAnalyticsEffect(
    (a) => {
      a.track(SegmentEvents.COLOR_PICKER_OPENED, { source })
    },
    [source]
  )

  return (
    <>
      <VStack divider={<Divider />} p={0} align="start">
        <ColorPickerThemeSection
          setColor={setColorAndTrack}
          currentColor={currentColor}
        />
        <ColorPickerSystemSection
          setColor={setColorAndTrack}
          currentColor={currentColor}
        />
      </VStack>
      <Flex
        px={5}
        py={3}
        bg="gray.100"
        alignItems="center"
        justifyContent="center"
        mx={-2}
        mb={-2}
        mt={2}
      >
        <Button
          onClick={unsetColorAndTrack}
          variant="link"
          size="sm"
          colorScheme="gray"
          leftIcon={<FontAwesomeIcon icon={regular('undo')} />}
        >
          <Trans>Reset to default</Trans>
        </Button>
      </Flex>
    </>
  )
}
