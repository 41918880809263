import { JSONContent } from '@tiptap/core'

import { getEmojiObjectFromId, getIdFromNative } from 'modules/emoji'

import { EMOJI_REGEX } from './regex'

/**
 * Helper function that can be used on Prosemirror Nodes or
 *
 * @returns
 */
export const handleReplaceNativeEmojis = (
  text: string,
  marks: undefined | JSONContent['marks']
): { replaced: boolean; nodes: JSONContent[] } => {
  const nodes: JSONContent[] = []
  const emojiMatches = text.matchAll(EMOJI_REGEX)

  let lastMatchEnd = 0
  for (const match of emojiMatches) {
    const start = match.index
    if (start === undefined) continue
    const end = start + match[0].length
    const native = match[0]
    const id = getIdFromNative(native)
    if (!id) {
      continue
    }
    const emojiObject = getEmojiObjectFromId(id)
    // Add the text before the emoji
    const textBefore = text.slice(lastMatchEnd, start)
    if (textBefore.length > 0) {
      nodes.push({
        type: 'text',
        text: textBefore,
        marks,
      })
    }

    // Add the emoji
    const newNode = {
      type: 'emoji',
      attrs: {
        native,
        id: emojiObject?.id,
      },
      marks,
    }
    lastMatchEnd = end

    nodes.push(newNode)
    console.debug('[migratePlugin] Replacing bad emoji', {
      start,
      end,
      native,
      newNode,
      textBefore,
      match,
    })
  }
  const lastText = text.slice(lastMatchEnd)
  if (lastMatchEnd === 0) {
    return {
      replaced: false,
      nodes,
    }
  }

  // Add the text after the emoji (if present)
  // Note that this could include other emojis, which will
  // be picked up in a subsequent iteration of this loop
  if (lastText) {
    nodes.push({
      type: 'text',
      text: lastText,
      marks,
    })
  }

  return {
    replaced: true,
    nodes,
  }
}
