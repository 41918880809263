export const CardTemplateEmptyNodes = {
  blankCard: {
    type: 'card',
    content: [
      {
        type: 'heading',
        attrs: {
          level: 1,
        },
      },
    ],
  },
  titleCard: {
    type: 'card',
    attrs: {
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'title',
        attrs: {
          horizontalAlign: 'left',
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Title',
          },
        ],
      },
      {
        type: 'heading',
        attrs: {
          horizontalAlign: 'left',
          level: 3,
        },
        content: [
          {
            type: 'text',
            text: 'Short description or subheading',
          },
        ],
      },
    ],
  },
  titleWithText: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'paragraph',
        attrs: {
          horizontalAlign: null,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          },
        ],
      },
      {
        type: 'paragraph',
        attrs: {
          horizontalAlign: null,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          },
        ],
      },
    ],
  },
  titleWithBullets: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'bullet',
        attrs: {
          indent: 0,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
          },
        ],
      },
      {
        type: 'bullet',
        attrs: {
          indent: 0,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
          },
        ],
      },
      {
        type: 'bullet',
        attrs: {
          indent: 0,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Ut enim ad minim veniam, quis nostrud exercitation',
          },
        ],
      },
      {
        type: 'bullet',
        attrs: {
          indent: 0,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Duis aute irure dolor in reprehenderit in voluptate',
          },
        ],
      },
      {
        type: 'bullet',
        attrs: {
          indent: 0,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Dolore eu fugiat nulla pariatur',
          },
        ],
      },
    ],
  },
  titleWith2ColumnContent: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'gridLayout',
        attrs: {
          colWidths: [50, 50],
          fullWidthBlock: false,
        },
        content: [
          {
            type: 'gridCell',
            content: [
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
                  },
                ],
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  titleWith2ColumnImageLeft: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'gridLayout',
        attrs: {
          colWidths: [50, 50],
          fullWidthBlock: false,
        },
        content: [
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. aliqua. Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  titleWith2ColumnImageRight: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'gridLayout',
        attrs: {
          colWidths: [50, 50],
          fullWidthBlock: false,
        },
        content: [
          {
            type: 'gridCell',
            content: [
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. aliqua. Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
                  },
                ],
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  titleWith3ColumnContent: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'gridLayout',
        attrs: {
          colWidths: [33.33, 33.34, 33.33],
          fullWidthBlock: false,
        },
        content: [
          {
            type: 'gridCell',
            content: [
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                  },
                ],
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.',
                  },
                ],
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  titleWith3NestedCards: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'paragraph',
        attrs: {
          horizontalAlign: null,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          },
        ],
      },
      {
        type: 'card',
        attrs: {
          previewContent: null,
          background: {
            type: 'none',
          },
          container: {},
          cardSize: 'default',
          layout: 'blank',
        },
        content: [
          {
            type: 'heading',
            attrs: {
              horizontalAlign: null,
              level: 1,
            },
            content: [
              {
                type: 'text',
                text: 'Section 1',
              },
            ],
          },
          {
            type: 'gridLayout',
            attrs: {
              colWidths: [50, 50],
              fullWidthBlock: false,
            },
            content: [
              {
                type: 'gridCell',
                content: [
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'gridCell',
                content: [
                  {
                    type: 'mediaPlaceholder',
                    attrs: {
                      horizontalAlign: null,
                      source: 'image.custom',
                      fullWidthBlock: false,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'card',
        attrs: {
          previewContent: null,
          background: {
            type: 'none',
          },
          container: {},
          cardSize: 'default',
          layout: 'blank',
        },
        content: [
          {
            type: 'heading',
            attrs: {
              horizontalAlign: null,
              level: 1,
            },
            content: [
              {
                type: 'text',
                text: 'Section 2',
              },
            ],
          },
          {
            type: 'gridLayout',
            attrs: {
              colWidths: [50, 50],
              fullWidthBlock: false,
            },
            content: [
              {
                type: 'gridCell',
                content: [
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'gridCell',
                content: [
                  {
                    type: 'mediaPlaceholder',
                    attrs: {
                      horizontalAlign: null,
                      source: 'image.custom',
                      fullWidthBlock: false,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'card',
        attrs: {
          previewContent: null,
          background: {
            type: 'none',
          },
          container: {},
          cardSize: 'default',
          layout: 'blank',
        },
        content: [
          {
            type: 'heading',
            attrs: {
              horizontalAlign: null,
              level: 1,
            },
            content: [
              {
                type: 'text',
                text: 'Section 3',
              },
            ],
          },
          {
            type: 'gridLayout',
            attrs: {
              colWidths: [50, 50],
              fullWidthBlock: false,
            },
            content: [
              {
                type: 'gridCell',
                content: [
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'gridCell',
                content: [
                  {
                    type: 'mediaPlaceholder',
                    attrs: {
                      horizontalAlign: null,
                      source: 'image.custom',
                      fullWidthBlock: false,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  titleWith3Toggles: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'toggle',

        content: [
          {
            type: 'toggleSummary',
            attrs: {
              fontSize: 'lg',
            },
            content: [
              {
                type: 'text',
                text: 'Key point toggle 1',
              },
            ],
          },
          {
            type: 'paragraph',
            attrs: {
              horizontalAlign: null,
              fontSize: null,
            },
            content: [
              {
                type: 'text',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
              },
            ],
          },
        ],
      },
      {
        type: 'toggle',

        content: [
          {
            type: 'toggleSummary',
            attrs: {
              fontSize: 'lg',
            },
            content: [
              {
                type: 'text',
                text: 'Key point toggle 2',
              },
            ],
          },
          {
            type: 'paragraph',
            attrs: {
              horizontalAlign: null,
              fontSize: null,
            },
            content: [
              {
                type: 'text',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
              },
            ],
          },
        ],
      },
      {
        type: 'toggle',
        content: [
          {
            type: 'toggleSummary',
            attrs: {
              fontSize: 'lg',
            },
            content: [
              {
                type: 'text',
                text: 'Key point toggle 3',
              },
            ],
          },
          {
            type: 'paragraph',
            attrs: {
              horizontalAlign: null,
              fontSize: null,
            },
            content: [
              {
                type: 'text',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
              },
            ],
          },
        ],
      },
    ],
  },
  titleWith2ImageColumns: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'gridLayout',
        attrs: {
          colWidths: [50, 50],
          fullWidthBlock: false,
        },
        content: [
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
              {
                type: 'heading',
                attrs: {
                  horizontalAlign: null,
                  level: 3,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Item 1',
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. aliqua. Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
                  },
                ],
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
              {
                type: 'heading',
                attrs: {
                  horizontalAlign: null,
                  level: 3,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Item 2',
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. aliqua. Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  titleWith3ImageColumns: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'gridLayout',
        attrs: {
          colWidths: [33.33, 33.33, 33.33],
          fullWidthBlock: false,
        },
        content: [
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
              {
                type: 'heading',
                attrs: {
                  horizontalAlign: null,
                  level: 3,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Item 1',
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
                  },
                ],
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
              {
                type: 'heading',
                attrs: {
                  horizontalAlign: null,
                  level: 3,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Item 2',
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.',
                  },
                ],
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
              {
                type: 'heading',
                attrs: {
                  horizontalAlign: null,
                  level: 3,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Item 3',
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  titleWith4ImageColumns: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'gridLayout',
        attrs: {
          colWidths: [25, 25, 25, 25],
          fullWidthBlock: false,
        },
        content: [
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
              {
                type: 'heading',
                attrs: {
                  horizontalAlign: null,
                  level: 4,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Item 1',
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                  },
                ],
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
              {
                type: 'heading',
                attrs: {
                  horizontalAlign: null,
                  level: 4,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Item 2',
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Ut enim ad minim veniam, quis nostrud exercitation',
                  },
                ],
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
              {
                type: 'heading',
                attrs: {
                  horizontalAlign: null,
                  level: 4,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Item 3',
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Duis aute irure dolor in reprehenderit in voluptate velit',
                  },
                ],
              },
            ],
          },
          {
            type: 'gridCell',
            content: [
              {
                type: 'mediaPlaceholder',
                attrs: {
                  horizontalAlign: null,
                  source: 'image.custom',
                  fullWidthBlock: false,
                },
              },
              {
                type: 'heading',
                attrs: {
                  horizontalAlign: null,
                  level: 4,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Item 4',
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: {
                  horizontalAlign: null,
                  fontSize: null,
                },
                content: [
                  {
                    type: 'text',
                    text: 'Neque porro quisquam est, qui dolorem ipsum quia dolor sit',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  titleWithContentAndButton: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: 'center',
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'paragraph',
        attrs: {
          horizontalAlign: null,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          },
        ],
      },
      {
        type: 'buttonGroup',
        attrs: {
          horizontalAlign: 'center',
        },
        content: [
          {
            type: 'button',
            attrs: {
              href: '',
              variant: 'solid',
            },
            content: [
              {
                type: 'text',
                text: 'Button 1',
              },
            ],
          },
        ],
      },
    ],
  },
  /// Card Layout templates
  accentLeft: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'left',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'paragraph',
        attrs: {
          horizontalAlign: null,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          },
        ],
      },
    ],
  },
  accentRight: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'right',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'paragraph',
        attrs: {
          horizontalAlign: null,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          },
        ],
      },
    ],
  },
  accentTop: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'top',
    },
    content: [
      {
        type: 'heading',
        attrs: {
          horizontalAlign: null,
          level: 1,
        },
        content: [
          {
            type: 'text',
            text: 'Card Title',
          },
        ],
      },
      {
        type: 'paragraph',
        attrs: {
          horizontalAlign: null,
          fontSize: null,
        },
        content: [
          {
            type: 'text',
            text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          },
        ],
      },
    ],
  },
  titleWithTimeline: {
    type: 'card',
    attrs: {
      id: 'sb2yzx7mi78270g',
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
      layoutTemplateColumns: null,
    },
    content: [
      {
        type: 'cardLayoutItem',
        attrs: {
          itemId: 'body',
          background: {},
        },
        content: [
          {
            type: 'heading',
            attrs: {
              horizontalAlign: null,
              level: 1,
            },
            content: [
              {
                type: 'text',
                text: 'Card Title',
              },
            ],
          },
          {
            type: 'paragraph',
            attrs: {
              horizontalAlign: null,
              fontSize: null,
            },
            content: [
              {
                type: 'text',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. aliqua. Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
              },
            ],
          },
          {
            type: 'smartLayout',
            attrs: {
              variantKey: 'timeline',
              options: {},
              fullWidthBlock: false,
            },
            content: [
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: null,
                  label: null,
                },
                content: [
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: null,
                  label: null,
                },
                content: [
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: 'default',
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: null,
                  label: null,
                },
                content: [
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: 'default',
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  titleWithLargeBulletList: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
      layoutTemplateColumns: null,
    },
    content: [
      {
        type: 'cardLayoutItem',
        attrs: {
          itemId: 'body',
          background: {},
        },
        content: [
          {
            type: 'heading',
            attrs: {
              horizontalAlign: null,
              level: 1,
            },
            content: [
              {
                type: 'text',
                text: 'Card Title',
              },
            ],
          },
          {
            type: 'smartLayout',
            attrs: {
              variantKey: 'bullets',
              options: {
                cellSize: 25,
              },
              fullWidthBlock: false,
            },
            content: [
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: null,
                  label: null,
                },
                content: [
                  {
                    type: 'heading',
                    attrs: {
                      horizontalAlign: null,
                      level: 3,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Item 1',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: null,
                  label: null,
                },
                content: [
                  {
                    type: 'heading',
                    attrs: {
                      horizontalAlign: null,
                      level: 3,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Item 2',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: null,
                  label: null,
                },
                content: [
                  {
                    type: 'heading',
                    attrs: {
                      horizontalAlign: null,
                      level: 3,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Item 3',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  titleWithTeamPhotos: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
      layoutTemplateColumns: null,
    },
    content: [
      {
        type: 'cardLayoutItem',
        attrs: {
          itemId: 'body',
          background: {},
        },
        content: [
          {
            type: 'heading',
            attrs: {
              horizontalAlign: null,
              level: 1,
            },
            content: [
              {
                type: 'text',
                text: 'Card Title',
              },
            ],
          },
          {
            type: 'smartLayout',
            attrs: {
              variantKey: 'imagesText',
              options: {
                cellSize: 10,
                imageSize: 1,
                horizontalAlign: 'center',
                imageShape: 'circle',
              },
              fullWidthBlock: false,
            },
            content: [
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: {
                    source: 'image.custom',
                    uploadStatus: 0,
                    showPlaceholder: false,
                    src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/c1414815be03462f8862cb80f89f720d/optimized/tesla.jpg',
                    meta: {
                      width: 820,
                      height: 709,
                      date_file_modified: '2023/02/07 22:20:08 GMT',
                      aspect_ratio: 1.157,
                      has_clipping_path: false,
                      frame_count: 1,
                      colorspace: 'sRGB',
                      has_transparency: false,
                      average_color: '#828282',
                      orientation: 'Horizontal (normal)',
                    },
                    name: 'tesla.jpg',
                  },
                  label: null,
                },
                content: [
                  {
                    type: 'heading',
                    attrs: {
                      horizontalAlign: null,
                      level: 3,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Nikola Tesla',
                      },
                    ],
                  },
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: 'default',
                    },
                    content: [
                      {
                        type: 'text',
                        marks: [
                          {
                            type: 'bold',
                          },
                        ],
                        text: 'Inventor',
                      },
                      {
                        type: 'hardBreak',
                      },
                      {
                        type: 'text',
                        text: '@ Tesla Inc. ',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: {
                    source: 'image.custom',
                    uploadStatus: 0,
                    showPlaceholder: false,
                    src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/53ab692111294834a286002e3ecfdf2b/optimized/marie-curie.jpg',
                    meta: {
                      width: 1200,
                      height: 1631,
                      date_file_modified: '2023/02/07 22:20:11 GMT',
                      aspect_ratio: 0.736,
                      has_clipping_path: false,
                      frame_count: 1,
                      colorspace: 'sRGB',
                      has_transparency: false,
                      orientation: 'Horizontal (normal)',
                      average_color: '#3b3b3b',
                    },
                    name: 'marie-curie.jpg',
                  },
                  label: null,
                },
                content: [
                  {
                    type: 'heading',
                    attrs: {
                      horizontalAlign: null,
                      level: 3,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Marie Curie',
                      },
                    ],
                  },
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: 'default',
                    },
                    content: [
                      {
                        type: 'text',
                        marks: [
                          {
                            type: 'bold',
                          },
                        ],
                        text: 'Physicist and Chemist',
                      },
                      {
                        type: 'hardBreak',
                      },
                      {
                        type: 'text',
                        text: '@ Radioactivity',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: {
                    source: 'image.custom',
                    uploadStatus: 0,
                    showPlaceholder: false,
                    src: 'https://cdn.gamma.app/zc87vhr30n8uf3n/e5d0250f2e054ee3a4c63a8e11f39c36/optimized/thomas-edison.webp',
                    meta: {
                      width: 360,
                      height: 450,
                      date_file_modified: '2023/02/07 22:20:22 GMT',
                      aspect_ratio: 0.8,
                      has_clipping_path: false,
                      frame_count: 1,
                      colorspace: 'sRGB',
                      has_transparency: false,
                      average_color: '#606060',
                      orientation: 'Horizontal (normal)',
                    },
                    name: 'thomas edison.webp',
                  },
                  label: null,
                },
                content: [
                  {
                    type: 'heading',
                    attrs: {
                      horizontalAlign: null,
                      level: 3,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Thomas Edison',
                      },
                    ],
                  },
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: 'default',
                    },
                    content: [
                      {
                        type: 'text',
                        marks: [
                          {
                            type: 'bold',
                          },
                        ],
                        text: 'Inventor ',
                      },
                      {
                        type: 'hardBreak',
                      },
                      {
                        type: 'text',
                        text: '@ Light bulb ',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  titleWith3Boxes: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
      layoutTemplateColumns: null,
    },
    content: [
      {
        type: 'cardLayoutItem',
        attrs: {
          itemId: 'body',
          background: {},
        },
        content: [
          {
            type: 'heading',
            attrs: {
              horizontalAlign: null,
              level: 1,
            },
            content: [
              {
                type: 'text',
                text: 'Card Title',
              },
            ],
          },
          {
            type: 'smartLayout',
            attrs: {
              variantKey: 'textBoxes',
              options: {
                cellSize: 10,
              },
              fullWidthBlock: false,
            },
            content: [
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: null,
                  label: null,
                },
                content: [
                  {
                    type: 'heading',
                    attrs: {
                      horizontalAlign: null,
                      level: 3,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Item 1',
                      },
                    ],
                  },
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: null,
                  label: null,
                },
                content: [
                  {
                    type: 'heading',
                    attrs: {
                      horizontalAlign: null,
                      level: 3,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Item 2',
                      },
                    ],
                  },
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: null,
                  label: null,
                },
                content: [
                  {
                    type: 'heading',
                    attrs: {
                      horizontalAlign: null,
                      level: 3,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Item 3',
                      },
                    ],
                  },
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  steppedPyramidWithText: {
    type: 'card',
    attrs: {
      id: 'lg316yl8ptg1rvs',
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
      layoutTemplateColumns: null,
    },
    content: [
      {
        type: 'cardLayoutItem',
        attrs: {
          itemId: 'body',
          background: {},
        },
        content: [
          {
            type: 'heading',
            attrs: {
              horizontalAlign: null,
              level: 1,
            },
            content: [
              {
                type: 'text',
                text: 'Card Title',
              },
            ],
          },
          {
            type: 'gridLayout',
            attrs: {
              colWidths: [50, 50],
              fullWidthBlock: false,
            },
            content: [
              {
                type: 'gridCell',
                attrs: {
                  verticalAlign: null,
                },
                content: [
                  {
                    type: 'smartLayout',
                    attrs: {
                      variantKey: 'staircase',
                      options: {
                        numbered: true,
                        hasLine: false,
                        twoSided: false,
                        orientation: 'horizontal',
                        cellSize: 10,
                        outsideText: false,
                        horizontalAlign: 'center',
                      },
                      fullWidthBlock: false,
                    },
                    content: [
                      {
                        type: 'smartLayoutCell',
                        attrs: {
                          options: {},
                          width: null,
                          image: null,
                          label: null,
                        },
                        content: [
                          {
                            type: 'heading',
                            attrs: {
                              horizontalAlign: null,
                              level: 3,
                            },
                            content: [
                              {
                                type: 'text',
                                text: 'Vision',
                              },
                            ],
                          },
                          {
                            type: 'paragraph',
                            attrs: {
                              horizontalAlign: null,
                              fontSize: null,
                            },
                            content: [
                              {
                                type: 'text',
                                text: 'Lorem ipsum dolor sit',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: 'smartLayoutCell',
                        attrs: {
                          options: {},
                          width: null,
                          image: null,
                          label: null,
                        },
                        content: [
                          {
                            type: 'heading',
                            attrs: {
                              horizontalAlign: null,
                              level: 3,
                            },
                            content: [
                              {
                                type: 'text',
                                text: 'Mission',
                              },
                            ],
                          },
                          {
                            type: 'paragraph',
                            attrs: {
                              horizontalAlign: null,
                              fontSize: null,
                            },
                            content: [
                              {
                                type: 'text',
                                text: 'Excepteur sint occaecat ',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: 'smartLayoutCell',
                        attrs: {
                          options: {},
                          width: null,
                          image: null,
                          label: null,
                        },
                        content: [
                          {
                            type: 'heading',
                            attrs: {
                              horizontalAlign: null,
                              level: 3,
                            },
                            content: [
                              {
                                type: 'text',
                                text: 'Goal',
                              },
                            ],
                          },
                          {
                            type: 'paragraph',
                            attrs: {
                              horizontalAlign: null,
                              fontSize: null,
                            },
                            content: [
                              {
                                type: 'text',
                                text: 'Sed ut perspiciatis unde omnis ',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: 'smartLayoutCell',
                        attrs: {
                          options: {},
                          width: null,
                          image: null,
                          label: null,
                        },
                        content: [
                          {
                            type: 'heading',
                            attrs: {
                              horizontalAlign: null,
                              level: 3,
                            },
                            content: [
                              {
                                type: 'text',
                                text: 'Strategy',
                              },
                            ],
                          },
                          {
                            type: 'paragraph',
                            attrs: {
                              horizontalAlign: null,
                              fontSize: null,
                            },
                            content: [
                              {
                                type: 'text',
                                text: 'Foste natus error sit volupta tem accusa nuin',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                type: 'gridCell',
                attrs: {
                  verticalAlign: 'center',
                },
                content: [
                  {
                    type: 'heading',
                    attrs: {
                      horizontalAlign: null,
                      level: 2,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Title here ',
                      },
                    ],
                  },
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa.',
                      },
                    ],
                  },
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Timpedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut offici',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  funnelWithText: {
    type: 'card',
    attrs: {
      id: 'eodg7rxsu7zf8xz',
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
      layoutTemplateColumns: null,
    },
    content: [
      {
        type: 'cardLayoutItem',
        attrs: {
          itemId: 'body',
          background: {},
        },
        content: [
          {
            type: 'heading',
            attrs: {
              horizontalAlign: null,
              level: 1,
            },
            content: [
              {
                type: 'text',
                text: 'Card Title',
              },
            ],
          },
          {
            type: 'gridLayout',
            attrs: {
              colWidths: [50, 50],
              fullWidthBlock: false,
            },
            content: [
              {
                type: 'gridCell',
                attrs: {
                  verticalAlign: null,
                },
                content: [
                  {
                    type: 'smartLayout',
                    attrs: {
                      variantKey: 'funnel',
                      options: {
                        numbered: true,
                        hasLine: false,
                        twoSided: false,
                        orientation: 'horizontal',
                        cellSize: 10,
                        outsideText: false,
                      },
                      fullWidthBlock: false,
                    },
                    content: [
                      {
                        type: 'smartLayoutCell',
                        attrs: {
                          options: {},
                          width: null,
                          image: null,
                          label: null,
                        },
                        content: [
                          {
                            type: 'heading',
                            attrs: {
                              horizontalAlign: null,
                              level: 3,
                            },
                            content: [
                              {
                                type: 'text',
                                text: 'Vision',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: 'smartLayoutCell',
                        attrs: {
                          options: {},
                          width: null,
                          image: null,
                          label: null,
                        },
                        content: [
                          {
                            type: 'heading',
                            attrs: {
                              horizontalAlign: null,
                              level: 3,
                            },
                            content: [
                              {
                                type: 'text',
                                text: 'Mission',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: 'smartLayoutCell',
                        attrs: {
                          options: {},
                          width: null,
                          image: null,
                          label: null,
                        },
                        content: [
                          {
                            type: 'heading',
                            attrs: {
                              horizontalAlign: null,
                              level: 3,
                            },
                            content: [
                              {
                                type: 'text',
                                text: 'Goal',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: 'smartLayoutCell',
                        attrs: {
                          options: {},
                          width: null,
                          image: null,
                          label: null,
                        },
                        content: [
                          {
                            type: 'heading',
                            attrs: {
                              horizontalAlign: null,
                              level: 3,
                            },
                            content: [
                              {
                                type: 'text',
                                text: 'Strategy',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: 'smartLayoutCell',
                        attrs: {
                          options: {},
                          width: null,
                          image: null,
                          label: null,
                        },
                        content: [
                          {
                            type: 'heading',
                            attrs: {
                              horizontalAlign: null,
                              level: 3,
                            },
                            content: [
                              {
                                type: 'text',
                                text: 'Conclusion',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                type: 'gridCell',
                attrs: {
                  verticalAlign: null,
                },
                content: [
                  {
                    type: 'heading',
                    attrs: {
                      horizontalAlign: null,
                      level: 2,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Title here ',
                      },
                    ],
                  },
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa.',
                      },
                    ],
                  },
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Timpedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut offici',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  barStatsWithText: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
      layoutTemplateColumns: null,
    },
    content: [
      {
        type: 'cardLayoutItem',
        attrs: {
          itemId: 'body',
          background: {},
        },
        content: [
          {
            type: 'heading',
            attrs: {
              horizontalAlign: null,
              level: 1,
            },
            content: [
              {
                type: 'text',
                text: 'Card Title',
              },
            ],
          },
          {
            type: 'gridLayout',
            attrs: {
              colWidths: [50, 50],
              fullWidthBlock: false,
            },
            content: [
              {
                type: 'gridCell',
                attrs: {
                  verticalAlign: null,
                },
                content: [
                  {
                    type: 'smartLayout',
                    attrs: {
                      variantKey: 'barStats',
                      options: {
                        cellSize: 20,
                      },
                      fullWidthBlock: false,
                    },
                    content: [
                      {
                        type: 'smartLayoutCell',
                        attrs: {
                          options: {},
                          width: null,
                          image: null,
                          label: '25%',
                        },
                        content: [
                          {
                            type: 'heading',
                            attrs: {
                              horizontalAlign: null,
                              level: 3,
                            },
                            content: [
                              {
                                type: 'text',
                                text: 'Vision',
                              },
                            ],
                          },
                          {
                            type: 'paragraph',
                            attrs: {
                              horizontalAlign: null,
                              fontSize: null,
                            },
                            content: [
                              {
                                type: 'text',
                                text: 'Lorem ipsum dolor sit amet, consectetur ',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: 'smartLayoutCell',
                        attrs: {
                          options: {},
                          width: null,
                          image: null,
                          label: '3/4',
                        },
                        content: [
                          {
                            type: 'heading',
                            attrs: {
                              horizontalAlign: null,
                              level: 3,
                            },
                            content: [
                              {
                                type: 'text',
                                text: 'Mission',
                              },
                            ],
                          },
                          {
                            type: 'paragraph',
                            attrs: {
                              horizontalAlign: null,
                              fontSize: null,
                            },
                            content: [
                              {
                                type: 'text',
                                text: 'Excepteur sint occaecat cupidatat non proident',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                type: 'gridCell',
                attrs: {
                  verticalAlign: 'start',
                },
                content: [
                  {
                    type: 'heading',
                    attrs: {
                      horizontalAlign: 'left',
                      level: 2,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Title here ',
                      },
                    ],
                  },
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: 'left',
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa.',
                      },
                    ],
                  },
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: 'left',
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Timpedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. ',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  titleWith3Stats: {
    type: 'card',
    attrs: {
      previewContent: null,
      background: {
        type: 'none',
      },
      container: {},
      cardSize: 'default',
      layout: 'blank',
      layoutTemplateColumns: null,
    },
    content: [
      {
        type: 'cardLayoutItem',
        attrs: {
          itemId: 'body',
          background: {},
        },
        content: [
          {
            type: 'heading',
            attrs: {
              horizontalAlign: null,
              level: 1,
            },
            content: [
              {
                type: 'text',
                text: 'Card Title',
              },
            ],
          },
          {
            type: 'smartLayout',
            attrs: {
              variantKey: 'stats',
              options: {
                cellSize: 15,
                shapeSize: 'lg',
              },
              fullWidthBlock: false,
            },
            content: [
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: null,
                  label: '25%',
                },
                content: [
                  {
                    type: 'heading',
                    attrs: {
                      horizontalAlign: null,
                      level: 3,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Vision',
                      },
                    ],
                  },
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {},
                  width: null,
                  image: null,
                  label: '3/4',
                },
                content: [
                  {
                    type: 'heading',
                    attrs: {
                      horizontalAlign: null,
                      level: 3,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Mission',
                      },
                    ],
                  },
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit ',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'smartLayoutCell',
                attrs: {
                  options: {
                    numberMax: 100,
                  },
                  width: null,
                  image: null,
                  label: 50,
                },
                content: [
                  {
                    type: 'heading',
                    attrs: {
                      horizontalAlign: null,
                      level: 3,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Goal',
                      },
                    ],
                  },
                  {
                    type: 'paragraph',
                    attrs: {
                      horizontalAlign: null,
                      fontSize: null,
                    },
                    content: [
                      {
                        type: 'text',
                        text: 'Sed ut perspiciatis unde omnis iste natus error sit volupta tem accusa ntium eius modi tempora',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
}
