import { Box, HStack, Text } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NodeViewProps } from '@tiptap/core'
import { useCallback } from 'react'

import { HEADING_NO_GRADIENT_CLASS } from 'modules/theming/styles/heading'
import { colorWithLightness, isColorDark } from 'utils/color'

import { DEFAULT_ACCENT_COLOR } from '../../../theming/constants'
import { NodeViewContent } from '../../react'
import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { useCardColorMode } from '../Card/hooks/useCardColorMode'
import { ContainerDragHandle } from '../DragDrop/ContainerDragHandle/ContainerDragHandle'
import { findFirstChildFontSizeDeco } from '../Font/FontSizePlugin'
import { getFontSizeStyles } from '../Font/fontStyles'
import { highlightColors } from '../TextColor/highlightStyles'
import { textColors } from '../TextColor/textColorStyles'
import { getCalloutBoxOption } from './options'
import { CalloutBoxAttrs } from './types'

export const CalloutBoxView = (nodeViewProps: NodeViewProps) => {
  const { node, editor, getPos, decorations } = nodeViewProps
  const { variant, icon, color } = node.attrs as CalloutBoxAttrs
  const { firstChildSize } = findFirstChildFontSizeDeco(decorations)
  const iconFontSizeStyles = getFontSizeStyles(firstChildSize)

  const { theme, isDark } = useCardColorMode(decorations)
  const accentColor = theme.accentColor ?? DEFAULT_ACCENT_COLOR
  const { colorScheme, icon: defaultIcon } = getCalloutBoxOption(variant)
  const { backgroundColor, iconColor, bodyColor } = color
    ? getCalloutBoxColorsOverride(color)
    : getCalloutBoxColors(isDark, colorScheme, accentColor)

  const selectNode = useCallback(() => {
    if (!editor.isEditable) return
    editor.commands.selectNodeAtPos(getPos())
  }, [editor, getPos])

  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps}>
      <Box
        backgroundColor={`${backgroundColor} !important`}
        pt="0.25em"
        pb="0.5em"
        px="1em"
        className="calloutBox"
        borderRadius="var(--box-border-radius)"
        css={{
          '--body-color': bodyColor,
          '--heading-color': bodyColor,
        }}
        data-selection-ring
      >
        <HStack
          direction="row"
          align={firstChildSize ? 'baseline' : 'flex-start'}
          spacing="1em"
        >
          {icon && (
            <Text
              sx={iconFontSizeStyles}
              onClick={selectNode}
              color={iconColor}
              mt={!firstChildSize ? '1em' : undefined}
              contentEditable={false}
            >
              <FontAwesomeIcon icon={defaultIcon} fixedWidth />
            </Text>
          )}
          <NodeViewContent
            style={{
              flex: '1 1 auto',
              minWidth: 0, // Prevents images from overflowing
            }}
            className={cx(HEADING_NO_GRADIENT_CLASS)} // Prevent gradients inside
          ></NodeViewContent>
        </HStack>
        <ContainerDragHandle {...nodeViewProps} />
      </Box>
    </AnnotatableNodeViewWrapper>
  )
}

const getCalloutBoxColorsOverride = (color: string) => {
  const isDark = isColorDark(color)
  const bodyColor = isDark ? 'white' : 'black'
  const iconColor = isDark
    ? colorWithLightness(color, 0.7)
    : colorWithLightness(color, 0.3)
  return {
    backgroundColor: color,
    bodyColor,
    iconColor,
  }
}

const getCalloutBoxColors = (
  isDark: boolean,
  colorScheme: string,
  accentColor: string
) => {
  const backgroundColor = isDark
    ? colorWithLightness(
        colorScheme === 'accent'
          ? accentColor
          : highlightColors.dark[colorScheme].hex,
        0.15
      )
    : colorWithLightness(
        colorScheme === 'accent'
          ? accentColor
          : highlightColors.light[colorScheme].hex,
        0.85
      )
  const bodyColor = isDark ? 'white' : 'black'
  const iconColor = isDark
    ? colorScheme === 'accent'
      ? colorWithLightness(accentColor, 0.7)
      : textColors.dark[colorScheme].hex
    : colorScheme === 'accent'
    ? colorWithLightness(accentColor, 0.3)
    : textColors.light[colorScheme].hex

  return { backgroundColor, bodyColor, iconColor }
}

export const isElementOutsideCalloutContent = (element) => {
  return !element.closest('[data-node-view-content-inner="calloutBox"]')
}
