import { t } from '@lingui/macro'
import { sortBy } from 'lodash'
export type FontSizeOption = {
  label: string
  size: number
  mobileSize?: number
  flagged?: boolean
  type: 'body' | 'heading' | 'title'
  // If it's a heading or title, the level
  level?: number
  inputRegex?: RegExp
  shortcut?: string
}

export const getFontSizeOptions = (): Record<string, FontSizeOption> => {
  return {
    sm: {
      label: t`Small text`,
      size: 0.8,
      flagged: true,
      type: 'body',
      shortcut: '/sm',
    },
    default: {
      label: t`Normal text`,
      size: 1,
      type: 'body',
      shortcut: '/md',
    },
    lg: {
      label: t`Large text`,
      size: 1.25,
      flagged: true,
      type: 'body',
      shortcut: '/lg',
    },
    h4: {
      label: t`Heading 4`,
      size: 1.25,
      mobileSize: 1.1,
      level: 4,
      type: 'heading',
      inputRegex: /^####\s$/,
      shortcut: '####',
    },
    h3: {
      label: t`Heading 3`,
      size: 1.5,
      mobileSize: 1.25,
      level: 3,
      type: 'heading',
      inputRegex: /^###\s$/,
      shortcut: '###',
    },
    h2: {
      label: t`Heading 2`,
      size: 2,
      mobileSize: 1.5,
      level: 2,
      type: 'heading',
      inputRegex: /^##\s$/,
      shortcut: '##',
    },
    h1: {
      label: t`Heading 1`,
      size: 2.5,
      mobileSize: 2,
      level: 1,
      type: 'heading',
      inputRegex: /^#\s$/,
      shortcut: '#',
    },
    title: {
      label: t`Title`,
      size: 3,
      mobileSize: 2.25,
      level: 1,
      type: 'title',
      shortcut: '/title',
    },
  }
}

export const getHeadingSizeOptions = () =>
  sortBy(
    Object.entries(getFontSizeOptions()).filter(
      ([_, { type }]) => type === 'heading'
    ),
    ([_, { level }]) => level
  )
export const getTitleSizeOptions = () =>
  sortBy(
    Object.entries(getFontSizeOptions()).filter(
      ([_, { type }]) => type === 'title'
    ),
    ([_, { level }]) => level
  )
export const getBodySizeOptions = () =>
  sortBy(
    Object.entries(getFontSizeOptions()).filter(
      ([_, { type }]) => type === 'body'
    ),
    ([_, { size }]) => size
  )
