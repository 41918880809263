import { ChatMessage } from 'modules/api'

import { Messages } from '../../types'

const MAX_MESSAGE_HISTORY = 6

type ChatMessageWithContent = ChatMessage & { content: string }

export const getMessageHistory = (messages: Messages[]) => {
  const formattedMessages = messages
    .slice(-MAX_MESSAGE_HISTORY - 1)
    .map((message) => {
      if (message.from === 'User') {
        return <ChatMessage>{
          role: 'user',
          content: message.message,
        }
      }
      if (message.isClassification) {
        return <ChatMessage>{
          role: 'assistant',
          content: message.message,
        }
      } else if (message.resp) {
        return <ChatMessage>{
          role: 'assistant',
          content: message.resp,
        }
      }
      return null
    })
    .filter((m): m is ChatMessageWithContent => !!m?.content)
  return formattedMessages
}
