import { Button, Menu, MenuButton, MenuList } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { Editor } from '@tiptap/core'

import { ColorPickerMenuInner } from 'modules/theming/components/ColorPickerMenu/ColorPickerMenu'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { focusEditorOnMenuClose } from '../FormattingMenus/utils'

type ColorButtonProps = {
  editor: Editor
  color?: string | null
  setColor: (color: string | null) => void
  analyticsSource: string
}

export const ColorButton = ({
  editor,
  color,
  setColor,
  analyticsSource,
}: ColorButtonProps) => {
  return (
    <Menu isLazy onClose={() => focusEditorOnMenuClose(editor)}>
      <GammaTooltip
        placement="top"
        label={<Trans>Color...</Trans>}
        aria-label={t`Color...`}
      >
        <MenuButton
          as={Button}
          variant="toolbar"
          rightIcon={
            <FontAwesomeIcon
              icon={regular('chevron-down')}
              transform="shrink-6"
            />
          }
          size="sm"
          onMouseDown={preventDefaultToAvoidBlur}
        >
          <FontAwesomeIcon icon={regular('palette')} />
        </MenuButton>
      </GammaTooltip>
      <MenuList overflow="hidden">
        <ColorPickerMenuInner
          currentColor={color || null}
          setColor={setColor}
          source={analyticsSource}
        />
      </MenuList>
    </Menu>
  )
}
