import {
  useCallback,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  ForwardedRef,
  MutableRefObject,
} from 'react'

import {
  EmojiPicker,
  EmojiObject,
  getEmojiObjectFromId,
  Picker,
} from 'modules/emoji'
import { useAppSelector } from 'modules/redux'
import { selectDoc } from 'modules/tiptap_editor/reducer'
import { useCan } from 'modules/user'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

type UseKeyboardHandlerArgs = {
  ref: ForwardedRef<any>
  pickerInstance?: Picker
}
const useKeyboardHandler = ({
  ref,
  pickerInstance,
}: UseKeyboardHandlerArgs) => {
  useImperativeHandle(ref, () => {
    const handleKeydown = (event: KeyboardEvent) => {
      const inputEl = pickerInstance.component?.refs?.searchInput?.current
      if (inputEl) {
        const { key, code } = event
        inputEl.dispatchEvent(new KeyboardEvent('keydown', { key, code }))
      }
      event.stopPropagation()
      event.preventDefault()
      return true
    }

    return {
      onKeyDown: ({ event }) => {
        if (
          ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Enter'].includes(
            event.key
          )
        ) {
          return handleKeydown(event)
        }

        return false
      },
    }
  })
}

interface EmojiDropdownProps {
  query: string
  onSelect: (emoji: EmojiObject) => void
}

const EmojiDropdownComponent = (
  { query, onSelect }: EmojiDropdownProps,
  ref: MutableRefObject<HTMLElement>
) => {
  const doc = useAppSelector(selectDoc)
  const userCanEditDoc = useCan('edit', doc)

  const [pickerInstance, setPickerInstance] = useState<Picker | null>(null)

  const initPicker = useCallback((picker: Picker) => {
    setPickerInstance(picker)

    // This is a hack to hide the internal search input. We can't just hide
    // the input element because of the adjacent spacer, so we hide the
    // div directly after the <nav> element.
    // See https://github.com/missive/emoji-mart/blob/a22316104fefbcea5ec584d5883599e217e64919/packages/emoji-mart/src/components/Picker/Picker.tsx#L1061
    // and https://github.com/missive/emoji-mart/issues/266#issuecomment-1312834880
    if (picker.shadowRoot) {
      const style = document.createElement('style')
      style.innerHTML = 'nav + div.padding-lr { display: none }'
      picker.shadowRoot.appendChild(style)
    }
    picker.addEventListener('mousedown', preventDefaultToAvoidBlur)
  }, [])

  useKeyboardHandler({ ref, pickerInstance })

  useEffect(() => {
    if (!pickerInstance) return

    // This is a hack to manually set the search query, as
    // the picker doesnt expose a way to do this.
    // See https://github.com/missive/emoji-mart/issues/266#issuecomment-1312834880
    const inputEl = pickerInstance.component?.refs?.searchInput?.current
    if (inputEl) {
      inputEl.value = query
      inputEl.dispatchEvent(new Event('input', { bubbles: true }))
    }
  }, [pickerInstance, query])

  const selectItem = useCallback(
    (emoji: EmojiObject) => {
      const emojiObject = getEmojiObjectFromId(emoji.id)
      if (emojiObject) {
        onSelect(emojiObject)
      }
    },
    [onSelect]
  )

  if (!userCanEditDoc) return <></>

  return (
    <EmojiPicker
      onCreate={initPicker}
      perLine={9}
      searchPosition="sticky"
      handlePick={selectItem}
      shouldFocusSearch={false}
      dataTargetName="emoji-list"
    />
  )
}

export const EmojiDropdown = forwardRef(EmojiDropdownComponent)
