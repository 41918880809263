import { FrontendChatCompletionPrompt } from 'modules/ai/prompt/FrontendChatCompletionPrompt'

const SYSTEM_PROMPT =
  `You are helping a user write a document. Your job is to continue what they've written by writing 2-3 additional sentences. Respond with the just the additional text, and no additional conversation.`.trim()

const USER_PROMPT =
  `Continue the following text. Respond with at most 3 sentences.

{{text}}`.trim()

export const AutocompletePrompt = new FrontendChatCompletionPrompt({
  systemPrompt: SYSTEM_PROMPT,
  userPrompt: USER_PROMPT,
  variables: ['text'],
  params: {
    maxTokens: 200,
    temperature: 1,
    model: 'gpt-3.5-turbo',
  },
})
