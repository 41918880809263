import {
  Box,
  BoxProps,
  IconButton,
  Portal,
  useDisclosure,
  useMediaQuery,
  useOutsideClick,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import React, { useState, MouseEvent, useEffect } from 'react'
import { usePopper } from 'react-popper'

import { EmojiPicker, EmojiObject } from 'modules/emoji'
import { isMobileDevice } from 'utils/deviceDetection'

import { TOOLTIP_PLACEMENT } from './Reactions'

export type ReactionPopoverProps = {
  size: string
  colorScheme?: 'gray'
  handleReactionClick: (emojiId: string, event?: MouseEvent) => void
  placement: 'right' | 'left'
  usePortal?: boolean
}

const isParent = (el: HTMLElement, compare: HTMLElement): boolean => {
  if (el === compare) {
    return true
  }
  if (el.parentElement == null) {
    return false
  }

  return isParent(el.parentElement, compare)
}

type EmojiPickerInnerProps = BoxProps & {
  isPopoverOpen: boolean
  onPopoverClose: () => void
  handleReactionClick: ReactionPopoverProps['handleReactionClick']
  setPopperElement: (el: HTMLElement | null) => void
  popperElement: HTMLElement | null
  openerElement: HTMLElement | null
  usePortal: boolean
  isMobile: boolean
  placement: 'left' | 'right'
}
export const EmojiPickerInner: React.FC<EmojiPickerInnerProps> = ({
  setPopperElement,
  onPopoverClose,
  handleReactionClick,
  popperElement,
  openerElement,
  usePortal,
  isMobile,
  placement,
  ...rest
}) => {
  useOutsideClick({
    ref: {
      current: popperElement,
    },
    handler(e: Event) {
      if (openerElement && isParent(e.target as HTMLElement, openerElement)) {
        return
      }
      onPopoverClose()
    },
  })
  const perLine = isMobile ? 9 : 12

  const inner = (
    <Box
      mx={!isMobile ? 3 : 0}
      mt={!isMobile ? '-110px' : 0}
      shadow="lg"
      bg="white"
      p="0"
      w="auto"
      minW="0px"
      zIndex="tooltip"
      data-target-name="reaction-emoji-picker"
      ref={setPopperElement}
      {...rest}
    >
      <EmojiPicker
        perLine={perLine}
        handlePick={(emoji: EmojiObject) => {
          handleReactionClick(emoji.id)
          onPopoverClose()
        }}
        shouldFocusSearch={!isMobileDevice()}
      />
    </Box>
  )
  return usePortal ? <Portal>{inner}</Portal> : inner
}

export const AddReactionButton = ({
  size,
  colorScheme,
  handleReactionClick,
  placement,
  usePortal = true,
}: ReactionPopoverProps) => {
  const [isMobile] = useMediaQuery(['(max-width: 840px)', '(min-width: 841px)'])
  const finalPlacement: 'bottom' | 'left-start' | 'right-start' | 'auto' =
    isMobile ? 'auto' : `${placement}-start`
  const {
    isOpen: isPopoverOpen,
    onToggle: onPopoverToggle,
    onClose: onPopoverClose,
  } = useDisclosure()
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const { styles, attributes, forceUpdate } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: finalPlacement,
      strategy: 'fixed',
    }
  )

  // force update one-time to fix any positioning issues
  useEffect(() => {
    if (!forceUpdate) return
    setTimeout(() => {
      forceUpdate()
    }, 0)
  }, [forceUpdate])

  return (
    <>
      <GammaTooltip
        placement={TOOLTIP_PLACEMENT}
        label={<Trans>Add reaction</Trans>}
        isOpen={isTooltipOpen}
      >
        <Box
          display="flex"
          onMouseEnter={() => {
            setIsTooltipOpen(true)
          }}
          onMouseLeave={() => setIsTooltipOpen(false)}
        >
          <IconButton
            colorScheme={colorScheme}
            float="inline-start"
            aria-label={t`Add reaction`}
            variant="ghost"
            onClick={(e) => {
              onPopoverToggle()
              e.stopPropagation()
            }}
            size={size || 'sm'}
            ref={setReferenceElement}
            isRound={true}
            icon={<FontAwesomeIcon icon={regular('smile-plus')} />}
          />
        </Box>
      </GammaTooltip>
      {isPopoverOpen && (
        <EmojiPickerInner
          usePortal={usePortal}
          setPopperElement={setPopperElement}
          onPopoverClose={onPopoverClose}
          handleReactionClick={handleReactionClick}
          popperElement={popperElement}
          isPopoverOpen={isPopoverOpen}
          openerElement={referenceElement}
          style={styles.popper}
          isMobile={isMobile}
          placement={placement}
          {...attributes.popper}
        />
      )}
    </>
  )
}
