import { Box, ButtonProps, IconButton } from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Trans, t } from '@lingui/macro'
import { useCallback, useState } from 'react'

interface FavoriteButtonProps extends ButtonProps {
  isFavorited: boolean
  onFavorite?: () => void
  onUnfavorite?: () => void
}

const ANIMATION_DURATION = 1000

export const FavoriteButton = ({
  isFavorited,
  onFavorite,
  onUnfavorite,
  ...buttonProps
}: FavoriteButtonProps) => {
  const [isActive, setActive] = useState(false)

  const handleFavorite = useCallback(() => {
    if (!onFavorite || !onUnfavorite) {
      console.error(
        '[FavoriteButton] onFavorite or onUnfavorite is not defined'
      )
      return
    }
    if (isFavorited) {
      setActive(false)
      onUnfavorite()
    } else {
      setActive(true)
      setTimeout(() => {
        // set it back once the animation is over
        setActive(false)
      }, ANIMATION_DURATION)
      onFavorite()
    }
  }, [onFavorite, onUnfavorite])

  return (
    <GammaTooltip
      label={
        isFavorited ? (
          <Trans>Remove from favorites</Trans>
        ) : (
          <Trans>Add to favorites</Trans>
        )
      }
      placement="top"
    >
      <IconButton
        // Workaround from https://github.com/chakra-ui/chakra-ui/pull/5679 to make this button
        // interact-with-able from inside a LinkBox with a LinkOverlay
        {...buttonProps}
        zIndex="1"
        data-favorite-button
        aria-label={t`Add to favorites`}
        className={`star-wrapper ${isActive ? 'active' : ''}`}
        onClick={handleFavorite}
        variant="ghost"
        size="xs"
        color={isFavorited ? 'yellow.400' : 'gray.400'}
        _hover={{
          color: isFavorited ? 'yellow.500' : 'gray.400',
          bg: isFavorited ? undefined : 'blackAlpha.100',
        }}
        bg={isFavorited ? 'yellow.50' : 'transparent'}
        borderRadius="full"
        icon={
          <>
            <FontAwesomeIcon
              icon={isFavorited ? solid('star') : regular('star')}
            />
            <Box fontSize="2.5px">
              <div className="star">
                <div className="tl"></div>
                <div className="tr"></div>
                <div className="bl"></div>
                <div className="br"></div>
              </div>
              <div className="ring"></div>
              <div className="circles"></div>
            </Box>
          </>
        }
      />
    </GammaTooltip>
  )
}
