import {
  findParentNode,
  isNodeSelection,
  mergeAttributes,
  Node,
} from '@tiptap/core'

import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'
import { BackgroundOptions } from 'modules/tiptap_editor/styles/types'
import { configureJSONAttribute } from 'modules/tiptap_editor/utils'

import { ExtensionPriorityMap } from '../../constants'
import { attrsOrDecorationsChanged } from '../../updateFns'
import { CardLayout } from '../types'
import { isCardNode } from '../utils'
import { setCardLayoutCommand } from './cardLayoutCommands'
import { CardLayoutItemView } from './CardLayoutItemView'
import { CardLayoutPlugin } from './CardLayoutPlugin'
import { createCardLayoutResizingPlugin } from './CardLayoutResizing/CardLayoutResizingPlugin'
import { getCardLayoutItems, isAccentCardLayoutItem } from './cardLayoutUtils'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    cardLayout: {
      setCardLayout: (
        pos: number,
        layout: CardLayout,
        getBgFn?: () => BackgroundOptions | undefined
      ) => ReturnType
      selectInsideCardBody: (cardPos: number) => ReturnType
      handleCardAccentDelete: () => ReturnType
    }
  }
}

export const CardLayoutItem = Node.create({
  name: 'cardLayoutItem',
  group: 'cardLayoutItemGroup',
  content: '(block | cardBlock)+',
  selectable: false, // If this is true, clicking between blocks selects the card instead of putting the cursor between the blocks
  draggable: false, // This seems to be needed to rearrange top level (but not nested) cards. Without it, they get duplicated.
  isolating: true,
  containerHandle: true,
  priority: ExtensionPriorityMap.CardLayoutItem,

  addAttributes() {
    return {
      itemId: {
        default: 'body',
      },
      background: {
        default: {},
        ...configureJSONAttribute('background'),
      },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(CardLayoutItemView, {
      update: attrsOrDecorationsChanged,
    })
  },
  addCommands() {
    return {
      handleCardAccentDelete:
        () =>
        ({ state, commands }) => {
          if (!isNodeSelection(state.selection)) {
            return false
          }
          const { node } = state.selection
          if (!isAccentCardLayoutItem(node)) {
            return false
          }

          const parentCard = findParentNode(isCardNode)(state.selection)
          if (!parentCard) {
            return false
          }

          return commands.setCardLayout(parentCard.pos, 'blank')
        },
      selectInsideCardBody:
        (cardPos: number) =>
        ({ state, tr, commands }) => {
          const node = state.doc.nodeAt(cardPos)
          if (!node || !isCardNode(node)) {
            return false
          }

          const cardLayoutItems = getCardLayoutItems(tr, cardPos)
          if (Object.entries(cardLayoutItems).length === 0) {
            return commands.selectInsideNodeAtPos(cardPos)
          }

          const body = cardLayoutItems.body
          if (body) {
            return commands.selectInsideNodeAtPos(body.pos)
          }

          // card has cardLayoutItems, but can't find body
          return false
        },
      setCardLayout:
        (
          cardPos: number,
          layout: CardLayout,
          getBgFn?: () => BackgroundOptions
        ) =>
        ({ tr, editor }) => {
          return setCardLayoutCommand(editor, tr, cardPos, layout, getBgFn)
        },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[class=card-layout-item]',
      },
    ]
  },

  addProseMirrorPlugins() {
    return [CardLayoutPlugin(this.editor), createCardLayoutResizingPlugin()]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, { class: 'card-layout-item' }),
      0,
    ]
  },
})
