import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  HStack,
  VStack,
} from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/core'

import { useAppSelector } from 'modules/redux'
import { ThumbnailSkeleton } from 'modules/tiptap_editor/components/thumbnail'
import { NodeViewWrapper } from 'modules/tiptap_editor/react/NodeViewWrapper'
import {
  selectDoc,
  selectIsStatic,
  selectMode,
  selectTOCData,
} from 'modules/tiptap_editor/reducer'
import { EditorModeEnum } from 'modules/tiptap_editor/types'
import { isScreenshot } from 'utils/export'

import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { findTopCardNodeParent } from '../Card/cardNavigationUtils'
import { CardTOCItem } from './CardTOCItem'

export interface CardTOCAttributes {
  nested: boolean
}

export const CardTableOfContentsView = (nodeViewProps: NodeViewProps) => {
  const { node, editor, getPos } = nodeViewProps
  const { nested } = node.attrs as CardTOCAttributes
  const pos = getPos()
  const parentCard = findTopCardNodeParent(editor, pos)
  const cardTree = useAppSelector(
    selectTOCData({
      cardId: parentCard?.node.attrs.id,
      showAll: isScreenshot,
    })
  )

  const doc = useAppSelector(selectDoc)
  const mode = useAppSelector(selectMode)
  const isStatic = useAppSelector(selectIsStatic)
  const isPresentMode = mode === EditorModeEnum.SLIDE_VIEW

  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps} as="div">
      <Box
        cursor="default"
        pointerEvents={isStatic ? 'none' : undefined}
        borderRadius="var(--box-border-radius)"
        data-selection-ring
        className="card-toc"
      >
        {cardTree.length === 0 && (
          <Alert
            colorScheme="gray"
            color="gray.800"
            fontFamily="Inter, sans-serif"
            fontSize="1em"
            py="0.75em"
            px="1em"
          >
            <AlertIcon w="1em" h="1em" alignSelf="center" mr="0.75em" />
            <Box>
              <AlertTitle fontSize="1em" lineHeight="1.5em">
                This is the last card.
              </AlertTitle>
              <AlertDescription fontSize="0.75em" lineHeight="1.5em">
                Add more cards to generate table of content links automatically.
              </AlertDescription>
            </Box>
          </Alert>
        )}
        {cardTree.map((card) => {
          return (
            <CardTOCItem
              key={card.id}
              cardId={card.id}
              cards={card.children}
              editor={editor}
              level={0}
              showNested={nested}
              docId={doc?.id || ''}
              isPresentMode={isPresentMode}
            />
          )
        })}
      </Box>
    </AnnotatableNodeViewWrapper>
  )
}

export const CardTableOfContentsThumbnailView = (
  _nodeViewProps: NodeViewProps
) => {
  return (
    <NodeViewWrapper>
      <VStack spacing="0.5em" align="start">
        <ThumbnailSkeleton h="1em" w="14em" bg="var(--link-color)" />
        <ThumbnailSkeleton h="1em" w="14em" bg="var(--link-color)" />
        <ThumbnailSkeleton h="1em" w="14em" bg="var(--link-color)" />
        <ThumbnailSkeleton h="1em" w="14em" bg="var(--link-color)" />
      </VStack>
    </NodeViewWrapper>
  )
}
