import {
  Button,
  Text,
  Badge,
  HStack,
  ButtonProps,
  Spinner,
  Box,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React, { memo } from 'react'

const MotionBox = motion(Box)

type EmojiClusterProps = {
  emojis?: string[]
}

export const EmojiCluster: React.FC<EmojiClusterProps> = memo(({ emojis }) => {
  return (
    <>
      {emojis?.map((emojiFromCluster, index) => {
        if (index === 0)
          return (
            <MotionBox
              initial={{
                x: '6px',
                y: '5px',
              }}
              textShadow={
                emojis?.length > 1 ? '0px 1px 3px rgba(0,0,0,.4)' : 'none'
              }
              fontSize="20px"
              position="absolute"
              key={index}
              zIndex={1}
            >
              <em-emoji size={0} native={true} id={emojiFromCluster} />
            </MotionBox>
          )
        if (index === 1)
          return (
            <MotionBox
              zIndex={0}
              initial={{
                x: '10px',
                y: '6px',
                scale: 0.7,
              }}
              animate={{
                scale: 0.7,
                rotate: '10deg',
                x: '18px',
                y: '-3px',
              }}
              position="absolute"
              key={index}
            >
              <em-emoji size={0} native={true} id={emojiFromCluster} />
            </MotionBox>
          )
        if (index === 2)
          return (
            <MotionBox
              zIndex={0}
              initial={{
                x: '10px',
                y: '6px',
                scale: 0.7,
              }}
              animate={{
                scale: 0.7,
                rotate: '-10deg',
                x: '-3px',
                y: '16px',
              }}
              position="absolute"
              key={index}
            >
              <em-emoji size={0} native={true} id={emojiFromCluster} />
            </MotionBox>
          )
        if (index === 3)
          return (
            <MotionBox
              zIndex={0}
              initial={{
                x: '10px',
                y: '6px',
                scale: 0.7,
              }}
              animate={{
                scale: 0.7,
                rotate: '-10deg',
                x: '-3px',
                y: '-3px',
              }}
              position="absolute"
              key={index}
            >
              <em-emoji size={0} native={true} id={emojiFromCluster} />
            </MotionBox>
          )
        return null
      })}
    </>
  )
})
EmojiCluster.displayName = 'EmojiCluster'
