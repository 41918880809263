/**
 * This component contains the ButtonGroup and Popup
 *
 * States of this component
 *   COLLAPSED
 *   EXPANDED
 *   OPEN
 *
 * Its responsibilities are
 * 1. manage the state of which popup is shown
 * 2. manage its own state of being collapsed / expanded / open
 * 3. hiding itself and triggering to the outside doc when a click outside occurs while in open state
 */
import { useToast } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { EditorOptions, JSONContent, NodeViewProps } from '@tiptap/core'
import { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Comment } from 'modules/api'
import { EmojiObject } from 'modules/emoji'
import { EventBusEvent, TiptapEventBus } from 'modules/tiptap_editor/eventBus'
import { openParentCards } from 'modules/tiptap_editor/extensions/Card'
import {
  selectIsOtherBlockCommentOpen,
  setCommentReactionOpen,
} from 'modules/tiptap_editor/reducer'
import { useUserContext } from 'modules/user'
import { isMobileDevice } from 'utils/deviceDetection'
import { useIsHovering } from 'utils/hooks'

import { DraftComment } from '../../DraftCommentsExtension/types'
import {
  SelectionData,
  useListenForCreateCommentFromSelection,
  useListenForOpenComment,
} from '../hooks'
import { MobileAddCommentWidget } from '../MobileAddCommentWidget'
import { BlockReaction } from '../types'
import { AvatarGroupPopup } from './AvatarGroupPopup'
import { BlockCommentsButtons } from './BlockCommentsButtons/BlockCommentsButtons'
import {
  useClickOutsideToHide,
  useExpandedAndHideOthers,
} from './BlockCommentsButtons/hooks'
import { BlockCommentsWrapper } from './components/BlockCommentsWrapper'
import { EmojiToast } from './components/EmojiToast'
import { AddCommentPopup } from './popups/AddCommentPopup'
import { AddReactionPopup } from './popups/AddReactionPopup'
import { BlockCommentsPopupWrapper } from './popups/BlockCommentsPopupWrapper'
import { ReactionsOverflowPopup } from './popups/ReactionsOverflowPopup'
import { ThreadViewPopup } from './popups/ThreadViewPopup'
import { useBlockReactionUpdate } from './reactionHooks'

type PopupStates =
  | 'thread'
  | 'add-comment'
  | 'add-reaction'
  | 'reactions-overflow'

const DRAWER_HEIGHTS: { [key: string]: string } = {
  'add-comment': '350px',
  'add-reaction': '350px',
  'reactions-overflow': '350px',
}
type BlockCommentsStackProps = {
  nodeName: string
  isVisible: boolean
  editor: NodeViewProps['editor']
  getPos: NodeViewProps['getPos']
  comments: Comment[]
  reactions: BlockReaction[]
  createDraftComment: (data?: SelectionData) => void
  cleanupDraftComment: () => void
  onCommentDraftUpdate: EditorOptions['onUpdate']
  draftComment: DraftComment | null
  onCommentSave: (commentJSON: JSONContent) => void
  userCanComment: boolean
  blockAllowsCommenting: boolean
  blockCommentId: string
  enableReactions: boolean

  mobileAddCommentPos: {
    left: number
    top: number
  } | null
  setIsButtonStackHovered: (isHovered: boolean) => void
}
export const BlockCommentsStack: React.FC<BlockCommentsStackProps> = ({
  nodeName,
  isVisible,
  editor,
  getPos,
  comments,
  reactions,
  createDraftComment,
  cleanupDraftComment,
  onCommentDraftUpdate,
  draftComment,
  onCommentSave,
  userCanComment,
  blockAllowsCommenting,
  blockCommentId,
  enableReactions,
  mobileAddCommentPos,
  setIsButtonStackHovered,
}) => {
  const toast = useToast()
  const isMobile = isMobileDevice()
  const dispatch = useDispatch()
  const { user, isUserLoading } = useUserContext()
  const { isHovering, delayedClose, ...hoverHandlers } = useIsHovering({
    enterDelay: 30,
    leaveDelay: 100,
  })
  const isHovered = Boolean(isHovering)
  const buttonRef = useRef<HTMLDivElement | null>(null)

  const commentsPanelRef = useRef<any>()

  const isOtherCommentOpen = useSelector(
    selectIsOtherBlockCommentOpen(blockCommentId)
  )

  const popupRef = useRef<HTMLDivElement | null>(null)
  const [popup, setPopup] = useState<PopupStates | null>(null)
  const isPopupOpen = popup !== null
  const [viewingCommentId, setViewingCommentId] = useState<string | null>(null)
  const viewingComment: Comment | null =
    comments.find((c) => c.id === viewingCommentId) || null

  const showThreadView = useCallback(
    (comment: { id: string }) => {
      setPopup('thread')
      setViewingCommentId(comment.id)
      dispatch(
        setCommentReactionOpen({
          isOpen: true,
          blockCommentId,
        })
      )
    },
    [blockCommentId, dispatch]
  )

  const onAddComment = useCallback(
    (data?: SelectionData) => {
      setPopup('add-comment')
      setViewingCommentId(null)
      dispatch(
        setCommentReactionOpen({
          isOpen: true,
          blockCommentId,
        })
      )
      createDraftComment(data)
    },
    [blockCommentId, dispatch, createDraftComment]
  )

  const onAddReaction = useCallback(() => {
    setPopup('add-reaction')
    setViewingCommentId(null)
    dispatch(
      setCommentReactionOpen({
        isOpen: true,
        blockCommentId,
      })
    )
  }, [blockCommentId, dispatch])

  const closePopup = useCallback(
    (closeStack: boolean = false) => {
      setPopup(null)
      setViewingCommentId(null)
      cleanupDraftComment()
      dispatch(
        setCommentReactionOpen({
          isOpen: false,
          blockCommentId,
        })
      )
      if (closeStack) {
        delayedClose(1000)
      }
    },
    [blockCommentId, cleanupDraftComment, delayedClose, dispatch]
  )

  const { addReactionFn, removeReactionFn } = useBlockReactionUpdate({
    editor,
    getPos,
    reactions,
  })
  const onSelectReaction = useCallback(
    (data: EmojiObject) => {
      if (!userCanComment) {
        return
      }
      addReactionFn({
        emoji: data.id,
      })
      toast({
        title: <EmojiToast emoji={data.id} isMobile={isMobile} />,
        status: 'success',
        duration: 5000,
        isClosable: false,
        position: isMobile ? 'bottom' : 'top',
      })
      closePopup(true)
    },
    [addReactionFn, closePopup, isMobile, toast, userCanComment]
  )

  const onCreateNewComment = useCallback(
    (commentJSON: JSONContent) => {
      onCommentSave(commentJSON)
      closePopup(true)
    },
    [onCommentSave, closePopup]
  )

  const onClickExistingReaction = useCallback(
    (blockReaction: BlockReaction) => {
      if (!userCanComment) {
        return
      }
      const existingReaction = blockReaction.reactions.find((r) =>
        r.users?.find((u) => u!.id === user?.id)
      )

      if (existingReaction) {
        removeReactionFn({
          reaction: existingReaction,
        })
      } else {
        addReactionFn({
          emoji: blockReaction.emoji,
        })
      }
    },
    [addReactionFn, removeReactionFn, user, userCanComment]
  )

  const onClickReactionsOverflow = useCallback(() => {
    setPopup('reactions-overflow')
    setViewingCommentId(null)
    dispatch(
      setCommentReactionOpen({
        isOpen: true,
        blockCommentId,
      })
    )
  }, [blockCommentId, dispatch])

  useClickOutsideToHide({
    isOpen: isPopupOpen,
    popupRef,
    onClose: closePopup,
  })

  // determines if the button stack is considered expanded, based on
  // what buttons are available to show and if its being hovered
  // also adds the class `is-taking-action` to body which will hide
  // other ButtonStacks when this one is expanded
  const { isExpanded } = useExpandedAndHideOthers({
    popup,
    isMobile,
    isHovered,
    comments,
    reactions,
    draftComment,
    enableReactions,
  })

  useEffect(() => {
    if (isMobile) {
      return
    }
    // Alert the parent so that we can highlight the node
    setIsButtonStackHovered(isHovered)
  }, [isHovered, isMobile, setIsButtonStackHovered])

  // hook to listen for creating a new comment via TextFormattingMenu "Add Comment" button
  useListenForCreateCommentFromSelection(getPos, onAddComment)

  // hook to listen to OPEN_COMMENT_POPUP events triggered from:
  // 1. clicking a neww comment notification
  // 2. when there is a commentId in the URL
  const commentIdToHighlight = useListenForOpenComment({
    comments,
    blockAllowsCommenting,
    showComment: useCallback(
      (parentCommentId: string) => {
        openParentCards({ pos: getPos(), editor })
        showThreadView({ id: parentCommentId })
      },
      [editor, getPos, showThreadView]
    ),
  })

  // handle notifying other components on the event bus that a comment popup was toggled
  useEffect(() => {
    TiptapEventBus.emit(EventBusEvent.POPUP_COMMENT_TOGGLED, {
      open: isPopupOpen,
    })
  }, [isPopupOpen])

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closePopup()
      }
    },
    [closePopup]
  )

  if (!isVisible || isUserLoading) {
    return null
  }

  const isLoggedInButCantComment = !!user && !userCanComment
  const isEmpty = comments.length === 0 && reactions.length === 0

  // In the cases where `isLoggedInButCantComment`, don't show any BlockReaction
  // controls on hover (but still show if we have existing comments/reactions)
  if ((isLoggedInButCantComment || !blockAllowsCommenting) && isEmpty) {
    return null
  }

  const hideAddCommentButton =
    isLoggedInButCantComment || !blockAllowsCommenting

  return (
    <>
      {isMobile && mobileAddCommentPos && (
        <MobileAddCommentWidget
          onClickAddComment={onAddComment}
          onClickAddReaction={onAddReaction}
          top={mobileAddCommentPos.top}
          left={mobileAddCommentPos.left}
        />
      )}
      <BlockCommentsWrapper
        nodeName={nodeName}
        className={cx(
          'block-comments-stack',
          isExpanded && 'is-expanded',
          isExpanded && 'is-taking-action',
          isEmpty && 'is-empty',
          !isMobile && isOtherCommentOpen && 'other-comment-open'
        )}
        isMobile={isMobile}
        isEmpty={isEmpty}
        isPopupOpen={isPopupOpen}
        {...hoverHandlers}
        buttonRef={buttonRef}
        onKeyDown={onKeyDown}
        hasCommentsOrReactions={!isEmpty}
      >
        <BlockCommentsButtons
          isExpanded={isExpanded}
          isMobile={isMobile}
          user={user}
          userCanComment={userCanComment}
          blockAllowsCommenting={blockAllowsCommenting}
          draftComment={draftComment}
          reactions={reactions}
          comments={comments}
          onClickThread={showThreadView}
          onClickAddComment={onAddComment}
          onClickAddReaction={onAddReaction}
          onClickExistingReaction={onClickExistingReaction}
          onClickReactionsOverflow={onClickReactionsOverflow}
          hideAddCommentButton={hideAddCommentButton}
          onClosePopup={closePopup}
          viewingCommentId={viewingCommentId}
          enableReactions={enableReactions}
        />
        {!isPopupOpen && !isMobile && (
          <AvatarGroupPopup
            isExpanded={isExpanded}
            comments={comments}
            reactions={reactions}
          />
        )}

        {isPopupOpen && (
          <BlockCommentsPopupWrapper
            isMobile={isMobile}
            ref={popupRef}
            closePopup={closePopup}
            drawerMinHeight={DRAWER_HEIGHTS[popup]}
          >
            {popup === 'thread' && (
              <ThreadViewPopup
                isMobile={isMobile}
                comment={viewingComment}
                closePopup={closePopup}
                editor={editor}
                getPos={getPos}
                commentIdToHighlight={commentIdToHighlight}
                commentsPanelRef={commentsPanelRef}
                userCanComment={userCanComment}
                blockAllowsCommenting={blockAllowsCommenting}
              />
            )}

            {popup === 'add-comment' && (
              <AddCommentPopup
                isMobile={isMobile}
                onCancel={() => {
                  closePopup()
                }}
                editor={editor}
                getPos={getPos}
                commentIdToHighlight={commentIdToHighlight}
                userCanComment={userCanComment}
                blockAllowsCommenting={blockAllowsCommenting}
                onCommentSave={onCreateNewComment}
                draftComment={draftComment}
                onCommentDraftUpdate={onCommentDraftUpdate}
              />
            )}

            {popup === 'add-reaction' && (
              <AddReactionPopup
                isMobile={isMobile}
                onReactionClick={onSelectReaction}
                editor={editor}
                getPos={getPos}
              />
            )}

            {popup === 'reactions-overflow' && (
              <ReactionsOverflowPopup
                isMobile={isMobile}
                onCancel={() => {
                  closePopup()
                }}
                onClickExistingReaction={onClickExistingReaction}
                editor={editor}
                getPos={getPos}
                user={user}
                userCanComment={userCanComment}
                blockAllowsCommenting={blockAllowsCommenting}
                reactions={reactions}
              />
            )}
          </BlockCommentsPopupWrapper>
        )}
      </BlockCommentsWrapper>
    </>
  )
}
