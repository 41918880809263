import { Box, Link, List, ListItem, Portal } from '@chakra-ui/react'
import { ScreenshotPreview } from '@gamma-app/ui'
import { Editor } from '@tiptap/core'
import { memo, useCallback, useMemo } from 'react'

import placeholderBackground from 'gamma_components/placeholderBackground.svg'
import { Doc } from 'modules/api'
import { useGetCard } from 'modules/cards'
import { useAppSelector } from 'modules/redux'
import { useShouldUsePublishedVersion } from 'modules/sites/PublishingContext'
import { navigateToCardLink } from 'modules/tiptap_editor/extensions/Link/navigateToCardLink'
import { selectDoc } from 'modules/tiptap_editor/reducer'
import { createUnorderedListStyle } from 'modules/tiptap_editor/styles/listStyles'
import { CardTreeItem } from 'modules/tiptap_editor/types'
import { useLightPopover } from 'utils/hooks'
import { getRelativeOrAbsoluteUrl } from 'utils/publishing'
import { getCardUrl } from 'utils/url'

import { CARD_TITLE_FALLBACK } from '../Mention'

type CardTOCItemProps = {
  docId: Doc['id']
  cardId: string
  editor: Editor
  level: number
  showNested: boolean
  cards: CardTreeItem[]
  isPresentMode: boolean
}

export const CardTOCItem = memo(
  ({
    cardId,
    docId,
    editor,
    cards,
    level,
    showNested,
    isPresentMode,
  }: CardTOCItemProps) => {
    const cardData = useGetCard(cardId)
    const doc = useAppSelector(selectDoc)

    const {
      referenceRef,
      popperRef,
      isHovering,
      getPopperProps,
      onMouseOver,
      onMouseOut,
    } = useLightPopover({ placement: 'top' })

    const handleNavigateToCard = useCallback(
      (event: React.MouseEvent) => {
        navigateToCardLink(editor, event, cardId)
      },
      [editor, cardId]
    )

    const isPublished = useShouldUsePublishedVersion()
    const cardUrl = useMemo(() => {
      const fullCardUrl = getCardUrl({ cardId, docId, isPresentMode })
      return isPublished
        ? getRelativeOrAbsoluteUrl({ url: fullCardUrl, doc })
        : fullCardUrl
    }, [cardId, docId, doc, isPresentMode, isPublished])
    const { listStyle, paddingLeft, marginLeft } =
      createUnorderedListStyle(level)

    if (!cardData) return null

    return (
      <>
        <List
          mb="0.5em" // from listStyles.ts
        >
          <ListItem listStyleType={listStyle} pl={paddingLeft} ml={marginLeft}>
            <Box
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              display="inline"
            >
              <Link
                href={cardUrl}
                className="link"
                role="link"
                isExternal={!isPublished}
                noOfLines={1}
                wordBreak="break-word"
                ref={referenceRef}
                onClick={handleNavigateToCard}
                // These two properties below are included so that Safari
                // will display the bullet points when using `noOfLines`
                display="-webkit-inline-box"
                verticalAlign="top"
              >
                {cardData?.title || CARD_TITLE_FALLBACK}
              </Link>
              {isHovering && (
                <Portal>
                  <ScreenshotPreview
                    shouldShow={isHovering}
                    src={cardData?.previewUrl}
                    fallbackSrc={placeholderBackground.src}
                    ref={popperRef}
                    onClick={handleNavigateToCard}
                    py={2}
                    {...getPopperProps()}
                    minH="158px"
                  />
                </Portal>
              )}
            </Box>
          </ListItem>
        </List>
        {showNested &&
          cards.map((card) => (
            <CardTOCItem
              cardId={card.id}
              key={card.id}
              cards={card.children}
              editor={editor}
              level={level + 1}
              showNested={showNested}
              docId={docId}
              isPresentMode={isPresentMode}
            />
          ))}
      </>
    )
  }
)

CardTOCItem.displayName = 'CardTOCItem'
