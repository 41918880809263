import { Box, Flex, SimpleGrid } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'
import { memo } from 'react'

import { IconProvider } from 'modules/media/providers/Icon'
import { getThemeBase } from 'modules/theming/themeBases'
import {
  ImageShapeOption,
  ImageShapeStyles,
} from 'modules/tiptap_editor/extensions/SmartLayout/options/ImageShapeOptions'

import { getAlignStyles } from '../../HorizontalAlign/HorizontalAlign'
import { HorizontalAlignment } from '../../HorizontalAlign/types'
import { AccessoryImage } from '../../media/Image/AccessoryImage'
import { useCellColor } from '../components/hooks'
import { EmptyCellContent } from '../constants'
import {
  CellSizeOption,
  getCellSizes,
  useNumColumns,
} from '../options/CellSizeOption'
import { ColorOption } from '../options/ColorOption'
import { HorizontalAlignOption } from '../options/HorizontalAlignOption'
import { ShapeSize, ShapeSizeOption } from '../options/ShapeSizeOption'
import { ImageShape } from '../options/types'
import {
  SmartLayoutCellComponent,
  SmartLayoutVariant,
  SmartLayoutWrapperComponent,
} from '../types'

export const ImagesWithTextWrapper: SmartLayoutWrapperComponent = memo(
  ({ children, options, numCells, containerSize }) => {
    const numCol = useNumColumns({ containerSize, options, numCells })
    return (
      <SimpleGrid
        columns={numCol.columns}
        rowGap="3em"
        columnGap="1.5em"
        data-selection-ring="padded"
        pos="relative"
        borderRadius="var(--box-border-radius)"
      >
        {children}
      </SimpleGrid>
    )
  }
)
ImagesWithTextWrapper.displayName = 'ImagesWithTextWrapper'

const IMAGE_SIZES: Record<ShapeSize, number> = {
  sm: 0.4,
  md: 0.6,
  lg: 0.8,
  full: 1,
}

const ICON_SIZES: Record<ShapeSize, number> = {
  sm: 0.15,
  md: 0.2,
  lg: 0.25,
  full: 0.35,
}

export const ImageWithTextCell: SmartLayoutCellComponent = memo(
  ({
    children,
    image,
    layoutOptions,
    cellOptions,
    theme,
    numCells,
    layoutContainerSize,
    isContentEmpty,
    isLayoutFocused,
    variant,
    dragHandle,
    ...props
  }) => {
    const isHorizontalLayout = layoutOptions.imagePosition == 'left'
    const base = getThemeBase(theme)
    const imageShape: ImageShape =
      layoutOptions.imageShape ?? base.smartLayoutImageShape ?? 'square'
    const shapeStyles = ImageShapeStyles[imageShape]

    const isIconLayout = variant.key == IconsWithText.key
    const isIcon = image?.source == IconProvider.key
    const imageSize =
      // Previously, we had a 0-1 slider for images. This is deprecated now
      !layoutOptions.shapeSize && layoutOptions.imageSize && !isIcon
        ? layoutOptions.imageSize
        : isIcon || isIconLayout
        ? ICON_SIZES[layoutOptions.shapeSize || 'md']
        : IMAGE_SIZES[layoutOptions.shapeSize || 'full']
    const isTransparent = image?.meta?.has_transparency
    // For old (pre-July 2023) decks, we had center alignment, so we want to preserve those
    // For new ones, this will always be defined from the variant's defaultOptions
    const horizontalAlign: HorizontalAlignment =
      layoutOptions.horizontalAlign ?? 'center'
    const alignStyles = getAlignStyles(horizontalAlign)
    const { color } = useCellColor(layoutOptions, cellOptions)
    const { columns } = useNumColumns({
      containerSize: layoutContainerSize,
      options: layoutOptions,
      numCells,
    })
    const isFullWidth = columns === 1
    const isSmallSize = imageSize <= 0.4

    const maxImageWidth = isSmallSize
      ? // Prevent icons scaling with the column width, they'll always be the same size
        `min(100%, ${getCellSizes().sm.width * imageSize}em)`
      : isFullWidth
      ? // Prevent images from getting super tall when they're a single column
        `min(100%, ${getCellSizes().xl.width * imageSize}em)`
      : '100%'

    const imageSx = isIcon // For icons, always do square
      ? {
          'svg path': {
            fill: color
              ? color
              : `url(#theme-accent-gradient-linear) var(--accent-color)`,
          },
        }
      : isTransparent // For transparent we don't want borders/shadows but we do want the shape
      ? { ...shapeStyles }
      : { ...(base.smartLayoutImageSx || base.imageSx), ...shapeStyles }

    return (
      <Flex
        borderRadius="var(--box-border-radius)"
        gap={
          isContentEmpty && !isLayoutFocused
            ? 0
            : isSmallSize
            ? '1em'
            : '1.25em'
        }
        direction={isHorizontalLayout ? 'row' : 'column'}
        align="center"
        justify="center"
        sx={alignStyles}
        data-selection-ring="padded"
        data-content-reference
        pos="relative"
      >
        <Box
          data-smart-layout-image-drop-target="true"
          className={cx('image')}
          w={`${imageSize * 100}%`}
          maxW={maxImageWidth}
          overflow="hidden"
          sx={imageSx}
        >
          <AccessoryImage image={image} {...props} />
        </Box>
        <Box
          minW={isHorizontalLayout ? '8em' : '100%'}
          maxW="100%"
          flex="1"
          sx={isHorizontalLayout ? getAlignStyles('left') : undefined}
          data-content-empty={isContentEmpty}
        >
          {children}
        </Box>
        {dragHandle}
      </Flex>
    )
  }
)
ImageWithTextCell.displayName = 'ImageWithTextCell'

export const ImagesWithText: SmartLayoutVariant = {
  key: 'imagesText',
  name: () => t`Images with text`,
  commandName: () => t`Images with text layout (blank)`,
  keywords: ['team', 'caption', 'images', 'text', 'layout'],
  icon: regular('address-card'),
  options: [
    {
      ...CellSizeOption,
      getDefaultOnParse() {
        // Always default to 4 columns
        return getCellSizes().sm.width
      },
    },
    {
      ...ShapeSizeOption,
      icon: regular('image'),
      name: () => t`Image size`,
    },
    ImageShapeOption,
    HorizontalAlignOption,
  ],
  cellOptions: [],
  Wrapper: ImagesWithTextWrapper,
  Cell: ImageWithTextCell,
  defaultContent: EmptyCellContent,
  addDirection(options) {
    return options.imagePosition === 'left' ? 'bottom' : 'right'
  },
  htmlTag: 'images',
  defaultOptions: {
    horizontalAlign: 'left',
    imageShape: 'landscape',
  },
}

export const IconsWithText: SmartLayoutVariant = {
  name: () => t`Icons with text`,
  commandName: () => t`Icons with text layout (blank)`,
  key: 'iconsText',
  icon: regular('icons'),
  keywords: ['icons', 'text', 'layout'],
  options: [
    ColorOption,
    {
      ...CellSizeOption,
      getDefaultOnParse() {
        // Always default to 4 columns
        return getCellSizes().sm.width
      },
    },
    {
      ...ShapeSizeOption,
      icon: regular('image'),
      name: () => t`Icon size`,
    },
    HorizontalAlignOption,
  ],
  cellOptions: [ColorOption],
  Wrapper: ImagesWithTextWrapper,
  Cell: ImageWithTextCell,
  addDirection(options) {
    return options.imagePosition === 'left' ? 'bottom' : 'right'
  },
  defaultOptions: {
    horizontalAlign: 'left',
  },
  defaultAttrs: {
    image: {
      source: IconProvider.key,
    },
  },
  htmlTag: 'icons',
}
