import { Flex, Spacer, StackProps, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

type CardStyleRowProps = {
  icon: any
  label: React.ReactNode
  children: React.ReactNode
} & StackProps

export const CardStyleRow = ({
  icon,
  label,
  children,
  ...rest
}: CardStyleRowProps) => {
  return (
    <Flex alignItems="center" {...rest}>
      <FontAwesomeIcon fixedWidth icon={icon} />
      <Text ml={2}>{label}</Text>
      <Spacer />
      {children}
    </Flex>
  )
}
