import { Box, Button, ButtonProps } from '@chakra-ui/react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Trans } from '@lingui/macro'
import { DecorationWithType, Editor } from '@tiptap/core'
import { useCallback } from 'react'

import { eventEmitter } from 'modules/card_notes/eventEmitter'
import { useGetColorsForDragHandle } from 'modules/tiptap_editor/extensions/DragDrop/ContainerDragHandle/useGetColorsForDragHandle'

import { CARD_DRAG_HANDLE_HEIGHT } from '../Card2/constants'
import { findCardPluginDecoration } from '../CardPlugin'
import { addCardNotes, isSelectionWithinTopCardAtPos } from './helpers'

type CardNotesButtonProps = {
  decorations: DecorationWithType[]
  editor: Editor
  getPos: () => number
} & ButtonProps

export const CardNotesButton = ({
  decorations,
  editor,
  getPos,
  ...buttonProps
}: CardNotesButtonProps) => {
  const onCardNotesButtonClick = useCallback(() => {
    const { hasCardNotes } = findCardPluginDecoration(decorations)
    const cardPos = getPos()
    if (!hasCardNotes) {
      addCardNotes(editor, cardPos)
    }

    // Make sure there is a selection in the card
    if (!isSelectionWithinTopCardAtPos(editor, cardPos)) {
      editor.commands.selectInsideNodeAtPos(cardPos)
    }

    eventEmitter.emit('toggle', {})
  }, [decorations, editor, getPos])

  const { color, bg, hoverColor, hoverBg, borderColor } =
    useGetColorsForDragHandle()

  return (
    <GammaTooltip
      label={
        <Trans
          comment={`Card notes are notes created by the user for a card and will function like Powerpoint "speaker notes"`}
        >
          Add card notes
        </Trans>
      }
      placement="top"
    >
      <Button
        height={CARD_DRAG_HANDLE_HEIGHT}
        fontWeight="900"
        alignItems="center"
        fontFamily="Inter, sans-serif"
        display="flex"
        backdropFilter="blur(8px)"
        borderRadius="sm"
        borderColor={borderColor}
        color={color}
        boxShadow="sm"
        cursor="pointer"
        fontSize="14px"
        bg={bg}
        px={0.5}
        _hover={{
          color: hoverColor,
          background: hoverBg,
        }}
        onClick={onCardNotesButtonClick}
        {...buttonProps}
      >
        <Box as="span" display="inline-flex" mr={1}>
          <FontAwesomeIcon icon={solid('memo-pad')} />
        </Box>
      </Button>
    </GammaTooltip>
  )
}
