import { gql } from '@apollo/client'

export const DEFAULT_THEME_FIELDS_FRAGMENT = gql`
  fragment DefaultThemeFields on Theme {
    id
    workspaceId
    name
    headingFont
    headingFontWeight
    bodyFont
    bodyFontWeight
    accentColor
    logoUrl
    config
    priority
    archived
    fonts {
      id
      name
      url
    }
    createdTime
    updatedTime
  }
`

export const THEME_GQL = gql`
  ${DEFAULT_THEME_FIELDS_FRAGMENT}
  mutation CreateTheme($input: CreateThemeInput!) {
    createTheme(input: $input) {
      ...DefaultThemeFields
      docCount
    }
  }

  mutation UpdateTheme($input: UpdateThemeInput!) {
    updateTheme(input: $input) {
      ...DefaultThemeFields
      docCount
    }
  }

  mutation DuplicateTheme($id: ID!) {
    duplicateTheme(id: $id) {
      ...DefaultThemeFields
    }
  }

  query GetTheme($id: ID) {
    theme(id: $id) {
      ...DefaultThemeFields
      docCount
      siteCount
      createdBy {
        id
        displayName
        email
        profileImageUrl
      }
    }
  }

  query GetThemes($workspaceId: ID, $archived: Boolean) {
    themes(workspaceId: $workspaceId, archived: $archived) {
      ...DefaultThemeFields
      createdBy {
        id
        displayName
        email
        profileImageUrl
      }
    }
  }

  mutation ArchiveTheme($id: ID!) {
    archiveTheme(id: $id) {
      ...DefaultThemeFields
      docCount
      siteCount
    }
  }

  mutation UnarchiveTheme($id: ID!) {
    unarchiveTheme(id: $id) {
      ...DefaultThemeFields
    }
  }
`
