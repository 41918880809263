import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { t, Trans } from '@lingui/macro'

import { SharePanelViews } from 'modules/sharing/components'

export const CHANNELS_ICON = regular('folder')
export const CHANNELS_ICON_ACTIVE = solid('folder-open')

export const getPublicAccessTooltipLabel = () =>
  t`They don't need a Gamma account to view this 👍.`

type SharePanelOption = {
  ariaLabel: string
  label: JSX.Element
  icon: FontAwesomeIconProps['icon']
  viewKey: SharePanelViews
  isBeta?: boolean
}

const SHARE_PANEL_OPTIONS = (): {
  [key in SharePanelViews]: SharePanelOption
} => ({
  invite: {
    ariaLabel: t`Invite others`,
    label: <Trans>Invite others</Trans>,
    icon: regular('user-plus'),
    viewKey: 'invite',
  },
  public: {
    ariaLabel: t`Share publicly`,
    label: <Trans>Share publicly</Trans>,
    icon: regular('earth-americas'),
    viewKey: 'public',
  },
  export: {
    ariaLabel: t`Export`,
    label: <Trans>Export</Trans>,
    icon: regular('arrow-down-to-bracket'),
    viewKey: 'export',
  },
  embed: {
    ariaLabel: t`Embed`,
    label: <Trans>Embed</Trans>,
    icon: regular('code'),
    viewKey: 'embed',
  },
  website: {
    ariaLabel: t`Website`,
    label: <Trans>Website</Trans>,
    icon: regular('globe'),
    viewKey: 'website',
    isBeta: true,
  },
})

const ORDERED_SHARE_PANEL_OPTIONS = [
  'invite',
  'public',
  'export',
  'embed',
  'website',
]

export const getOrderedSharePanelOptions = ({
  showSharePublic = true,
  showEmbed = true,
  showWebsites = false,
}: {
  showSharePublic?: boolean
  showEmbed?: boolean
  showWebsites?: boolean
}) => {
  const options = ORDERED_SHARE_PANEL_OPTIONS.map(
    (viewKey) => SHARE_PANEL_OPTIONS()[viewKey]
  )
  return options.filter((option) => {
    if (option.viewKey === 'public') {
      return showSharePublic
    }
    if (option.viewKey === 'embed') {
      return showEmbed
    }
    if (option.viewKey === 'website') {
      return showWebsites
    }
    return true
  })
}
