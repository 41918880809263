import {
  compareDesc,
  differenceInWeeks,
  format,
  formatRelative,
  isToday,
  isYesterday,
  parseISO,
  subDays,
} from 'date-fns'

import { Comment } from 'modules/api'
import { getLocaleForDateFns } from 'modules/i18n/utils/date'

export const normalizeDate = (date: string) => {
  try {
    const locale = getLocaleForDateFns()
    const parsed = parseISO(date)
    const sub = subDays(parsed, 0)
    if (isToday(parsed) || isYesterday(parsed)) {
      return formatRelative(sub, new Date(), { locale })
    }
    return `${format(parsed, 'PPp', { locale })}`
  } catch (e) {
    console.error('[normalizeDate] Error normalizing date:', e)
    return 'date unknown'
  }
}

interface GroupedItems<T> {
  today: T[]
  thisWeek: T[]
  earlier: T[]
}

type SortByTime = 'createdTime' | 'updatedTime'

const getLatestReply = (replies: Comment[]): Comment | null => {
  return replies?.reduce((acc: Comment, item: Comment) => {
    if (!acc) {
      return item
    }
    if (item.updatedTime > acc?.updatedTime) {
      return item
    }
    return acc
  }, null)
}

export const getLatestUpdatedCommentOrReplyTime = (comment) => {
  const latestReply = getLatestReply(comment.replies)
  if (!latestReply) return comment.updatedTime
  return comment.updatedTime > latestReply?.updatedTime
    ? comment.updatedTime
    : latestReply.updatedTime
}

export const sortByLatestCommentOrReply = (a, b) => {
  // For comments, we need to get the latest updatedTime for a comment OR its replies and
  // use that to group.
  const latestReplyA = getLatestReply(a.replies)
  const latestReplyB = getLatestReply(b.replies)
  const aTime = latestReplyA ? latestReplyA.updatedTime : a.updatedTime
  const bTime = latestReplyB ? latestReplyB.updatedTime : b.updatedTime
  if (aTime > bTime) {
    return -1
  }
  if (bTime > aTime) {
    return 1
  }
  return 0
}

const DEFAULT_SORTBY_FIELD = 'createdTime'
export const groupByTodayThisWeekAndMore = <T>({
  items,
  sortBy = DEFAULT_SORTBY_FIELD,
  latestTimeGetterFn,
}: {
  items: T[]
  sortBy?: SortByTime | ((a: T, b: T) => number)
  latestTimeGetterFn?: (item: T) => string
}): GroupedItems<T> => {
  const initialGroup: GroupedItems<T> = {
    today: [],
    thisWeek: [],
    earlier: [],
  }
  return items
    .slice()
    .sort((a, b) => {
      if (typeof sortBy === 'function') {
        return sortBy(a, b)
      }
      return compareDesc(parseISO(a[sortBy]), parseISO(b[sortBy]))
    })
    .reduce((result, item) => {
      const time = latestTimeGetterFn
        ? parseISO(latestTimeGetterFn(item))
        : parseISO(item[DEFAULT_SORTBY_FIELD])
      const today = new Date()
      if (isToday(time)) {
        result.today = result.today.concat(item)
      } else if (differenceInWeeks(today, time) < 1) {
        result.thisWeek = result.thisWeek.concat(item)
      } else {
        result.earlier = result.earlier.concat(item)
      }
      return result
    }, initialGroup)
}
