import { TaskEditorContext } from 'modules/ai/chat/Task'
import { BackendChatCompletionPrompt } from 'modules/ai/prompt/BackendChatCompletionPrompt'
import { ChatOperation } from 'modules/api'

import { SuggestContentTask } from '../definitions/SuggestContentTask'

export const changeContentLayoutPrompt = new BackendChatCompletionPrompt({
  systemPromptOperation: ChatOperation.ChangeContentLayoutSystem,
  userPromptOperation: ChatOperation.ChangeContentLayoutUser,
  variables: ['html', 'message'],
  params: {
    temperature: 0.7,
    maxTokens: 1000,
  },
})

export const changeContentLayoutTask = new SuggestContentTask({
  key: 'CHANGE_LAYOUT',
  description:
    'Change the display of information to use a different layout (like timeline, bullets, boxes, columns, table) or rearrange elements within a layout',
  classifyExamples: [
    'turn this into a table',
    'make each bullet into a box',
    'could you split this into columns?',
    'make this a timeline',
    'could you make this more visual?',
    'visualize this as a timeline',
  ],
  classifyReply: `Sure, I'll start reformatting this as a table...`,
  getTaskTarget: (editorContext: TaskEditorContext) => {
    // defaultRange is either the block or the whole card
    if (editorContext.selectedDefaultRange !== 'selectionRange') {
      return editorContext.defaultRange
    }

    return editorContext.selectionRange.html.trim().length === 0
      ? // if has a text selection thats trimmed empty then use the entire card
        editorContext.cardContent
      : // if has a text selection use blockRange
        editorContext.blockRange
  },
  prompt: changeContentLayoutPrompt,
})
