import { Editor, NodeViewProps } from '@tiptap/core'
import React, { useCallback, useState } from 'react'

import { UnsplashProvider } from 'modules/media'
import { ACCENT_IMAGE_SOURCE_KEY } from 'modules/theming'
import {
  MediaSourcesMap,
  MediaSourceType,
} from 'modules/tiptap_editor/extensions/media/MediaSources'

import { BackgroundOptions } from '../../styles/types'
import { BackgroundPanel } from './BackgroundPanel'
import { isValidBackgroundSource } from './backgroundUtils'

export type EditorBackgroundPanelProps = {
  editor?: Editor
  updateNestedAttributes: NodeViewProps['updateAttributes']
  background: BackgroundOptions
  defaultMessage: React.ReactNode
  isDark?: boolean
  isValidSource?: (s: MediaSourceType) => boolean
}

export const EditorBackgroundPanel: React.FC<EditorBackgroundPanelProps> = ({
  editor,
  updateNestedAttributes,
  background,
  defaultMessage,
  isDark,
  isValidSource,
}) => {
  const [currentSourceKey, setCurrentSourceKey] = useState(UnsplashProvider.key)
  const currentSource = MediaSourcesMap[currentSourceKey]

  const updateBackground = useCallback(
    (bg: BackgroundOptions) => {
      if (currentSource.key === ACCENT_IMAGE_SOURCE_KEY) {
        updateNestedAttributes({
          background: bg,
        })
      } else {
        // Remove accentId when the background is changed from an accent image to a different source, like image.
        // This prevents the accent image from appearing to be still selected
        // when switching back to the accent image panel.
        updateNestedAttributes({
          background: {
            ...bg,
            accentId: undefined,
          },
        })
      }
    },
    [currentSource.key, updateNestedAttributes]
  )

  const isValidBgSource = useCallback(
    (source: MediaSourceType) => {
      return isValidSource
        ? isValidSource(source) && isValidBackgroundSource(source)
        : isValidBackgroundSource(source)
    },
    [isValidSource]
  )

  return (
    <BackgroundPanel
      editor={editor}
      isDark={isDark}
      isValidSource={isValidBgSource}
      defaultMessage={defaultMessage}
      currentBackground={background}
      updateCurrentBackground={updateBackground}
      currentSourceKey={currentSourceKey}
      setCurrentSourceKey={setCurrentSourceKey}
      currentSource={currentSource}
    />
  )
}
