import { ButtonGroup } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'
import { useCallback } from 'react'
import {
  MdOutlineVerticalAlignBottom,
  MdOutlineVerticalAlignCenter,
  MdOutlineVerticalAlignTop,
} from 'react-icons/md'

import { VerticalAlign } from 'modules/tiptap_editor/extensions/Card/CardLayout/types'
import {
  EMPTY_GRID_CELL,
  MAX_COLUMNS,
} from 'modules/tiptap_editor/extensions/Layout/constants'

import { DeleteNodeButton } from '../buttons/DeleteNodeButton'
import { DuplicateNodeButton } from '../buttons/DuplicateNodeButton'
import { SelectParentButton } from '../buttons/SelectParentButton'
import { ToolbarButton } from '../buttons/ToolbarButton'
import { FormattingMenuDivider } from './FormattingMenuDivider'
import { NodeFormattingMenuProps } from './types'

export const LayoutCellFormattingMenu = ({
  editor,
  selection,
}: NodeFormattingMenuProps) => {
  const parentLayout = selection.$from.parent
  const canAddCells = parentLayout.childCount < MAX_COLUMNS
  const addColumnBefore = () => {
    editor.chain().insertContentAt(selection.from, EMPTY_GRID_CELL).run()
  }
  const addColumnAfter = () => {
    editor
      .chain()
      .insertContentAt(
        selection.from + selection.node.nodeSize,
        EMPTY_GRID_CELL
      )
      .run()
  }

  const handleDelete = () => {
    editor
      .chain()
      .selectInsideNodeAtPos(selection.from)
      .handleLayoutDelete(true, false)
      .run()
  }

  const { verticalAlign } = selection.node.attrs
  const toggleVerticalAlign = useCallback(
    (align: VerticalAlign) => {
      editor
        .chain()
        .updateAttributes('gridCell', {
          verticalAlign: align === verticalAlign ? null : align,
        })
        .selectNodeAtPos(selection.from)
        .focus()
        .run()
    },
    [editor, verticalAlign, selection]
  )

  return (
    <ButtonGroup spacing={1} size="sm" alignItems="center">
      <SelectParentButton
        editor={editor}
        label={t`Edit layout...`}
        icon={regular('columns')}
      />
      {canAddCells && (
        <>
          <FormattingMenuDivider />
          <ToolbarButton
            label={t`Add column before`}
            icon={regular('diagram-cells')}
            iconRotation={270}
            onClick={addColumnBefore}
            testId="add-col-before-button"
          />
          <ToolbarButton
            label={t`Add column after`}
            icon={regular('diagram-cells')}
            iconRotation={90}
            onClick={addColumnAfter}
            testId="add-col-after-button"
          />
          <DuplicateNodeButton editor={editor} selection={selection} />
        </>
      )}
      <ToolbarButton
        onClick={() => toggleVerticalAlign('start')}
        isActive={verticalAlign === 'start'}
        label={t`Align top`}
      >
        <MdOutlineVerticalAlignTop />
      </ToolbarButton>
      <ToolbarButton
        onClick={() => toggleVerticalAlign('center')}
        isActive={verticalAlign === 'center'}
        label={t`Align middle`}
      >
        <MdOutlineVerticalAlignCenter />
      </ToolbarButton>
      <ToolbarButton
        onClick={() => toggleVerticalAlign('end')}
        isActive={verticalAlign === 'end'}
        label={t`Align bottom`}
      >
        <MdOutlineVerticalAlignBottom />
      </ToolbarButton>
      <FormattingMenuDivider />
      <DeleteNodeButton editor={editor} handleDelete={handleDelete} />
    </ButtonGroup>
  )
}
