import {
  Button,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useToast,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { undo } from '@gamma-app/y-prosemirror'
import { Trans } from '@lingui/macro'
import { Editor } from '@tiptap/core'
import { groupBy } from 'lodash'

import { featureFlags } from 'modules/featureFlags'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import {
  RewriteCommand,
  getRewriteTypes,
  TextRewriteCommands,
} from '../../../extensions/AI/Rewrite/commands'
import { ToolbarButtonProps } from '../buttons/ToolbarButton'
import { checkCommandDisabled } from './TextFormattingCommands'
import { focusEditorOnMenuClose } from './utils'

type TextRewriteMenuProps = Omit<ToolbarButtonProps, 'onClick'> & {
  editor: Editor
}

const getTextRewriteCommandsGrouped = () =>
  Object.entries(groupBy(TextRewriteCommands, 'type'))

export const TextRewriteMenu = ({
  editor,
  testId,
  disabled,
  icon,
  label,
}: TextRewriteMenuProps) => {
  const toast = useToast()
  return (
    <Menu isLazy onClose={() => focusEditorOnMenuClose(editor)}>
      <GammaTooltip placement="top" label={label}>
        <MenuButton
          isDisabled={disabled}
          as={Button}
          variant="toolbar"
          data-testid={testId}
          onMouseDown={preventDefaultToAvoidBlur}
          rightIcon={
            <FontAwesomeIcon
              icon={regular('chevron-down')}
              transform="shrink-6"
            />
          }
        >
          {icon && <FontAwesomeIcon icon={icon} />}
        </MenuButton>
      </GammaTooltip>
      <Portal>
        <MenuList maxH="min(25em, 45vh)" overflowY="auto" maxW="450px">
          {getTextRewriteCommandsGrouped().map(([type, commands]) => {
            const { label: groupLabel, featureFlag } = getRewriteTypes()[type]
            if (featureFlag && !featureFlags.get(featureFlag)) return
            const enabledCommands = commands
              .map((command) => {
                if (!command.apply || checkCommandDisabled(editor, command))
                  return
                return (
                  <MenuItem
                    key={command.key}
                    value={command.key}
                    icon={<FontAwesomeIcon icon={command.icon} fixedWidth />}
                    onClick={() => runCommand(command, editor, toast)}
                  >
                    <Text>{command.name()}</Text>
                    {command.description && (
                      <Text color="gray.400" fontSize="sm">
                        {command.description()}
                      </Text>
                    )}
                  </MenuItem>
                )
              })
              .filter(Boolean)
            if (!enabledCommands.length) {
              return null
            }

            return (
              <MenuGroup key={type} title={groupLabel}>
                {enabledCommands}
              </MenuGroup>
            )
          })}
        </MenuList>
      </Portal>
    </Menu>
  )
}

const runCommand = (
  { apply }: RewriteCommand,
  editor: Editor,
  toast: ReturnType<typeof useToast>
) => {
  if (!apply) return
  // This could be synchronous, in which case we just return
  const applyPromise = apply(editor)
  if (!applyPromise) return

  // Otherwise, show a toast that it's in progress and when it's done/errors
  const loadingToast = toast({
    position: 'top',
    isClosable: false,
    duration: null,
    status: 'loading',
    variant: 'subtle',
    title: (
      <Trans comment="This is shown in a notification while our AI converts a string of text for users">
        Converting text...
      </Trans>
    ),
  })
  applyPromise
    .then(() => {
      toast.close(loadingToast)
      const doneToast = toast({
        title: (
          <HStack>
            <Text noOfLines={1}>
              <Trans comment="This is shown when the text conversion is complete.">
                Converted.
              </Trans>{' '}
            </Text>
            <Link
              textDecoration="underline"
              onClick={() => {
                undo(editor.state)
                toast.close(doneToast)
              }}
            >
              <Trans>Undo</Trans>
            </Link>
          </HStack>
        ),
        status: 'success',
        duration: 3000,
        position: 'top',
      })
    })
    .catch((error: Error) => {
      toast.close(loadingToast)
      toast({
        title: <Trans>Conversion failed</Trans>,
        description: error.message,
        status: 'error',
        duration: 3000,
        position: 'top',
      })
    })
}
