import { Button, Code, Flex, Image, Stack, Text } from '@chakra-ui/react'
import { Global } from '@emotion/react'
import { Trans } from '@lingui/macro'
import * as Sentry from '@sentry/nextjs'
import { Component, ReactNode } from 'react'

type ErrorBoundaryProps = {
  children: ReactNode
  isInsideDrawer?: boolean
}

type ErrorBoundaryState = {
  hasError: boolean
  errMessage: string
}

export class EditorCoreErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, errMessage: '' }
  }
  errorEventId: string | null = null

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errMessage: error.message }
  }

  componentDidCatch(
    error: Error,
    // Loosely typed because it depends on the React version and was
    // accidentally excluded in some versions.
    errorInfo?: { componentStack?: string | null }
  ) {
    this.errorEventId = Sentry.captureException(error, {
      extra: {
        info: errorInfo?.componentStack,
      },
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorBoundaryComponent
          errMessage={this.state.errMessage}
          errorId={this.errorEventId}
          isInsideDrawer={this.props.isInsideDrawer}
        />
      )
    }

    return this.props.children
  }
}

// All the places that may contain EditorCore
const editorWrapperSelectors = [
  'collaborative-editor-wrapper',
  'public-static-editor-wrapper-inner',
  'example-static-editor-wrapper-inner',
  'custom-theme-tabs',
  'custom-theme-tab-panels',
  'custom-theme-preview-editor',
]
  .map((selector) => `[data-testid="${selector}"]`)
  .join(', ')

const ErrorBoundaryComponent = ({
  errMessage,
  errorId,
  isInsideDrawer = false,
}: {
  errMessage: string
  errorId: string | null
  isInsideDrawer?: boolean
}) => {
  return (
    <Flex
      minH="100%"
      direction="column"
      data-editor-error-component
      align="center"
    >
      <Global
        styles={{
          [editorWrapperSelectors]: {
            height: '100%',
          },
          html: { height: '100%' },
          body: { height: '100%' },
        }}
      />
      <Stack
        flex={1}
        align="center"
        justify="center"
        p={6}
        spacing={isInsideDrawer ? [4, 4, 4, 6] : [6, 10, 12]}
        w={
          isInsideDrawer
            ? ['100%', '100%', '100%', 'container.sm', 'container.md']
            : ['100%', '100%', 'container.md']
        }
      >
        <Stack textAlign="center">
          <Text
            fontSize={
              isInsideDrawer ? ['xl', 'xl', '2xl'] : ['xl', '2xl', '3xl']
            }
            fontWeight="600"
            fontFamily="p22-mackinac-pro, sans-serif"
          >
            <Trans>It looks like something went wrong.</Trans>
          </Text>
          {!isInsideDrawer && (
            <Text fontSize="lg">
              <Trans>Try refreshing the page</Trans>
            </Text>
          )}
        </Stack>
        <Image
          src="https://cdn.gamma.app/zc87vhr30n8uf3n/b4b04402416f4ec8b5c1dc24f4c2a3d6/optimized/Sal-Spaceship-Riding.png"
          px={isInsideDrawer ? [4, 4, 4, 8] : [6, 6, 10]}
          maxW={isInsideDrawer ? 'min(80%, 22rem)' : 'min(100%, 24rem)'}
        />

        {errMessage && (
          <Code py={1} px={12}>
            {errMessage}
            {errorId && (
              <Trans>
                <br /> Error ID: {errorId}
              </Trans>
            )}
          </Code>
        )}
        <Stack spacing={4}>
          {!isInsideDrawer && (
            <Button
              alignSelf="center"
              variant="solid"
              size={['md', 'md', 'lg']}
              onClick={() => {
                window.location.reload()
              }}
            >
              <Trans>Refresh page</Trans>
            </Button>
          )}

          <Text size="sm" textAlign="center">
            <Trans>Reach out to support if this issue persists.</Trans>
          </Text>
        </Stack>
      </Stack>
    </Flex>
  )
}
