import { Flex, FlexProps, Image } from '@chakra-ui/react'

import { useAppSelector } from 'modules/redux'
import { isPdfExport, isPuppeteer } from 'utils/export'

import { selectMode, selectTheme } from '../../reducer'
import { EditorModeEnum } from '../../types'
import { DOC_Z_INDEXES } from './zIndexes'

const LOGO_HEIGHT = '4vh'
const LOGO_WIDTH = '10vw'

// DO NOT FUTZ WITH THESE
export const LOGO_MARGIN_PDF = 'var(--chakra-space-10)'
export const LOGO_MAX_HEIGHT_PDF = '80px'
export const LOGO_WIDTH_PDF = '80px'

export const CustomerLogo = () => {
  const theme = useAppSelector(selectTheme)
  const mode = useAppSelector(selectMode)
  const isPresentMode = mode === EditorModeEnum.SLIDE_VIEW
  const { logoUrl } = theme

  if (!logoUrl) return null

  const logoProps: FlexProps = isPdfExport
    ? {
        position: 'fixed',
        right: LOGO_MARGIN_PDF,
        top: LOGO_MARGIN_PDF,
        height: LOGO_MAX_HEIGHT_PDF,
        maxHeight: LOGO_MAX_HEIGHT_PDF,
        width: LOGO_WIDTH_PDF,
      }
    : isPresentMode
    ? {
        position: 'fixed',
        right: 'clamp(2rem, 3vw, 5rem)',
        top: '3rem',
        justify: 'end',
        align: 'start',
        width: LOGO_WIDTH,
        height: LOGO_HEIGHT,
      }
    : {
        // Uses position: sticky to always stay on screen, without scrolling away
        // position: fixed doesn't work because this needs to stay in the same stacking context as Doc.tsx
        // to go above the background but behind the cards
        // and also because this needs to work inside modals/drawers like Snapshots and Theme Editor
        position: 'sticky',
        left: '3rem',
        top: `calc(100% - ${LOGO_HEIGHT} - 4.5rem)`,
        justify: 'start',
        width: LOGO_WIDTH,
        height: LOGO_HEIGHT,
      }

  const logoZIndex = isPuppeteer // Breakpoints dont work in export
    ? DOC_Z_INDEXES.customerLogo
    : { base: undefined, xl: DOC_Z_INDEXES.customerLogo }
  return (
    <Flex
      {...logoProps}
      className="logo"
      // On small screens, let the logo go behind card edges. But on big ones,
      // make sure it's always visible, even over borderless cards and card backgrounds
      zIndex={logoZIndex}
      borderRadius="md"
    >
      <Image
        src={logoUrl}
        maxH="100%"
        maxW="100%"
        transitionProperty="width, height"
        transitionDuration="normal"
        pointerEvents="none"
        data-testid="customer-logo"
        data-customer-logo-image
        objectFit="contain"
        objectPosition={isPresentMode ? 'top right' : 'bottom left'}
      />
    </Flex>
  )
}

export const CustomerLogoMobile = () => {
  const theme = useAppSelector(selectTheme)
  const { logoUrl } = theme
  if (!logoUrl) return null
  return (
    <Flex
      w="100%"
      justify="center"
      align="center"
      mt={8}
      mb={10}
      data-id="customer-logo-wrapper"
    >
      <Image
        src={logoUrl}
        maxW="12em"
        zIndex={DOC_Z_INDEXES.customerLogo}
        maxH={LOGO_HEIGHT}
        transitionProperty="width, height"
        transitionDuration="normal"
        pointerEvents="none"
        objectFit="contain"
        objectPosition="bottom"
      />
    </Flex>
  )
}
