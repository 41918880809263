import {
  Box,
  Button,
  Center,
  Fade,
  GridItem,
  IconButton,
  SimpleGrid,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { useCallback } from 'react'

import { getBackgroundProps } from 'modules/tiptap_editor/styles/backgroundStyles'
import { BackgroundOptions } from 'modules/tiptap_editor/styles/types'
import { THUMBNAIL_RESIZE_PARAMS } from 'utils/image'

type AccentImageGridProps = {
  accentImages: BackgroundOptions[]
  isDark: boolean
  handleDelete?: (item: BackgroundOptions) => void
  handleItemClick?: (item: BackgroundOptions) => void
  // If the accent image grid is used inside the AccentImagePanel we will have currentAttributes
  currentAttributes?: Record<string, any>
}

export const AccentImageGrid = ({
  accentImages,
  isDark,
  handleDelete,
  handleItemClick,
  currentAttributes,
}: AccentImageGridProps) => {
  const onAccentDeleteClick = useCallback(
    (item: BackgroundOptions) => (e: React.MouseEvent<HTMLButtonElement>) => {
      if (!handleDelete) return
      e.stopPropagation()
      handleDelete(item)
    },
    [handleDelete]
  )

  const onAccentEditClick = useCallback(
    (item: BackgroundOptions) => () => {
      if (!handleItemClick) return
      handleItemClick(item)
    },
    [handleItemClick]
  )

  return (
    <SimpleGrid gap={2} columns={{ base: 2, '2xl': 3 }}>
      {accentImages.map((item) => {
        const backgroundProps = getBackgroundProps(
          item,
          isDark,
          THUMBNAIL_RESIZE_PARAMS
        )
        const isSelected = currentAttributes?.accentId === item.accentId
        return (
          <GridItem key={item.accentId}>
            <Box
              as={Button}
              variant="unstyled"
              backgroundColor={isDark ? 'black' : 'white'}
              cursor={handleItemClick ? 'pointer' : undefined}
              {...backgroundProps}
              opacity={1}
              width="100%"
              borderRadius="base"
              shadow="md"
              minH="100px"
              position="relative"
              role="group"
              onClick={onAccentEditClick(item)}
            >
              {handleDelete && (
                <GammaTooltip
                  label={<Trans>Delete accent image</Trans>}
                  placement="top"
                >
                  <IconButton
                    aria-label={t`Delete accent image`}
                    icon={<FontAwesomeIcon icon={regular('trash')} />}
                    onClick={onAccentDeleteClick(item)}
                    isRound
                    position="absolute"
                    top={1}
                    right={1}
                    size="sm"
                    color="red.600"
                    opacity={0}
                    _hover={{
                      // override the default button hover color
                      color: 'red.600',
                    }}
                    _groupHover={{
                      opacity: 1,
                    }}
                  />
                </GammaTooltip>
              )}

              {currentAttributes && (
                <Fade in={isSelected}>
                  <Box
                    pointerEvents="none"
                    color="white"
                    pos="absolute"
                    inset={0}
                    bg="rgba(0,0,0,.5)"
                    borderRadius="base"
                  >
                    <Center h="100%" w="100%">
                      <FontAwesomeIcon icon={regular('check')} />
                    </Center>
                  </Box>
                </Fade>
              )}
            </Box>
          </GridItem>
        )
      })}
    </SimpleGrid>
  )
}
