import { Editor, Range } from '@tiptap/core'
import cloneDeep from 'lodash/cloneDeep'
import { Slice } from 'prosemirror-model'
import {
  NodeSelection,
  Selection,
  TextSelection,
  Transaction,
} from 'prosemirror-state'

import { ApplyingAiModificationEvent } from 'modules/tiptap_editor/extensions/AI/AiModifications/AiModificationsState'
import { goToCard } from 'modules/tiptap_editor/extensions/Card'
import { findNodeAndParents } from 'modules/tiptap_editor/utils'
import { isCardNode } from 'modules/tiptap_editor/utils/nodeHelpers'

import {
  DesignPartnerModification,
  ModificationContent,
} from './DesignPartnerModification'
import {
  getModificationRange,
  getPreviewJsonFromCardId,
  modificationContentToSlice,
} from './utils'

export class ReplaceContentModification implements DesignPartnerModification {
  type = 'replace-content'
  description: string
  private rangeId: string
  private slice: Slice
  private content: ModificationContent

  constructor(
    editor: Editor,
    opts: {
      description: string
      content: ModificationContent
      rangeId: string
    }
  ) {
    this.description = opts.description
    this.rangeId = opts.rangeId
    this.content = opts.content
    this.slice = modificationContentToSlice(editor, opts.content)
  }

  isApplied(editor: Editor): boolean {
    const tr = editor.state.tr
    let appliedRange: Range | null = null
    try {
      appliedRange = this.transact(editor, tr, false)
    } catch (e) {
      console.error(
        '[isModificationActive] Could not transact modificatioon, returning false',
        e.message
      )
      return false
    }

    if (!appliedRange) {
      return false
    }

    const existingRange = getModificationRange(editor, this.rangeId)
    if (!existingRange) {
      return false
    }

    try {
      const currSlice = editor.state.doc.slice(
        existingRange.from,
        existingRange.to
      )
      const appliedSlice = tr.doc.slice(existingRange.from, existingRange.to)
      const rangesEq =
        appliedRange.from === existingRange.from &&
        appliedRange.to === existingRange.to
      // console.debug(
      //   '[AIChat ReplaceContentModification] isApplied',
      //   this.description,
      //   {
      //     currSlice,
      //     appliedSlice,
      //     rangesEq,
      //     slicesEq: currSlice.eq(appliedSlice),
      //   }
      // )
      return currSlice.eq(appliedSlice) && rangesEq
    } catch (e) {
      // [isModificationActive] Could not compare slices, returning false,
    }
    return false
  }

  getPreviewJson(editor: Editor) {
    const tr = editor.state.tr
    this.transact(editor, tr)

    const cardId = this.getParentCardId(editor, tr)

    const docJson = cloneDeep(tr.doc.toJSON())
    return getPreviewJsonFromCardId(docJson, cardId)
  }

  getParentCardId(editor: Editor, tr: Transaction) {
    const range = getModificationRange(editor, this.rangeId)
    if (!range) {
      return null
    }
    const $from = tr.doc.resolve(range.from)
    const card = findNodeAndParents($from, isCardNode)[0]
    if (!card) {
      return null
    }
    const cardId = card.node.attrs.id
    if (!cardId) {
      return null
    }
    return cardId
  }

  apply(editor: Editor): void {
    const tr = editor.state.tr
    this.transact(editor, tr)
    const range = getModificationRange(editor, this.rangeId)
    console.debug('[ReplaceContentModification] Applying modification', {
      modification: this,
      range,
    })
    editor.view.dispatch(tr)
    const cardId = this.getParentCardId(editor, tr)
    goToCard({ editor, cardId })
  }

  private transact(
    editor: Editor,
    tr: Transaction,
    setSelection = true
  ): Range | null {
    const range = getModificationRange(editor, this.rangeId)

    if (!range) {
      return null
    }
    const { state } = editor
    tr.replace(range.from, range.to, this.slice).setMeta(
      'applyingAiModification',
      <ApplyingAiModificationEvent>{
        rangeId: this.rangeId,
      }
    )

    const newTo = tr.mapping.map(range.to)
    const newRange = {
      from: range.from,
      to: newTo,
    }

    if (setSelection) {
      // If it was an empty selection, just keep it where it was
      if (state.selection.empty) {
        tr.setSelection(Selection.near(tr.doc.resolve(range.from)))
        return newRange
      }

      // If it's a node selection, preserve it
      if (state.selection instanceof NodeSelection) {
        tr.setSelection(NodeSelection.create(tr.doc, range.from))
        return newRange
      }
      // Otherwise, create a text selection
      const newSel = TextSelection.between(
        tr.doc.resolve(range.from),
        tr.doc.resolve(newTo)
      )
      tr.setSelection(newSel)
    }

    return newRange
  }

  getContentForTracking() {
    return this.content
  }
}
