import {
  AspectRatio,
  Box,
  Button,
  IconButton,
  Image as ChakraImage,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { Editor } from '@tiptap/core'
import clamp from 'lodash/clamp'
import round from 'lodash/round'
import { Node } from 'prosemirror-model'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { RelativePosition } from 'yjs'

import { BackgroundPos } from 'modules/tiptap_editor/styles/types'
import {
  absoluteToRelativePos,
  relativeToAbsolutePos,
} from 'modules/tiptap_editor/utils/relativePosition'
import { resizeAndProxyImageUrl, THUMBNAIL_RESIZE_PARAMS } from 'utils/image'

import {
  getNodeImageAttrs,
  mergeImageAttrs,
} from '../../../extensions/media/Upload/utils'

const useResponsiveBackgroundImage = (imageUrl) => {
  const [aspectRatio, setAspectRatio] = useState<number | null>(null)

  useEffect(() => {
    const calculateAspectRatio = () => {
      const img = new Image()
      img.src = imageUrl
      img.onload = () => {
        const { naturalWidth, naturalHeight } = img
        const calculatedAspectRatio = naturalWidth / naturalHeight
        setAspectRatio(calculatedAspectRatio)
      }
    }

    calculateAspectRatio()

    // Clean up the event listener
    return () => {
      setAspectRatio(null)
    }
  }, [imageUrl])

  return aspectRatio
}

// utils
const setElementBackgroundPos = (
  editor: Editor,
  pos: number,
  value: BackgroundPos | null
) => {
  const dom = editor.view.domAtPos(pos)
  if (!dom) {
    return
  }
  const { node, offset } = dom
  const el: HTMLElement = node.childNodes[offset] as HTMLElement
  const bgEl = el.querySelector('[data-change-focus-point-el]') as HTMLElement
  if (!bgEl) {
    return
  }

  bgEl.style.backgroundPosition = value ? `${value.x}% ${value.y}%` : ''
}

const getNodeBackgroundPos = (node: Node) => {
  return getNodeImageAttrs(node)?.backgroundPos
}

const updateNodeAttrs = (
  editor: Editor,
  bgPos: BackgroundPos | undefined,
  relativePos: RelativePosition | null
) => {
  if (!bgPos || !relativePos) {
    // this indicates that no dragging has been done, dont update attributes
    return
  }
  const p = relativeToAbsolutePos(editor.state, relativePos)
  if (!p) {
    console.error('ChangeFocusPoint - relative pos is null')
    return
  }
  setElementBackgroundPos(editor, p, null)
  const node = editor.state.doc.nodeAt(p)
  if (!node) {
    return
  }

  const existingImgAttrs = getNodeImageAttrs(node)
  const newAttrs = mergeImageAttrs(node, {
    ...existingImgAttrs,
    backgroundPos: bgPos,
  })
  editor.commands.updateAttributesAtPos(p, newAttrs)
}

export const useDotPopover = ({
  editor,
  pos,
  node,
}: {
  editor: Editor
  pos: number
  node: Node
}) => {
  const dotRef = useRef<HTMLDivElement | null>(null)
  const dotContainerRef = useRef<HTMLDivElement | null>(null)
  // We need to keep a RelativePosition ref because the pos of the node can change
  // while the user is dragging the dot
  const relPosRef = useRef<RelativePosition | null>(null)
  const [canReset, setCanReset] = useState(false)
  // undefined means the node attr backgroundPos is not defined, defaulting to 50 50
  const bgPosRef = useRef<BackgroundPos | undefined>()
  const setDotPosition = useCallback(
    (value: BackgroundPos | undefined) => {
      const dot = dotRef.current
      if (!dot) {
        return
      }
      // always set the ref value on load becuase undefined means we dont want to save on close
      bgPosRef.current = value

      if (!value) {
        // if there is no value then we're loading a image
        // without backgroundPos, reset the dot style but dont update the ref
        // because we dont want closing the popover to write {x: 50, y:50}
        // set the position of the dot
        dot.style.left = `calc(50% - 10px)`
        dot.style.top = `calc(50% - 10px)`
        setCanReset(false)
        return
      }

      // set the position of the dot
      dot.style.left = `calc(${value.x}% - 10px)`
      dot.style.top = `calc(${value.y}% - 10px)`
      // update the image bg temporarily
      setElementBackgroundPos(editor, pos, value)
      setCanReset(value.x !== 50 || value.y !== 50)
    },
    [editor, pos]
  )

  const { onOpen, onClose, isOpen } = useDisclosure()
  const onPopoverOpen = useCallback(() => {
    // load data into popover

    // may need to wait for image to load
    setDotPosition(getNodeBackgroundPos(node))
    onOpen()
  }, [node, onOpen, setDotPosition])

  const resetDotPosition = useCallback(() => {
    const val = { x: 50, y: 50 }
    setDotPosition(val)
    const relPos = absoluteToRelativePos(editor.state, pos)
    updateNodeAttrs(editor, val, relPos)
  }, [setDotPosition, editor, pos])

  // When ever the dot moves, update the state with the new position.
  // The position is relative to the container. Dragging the dot will
  // change the position of the dot relative to the container, and store it in state.
  // The position is a percentage of the container size, so it will work with any size image.
  const moveDotPos = useCallback(
    (e: MouseEvent) => {
      const container = dotContainerRef.current
      if (!container) {
        return
      }
      const containerRect = container.getBoundingClientRect()
      const containerWidth = containerRect.width
      const containerHeight = containerRect.height
      const x = (e.clientX - containerRect.left) / containerWidth
      const y = (e.clientY - containerRect.top) / containerHeight

      setDotPosition({
        x: round(clamp(x, 0, 1) * 100, 2),
        y: round(clamp(y, 0, 1) * 100, 2),
      })
    },
    [setDotPosition]
  )

  // Add a mouse down listener to the dot. When the mouse is down, add a mouse move listener
  // to the container. When the mouse is up, remove the mouse move listener.
  // This will cause the dot to move with the mouse.
  // Also a mouse up listener is added to the window, so that if the mouse is released outside
  // the container, the dot will stop moving.
  useEffect(() => {
    const dot = dotRef.current
    const container = dotContainerRef.current
    if (!dot || !container) {
      return
    }
    relPosRef.current = absoluteToRelativePos(editor.state, pos)
    const handleMouseDown = () => {
      container.addEventListener('mousemove', moveDotPos)
      window.addEventListener('mouseup', handleMouseUp)
    }
    const handleMouseUp = () => {
      container.removeEventListener('mousemove', moveDotPos)
      window.removeEventListener('mouseup', handleMouseUp)
      updateNodeAttrs(editor, bgPosRef.current, relPosRef.current)
    }
    dot.addEventListener('mousedown', handleMouseDown)
    return () => {
      dot.removeEventListener('mousedown', handleMouseDown)
    }
  }, [editor, moveDotPos, pos])

  // Allow clickng and dragging on the contianer itself.
  // This will cause the dot to move with the mouse.
  useEffect(() => {
    const container = dotContainerRef.current
    if (!container) {
      return
    }
    relPosRef.current = absoluteToRelativePos(editor.state, pos)
    const handleMouseDown = (e: MouseEvent) => {
      container.addEventListener('mousemove', moveDotPos)
      window.addEventListener('mouseup', handleMouseUp)
      moveDotPos(e)
    }
    const handleMouseUp = () => {
      container.removeEventListener('mousemove', moveDotPos)
      window.removeEventListener('mouseup', handleMouseUp)
      updateNodeAttrs(editor, bgPosRef.current, relPosRef.current)
    }
    container.addEventListener('mousedown', handleMouseDown)
    return () => {
      container.removeEventListener('mousedown', handleMouseDown)
    }
  }, [editor, moveDotPos, pos])

  return {
    onOpen: onPopoverOpen,
    onClose,
    isOpen,
    dotRef,
    dotContainerRef,
    canReset,
    resetDotPosition,
  }
}

type ChangeFocusPointProps = {
  editor: Editor
  pos: number
  node: Node
}

export const ChangeFocusPoint: React.FC<ChangeFocusPointProps> = ({
  editor,
  pos,
  node,
}) => {
  const {
    onOpen,
    onClose,
    isOpen,
    dotRef,
    dotContainerRef,
    canReset,
    resetDotPosition,
  } = useDotPopover({
    editor,
    pos,
    node,
  })

  const imgAttrs = getNodeImageAttrs(node)
  const src = imgAttrs?.src || imgAttrs?.tempUrl
  const aspectRatio = useResponsiveBackgroundImage(src)

  return (
    <Popover
      strategy="absolute"
      placement="top"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <GammaTooltip label={<Trans>Change focus point</Trans>}>
        {/* Tooltip and PopoverTrigger cannot share the same element.  This is an issue with popper */}
        <Box my={1}>
          <PopoverTrigger>
            <IconButton
              variant="toolbar"
              size="sm"
              icon={<FontAwesomeIcon icon={regular('crosshairs-simple')} />}
              boxSize={7}
              p={0}
              my={0}
              aria-label={t`Change focus point`}
            />
          </PopoverTrigger>
        </Box>
      </GammaTooltip>
      <PopoverContent>
        <PopoverBody p={1}>
          <Box ref={dotContainerRef} userSelect="none" position="relative">
            <Box
              ref={dotRef}
              borderRadius="full"
              boxSize={5}
              pos="absolute"
              zIndex="overlay"
              border="2px solid"
              borderColor="gray.100"
              pointerEvents="none"
              boxShadow={'0 0 0 2px rgba(0,0,0,.1)'}
              bg="white"
            />
            {src && (
              <AspectRatio
                maxW="300px"
                ratio={aspectRatio || 1}
                position="relative"
              >
                <ChakraImage
                  pointerEvents="none"
                  src={resizeAndProxyImageUrl(
                    src,
                    THUMBNAIL_RESIZE_PARAMS,
                    imgAttrs.meta
                  )}
                  fallbackSrc={src}
                  objectFit="cover"
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                />
              </AspectRatio>
            )}
            <Button
              opacity={canReset ? 1 : 0}
              pos="absolute"
              bottom={3}
              right={3}
              variant="plain"
              size="xs"
              onClick={resetDotPosition}
            >
              <Trans>Reset</Trans>
            </Button>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
