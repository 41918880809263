import { t } from '@lingui/macro'

import {
  ColorPickerMenuSection,
  ColorPickerMenuSectionProps,
} from './ColorPickerMenuSection'
import { NamedColor } from './types'

export const getSystemColors = (): NamedColor[] => {
  return [
    { key: 'black', hex: '#000000', name: t`Black` },
    { key: 'dark-gray', hex: '#4D4D4D', name: t`Dark Gray` },
    { key: 'gray', hex: '#808080', name: t`Gray` },
    { key: 'light-gray', hex: '#CCCCCC', name: t`Light Gray` },
    { key: 'white-smoke', hex: '#F2F2F2', name: t`White Smoke` },
    { key: 'white', hex: '#FFFFFF', name: t`White` },
    { key: 'light-sun', hex: '#FCEC99', name: t`Light Sun` },
    { key: 'peach', hex: '#FFD1A7', name: t`Peach` },
    { key: 'salmon', hex: '#FAA1A1', name: t`Salmon` },
    { key: 'lavender', hex: '#D8AFF8', name: t`Lavender` },
    { key: 'baby-blue', hex: '#AFCBF8', name: t`Baby Blue` },
    { key: 'pale-green', hex: '#AEE4BD', name: t`Pale Green` },
    { key: 'gold', hex: '#F9D933', name: t`Gold` },
    { key: 'orange', hex: '#FFA44F', name: t`Orange` },
    { key: 'red', hex: '#F44444', name: t`Red` },
    { key: 'purple', hex: '#B05EF1', name: t`Purple` },
    { key: 'blue', hex: '#5E98F1', name: t`Blue` },
    { key: 'green', hex: '#5CC97B', name: t`Green` },
    { key: 'dark-goldenrod', hex: '#957D00', name: t`Dark Goldenrod` },
    { key: 'brown', hex: '#995515', name: t`Brown` },
    { key: 'maroon', hex: '#910D0D', name: t`Maroon` },
    { key: 'dark-purple', hex: '#5E208E', name: t`Dark Purple` },
    { key: 'midnight-blue', hex: '#204C8E', name: t`Midnight Blue` },
    { key: 'forest-green', hex: '#1F7135', name: t`Forest Green` },
  ]
}

export const ColorPickerSystemSection = ({
  setColor,
  currentColor,
}: Omit<ColorPickerMenuSectionProps, 'label' | 'colors'>) => {
  return (
    <ColorPickerMenuSection
      label={t`System colors`}
      colors={getSystemColors()}
      setColor={setColor}
      currentColor={currentColor}
    />
  )
}
