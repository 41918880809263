import { parseGammaDocUrl } from 'utils/publishing'

type LinkablePageData = {
  docId: string
  title?: string
}

type ExistingLink = {
  href: string
  title?: string
  docId?: string
}

// Given a site and the cards for the current doc, return the pages and cards that can be linked to
export const getLinkablePages = ({
  site,
}): { linkablePages: LinkablePageData[] } => {
  const linkablePages = site?.routes.map((r) => {
    return {
      docId: r.docId,
      title: r.doc?.title,
    }
  })

  return { linkablePages }
}

export const getExistingLink = ({
  href,
  linkablePages,
}: {
  href: string
  linkablePages: LinkablePageData[]
}): ExistingLink | null => {
  if (!href) {
    return null
  }

  const parsedUrl = parseGammaDocUrl(href)
  if (!parsedUrl)
    return {
      href,
    }

  const { docId } = parsedUrl
  const match = (docId && linkablePages?.find((p) => p.docId === docId)) || null

  return { ...match, href }
}
