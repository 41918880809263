import { Button, Flex } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'

export const AddColorButton = ({
  onClick,
  label,
}: {
  onClick: () => void
  label: string
}) => {
  return (
    <GammaTooltip label={label} placement="top">
      <Button
        display="inline-flex"
        alignSelf="center"
        boxSize="26px"
        borderRadius="md"
        border="1px solid"
        borderColor="blackAlpha.300"
        bgColor="gray.50"
        onClick={onClick}
        variant="unstyled"
        minW={0}
        fontSize="xs"
        transitionProperty="border"
        transitionDuration="normal"
        textAlign="center"
      >
        <Flex justify="center" align="center">
          <FontAwesomeIcon icon={regular('plus')} />
        </Flex>
      </Button>
    </GammaTooltip>
  )
}
