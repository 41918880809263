import { IconButton } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t } from '@lingui/macro'

import { CommentStatus } from 'modules/api'

export const getCommentStatusConfig = () => {
  return {
    [CommentStatus.Open]: {
      label: t`Mark as closed`,
      icon: regular('check-circle'),
      successMessage: t`Comment marked as closed`,
    },
    [CommentStatus.Closed]: {
      label: t`Mark as open`,
      icon: regular('dot-circle'),
      successMessage: t`Comment reopened`,
    },
  }
}

export const CommentStatusButton = ({ status, handleCommentStatusUpdate }) => {
  return (
    <GammaTooltip
      label={getCommentStatusConfig()[status].label}
      aria-label={getCommentStatusConfig()[status].label}
    >
      <IconButton
        variant="ghost"
        size="sm"
        borderRadius="full"
        isDisabled={false}
        aria-label={getCommentStatusConfig()[status].label}
        icon={<FontAwesomeIcon icon={getCommentStatusConfig()[status].icon} />}
        onClick={() => handleCommentStatusUpdate(status)}
      />
    </GammaTooltip>
  )
}
