import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useClipboard,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'

import { formatDate } from 'modules/i18n/utils/date'

interface DocumentGridItemMenuProps {
  href: string
  title: string
  createdByName?: string
  createdByEmail?: string
  createdTime: string
  isDuplicateEnabled: boolean
  isShareEnabled: boolean
  isRenameEnabled: boolean
  isTrashAndRestoreEnabled: boolean
  isRemoveFromChannelEnabled: boolean
  isTrashed: boolean
  isFavorited: boolean
  onDuplicate?: () => void
  onFavorite?: () => void
  onUnfavorite?: () => void
  onTrash?: () => void
  onPermanentDelete?: () => void
  onRestore?: () => void
  onShareClick?: () => void
  onRename?: () => void
  onRemoveFromChannel?: () => void
}

export const DocumentGridItemMenu = ({
  href,
  title,
  createdByName,
  createdByEmail,
  createdTime,
  isDuplicateEnabled,
  isRenameEnabled,
  isShareEnabled,
  isTrashed,
  isFavorited,
  isTrashAndRestoreEnabled,
  isRemoveFromChannelEnabled,
  onDuplicate,
  onFavorite,
  onUnfavorite,
  onTrash,
  onPermanentDelete,
  onRestore,
  onShareClick,
  onRename,
  onRemoveFromChannel,
}: DocumentGridItemMenuProps) => {
  const { onCopy } = useClipboard(`${window.location.origin}${href}`)
  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Menu isLazy={true} closeOnSelect={false}>
      <MenuButton
        data-dashboard-doc-menu
        as={IconButton}
        size="xs"
        variant="ghost"
        borderRadius="full"
        onClick={onOpen}
        icon={<FontAwesomeIcon fixedWidth icon={regular('ellipsis-h')} />}
        color="gray.400"
        _hover={{ color: 'gray.800', bg: 'gray.200' }}
      >
        <Trans>Actions</Trans>
      </MenuButton>
      {isOpen && (
        <Portal>
          <MenuList maxW="sm" zIndex="overlay">
            <Box px={4} py={0} wordBreak="break-word">
              <Text>{title}</Text>
              <Text fontSize="sm" color="gray.500" noOfLines={1}>
                <Trans>
                  Created {formatDate(createdTime, 'MMMM do, yyyy')}
                </Trans>
              </Text>
              {createdByEmail && createdByName && (
                <GammaTooltip label={createdByEmail}>
                  <Text
                    display="inline-block"
                    fontSize="sm"
                    color="gray.500"
                    noOfLines={1}
                  >
                    <Trans> by {createdByName}</Trans>
                  </Text>
                </GammaTooltip>
              )}
            </Box>
            {(isShareEnabled ||
              isRenameEnabled ||
              isRemoveFromChannelEnabled) && <MenuDivider />}
            {isRemoveFromChannelEnabled && (
              <MenuItem
                onClick={onRemoveFromChannel}
                icon={<FontAwesomeIcon fixedWidth icon={regular('ban')} />}
              >
                <Trans>Remove from folder</Trans>
              </MenuItem>
            )}
            {isShareEnabled && (
              <MenuItem
                onClick={onShareClick}
                icon={<FontAwesomeIcon fixedWidth icon={regular('share')} />}
              >
                <Trans>Share...</Trans>
              </MenuItem>
            )}
            {isRenameEnabled && (
              <MenuItem
                onClick={onRename}
                icon={
                  <FontAwesomeIcon fixedWidth icon={regular('pen-field')} />
                }
              >
                <Trans>Rename...</Trans>
              </MenuItem>
            )}
            <MenuDivider />

            {!onFavorite ? null : isFavorited ? (
              <MenuItem
                icon={
                  <Box color="yellow.400">
                    <FontAwesomeIcon fixedWidth icon={solid('star')} />
                  </Box>
                }
                onClick={onUnfavorite}
              >
                <Trans>Remove from favorites</Trans>
              </MenuItem>
            ) : (
              <MenuItem
                icon={<FontAwesomeIcon fixedWidth icon={regular('star')} />}
                onClick={onFavorite}
              >
                <Trans>Add to favorites</Trans>
              </MenuItem>
            )}
            {isDuplicateEnabled && Boolean(onDuplicate) && (
              <MenuItem
                icon={<FontAwesomeIcon fixedWidth icon={regular('clone')} />}
                onClick={onDuplicate}
              >
                <Trans>Duplicate</Trans>
              </MenuItem>
            )}
            <MenuItem
              icon={
                <FontAwesomeIcon fixedWidth icon={regular('share-square')} />
              }
              onClick={() => {
                onCopy()
                toast({
                  title: <Trans>URL copied.</Trans>,
                  status: 'success',
                  duration: 3000,
                  position: 'top',
                })
              }}
            >
              <Trans>Copy link</Trans>
            </MenuItem>

            {isTrashAndRestoreEnabled && <MenuDivider />}
            {isTrashAndRestoreEnabled ? (
              isTrashed ? (
                <>
                  <MenuItem
                    icon={
                      <FontAwesomeIcon fixedWidth icon={solid('rotate-left')} />
                    }
                    onClick={onRestore}
                  >
                    <Trans>Restore</Trans>
                  </MenuItem>
                  {onPermanentDelete && (
                    <>
                      <MenuDivider />
                      <MenuItem
                        color="red"
                        icon={
                          <FontAwesomeIcon
                            fixedWidth
                            icon={regular('circle-xmark')}
                          />
                        }
                        onClick={onPermanentDelete}
                      >
                        <Trans>Permanently delete</Trans>
                      </MenuItem>
                    </>
                  )}
                </>
              ) : (
                <MenuItem
                  color="red"
                  icon={<FontAwesomeIcon fixedWidth icon={regular('trash')} />}
                  onClick={() => {
                    // Hack to close the menu so we don't see a flash of other menu items
                    onClose()
                    onTrash?.()
                  }}
                >
                  <Trans>Send to trash</Trans>
                </MenuItem>
              )
            ) : null}
          </MenuList>
        </Portal>
      )}
    </Menu>
  )
}
