import { Box, BoxProps } from '@chakra-ui/react'

export const ThumbnailSkeleton = (props: BoxProps) => {
  return (
    <Box
      bg="var(--body-color)"
      opacity="0.4"
      borderRadius="sm"
      {...props}
    ></Box>
  )
}
