import { mergeAttributes, Node } from '@tiptap/core'

import {
  configureJSONAttribute,
  configureObjectAttribute,
} from 'modules/tiptap_editor/utils'

import { ReactNodeViewRenderer } from '../../react'
import {
  addBookmarkStorage,
  bookmarkNode,
  getAttrsFromBookmark,
} from '../AI/Sal/utils/bookmark'
import { attrsOrDecorationsChanged } from '../updateFns'
import { SmartLayoutCellView } from './SmartLayoutCellView'

export const SmartLayoutCell = Node.create({
  name: 'smartLayoutCell',
  content: '(block | smartLayoutBlock)+',
  isolating: true,
  selectable: false,
  containerHandle: true,

  imageKeyPath: 'image',
  accessoryImageKey: 'image',

  addAttributes() {
    return {
      options: {
        default: {},
        ...configureJSONAttribute('options'),
      },
      width: {
        default: null,
      },
      image: {
        default: null,
        ...configureJSONAttribute('image'),
      },
      label: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[class=layout-item]',
      },
      {
        tag: 'item',
      },
      {
        tag: 'div[bookmark]',
        context: 'smartLayout/',
        // content comes from the hole `0` in renderHTMLForAI
        getAttrs: getAttrsFromBookmark(this.storage),
      },
      {
        tag: 'div',
        context: 'smartLayout/',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { class: 'layout-item' }), 0]
  },

  addStorage: addBookmarkStorage,

  renderHTMLforAI({ node }) {
    return ['div', { bookmark: bookmarkNode(this.storage, node) }, 0]
  },

  addNodeView() {
    return ReactNodeViewRenderer(SmartLayoutCellView, {
      update: attrsOrDecorationsChanged,
    })
  },
})
