import { SearchType, useHealthCheck, useSearchMentionQuery } from 'modules/api'

type SearchMentionResult = ReturnType<typeof useSearchMentionQuery>
type SearchMentionResultSubset = Pick<
  SearchMentionResult,
  'data' | 'previousData' | 'error' | 'loading'
>

export function useSearchMentions(
  query: string,
  gammaOrgId: string | undefined
): SearchMentionResultSubset {
  const { isConnected } = useHealthCheck()
  const skipQuery = !query || !gammaOrgId || !isConnected

  const userResults = useSearchMentionQuery({
    variables: { workspaceId: gammaOrgId!, types: [SearchType.User], query },
    skip: skipQuery,
  })
  const docResults = useSearchMentionQuery({
    variables: { workspaceId: gammaOrgId!, types: [SearchType.Doc], query },
    skip: skipQuery,
  })

  return mergeMentions([userResults, docResults])
}

function mergeMentions(
  results: SearchMentionResult[]
): SearchMentionResultSubset {
  function mergeDataObject<K extends 'data' | 'previousData'>(
    // Target gets modified in place
    target: SearchMentionResultSubset,
    key: K,
    data: SearchMentionResult[K]
  ): void {
    if (!data) {
      return
    }

    if (!target[key]) {
      target[key] = {
        search: [],
      }
    }

    target[key]!.search = [...target[key]!.search, ...data.search]
  }

  const merged = results.reduce(
    (agg, { data, previousData, error, loading }) => {
      // If this request is still loading and we have previous data, return the
      // previous data as the result in order to prevent jumps in the UI
      if (!data && loading && previousData) {
        data = previousData
      }

      mergeDataObject(agg, 'data', data)
      mergeDataObject(agg, 'previousData', previousData)

      if (loading) {
        agg.loading = true
      }

      if (error) {
        // Keeping only a single error if there are multiple in order to
        // maintain signature compatibility. I don't like this very much
        agg.error = error
      }

      return agg
    },
    {} as SearchMentionResultSubset
  )

  return merged
}
