import { HStack, Input } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t, Trans } from '@lingui/macro'
import { Editor } from '@tiptap/core'
import { TextSelection } from 'prosemirror-state'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useAppSelector } from 'modules/redux'
import { navigateToCardLink } from 'modules/tiptap_editor/extensions/Link/navigateToCardLink'
import { isOtherSupportedProtocol } from 'modules/tiptap_editor/extensions/Link/utils'
import { TextColorMenu } from 'modules/tiptap_editor/extensions/TextColor/TextColorMenu'
import { selectCardIds } from 'modules/tiptap_editor/reducer'
import { isValidUrl, startsWithHttp } from 'utils/link'

import {
  doesMemoContainGivenCardFromUrl,
  getCardIdFromHash,
} from '../../../utils'
import { useToggleMediaDrawer } from '../../drawers/MediaDrawer/utils'
import { ToolbarButton } from '../buttons/ToolbarButton'
import { EmbedDisplayDropdown } from './EmbedVideoFormattingMenu'

type LinkFormattingMenuProps = {
  editor: Editor
  selection: TextSelection
}

export const LinkFormattingMenu = ({
  editor,
  selection,
}: LinkFormattingMenuProps) => {
  const href = editor.getAttributes('link').href || ''

  return (
    <>
      {href && <EmbedDisplayDropdown editor={editor} selection={selection} />}
      {!selection.empty && <TextColorMenu editor={editor} />}
      <LinkInput
        href={href}
        updateHref={(url) => editor.commands.updateSurroundingLink(url)}
        unlink={() => {
          editor.commands.removeSurroundingLink()
        }}
        editor={editor}
        allowEditMetadata={!isOtherSupportedProtocol(href)}
      />
    </>
  )
}

type LinkInputProps = {
  href: string
  updateHref: (url: string) => void
  unlink?: () => void
  editor: Editor
  allowEditMetadata?: boolean
}
export const LinkInput = ({
  href,
  updateHref,
  unlink,
  editor,
  allowEditMetadata = false,
}: LinkInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = useState<string>(href)
  const cardIds = useAppSelector(selectCardIds)
  const isInternalCardLink = doesMemoContainGivenCardFromUrl(href, cardIds)
  const setHref = (inputUrl: string) => {
    // Make sure it's a valid link
    if (!inputUrl) return
    const shouldPrependHttp =
      !startsWithHttp(inputUrl) && !isOtherSupportedProtocol(inputUrl)
    const url = shouldPrependHttp ? 'http://' + inputUrl : inputUrl

    if (!isValidUrl(url)) {
      updateHref('')
      return
    }
    updateHref(url)
  }

  const onClick = useCallback(() => {
    try {
      window.open(href, '_blank')
    } catch (err) {
      console.error('(caught) Link Formatting Menu error', err)
    }
  }, [href])

  const onInternalCardLinkClick = useCallback(
    (event: React.MouseEvent) => {
      if (!isInternalCardLink) return
      const cardId = getCardIdFromHash(href)
      navigateToCardLink(editor, event, cardId)
    },
    [editor, href, isInternalCardLink]
  )

  useEffect(() => {
    if (!href && inputRef.current) {
      // Need to wrap in setTimeout to improve UX for people using Command + K keyboard shortcut;
      // without this, the link input field is focused but editor scrolls to the top of the doc!
      setTimeout(() => {
        inputRef?.current?.focus()
      })
    }
  }, [href])

  useEffect(() => {
    setInputValue(href)
  }, [href])

  const toggleMediaDrawer = useToggleMediaDrawer()
  const editLink = useCallback(
    (ev) => {
      toggleMediaDrawer(true)
      ev.stopPropagation()
    },
    [toggleMediaDrawer]
  )

  return (
    <HStack spacing={2} py={1} mx={2}>
      <Input
        value={inputValue}
        ref={inputRef}
        onChange={(event) => setInputValue(event.target.value)}
        onBlur={() => {
          setHref(inputValue)
        }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            setHref(inputValue)
            // Manually blur the input on enter as a UX
            // confirmation that enter captured the new value,
            // and return focus to the editor
            editor.commands.focus()
          }
        }}
        placeholder={t`Paste a link, then hit enter`}
        width="250px"
        size="sm"
        data-testid="link-input"
      />
      {unlink && (
        <ToolbarButton
          label={<Trans>Remove link</Trans>}
          icon={regular('unlink')}
          onClick={unlink}
        />
      )}
      {isInternalCardLink && (
        <ToolbarButton
          label={<Trans>Jump to card Link</Trans>}
          icon={regular('arrow-right')}
          onClick={onInternalCardLinkClick}
        />
      )}
      {href && (
        <ToolbarButton
          label={
            isInternalCardLink ? (
              <Trans>Open card in new window</Trans>
            ) : (
              <Trans>Visit link</Trans>
            )
          }
          icon={regular('external-link')}
          onClick={onClick}
        />
      )}
      {href && allowEditMetadata && (
        <ToolbarButton
          label={<Trans>Edit</Trans>}
          icon={regular('pencil')}
          onClick={editLink}
        />
      )}
    </HStack>
  )
}
