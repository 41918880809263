import { Editor } from '@tiptap/core'
import { useEffect, useRef } from 'react'

import { UploadStatus } from 'modules/media/types/ImageUpload'
import { uploadImageFileOrUrl } from 'modules/tiptap_editor/extensions/media/Upload/uploadPlugin'

import { ImageAttrs } from '../types/Image'

type UseUploadOnUnmountProps = {
  currentAttributes: Partial<ImageAttrs>
  updateAttributes: (attrs: Partial<ImageAttrs>) => void
  isMounted: boolean
  editor?: Editor
}

export const useUploadOnUnmount = ({
  currentAttributes,
  updateAttributes,
  editor,
  isMounted,
}: UseUploadOnUnmountProps) => {
  const currentRef = useRef({
    currentAttributes,
    updateAttributes,
    sourceKey: currentAttributes.source,
  })
  currentRef.current.currentAttributes = currentAttributes
  currentRef.current.updateAttributes = updateAttributes

  useEffect(() => {
    // Keep track of the last known source when the tempUrl or src url changes
    currentRef.current.sourceKey = currentRef.current.currentAttributes.source
  }, [currentAttributes.tempUrl, currentAttributes.src])

  // When we unmount, be sure to upload the currently selected icon to the CDN if
  // there is a tempUrl and no src
  useEffect(() => {
    if (!editor || isMounted) return

    const { src, tempUrl, uploadStatus } = currentRef.current.currentAttributes
    if (tempUrl && !src && uploadStatus !== UploadStatus.Uploading) {
      console.log('[useUploadOnUnmount] uploading tempUrl:', tempUrl)
      currentRef.current.updateAttributes({
        uploadStatus: UploadStatus.Uploading,
        source: currentRef.current.sourceKey,
      })
      uploadImageFileOrUrl(editor, tempUrl)
    }
  }, [editor, isMounted])
}
