import { Box, Flex } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'
import { memo } from 'react'

import { getThemeBase } from 'modules/theming/themeBases'

import { BulletMarker } from '../components/BulletMarker'
import { CELL_PADDING, EmptyCellContent } from '../constants'
import { CellSizeOption, useNumColumns } from '../options/CellSizeOption'
import { ColorOption } from '../options/ColorOption'
import { NumberedOption } from '../options/NumberedOption'
import {
  SmartLayoutCellComponent,
  SmartLayoutVariant,
  SmartLayoutWrapperComponent,
} from '../types'

const GAP = '1em'

export const BulletsWrapper: SmartLayoutWrapperComponent = memo(
  ({ children }) => {
    return (
      <Flex
        gap={GAP}
        direction="row"
        wrap="wrap"
        data-selection-ring
        pos="relative"
      >
        {children}
      </Flex>
    )
  }
)
BulletsWrapper.displayName = 'BulletsWrapper'

export const BulletsCell: SmartLayoutCellComponent = memo((props) => {
  const { children, ...markerProps } = props
  const { theme, layoutOptions, numCells, layoutContainerSize, dragHandle } =
    markerProps
  const base = getThemeBase(theme)
  const { columns } = useNumColumns({
    options: layoutOptions,
    numCells,
    containerSize: layoutContainerSize,
  })

  return (
    <Flex
      direction="row"
      align="flex-start"
      gap={CELL_PADDING}
      // Always fill the space, distributing evenly
      flex={'1 1 auto'}
      w={`calc(${100 / columns}% - ${GAP})`}
      data-selection-ring
      data-content-reference
      pos="relative"
    >
      <BulletMarker {...markerProps} alignText></BulletMarker>
      <Box
        flex="1"
        minW="0"
        sx={base.smartLayoutContentSx}
        pt="var(--block-margin)"
      >
        {children}
      </Box>
      {dragHandle}
    </Flex>
  )
})
BulletsCell.displayName = 'BulletsCell'

export const Bullets: SmartLayoutVariant = {
  key: 'bullets',
  commandName: () => t`Bullets layout (blank)`,
  name: () => t`Bullets`,
  icon: regular('square-list'),
  options: [CellSizeOption, ColorOption, NumberedOption],
  cellOptions: [ColorOption],
  Wrapper: BulletsWrapper,
  Cell: BulletsCell,
  defaultContent: EmptyCellContent,
  htmlTag: 'bullets',
  keywords: ['bullets', 'layout', 'list', 'bullet'],
}
