import { mergeAttributes, Node } from '@tiptap/core'

import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'

import { attrsOrDecorationsChanged } from '../updateFns'
import { TitleView } from './TitleView'

export enum TitleLevel {
  DocTitle = 1,
}

export const Title = Node.create({
  name: 'title',
  content: 'inline*',
  group: 'block cardNotesBlock',
  defining: true,
  selectable: false,

  addAttributes() {
    return {
      level: {
        default: TitleLevel.DocTitle,
      },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(TitleView, {
      update: attrsOrDecorationsChanged,
    })
  },

  parseHTML() {
    return [
      {
        tag: 'h1[class=title]',
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'h1',
      mergeAttributes(HTMLAttributes, {
        class: 'title',
      }),
      0,
    ]
  },
})
