import { clamp } from 'lodash'

import { SmartLayoutCellProps } from '../../types'

// Not in use right now
export const getStatsPercentWithMax = ({
  cellOptions,
  layoutOptions,
  label,
  index,
  numCells,
  maxLabelValue,
}: SmartLayoutCellProps) => {
  const max =
    cellOptions.numberMax ??
    layoutOptions.numberMax ??
    (maxLabelValue && maxLabelValue > 0 && maxLabelValue <= 100
      ? 100
      : maxLabelValue) ??
    numCells
  const labelNumber = cellOptions.numberValue ?? parseLabelNumber(label)
  const value = labelNumber ?? index + 1
  return clamp(value / max, 0, 1)
}

export const getStatsPercent = ({ label }: SmartLayoutCellProps) => {
  const value = parseLabelNumber(label)
  if (!value) return 0
  return clamp(value / 100, 0, 1)
}

const NUMBER_REGEX = /[0-9,.]+/g
const THOUSAND_REGEX = /[,.](?=\d{3})/g
const FRACTION_REGEX = /([0-9]+)\s?\/\s?([0-9]+)/g
export const parseLabelNumber = (
  label?: string | number | null
): number | undefined => {
  if (!label) return
  if (typeof label === 'number') return label
  try {
    const fraction = label.match(FRACTION_REGEX)?.[0]
    if (fraction) {
      const [numerator, denominator] = fraction.split('/').map((n) => n.trim())
      return (100 * parseFloat(numerator)) / parseFloat(denominator)
    }
    const num = label.match(NUMBER_REGEX)?.[0]
    const numWithoutCommas = num?.replaceAll(THOUSAND_REGEX, '')
    return numWithoutCommas ? parseFloat(numWithoutCommas) : undefined
  } catch (err) {
    console.error(`[SmartLayout] Failed to parse label number: ${label}`, err)
    return undefined
  }
}
