import { Box, BoxProps } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { ReactNode } from 'react'

import { Theme } from 'modules/theming'

import { CARD_CONTENT_CLASS, EXPAND_CARD_TRANSITION_TIME } from '..'
import { CardDimensionsOption } from '../CardDimensions'
import {
  findLayoutPreset,
  getCardLayoutChildrenSx,
} from '../CardLayout/cardLayoutUtils'
import { CardAttributes, DisplayLayout } from '../types'
import { CardHeightGuide } from './CardHeightGuide'
import { CARD2_LAYOUT_CLASS } from './constants'

type CardLayoutComponentProps = {
  displayLayout: DisplayLayout
  attrs: CardAttributes
  isExpandableSelected: boolean
  isEditable: boolean
  isFocused: boolean
  isPresentMode: boolean
  isFullBleed: boolean
  isFirstCard: boolean
  hasLayoutItem: boolean
  children: ReactNode
  onDoubleClick?: BoxProps['onDoubleClick']
  cardDimensions: CardDimensionsOption
  theme: Theme
}

export const Card2Layout = ({
  displayLayout,
  children,
  isExpandableSelected,
  isFocused,
  isFullBleed,
  isFirstCard,
  isPresentMode,
  isEditable,
  attrs,
  hasLayoutItem,
  onDoubleClick,
  cardDimensions,
  theme,
}: CardLayoutComponentProps) => {
  // always show a blank layout in nested cards
  const layoutPreset = findLayoutPreset(displayLayout)
  const childItemsSx = getCardLayoutChildrenSx(displayLayout)
  const layoutTemplateColumns = attrs.layoutTemplateColumns
  const { logoUrl } = theme
  const shouldAddPaddingForLogos = logoUrl && isPresentMode

  return (
    <Box
      className={cx(
        CARD2_LAYOUT_CLASS,
        isExpandableSelected && 'expandable-selected'
      )}
      onDoubleClick={onDoubleClick}
      w={'var(--card-width)'}
      maxW="100%"
      position="relative"
      spellCheck={isFocused}
      cursor="auto"
      color="var(--body-color)"
      sx={{
        // the direct descendent specificity is necessary so that grids and nested card sub grids
        // dont interact with each other
        [`> .${CARD_CONTENT_CLASS}`]: {
          padding: !hasLayoutItem ? 'var(--card-inner-padding)' : undefined,
          '> [data-node-view-content-inner="card"]': {
            // if you are using CardView2 but the content was created without a card layout item
            ...(hasLayoutItem
              ? {
                  // base grid props for all layout presets
                  display: 'grid',
                  alignItems: 'stretch',
                  justifyContent: 'stretch',
                  minH: '100%',
                  transitionDuration: `${EXPAND_CARD_TRANSITION_TIME}ms`,
                  transitionProperty: 'grid-template-columns, min-height',
                  ...layoutPreset.grid,
                  ...(layoutTemplateColumns &&
                    layoutPreset.allowResizing && {
                      gridTemplateColumns: layoutTemplateColumns,
                    }),
                  ...childItemsSx,
                }
              : {
                  display: 'block',
                  // try to fill up the width, max-content-width is card width when not in
                  // present mode
                  mx: 'auto',
                  maxW: 'var(--max-content-width)',
                  width: '100%',
                  boxSizing: 'border-box',
                  minW: 0,
                }),

            '> .node-cardLayoutItem': {
              '> [data-node-view-wrapper]': {
                '> .card-layout-cell-bg': {
                  '> [data-node-view-content]': {
                    '> [data-node-view-content-inner]': {
                      maxWidth: 'var(--max-content-width)',
                      margin: 'auto', // Center horizontally
                      marginTop:
                        isFullBleed &&
                        isFirstCard &&
                        isEditable &&
                        !isPresentMode
                          ? '2rem'
                          : shouldAddPaddingForLogos
                          ? '2rem'
                          : 'auto',
                    },
                    padding: 'var(--card-inner-padding)',
                  },
                },
              },
            },
          },
        },
      }}
    >
      {children}
      {isFocused && cardDimensions.aspectRatio && <CardHeightGuide />}
    </Box>
  )
}
