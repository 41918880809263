import { Box } from '@chakra-ui/react'
import { memo } from 'react'

import {
  DEFAULT_GRADIENT_COLOR,
  lightenedGradient,
} from '../components/LinearGradientPicker/LinearGradientPicker'
import { LinearGradient } from '../components/LinearGradientPicker/types'
import { getThemeBase } from '../themeBases'
import { Theme } from '../types'
import { isThemeDark } from '../utils/utils'
import { getThemeCSSVars } from './variables'

type ThemeSVGDefsProps = {
  theme: Theme
}

export const ThemeSVGDefs = memo(({ theme }: ThemeSVGDefsProps) => {
  const base = getThemeBase(theme)
  const css = getThemeCSSVars(theme)
  return (
    <Box as={'svg'} width="0" height="0" sx={css}>
      <defs>
        <ThemeAccentGradientLinearDef theme={theme} />
        <ThemeAccentBackgroundGradientLinearDef theme={theme} />
        {base.SVGDefs && <base.SVGDefs theme={theme} />}
      </defs>
    </Box>
  )
})

ThemeSVGDefs.displayName = 'ThemeSVGDefs'

const ThemeAccentGradientLinearDef = memo(({ theme }: ThemeSVGDefsProps) => {
  const { accentGradient: gradient } = theme.config
  if (!gradient || gradient.colors.length <= 1 || gradient.disabled) return null

  return (
    <LinearGradientDef
      gradient={gradient}
      id={`theme-accent-gradient-linear`}
    />
  )
})

ThemeAccentGradientLinearDef.displayName = 'ThemeAccentGradientLinearDef'

const ThemeAccentBackgroundGradientLinearDef = memo(
  ({ theme }: ThemeSVGDefsProps) => {
    const { accentGradient: gradient } = theme.config
    if (!gradient || gradient.colors.length <= 1 || gradient.disabled)
      return null
    const isDark = isThemeDark(theme)
    const backgroundGradient = lightenedGradient(gradient, isDark ? 0.25 : 0.9)
    if (!backgroundGradient) return null
    return (
      <LinearGradientDef
        gradient={backgroundGradient}
        id={`theme-accent-background-gradient-linear`}
      />
    )
  }
)

ThemeAccentBackgroundGradientLinearDef.displayName =
  'ThemeAccentBackgroundGradientLinearDef'

type LinearGradientDefProps = {
  gradient: LinearGradient
  id: string
}

const LinearGradientDef = memo(({ gradient, id }: LinearGradientDefProps) => {
  return (
    <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="100%">
      {gradient.colors.map((color, i) => (
        <stop
          key={i}
          offset={`${(i * 100) / (gradient.colors.length - 1)}%`}
          stopColor={color || DEFAULT_GRADIENT_COLOR}
        />
      ))}
    </linearGradient>
  )
})

LinearGradientDef.displayName = 'LinearGradientDef'
