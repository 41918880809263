import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'

import {
  AirtableProvider,
  AmplitudeProvider,
  BingImageProvider,
  CalendlyProvider,
  CustomImageProvider,
  CustomVideoProvider,
  FigmaProvider,
  GiphyProvider,
  GoogleDriveProvider,
  GoogleFormProvider,
  GoogleImageProvider,
  GradientProvider,
  IconProvider,
  JotformProvider,
  LexicaProvider,
  LoomProvider,
  MicrosoftOfficeProvider,
  MiroProvider,
  PDFProvider,
  PowerBIProvider,
  SpotifyProvider,
  TikTokProvider,
  TwitterProvider,
  UnsplashProvider,
  VimeoProvider,
  WebpageProvider,
  WebImageProvider,
  WistiaProvider,
  YoutubeProvider,
  TallyProvider,
  AIGeneratedImageProvider,
  TypeformProvider,
} from 'modules/media'
import { MediaProviderType } from 'modules/media/types/MediaProvider'
import { ACCENT_IMAGE_SOURCE_KEY } from 'modules/theming'
import { AccentImagePanel } from 'modules/tiptap_editor/components/panels/AccentImagePanel'

import { ColorPanel } from '../../components/panels/ColorPanel'
import { BackgroundType } from '../../styles/types'

export const NO_BACKGROUND_SOURCE_KEY = 'default'
const SOLID_COLOR_SOURCE_KEY = 'color.solid'

export type MediaSourceType = MediaProviderType & {
  availableOffline?: boolean // If this can be used offline
  disabled?: boolean
  nodeName?: string // If this can be inserted as an node
  backgroundType?: BackgroundType // If this can be used as a background
  priority?: number // Controls search order. Higher is better, default 0
}

const EmptyPanel = () => <></>

// TODO: Make Media Sources a class that observes changes to the i18n.locale
// Anyone that needs them can get MediaSources.sources
// https://linear.app/gamma-app/issue/G-5253/[perf]-make-media-sources-a-class-that-observes-changes-to-the
export const MediaSources: Record<string, MediaSourceType[]> = {
  Default: [
    {
      label: () => t`None`,
      availableOffline: true,
      key: NO_BACKGROUND_SOURCE_KEY,
      backgroundType: BackgroundType.NONE,
      Panel: EmptyPanel,
    },
  ],
  Image: [
    {
      ...CustomImageProvider,
      nodeName: 'image',
      backgroundType: BackgroundType.IMAGE,
      priority: 10, // Surface above galleries and diagrams when searching /im
    },
    {
      ...LexicaProvider,
      nodeName: 'image',
      backgroundType: BackgroundType.IMAGE,
    },
    {
      ...WebImageProvider,
      nodeName: 'image',
      backgroundType: BackgroundType.IMAGE,
    },
    {
      ...BingImageProvider,
      nodeName: 'image',
      // Disabled until we figure out pricing
      disabled: true,
      backgroundType: BackgroundType.IMAGE,
    },
    {
      ...AIGeneratedImageProvider,
      nodeName: 'image',
      backgroundType: BackgroundType.IMAGE,
    },
    {
      ...UnsplashProvider,
      nodeName: 'image',
      backgroundType: BackgroundType.IMAGE,
    },
    {
      ...GiphyProvider,
      nodeName: 'image',
      backgroundType: BackgroundType.IMAGE,
    },
    {
      ...IconProvider,
      nodeName: 'image',
    },
    {
      ...GoogleImageProvider,
      nodeName: 'image',
      // Disabled until we figure out pricing
      disabled: true,
      backgroundType: BackgroundType.IMAGE,
    },
    {
      label: () => t`Accent images`,
      key: ACCENT_IMAGE_SOURCE_KEY,
      availableOffline: true,
      icon: duotone('images'),
      Panel: AccentImagePanel,
      backgroundType: BackgroundType.IMAGE,
      keywords: ['accent image'],
    },
  ],
  Video: [
    {
      ...CustomVideoProvider,
      nodeName: 'video',
    },
    {
      ...LoomProvider,
      nodeName: 'video',
    },
    {
      ...YoutubeProvider,
      nodeName: 'video',
    },
    {
      ...VimeoProvider,
      nodeName: 'video',
    },
    {
      ...WistiaProvider,
      nodeName: 'video',
    },
    {
      ...TikTokProvider,
      nodeName: 'video',
    },
    {
      ...SpotifyProvider,
      nodeName: 'video',
    },
  ],
  Embed: [
    {
      ...WebpageProvider,
      nodeName: 'embed',
      priority: 10, // Surface above video/audio when searching "embed"
    },
    {
      ...GoogleDriveProvider,
      nodeName: 'embed',
    },
    {
      ...PDFProvider,
      nodeName: 'embed',
    },
    {
      ...FigmaProvider,
      nodeName: 'embed',
    },
    {
      ...TwitterProvider,
      nodeName: 'embed',
    },
    {
      ...MiroProvider,
      nodeName: 'embed',
    },
    {
      ...AirtableProvider,
      nodeName: 'embed',
    },
    {
      ...CalendlyProvider,
      nodeName: 'embed',
    },
    {
      ...TypeformProvider,
      nodeName: 'embed',
    },
    {
      ...JotformProvider,
      nodeName: 'embed',
    },
    {
      ...GoogleFormProvider,
      nodeName: 'embed',
    },
    {
      ...TallyProvider,
      nodeName: 'embed',
    },
    {
      ...AmplitudeProvider,
      nodeName: 'embed',
    },
    {
      ...MicrosoftOfficeProvider,
      nodeName: 'embed',
    },
    {
      ...PowerBIProvider,
      nodeName: 'embed',
    },
  ],
  Color: [
    {
      label: () => t`Solid Color`,
      key: SOLID_COLOR_SOURCE_KEY,
      availableOffline: true,
      icon: duotone('palette'),
      Panel: ColorPanel,
      backgroundType: BackgroundType.COLOR,
      keywords: ['solid color'],
    },
    {
      ...GradientProvider,
      availableOffline: true,
      backgroundType: BackgroundType.GRADIENT,
    },
  ],
}

export const MediaSourcesMap = Object.fromEntries(
  Object.values(MediaSources)
    .flat()
    .map((source) => [source.key, source])
)

export const getMediaSourceGroupDisplayName: Record<
  keyof typeof MediaSources,
  () => string
> = {
  Default: () => t`Default`,
  Image: () => t`Image`,
  Video: () => t`Video`,
  Embed: () => t`Embed`,
  Color: () => t`Color`,
}
