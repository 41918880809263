import { Box, BoxProps } from '@chakra-ui/react'

export type HtmlEmbedProps = {
  html: string
} & BoxProps
export const HtmlEmbed = ({ html, ...props }: HtmlEmbedProps) => {
  return (
    <Box
      position="relative"
      role="group"
      className="embed-viewer"
      data-selection-ring
      data-selection-background
      borderRadius="var(--box-border-radius)"
      contentEditable={false}
      dangerouslySetInnerHTML={{ __html: html }}
      {...props}
    />
  )
}
