import {
  Editable,
  EditableInput,
  EditableInputProps,
  EditablePreview,
  EditableProps,
} from '@chakra-ui/react'
import { useCallback } from 'react'

import { useAppSelector } from 'modules/redux'
import { selectContentEditable } from 'modules/tiptap_editor/reducer'

type EditableLabelProps = EditableProps & {
  updateAttributes?: (attributes: Record<string, any>) => void
  inputProps?: EditableInputProps
}

export const EditableLabel = ({
  updateAttributes,
  value,
  placeholder,
  inputProps,
  ...props
}: EditableLabelProps) => {
  const updateValue = useCallback(
    (newValue: string) => {
      if (!updateAttributes) return
      updateAttributes({ label: newValue === '' ? null : newValue })
    },
    [updateAttributes]
  )
  const isEditable = useAppSelector(selectContentEditable) && updateAttributes

  return (
    <Editable
      value={value || ''}
      placeholder={placeholder}
      onChange={updateValue}
      isDisabled={!isEditable}
      {...props}
    >
      <EditablePreview width="100%" h="1em" py={0} noOfLines={1} />
      <EditableInput width="100%" my={-1} py={1} {...inputProps} />
    </Editable>
  )
}
