import { NodeViewProps } from '@tiptap/core'

import { useAppSelector } from 'modules/redux'
import { useShouldUsePublishedVersion } from 'modules/sites/PublishingContext'
import { useShouldRenderMobileVersion } from 'modules/tiptap_editor/hooks'
import {
  selectCardCollapsed,
  selectDocSettings,
  selectEditable,
  selectIsStatic,
  selectZoomLevel,
} from 'modules/tiptap_editor/reducer'

import { EditorModeEnum } from '../../../types'
import { isExpandableSelectedNode } from '../../ExpandableNodes/ExpandableNodes'
import { isFocusedInside } from '../../selection/utils'
import { getCardDimensions } from '../CardDimensions'
import { findCardPluginDecoration } from '../CardPlugin'
import { CardAttributes } from '../types'
import { useCardColorMode } from './useCardColorMode'
import { usePresentVariant } from './usePresentVariant'

export const useCardState = (
  nodeViewProps: Pick<NodeViewProps, 'node' | 'decorations' | 'editor'>
) => {
  const { node, decorations, editor } = nodeViewProps
  const attrs = node.attrs as CardAttributes
  const { id: cardId, previewContent } = attrs

  const isMobileDevice = useShouldRenderMobileVersion()
  const isPublished = useShouldUsePublishedVersion()

  // Editor modes
  const isThumbnail = editor.isThumbnail
  const isStatic = useAppSelector(selectIsStatic)
  const [presentVariant, prevPresentVariant] = usePresentVariant(cardId)
  const mode = // Derive the mode from the presentVariant so that we recompute things at the same time
    presentVariant === 'doc'
      ? EditorModeEnum.DOC_VIEW
      : EditorModeEnum.SLIDE_VIEW
  const isEditable = useAppSelector((state) => selectEditable(state, mode)) // Pass the mode derived from the presentVariant to observe editable changes
  const isPresentMode = mode === EditorModeEnum.SLIDE_VIEW
  const isPresenting =
    presentVariant !== 'doc' && presentVariant !== 'presentCollapsed'
  const isPresentingCurrent = presentVariant === 'presentCurrent'
  const isPresentingParent = presentVariant === 'presentParent'
  const userZoomLevel = useAppSelector(selectZoomLevel)

  // Selection/interactions
  const isFocused = isFocusedInside(decorations)
  const isCollapsed = useAppSelector(selectCardCollapsed(cardId))
  const isExpandableSelected = isExpandableSelectedNode(decorations)

  // Position and parent/child state, via the CardPlugin decorations
  const {
    isNested,
    nestedDepth,
    isFirstCard,
    isLastCard,
    inheritContainerEffect,
    inheritContainerIsDark,
    inheritContainerWidth,
    displayLayout,
    insideSideLayout,
    cardAccentBackgroundMaskEffect,
    hasLayoutItem,
  } = findCardPluginDecoration(decorations)
  // Deprecated - only used in Card1. todo: remove
  const inheritContainer = {
    effect: inheritContainerEffect,
    isDark: inheritContainerIsDark,
    width: inheritContainerWidth,
  }

  // Card style options - can be card level or derived from theme or doc settings
  const { theme, isDark, isDarkOverride } = useCardColorMode(decorations)
  const {
    cardDimensions: cardDimensionsKey,
    defaultFullBleed,
    defaultContentWidth,
    presentBackdrop: showPresentBackdrop = false,
  } = useAppSelector(selectDocSettings)
  const cardDimensions = getCardDimensions(cardDimensionsKey)
  const isFullBleed =
    // If the cardSize is 'default', then fall back to the full bleed setting
    attrs.cardSize === 'default'
      ? defaultFullBleed === 'full'
      : // Otherwise, use the cardSize setting, which will be 'full' or 'contained
        attrs.cardSize === 'full'
  const isWideContent = (attrs.container.width ?? defaultContentWidth) === 'lg'

  return {
    inheritContainer,
    isCollapsed,
    isEditable,
    isFirstCard,
    isFocused,
    isExpandableSelected,
    isLastCard,
    // This is a convenience to ensure memoization of functions using this
    // value account for the possiblity of isMobileDevice changing in the SSR context
    isMobileDevice,
    isNested,
    isPresenting,
    isPresentingCurrent,
    isPresentingParent,
    isPresentMode,
    isPublished,
    isStatic,
    isDark,
    isDarkOverride,
    isThumbnail,
    mode,
    nestedDepth,
    presentVariant,
    prevPresentVariant,
    previewContent,
    theme,
    attrs,
    cardId,
    isFullBleed,
    isWideContent,
    displayLayout,
    insideSideLayout,
    cardAccentBackgroundMaskEffect,
    cardDimensions,
    showPresentBackdrop,
    hasLayoutItem,
    userZoomLevel,
  }
}
