import { isMobileDevice } from 'utils/deviceDetection'

import { paddedTextBackground } from '../../TextColor/highlightStyles'

const DIM_OPACITY = 0.5

const iconColor = '%23553bff'
const sparkle = `url(\'data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" fill="${iconColor}"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M245.8 45.9C241.9 37.4 233.4 32 224 32s-17.9 5.4-21.8 13.9L142.7 174.7 13.9 234.2C5.4 238.1 0 246.6 0 256s5.4 17.9 13.9 21.8l128.8 59.5 59.5 128.8c3.9 8.5 12.4 13.9 21.8 13.9s17.9-5.4 21.8-13.9l59.5-128.8 128.8-59.5c8.5-3.9 13.9-12.4 13.9-21.8s-5.4-17.9-13.9-21.8L305.3 174.7 245.8 45.9z"/></svg>\')`
const sparkles = `url(\'data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512" fill="${iconColor}"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M327.5 85.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 128l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 128l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 64 426.8 7.5C425.1 3 420.8 0 416 0s-9.1 3-10.8 7.5L384 64 327.5 85.2zM205.1 73.3c-2.6-5.7-8.3-9.3-14.5-9.3s-11.9 3.6-14.5 9.3L123.3 187.3 9.3 240C3.6 242.6 0 248.3 0 254.6s3.6 11.9 9.3 14.5l114.1 52.7L176 435.8c2.6 5.7 8.3 9.3 14.5 9.3s11.9-3.6 14.5-9.3l52.7-114.1 114.1-52.7c5.7-2.6 9.3-8.3 9.3-14.5s-3.6-11.9-9.3-14.5L257.8 187.4 205.1 73.3zM384 384l-56.5 21.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 448l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 448l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 384l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L384 384z"/></svg>\')`

const sparklesAfterContent = {
  // FontAwesome icon of the astronaut
  backgroundRepeat: 'no-repeat',
  backgroundSize: '11px',
  backgroundPosition: '8px center',
  fontSize: '13px',
  fontWeight: 'bold',
  color: 'var(--chakra-colors-trueblue-400)',
  position: 'absolute',
  zIndex: 1,
  top: '0px',
  left: '0px',
  backgroundColor: 'var(--chakra-colors-trueblue-100)',
  padding: '4px 12px',
  paddingLeft: '24px',
  borderRadius: '0 0 4px 4px',
  pointerEvents: 'none',
}

// These styles will only apply when the design partner panel is open
export const SalEditorStyles = {
  // Animate card opacity when entering Sal mode
  '.block-card': {
    transitionProperty: 'opacity',
    transitionDuration: 'normal',
  },

  '.ai-generate-streaming-card': {
    '&.node-card': {
      // Only disable pointerEvents on desktop, as mobile devices need to
      // be able to scroll via touch (editing is disabled on mobile anyway)
      pointerEvents: !isMobileDevice() ? 'none !important' : undefined,
    },
    '&.node-card > [data-node-view-wrapper] > .card-wrapper > .card-body': {
      _before: {
        content: "''",
        position: 'absolute',
        inset: '0px',
        zIndex: 1,
        boxShadow: '0px 0px 0px 5px var(--chakra-colors-trueblue-100)',
        borderRadius: 'inherit',
        pointerEvents: 'none',
      },
    },

    '.streaming-cursor__label': {
      _after: {
        ...sparklesAfterContent,
        backgroundImage: sparkle,
        content: 'var(--ai-generating-label)',
      },
    },
  },

  // Styles in here will only apply when Sal is open
  '&.sal-active': {
    // Dim inactive cards
    '.block-card:not(.sal-active-card, .sal-active-card-parent)': {
      opacity: DIM_OPACITY,
    },

    '.sal-active-card > [data-node-view-wrapper] > .card-wrapper > .card-body':
      {
        _before: {
          content: "''",
          position: 'absolute',
          inset: '0px',
          zIndex: 1,
          boxShadow: '0px 0px 0px 5px var(--chakra-colors-trueblue-100)',
          borderRadius: 'inherit',
          pointerEvents: 'none',
        },
        _after: {
          ...sparklesAfterContent,
          backgroundImage: sparkles,
          content: 'var(--ai-editing-label)',
          left: '50%',
          transform: 'translate(-50%,0px)',
        },
      },

    // If you're in a nested card, dim blocks that aren't the active card
    '.sal-active-card-parent': {
      '.block:not(.sal-active-card)': {
        opacity: DIM_OPACITY,
      },
      '.sal-active-card': {
        '.block': {
          opacity: 1,
        },
      },
    },

    '.sal-selection-text': {
      backgroundColor: `rgba(100, 100, 100, 0.25)`, // gray
      ...paddedTextBackground,
    },
  },
}
