import { mergeAttributes, Node } from '@tiptap/core'

import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'

import { attrsOrDecorationsChanged } from '../updateFns'
import {
  CardTableOfContentsThumbnailView,
  CardTableOfContentsView,
} from './CardTableOfContentsView'

export const CardTableOfContents = Node.create({
  name: 'tableOfContents',
  group: 'block',
  atom: true,
  selectable: true,
  dynamic: true,

  addAttributes() {
    return {
      nested: {
        default: false,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[class=toc]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { class: 'toc' })]
  },

  addNodeView() {
    if (this.editor.isThumbnail) {
      return ReactNodeViewRenderer(CardTableOfContentsThumbnailView, {
        update: attrsOrDecorationsChanged,
      })
    }

    return ReactNodeViewRenderer(CardTableOfContentsView, {
      update: attrsOrDecorationsChanged,
    })
  },
})
