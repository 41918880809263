import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { NodeSelection } from 'prosemirror-state'

import { openMediaDrawer } from 'modules/tiptap_editor/components/drawers/MediaDrawer/utils'

import { ExtensionPriorityMap } from '../../constants'
import { HorizontalAlignment } from '../../HorizontalAlign/types'
import { isMediaNode } from '../utils'
import { MediaPlaceholderView } from './MediaPlaceholderView'

export type MediaPlaceholderAttrs = {
  source: string | null
  horizontalAlign: HorizontalAlignment
  fullWidthBlock: boolean
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    mediaPlaceholder: {
      insertMediaBlock: (sourceKey: string) => ReturnType
    }
  }
}

export const MediaPlaceholder = Node.create({
  name: 'mediaPlaceholder',
  group: 'block media',
  atom: true,
  selectable: true,
  draggable: true,
  priority: ExtensionPriorityMap.MediaPlaceholder,

  addNodeView() {
    return ReactNodeViewRenderer(MediaPlaceholderView)
  },

  addAttributes() {
    // The default source to highlight when you open
    return {
      source: {},
      fullWidthBlock: { default: false },
    }
  },

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        const selection = editor.state.selection
        if (selection instanceof NodeSelection && isMediaNode(selection.node)) {
          openMediaDrawer()
          return true
        }
        return false
      },
    }
  },

  addCommands() {
    return {
      insertMediaBlock:
        (sourceKey) =>
        ({ commands }) => {
          commands.insertContentAndSelect({
            type: 'mediaPlaceholder',
            attrs: {
              source: sourceKey,
            },
          })

          requestAnimationFrame(() => {
            openMediaDrawer()
          })
          return true
        },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[class=media-placeholder]',
      },
      {
        tag: 'img[placeholder=true]',
        priority: 60, // Run ahead of img[src] rules
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, { class: 'media-placeholder' }),
    ]
  },
})
