import { CheckIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Trans } from '@lingui/macro'
import { Editor } from '@tiptap/core'
import { useCallback } from 'react'
import tinycolor from 'tinycolor2'

import { ColorPickerMenuInner } from 'modules/theming/components/ColorPickerMenu/ColorPickerMenu'
import { hasOneUniqueAttributeForMark } from 'modules/tiptap_editor/utils/attributes'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { ToolbarButtonProps } from '../../components/menus/buttons/ToolbarButton'
import { focusEditorOnMenuClose } from '../../components/menus/FormattingMenus/utils'

type TextColorMenuProps = Omit<ToolbarButtonProps, 'onClick'> & {
  editor: Editor
}

const useTextColorMenu = ({ editor }: { editor: Editor }) => {
  // Values should be false if no highlight is applied, and null if there's a mix of styles
  const { selection, doc, schema } = editor.state
  const { from, to } = selection
  const textColorValue = doc.rangeHasMark(from, to, schema.marks.highlight)
    ? null
    : !doc.rangeHasMark(from, to, schema.marks.textColor)
    ? getSelectedTextColor(editor)
    : hasOneUniqueAttributeForMark<string>(editor.state, 'textColor', 'hex') ||
      null
  const highlightValue = doc.rangeHasMark(from, to, schema.marks.textColor)
    ? null
    : !doc.rangeHasMark(from, to, schema.marks.highlight)
    ? null
    : hasOneUniqueAttributeForMark<string>(editor.state, 'highlight', 'hex') ||
      null

  const setHighlightColor = useCallback(
    (hex: string) => editor.chain().focus().setHighlightColor(hex).run(),
    [editor]
  )
  const setTextColor = useCallback(
    (hex: string) => editor.chain().focus().setTextColor(hex).run(),
    [editor]
  )

  const resetColorAndHighlight = useCallback(() => {
    editor.chain().focus().unsetMark('textColor').unsetMark('highlight').run()
  }, [editor])

  return {
    highlightValue,
    resetColorAndHighlight,
    setHighlightColor,
    setTextColor,
    textColorValue,
  }
}

export const TextColorMenu = ({
  editor,
  testId,
  disabled,
}: TextColorMenuProps) => {
  const {
    textColorValue,
    highlightValue,
    setHighlightColor,
    setTextColor,
    resetColorAndHighlight,
  } = useTextColorMenu({ editor })

  return (
    <Menu isLazy onClose={() => focusEditorOnMenuClose(editor)}>
      <GammaTooltip placement="top" label={<Trans>Text color...</Trans>}>
        <MenuButton
          isDisabled={disabled}
          as={Button}
          variant="toolbar"
          rightIcon={
            <FontAwesomeIcon
              icon={regular('chevron-down')}
              transform="shrink-6"
            />
          }
          data-testid={testId}
          onMouseDown={preventDefaultToAvoidBlur}
          size="sm"
        >
          <FontColorIcon
            color={highlightValue || textColorValue || undefined}
          />
        </MenuButton>
      </GammaTooltip>
      <MenuList overflow="hidden">
        <Tabs
          isFitted
          variant="soft-rounded"
          size="sm"
          defaultIndex={highlightValue ? 1 : 0}
        >
          <TabList>
            <Tab>
              <HStack>
                <FontAwesomeIcon icon={regular('font')} />
                <Text>
                  <Trans>Text</Trans>
                </Text>
              </HStack>
            </Tab>
            <Tab>
              <HStack>
                <FontAwesomeIcon icon={regular('highlighter-line')} />
                <Text>
                  <Trans>Highlight</Trans>
                </Text>
              </HStack>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0} pt={2}>
              <ColorPickerMenuInner
                currentColor={textColorValue || null}
                setColor={setTextColor}
                unsetColor={resetColorAndHighlight}
                source="TextColor"
              />
            </TabPanel>
            <TabPanel p={0} pt={2}>
              <ColorPickerMenuInner
                currentColor={highlightValue || ''}
                setColor={setHighlightColor}
                unsetColor={resetColorAndHighlight}
                source="TextHighlight"
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </MenuList>
    </Menu>
  )
}

const getSelectedTextColor = (editor: Editor): string | null => {
  const { from } = editor.state.selection
  const dom = editor.view.domAtPos(from)
  const elt =
    dom.node instanceof HTMLElement ? dom.node : dom.node.parentElement
  if (!elt) return null
  const css = getComputedStyle(elt as HTMLElement)
  const computedColor = css.getPropertyValue('color')
  if (!computedColor || computedColor === 'rgba(0, 0, 0, 0)') return null
  return tinycolor(computedColor).toHexString().toUpperCase()
}

type FontColorIconProps = {
  color?: string
  fixedWidth?: boolean
  isChecked?: boolean
}

const FontColorIcon = ({
  color,
  fixedWidth,
  isChecked,
}: FontColorIconProps) => {
  return (
    <Stack spacing="2px">
      <Text>
        {isChecked ? (
          <CheckIcon />
        ) : (
          <FontAwesomeIcon
            icon={solid('font')}
            fixedWidth={fixedWidth}
            size={color ? 'sm' : undefined}
            transform={{
              y: color ? 3 : 0,
            }}
          />
        )}
      </Text>
      {color && (
        <Flex
          w={5}
          h={1.5}
          bg={color}
          borderRadius="sm"
          borderWidth="1px"
          borderColor="blackAlpha.400"
        />
      )}
    </Stack>
  )
}
