import { t } from '@lingui/macro'

import { useAppSelector } from 'modules/redux'
import { getThemeColorPalette, Theme } from 'modules/theming'
import {
  EventBusEvent,
  TiptapEventBus as tiptapEventEmitter,
} from 'modules/tiptap_editor/eventBus'
import { selectTheme } from 'modules/tiptap_editor/reducer'

import {
  ColorPickerMenuSection,
  ColorPickerMenuSectionProps,
} from './ColorPickerMenuSection'
import { NamedColor } from './types'

export const ColorPickerThemeSection = ({
  setColor,
  currentColor,
}: Omit<ColorPickerMenuSectionProps, 'label' | 'colors'>) => {
  const theme: Theme = useAppSelector(selectTheme)
  const themeColors: NamedColor[] = getThemeColorPalette(theme).map((hex) => {
    return { hex, key: hex }
  })

  return (
    <ColorPickerMenuSection
      label={t`Theme colors`}
      colors={themeColors}
      setColor={setColor}
      currentColor={currentColor}
      handleAddColorButtonClick={() => {
        tiptapEventEmitter.emit(EventBusEvent.OPEN_THEME_EDITOR, {})
      }}
      addColorLabel={
        theme.workspaceId === null
          ? t`Customize theme colors`
          : t`Edit theme colors`
      }
    />
  )
}
