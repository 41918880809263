import { SearchIcon } from '@chakra-ui/icons'
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { ChangeEvent } from 'react'

import { isMobileDevice } from 'utils/deviceDetection'

type SearchInputProps = {
  onChange: (e: ChangeEvent) => void
  query: string
  isDisabled: boolean
  isLoading: boolean
}
export const SearchInput = ({
  onChange,
  query,
  isDisabled,
  isLoading,
}: SearchInputProps) => {
  return (
    <InputGroup size="lg" variant="unstyled">
      <InputLeftElement p={4} pointerEvents="none" color="gray.300">
        <SearchIcon />
      </InputLeftElement>
      <Input
        isDisabled={isDisabled}
        placeholder={t`Jump to`}
        p={3}
        pl={12}
        border="none"
        outline="none"
        shadow="none"
        value={query}
        onChange={onChange}
        _placeholder={{
          opacity: 1,
          color: 'gray.300',
        }}
      />
      <InputRightElement
        p={4}
        pointerEvents="none"
        color="gray.500"
        opacity={isLoading ? 1 : 0}
        transitionProperty="common"
        transitionDuration="normal"
        mr={isMobileDevice() ? 8 : 0} // nudge the icon to the left to accommodate the close button on mobile
      >
        <Spinner size="sm" />
      </InputRightElement>
    </InputGroup>
  )
}
