import { Box, Flex, Image } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/react'
import { useCallback, useEffect } from 'react'

import { UploadStatus } from 'modules/media/types/ImageUpload'
import { useAppDispatch, useAppSelector } from 'modules/redux'
import { isThemeDark, Theme } from 'modules/theming'
import { getThemeBase } from 'modules/theming/themeBases'
import { useShouldRenderMobileVersion } from 'modules/tiptap_editor/hooks'
import {
  selectAnimationsEnabled,
  selectEditable,
} from 'modules/tiptap_editor/reducer'
import { useEffectWhen, useWindowResizing } from 'utils/hooks'

import { MOVEABLE_WRAPPER_CLASSNAME } from '..'
import { AnnotatableNodeViewWrapper } from '../../Annotatable'
import { findBlockWidthDecoration } from '../../block/BlockWidthPlugin'
import { findCardPluginDecoration } from '../../Card/CardPlugin'
import { isFootnoteEditor } from '../../Footnote/utils'
import { getAlignStyles } from '../../HorizontalAlign/HorizontalAlign'
import { isFocusedAndEditable } from '../../selection/FocusedNodes'
import { MAX_IMAGE_HEIGHT_VIEWPORT } from '../constants'
import { isNodeViewInGallery } from '../Gallery'
import {
  MediaPlaceholderBlock,
  MediaPlaceholderErrorUploadingTag,
  MediaPlaceholderSpinner,
} from '../Placeholder'
import { ResizableControls, useResizeable } from '../Resizeable'
import { ImageNodeAttrs, ResizeAttrs } from '../types'
import { isSVG } from '../utils'
import {
  getZoomLayoutId,
  useMediaZoom,
  ZoomableOverlay,
  ZoomClickCapture,
  ZoomTransition,
} from '../Zoomable'
import { ClippableControls } from './Clippable'
import { CroppedImage } from './CroppedImage'
import { useCroppingControls } from './croppingHooks'
import { eventEmitter } from './eventEmitter'
import { useSetSize } from './hooks'
import { selectIsIdCropping } from './reducer'

type ImageView2Props = NodeViewProps & {
  theme: Theme
}
export const ImageView2 = ({ theme, ...nodeViewProps }: ImageView2Props) => {
  const dispatch = useAppDispatch()
  const {
    node,
    editor,
    updateAttributes,
    selected: isSelected,
    decorations,
  } = nodeViewProps

  const {
    src,
    tempUrl,
    uploadStatus,
    meta,
    showPlaceholder,
    horizontalAlign,
    resize,
    id,
  } = node.attrs as ImageNodeAttrs

  const widthPx = resize?.width || meta?.width
  const isSvg = isSVG(src || tempUrl)
  const { isFullWidth } = findBlockWidthDecoration(decorations)
  const alignStyles = getAlignStyles(isFullWidth ? 'center' : horizontalAlign)
  const isMobileDevice = useShouldRenderMobileVersion()
  const inGallery = isNodeViewInGallery(nodeViewProps)
  const isFocused = isFocusedAndEditable(decorations)
  const isEditable = useAppSelector(selectEditable)
  const inFootnote = isFootnoteEditor(editor)
  const { isCardDark } = findCardPluginDecoration(decorations)
  const isDark = isCardDark ?? isThemeDark(theme)
  const backgroundColor = isDark ? 'black' : 'white'
  const isUploading = uploadStatus === UploadStatus.Uploading

  // zooming
  const { isZoomed, enterZoom, exitZoom } = useMediaZoom(id)

  const {
    width: currentWidth,
    height: currentHeight,
    aspectRatio: intrinsicAspectRatio,
    containerWrapperRef,
    imageRef,
    setSize,
  } = useSetSize()

  useEffect(() => {
    return eventEmitter.on('startCrop', ({ id: imageId }) => {
      if (id === imageId) {
        // we must set the size for the CroppedImage and Clippable to work properly
        // when starting a crop
        setSize()

        requestAnimationFrame(() => {
          editor.commands.refreshBubbleMenu?.()
        })
      }
    })
  }, [id, setSize, editor])

  const {
    ref: imageWrapperRef,
    isResizing,
    setIsResizing,
    resizeableSx,
    isAnimating,
    onLayoutAnimationStart,
    onLayoutAnimationComplete,
  } = useResizeable<HTMLDivElement>(editor)

  const resizingEnabled =
    imageWrapperRef.current && isFocused && !inGallery && !inFootnote
  // since we have a hook that confirms crop on deselect then we dont need isFocused in
  // croppingEnabled the way we need it in resizingEnabled
  const croppingEnabled = imageWrapperRef.current && !inGallery && !inFootnote

  // theme
  const base = getThemeBase(theme)
  const updateResizeAttrs = useCallback(
    (resizeAttrs: Partial<ResizeAttrs>, setFullWidth?: boolean) => {
      updateAttributes({
        ...node.attrs,
        fullWidthBlock:
          setFullWidth === undefined ? node.attrs.fullWidthBlock : setFullWidth,
        resize: {
          ...node.attrs.resize,
          ...resizeAttrs,
        },
      })
    },
    [node.attrs, updateAttributes]
  )

  const isWindowResizing = useWindowResizing()
  // cropping
  const isCropping = useAppSelector(selectIsIdCropping(id))

  const { confirmCrop } = useCroppingControls(editor)
  // Handle confirm of cropping when deselecting the image
  useEffectWhen(
    () => {
      if (isCropping && !isSelected) {
        confirmCrop({ id })
      }
    },
    [editor, dispatch, isSelected, isCropping, id, confirmCrop],
    [isSelected]
  )

  const renderZoomableOverlay =
    !isCropping && !isResizing && !inGallery && !editor.isThumbnail
  const enableClickCapture = !editor.isThumbnail

  // do not allow zooming when cropping or resizing
  const enterZoomWithCheck = useCallback(() => {
    if (isCropping || isResizing) {
      return
    }
    enterZoom()
  }, [enterZoom, isCropping, isResizing])

  // ensure that the bubble menu is in the right spot if selected and the image loads
  const onImageLoad = useCallback(() => {
    if (isSelected) {
      editor.commands.refreshBubbleMenu?.()
    }
  }, [isSelected, editor])

  const animationsEnabled = useAppSelector(selectAnimationsEnabled)
  const motionProps = animationsEnabled
    ? {
        // This needs to be a string for the equality check
        layoutDependency: isZoomed,
        layoutId: getZoomLayoutId(id),
        transition: ZoomTransition,
        onLayoutAnimationStart,
        onLayoutAnimationComplete,
      }
    : undefined

  return (
    <AnnotatableNodeViewWrapper
      {...nodeViewProps}
      as="div"
      style={{ height: inGallery ? '100%' : undefined }}
    >
      {showPlaceholder && <MediaPlaceholderBlock {...nodeViewProps} />}
      {!showPlaceholder && (src || tempUrl) && (
        <Flex
          data-testid="image-node-wrapper"
          ref={containerWrapperRef}
          h="100%"
          w="100%"
          direction="column"
          className={MOVEABLE_WRAPPER_CLASSNAME}
          sx={resizeableSx}
          css={alignStyles}
        >
          {croppingEnabled && isCropping && (
            <ClippableControls
              imageWrapperRef={imageWrapperRef}
              updateResizeAttrs={updateResizeAttrs}
              refreshDeps={[isWindowResizing, isAnimating]}
              currentWidth={currentWidth}
              currentHeight={currentHeight}
              clipPath={resize?.clipPath}
              clipAspectRatio={resize?.clipAspectRatio}
              onFinishCrop={() => {
                requestAnimationFrame(() => {
                  editor.commands.refreshBubbleMenu?.()
                })
              }}
            />
          )}
          {resizingEnabled && !isCropping && isSelected && !isFullWidth && (
            <ResizableControls
              imageWrapperRef={imageWrapperRef}
              setIsResizing={setIsResizing}
              updateResizeAttrs={updateResizeAttrs}
              refreshDeps={[node.attrs, isAnimating, isWindowResizing]}
            />
          )}
          <Box
            // This div will get masked while you're cropping
            ref={imageWrapperRef}
            css={{
              mask: isCropping ? '' : 'none !important',
              // resizeImageToFitViewport will run when upload finishes, but in the meantime
              // set a max height using CSS.
              '--media-maxH':
                isUploading && !isResizing && !resize?.width && !inGallery
                  ? `${MAX_IMAGE_HEIGHT_VIEWPORT * 100}vh`
                  : undefined,
            }}
            sx={
              meta?.has_transparency
                ? undefined
                : isSelected && resizingEnabled
                ? { ...base.imageSx, borderRadius: '0px' }
                : base.imageSx
            }
            data-drag-handle // https://tiptap.dev/guide/node-views/react#dragging
            data-image-node-element
            data-selection-ring="inside"
            data-selection-background
            data-node-image-testid={id}
            w={
              isFullWidth || inGallery
                ? '100%'
                : widthPx
                ? `calc(${widthPx} * var(--font-size) / 16)`
                : 'fit-content'
            }
            maxW="100%"
            position="relative"
            overflow="hidden" // Apply rounded corners from theme
          >
            {/* Use an img tag to capture clicks so you can right click for copy image and copy image address */}
            {enableClickCapture && (
              <ZoomClickCapture
                enterZoom={enterZoomWithCheck}
                as={Image}
                src={isMobileDevice ? undefined : src}
                loading="lazy"
              />
            )}
            {/* Thumbnail loaded directly in the memo body */}
            <CroppedImage
              containerWidth={currentWidth}
              intrinsicAspectRatio={intrinsicAspectRatio || undefined}
              isCroppingThisImage={isCropping}
              imageAttrs={node.attrs as ImageNodeAttrs}
              onLoad={onImageLoad}
              ref={imageRef}
              // A specified width is needed to get SVGs to render. The max-width style applied in
              // contentStyles will keep this to max 100% of the container. We don't specify height
              // because that will be automatically inferred from the image's aspect ratio, and we
              // don't have a max-height to contain it.
              width={
                inGallery
                  ? 'auto'
                  : widthPx || isResizing || isFullWidth || isSvg
                  ? `100%`
                  : meta?.width
              }
              // On Safari, setting width: 100% messes up the gallery flex layout, but min-width: 100% works in both Safari and Chrome
              minW={inGallery ? '100%' : undefined}
              motionProps={motionProps}
              objectFit={inGallery ? 'cover' : 'contain'}
              isThumbnail={inGallery || editor.isThumbnail}
            />
            {renderZoomableOverlay && (
              <ZoomableOverlay
                isZoomed={isZoomed}
                exitZoom={exitZoom}
                editor={editor}
              >
                <CroppedImage
                  imageAttrs={node.attrs as ImageNodeAttrs}
                  backgroundColor={backgroundColor}
                  w={isSvg ? '80vw' : undefined}
                  motionProps={{
                    layoutDependency: isZoomed,
                    layoutId: getZoomLayoutId(id),
                    transition: ZoomTransition,
                  }}
                  isZoomed
                />
              </ZoomableOverlay>
            )}
            {/* Upload status indicators */}
            {isUploading && isEditable && <MediaPlaceholderSpinner />}
            {uploadStatus == UploadStatus.Error && (
              <MediaPlaceholderErrorUploadingTag />
            )}
          </Box>
        </Flex>
      )}
    </AnnotatableNodeViewWrapper>
  )
}
