import { Box, Image, Badge, HStack, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ListBox, ListBoxItem, ListBoxList } from '@gamma-app/ui'
import { Editor } from '@tiptap/core'
import { Range } from '@tiptap/react'
import { forwardRef, MutableRefObject, useCallback } from 'react'

import { CreditCostIcon } from 'modules/credits/components/CreditCostIcon'
import { useLocalizedFunction } from 'modules/i18n/hooks/useLocalizedFunction'
import { NodeInsertMethods } from 'modules/segment'
import {
  checkCommandDisabled,
  getSortedCommands,
  trackItemInserted,
} from 'modules/tiptap_editor/commands'
import { useSuggestionKeyboardHandler } from 'modules/tiptap_editor/extensions/Suggestion'

interface SlashMenuDropdownProps {
  editor: Editor
  range: Range
  query: string
}

const SlashMenuDropdownComponent = (
  { query, editor }: SlashMenuDropdownProps,
  ref: MutableRefObject<any>
): any => {
  const SORTED_COMMANDS = useLocalizedFunction(getSortedCommands)
  const options = SORTED_COMMANDS.filter((command) => {
    if (
      checkCommandDisabled(editor, command) ||
      command.type === 'cardTemplate' // filter out card templates
    ) {
      return false
    }
    const { name, keywords = [] } = command
    return (
      name().toLowerCase().includes(query.toLowerCase()) ||
      keywords.find((keyword) => keyword.startsWith(query.toLowerCase()))
    )
  })
  const selectItem = useCallback(
    (index: number) => {
      if (!options[index]) return
      const { execute } = options[index]
      const selection = editor.state.selection
      editor
        .chain()
        .deleteRange({
          from: selection.from - query.length - 1, // -1 for the slash
          to: selection.to,
        })
        .run()
      trackItemInserted(options[index], NodeInsertMethods.SLASH_MENU)
      execute(editor)
    },
    [editor, options, query.length]
  )

  const { selectedIndex, selectedItemEl } = useSuggestionKeyboardHandler({
    ref,
    selectItem,
    options,
  })

  return options.length > 0 ? (
    <Box data-slash-menu-dropdown>
      <ListBox>
        <ListBoxList width="375px">
          {options.map(
            (
              { name, icon, iconStyle, image, shortcut, creditType, badge },
              index
            ) => (
              <ListBoxItem
                ref={index === selectedIndex ? selectedItemEl : null}
                icon={
                  image ? (
                    <Image
                      mt={1}
                      h="1.5em"
                      w="1.25em"
                      objectFit="contain"
                      src={image.src}
                    />
                  ) : icon ? (
                    <FontAwesomeIcon
                      icon={icon}
                      rotation={iconStyle?.rotation}
                      size="1x"
                      fixedWidth={true}
                      color="var(--chakra-colors-trueblue-600)"
                    />
                  ) : (
                    <></>
                  )
                }
                tabIndex={index === selectedIndex ? 0 : -1}
                key={index}
                onClick={() => selectItem(index)}
                command={shortcut}
              >
                {name()}
                {creditType && (
                  <CreditCostIcon
                    type={creditType}
                    colorScheme="blackAlpha"
                    bg="whiteAlpha.800"
                  />
                )}
                {badge && (
                  <Badge ml={2} colorScheme="green">
                    {badge()}
                  </Badge>
                )}
              </ListBoxItem>
            )
          )}
        </ListBoxList>
      </ListBox>
    </Box>
  ) : (
    <></>
  )
}

export const SlashMenuDropdown = forwardRef(SlashMenuDropdownComponent)
