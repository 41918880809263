import { Box, Flex, SimpleGrid } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'
import { memo } from 'react'

import { HEADING_CLASS } from 'modules/theming/styles/heading'
import { getThemeBase } from 'modules/theming/themeBases'
import { useShouldRenderMobileVersion } from 'modules/tiptap_editor/hooks'
import { useElementSize } from 'utils/breakpoints/useElementSize'

import { getAlignStyles } from '../../HorizontalAlign/HorizontalAlign'
import { HorizontalAlignment } from '../../HorizontalAlign/types'
import { EditableLabel } from '../components/EditableLabel'
import { useCellColor } from '../components/hooks'
import { CELL_PADDING, EmptyCellContent } from '../constants'
import { CellSizeOption, useNumColumns } from '../options/CellSizeOption'
import { ColorOption } from '../options/ColorOption'
import { HorizontalAlignOption } from '../options/HorizontalAlignOption'
import { OrientationOption } from '../options/OrientationOption'
import {
  SmartLayoutCellComponent,
  SmartLayoutCellProps,
  SmartLayoutVariant,
  SmartLayoutWrapperComponent,
} from '../types'

const CELL_SPACING = '1.5em'

const useLayoutIsHorizontal = (
  options: SmartLayoutCellProps['layoutOptions'],
  numCells: number,
  containerSize: number
) => {
  const isMobile = useShouldRenderMobileVersion()
  const { columns } = useNumColumns({ containerSize, numCells, options })
  if (
    options.orientation === 'vertical' ||
    isMobile ||
    (numCells > 1 && columns === 1)
  ) {
    return { columns: 1, isHorizontal: false }
  }
  return {
    columns,
    isHorizontal: true,
  }
}

export const ArrowsWrapper: SmartLayoutWrapperComponent = memo(
  ({ children, options, numCells, containerSize }) => {
    const { isHorizontal, columns } = useLayoutIsHorizontal(
      options,
      numCells,
      containerSize
    )
    const gridProps = isHorizontal
      ? {
          columns,
          rowGap: CELL_SPACING,
        }
      : { columns: 1 }

    return (
      <SimpleGrid data-selection-ring {...gridProps} pos="relative">
        {children}
      </SimpleGrid>
    )
  }
)
ArrowsWrapper.displayName = 'ArrowsWrapper'

export const ArrowsCell: SmartLayoutCellComponent = memo((cellProps) => {
  const {
    layoutOptions,
    children,
    theme,
    numCells,
    layoutContainerSize,
    dragHandle,
  } = cellProps
  const base = getThemeBase(theme)
  const { isHorizontal } = useLayoutIsHorizontal(
    layoutOptions,
    numCells,
    layoutContainerSize
  )
  const horizontalAlign: HorizontalAlignment = layoutOptions.horizontalAlign
  const alignStyles = getAlignStyles(horizontalAlign || 'left')

  const contentProps = isHorizontal
    ? {
        minW: 0, // Allows columns to shrink if the container is too small
        transformOrigin: 'center top',
        p: CELL_PADDING,
        pt: 0,
      }
    : {
        transformOrigin: 'left center',
        p: CELL_PADDING,
        px: 0,
      }
  return (
    <Flex
      direction={
        isHorizontal
          ? 'column'
          : horizontalAlign === 'right'
          ? 'row-reverse'
          : 'row'
      }
      data-selection-ring
      data-content-reference
      pos="relative"
      gap={CELL_SPACING}
      minH={isHorizontal ? undefined : '8em'}
    >
      <ArrowsLabel {...cellProps} />
      <Box
        flex="1"
        sx={{ ...contentProps, ...alignStyles, ...base.smartLayoutContentSx }}
      >
        {children}
      </Box>
      {dragHandle}
    </Flex>
  )
})
ArrowsCell.displayName = 'ArrowsCell'

const ARROW_THICKNESS_EM = 4
const ARROW_THICKNESS_EM_VERTICAL = 5
const ARROW_LENGTH_EM = 1

const ArrowsLabel: SmartLayoutCellComponent = memo(
  ({
    layoutOptions,
    cellOptions,
    label,
    index,
    updateAttributes,
    theme,
    numCells,
    layoutContainerSize,
  }) => {
    const base = getThemeBase(theme)
    const { colorVars } = useCellColor(layoutOptions, cellOptions)
    const { isHorizontal } = useLayoutIsHorizontal(
      layoutOptions,
      numCells,
      layoutContainerSize
    )

    const { width, height, ref: resizeRef } = useElementSize()
    // In SVGs, we have to be explicit about the width and height but it needs to scale with the font size
    // so we calculate the font size at renderer time. useElementSize ensures this is reactive
    const fontSize = resizeRef.current
      ? parseFloat(window.getComputedStyle(resizeRef.current).fontSize)
      : 16
    const arrowThickness =
      (isHorizontal ? ARROW_THICKNESS_EM : ARROW_THICKNESS_EM_VERTICAL) *
      fontSize
    const arrowLength = ARROW_LENGTH_EM * fontSize

    // Draw the chevron so the thickness of the arrow and its pointiness are proportional to the font size,
    // meaning they'll scale naturally in filmstrip and present mode
    const chevronPath = isHorizontal
      ? `
    M ${width - arrowLength} 0
    L ${width} ${arrowThickness / 2}
    L ${width - arrowLength} ${arrowThickness}
    L 0 ${arrowThickness}
    L ${arrowLength} ${arrowThickness / 2}
    L 0 0 Z`
      : `
    M 0 ${height - arrowLength}
    L ${arrowThickness / 2} ${height}
    L ${arrowThickness} ${height - arrowLength}
    L ${arrowThickness} 0
    L ${arrowThickness / 2} ${arrowLength}
    L 0 0 Z`

    const sizeProps = isHorizontal
      ? {
          width: '100%',
          height: `${ARROW_THICKNESS_EM}em`,
        }
      : {
          width: `${ARROW_THICKNESS_EM_VERTICAL}em`,
          height: '100%',
        }

    return (
      <Flex
        lineHeight="1"
        contentEditable={false}
        align="center"
        justify="center"
        position="relative"
        sx={{
          ...colorVars,
          ...base.svgShapeSx,
          ...base.smartLayoutContentSx,
        }}
        {...sizeProps}
        ref={resizeRef}
        data-export-as-image
      >
        <Box as="svg" pos="absolute" inset="0" overflow="visible">
          <Box as={'path'} d={chevronPath} sx={base.svgShapeSx} />
        </Box>
        <EditableLabel
          value={label || ''}
          my="0.5em"
          updateAttributes={updateAttributes}
          placeholder={`${index + 1}`}
          pos="absolute"
          inset="0"
          className={cx(HEADING_CLASS)}
          fontSize="1.5em"
          display="flex"
          alignItems="center"
          sx={getAlignStyles('center')}
          style={{
            width: '100%',
          }}
        />
      </Flex>
    )
  }
)
ArrowsLabel.displayName = 'ArrowsLabel'

export const Arrows: SmartLayoutVariant = {
  key: 'arrows',
  name: () => t`Arrows`,
  commandName: () => t`Arrows layout (blank)`,
  icon: regular('forward'),
  options: [
    { ...OrientationOption, defaultValue: 'horizontal' },
    ColorOption,
    HorizontalAlignOption,
    CellSizeOption,
  ],
  cellOptions: [ColorOption],
  Wrapper: ArrowsWrapper,
  Cell: ArrowsCell,
  defaultContent: EmptyCellContent,
  addDirection(options) {
    return options.orientation === 'horizontal' ? 'right' : 'bottom'
  },
  isFullWidth(options) {
    return options.orientation === 'horizontal'
  },
  htmlTag: 'arrows',
  keywords: ['sequence', 'ordered', 'steps', 'arrows', 'chevrons', 'roadmap'],
}
