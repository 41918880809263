import { Box, BoxProps, HStack } from '@chakra-ui/react'
import { useGammaTooltipHider } from '@gamma-app/ui'
import { Editor, isNodeSelection } from '@tiptap/core'
import { NodeSelection, Selection, TextSelection } from 'prosemirror-state'
import { Decoration } from 'prosemirror-view'
import { useMemo } from 'react'

import { featureFlags } from 'modules/featureFlags'
import { isAccentCardLayoutItem } from 'modules/tiptap_editor/extensions/Card/CardLayout/cardLayoutUtils'
import { getDocFlags } from 'modules/tiptap_editor/extensions/DocFlags/docFlags'
import {
  isLayoutCellNode,
  isLayoutNode,
} from 'modules/tiptap_editor/extensions/Layout/utils'
import { isGalleryNode } from 'modules/tiptap_editor/extensions/media/Gallery'
import {
  isSmartLayoutCellNode,
  isSmartLayoutNode,
} from 'modules/tiptap_editor/extensions/SmartLayout/isSmartLayoutCellNode'
import { CellSelection } from 'modules/tiptap_editor/extensions/tables/prosemirror-table'
import {
  getDecorationsForNode,
  isCardNode,
} from 'modules/tiptap_editor/utils/nodeHelpers'

import {
  BubbleMenu,
  BubbleMenuProps,
} from '../../../extensions/BubbleMenu/BubbleMenu'
import { isMediaEmbedNode } from '../../../extensions/media/utils'
import { useEditorUpdateDuringSelection } from '../../../hooks'
import { ButtonFormattingMenu } from './ButtonFormattingMenu'
import { CalloutBoxFormattingMenu } from './CalloutBoxFormattingMenu'
import { CardFormattingMenu } from './CardFormattingMenu'
import { CardLayoutItemFormattingMenu } from './CardLayoutItemFormattingMenu'
import { CardTOCFormattingMenu } from './CardTOCFormattingMenu'
import { ContributorsFormattingMenu } from './ContributorsFormattingMenu'
import { DrawingFormattingMenu } from './DrawingFormattingMenu'
import { EmbedVideoFormattingMenu } from './EmbedVideoFormattingMenu'
import { GalleryFormattingMenu } from './GalleryFormattingMenu'
import { ImageFormattingMenu } from './ImageFormattingMenu'
import { LayoutCellFormattingMenu } from './LayoutCellFormattingMenu'
import { LayoutFormattingMenu } from './LayoutFormattingMenu'
import { LinkFormattingMenu } from './LinkFormattingMenu'
import { SiteButtonFormattingMenu } from './sites/SiteButtonFormattingMenu'
import { SmartLayoutCellFormattingMenu } from './SmartLayoutCellFormattingMenu'
import { SmartLayoutFormattingMenu } from './SmartLayoutFormattingMenu'
import { TableFormattingMenu } from './TableFormattingMenu'
import { TextFormattingMenu } from './TextFormattingMenu'
import { NodeFormattingMenuProps } from './types'

type FormattingMenuProps = {
  editor: Editor
  scrollingParentSelector?: string
  menuContext?: 'navigation'
}

type FormattingMenuType = {
  component: React.FC<
    | NodeFormattingMenuProps
    | {
        editor: Editor
        selection: Selection
        decorations?: Decoration[]
      }
  >
  predicate: (selection: Selection, editor: Editor) => boolean
  offsetPx?: number
}

const FORMATTING_MENUS: FormattingMenuType[] = [
  {
    component: TableFormattingMenu,
    predicate: (selection): selection is CellSelection =>
      selection instanceof CellSelection && selection.isColSelection(),
    offsetPx: 16,
  },
  {
    component: TableFormattingMenu,
    predicate: (selection): selection is CellSelection =>
      selection instanceof CellSelection && selection.isRowSelection(),
    offsetPx: 12,
  },
  {
    component: LinkFormattingMenu,
    predicate: (selection, editor): selection is TextSelection =>
      selection instanceof TextSelection && editor.isActive('link'),
  },
  {
    component: ButtonFormattingMenu,
    predicate: (_selection, editor) =>
      editor.isActive('button') &&
      !editor.isSiteEditor &&
      !editor.isSiteNavbarEditor,
  },
  {
    component: SiteButtonFormattingMenu,
    predicate: (_selection, editor) => {
      return (
        editor.isActive('button') &&
        Boolean(editor.isSiteEditor || editor.isSiteNavbarEditor)
      )
    },
  },
  {
    component: LayoutFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection && isLayoutNode(selection.node),
  },
  {
    component: CardFormattingMenu,
    predicate: (selection): selection is NodeSelection => {
      /**
       * Check if either we are using CardView2 in card 1 or
       * if the current doc has card layouts enabled via getDocFlags
       * TODO(jordan): remove both of these checks when we deprecate card1
       * and move verything to card2
       */
      const docFlags = getDocFlags(selection.$from.doc) || {}
      const card1InCard2 = featureFlags.get('card1InCard2')
      const enabled = docFlags.cardLayoutsEnabled || card1InCard2
      const isExpandedCardNodeSel =
        selection instanceof NodeSelection && isCardNode(selection.node)
      // && selectCardCollapsed(selection.node.attrs.id)(getStore().getState()) === false

      return enabled && isExpandedCardNodeSel
    },
    offsetPx: 0,
  },
  {
    component: CardLayoutItemFormattingMenu,
    predicate: (selection) => {
      const res =
        selection instanceof NodeSelection &&
        isAccentCardLayoutItem(selection.node)
      return res
    },
  },
  {
    component: LayoutCellFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection && isLayoutCellNode(selection.node),
  },
  {
    component: SmartLayoutFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection && isSmartLayoutNode(selection.node),
  },
  {
    component: SmartLayoutCellFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection &&
      isSmartLayoutCellNode(selection.node),
  },
  {
    component: GalleryFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection && isGalleryNode(selection.node),
  },
  {
    component: CalloutBoxFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection &&
      selection.node.type.name === 'calloutBox',
  },
  {
    component: DrawingFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection &&
      selection.node.type.name === 'drawing',
  },
  {
    component: ImageFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection &&
      (selection.node.type.name === 'image' ||
        selection.node.type.name === 'mediaPlaceholder'),
  },
  {
    component: EmbedVideoFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection && isMediaEmbedNode(selection.node),
  },
  {
    component: CardTOCFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection &&
      selection.node.type.name === 'tableOfContents',
  },
  {
    component: ContributorsFormattingMenu,
    predicate: (selection): selection is NodeSelection =>
      selection instanceof NodeSelection &&
      selection.node.type.name === 'contributors',
  },
  {
    component: TextFormattingMenu,
    predicate: (selection, editor): selection is TextSelection =>
      selection instanceof TextSelection,
  },
]

export const NAVBAR_FORMATTING_MENUS: FormattingMenuType[] = [
  {
    component: SiteButtonFormattingMenu,
    predicate: (_selection, editor) => {
      return (
        editor.isActive('button') &&
        Boolean(editor.isSiteEditor || editor.isSiteNavbarEditor)
      )
    },
  },
]
const menuStyles: BoxProps = {
  border: '1px solid',
  borderColor: 'gray.200',
  backdropFilter: 'blur(20px)',
  backgroundColor: '#F9FAFBEE',
  shadow: 'lg',
}

export const FormattingMenu = ({
  editor,
  scrollingParentSelector,
  menuContext,
}: FormattingMenuProps) => {
  // Trigger a re-render whenever the selection changes or content is
  // updated while the selection is not empty, as our menus need to re-calc
  // This prevents the menus from re-rendering on every keystroke
  useEditorUpdateDuringSelection(editor)
  const { hideTooltips, GammaTooltipHiderContext } = useGammaTooltipHider()
  const menusToUse =
    menuContext === 'navigation' ? NAVBAR_FORMATTING_MENUS : FORMATTING_MENUS
  const selection = editor.state.selection
  const activeMenu = menusToUse.find((menu) =>
    menu.predicate(selection, editor)
  )
  let decorations: Decoration[] = []
  try {
    if (isNodeSelection(editor.state.selection)) {
      decorations = getDecorationsForNode(editor, editor.state.selection.from)
    }
  } catch (e) {
    console.error(
      `[FormattingMenu] unable to find decorations at ${editor.state.selection.from}`,
      e.message
    )
  }

  // @ts-ignore - tippy.js only wants a number for z-index, but the string works correctly
  const tippyOptions: BubbleMenuProps['tippyOptions'] = useMemo(
    () => ({
      maxWidth: 'none',
      zIndex: 'var(--chakra-zIndices-popover)',
      placement: 'top',
      popperOptions: {
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top', 'bottom'],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              boundary: scrollingParentSelector
                ? document.querySelector(scrollingParentSelector)
                : undefined,
            },
          },
        ],
      },
    }),
    [scrollingParentSelector]
  )

  return (
    <GammaTooltipHiderContext>
      <BubbleMenu
        editor={editor}
        onHide={hideTooltips}
        tippyOptions={tippyOptions}
      >
        {activeMenu && (
          <Box
            borderRadius="xl"
            {...menuStyles}
            w="auto"
            minW="0px"
            h="fit-content"
            position="relative"
            bottom={activeMenu.offsetPx ? `${activeMenu.offsetPx}px` : '0'}
            data-in-editor-focus
            data-testid="formatting-menu"
          >
            <HStack spacing={3} px={2} minH={10}>
              <activeMenu.component
                editor={editor}
                selection={selection}
                decorations={decorations}
              />
            </HStack>
          </Box>
        )}
      </BubbleMenu>
    </GammaTooltipHiderContext>
  )
}
