import {
  HStack,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import clamp from 'lodash/clamp'

import { useShouldRenderMobileVersion } from 'modules/tiptap_editor/hooks'
import { isPuppeteer } from 'utils/export'

import { SmartLayoutOption, SmartLayoutOptionControlProps } from '../types'

const COLUMN_WIDTH = 10
const FULL_WIDTH = COLUMN_WIDTH * 4

type CellSize = {
  targetColumns: number // Used for grid-based layouts
  width: number // In em, used for flex-based layouts
  label: string
}

// Widths here are based on a base card size of FULL_WIDTH
export const getCellSizes = (): Record<string, CellSize> => {
  return {
    sm: {
      targetColumns: 4,
      label: t`Small`,
      width: 10,
    },
    md: {
      targetColumns: 3,
      label: t`Medium`,
      width: 15,
    },
    lg: {
      targetColumns: 2,
      label: t`Large`,
      width: 20,
    },
    xl: {
      targetColumns: 1,
      label: t`Extra Large`,
      width: 25,
    },
  }
}

const CellSizeOptionControl = ({
  value,
  updateValue,
  option,
}: SmartLayoutOptionControlProps<number>) => {
  const size = Object.values(getCellSizes()).find((s) => s.width === value)

  return (
    <HStack spacing={2} mr={2}>
      <GammaTooltip
        placement="top"
        label={
          <VStack spacing={0}>
            <Text color="gray.400">{option.name()}</Text>
            <Text>{size?.label}</Text>
          </VStack>
        }
      >
        <Text fontSize="sm" fontWeight="600">
          <FontAwesomeIcon icon={regular('columns-3')} />
        </Text>
      </GammaTooltip>
      <Slider
        min={getCellSizes().sm.width}
        max={getCellSizes().xl.width}
        step={5}
        value={value}
        onChange={updateValue}
        w="60px"
        focusThumbOnChange={false}
        colorScheme="trueblue"
        size="sm"
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        {size ? (
          <GammaTooltip
            placement="top"
            label={
              <VStack spacing={0}>
                <Text color="gray.400">{option.name()}</Text>
                <Text>{size.label}</Text>
              </VStack>
            }
          >
            <SliderThumb background="trueblue.500" />
          </GammaTooltip>
        ) : (
          <SliderThumb background="trueblue.500" />
        )}
      </Slider>
    </HStack>
  )
}

export const CellSizeOption: SmartLayoutOption<number> = {
  key: 'cellSize',
  defaultValue: getCellSizes().md.width,
  Control: CellSizeOptionControl,
  getDefaultOnParse(elt) {
    const numChildren = elt.childElementCount
    if (numChildren % 2 === 0) {
      return getCellSizes().lg.width // Two columns
    } else {
      return getCellSizes().md.width // Three columns
    }
  },
  name: () => t`Column size`,
}

export const useNumColumns = ({ containerSize, numCells, options }) => {
  // First, get the target # columns based on the user's size preference
  const targetColumns =
    Object.values(getCellSizes()).find((s) => s.width === options.cellSize)
      ?.targetColumns || 3
  const targetWidth = FULL_WIDTH / targetColumns

  // Then, figure out the max that can fit
  const isMobile = useShouldRenderMobileVersion()
  const breakpointMaxColumns =
    useBreakpointValue({
      base: 1,
      sm: 2,
      md: 3,
      xl: 4,
    }) || 2
  // Breakpoints dont work in print exports, so override there
  const deviceMaxColumns = isPuppeteer ? 4 : isMobile ? 2 : breakpointMaxColumns

  const maxWidth = clamp(
    FULL_WIDTH * containerSize, // Use the container size from the decoration,
    COLUMN_WIDTH, // Minimum of 1 column
    deviceMaxColumns * COLUMN_WIDTH // Max of the device width
  )
  const maxColumns = Math.max(1, Math.floor(maxWidth / targetWidth))
  const columns = Math.min(maxColumns, numCells)

  // Force balance. Commenting out til we find the right logic here
  // If you end up with 5 cells this will go all the way down to 1 col which feels broken
  // if (numCells > columns) {
  //   // Find the number of columns that keeps even rows
  //   // Ex: if we have 4 cells but 3 columns, drop down to 2 so we get 2x2
  //   for (; columns >= 1; columns--) {
  //     if (numCells % columns == 0) {
  //       break
  //     }
  //   }
  // }

  // console.log('useNumColumns', {
  //   targetColumns,
  //   breakpointMaxColumns,
  //   deviceMaxColumns,
  //   maxWidth,
  //   maxColumns,
  //   columns,
  // })

  return {
    maxWidth,
    targetWidth,
    targetColumns,
    maxColumns,
    columns,
  }
}
