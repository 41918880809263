import { Flex, Spacer, Stack, Text } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'

type ExportButtonProps = {
  formatLabel: string
  icon: FontAwesomeIconProps['icon']
  accentColor: string
  onClick: () => void
  exportInProgress: boolean
  exportInProgressNote?: React.ReactNode
  badge?: React.ReactNode
}

export const ExportButton = ({
  formatLabel,
  icon,
  accentColor,
  onClick,
  exportInProgress,
  exportInProgressNote,
  badge,
}: ExportButtonProps) => {
  return (
    <Flex
      alignItems="stretch"
      overflow="hidden"
      onClick={onClick}
      borderRadius="md"
      border="1px"
      borderColor="gray.200"
      bgColor={exportInProgress ? 'gray.50' : 'white'}
      cursor={exportInProgress ? 'progress' : undefined}
      disabled={exportInProgress}
      as={'button'}
      transitionProperty="common"
      transitionDuration="normal"
      _hover={
        exportInProgress
          ? {}
          : {
              boxShadow: 'var(--chakra-shadows-md)',
              borderColor: `var(--chakra-colors-${accentColor}-300)`,
              color: `var(--chakra-colors-${accentColor}-700)`,
            }
      }
    >
      <Flex
        bgColor={exportInProgress ? 'gray.200' : `${accentColor}.100`}
        color={`${exportInProgress ? 'gray' : accentColor}.500`}
        width={16}
        minH={16}
        alignItems="center"
        justifyContent="center"
        fontSize="2xl"
      >
        <FontAwesomeIcon icon={icon} fixedWidth />
      </Flex>
      <Stack alignItems="flex-start" spacing={0} p={4} justifyContent="center">
        <Text fontWeight={'medium'}>
          <Trans>Export to {formatLabel}</Trans> {badge}
        </Text>
        {exportInProgress && (
          <Text color="gray.600" fontSize="xs">
            <Trans>Your {formatLabel} is being generated.</Trans>{' '}
            {exportInProgressNote}
          </Text>
        )}
      </Stack>
      <Spacer />
      {!exportInProgress && (
        <Flex alignItems="center" pl={4} pr={6}>
          <FontAwesomeIcon icon={regular('arrow-down-to-bracket')} size="1x" />
        </Flex>
      )}
    </Flex>
  )
}
