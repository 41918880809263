import { EventEmitter } from 'utils/EventEmitter'

type SuggestionEditorEvents = {
  openSuggestionEditor: {
    cardId: string | null
    userMessage: string
  }
}

export const eventEmitter = new EventEmitter<SuggestionEditorEvents>()
