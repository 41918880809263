import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'

import { CommandInfo } from 'modules/tiptap_editor/commands'

import { CalloutBoxOption, CalloutBoxVariant } from './types'
export const getCalloutBoxOptions = (): Record<
  CalloutBoxVariant,
  CalloutBoxOption
> => {
  return {
    note: {
      name: t`Note`,
      commandName: t`Note box`,
      description: t`Draw attention using your theme accent color`,
      icon: regular('sticky-note'),
      colorScheme: 'accent',
    },
    info: {
      name: t`Info`,
      commandName: t`Info box`,
      description: t`Highlight information in a blue box`,
      icon: regular('info-circle'),
      colorScheme: 'blue',
    },
    warning: {
      name: t`Warning`,
      commandName: t`Warning box`,
      icon: regular('triangle-exclamation'),
      description: t`Call out risks in a yellow box`,
      colorScheme: 'yellow',
    },
    caution: {
      name: t`Caution`,
      commandName: t`Caution box`,
      description: t`Emphasize problems in a red box`,
      icon: regular('xmark-circle'),
      colorScheme: 'red',
    },
    success: {
      name: t`Success`,
      commandName: t`Success box`,
      description: t`Add positive news in a green box`,
      icon: regular('check-circle'),
      colorScheme: 'green',
    },
    question: {
      name: t`Question`,
      commandName: t`Question box`,
      description: t`Capture open questions in a gray box`,
      icon: regular('question-circle'),
      colorScheme: 'gray',
    },
  }
}
export const getCalloutBoxOption = (
  option: CalloutBoxVariant
): CalloutBoxOption => {
  return getCalloutBoxOptions()[option] || getCalloutBoxOptions().note
}

export const getCalloutBoxCommands = () =>
  Object.entries(getCalloutBoxOptions()).map(
    ([variant, { commandName, icon }]) => {
      const command: CommandInfo = {
        key: `calloutBox-${variant}`,
        name: () => commandName,
        nodeName: 'calloutBox',
        icon,
        shortcut: `/${variant}`,
        keywords: ['callout', 'box'],
        execute: (editor) =>
          editor.chain().focus().wrapIn('calloutBox', { variant }).run(),
      }
      return command
    }
  )

export const CalloutBoxEmptyNodes = Object.fromEntries(
  Object.keys(getCalloutBoxOptions()).map((variant) => [
    `calloutBox-${variant}`,
    {
      type: 'calloutBox',
      attrs: {
        variant,
      },
      content: [
        {
          type: 'paragraph',
        },
      ],
    },
  ])
)
