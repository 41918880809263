import { Flex, FlexProps } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { DarkModeProvider } from '@gamma-app/ui'

import { useAppSelector } from 'modules/redux'
import { getBackgroundProps } from 'modules/tiptap_editor/styles/backgroundStyles'
import { preventDefaultToAllowDrop } from 'utils/handlers'

import { selectAnimationsEnabled } from '../../../reducer'
import {
  BETWEEN_CARD_TRANSITION_TIME,
  CARD_PRESENTING_CLASS,
  CARD_WRAPPER_CLASS,
} from '../constants'
import { PresentVariant } from '../hooks/usePresentVariant'
import { CardAttributes } from '../types'

type Card2WrapperProps = {
  attrs: CardAttributes
  isPresentMode: boolean
  isNested: boolean
  isDark: boolean
  isThumbnail?: boolean
  presentVariant: PresentVariant
  children: React.ReactNode
} & FlexProps

export const Card2Wrapper: React.FC<Card2WrapperProps> = ({
  attrs,
  children,
  presentVariant,
  isPresentMode,
  isDark,
  isNested,
  isThumbnail,
  css,
}) => {
  const { background, id: cardId } = attrs
  const animationsEnabled = useAppSelector(selectAnimationsEnabled)
  const bgProps =
    isNested || isThumbnail ? undefined : getBackgroundProps(background, isDark)
  return (
    <Flex
      onDragOver={preventDefaultToAllowDrop} // Allows drops on the edges of a card, or between them https://stackoverflow.com/a/21341021
      top={0}
      left={0}
      position="relative"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      cursor="default"
      data-animate-value={presentVariant}
      fontSize="var(--editor-font-size, 1rem)" // Reset font sizes for nested cards
      py="var(--card-outer-padding-y)"
      // --doc-padding is set in Doc.tsx and comes from panels opening on the left and right
      pl="var(--card-outer-padding-left)"
      pr="var(--card-outer-padding-right)"
      data-card-id={cardId} // Used in spotlight
      data-card-scroll-element // The element that scrolls in present mode (used for PDF export)
      overflow={isPresentMode && !isNested ? 'hidden auto' : undefined}
      className={cx(
        CARD_WRAPPER_CLASS,
        'card-expanded',
        isPresentMode && CARD_PRESENTING_CLASS,
        isPresentMode &&
          `card-present-mode card-present-mode--${presentVariant}`
      )}
      transitionProperty="transform, padding"
      transitionDuration={`${
        animationsEnabled ? BETWEEN_CARD_TRANSITION_TIME : 0
      }ms`}
      {...bgProps}
      css={css}
    >
      <DarkModeProvider isDark={isDark}>{children}</DarkModeProvider>
    </Flex>
  )
}

Card2Wrapper.displayName = 'CardContainer'
