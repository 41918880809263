import React, { PropsWithChildren, useContext } from 'react'

export type PreviewContextType = {
  previewModeEnabled: boolean
}

export const PreviewContext = React.createContext<PreviewContextType>({
  previewModeEnabled: false,
})

export const usePreviewContext = () => {
  const ctx = useContext(PreviewContext)
  return ctx
}

const enabledValue = { previewModeEnabled: true }

// Allows forcing the Preview react editor to force mobile mode using useShouldRenderMobileVersion
export const PreviewContextProvider = ({ children }: PropsWithChildren<{}>) => {
  return (
    <PreviewContext.Provider value={enabledValue}>
      {children}
    </PreviewContext.Provider>
  )
}
