import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'
import cloneDeep from 'lodash/cloneDeep'

import { CommandInfo, setDraggingContent } from '../../../commands'
import FlywheelImage from '../thumbnails/Flywheel.svg'
import QuadrantImage from '../thumbnails/Quadrants.svg'
import VennImage from '../thumbnails/Venn.svg'
import { generateDrawingId, setDrawingExpanded } from '../utils'
import { DrawingTemplates } from './templates'

export const DrawingCommands: CommandInfo[] = [
  {
    key: 'diagram',
    name: () => t`Blank diagram`,
    keywords: ['sketch', 'drawing', 'shapes', 'diagram'],
    shortcut: '/diagram',
  },
  {
    key: 'flywheel',
    name: () => t`Flywheel diagram`,
    keywords: ['cycle', 'circle', 'diagram', 'flywheel'],
    previewImage: FlywheelImage,
  },
  {
    key: 'quadrant',
    name: () => t`2x2 matrix diagram`,
    keywords: ['matrix', 'quadrant', 'diagram', '2x2'],
    previewImage: QuadrantImage,
  },
  {
    key: 'venn',
    name: () => t`Venn diagram`,
    previewImage: VennImage,
    keywords: ['venn', 'diagram'],
  },
].map((item): CommandInfo => {
  const node = DrawingTemplates[item.key] || {
    type: 'drawing',
    attrs: {},
  }

  return {
    ...item,
    nodeName: 'drawing',
    icon: duotone('shapes'),
    featureFlag: 'drawingBlock',
    execute: (editor) => {
      const drawingId = generateDrawingId()
      const newNode = cloneDeep(node)
      newNode.attrs.id = drawingId
      setDrawingExpanded(drawingId, true)
      editor.commands.insertContent(newNode)
    },
    dragStartFn: (editor) => {
      const drawingId = generateDrawingId()
      const newNode = cloneDeep(node)
      newNode.attrs.id = drawingId
      setDrawingExpanded(drawingId, true)
      setDraggingContent(editor, newNode)
    },
  }
})
