import { Box, Button, Link, Text } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { NodeViewProps } from '@tiptap/core'

import { useAppSelector } from 'modules/redux'
import { useSiteEnabledLink } from 'modules/sites/hooks/useSiteEnabledLink'
import { CLICKABLE_BOX_CLASS } from 'modules/theming/styles/box'
import {
  HEADING_CLASS,
  HEADING_NO_GRADIENT_CLASS,
} from 'modules/theming/styles/heading'

import { NodeViewContent, NodeViewWrapper } from '../../react'
import { selectContentEditable } from '../../reducer'
import { isNodeViewEmpty } from '../EmptyNodes'
import { isFocusedInside } from '../selection/utils'
import { ButtonAttrs } from './types'

export const ButtonView = (nodeViewProps: NodeViewProps) => {
  const { node, decorations } = nodeViewProps
  const { href, variant } = node.attrs as ButtonAttrs
  const isEditable = useAppSelector(selectContentEditable)
  const isFocused = isFocusedInside(decorations)
  const isEditing = isEditable && isFocused
  const isEmpty = isNodeViewEmpty(decorations)
  const showPlaceholder = isEmpty

  const { linkUrl, handleClick, isCurrentPage } = useSiteEnabledLink({
    url: href,
  })

  const buttonProps =
    variant === 'solid'
      ? {
          backgroundColor: 'var(--link-color)',
          backgroundImage: 'var(--accent-gradient)',
          border: '0px',
        }
      : variant === 'outline'
      ? {
          border: '0.125em solid var(--link-color)',
          backgroundColor: 'var(--card-color)',
        }
      : undefined

  return (
    <NodeViewWrapper>
      <Button
        as={isEditing ? Box : Link}
        variant="unstyled"
        display="inline-flex"
        cursor={isEditable ? 'text' : 'pointer'}
        userSelect={isEditable ? 'auto' : 'none'}
        href={linkUrl || undefined}
        onClick={handleClick}
        pos="relative"
        style={buttonProps}
        // Reset default link styling
        _hover={{ textDecoration: 'none' }}
        // Force the sizes to scale with present mode
        minW="min(100%, 12em)"
        maxW="100%"
        fontSize="1em"
        py="0.5em"
        minH={'2.75em'}
        w="var(--button-width)"
        h="auto"
        px={variant === 'link' ? '0.5em' : '1em'}
        className={cx(
          'button',
          variant !== 'link' && CLICKABLE_BOX_CLASS,
          variant === 'solid' && HEADING_NO_GRADIENT_CLASS
        )}
        data-variant={variant}
        data-export-as-image
        data-export-link={href}
        // Prevents a double transition on font size that's too slow
        transitionProperty="none"
        sx={{
          '@media print': {
            width: 'auto',
          },
          outline: isFocused
            ? '2px solid var(--chakra-ring-color) !important'
            : undefined,
        }}
        data-is-current-page={isCurrentPage}
      >
        <Text
          as="div"
          noOfLines={isEditing || showPlaceholder ? undefined : 1}
          wordBreak="break-all"
          className={cx(variant !== 'link' && HEADING_CLASS)}
          style={{
            color:
              variant === 'solid'
                ? 'var(--link-color-inverted)'
                : variant === 'outline'
                ? 'var(--link-color)'
                : undefined,
          }}
          textAlign="center"
        >
          {showPlaceholder && (
            <Text
              opacity="0.3"
              pointerEvents="none"
              contentEditable={false}
              pos="absolute"
              w="100%"
              left="0"
              textAlign="center"
            >
              …
            </Text>
          )}
          <NodeViewContent />
        </Text>
      </Button>
    </NodeViewWrapper>
  )
}
