import { findParentNode } from '@tiptap/core'
import TiptapBlockquote from '@tiptap/extension-blockquote'
import { liftTarget } from 'prosemirror-transform'

import { ReactNodeViewRenderer } from '../../react'
import { attrsOrDecorationsChanged } from '../updateFns'
import { BlockquoteView } from './BlockquoteView'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    blockquote: {
      unwrapQuoteOnDelete: () => ReturnType
    }
  }
}

export const Blockquote = TiptapBlockquote.extend({
  // Prevent nesting blockquotes inside blockquotes
  group: 'cardBlock layoutBlock footnoteBlock tableBlock cardNotesBlock',
  content: '(block | calloutBlock)+',

  addNodeView() {
    return ReactNodeViewRenderer(BlockquoteView, {
      update: attrsOrDecorationsChanged,
    })
  },

  addCommands() {
    return {
      ...this.parent?.(),
      unwrapQuoteOnDelete:
        () =>
        ({ state, tr }) => {
          const { selection } = state
          if (!selection.empty) return false
          const parentQuote = findParentNode(
            (n) => n.type.name === Blockquote.name
          )(selection)
          if (!parentQuote) return false

          // +2 = inside the quote > inside the textblock
          // Could also be +1 if we have a GapCursor
          const isAtEdge = selection.from <= parentQuote.pos + 2
          if (!isAtEdge) return false
          const range = selection.$from.blockRange(selection.$to)
          if (!range) return false
          const target = liftTarget(range)
          if (!target) return false
          tr.lift(range, target)
          return true
        },
    }
  },
})
