import {
  selectActiveNoteCardId,
  setCardNoteProps,
} from 'modules/card_notes/reducer'
import { useSyncEditorSelection } from 'modules/card_notes/useSyncEditorSelection'
import { useAppDispatch, useAppSelector } from 'modules/redux'
import { InnerEditorNodeViewProps } from 'modules/tiptap_editor/extensions/Footnote/InnerEditorNodeView'

import { findCardPluginDecoration } from '../CardPlugin'
import { isSelectionWithinTopCardAtPos } from './helpers'

/**
 * This node view doesn't return a React element, but instead
 * is responsible for updating the cards notes redux state based on the current
 * selection in the editor.
 */
export const CardNotesNodeView = (nodeViewProps: InnerEditorNodeViewProps) => {
  const { decorations, editor, getPos, destroyEditor, mountEditor } =
    nodeViewProps
  const dispatch = useAppDispatch()
  useSyncEditorSelection(editor)
  const currentActiveNoteCardId = useAppSelector(selectActiveNoteCardId)
  const { parentCardId, isNested } = findCardPluginDecoration(decorations)

  if (
    parentCardId !== currentActiveNoteCardId &&
    isSelectionWithinTopCardAtPos(editor, getPos()) &&
    mountEditor &&
    !isNested
  ) {
    dispatch(
      setCardNoteProps({
        activeNoteCardId: parentCardId,
        mountEditor,
        destroyEditor,
      })
    )
  }
}
