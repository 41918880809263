// Updated to support SSR rendering

import React from 'react'

import { useShouldUsePublishedVersion } from 'modules/sites/PublishingContext'

import { NodeViewContent as SSRNodeViewContent } from '../SSRReactEditor/NodeViewContent'
import { useReactNodeView } from './useReactNodeView'

export interface NodeViewContentProps {
  [key: string]: any
  as?: React.ElementType
}

export const NodeViewContent: React.FC<NodeViewContentProps> = (props) => {
  const shouldUsePublishedVersion = useShouldUsePublishedVersion()
  if (shouldUsePublishedVersion) {
    return <SSRNodeViewContent {...props} />
  }
  return <NodeViewContentCore {...props} />
}

export const NodeViewContentCore: React.FC<NodeViewContentProps> = (props) => {
  const Tag = props.as || 'div'
  const { nodeViewContentRef } = useReactNodeView()

  return (
    <Tag
      {...props}
      ref={nodeViewContentRef}
      data-node-view-content=""
      style={{
        whiteSpace: 'pre-wrap',
        ...props.style,
      }}
    />
  )
}

NodeViewContentCore.displayName = 'NodeViewContentCore'
