import { Editor } from '@tiptap/core'
import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import { selectIsCardNotesPanelOpen } from 'modules/card_notes/reducer'
import { featureFlags } from 'modules/featureFlags'
import { useAppStore } from 'modules/redux'

import { findTopCardNodeParent } from '../cardNavigationUtils'
import { isCardNotesEditor } from './helpers'

/**
 * When the Card Notes panel is open, this plugin adds a class to the editor DOM node
 * and the active parent card. This is used to highlight the active card and dim the rest.
 * The styles applied are defined in cardNotesEditorStyles.ts.
 */
export const CardNotesSelectionPlugin = (
  editor: Editor,
  store: ReturnType<typeof useAppStore>
) =>
  new Plugin({
    props: {
      attributes: () => {
        const isCardNotesEnabled = featureFlags.get('cardNotes')
        if (
          selectIsCardNotesPanelOpen(store.getState()) &&
          isCardNotesEnabled &&
          !isCardNotesEditor(editor)
        ) {
          // Add a class to the editor DOM node
          return {
            class: 'card-notes-active',
          }
        }

        return {
          class: '',
        }
      },

      decorations: ({ doc, selection }) => {
        const isCardNotesEnabled = featureFlags.get('cardNotes')
        if (
          !selectIsCardNotesPanelOpen(store.getState()) ||
          !isCardNotesEnabled ||
          isCardNotesEditor(editor)
        ) {
          return
        }
        const parentCard = findTopCardNodeParent(editor, selection.from)
        if (!parentCard) {
          return
        }
        // Add a class to the active parent card
        return DecorationSet.create(doc, [
          Decoration.node(
            parentCard.pos,
            parentCard.pos + parentCard.node.nodeSize,
            {
              class: 'card-notes-active-parent-card',
            }
          ),
        ])
      },
    },
  })
