import { CheckIcon } from '@chakra-ui/icons'
import { Button, ButtonGroup, Image } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t, Trans } from '@lingui/macro'

import { DeleteNodeButton } from '../buttons/DeleteNodeButton'
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownList,
} from '../ToolbarDropdown'
import { FormattingMenuDivider } from './FormattingMenuDivider'
import Icon3Col from './icons/layout-3-cols.svg'
import Icon3070 from './icons/layout-30-70.svg'
import Icon4Col from './icons/layout-4-cols.svg'
import Icon5050 from './icons/layout-50-50.svg'
import Icon7030 from './icons/layout-70-30.svg'
import { NodeFormattingMenuProps } from './types'

type ColPreset = {
  name: string
  image: any
  value: number[]
  testId: string
}

const getColumnPresents = (): ColPreset[] => {
  return [
    {
      name: t`2 columns - equal`,
      testId: 'layout-preset-5050',
      image: Icon5050,
      value: [50, 50],
    },
    {
      name: t`2 columns - left`,
      testId: 'layout-preset-7030',
      image: Icon7030,
      value: [70, 30],
    },
    {
      name: t`2 columns - right`,
      testId: 'layout-preset-3070',
      image: Icon3070,
      value: [30, 70],
    },
    {
      name: t`3 columns`,
      testId: 'layout-preset-3col',
      image: Icon3Col,
      value: [33.33, 33.33, 33.34],
    },
    {
      name: t`4 columns`,
      testId: 'layout-preset-4col',
      image: Icon4Col,
      value: [25, 25, 25, 25],
    },
  ]
}

export const LayoutFormattingMenu = ({
  editor,
  selection,
}: NodeFormattingMenuProps) => {
  const { node: layout, from: layoutPos } = selection

  const checkActive = (value: number[]) => {
    if (!layout) {
      return false
    }

    return JSON.stringify(value) === JSON.stringify(layout.attrs.colWidths)
  }

  return (
    <>
      <ButtonGroup spacing={1} size="sm" alignItems="center">
        <Dropdown isLazy>
          <DropdownButton
            as={Button}
            variant="toolbar"
            rightIcon={<FontAwesomeIcon icon={regular('chevron-down')} />}
            size="sm"
            data-testid="layout-preset-dropdown"
          >
            <Trans>{layout.childCount} columns</Trans>
          </DropdownButton>
          <DropdownList data-in-editor-focus fontSize="md">
            {getColumnPresents().map(({ testId, name, image, value }) => {
              return (
                <DropdownItem
                  key={name}
                  data-testid={testId}
                  icon={
                    checkActive(value) ? (
                      <CheckIcon w="2rem" />
                    ) : (
                      <Image
                        h="1rem"
                        w="2rem"
                        objectFit="cover"
                        src={image.src}
                      />
                    )
                  }
                  onClick={() => {
                    if (!layoutPos) {
                      return
                    }
                    editor.commands.useLayoutPreset(layoutPos, value)
                  }}
                >
                  {name}
                </DropdownItem>
              )
            })}
          </DropdownList>
        </Dropdown>
        {/* <FullWidthButton editor={editor} selection={selection} /> */}
        <FormattingMenuDivider />
        <DeleteNodeButton editor={editor} />
      </ButtonGroup>
    </>
  )
}
