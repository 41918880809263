import { Box, Button, ButtonGroup, HStack, Text } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t, Trans } from '@lingui/macro'
import { NodeViewProps } from '@tiptap/core'
import { memo, useCallback } from 'react'

import { useGetCardText } from 'modules/cards'
import { useAppDispatch, useAppSelector } from 'modules/redux'
import { useAnalytics } from 'modules/segment/SegmentContextProvider'
import { SegmentEvents } from 'modules/segment/segmentEvents'
import {
  ClickableButtonSx,
  clickableHoverSx,
  CLICKABLE_BOX_CLASS,
} from 'modules/theming/styles/box'
import { HEADING_CLASS } from 'modules/theming/styles/heading'
import { getThemeCSSVars } from 'modules/theming/styles/variables'
import { EMOJI_REGEX } from 'modules/tiptap_editor/extensions/Emoji/regex'
import {
  selectTheme,
  setFollowingAttached,
} from 'modules/tiptap_editor/reducer'
import { getBackgroundColor } from 'modules/tiptap_editor/styles/backgroundStyles'
import { domPurify } from 'utils/sanitize'

import { AnnotatableNodeViewWrapper } from '../../Annotatable'
import { ContainerDragHandle } from '../../DragDrop/ContainerDragHandle/ContainerDragHandle'
import { isExpandableSelectedNode } from '../../ExpandableNodes/ExpandableNodes'
import { getFontSizeStyles } from '../../Font/fontStyles'
import { setCardCollapsed } from '../CardCollapse'
import { CARD_WRAPPER_CLASS } from '../constants'
import { useCardState } from '../hooks/useCardState'
import { CardAttributes } from '../types'

const CollapsedCardTitleStyles = getFontSizeStyles('h4')

export const CollapsedCard = memo((nodeViewProps: NodeViewProps) => {
  const { decorations, editor, getPos, node } = nodeViewProps
  const analytics = useAnalytics()
  const dispatch = useAppDispatch()

  const cardState = useCardState(nodeViewProps)
  const { cardId, isPresentMode, isEditable, isMobileDevice } = cardState

  const { title, text } = useGetCardText({ node, cardId })

  const { previewContent, container } = nodeViewProps.node
    .attrs as CardAttributes
  const hidePreview = previewContent === ''
  // Wrap emojis within the title in a span so they don't rendered as gradient text.
  const titleHtml = title?.replaceAll(EMOJI_REGEX, (match) => {
    return `<span class="emoji">${match}</span>`
  })

  const theme = useAppSelector(selectTheme)
  const bgColor = getBackgroundColor(container.background)
  const vars = bgColor && getThemeCSSVars(theme, undefined, bgColor)

  // Expandable selection
  const isExpandableSelected = isExpandableSelectedNode(decorations)

  const expandCard = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      if (isPresentMode && !isEditable) {
        dispatch(setFollowingAttached({ attached: false }))
        editor.commands.descendIntoCurrentCard(getPos(), 'push')
        analytics?.track(SegmentEvents.CARD_EXPANDED, {
          is_present_mode: true,
          method: 'click',
        })
      } else {
        setCardCollapsed(cardId, false)
        setTimeout(() => {
          if (!isEditable || editor.isDestroyed) return
          // Select just inside the opened card
          const pos = getPos()
          editor.commands.selectInsideNodeAtPos(pos)
        }, 50) // Wait until it's opened so contentEditable is true
        analytics?.track(SegmentEvents.CARD_EXPANDED, {
          is_present_mode: false,
          method: 'click',
        })
      }
    },
    [cardId, isPresentMode, isEditable, dispatch, editor, getPos, analytics]
  )

  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps}>
      <ContainerDragHandle
        {...nodeViewProps}
        data-testid="card-drag-handle"
        handlePlacement="outside"
      />
      <Box
        mb="0.25em"
        position="relative"
        contentEditable={false}
        userSelect="none"
        role="group"
        data-card-id={cardId}
        data-export-as-image
      >
        <ButtonGroup
          position="absolute"
          right={'0.75em'}
          top={'0.75em'}
          data-print-hidden
          variant="unstyled"
          colorScheme="gray"
          data-thumbnail-hidden
        >
          {isEditable && (
            <CollapsedCardPreviewMenu
              hidePreview={hidePreview}
              {...nodeViewProps}
            />
          )}
          <CollapsedCardExpandButton onClick={expandCard} />
        </ButtonGroup>

        <Box
          cursor="pointer"
          className={cx(
            CLICKABLE_BOX_CLASS,
            CARD_WRAPPER_CLASS,
            'card-collapsed',
            isExpandableSelected && 'expandable-selected'
          )}
          _groupHover={clickableHoverSx} // Always use the hover transition, even when editable
          paddingX={isMobileDevice ? '1em' : '1.5em'}
          paddingY={isMobileDevice ? '1em' : '1em'}
          onClick={expandCard}
          data-collapsed-card
          data-selection-ring
          data-selection-background
          data-content-reference // Used for drag previews
          css={vars}
          sx={{
            backgroundColor: bgColor,
          }}
        >
          <Text
            sx={CollapsedCardTitleStyles}
            noOfLines={1}
            mr={'1.75em'} // Make room for card controls
            mb={'0.375em'}
            className={cx(HEADING_CLASS)}
          >
            {titleHtml ? (
              <Text
                as="span"
                dangerouslySetInnerHTML={{
                  __html: domPurify.sanitize(titleHtml),
                }}
              />
            ) : title ? (
              title
            ) : (
              <Text as="span" color="gray.400">
                <Trans>Untitled card</Trans>
              </Text>
            )}
          </Text>
          <HStack color="var(--body-color-muted)">
            <Text noOfLines={1} fontSize="0.9em">
              {hidePreview || !text?.trim() ? <Trans>View more</Trans> : text}
            </Text>
            {hidePreview && (
              <FontAwesomeIcon icon={regular('chevron-down')} size="sm" />
            )}
          </HStack>
        </Box>
      </Box>
    </AnnotatableNodeViewWrapper>
  )
})

CollapsedCard.displayName = 'CollapsedCard'

const CollapsedCardPreviewMenu = ({ hidePreview, updateAttributes }) => {
  const togglePreview = useCallback(
    (ev) => {
      if (!updateAttributes) return
      // Set previewContent to empty string to hide it and show "view more" instead
      // Reset to null to show default
      updateAttributes({ previewContent: hidePreview ? null : '' })
      ev.stopPropagation()
    },
    [updateAttributes, hidePreview]
  )
  return (
    <Button
      opacity={0}
      transitionProperty="opacity"
      transitionDuration="normal"
      _groupHover={{
        opacity: 1,
      }}
      sx={ClickableButtonSx}
      fontSize="0.7em"
      onClick={togglePreview}
      className={CLICKABLE_BOX_CLASS}
      zIndex={1}
    >
      {hidePreview ? <Trans>Show preview</Trans> : <Trans>Hide preview</Trans>}
    </Button>
  )
}

const CollapsedCardExpandButton = ({ onClick }) => {
  return (
    <Button
      aria-label={t`Expand card`}
      className={CLICKABLE_BOX_CLASS}
      onClick={onClick}
      zIndex={1}
      sx={ClickableButtonSx}
      fontSize="0.7em"
      data-testid="expand-card-button"
    >
      <FontAwesomeIcon icon={regular('expand-alt')} fontSize="0.9em" />
      <Text
        as="span"
        _groupHover={{ opacity: 1, display: 'inline' }}
        opacity="0"
        display="none"
        ml={2}
      >
        <Trans>Expand</Trans>
      </Text>
    </Button>
  )
}
