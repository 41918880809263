import { Mark } from '@tiptap/core'
import { mergeAttributes } from '@tiptap/react'

import { ExtensionPriorityMap } from '../constants'
import { ValidTextColors } from './textColorStyles'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    textColor: {
      // Deprecated. Old text colors were a semantic variant. New ones are a hex color.
      setTextColorVariant: (variant: string) => ReturnType
      setTextColor: (hex: string) => ReturnType
    }
  }
}

export const TextColor = Mark.create({
  name: 'textColor',
  excludes: 'highlight textColor',
  priority: ExtensionPriorityMap.TextColor,
  addAttributes() {
    return {
      // Deprecated. Old text colors were a semantic variant. New ones are a hex color.
      variant: {},
      hex: {},
    }
  },
  addCommands() {
    return {
      setTextColorVariant:
        (variant) =>
        ({ commands }) => {
          return commands.setMark(this.name, { hex: null, variant })
        },
      setTextColor:
        (hex) =>
        ({ commands }) => {
          return commands.setMark(this.name, { hex, variant: null })
        },
    }
  },
  parseHTML() {
    return [
      {
        tag: 'span[class=textColor]',
      },
      {
        tag: '*[color]',
        getAttrs(elt: HTMLElement) {
          const color = elt.getAttribute('color')
          if (!color) {
            return false
          }
          if (!ValidTextColors.includes(color)) {
            // If it's not a semantic variant, use it as a hex color directly
            return {
              hex: color,
            }
          }
          return {
            variant: color,
          }
        },
        // This lets us match a tag like <h1 color="red">. It will run before the H1 rule due to the higher
        // priority, and consuming: false means the H1 rule can still match it.
        consuming: false,
        priority: 60,
      },
    ]
  },

  // Used in the editor, and regular copy and pastes
  renderHTML({ HTMLAttributes, mark }) {
    return [
      'span',
      mergeAttributes(HTMLAttributes, {
        class: 'textColor',
        style: mark.attrs.hex ? `--text-color: ${mark.attrs.hex}` : undefined,
      }),
    ]
  },

  renderHTMLforAI({ mark }) {
    return [
      'span',
      {
        color: mark.attrs.hex ?? mark.attrs.variant,
      },
    ]
  },
})
