import { JSONContent } from '@tiptap/core'

import { isEmojiDataLoaded } from 'modules/emoji'
import { handleReplaceNativeEmojis } from 'modules/tiptap_editor/extensions/Emoji/utils'

export const migrateNativeEmojisInJSONContent = (
  content: JSONContent[]
): void => {
  if (!isEmojiDataLoaded() || !content || content.length === 0) {
    return
  }

  for (let i = 0; i < content.length; i++) {
    traverseAndReplaceEmojiJSONContent(content[i], content, i)
  }
}

const traverseAndReplaceEmojiJSONContent = (
  n: JSONContent,
  parent: JSONContent[],
  childIndex: number
): void => {
  if (n.type === 'text') {
    if (!n.text) {
      return
    }
    const { replaced, nodes } = handleReplaceNativeEmojis(n.text, n.marks)
    if (replaced) {
      parent.splice(childIndex, 1, ...nodes)
    }
    // is text node and has replaced, no children, can return
    return
  }

  if (n.content && n.content.length > 0) {
    for (let i = 0; i < n.content.length; i++) {
      traverseAndReplaceEmojiJSONContent(n.content[i], n.content, i)
    }
  }
}
