import { Button } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'

import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownList,
} from 'modules/tiptap_editor/components/menus/ToolbarDropdown'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { SmartLayoutOptionControlProps } from '../types'

export type DropdownChoice = {
  name: string
  icon: FontAwesomeIconProps['icon']
  disabled?: boolean
}

type DropdownOptionControlProps = SmartLayoutOptionControlProps & {
  choices: Record<string, DropdownChoice>
  defaultIcon?: FontAwesomeIconProps['icon']
}

export const DropdownOptionControl = ({
  value,
  updateValue,
  choices,
  defaultIcon,
  option,
}: DropdownOptionControlProps) => {
  return (
    <Dropdown isLazy>
      <GammaTooltip placement="top" label={option.name()} shouldWrapChildren>
        <DropdownButton
          as={Button}
          variant="toolbar"
          onMouseDown={preventDefaultToAvoidBlur}
          rightIcon={
            <FontAwesomeIcon
              icon={regular('chevron-down')}
              transform="shrink-6"
            />
          }
        >
          <FontAwesomeIcon icon={choices[value]?.icon || defaultIcon} />
        </DropdownButton>
      </GammaTooltip>
      <DropdownList>
        {Object.entries(choices).map(([key, { icon, name, disabled }]) => {
          if (disabled) return null
          return (
            <DropdownItem
              key={key}
              icon={<FontAwesomeIcon icon={icon} fixedWidth />}
              onClick={() => updateValue(key)}
            >
              {name}
            </DropdownItem>
          )
        })}
      </DropdownList>
    </Dropdown>
  )
}
