import {
  Box,
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t, Trans } from '@lingui/macro'
import { findChildren } from '@tiptap/core'
import { merge } from 'lodash'
import { useCallback, useEffect, useMemo, useRef } from 'react'

import { useAppSelector } from 'modules/redux'
import { useShouldUsePublishedVersion } from 'modules/sites/PublishingContext'
import { getThemeCSSVars } from 'modules/theming/styles/variables'
import { NodeViewWrapper } from 'modules/tiptap_editor/react'
import { SimpleEditorContent } from 'modules/tiptap_editor/SSRReactEditor/EditorContent'
import { editorStyles } from 'modules/tiptap_editor/styles/editorStyles'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { MediaDrawer } from '../../components/drawers/MediaDrawer/MediaDrawer'
import { FormattingMenu } from '../../components/menus/FormattingMenus'
import { EditorContent } from '../../react/EditorContent'
import {
  selectContentEditable,
  selectExpandedNoteId,
  selectTheme,
} from '../../reducer'
import { editorHasFocus } from '../../utils'
import { CARD_CONTENT_CLASS } from '../Card/constants'
import { setFootnoteExpanded } from './FootnoteState'
import { useToggleFootnoteOnClick } from './hooks'
import { InnerEditorNodeViewProps } from './InnerEditorNodeView'
const GUTTER_PIXELS = 8

export const FootnoteView = (nodeViewProps: InnerEditorNodeViewProps) => {
  const { node, editor, innerEditor, selected } = nodeViewProps
  const { noteId } = node.attrs
  const footnoteRef = useRef<HTMLDivElement>(null)

  // Check in redux to see if we're hovering, because the mark will dispatch here
  const expandedNoteId = useAppSelector(selectExpandedNoteId)
  const editable = useAppSelector(selectContentEditable)

  const shouldUsePublishedVersion = useShouldUsePublishedVersion()

  const { onClick } = useToggleFootnoteOnClick(editor, noteId, footnoteRef)

  // Prevent editing notes when outer editor isn't editable
  useEffect(() => {
    if (!innerEditor) return
    innerEditor.setEditable(editable)
  }, [editable, innerEditor])

  const isOpen = expandedNoteId ? expandedNoteId === noteId : false

  const onClose = useCallback(
    () => setFootnoteExpanded(noteId, false),
    [noteId]
  )

  useEffect(() => {
    if (!innerEditor) return
    const onEditorBlur = () => {
      setTimeout(() => {
        if (!editorHasFocus(innerEditor)) {
          onClose()
        }
      }, 20)
    }
    innerEditor.on('blur', onEditorBlur)
    return () => {
      innerEditor.off('blur', onEditorBlur)
    }
  }, [innerEditor, onClose])

  const onFootnoteClick = useCallback(() => {
    onClick()
  }, [onClick])

  return (
    <NodeViewWrapper as="span" data-testid="footnote-nodeview-wrapper">
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        placement="top"
        gutter={GUTTER_PIXELS}
        isLazy
        lazyBehavior="unmount"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={false}
        closeOnBlur={false} // Use our custom logic above instead of Chakra's
        closeOnEsc={false}
        returnFocusOnClose={false}
      >
        <PopoverTrigger>
          <Text
            as="span"
            onClick={onFootnoteClick}
            _after={{
              // This counter is setup in Doc.tsx
              content: 'counter(footnote)',
              counterIncrement: 'footnote',
              position: 'relative',
              bottom: '0.6em',
              fontSize: '0.8em',
              shadow: selected ? 'outline' : undefined,
              borderRadius: 'sm',
            }}
            pl="1px"
            className="footnote"
            contentEditable={false}
            data-footnote-view
            cursor="pointer"
          />
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            zIndex="popover"
            shadow="md"
            borderRadius="md"
            border="1px solid"
            borderColor="gray.200"
            w="500px"
            maxW="100vw"
            data-footnote-popover-test-id={noteId}
            p={0}
            ref={footnoteRef}
          >
            <PopoverArrow />
            <PopoverBody
              position="relative"
              zIndex="1"
              p={0}
              className="footnote-popover"
              data-footnote-popover-id={noteId}
            >
              {shouldUsePublishedVersion ? (
                <StaticNoteViewer {...nodeViewProps} onClose={onClose} />
              ) : (
                <NoteEditor
                  {...nodeViewProps}
                  editable={editable}
                  onClose={onClose}
                />
              )}
            </PopoverBody>
            {/* Make the hover target bigger on the edges so you don't fall in the gap */}
            <Box
              position="absolute"
              top={`-${GUTTER_PIXELS}px`}
              bottom={`-${GUTTER_PIXELS}px`}
              left={0}
              right={0}
              zIndex="0"
            />
          </PopoverContent>
        </Portal>
      </Popover>
    </NodeViewWrapper>
  )
}

const InnerEditorStyles = merge({}, editorStyles, {
  '.ProseMirror': {
    px: 8,
    py: 4,
    // This scrolling needs to happen here, because bubble-menu-plugin
    // mounts to the direct parent of the editor and that needs to be
    // outside the overflow
    overflow: 'hidden auto',
    maxH: '300px',
    maxW: '100%',
    borderRadius: 'md',
  },
})

const NoteEditor = ({
  editor,
  innerEditor,
  mountEditor,
  destroyEditor,
  node,
  editable,
  getPos,
  onClose,
}: InnerEditorNodeViewProps & { editable: boolean; onClose: () => void }) => {
  const { noteId } = node.attrs

  const convertToCard = useCallback(
    () => editor.commands.convertNoteToCard(noteId),
    [editor, noteId]
  )

  const deleteFootnote = useCallback(() => {
    editor.chain().focus().setNodeSelection(getPos()).deleteSelection().run()
  }, [editor, getPos])

  // Mount the editor when we open the footnote, then destroy it when we close
  useEffect(() => {
    if (!mountEditor || !destroyEditor) return
    mountEditor()
    return () => {
      destroyEditor()
    }
  }, [mountEditor, destroyEditor])

  const theme = useAppSelector(selectTheme)
  const cssVars = getThemeCSSVars(theme, false, '#FFFFFF')

  if (!innerEditor) return null

  const hasLinkedMark =
    findChildren(editor.state.doc, (childNode) =>
      childNode.marks.some(
        (mark) =>
          mark.type.name === 'footnoteLabel' && mark.attrs.noteId === noteId
      )
    ).length > 0

  return (
    <>
      <Box
        className={CARD_CONTENT_CLASS}
        sx={InnerEditorStyles}
        position="relative"
        _focusWithin={{ shadow: editable ? 'outline' : undefined }}
        borderRadius="md"
        css={cssVars}
      >
        <EditorContent editor={innerEditor} className="highlight-mask" />
        <MediaDrawer editor={innerEditor} />
        <ButtonGroup
          size="sm"
          colorScheme="gray"
          variant="ghost"
          position="absolute"
          right={4}
          top={2}
          onMouseDown={preventDefaultToAvoidBlur}
          spacing={0}
        >
          {editable && (
            <Menu autoSelect={false} isLazy>
              <MenuButton
                as={IconButton}
                icon={<FontAwesomeIcon icon={regular('ellipsis')} />}
                minW={6}
                h={6}
                isRound
                backgroundColor="white"
              ></MenuButton>
              <Portal>
                <MenuList data-in-editor-focus zIndex="dropdown">
                  {hasLinkedMark && (
                    <MenuItem
                      icon={
                        <span className="fa-layers fa-fw">
                          <FontAwesomeIcon
                            icon={solid('rectangle')}
                            fixedWidth
                          />
                          <FontAwesomeIcon
                            icon={solid('arrow-right')}
                            inverse
                            transform="shrink-8"
                          />
                        </span>
                      }
                      onClick={convertToCard}
                    >
                      <Trans>Convert to card</Trans>
                    </MenuItem>
                  )}
                  <MenuItem
                    icon={
                      <FontAwesomeIcon icon={regular('trash')} fixedWidth />
                    }
                    color="red.500"
                    onClick={deleteFootnote}
                  >
                    <Trans>Delete footnote</Trans>
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          )}
          <IconButton
            aria-label={t`Close footnote`}
            icon={<FontAwesomeIcon icon={regular('times')} />}
            minW={6}
            h={6}
            isRound
            onClick={onClose}
            backgroundColor="white"
          />
        </ButtonGroup>
      </Box>
      <FormattingMenu editor={innerEditor} />
    </>
  )
}

// A simplified version of the footnote editor. It's read-only and
// suitable for SSR.
const StaticNoteViewer = ({
  mountEditor,
  destroyEditor,
  onClose,
}: InnerEditorNodeViewProps & { onClose: () => void }) => {
  const innerEditor = useMemo(() => {
    if (!mountEditor) return
    return mountEditor()
  }, [mountEditor])

  useEffect(() => {
    if (!destroyEditor) return
    return () => destroyEditor()
  }, [destroyEditor])

  const theme = useAppSelector(selectTheme)
  const cssVars = getThemeCSSVars(theme, false, '#FFFFFF')

  if (!innerEditor) return null

  return (
    <>
      <Box
        className={CARD_CONTENT_CLASS}
        sx={InnerEditorStyles}
        position="relative"
        borderRadius="md"
        css={cssVars}
      >
        <SimpleEditorContent editor={innerEditor} />
        <ButtonGroup
          size="sm"
          colorScheme="gray"
          variant="ghost"
          position="absolute"
          right={4}
          top={2}
          onMouseDown={preventDefaultToAvoidBlur}
          spacing={0}
        >
          <IconButton
            aria-label={t`Close footnote`}
            icon={<FontAwesomeIcon icon={regular('times')} />}
            minW={6}
            h={6}
            isRound
            onClick={onClose}
            backgroundColor="white"
          />
        </ButtonGroup>
      </Box>
    </>
  )
}
