import { CloseIcon } from '@chakra-ui/icons'
import { Box, Flex, IconButton, Portal } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { Editor } from '@tiptap/core'
import { motion } from 'framer-motion'
import { isHotkey } from 'is-hotkey'
import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { keyboardHandler } from 'modules/keyboard'
import { setModalOpen } from 'modules/modal_state'
import { preventDefaultToAvoidBlur } from 'utils/handlers'
import { useEffectWhen } from 'utils/hooks'

import { OVERLAY_PADDING, ZoomTransition } from './constants'

const MotionBox = motion(Box)

export type ZoomableOverlayProps = {
  children: React.ReactNode
  isZoomed: boolean
  exitZoom: () => void
  editor: Editor
}

// Renders a lightbox containing a zoomed/interactive version of a media block, animating up from its original position.
// This should be placed inside a container with position: relative so it can handle clicks and position correctly.
export const ZoomableOverlay = ({
  children,
  isZoomed,
  exitZoom,
  editor,
}: ZoomableOverlayProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const keydownListener = (e: KeyboardEvent) => {
      if (isZoomed && ['ArrowDown', 'ArrowUp'].includes(e.key)) {
        e.preventDefault()
        return true
      }
      if ((isHotkey('Esc')(e) || isHotkey('Enter')(e)) && isZoomed) {
        exitZoom()
        e.preventDefault()
        return true
      }
      return false
    }
    return keyboardHandler.on('keydown', 'ZOOMABLE_OVERLAY', keydownListener)
  }, [exitZoom, isZoomed])

  useEffect(() => {
    dispatch(setModalOpen({ id: 'zoomableOverlay', isOpen: isZoomed }))
  }, [dispatch, isZoomed])

  const exitButtonRef = useRef<HTMLButtonElement>(null)

  // Blur the editor when overlay opens to avoid keyboard from editing in the background
  // Return focus when it closes, but only if the editor is editable
  useEffectWhen(
    () => {
      if (!editor.isEditable) return
      if (isZoomed) {
        exitButtonRef.current?.focus()
      } else {
        editor.commands.focus()
      }
    },
    [editor, isZoomed],
    [isZoomed],
    false // Only run when isZoomed changes from its initial value, not on first run
  )

  return (
    <Portal>
      {isZoomed && (
        <Box
          position="fixed"
          inset={0}
          padding={OVERLAY_PADDING}
          zIndex="modal"
          userSelect="none"
          // To prevent clicks from bubbling up here, use stopPropagation on the child element passed in
          onClick={exitZoom}
          // Prevents the close click from stealing focus from the editor
          onMouseDown={preventDefaultToAvoidBlur}
          data-in-editor-focus
          data-testid="zoomable-overlay"
        >
          <MotionBox
            position="absolute"
            inset={0}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.8 }}
            // This has no exit animation, because it would overlap the zoomed content
            // as it animates out. https://github.com/framer/motion/issues/260#issuecomment-1063502560
            // If we do bring back an exit animation, we'll need to wrap this in AnimatePresence
            // and make sure that goes outside the Portal. https://github.com/framer/motion/issues/1302
            transition={ZoomTransition}
            backgroundColor="black"
            key="shade"
          />
          <Box
            position="absolute"
            right={2}
            top={2}
            zIndex="docked"
            contentEditable={false}
            suppressContentEditableWarning
          >
            <IconButton
              aria-label={t`Close zoom`}
              colorScheme="gray"
              size="md"
              onClick={exitZoom}
              onMouseDown={preventDefaultToAvoidBlur}
              icon={<CloseIcon />}
              className="exit-button"
              ref={exitButtonRef}
            />
          </Box>
          <Flex
            position="relative"
            zIndex="1"
            h="100%"
            w="100%"
            align="center"
            justify="center"
            direction="column"
            css={{
              '--overlay-maxH': `calc(100vh - 2 * ${OVERLAY_PADDING})`,
              '--overlay-maxW': `calc(100vw - 2 * ${OVERLAY_PADDING})`,
              '--media-maxH': `var(--overlay-maxH)`,
              '--media-maxW': `var(--overlay-maxW)`,
            }}
          >
            {children}
          </Flex>
        </Box>
      )}
    </Portal>
  )
}
