import {
  Box,
  Text,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Stack,
  Tag,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { Editor } from '@tiptap/core'
import { Selection } from 'prosemirror-state'
import { useCallback, useState } from 'react'

import { useAppSelector } from 'modules/redux'
import { selectSite } from 'modules/sites/reducer'
import { getSiteManagerUrlSingleRoute } from 'modules/sites/utils'
import {
  BUTTON_GROUP_RULES,
  getButtonVariants,
} from 'modules/tiptap_editor/extensions/buttons/constants'

import { AlignmentCommands } from '../../../../extensions/HorizontalAlign/HorizontalAlign'
import { DeleteNodeButton } from '../../buttons/DeleteNodeButton'
import { ToolbarButton } from '../../buttons/ToolbarButton'
import { SiteLinksMenu } from './SiteLinksMenu'
import { getExistingLink, getLinkablePages } from './utils'

const ExistingSiteLink = ({
  existingLink,
  handleRemoveClick,
}: {
  existingLink
  handleRemoveClick: (type: 'pages' | 'url') => void
}) => {
  const isExternalUrl = existingLink?.href && !existingLink.docId
  const { title, href } = existingLink

  if (isExternalUrl) {
    return (
      <HStack spacing={2}>
        <InputGroup size="sm">
          <Input size="sm" isReadOnly={true} value={href} />
          <InputRightElement>
            <ToolbarButton
              label={<Trans>Edit link</Trans>}
              icon={regular('edit')}
              onClick={() => handleRemoveClick('url')}
            />
          </InputRightElement>
        </InputGroup>
      </HStack>
    )
  }

  return (
    <HStack spacing={1}>
      <Tag>
        <HStack spacing={1}>
          <FontAwesomeIcon icon={regular('file')} />
          <GammaTooltip label={title} placement="top">
            <Box noOfLines={1}>{title}</Box>
          </GammaTooltip>
          <GammaTooltip label={t`Edit link`} placement="top">
            <IconButton
              size="xxs"
              variant="ghost"
              aria-label={t`Edit link`}
              icon={<FontAwesomeIcon icon={regular('edit')} />}
              onClick={() => handleRemoveClick('pages')}
            />
          </GammaTooltip>
        </HStack>
      </Tag>
    </HStack>
  )
}

type SiteButtonFormattingMenuProps = {
  editor: Editor
  selection: Selection
}
export const SiteButtonFormattingMenu = ({
  editor,
  selection,
}: SiteButtonFormattingMenuProps) => {
  const site = useAppSelector(selectSite)
  const { linkablePages } = getLinkablePages({
    site,
  })
  const [initialTab, setInitialTab] = useState<'pages' | 'url'>('pages')
  const [initialHref, setInitialHref] = useState<string | null>(null)
  const href = editor.getAttributes('button').href || ''
  const deleteButton = useCallback(
    () => editor.commands.handleButtonDelete(false),
    [editor]
  )
  const groupNode = selection.$from.node(-1)
  const rules = BUTTON_GROUP_RULES[groupNode.type.name]
  const enabledVariants = getButtonVariants().filter((v) =>
    rules.variants.includes(v.key)
  )

  const existingLink = getExistingLink({ href, linkablePages })
  const newTabUrl =
    existingLink?.docId && site?.id
      ? getSiteManagerUrlSingleRoute(site?.id, existingLink.docId)
      : href

  return (
    <Stack w={existingLink ? undefined : '320px'} maxW="50vw">
      <HStack
        justify="space-between"
        pt={1}
        borderBottomWidth={existingLink ? 0 : 1}
        mx={-2}
        px={2}
      >
        {enabledVariants.length >= 2 &&
          enabledVariants.map(({ name, icon, key }) => {
            return (
              <ToolbarButton
                key={name}
                label={name}
                icon={icon}
                onClick={() => {
                  editor.commands.updateAttributes('button', { variant: key })
                }}
                isActive={editor.isActive('button', { variant: key })}
              />
            )
          })}
        {rules.alignEnabled &&
          AlignmentCommands.map(({ name, icon, checkActive, apply }) => {
            if (!checkActive || !apply) return
            return (
              <ToolbarButton
                key={name()}
                label={name()}
                icon={icon}
                onClick={() => {
                  apply(editor)
                }}
                isActive={checkActive(editor)}
              />
            )
          })}
        {existingLink && (
          <ExistingSiteLink
            existingLink={existingLink}
            handleRemoveClick={(type) => {
              setInitialTab(type)
              if (type === 'url') {
                setInitialHref(href)
              }
              editor.commands.updateAttributes('button', { href: null })
            }}
          />
        )}
        <Spacer />
        {existingLink && (
          <ToolbarButton
            label={<Trans>Open editor in new tab</Trans>}
            icon={regular('external-link-alt')}
            onClick={() => window.open(newTabUrl, '_blank')}
          />
        )}
        <DeleteNodeButton editor={editor} handleDelete={deleteButton} />
      </HStack>
      {!existingLink && (
        <SiteLinksMenu
          updateHref={(url) => {
            editor.commands.updateAttributes('button', { href: url })
          }}
          initialTab={initialTab}
          initialHref={initialHref}
          onChange={() => null}
        />
      )}
    </Stack>
  )
}
