export const configureIdAttribute = (getIdFn: () => string, name = 'id') => {
  return {
    default: null,
    parseHTML: (el: HTMLElement) => {
      const value = el.getAttribute(`data-${name}`)
      if (value) {
        return value
      }

      const id = getIdFn()
      console.debug('[configureIdAttribute] generating id in parseHTML', id)
      return id
    },
  }
}
