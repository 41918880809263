import {
  Button,
  ButtonGroup,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t, Trans } from '@lingui/macro'
import { Editor } from '@tiptap/core'
import { NodeSelection, TextSelection } from 'prosemirror-state'
import { useCallback } from 'react'

import { isFootnoteEditor } from 'modules/tiptap_editor/extensions/Footnote/utils'
import { isOtherSupportedProtocol } from 'modules/tiptap_editor/extensions/Link/utils'
import {
  addImageComment,
  isNodeInGallery,
} from 'modules/tiptap_editor/extensions/media/Gallery'
import {
  LinkAttrs,
  MediaEmbedAttrs,
} from 'modules/tiptap_editor/extensions/media/types'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { getEmbedProvider } from '../../../extensions/media/Embed'
import { useMediaZoom } from '../../../extensions/media/Zoomable'
import { useToggleMediaDrawer } from '../../drawers/MediaDrawer/utils'
import { DeleteNodeButton } from '../buttons/DeleteNodeButton'
import { ToolbarButton } from '../buttons/ToolbarButton'
import { FormattingMenuDivider } from './FormattingMenuDivider'
import { NodeFormattingMenuProps } from './types'

export const EmbedVideoFormattingMenu = ({
  editor,
  selection,
}: NodeFormattingMenuProps) => {
  const toggleMediaDrawer = useToggleMediaDrawer()
  const editNode = useCallback(
    (ev) => {
      toggleMediaDrawer(true)
      ev.stopPropagation()
    },
    [toggleMediaDrawer]
  )
  const inFootnote = isFootnoteEditor(editor)
  const inGallery = isNodeInGallery(selection.$from)
  const addComment = useCallback(() => {
    addImageComment(selection)
  }, [selection])
  const { enterZoom } = useMediaZoom(selection.node.attrs.id)

  return (
    <ButtonGroup spacing={0} size="sm" alignItems="center">
      {!inGallery && (
        <EmbedDisplayDropdown editor={editor} selection={selection} />
      )}
      <ToolbarButton
        label={<Trans>Zoom</Trans>}
        icon={regular('magnifying-glass-plus')}
        onClick={enterZoom}
      />
      {!inFootnote && (
        <ToolbarButton
          label={<Trans>Add comment</Trans>}
          icon={regular('comment')}
          onClick={addComment}
        />
      )}
      <ToolbarButton
        label={<Trans>Edit</Trans>}
        icon={regular('pencil')}
        onClick={editNode}
      />
      <FormattingMenuDivider />
      {/* <FullWidthButton editor={editor} selection={selection} /> */}
      <DeleteNodeButton editor={editor} />
    </ButtonGroup>
  )
}

const getOptions = () => {
  return {
    link: { title: t`Link` },
    preview: { title: t`Preview` },
    inline: { title: t`Inline` },
  }
}

type EmbedDisplayDropdownProps = {
  editor: Editor
  selection: TextSelection | NodeSelection
}

export const EmbedDisplayDropdown = ({
  editor,
  selection,
}: EmbedDisplayDropdownProps) => {
  const nodeOrMarkName =
    selection instanceof TextSelection ? 'link' : selection.node.type.name
  const isLink = nodeOrMarkName === 'link'
  const attrs = editor.getAttributes(nodeOrMarkName) as
    | Partial<MediaEmbedAttrs>
    | LinkAttrs
  const { embed, source } = attrs
  const provider = getEmbedProvider(source || undefined)
  const isTwitterEmbed = provider.key === 'embed.twitter'
  const options = getOptions()
  const onChange = useCallback(
    (newStyle: keyof typeof options) => {
      if (nodeOrMarkName === 'link' && newStyle !== 'link') {
        editor.commands.convertLinkToMedia(newStyle)
      } else if (newStyle === 'link') {
        editor.commands.convertMediaToLink()
        return
      }
      editor.commands.updateAttributes(nodeOrMarkName, {
        displayStyle: newStyle,
      })
    },
    [editor, nodeOrMarkName]
  )

  if ('href' in attrs && isOtherSupportedProtocol(attrs.href)) return null

  // Temp fix: Don't show the dropdown for Twitter embeds. All Twitter embeds that have ever been
  // created have a `displayStyle` of `preview`, even though they render in docs as though
  // they were inline (and should technically be inline).
  // It's too confusing to show the dropdown now because it won't change how the tweet is displayed
  // because `preferHtml` overrides everything right now.
  if (isTwitterEmbed) return null

  const currentDisplayStyle = isLink
    ? 'link'
    : ('displayStyle' in attrs && attrs.displayStyle) || 'inline'

  return (
    <Menu closeOnSelect={true} isLazy autoSelect={false}>
      <MenuButton
        size="sm"
        as={Button}
        rightIcon={
          <FontAwesomeIcon
            icon={regular('chevron-down')}
            transform="shrink-6"
          />
        }
        variant="toolbar"
        pl={2}
        onMouseDown={preventDefaultToAvoidBlur}
        data-testid="display-style-dropdown"
      >
        {options[currentDisplayStyle].title}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          type="radio"
          onChange={onChange}
          value={currentDisplayStyle}
        >
          {Object.entries(options).map(([key, { title }]) => {
            return (
              <MenuItemOption
                value={key}
                key={key}
                onMouseDown={preventDefaultToAvoidBlur}
                data-testid={`display-style-option-${key}`}
              >
                <Text>{title}</Text>
              </MenuItemOption>
            )
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
