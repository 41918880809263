import { Box, Button, Text, useColorMode } from '@chakra-ui/react'
import { GammaTooltip } from '@gamma-app/ui'
import { Trans } from '@lingui/macro'
import React, { MouseEvent, useCallback } from 'react'

import { EmojiIcon } from 'modules/emoji'

import { TOOLTIP_PLACEMENT } from './Reactions'

export type ReactionButtonProps = {
  colorScheme?: 'gray'
  size: string
  emoji: string
  label: React.ReactNode
  count?: number
  hasSelfReacted: boolean
  handleReactionClick: (emoji: string, event?: MouseEvent) => void
  readOnly: boolean
}

export const ReactionButton = ({
  colorScheme,
  size,
  emoji,
  label,
  count,
  hasSelfReacted,
  handleReactionClick,
  readOnly,
}: ReactionButtonProps) => {
  const { colorMode } = useColorMode()
  const isLight = colorMode === 'light'

  const onClick = useCallback(
    (e) => {
      if (readOnly) return
      handleReactionClick(emoji, e)
    },
    [emoji, handleReactionClick, readOnly]
  )
  return (
    <GammaTooltip
      placement={TOOLTIP_PLACEMENT}
      label={
        <Box alignContent="center">
          {label}
          {!readOnly && (
            <Text display="block" color={isLight ? 'gray.500' : 'gray.700'}>
              {hasSelfReacted ? (
                <Trans>Click to remove</Trans>
              ) : (
                <Trans>Click to react</Trans>
              )}
            </Text>
          )}
        </Box>
      }
    >
      <Button
        cursor={readOnly ? 'default' : 'cursor'}
        data-testid={`reaction-button-${emoji}`}
        size={size || 'sm'}
        color={isLight ? 'gray.500' : 'gray.300'}
        bg={hasSelfReacted ? (isLight ? 'gray.100' : 'gray.700') : 'none'}
        borderWidth="1px"
        borderStyle="solid"
        colorScheme={colorScheme}
        borderColor={
          hasSelfReacted ? (isLight ? 'gray.200' : 'gray.500') : 'transparent'
        }
        _hover={{
          bg: isLight ? 'gray.200' : 'gray.600',
        }}
        variant="ghost"
        borderRadius="full"
        px={2}
        onClick={onClick}
      >
        <EmojiIcon emojiId={emoji} fontSize={size === 'sm' ? 'lg' : 'sm'} />
        <Text as="span" fontSize={size} ml={1.5}>
          {count}
        </Text>
      </Button>
    </GammaTooltip>
  )
}
