import { Box, Flex, SimpleGrid } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'
import { arc } from '@visx/shape'
import { memo } from 'react'

import { HEADING_CLASS } from 'modules/theming/styles/heading'
import { getThemeBase } from 'modules/theming/themeBases'
import { useShouldRenderMobileVersion } from 'modules/tiptap_editor/hooks'

import { getAlignStyles } from '../../../HorizontalAlign/HorizontalAlign'
import { HorizontalAlignment } from '../../../HorizontalAlign/types'
import { EditableLabel } from '../../components/EditableLabel'
import { useCellColor } from '../../components/hooks'
import { EmptyCellContent } from '../../constants'
import {
  CellSizeOption,
  getCellSizes,
  useNumColumns,
} from '../../options/CellSizeOption'
import { HorizontalAlignOption } from '../../options/HorizontalAlignOption'
import { ShapeSize, ShapeSizeOption } from '../../options/ShapeSizeOption'
import {
  SmartLayoutCellComponent,
  SmartLayoutVariant,
  SmartLayoutWrapperComponent,
} from '../../types'
import { getStatsPercent } from './utils'

export const CircleStatsWrapper: SmartLayoutWrapperComponent = memo(
  ({ children, options, containerSize, numCells }) => {
    const { columns } = useNumColumns({ containerSize, options, numCells })

    return (
      <SimpleGrid
        columns={columns}
        rowGap="3em"
        columnGap="1.5em"
        data-selection-ring="padded"
        pos="relative"
        borderRadius="var(--box-border-radius)"
      >
        {children}
      </SimpleGrid>
    )
  }
)
CircleStatsWrapper.displayName = 'CircleStatsWrapper'

const VIEWBOX_WIDTH = 100

const CIRCLE_SIZES: Record<ShapeSize, number> = {
  sm: 0.4,
  md: 0.6,
  lg: 0.8,
  full: 1,
}

export const CircleStatsCell: SmartLayoutCellComponent = memo((props) => {
  const {
    theme,
    children,
    layoutOptions,
    cellOptions,
    label,
    updateAttributes,
    isContentEmpty,
    isLayoutFocused,
    dragHandle,
  } = props
  const base = getThemeBase(theme)
  // If this cell has a color set, we need recalculate CSS vars for lines/borders and text colors
  const { colorVars } = useCellColor(layoutOptions, cellOptions)
  const horizontalAlign: HorizontalAlignment = layoutOptions.horizontalAlign
  const alignStyles = getAlignStyles(horizontalAlign || 'center')
  const sx = {
    ...alignStyles,
    ...base.smartLayoutContentSx,
  }

  const radius = VIEWBOX_WIDTH / 2
  const strokeWidth = radius * 0.18
  const innerRadius = radius - strokeWidth
  const percentFilled = getStatsPercent(props)

  const circleArc = arc({
    innerRadius,
    outerRadius: radius,
  })

  const fillPath = circleArc({
    startAngle: 0,
    endAngle: percentFilled * 2 * Math.PI,
  })
  const trackPath = circleArc({
    startAngle: 0,
    endAngle: 2 * Math.PI,
  })

  const circleSize = CIRCLE_SIZES[layoutOptions.shapeSize] || 1
  const maxCircleSize = `min(100%, ${getCellSizes().md.width * circleSize}em)`
  const isMobile = useShouldRenderMobileVersion()
  const fontSize = isMobile ? `${circleSize * 2}em` : `${circleSize * 2.5}em`

  return (
    <Flex
      borderRadius="var(--box-border-radius)"
      direction={'column'}
      align="center"
      justify="start"
      sx={sx}
      data-selection-ring="padded"
      data-content-reference
      pos="relative"
      gap={isContentEmpty && !isLayoutFocused ? 0 : '1em'}
    >
      <Flex
        direction="column"
        align="center"
        justify="center"
        width={`${circleSize * 100}%`}
        maxW={maxCircleSize}
        pos="relative"
        sx={colorVars}
        contentEditable={false}
        py="0.25em"
      >
        <EditableLabel
          value={label || ''}
          updateAttributes={updateAttributes}
          pos="absolute"
          left={`${strokeWidth}%`}
          className={cx(HEADING_CLASS)}
          fontSize={fontSize}
          lineHeight={1}
          display="flex"
          alignItems="center"
          sx={getAlignStyles('center')}
          style={{
            width: `${100 - strokeWidth * 2}%`,
          }}
        />
        <svg
          width="100%"
          // First two numbers are starting point: -50/-50 so it's centered on zero
          // Second two numbers are the width and height
          viewBox={`${-VIEWBOX_WIDTH / 2} ${
            -VIEWBOX_WIDTH / 2
          } ${VIEWBOX_WIDTH} ${VIEWBOX_WIDTH}`}
          overflow="visible"
        >
          <Box as={'path'} d={trackPath || undefined} sx={base.svgShapeSx} />
          <Box as={'path'} d={fillPath || undefined} sx={base.svgAccentSx} />
        </svg>
      </Flex>
      <Box w="100%" data-content-empty={isContentEmpty}>
        {children}
      </Box>
      {dragHandle}
    </Flex>
  )
})
CircleStatsCell.displayName = 'CircleStatsCell'

export const CircleStats: SmartLayoutVariant = {
  key: 'circleStats',
  name: () => t`Circle stats`,
  commandName: () => t`Circle stats layout (blank)`,
  icon: regular('circle-quarter-stroke'),
  options: [
    CellSizeOption,
    { ...ShapeSizeOption, name: () => t`Circle size`, icon: regular('circle') },
    HorizontalAlignOption,
  ],
  cellOptions: [],
  Wrapper: CircleStatsWrapper,
  Cell: CircleStatsCell,
  defaultContent: EmptyCellContent,
  defaultAttrs: {
    label: '50%',
  },
  addDirection() {
    return 'right'
  },
  keywords: ['stats', 'circle'],
  htmlTag: 'circleStats',
  featureFlag: 'statsSmartLayouts',
}
