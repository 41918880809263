import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'

import { EMPTY_NODES } from 'modules/tiptap_editor/commands/emptyNodes'
import { CommandInfo } from 'modules/tiptap_editor/commands/types'

import { setDraggingContent } from '../../commands/utils'
import { generateToggleId } from './UniqueToggleId'
import { setToggleOpen } from './utils'

export const ToggleCommands: CommandInfo[] = [
  {
    key: 'toggle',
    name: () => t`Toggle`,
    description: () =>
      t`A collapsible section. Highlight key points and hide details`,
    keywords: ['toggle', 'expand', 'collapse', 'disclosure', 'details'],
    nodeName: 'toggle',
    icon: duotone('play'),
    execute: (editor) => {
      const id = generateToggleId()
      setToggleOpen(id, true)
      editor
        .chain()
        .insertContent({
          ...EMPTY_NODES.toggle,
          attrs: {
            id,
          },
        })
        .selectInsertedNode()
        .run()
    },
    dragStartFn: (editor) => {
      const id = generateToggleId()
      setToggleOpen(id, true)
      setDraggingContent(editor, {
        ...EMPTY_NODES.toggle,
        attrs: {
          id,
        },
      })
    },
  },
]
