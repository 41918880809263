import { SmartLayoutVariant } from '../types'
import { Arrows } from './Arrows'
import { Bullets } from './Bullets'
import { IconsWithText, ImagesWithText } from './ImagesWithText'
import { Funnel } from './pyramid/Funnel'
import { Pyramid } from './pyramid/Pyramid'
import { Staircase } from './pyramid/Staircase'
import { BarStats } from './stats/BarStats'
import { CircleStats } from './stats/CircleStats'
import { PlainStats } from './stats/PlainStats'
import { TextBoxes } from './TextBoxes'
import { Timeline } from './Timeline'

const DefaultVariant = Bullets

export const getSmartLayoutVariants = (): SmartLayoutVariant[] => {
  return [
    Bullets,
    TextBoxes,
    ImagesWithText,
    IconsWithText,
    Timeline,
    Arrows,
    PlainStats,
    CircleStats,
    BarStats,
    Pyramid,
    Funnel,
    Staircase,
  ]
}

const getSmartLayoutVariantMap = (): Record<string, SmartLayoutVariant> =>
  getSmartLayoutVariants().reduce((acc, variant) => {
    acc[variant.key] = variant
    return acc
  }, {})

export const getSmartLayoutVariant = (key?: string): SmartLayoutVariant => {
  return (key && getSmartLayoutVariantMap()[key]) || DefaultVariant
}
