import { Box, Flex, Text } from '@chakra-ui/react'
import { TooltipAvatar } from '@gamma-app/ui'
import { Trans } from '@lingui/macro'
import { formatDistanceStrict, parseISO } from 'date-fns'
import React from 'react'

type CreatedByPanelProps = {
  createdByName: string
  createdByProfileImageUrl?: string
  createdByYou?: boolean
  timestampDescription?: React.ReactNode
}

export const CreatedByPanel = ({
  createdByName,
  createdByProfileImageUrl,
  createdByYou,
  timestampDescription,
}: CreatedByPanelProps) => {
  return (
    <Flex align="center">
      <TooltipAvatar
        display="inline-block"
        size="xs"
        fontSize="xs"
        name={createdByName}
        src={createdByProfileImageUrl}
        tooltipProps={{
          placement: 'top',
        }}
      />
      <Box ml={2} mr={2} w="100%">
        <Text noOfLines={1} fontSize="xs" w="100%" wordBreak="break-all">
          {createdByYou ? (
            <Trans>Created by you</Trans>
          ) : (
            <Trans>Created by {createdByName}</Trans>
          )}
        </Text>
        {timestampDescription && (
          <Text noOfLines={1} fontSize="11px" color="gray.400">
            {timestampDescription}
          </Text>
        )}
      </Box>
    </Flex>
  )
}
