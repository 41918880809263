import { JSONContent } from '@tiptap/core'

import { CalloutBoxEmptyNodes } from '../extensions/CalloutBox/options'
import { CardTemplateEmptyNodes } from '../extensions/Card/cardTemplates'
import { TABLE_OF_CONTENTS_HEADER } from '../extensions/CardTableOfContents/constants'

export const EMPTY_NODES: Record<string, JSONContent> = {
  title: {
    type: 'title',
    attrs: { level: 1 },
  },
  h1: {
    type: 'heading',
    attrs: { level: 1 },
  },
  normalText: {
    type: 'paragraph',
  },
  h2: {
    type: 'heading',
    attrs: { level: 2 },
  },
  h3: {
    type: 'heading',
    attrs: { level: 3 },
  },
  h4: {
    type: 'heading',
    attrs: { level: 4 },
  },
  bulletedList: {
    type: 'bullet',
    attrs: {
      indent: 0,
    },
  },
  numberedList: {
    type: 'numbered',
    attrs: {
      indent: 0,
    },
  },
  todoList: {
    type: 'todo',
    attrs: { indent: 0 },
  },
  blockquote: {
    type: 'blockquote',
    content: [
      {
        type: 'paragraph',
      },
    ],
  },
  codeBlock: {
    type: 'codeBlock',
  },
  mathBlock: {
    type: 'math_display',
  },
  button: {
    type: 'button',
    content: [
      {
        type: 'text',
        text: 'Click here',
      },
    ],
  },
  buttonGroup: {
    type: 'buttonGroup',
    content: [
      {
        type: 'button',
        content: [
          {
            type: 'text',
            text: 'Click here',
          },
        ],
      },
    ],
  },
  table2: {
    type: 'table',
    content: [
      {
        type: 'tableRow',
        content: [
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
        ],
      },
      {
        type: 'tableRow',
        content: [
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
        ],
      },
    ],
  },
  table3: {
    type: 'table',
    content: [
      {
        type: 'tableRow',
        content: [
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
        ],
      },
      {
        type: 'tableRow',
        content: [
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
        ],
      },
      {
        type: 'tableRow',
        content: [
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
        ],
      },
    ],
  },
  table4: {
    type: 'table',
    content: [
      {
        type: 'tableRow',
        content: [
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
        ],
      },
      {
        type: 'tableRow',
        content: [
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
        ],
      },
      {
        type: 'tableRow',
        content: [
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
        ],
      },
      {
        type: 'tableRow',
        content: [
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: {
              colspan: 1,
              rowspan: 1,
            },
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
        ],
      },
    ],
  },
  columns2: {
    type: 'gridLayout',
    content: [
      {
        type: 'gridCell',
        content: [
          {
            type: 'paragraph',
          },
        ],
      },
      {
        type: 'gridCell',
        content: [
          {
            type: 'paragraph',
          },
        ],
      },
    ],
  },
  columns3: {
    type: 'gridLayout',
    content: [
      {
        type: 'gridCell',
        content: [
          {
            type: 'paragraph',
          },
        ],
      },
      {
        type: 'gridCell',
        content: [
          {
            type: 'paragraph',
          },
        ],
      },
      {
        type: 'gridCell',
        content: [
          {
            type: 'paragraph',
          },
        ],
      },
    ],
  },
  columns4: {
    type: 'gridLayout',
    content: [
      {
        type: 'gridCell',
        content: [
          {
            type: 'paragraph',
          },
        ],
      },
      {
        type: 'gridCell',
        content: [
          {
            type: 'paragraph',
          },
        ],
      },
      {
        type: 'gridCell',
        content: [
          {
            type: 'paragraph',
          },
        ],
      },
      {
        type: 'gridCell',
        content: [
          {
            type: 'paragraph',
          },
        ],
      },
    ],
  },
  insertCardInside: {
    type: 'card',
    content: [
      {
        type: 'heading',
        attrs: {
          level: 1,
        },
      },
    ],
  },
  footnote: {
    type: 'footnote',
    content: [
      {
        type: 'paragraph',
      },
    ],
  },
  contributors: {
    type: 'contributors',
  },
  divider: {
    type: 'divider',
  },
  tableOfContents: [
    {
      type: 'heading',
      attrs: { level: 3 },
      content: [
        {
          type: 'text',
          text: TABLE_OF_CONTENTS_HEADER,
        },
      ],
    },
    {
      type: 'tableOfContents',
    },
  ],
  gallery: {
    type: 'gallery',
    content: [],
  },
  ...CardTemplateEmptyNodes,
  ...CalloutBoxEmptyNodes,
  toggle: {
    type: 'toggle',
    content: [
      {
        type: 'toggleSummary',
      },
      {
        type: 'paragraph',
      },
    ],
  },
}
