import { Box, Flex } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'
import { memo } from 'react'

import { getThemeBase } from 'modules/theming/themeBases'

import { getAlignStyles } from '../../HorizontalAlign/HorizontalAlign'
import { HorizontalAlignment } from '../../HorizontalAlign/types'
import { useCellColor } from '../components/hooks'
import { CELL_PADDING, EmptyCellContent } from '../constants'
import { CellSizeOption, useNumColumns } from '../options/CellSizeOption'
import { ColorOption } from '../options/ColorOption'
import { HorizontalAlignOption } from '../options/HorizontalAlignOption'
import {
  SmartLayoutCellComponent,
  SmartLayoutVariant,
  SmartLayoutWrapperComponent,
} from '../types'

const GAP = '1em'

export const TextBoxesWrapper: SmartLayoutWrapperComponent = memo(
  ({ children }) => {
    return (
      <Flex gap={GAP} wrap="wrap" data-selection-ring pos="relative">
        {children}
      </Flex>
    )
  }
)
TextBoxesWrapper.displayName = 'TextBoxesWrapper'

export const TextBoxCell: SmartLayoutCellComponent = memo(
  ({
    theme,
    children,
    layoutOptions,
    cellOptions,
    numCells,
    layoutContainerSize,
    dragHandle,
  }) => {
    const base = getThemeBase(theme)
    const { columns } = useNumColumns({
      options: layoutOptions,
      numCells,
      containerSize: layoutContainerSize,
    })

    // If this cell has a color set, we need recalculate CSS vars for lines/borders and text colors
    const { colorVars } = useCellColor(layoutOptions, cellOptions)
    const horizontalAlign: HorizontalAlignment = layoutOptions.horizontalAlign
    const alignStyles = getAlignStyles(horizontalAlign)
    const sx = {
      ...colorVars,
      ...alignStyles,
      ...(base.smartLayoutBoxSx || base.boxSx),
    }

    return (
      <Box
        // Always fill the space, distributing evenly
        flex={'1 1 auto'}
        w={`calc(${100 / columns}% - ${GAP})`}
        boxSizing="border-box"
        p={CELL_PADDING}
        data-content-reference
        data-selection-ring
        pos="relative"
        sx={sx}
      >
        {children}
        {dragHandle}
      </Box>
    )
  }
)
TextBoxCell.displayName = 'TextBoxCell'

export const TextBoxes: SmartLayoutVariant = {
  key: 'textBoxes',
  name: () => t`Text boxes`,
  commandName: () => t`Text boxes layout (blank)`,
  icon: regular('input-text'),
  options: [CellSizeOption, ColorOption, HorizontalAlignOption],
  cellOptions: [ColorOption],
  Wrapper: TextBoxesWrapper,
  Cell: TextBoxCell,
  defaultContent: EmptyCellContent,
  addDirection() {
    return 'right'
  },
  htmlTag: 'boxes',
  keywords: ['textbox', 'layout'],
}
