import { HStack } from '@chakra-ui/react'
import { t } from '@lingui/macro'

import { ToolbarButton } from '../../../components/menus/buttons/ToolbarButton'
import { AlignmentCommands } from '../../HorizontalAlign/HorizontalAlign'
import { HorizontalAlignment } from '../../HorizontalAlign/types'
import { SmartLayoutOption, SmartLayoutOptionControlProps } from '../types'

const HorizontalAlignOptionControl = ({
  value,
  updateValue,
}: SmartLayoutOptionControlProps<HorizontalAlignment>) => {
  return (
    <HStack spacing={0}>
      {AlignmentCommands.map(({ name, icon, alignment }) => {
        return (
          <ToolbarButton
            key={name()}
            label={name()}
            icon={icon}
            onClick={() => {
              updateValue(alignment === value ? null : alignment)
            }}
            isActive={value === alignment}
          />
        )
      })}
    </HStack>
  )
}

export const HorizontalAlignOption: SmartLayoutOption<HorizontalAlignment> = {
  key: 'horizontalAlign',
  defaultValue: null,
  Control: HorizontalAlignOptionControl,
  name: () => t`Horizontal alignment`,
}
