import { ButtonGroup } from '@chakra-ui/react'
import { Editor } from '@tiptap/core'

import { TextColorMenu } from 'modules/tiptap_editor/extensions/TextColor/TextColorMenu'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { ToolbarButton } from '../buttons/ToolbarButton'
import { TextConvertMenu } from './TextConvertMenu'
import {
  checkCommandDisabled,
  FilterKeyForTextFormattingCommands,
  TextFormattingCommands,
} from './TextFormattingCommands'
import { TextRewriteMenu } from './TextRewriteMenu'
import { TextSizeMenu } from './TextSizeMenu'

type TextFormattingMenuProps = {
  editor: Editor
  filterBy?: FilterKeyForTextFormattingCommands
}

export const TextFormattingMenu = ({
  editor,
  filterBy,
}: TextFormattingMenuProps) => {
  return (
    <>
      <ButtonGroup
        isAttached
        key="text"
        onMouseDown={preventDefaultToAvoidBlur}
      >
        <TextSizeMenu editor={editor}></TextSizeMenu>
      </ButtonGroup>
      {TextFormattingCommands.map((group, idx) => (
        <ButtonGroup
          spacing={0}
          size="sm"
          key={idx}
          onMouseDown={preventDefaultToAvoidBlur}
          _empty={{
            display: 'none',
          }}
        >
          {group
            .filter((n) => {
              if (filterBy) return n[filterBy]
              return n
            })
            .map((command) => {
              if (checkCommandDisabled(editor, command)) return
              const {
                key,
                name,
                shortcut,
                icon,
                mark,
                node,
                checkActive,
                apply,
              } = command

              const action = apply
                ? apply
                : mark
                ? (editorInstance) =>
                    editorInstance.chain().toggleMark(mark).focus().run()
                : () => {}
              const isActive = checkActive
                ? checkActive(editor)
                : mark
                ? editor.isActive(mark)
                : node
                ? editor.isActive(node)
                : undefined
              if (key === 'textColor') {
                return (
                  <TextColorMenu
                    key={key}
                    shortcut={shortcut}
                    icon={icon}
                    editor={editor}
                  />
                )
              } else if (key === 'rewriteText') {
                return (
                  <TextRewriteMenu
                    editor={editor}
                    key={key}
                    shortcut={shortcut}
                    icon={icon}
                    testId={key}
                    label={name()}
                  />
                )
              } else if (key === 'convertText') {
                return (
                  <TextConvertMenu
                    editor={editor}
                    key={key}
                    shortcut={shortcut}
                    icon={icon}
                    testId={key}
                    label={name()}
                  />
                )
              } else {
                return (
                  <ToolbarButton
                    key={key}
                    label={name()}
                    shortcut={shortcut}
                    isActive={isActive}
                    onClick={() => action(editor)}
                    icon={icon}
                    testId={key}
                  />
                )
              }
            })}
        </ButtonGroup>
      ))}
    </>
  )
}
