import { useToast } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { Editor, findParentNodeClosestToPos, JSONContent } from '@tiptap/core'
import { NodeViewProps } from '@tiptap/react'
import { groupBy, sortBy } from 'lodash'
import flatMap from 'lodash/flatMap'
import isEqual from 'lodash/isEqual'
import { Decoration } from 'prosemirror-view'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Comment, CommentStatus, useCreateCommentMutation } from 'modules/api'
import { useAppSelector } from 'modules/redux'
import { EventBusEvent, TiptapEventBus } from 'modules/tiptap_editor/eventBus'
import {
  deleteDraftReply,
  selectDoc,
  selectDraftCommentReplyForCommentId,
  updateDraftReply,
} from 'modules/tiptap_editor/reducer'
import {
  generateHtmlFromNode,
  generateTextFromNode,
} from 'modules/tiptap_editor/utils/contentHelpers'
import { isCardNode } from 'modules/tiptap_editor/utils/nodeHelpers'
import { relativeToAbsolutePos } from 'modules/tiptap_editor/utils/relativePosition'
import { useUserContext } from 'modules/user'
import { DOC_COMMENTS_FRAGMENTS } from 'sections/docs/graphql'
import { isMobileDevice } from 'utils/deviceDetection'

import { AnnotationDecoration } from '../AnnotationExtension/types'
import {
  DraftComment,
  DraftCommentDecoration,
} from '../DraftCommentsExtension/types'
import { getParentCommentId } from './BlockCommentsStack/utils'
import {
  BlockReaction,
  DraftCommentReply,
  UpdateDraftCommentReply,
} from './types'

const toastPosition = isMobileDevice() ? 'bottom' : 'top'

type MappedThing<T> = {
  [targetId: string]: T[]
} | null

function getMappedByTargetId<T extends { targetId?: string }>(
  comments?: T[]
): MappedThing<T> {
  if (!comments) return null
  return comments.reduce((result, comment) => {
    if (!comment.targetId) {
      return result
    }
    if (!result[comment.targetId]) {
      result[comment.targetId] = [comment]
    } else {
      result[comment.targetId].push(comment)
    }
    return result
  }, {})
}

// filter out annotation decorations from all decorations
const filterAnnotationDecorations = (
  decorations: Decoration[]
): AnnotationDecoration[] => {
  const filterFn = (item: Decoration): item is AnnotationDecoration => {
    return !!item.spec.isAnnotation
  }
  return decorations.filter(filterFn)
}

// get and join annotation comments
export const useAnnotationComments = (decorations: Decoration[]) => {
  const docComments = useAppSelector<Comment[]>((state) => {
    const doc = selectDoc(state)

    // mapped  by target id
    const commentsMap = getMappedByTargetId<Comment>(
      (doc?.comments || []).filter((c) => c.status === CommentStatus.Open)
    )
    if (!commentsMap) return []

    const annotations = filterAnnotationDecorations(decorations)

    return flatMap(annotations, ({ spec }) => commentsMap[spec.id]).filter(
      Boolean
    )
  }, isEqual)

  return docComments
}

export const useDraftCommentsFromDecorations = (
  decorations: Decoration[]
): DraftComment[] => {
  return decorations
    .filter(
      (d: DraftCommentDecoration | Decoration): d is DraftCommentDecoration =>
        !!d.spec.isDraftComment
    )
    .map((a) => a.spec.comment)
}

export type SelectionData = {
  targetHtml: string // The HTML string representing the selected content
  getPos: () => number // Fn to get the from Prosemirror position for the selected content
}

export const useOnCommentSave = ({
  draftComment,
  clearDraftComment,
  editor,
}: {
  clearDraftComment: () => void
  draftComment: DraftComment | null
  editor: Editor
}) => {
  const [createComment] = useCreateCommentMutation()
  const toast = useToast()
  const docId = editor.gammaDocId as string
  const { user } = useUserContext()

  return (commentJSON: JSONContent) => {
    if (!draftComment) {
      return
    }

    const { relativePos, targetId } = draftComment

    const pos = relativeToAbsolutePos(editor.state, relativePos)
    if (!pos) {
      throw new Error(`Could not save comment, null pos from relativePos`)
    }

    const card = findParentNodeClosestToPos(
      editor.state.doc.resolve(pos),
      isCardNode
    )
    const upToDateNode = editor.view.state.doc.nodeAt(
      pos
    ) as NodeViewProps['node']
    const targetHtmlToUse =
      draftComment.targetHtml ||
      generateHtmlFromNode(upToDateNode, ['footnote', 'footnoteLabel'])
    const targetTextToUse =
      draftComment.targetHtml ||
      generateTextFromNode(upToDateNode, ['footnote', 'footnoteLabel'])

    editor.commands.addAnnotation({
      id: targetId,
      pos,
    })

    const cardId = card?.node?.attrs.id
    const input = {
      targetId,
      docId,
      cardId,
      content: commentJSON,
      targetHtml: targetHtmlToUse,
      targetText: targetTextToUse,
    }

    createComment({
      variables: { input },
      update: (cache, { data }) => {
        cache.writeFragment({
          id: `Doc:${docId}`,
          fragment: DOC_COMMENTS_FRAGMENTS,
          fragmentName: 'DocCommentsCreate',
          data: {
            comments: [data?.createComment],
          },
        })
      },
      optimisticResponse: {
        createComment: {
          id: 'temp-id',
          // commentId needs to be present for optimistic update to work
          commentId: '',
          __typename: 'Comment',
          ...input,
          // Fixes G-836. For some reason, this coerces the commentJSON into a palatable shape.
          content: JSON.parse(JSON.stringify(commentJSON)),
          user,
          archived: false,
          reactions: [],
          replies: [],
          status: CommentStatus.Open,
          createdTime: new Date().toISOString(),
          updatedTime: new Date().toISOString(),
        },
      },
    })

    toast({
      title: t`Comment posted.`,
      status: 'success',
      duration: 5000,
      isClosable: false,
      position: toastPosition,
    })
    clearDraftComment()
  }
}

/**
 * Hook to handle listening for the create comment event and
 * toggling the appropriate comment panel open
 */
export const useListenForCreateCommentFromSelection = (
  getPos: NodeViewProps['getPos'],
  onCreateDraft: (selection: SelectionData) => void
) => {
  useEffect(() => {
    let isMounted = true
    const handleCreateCommentFromSelection = ({
      selectionPos,
      parentPos,
      text: targetHtml,
    }) => {
      if (!isMounted) {
        return
      }
      const thisPos = getPos()
      if (thisPos !== parentPos) {
        return
      }

      onCreateDraft({ targetHtml, getPos: () => selectionPos })
    }
    TiptapEventBus.on(
      EventBusEvent.CREATE_COMMENT_FROM_SELECTION,
      handleCreateCommentFromSelection
    )
    return () => {
      TiptapEventBus.on(
        EventBusEvent.CREATE_COMMENT_FROM_SELECTION,
        handleCreateCommentFromSelection
      )
      isMounted = false
    }
  }, [getPos, onCreateDraft])
}

/**
 * Hook to handle listening for the open popup comment event and:
 *   - Toggling the appropriate comment panel open
 *   - Scrolling to the comment
 *   - Optionally highlighting it
 */
export const useListenForOpenComment = ({
  showComment,
  comments,
  blockAllowsCommenting,
  highlightDuration = 4000,
}: {
  showComment: (parentCommentId: string) => void
  comments: Comment[]
  blockAllowsCommenting: boolean
  highlightDuration?: number
}) => {
  const [activeCommentId, setActiveCommentId] = useState<string | null>(null)

  useEffect(() => {
    let isMounted = true
    const cb = ({
      commentId,
      highlightComment = false,
    }: {
      commentId: string
      highlightComment?: boolean
    }) => {
      if (!isMounted || !blockAllowsCommenting) return

      const parentCommentId = getParentCommentId(comments, commentId)

      if (!parentCommentId) {
        // this comment id doesnt belong to this comment list (or replies)
        return
      }

      showComment(parentCommentId)
      if (highlightComment) {
        // Highlight the comment id (which may be a reply)
        setActiveCommentId(commentId)
        setTimeout(() => {
          if (isMounted) {
            setActiveCommentId(null)
          }
        }, highlightDuration)
      }
    }
    TiptapEventBus.on(EventBusEvent.OPEN_POPUP_COMMENT, cb)
    return () => {
      TiptapEventBus.off(EventBusEvent.OPEN_POPUP_COMMENT, cb)
      isMounted = false
    }
  }, [comments, blockAllowsCommenting, highlightDuration, showComment])

  return activeCommentId
}

export const useDraftReply = (id: string) => {
  const dispatch = useDispatch()
  const reduxDraftReply = useAppSelector(
    selectDraftCommentReplyForCommentId(id)
  )
  const localDraftReply = useRef<DraftCommentReply | null>(null)
  const [hasLocalDraftReply, setHasLocalDraftReply] = useState<boolean>(false)

  const updateLocalDraftReply: UpdateDraftCommentReply = useCallback(
    (reply: DraftCommentReply) => {
      localDraftReply.current = reply
      setHasLocalDraftReply(Boolean(reply))
    },
    []
  )

  const initLocalDraftReply = useCallback(() => {
    localDraftReply.current = reduxDraftReply
    setHasLocalDraftReply(Boolean(reduxDraftReply))
  }, [reduxDraftReply])

  const saveDraftReplyToRedux = useCallback(() => {
    if (!localDraftReply.current || !localDraftReply.current.text?.length) {
      dispatch(deleteDraftReply({ id }))
      return
    }
    dispatch(updateDraftReply({ id, reply: localDraftReply.current }))
  }, [id, dispatch])

  const saveFnRef = useRef<() => void>(saveDraftReplyToRedux)
  useEffect(() => {
    saveFnRef.current = saveDraftReplyToRedux
  }, [saveDraftReplyToRedux])

  useEffect(() => {
    initLocalDraftReply()

    return () => {
      saveFnRef.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    hasLocalDraftReply,
    initialDraftReply: localDraftReply.current,
    updateLocalDraftReply,
  }
}

export const useAnnotationReactions = (
  decorations: Decoration[]
): BlockReaction[] => {
  const docReactions = useAppSelector<BlockReaction[]>((state) => {
    const doc = selectDoc(state)

    // mapped  by target id
    const reactionsMap = getMappedByTargetId(doc?.reactions || [])
    if (!reactionsMap) return []

    const annotations = filterAnnotationDecorations(decorations)

    const flattened = flatMap(
      annotations,
      ({ spec }) => reactionsMap[spec.id]
    ).filter(Boolean)

    const byEmoji = groupBy(flattened, 'emoji')
    const ret: BlockReaction[] = []
    for (const [emoji, reactions] of Object.entries(byEmoji)) {
      const count = reactions.reduce((carry, item) => carry + item.count!, 0)

      ret.push({
        emoji,
        count,
        reactions,
      })
    }
    return sortBy(
      ret.filter((r) => r.count > 0),
      'count'
    ).reverse()
  }, isEqual)

  return docReactions
}
