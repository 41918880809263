import { t } from '@lingui/macro'
import { Editor, JSONContent } from '@tiptap/core'
import cloneDeep from 'lodash/cloneDeep'
import { nanoid } from 'nanoid'
import { Node } from 'prosemirror-model'
import { useCallback } from 'react'

import { extractSuggestionFromResponse } from 'modules/ai/chat/tasks/definitions/SuggestContentTask'
import { changeContentLayoutPrompt } from 'modules/ai/chat/tasks/suggest/changeLayout'
import { runChatCompletionPrompt } from 'modules/ai/prompt/ChatCompletionPrompt'
import { contentToAiHtml } from 'modules/ai/serialization/contentToAiHtml'
import { generateAIInteractionId } from 'modules/ai/track'
import { transformHtmlFromAI } from 'modules/ai/transform/transformHtmlFromAI'
import { featureFlags } from 'modules/featureFlags/FeatureFlagProvider'
import { useAppDispatch } from 'modules/redux'
import { parseExternalHtml } from 'modules/tiptap_editor/extensions/Clipboard/parseExternalHtml'

import { addCardVariants, SuggestionVariant } from './reducer'

export const CARD_SUGGESTION_DEFAULT_MESSAGE = `Translate this to Spanish please`

export const useFetchCardSuggestions = ({
  editor,
}: {
  editor?: Editor | null
}) => {
  const dispatch = useAppDispatch()

  return useCallback(
    async ({
      doc,
      cardId,
      message,
    }: {
      doc: Node
      cardId: string
      message: string
    }) => {
      if (!editor) return

      // Step #1: Get the content of the card
      const cardHtmlMap: Record<string, { html: string; node: Node }> = {}
      doc.descendants((node) => {
        if (node.type.name === 'card') {
          const id = node.attrs.id
          if (cardId !== id) return false
          const cardHtml = contentToAiHtml(editor, node.content)
          cardHtmlMap[id] = {
            html: cardHtml,
            node,
          }
          return false // Prevents iterating into nested cards within
        }
        return true
      })

      // Step #2: Initialize the card map and content with the original values
      const originals = Object.values(cardHtmlMap).map(({ node }) => {
        return {
          cardId,
          variant: {
            label: t`Original`,
            card: cloneDeep(node.toJSON()) as JSONContent,
            id: 'original',
          },
          autoselect: true,
        }
      })
      dispatch(addCardVariants(originals))

      // Step #3: Fetch card suggestions async and add them when they return
      Object.values(cardHtmlMap).forEach(async ({ node, html }) => {
        const suggestion = await fetchCardSuggestion({
          editor,
          html,
          node,
          message,
        })
        dispatch(
          addCardVariants([
            {
              cardId,
              variant: suggestion,
              autoselect: true,
            },
          ])
        )
      })
    },
    [dispatch, editor]
  )
}

const fetchCardSuggestion = async ({
  html,
  node,
  message,
  editor,
}: {
  html: string
  node: Node
  message: string
  editor: Editor
}): Promise<SuggestionVariant> => {
  const interactionId = generateAIInteractionId()
  const input = changeContentLayoutPrompt.prepare({
    variables: { html, message },
  })

  const resp = await runChatCompletionPrompt(input, interactionId, {
    timeout: featureFlags.get('aiRequestTimeouts').suggestContent,
  })
  const { suggestion } = extractSuggestionFromResponse(resp!)
  // suggestion here is the contents of the card, so cardLayoutItem
  const { content } = parseExternalHtml(
    await transformHtmlFromAI(suggestion!, { loadImages: true }),
    editor.schema
  )
  const newCard: JSONContent = {
    // Keep the old id and other attrs
    ...node.toJSON(),
    content: content.toJSON(),
  }
  return {
    id: nanoid(5),
    label: t`Suggested`,
    card: newCard,
  }
}
