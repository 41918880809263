import { Box } from '@chakra-ui/react'
import { CSSObject } from '@emotion/react'
import { Node } from 'prosemirror-model'
import { useMemo } from 'react'

import { useFeatureFlag } from 'modules/featureFlags'
import { useAppSelector } from 'modules/redux'
import { DEFAULT_FONTS, isThemeDark, Theme } from 'modules/theming'
import { getThemeCSSVars } from 'modules/theming/styles/variables'
import { useGammaBreakpointValue } from 'utils/breakpoints/useGammaBreakpointValue'

import { selectBackground, selectTheme, selectZoomLevel } from '../../reducer'
import {
  getBackgroundProps,
  getDocOrThemeBackground,
} from '../../styles/backgroundStyles'
import {
  getContainerOptions,
  getContainerStyles,
} from '../../styles/containerStyles'
import {
  BackgroundOptions,
  BackgroundType,
  ContainerEffect,
  ContainerOptions,
} from '../../styles/types'
import {
  CARD_BACKGROUND_CLASS,
  CARD_BACKGROUND_PADDING_RESPONSIVE,
  CARD_CONTAINER_CLASS,
  CARD_WIDTHS,
  NESTED_CARD_OUTDENT,
} from './constants'
import { CardAttributes } from './types'

export const useCardStyles = ({
  node,
  isCollapsed,
  isNested,
  isEditable,
  isMobileDevice,
  nestedDepth,
  isPresentMode,
  inheritContainer,
}: {
  node: Node
  isCollapsed: boolean
  isNested: boolean
  nestedDepth: number
  isPresentMode: boolean
  isEditable: boolean
  isMobileDevice: boolean
  inheritContainer: Partial<ContainerOptions>
}) => {
  // Get the theme and calculate card styles from theme + attributes
  const theme: Theme = useAppSelector(selectTheme)
  const { background: cardBackground, container: cardContainer } =
    node.attrs as CardAttributes

  // Inherit container settings from parent cards, with nearest parent taking precedence
  const containerOptions = getContainerOptions(
    theme,
    isCollapsed
      ? // Nested cards should always use their parents' style as a base, but can still override it
        { ...inheritContainer, effect: ContainerEffect.COLLAPSED }
      : { ...inheritContainer, ...cardContainer }
  )
  const containerStyles = useMemo(
    () => getContainerStyles(containerOptions, theme, isMobileDevice),
    [containerOptions, theme, isMobileDevice]
  )

  const isDark = containerOptions.isDark
  const foregroundColor = isDark ? 'white' : 'black'

  const width = containerOptions.width || 'md'
  const presentFullWidth = useFeatureFlag('presentFullWidth')
  const isFullWidth =
    (containerOptions.width === 'full' ||
      (presentFullWidth && isPresentMode)) &&
    !isCollapsed &&
    !isMobileDevice

  const hasCardBackground = cardBackground.type !== BackgroundType.NONE
  const docBackground = useAppSelector(selectBackground)
  const docOrThemeBackground = getDocOrThemeBackground(theme, docBackground)
  const background = hasCardBackground ? cardBackground : docOrThemeBackground
  const isBackgroundOutside =
    hasCardBackground && !isCollapsed && !isNested && !background.inside

  // Responsive padding and sizes
  // The `zoomFactor` is used for card widths, padding, and blocks that resize like images and drawings
  // The `fontSize` is used for text sizes
  const presentScaleFont = useFeatureFlag('presentScaleFont')
  const presentScale =
    useGammaBreakpointValue({
      base: 1,
      lg: 1.125,
      xl: 1.25,
      '2xl': 1.5,
      '3xl': 1.75,
    }) || 1
  const userZoomLevel = useAppSelector(selectZoomLevel)

  const zoomFactor = isMobileDevice
    ? 1
    : 1.125 * // Base font size is 18px not 16px on desktop
      (isPresentMode ? presentScale * userZoomLevel : 1) // Present mode scales up responsively and respects user zoom level

  // In present mode, scale up the font. Default is to scale with the card width/zoom factor
  // But if `presentScaleFont` feature flag is on, then use VW/VH units instead. In that case,
  // the font size won't flow consistently with the card width.
  const fontSizeRem = (isCollapsed ? 0.66 : 1) * zoomFactor
  const fontSizeVw = 1.75 * userZoomLevel * (isCollapsed ? 0.66 : 1)
  const fontSizeVh = fontSizeVw * 1.5 // On wider layouts, the VH becomes the binding constraint
  const fontSize =
    presentScaleFont && isPresentMode
      ? `clamp(1rem, ${fontSizeVw}vw, ${fontSizeVh}vh)`
      : `${fontSizeRem}rem`

  const outerPaddingY =
    useGammaBreakpointValue(
      isCollapsed && isNested
        ? [0] // Spacing between collapsed cards
        : isNested
        ? [1] // Spacing between nested cards
        : isBackgroundOutside && !isPresentMode && !isMobileDevice
        ? CARD_BACKGROUND_PADDING_RESPONSIVE
        : [0.5, 1.75] // Spacing between top-level cards
    ) || 0
  const outerPaddingX =
    (useGammaBreakpointValue(
      isCollapsed
        ? [0]
        : isFullWidth && isEditable // Make room for insert widget and TOC when editing
        ? { md: 2, lg: 6 }
        : [0.5, 2]
    ) || 0) -
    nestedDepth * NESTED_CARD_OUTDENT
  const outerPadding = `${outerPaddingY}rem ${outerPaddingX}rem`

  const innerPaddingY =
    (useGammaBreakpointValue(isCollapsed ? [1, 1.25] : [1.5, 2.5]) || 1) *
    zoomFactor
  const innerPaddingX =
    (useGammaBreakpointValue(
      isCollapsed || isFullWidth ? [1, 2] : [1.5, 4.5]
    ) || 1) *
      zoomFactor +
    (isCollapsed || isFullWidth ? 0 : nestedDepth * NESTED_CARD_OUTDENT)
  const innerPadding = `${innerPaddingY}rem ${innerPaddingX}rem`
  const cardContentWidth =
    zoomFactor * (isFullWidth ? CARD_WIDTHS['full'] : CARD_WIDTHS[width])
  const cardWidth = cardContentWidth + innerPaddingX * 2 + outerPaddingX * 2

  const isDarkOverride = isDark !== isThemeDark(theme) ? isDark : undefined
  const cssVars = {
    ...getThemeCSSVars(theme, isDarkOverride),
    '--font-size': `${theme.config.fontSize ?? DEFAULT_FONTS.fontSize}em`,
  }
  const maxTextWidth =
    presentFullWidth && isPresentMode
      ? // Allow text columns to go a little wider in present mode, to make room for content
        `calc(${CARD_WIDTHS['lg']} * ${fontSize})`
      : isFullWidth
      ? `calc(${CARD_WIDTHS['md']} * ${fontSize})`
      : undefined

  return {
    background,
    cardWidth,
    containerOptions,
    containerStyles,
    zoomFactor,
    foregroundColor,
    hasCardBackground,
    innerPadding,
    innerPaddingX,
    innerPaddingY,
    outerPaddingY,
    isDark,
    isBackgroundOutside,
    outerPadding,
    theme,
    cssVars,
    isFullWidth,
    maxTextWidth,
    fontSize,
  }
}

type CardContainerProps = {
  isFocused: boolean
  isCollapsed: boolean
  containerStyles: CSSObject
  isFullWidth: boolean
}

export const CardContainer = ({
  isCollapsed,
  isFocused,
  containerStyles,
  isFullWidth,
}: CardContainerProps) => {
  if (isFullWidth) {
    containerStyles.borderRadius = 'none'
  }

  return (
    <Box
      sx={containerStyles}
      className={CARD_CONTAINER_CLASS}
      data-guider-highlight="card-body"
      inset={0}
      data-selection-ring
      data-selection-background
      outline={
        isFocused && !isCollapsed
          ? '2px solid var(--chakra-ring-color)'
          : undefined
      }
      // Prevent sliding when full width
      right={isFullWidth ? 'calc(1 * var(--comment-slide-margin))' : 0}
      // Bulge out of the parent card
      position="absolute"
      boxSizing="content-box"
      // Transition visual styles using CSS
      transitionProperty="border-radius, background, backdrop-filter, filter, box-shadow, inset"
      transitionDuration="normal"
      // Prevent cursor getting stuck in here
      contentEditable={false}
      pointerEvents="none"
    />
  )
}

type CardBackgroundProps = {
  background: BackgroundOptions
  containerOptions: ContainerOptions
  containerStyles: CSSObject
  isCollapsed: boolean
  isPresenting: boolean
  isDark?: boolean
  isNested: boolean
  isBackgroundOutside: boolean
  isFullWidth: boolean
  hasCardBackground: boolean
  outerPaddingY: number
}

export const CardBackground = ({
  background,
  containerStyles,
  containerOptions,
  hasCardBackground,
  isCollapsed,
  isPresenting,
  isFullWidth,
  isDark,
  isBackgroundOutside,
  outerPaddingY,
}: CardBackgroundProps) => {
  const backgroundProps = useMemo(
    () => getBackgroundProps(background, isDark || false),
    [background, isDark]
  )
  const backgroundPadding =
    isBackgroundOutside && !isFullWidth ? outerPaddingY : 0

  if (
    (isPresenting && isBackgroundOutside) ||
    isCollapsed ||
    !hasCardBackground
  ) {
    return null
  }

  return (
    <Box
      className={CARD_BACKGROUND_CLASS}
      contentEditable={false}
      // Bulge out of the parent card
      position="absolute"
      borderRadius={isBackgroundOutside ? 0 : containerStyles.borderRadius}
      sx={{
        '@media print': {
          borderRadius: 'none',
        },
      }}
      width={isBackgroundOutside ? 'var(--editor-width)' : undefined}
      insetY={`-${backgroundPadding}rem`}
      insetX={isBackgroundOutside ? 'auto' : 0}
      // Needs to offset the padding added in Doc.tsx
      ml={isBackgroundOutside ? 'calc(-1 * var(--comment-slide-margin))' : 0}
      border="1px solid transparent" // Match the size of the container
      boxSizing="content-box"
      // Use CSS to animate between visual styles
      transitionProperty="background, border-radius, border, width, inset, margin"
      transitionDuration={`normal`}
      {...backgroundProps}
      backgroundAttachment={'unset'} // Force card-level backgrounds to be unfixed, unlike present mode
      backgroundRepeat="no-repeat"
      // Let drag/click handlers know when a mouse event on this element is not really inside the card
      data-outside-card-body={isBackgroundOutside}
    />
  )
}
