// Returns an array of elements matching the selector

import { Editor } from '@tiptap/core'
import { DOMSerializer, Fragment, Node, Schema } from 'prosemirror-model'

import { transformCopiedDocFragment } from './transformCopiedDocFragment'

export const serializeFragment = (
  fragment: Fragment,
  schema: Schema,
  serializer?: DOMSerializer
) => {
  // Use the schema's default serializer if one isn't passed in (AI prompts pass their own)
  serializer ??= DOMSerializer.fromSchema(schema)
  // ProseMirror will return a DocumentFragment when the third `target` prop is not sent
  const doc = serializer.serializeFragment(fragment) as DocumentFragment
  return transformCopiedDocFragment(doc)
}

const NonTextTags = ['img']
export const isElementEmpty = (node: Element | ChildNode) => {
  if (!node.hasChildNodes()) return true
  if (node.textContent?.trim() != '' || NonTextTags.includes(node.nodeName))
    return false
  return [...node.childNodes].every((child) => {
    return isElementEmpty(child)
  })
}

export const getSerializedNodeAtPos = (editor: Editor, pos: number) => {
  const node = editor.state.doc.nodeAt(pos)
  if (!node) return
  const fragment = Fragment.from(node)
  const serialized = serializeFragment(fragment, editor.schema)
  // Turn the fragment into HTML for clipboard
  const div = document.createElement('div')
  div.appendChild(serialized)
  const html = div.innerHTML
  return html
}

export const serializeFragmentToHtml = (
  fragment: Fragment,
  schema: Schema,
  serializer?: DOMSerializer
) => {
  const serialized = serializeFragment(fragment, schema, serializer)
  // Turn the fragment into HTML for clipboard
  const div = document.createElement('div')
  div.appendChild(serialized)
  const html = div.innerHTML
  return html
}

export const getNodeHtmlContent = (node: Node, serializer?: DOMSerializer) => {
  return serializeFragmentToHtml(node.content, node.type.schema, serializer)
}
