import { ButtonGroup, IconButton } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { useCallback, useRef } from 'react'

import { useAppSelector } from 'modules/redux'
import { Theme } from 'modules/theming'
import {
  EditBackgroundDrawer,
  useEditBackgroundDrawerDisclosure,
} from 'modules/tiptap_editor/extensions/Card/Card2/EditBackgroundDrawer/EditBackgroundDrawer'
import { CardLayoutItemAttrs } from 'modules/tiptap_editor/extensions/Card/CardLayout/types'
import { findCardLayoutDecorationSpec } from 'modules/tiptap_editor/extensions/Card/CardLayout/CardLayoutPlugin'
import { getStartingIndexForBackgroundSelection } from 'modules/tiptap_editor/extensions/Card/CardLayout/cardLayoutUtils'
import { selectTheme } from 'modules/tiptap_editor/reducer'
import {
  BackgroundOptions,
  BackgroundType,
} from 'modules/tiptap_editor/styles/types'

import { ChangeFocusPoint } from './ChangeFocusPoint'
import { NodeFormattingMenuProps } from './types'
import { handleBackgroundResetOrUpdate } from './utils'

export const CardLayoutItemFormattingMenu = ({
  editor,
  selection,
  decorations,
}: NodeFormattingMenuProps) => {
  const { node, from: pos } = selection
  const cardLayoutDeco = findCardLayoutDecorationSpec(decorations)
  const attrs = node.attrs as CardLayoutItemAttrs

  const theme: Theme = useAppSelector(selectTheme)
  const accentBackgrounds = theme.config?.accentBackgrounds
  const initialCount = getStartingIndexForBackgroundSelection(
    attrs.background,
    accentBackgrounds
  )
  const countRef = useRef<number>(initialCount)

  const updateCardLayoutItemAttributes = useCallback(
    (a: Record<string, any>) => {
      handleBackgroundResetOrUpdate(editor, pos, a)
    },
    [editor, pos]
  )

  const addRandomAccentBackground = useCallback(() => {
    if (!accentBackgrounds) {
      return
    }
    editor.commands.updateNestedAttributesAtPos(pos, {
      background: accentBackgrounds[countRef.current],
    })
    countRef.current = (countRef.current + 1) % accentBackgrounds.length
  }, [accentBackgrounds, editor, pos])

  const { openBackgroundTab, ...disclosureProps } =
    useEditBackgroundDrawerDisclosure()

  const type = (node.attrs.background as BackgroundOptions).type
  const showChangeFocusPoint = type === BackgroundType.IMAGE

  return (
    <>
      <EditBackgroundDrawer
        editor={editor}
        {...disclosureProps}
        updateNestedAttributes={updateCardLayoutItemAttributes}
        background={attrs.background}
        isAccentImageDrawer
      />
      <ButtonGroup spacing={1} size="sm" alignItems="center">
        {attrs.itemId === 'accent' && (
          <>
            {accentBackgrounds && accentBackgrounds.length > 0 && (
              <GammaTooltip label={<Trans>Surprise me</Trans>}>
                <IconButton
                  onClick={addRandomAccentBackground}
                  variant="toolbar"
                  size="sm"
                  icon={<FontAwesomeIcon icon={regular('dice')} />}
                  boxSize={7}
                  p={0}
                  aria-label={t`Surprise me`}
                />
              </GammaTooltip>
            )}
            <GammaTooltip label={<Trans>Edit accent image</Trans>}>
              <IconButton
                onClick={openBackgroundTab}
                variant="toolbar"
                size="sm"
                icon={<FontAwesomeIcon icon={regular('image')} />}
                boxSize={7}
                p={0}
                aria-label={t`Edit accent image`}
              />
            </GammaTooltip>
            {showChangeFocusPoint && (
              <ChangeFocusPoint editor={editor} pos={pos} node={node} />
            )}
          </>
        )}
      </ButtonGroup>
    </>
  )
}
