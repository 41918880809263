import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Divider,
  HStack,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  StackProps,
  Text,
  useOutsideClick,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DarkModeProvider, GammaTooltip } from '@gamma-app/ui'
import { undo } from '@gamma-app/y-prosemirror'
import { t, Trans } from '@lingui/macro'
import { Editor, NodeViewProps } from '@tiptap/core'
import { memo, useCallback, useRef } from 'react'

import { useFeatureFlag } from 'modules/featureFlags'
import { useAppDispatch, useAppSelector } from 'modules/redux'
import { handleBackgroundResetOrUpdate } from 'modules/tiptap_editor/components/menus/FormattingMenus/utils'
import {
  EventBusEvent,
  TiptapEventBus as tiptapEventEmitter,
} from 'modules/tiptap_editor/eventBus'
import {
  BackgroundOptions,
  BackgroundType,
  MaskOptions,
} from 'modules/tiptap_editor/styles/types'
import { stopPropagation } from 'utils/handlers'

import { useGetColorsForDragHandle } from '../../DragDrop/ContainerDragHandle/useGetColorsForDragHandle'
import { getCardLayoutItemsFromResolvedPos } from '../CardLayout/cardLayoutUtils'
import { CardAttributes, DisplayLayout } from '../types'
import { backgroundSupportsMask } from './BackgroundMask'
import { CardAccentImageSelector } from './CardStylingMenu/CardAccentImageSelector'
import { CardBackdropSelector } from './CardStylingMenu/CardBackdropSelector'
import { CardAccentBackgroundOverlaySelector } from './CardStylingMenu/CardBackgroundOverlaySelector'
import { CardColorSelector } from './CardStylingMenu/CardColorSelector'
import { CardContentWidthSelector } from './CardStylingMenu/CardContentWidthSelector'
import { CardLayoutSelector } from './CardStylingMenu/CardLayoutSelector'
import { CardWidthSelector } from './CardStylingMenu/CardWidthSelector'
import {
  closeCardStylingMenu,
  selectCardStylingMenuOpen,
  toggleCardStylingMenu,
} from './CardStylingMenu/reducer'
import { CARD_DRAG_HANDLE_HEIGHT } from './constants'
import {
  EditBackgroundDrawer,
  useEditBackgroundDrawerDisclosure,
} from './EditBackgroundDrawer/EditBackgroundDrawer'

type CardStylesMenuProps = {
  editor: Editor
  getPos: NodeViewProps['getPos']
  attrs: CardAttributes
  displayLayout: DisplayLayout
  accentBackgroundMaskEffect: MaskOptions['effect']
  insideSideLayout: boolean
  isNested: boolean
  isFullBleed: boolean
  isWideContent: boolean
  topMargin: StackProps['mt']
} & StackProps

export const CardStylingMenuComponent = ({
  editor,
  getPos,
  attrs,
  accentBackgroundMaskEffect,
  displayLayout,
  insideSideLayout,
  isNested,
  isFullBleed,
  isWideContent,
  topMargin,
}: CardStylesMenuProps) => {
  // Styles should match GlobalDragHandle styles in globals.scss
  const { color, bg, hoverColor, hoverBg, borderColor } =
    useGetColorsForDragHandle()

  const popoverContentRef = useRef<HTMLDivElement>(null)
  const isPopoverOpen = useAppSelector(selectCardStylingMenuOpen(attrs.id))
  const dispatch = useAppDispatch()

  const onClose = useCallback(() => {
    dispatch(closeCardStylingMenu())
  }, [dispatch])

  const onToggle = useCallback(() => {
    dispatch(
      toggleCardStylingMenu({
        cardId: attrs.id,
      })
    )
  }, [dispatch, attrs?.id])

  useOutsideClick({
    ref: popoverContentRef,
    handler: (e) => {
      if (!isPopoverOpen) {
        return
      }
      const target = e.target as HTMLElement
      const popoverTrigger = target?.closest(
        `[data-card-styles-menu-trigger="${attrs.id}"]`
      )
      const clickedOnBgDrawerOverlay = !!target
        ?.closest('.chakra-modal__content-container') // the container for the drawer modal
        ?.querySelector('[data-edit-background-drawer]')
      // don't close if click is on the popover trigger or overlay for the background drawer
      if (popoverTrigger || clickedOnBgDrawerOverlay) {
        return
      }
      onClose()
    },
  })

  const allowFullBleed = useFeatureFlag('borderless')
  const allowContentWidth = useFeatureFlag('cardWidthOptions')
  const canPageSetup = useFeatureFlag('pageSetup')
  const layoutItems = getCardLayoutItemsFromResolvedPos(
    editor.state.doc.resolve(getPos())
  )
  const accentPos = layoutItems.accent?.pos || null
  const accentBackground: BackgroundOptions | undefined =
    layoutItems.accent?.node.attrs.background
  const hasAccentImage =
    accentBackground && accentBackground.type !== BackgroundType.NONE
  const hasBackdrop = attrs.background.type !== 'none'
  const hasOverrides =
    attrs.cardSize !== 'default' ||
    hasBackdrop ||
    hasAccentImage ||
    attrs.container.width ||
    attrs.container.background || // has non-default card color background
    displayLayout !== 'blank'
  const showOverlaySelector =
    displayLayout === 'behind' &&
    hasAccentImage &&
    backgroundSupportsMask(accentBackground)

  const toast = useToast()

  const { openBackgroundTab: editBackdrop, ...backdropDisclosureProps } =
    useEditBackgroundDrawerDisclosure()

  const updateCardNestedAttributes = useCallback(
    (a: Partial<CardAttributes>) => {
      handleBackgroundResetOrUpdate(editor, getPos(), a)
    },
    [editor, getPos]
  )

  const resetAllStyles = useCallback(() => {
    const cardAttrsReset: Partial<CardAttributes> = {}
    if (hasBackdrop) {
      cardAttrsReset.background = {
        type: BackgroundType.NONE,
        source: undefined,
      }
    }
    cardAttrsReset.cardSize = undefined

    cardAttrsReset.container = {
      ...attrs.container,
      width: undefined,
    }

    if (attrs.container.background) {
      cardAttrsReset.container = {
        ...attrs.container,
        background: undefined,
      }
    }
    if (displayLayout !== 'blank') {
      cardAttrsReset.layout = 'blank'
    }

    if (accentPos) {
      editor
        .chain()
        .updateAttributesAtPos(getPos(), cardAttrsReset)
        .updateNestedAttributesAtPos(accentPos, {
          background: { type: BackgroundType.NONE },
        })
        .run()
    } else {
      editor.commands.updateAttributesAtPos(getPos(), cardAttrsReset)
    }
    const removedCardStylingString = t`Removed all card styling`
    const undoString = t`Undo`
    const toastId = toast({
      title: (
        <HStack>
          <Text>{removedCardStylingString}</Text>
          <Link
            textDecoration="underline"
            onClick={() => {
              undo(editor.state)
              toast.close(toastId)
            }}
          >
            {undoString}
          </Link>
        </HStack>
      ),
      status: 'info',
      duration: 3000,
      position: 'top',
    })
  }, [
    hasBackdrop,
    attrs.container,
    displayLayout,
    accentPos,
    editor,
    getPos,
    toast,
  ])

  return (
    <DarkModeProvider isDark={false}>
      <HStack
        spacing={1}
        left={8}
        // set above ContainerDragHandle
        zIndex={4}
        position="absolute"
        mt={topMargin}
        // don't trigger selectCardEdges
        onClick={stopPropagation}
        contentEditable={false}
      >
        <Popover
          isLazy
          returnFocusOnClose={false}
          isOpen={isPopoverOpen}
          onClose={onClose}
          closeOnBlur={false}
        >
          <GammaTooltip
            label={<Trans>Card styling</Trans>}
            placement="top"
            shouldWrapChildren
          >
            <PopoverTrigger>
              <Button
                data-card-styles-menu-trigger={attrs.id}
                height={CARD_DRAG_HANDLE_HEIGHT}
                fontWeight="900"
                alignItems="center"
                fontFamily="Inter, sans-serif"
                display="flex"
                backdropFilter="blur(8px)"
                borderRadius="sm"
                borderColor={borderColor}
                color={color}
                boxShadow="sm"
                cursor="pointer"
                fontSize="14px"
                bg={bg}
                px={0.5}
                _hover={{
                  color: hoverColor,
                  background: hoverBg,
                }}
                onClick={onToggle}
              >
                <Box as="span" display="inline-flex" mr={1}>
                  <FontAwesomeIcon icon={regular('palette')} />
                </Box>
                <ChevronDownIcon />
              </Button>
            </PopoverTrigger>
          </GammaTooltip>
          <Portal>
            <PopoverContent
              data-card-styles-menu
              ref={popoverContentRef}
              maxWidth="320px"
              // Prevent menu from inheriting the card's font settings
              fontFamily="Inter, sans-serif"
              fontSize="md"
              border="none"
            >
              <Stack spacing={5} p={4}>
                <CardLayoutSelector
                  editor={editor}
                  layout={displayLayout}
                  getPos={getPos}
                  edgeLayoutsEnabled={!insideSideLayout}
                />
                {displayLayout !== 'blank' && (
                  <CardAccentImageSelector
                    editor={editor}
                    accentPos={accentPos}
                    cardId={attrs.id}
                    hasAccentImage={!!hasAccentImage}
                    isBehindLayout={displayLayout === 'behind'}
                  />
                )}
                {showOverlaySelector && (
                  <CardAccentBackgroundOverlaySelector
                    editor={editor}
                    accentBackground={accentBackground}
                    accentPos={accentPos}
                    effect={accentBackgroundMaskEffect}
                  />
                )}
                <CardColorSelector
                  editor={editor}
                  attrs={attrs}
                  getPos={getPos}
                />

                {!isNested && (
                  <>
                    <Divider my="3" />
                    {allowFullBleed && (
                      <CardWidthSelector
                        editor={editor}
                        getPos={getPos}
                        isFullWidth={isFullBleed}
                      />
                    )}
                    {allowContentWidth && (
                      <CardContentWidthSelector
                        editor={editor}
                        getPos={getPos}
                        isWideContent={isWideContent}
                      />
                    )}
                    <CardBackdropSelector
                      editor={editor}
                      getPos={getPos}
                      onAddOrEditClick={editBackdrop}
                      hasBackdrop={hasBackdrop}
                      isDisabled={isFullBleed}
                    />
                  </>
                )}
              </Stack>
              <Wrap
                px={5}
                py={3}
                bg="gray.100"
                mx={-1}
                mb={-1}
                justify={hasOverrides ? 'space-between' : 'center'}
                alignItems="center"
                borderBottomRadius="xl"
              >
                {hasOverrides && (
                  <WrapItem>
                    <Button
                      onClick={resetAllStyles}
                      variant="link"
                      size="sm"
                      colorScheme="gray"
                      my={0}
                    >
                      <Trans>Reset styling</Trans>
                    </Button>
                  </WrapItem>
                )}
                {canPageSetup && (
                  <WrapItem>
                    <Button
                      onClick={() => {
                        onClose()
                        tiptapEventEmitter.emit(
                          EventBusEvent.OPEN_PAGE_SETUP,
                          'cards'
                        )
                      }}
                      variant="link"
                      size="sm"
                      colorScheme="gray"
                      my={0}
                    >
                      <Trans>Page setup</Trans>
                    </Button>
                  </WrapItem>
                )}
              </Wrap>
            </PopoverContent>
          </Portal>
        </Popover>

        {/* Card background drawer */}
        <EditBackgroundDrawer
          editor={editor}
          updateNestedAttributes={updateCardNestedAttributes}
          background={attrs.background}
          container={attrs.container}
          {...backdropDisclosureProps}
        />
      </HStack>
    </DarkModeProvider>
  )
}

export const CardStylingMenu = memo(CardStylingMenuComponent)
