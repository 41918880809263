import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Editor } from '@tiptap/core'

import {
  getBodySizeOptions,
  getHeadingSizeOptions,
  getTitleSizeOptions,
} from 'modules/tiptap_editor/extensions/Font/constants'
import {
  allowedFontSizes,
  FontSize,
  getSelectedFontSizes,
} from 'modules/tiptap_editor/extensions/Font/FontSize'
import { getFontSizeOption } from 'modules/tiptap_editor/extensions/Font/utils'
import { canChangeSelectedNodeType } from 'modules/tiptap_editor/utils'
type TextDropdownProps = {
  editor: Editor
}

export const TextSizeMenu = ({ editor }: TextDropdownProps) => {
  const setFontSize = (value: FontSize) => {
    editor.chain().focus().setFontSize(value).run()
  }
  const { $from } = editor.state.selection
  const allowHeading =
    canChangeSelectedNodeType(editor, 'heading') ||
    allowedFontSizes($from.parent).includes('heading')
  const allowTitle =
    canChangeSelectedNodeType(editor, 'title') ||
    allowedFontSizes($from.parent).includes('title')
  const allowBody =
    canChangeSelectedNodeType(editor, 'paragraph') ||
    allowedFontSizes($from.parent).includes('body')

  if (!allowBody && !allowHeading && !allowTitle) return null

  const currentSizes = getSelectedFontSizes(editor)
  const currentSize = editor.isActive('title')
    ? 'title'
    : editor.isActive('heading')
    ? `h${editor.getAttributes('heading').level}`
    : currentSizes.length === 1
    ? currentSizes[0] || 'default'
    : 'default'
  const currentOption = getFontSizeOption(currentSize)
  const displaySize = (size: number) => {
    // Scales down the actual font size
    return `${Math.sqrt(size)}em`
  }

  return (
    <Menu closeOnSelect={true} isLazy>
      <MenuButton
        size="sm"
        as={Button}
        rightIcon={
          <FontAwesomeIcon
            icon={regular('chevron-down')}
            transform="shrink-6"
          />
        }
        variant="toolbar"
        pl={2}
      >
        {currentOption.label}
      </MenuButton>
      <MenuList maxH="min(25em, 45vh)" overflowY="auto" minW="240px">
        {allowBody && (
          <MenuOptionGroup type="radio" value={currentSize}>
            {getBodySizeOptions().map(
              ([key, { label, size, flagged, shortcut }]) => {
                return (
                  <MenuItemOption
                    value={key}
                    key={key}
                    onClick={() => setFontSize(key)}
                  >
                    <HStack>
                      <Text fontSize={displaySize(size)}>{label}</Text>
                      <Spacer />
                      <Text color="gray.400">{shortcut}</Text>
                    </HStack>
                  </MenuItemOption>
                )
              }
            )}
          </MenuOptionGroup>
        )}
        {allowTitle && (
          <MenuOptionGroup type="radio" value={currentSize}>
            {getTitleSizeOptions().map(([key, { label, size, shortcut }]) => {
              return (
                <MenuItemOption
                  value={key}
                  key={key}
                  onClick={() => setFontSize(key)}
                >
                  <HStack>
                    <Text fontSize={displaySize(size)} fontWeight={'bold'}>
                      {label}
                    </Text>
                    <Spacer />
                    <Text color="gray.400">{shortcut}</Text>
                  </HStack>
                </MenuItemOption>
              )
            })}
          </MenuOptionGroup>
        )}
        {allowHeading && (
          <MenuOptionGroup type="radio" value={currentSize}>
            {getHeadingSizeOptions().map(([key, { label, size, shortcut }]) => {
              return (
                <MenuItemOption
                  value={key}
                  key={key}
                  onClick={() => setFontSize(key)}
                >
                  <HStack>
                    <Text fontSize={displaySize(size)} fontWeight={'bold'}>
                      {label}
                    </Text>
                    <Spacer />
                    <Text color="gray.400">{shortcut}</Text>
                  </HStack>
                </MenuItemOption>
              )
            })}
          </MenuOptionGroup>
        )}
      </MenuList>
    </Menu>
  )
}
