import { CheckIcon } from '@chakra-ui/icons'
import { Button, ButtonGroup, HStack } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Attrs } from 'prosemirror-model'
import { useCallback } from 'react'

import { featureFlags } from 'modules/featureFlags'
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownList,
} from 'modules/tiptap_editor/components/menus/ToolbarDropdown'
import { findSmartLayoutDecoration } from 'modules/tiptap_editor/extensions/SmartLayout/decoration'
import { SmartLayoutAttrs } from 'modules/tiptap_editor/extensions/SmartLayout/types'

import {
  getSmartLayoutVariant,
  getSmartLayoutVariants,
} from '../../../extensions/SmartLayout/variants'
import { DeleteNodeButton } from '../buttons/DeleteNodeButton'
import { FormattingMenuDivider } from './FormattingMenuDivider'
import { NodeFormattingMenuProps } from './types'

export const SmartLayoutFormattingMenu = ({
  editor,
  selection,
  decorations,
}: NodeFormattingMenuProps) => {
  const { node } = selection
  const { options, variantKey } = node.attrs as SmartLayoutAttrs
  const variant = getSmartLayoutVariant(variantKey)

  const updateAttributes = useCallback(
    (attrs: Attrs) => {
      editor.commands.updateNestedAttributesAtPos(selection.from, attrs)
    },
    [editor, selection.from]
  )

  const updateVariant = useCallback(
    (key: string) => {
      updateAttributes({ variantKey: key })
    },
    [updateAttributes]
  )

  return (
    <HStack spacing={2}>
      <Dropdown isLazy>
        <DropdownButton
          as={Button}
          variant="toolbar"
          rightIcon={
            <FontAwesomeIcon
              icon={regular('chevron-down')}
              transform="shrink-6"
            />
          }
          leftIcon={<FontAwesomeIcon icon={variant.icon} fixedWidth />}
          size="sm"
          data-testid="smart-layout-variant-dropdown"
        >
          {variant.name()}
        </DropdownButton>
        <DropdownList
          maxH="min(25em, 45vh)"
          overflowY="auto"
          data-in-editor-focus
          fontSize="md"
        >
          {getSmartLayoutVariants().map(
            ({ key, name, icon, disabled, featureFlag }) => {
              if (disabled || (featureFlag && !featureFlags.get(featureFlag))) {
                return null
              }
              const isChecked = variantKey === key
              return (
                <DropdownItem
                  key={key}
                  data-test-variant-option={key}
                  icon={
                    isChecked ? (
                      <CheckIcon />
                    ) : (
                      <FontAwesomeIcon icon={icon} fixedWidth />
                    )
                  }
                  onClick={() => updateVariant(key)}
                >
                  {name()}
                </DropdownItem>
              )
            }
          )}
        </DropdownList>
      </Dropdown>
      <FormattingMenuDivider />
      <ButtonGroup size="sm">
        {variant.options.map((option) => {
          const deco = findSmartLayoutDecoration(decorations)

          if (
            option.checkEnabled &&
            !option.checkEnabled(node.attrs as SmartLayoutAttrs, deco)
          ) {
            return null
          }
          const value = options[option.key] ?? option.defaultValue
          const updateValue = (newValue: any, updateChildren?: boolean) => {
            updateAttributes({
              options: { ...options, [option.key]: newValue },
            })
            if (updateChildren) {
              node.forEach((child, offset) => {
                editor.commands.updateNestedAttributesAtPos(
                  selection.from + offset + 1,
                  {
                    options: { ...child.attrs.options, [option.key]: newValue },
                  }
                )
              })
            }
          }
          return (
            <option.Control
              key={option.key}
              value={value}
              updateValue={updateValue}
              editor={editor}
              option={option}
            />
          )
        })}
        {/* <FullWidthButton editor={editor} selection={selection} /> */}
      </ButtonGroup>
      <FormattingMenuDivider />
      <DeleteNodeButton editor={editor} />
    </HStack>
  )
}
