import {
  Box,
  ButtonProps,
  Collapse,
  Flex,
  Stack,
  StackProps,
} from '@chakra-ui/react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Trans } from '@lingui/macro'
import { AnimatePresence } from 'framer-motion'
import { memo, MouseEvent, ReactElement } from 'react'

import { Comment, User } from 'modules/api'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { DraftComment } from '../../../DraftCommentsExtension/types'
import { BlockReaction } from '../../types'
import { GenericBlockCommentButton } from './GenericBlockCommentButton'
import { REACTION_COUNT_TO_SHOW, useBlockCommentsButtonData } from './hooks'
import { ReactionButton } from './ReactionButton'

/**
 * Valid states
 * FRESH - no comments or reactions
 * DRAFT - has a draft comment
 * MULTIPLE_THREADS - can have reactions
 * SINGLE_THREAD - can have reactions
 * REACTIONS_ONLY - no threads
 */
type BlockCommentsButtonsProps = {
  isExpanded: boolean
  isMobile: boolean
  user?: User
  userCanComment: boolean
  comments: Comment[]
  reactions: BlockReaction[]
  draftComment: DraftComment | null
  onClickThread: (comment: Comment) => void
  onClickAddReaction: () => void
  onClickExistingReaction: (blockReaction: BlockReaction) => void
  onClickReactionsOverflow: () => void
  onClickAddComment: () => void
  onClosePopup: () => void
  hideAddCommentButton: boolean
  viewingCommentId: string | null
  enableReactions: boolean
  blockAllowsCommenting: boolean
} & StackProps

const SELECTED_THREAD_STYLES: Partial<ButtonProps> = {
  bg: 'yellow.600',
  color: 'yellow.100',
  _hover: {
    bg: 'yellow.600',
    color: 'yellow.100',
  },
}

export const YELLOW_HOVER_STYLES: Partial<ButtonProps['_hover']> = {
  bg: 'yellow.100',
  color: 'yellow.800',
}
const THREAD_HOVER_STYLES: Partial<ButtonProps['_hover']> = {
  bgGradient: 'linear(to-b, yellow.300, yellow.400)',
  color: 'yellow.700',
}
export const YELLOW_BG_GRADIENT = 'linear(to-b, yellow.200, yellow.300)'

const stopPropagation = (ev: MouseEvent) => {
  ev.stopPropagation()
}
export const BlockCommentsButtons: React.FC<BlockCommentsButtonsProps> = memo(
  ({
    user,
    userCanComment,
    isExpanded,
    isMobile,
    comments,
    reactions,
    draftComment,
    onClickThread,
    onClickAddComment,
    onClickAddReaction,
    onClickReactionsOverflow,
    onClickExistingReaction,
    onClosePopup,
    hideAddCommentButton = false,
    viewingCommentId,
    enableReactions,
    blockAllowsCommenting,
    ...stackProps
  }) => {
    const { state, allCount, overflowReactionCount } =
      useBlockCommentsButtonData({
        comments,
        reactions,
        draftComment,
      })
    const buttons: ReactElement[] = []

    if (!isExpanded) {
      switch (state) {
        case 'draft':
          buttons.push(
            <GenericBlockCommentButton
              key="draft"
              isMobile={isMobile}
              icon={regular('comment-lines') as IconProp}
              onClick={onClickAddComment}
              bg={isExpanded ? 'gray.100' : 'white'}
              _hover={YELLOW_HOVER_STYLES}
              color="gray.600"
            />
          )
          break
        case 'fresh':
          buttons.push(
            <GenericBlockCommentButton
              key="fresh"
              isMobile={isMobile}
              icon={solid('comment-plus') as IconProp}
              bg={isExpanded ? 'gray.100' : 'white'}
              _hover={YELLOW_HOVER_STYLES}
              color="gray.600"
              bgGradient={YELLOW_BG_GRADIENT}
              tooltip={<Trans>Add comment</Trans>}
              data-testid="create-comment-button"
              onClick={onClickAddComment}
            />
          )
          break
        case 'reactions-only':
          if (reactions.length > 0) {
            const reaction = reactions[0]

            const emojiCluster = reactions.reduce<string[]>((carry, r) => {
              carry.push(r.emoji)
              return carry
            }, [])

            buttons.push(
              <ReactionButton
                key={reaction.emoji}
                isMobile={isMobile}
                onClick={(ev) => {
                  onClickExistingReaction(reaction)
                  ev.stopPropagation()
                }}
                reaction={reaction}
                user={user}
                badgeCount={allCount}
                emoji={reaction.emoji}
                data-testid={`reaction-${reaction.emoji}`}
                emojiCluster={emojiCluster}
                canReact={userCanComment && blockAllowsCommenting}
              />
            )
          }
          break
        case 'single-thread':
          buttons.push(
            <GenericBlockCommentButton
              key="first-thread"
              isMobile={isMobile}
              icon={solid('comment') as IconProp}
              bgGradient={YELLOW_BG_GRADIENT}
              badgeCount={allCount}
              data-testid="single-thread"
            />
          )
          break
        case 'multiple-threads':
          buttons.push(
            <GenericBlockCommentButton
              key="first-thread"
              isMobile={isMobile}
              bgGradient={YELLOW_BG_GRADIENT}
              icon={solid('comments') as IconProp}
              badgeCount={allCount}
              data-testid="multiple-threads"
            />
          )
          break
        // TODO handle reactions-only
      }
    }

    if (isExpanded) {
      // expanded thread view
      comments.forEach((comment, index) => {
        const isViewing =
          viewingCommentId !== null && comment.id === viewingCommentId

        const selectedThreadStyles: Partial<ButtonProps> = isViewing
          ? SELECTED_THREAD_STYLES
          : {}

        const onClick = isViewing
          ? () => onClosePopup()
          : () => onClickThread(comment)
        buttons.push(
          <GenericBlockCommentButton
            key={index === 0 ? 'first-thread' : `thread-${index}`}
            isMobile={isMobile}
            icon={solid('comment') as IconProp}
            badgeCount={comment.replies!.length + 1}
            bgGradient={YELLOW_BG_GRADIENT}
            onClick={onClick}
            data-testid={`thread-${index}`}
            {...selectedThreadStyles}
          />
        )
      })

      // Reactions and overflow reactions
      if (enableReactions) {
        reactions.slice(0, REACTION_COUNT_TO_SHOW).forEach((reaction) => {
          buttons.push(
            <ReactionButton
              key={reaction.emoji}
              isMobile={isMobile}
              onClick={(ev) => {
                onClickExistingReaction(reaction)
                ev.stopPropagation()
              }}
              data-testid={`reaction-${reaction.emoji}`}
              reaction={reaction}
              user={user}
              badgeCount={reaction.count}
              emoji={reaction.emoji}
              canReact={userCanComment && blockAllowsCommenting}
            />
          )
        })
        if (overflowReactionCount > 0) {
          buttons.push(
            <GenericBlockCommentButton
              isMobile={isMobile}
              key="reactions-overflow"
              onClick={() => {
                onClickReactionsOverflow()
              }}
              bg="gray.100"
              color="yellow.800"
              _hover={YELLOW_HOVER_STYLES}
            >
              +{overflowReactionCount}
            </GenericBlockCommentButton>
          )
        }
      }

      // add comment button
      if (state === 'draft') {
        buttons.push(
          <GenericBlockCommentButton
            key="draft"
            isMobile={isMobile}
            icon={regular('comment-lines') as IconProp}
            tooltip={<Trans>Edit draft comment</Trans>}
            onClick={onClickAddComment}
            bg={isExpanded ? 'gray.100' : 'white'}
            _hover={YELLOW_HOVER_STYLES}
            data-testid="create-comment-button-draft"
            color="gray.600"
          />
        )
      } else if (!hideAddCommentButton) {
        if (!isMobile) {
          // user is logged in with Comment permission or is logged out with token
          buttons.push(
            <GenericBlockCommentButton
              key="fresh"
              isMobile={isMobile}
              icon={solid('comment-plus') as IconProp}
              bgGradient={YELLOW_BG_GRADIENT}
              tooltip={<Trans>Add comment</Trans>}
              onClick={onClickAddComment}
              // TODO refactor these in button "variants"
              bg={isExpanded ? 'gray.100' : 'white'}
              _hover={YELLOW_HOVER_STYLES}
              data-testid="create-comment-button"
              color="gray.600"
            />
          )
        }
      }

      // add reaction button
      if (enableReactions && userCanComment && !hideAddCommentButton) {
        if (!isMobile) {
          buttons.push(
            <GenericBlockCommentButton
              key="add-reaction"
              isMobile={isMobile}
              icon={solid('smile-plus') as IconProp}
              bgGradient={YELLOW_BG_GRADIENT}
              onClick={() => {
                onClickAddReaction()
              }}
              tooltip={<Trans>Add reaction</Trans>}
              data-testid="add-reaction-button"
              bg="gray.100"
              _hover={YELLOW_HOVER_STYLES}
              color="gray.600"
            />
          )
        }
      }
    }

    if (isMobile) {
      return (
        <Flex
          className="block-comments-inner-stack"
          userSelect="none"
          transitionProperty="all"
          transitionDuration="fast"
          flexDir="row"
          flexWrap="wrap"
          onClick={stopPropagation} // Prevents button click from selecting after a nested card
        >
          {buttons}
        </Flex>
      )
    }

    return (
      <Stack
        className="block-comments-inner-stack"
        userSelect="none"
        spacing={0}
        width="34px"
        transitionProperty="all"
        transitionDuration="fast"
        onClick={stopPropagation} // Prevents button click from selecting after a nested card
        borderRadius="full"
        boxShadow={'0 0 0 2px rgba(0,0,0,.1)'}
        bg="white"
        border="1px solid transparent"
        onMouseDown={preventDefaultToAvoidBlur}
        data-testid="block-comments-buttons"
        {...stackProps}
      >
        <Box>{buttons[0]}</Box>
        <Collapse
          in={isExpanded}
          startingHeight={0}
          transitionEnd={{
            enter: {
              overflow: 'initial',
            },
          }}
        >
          <AnimatePresence>
            {buttons.length > 1 && (
              <Stack spacing={1}>{buttons.slice(1)}</Stack>
            )}
          </AnimatePresence>
        </Collapse>
      </Stack>
    )
  }
)

BlockCommentsButtons.displayName = 'BlockCommentsButtons'
