import { CheckIcon } from '@chakra-ui/icons'
import { Button } from '@chakra-ui/react'
import { GammaTooltip } from '@gamma-app/ui'

import { isColorDark } from 'utils/color'

type ColorIconProps = {
  color: string
  setColor: (color: string) => void
  isActive: boolean
  name?: string
}

export const ColorIcon = ({
  color,
  setColor,
  isActive,
  name,
}: ColorIconProps) => {
  const selectedColor = isColorDark(color) ? 'white' : 'black'
  return (
    <GammaTooltip label={name || color.toUpperCase()} placement="top">
      <Button
        display="inline-block"
        alignSelf="center"
        boxSize="26px"
        borderRadius="md"
        border="1px solid"
        borderColor="blackAlpha.300"
        color={selectedColor}
        bgColor={color ?? '#FFFFFF'}
        onClick={() => setColor(color)}
        variant="unstyled"
        minW={0}
        fontSize="xs"
        transitionProperty="border"
        transitionDuration="normal"
      >
        {isActive && <CheckIcon />}
      </Button>
    </GammaTooltip>
  )
}
