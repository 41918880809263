import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t, Trans } from '@lingui/macro'

import { FeatureFlags } from 'modules/featureFlags'
import { CommandInfo, getCommandsMap } from 'modules/tiptap_editor/commands'
import { getCalloutBoxCommands } from 'modules/tiptap_editor/extensions/CalloutBox/options'
import { getGroupedCardTemplates } from 'modules/tiptap_editor/extensions/Card/cardTemplates'
import { MediaCommands } from 'modules/tiptap_editor/extensions/media/MediaCommands'
import {
  SmartLayoutCommands,
  SmartLayoutTemplateCommands,
} from 'modules/tiptap_editor/extensions/SmartLayout/commands'

import { DrawingCommands } from '../../../extensions/Drawing/templates'

export type InsertableCategory = Record<
  InsertableCategoryNameEnum,
  InsertableCategoryInfo
>
export type InsertableItemGroup = {
  subcategory: JSX.Element
  items: Array<CommandInfo>
}
export type InsertableCategoryInfo = {
  name: string
  icon: IconProp
  itemGroups: Array<InsertableItemGroup>
  featureFlag?: keyof FeatureFlags
}

export enum InsertableCategoryNameEnum {
  Cards = 'cards',
  Images = 'images',
  Apps = 'apps',
  Videos = 'videos',
  Layouts = 'layouts',
  Drawings = 'drawings',
  Text = 'text',
  Callouts = 'callouts',
  Forms = 'forms',
}

export enum SubcategoryEnum {
  Uncategorized = 'Other',
  Table = 'Table',
  Grid = 'Grid',
  SmartLayout = 'Smart layouts',
  Lists = 'Lists',
  Headings = 'Headings',
  Diagrams = 'Diagrams',
  New = 'New',
  Callouts = 'Callout boxes',
}

export const subCategoryEnumToDisplayName = {
  [SubcategoryEnum.Uncategorized]: <Trans>Other</Trans>,
  [SubcategoryEnum.Table]: <Trans>Table</Trans>,
  [SubcategoryEnum.Grid]: <Trans>Grid</Trans>,
  [SubcategoryEnum.SmartLayout]: <Trans>Smart layouts</Trans>,
  [SubcategoryEnum.Lists]: <Trans>Lists</Trans>,

  [SubcategoryEnum.Headings]: <Trans>Headings</Trans>,
  [SubcategoryEnum.Diagrams]: <Trans>Diagrams</Trans>,
  [SubcategoryEnum.New]: <Trans>New</Trans>,
  [SubcategoryEnum.Callouts]: <Trans>Callout boxes</Trans>,
}

export const getInsertableCategoriesMap = (): InsertableCategory => {
  const COMMANDS_MAP = getCommandsMap()
  return {
    [InsertableCategoryNameEnum.Cards]: {
      name: t`Card templates`,
      icon: duotone('rectangle-history-circle-plus'),
      itemGroups: getGroupedCardTemplates(),
    },

    [InsertableCategoryNameEnum.Images]: {
      name: t`Add images`,
      icon: duotone('image'),
      itemGroups: [
        {
          subcategory:
            subCategoryEnumToDisplayName[SubcategoryEnum.Uncategorized],
          items: [
            ...MediaCommands.filter((command) => command.nodeName === 'image'),
            COMMANDS_MAP.gallery,
          ],
        },
      ],
    },
    [InsertableCategoryNameEnum.Apps]: {
      name: t`Embed apps & webpages`,
      icon: duotone('browser'),

      itemGroups: [
        {
          subcategory:
            subCategoryEnumToDisplayName[SubcategoryEnum.Uncategorized],
          items: [
            ...MediaCommands.filter(
              (command) =>
                command.nodeName === 'embed' &&
                (!command.categories || command.categories.includes('app'))
            ),
          ],
        },
      ],
    },
    [InsertableCategoryNameEnum.Videos]: {
      name: t`Embed videos`,
      icon: duotone('film'),
      itemGroups: [
        {
          subcategory:
            subCategoryEnumToDisplayName[SubcategoryEnum.Uncategorized],
          items: [
            ...MediaCommands.filter((command) => command.nodeName === 'video'),
          ],
        },
      ],
    },
    [InsertableCategoryNameEnum.Layouts]: {
      name: t`Layout options`,
      icon: duotone('table-layout'),
      itemGroups: [
        {
          subcategory: subCategoryEnumToDisplayName[SubcategoryEnum.Grid],
          items: [
            COMMANDS_MAP.columns2,
            COMMANDS_MAP.columns3,
            COMMANDS_MAP.columns4,
            COMMANDS_MAP.gallery,
          ],
        },
        {
          subcategory: subCategoryEnumToDisplayName[SubcategoryEnum.Table],
          items: [
            COMMANDS_MAP.table2,
            COMMANDS_MAP.table3,
            COMMANDS_MAP.table4,
          ],
        },
        {
          subcategory:
            subCategoryEnumToDisplayName[SubcategoryEnum.SmartLayout],
          items: SmartLayoutCommands,
        },
      ],
    },
    [InsertableCategoryNameEnum.Drawings]: {
      name: t`Visual templates`,
      icon: duotone('shapes'),
      featureFlag: 'drawingBlock',
      itemGroups: [
        {
          subcategory:
            subCategoryEnumToDisplayName[SubcategoryEnum.SmartLayout],
          items: SmartLayoutTemplateCommands,
        },
        {
          subcategory: subCategoryEnumToDisplayName[SubcategoryEnum.Diagrams],
          items: DrawingCommands,
        },
      ],
    },
    [InsertableCategoryNameEnum.Text]: {
      name: t`Text formatting`,
      icon: duotone('font-case'),
      itemGroups: [
        {
          subcategory: subCategoryEnumToDisplayName[SubcategoryEnum.Headings],
          items: [
            COMMANDS_MAP.title,
            COMMANDS_MAP.h1,
            COMMANDS_MAP.h2,
            COMMANDS_MAP.h3,
            COMMANDS_MAP.h4,
          ],
        },
        {
          subcategory: subCategoryEnumToDisplayName[SubcategoryEnum.Lists],
          items: [
            COMMANDS_MAP.bulletedList,
            COMMANDS_MAP.numberedList,
            COMMANDS_MAP.todoList,
          ],
        },
        {
          subcategory:
            subCategoryEnumToDisplayName[SubcategoryEnum.Uncategorized],
          items: [
            COMMANDS_MAP.codeBlock,
            COMMANDS_MAP.mathBlock,
            COMMANDS_MAP.footnote,
            COMMANDS_MAP.toggle,
            COMMANDS_MAP.contributors,
            COMMANDS_MAP.divider,
            COMMANDS_MAP.tableOfContents,
            COMMANDS_MAP.normalText,
          ],
        },
      ],
    },
    [InsertableCategoryNameEnum.Callouts]: {
      name: t`Callout blocks`,
      icon: duotone('comment-alt-exclamation'),
      itemGroups: [
        {
          subcategory: subCategoryEnumToDisplayName[SubcategoryEnum.Callouts],
          items: getCalloutBoxCommands(),
        },
        {
          subcategory:
            subCategoryEnumToDisplayName[SubcategoryEnum.Uncategorized],
          items: [COMMANDS_MAP.blockquote],
        },
      ],
    },
    [InsertableCategoryNameEnum.Forms]: {
      name: t`Forms & buttons`,
      icon: duotone('pen-field'),
      itemGroups: [
        {
          subcategory:
            subCategoryEnumToDisplayName[SubcategoryEnum.Uncategorized],
          items: [
            COMMANDS_MAP.buttonGroup,
            ...MediaCommands.filter(
              (command) =>
                command.nodeName === 'embed' &&
                command.categories?.includes('form')
            ),
          ],
        },
      ],
    },
  }
}

// Used to determine the order in which the categories appear in the widget
const getSortedCategories = (): Array<Array<InsertableCategoryNameEnum>> => {
  return [
    [
      InsertableCategoryNameEnum.Cards,
      InsertableCategoryNameEnum.Text,
      InsertableCategoryNameEnum.Callouts,
      InsertableCategoryNameEnum.Layouts,
      InsertableCategoryNameEnum.Drawings,
    ],
    [
      InsertableCategoryNameEnum.Images,
      InsertableCategoryNameEnum.Videos,
      InsertableCategoryNameEnum.Apps,
      InsertableCategoryNameEnum.Forms,
    ],
  ]
}

export const getInsertableCategoriesList = () =>
  getSortedCategories().map((outer) =>
    outer.map((key) => getInsertableCategoriesMap()[key])
  )
