import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Trans } from '@lingui/macro'
import tinycolor from 'tinycolor2'

import { ColorPickerMenuInner } from 'modules/theming/components/ColorPickerMenu/ColorPickerMenu'

import { CardStyleRow } from './CardStyleRow'
import {
  CardColorSelectorProps,
  useCardColorSelector,
} from './useCardColorSelector'

export const CardColorSelector = ({
  editor,
  attrs,
  getPos,
}: CardColorSelectorProps) => {
  const { setCardColor, currentColor, themeCardColor } = useCardColorSelector({
    editor,
    attrs,
    getPos,
  })
  const isDefaultColor = currentColor === undefined
  return (
    <CardStyleRow
      icon={regular('fill')}
      label={
        attrs.layout === 'behind' ? (
          <Trans>Overlay color</Trans>
        ) : (
          <Trans>Card color</Trans>
        )
      }
    >
      <Menu isLazy>
        <MenuButton
          as={Button}
          variant="plain"
          size="sm"
          boxShadow="none"
          minW="128px"
          textAlign="left"
          rightIcon={<ChevronDownIcon />}
        >
          <Flex as="span" alignItems="center" gap={2}>
            <ColorIcon color={currentColor ?? themeCardColor} />
            <Text
              as="span"
              // monospace and uppercase for hex codes, used for non-default
              fontFamily={isDefaultColor ? undefined : 'mono'}
              textTransform={isDefaultColor ? undefined : 'uppercase'}
              fontSize="sm"
            >
              {isDefaultColor ? (
                <Trans>Default</Trans>
              ) : (
                tinycolor(currentColor).toHexString()
              )}
            </Text>
          </Flex>
        </MenuButton>
        <MenuList overflow="hidden">
          <ColorPickerMenuInner
            currentColor={currentColor ?? themeCardColor}
            setColor={setCardColor}
            source="CardColor"
          />
        </MenuList>
      </Menu>
    </CardStyleRow>
  )
}

const ColorIcon = ({ color }: { color?: string }) => {
  return (
    <Box
      as="span"
      alignSelf="center"
      boxSize={5}
      borderRadius="md"
      border="2px solid"
      borderColor="gray.300"
      bgColor={color ?? '#FFFFFF'}
    />
  )
}
