import { config } from 'config'
import {
  getApolloClient,
  UpdateUserDocument,
  UpdateUserSettingsDocument,
} from 'modules/api'
import { ErrorCode } from 'modules/errors'
import { sendGTMSignupCompleteEvent } from 'modules/googleTagManager/events'
import {
  getSupportedLocaleKeyFromNavigatorLanguageOrUseFallback,
  isNavigatorLanguageGA,
} from 'modules/i18n/utils/localeKey'
import { getUserMetadataStore } from 'modules/performance/UserMetadataStore'
import { analytics, SegmentEvents } from 'modules/segment'
import { generateSettingsUpdatePatch } from 'modules/user'
import { localStore } from 'utils/storage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import { getDefaultSignupRedirect, getLoginBroadcastChannel } from './utils'

let windowObjectReference: Window | null = null
let previousUrl: string | null = null

export enum WindowPostMessageTypes {
  LOGIN_COMPLETE = 'loginComplete',
  LOGIN_FAILURE = 'loginFailure',
  SIGNUP_COMPLETE = 'signupComplete',
}

type SignupData = {
  isFirstWorkspace: boolean // A user just signed up, and this is that user's FIRST workspace
  newWorkspace: boolean // A user just signed up, and a new workspace was created
  signupFlow: string
  welcomeDocId?: string
  userId: string
  workspaceId: string
}

export type MessageEventData = {
  type: WindowPostMessageTypes
  payload?: {
    code?: ErrorCode
    data?: SignupData
  }
}

type ReceiveMessageParams = {
  event: MessageEvent<MessageEventData>
  errorCallback?: (code: ErrorCode) => void
  redirectTo?: string
  doneCallback?: () => void
}

const identifyLoggedinUser = (data?: SignupData) => {
  if (!data) return
  const { userId, workspaceId } = data
  analytics.identify(userId, {
    user_org_id: workspaceId,
    anonymous_user: false,
    gamma_user_id: userId,
  })
}

const setUserReferrer = (id: string, referrer: string) => {
  return getApolloClient().mutate({
    mutation: UpdateUserDocument,
    variables: {
      input: {
        id,
        referrer,
      },
    },
  })
}

const setUserLocale = () => {
  const locale = getSupportedLocaleKeyFromNavigatorLanguageOrUseFallback()
  const settings = {
    set: {
      locale,
    },
  }
  const patch = generateSettingsUpdatePatch(settings)

  return getApolloClient().mutate({
    mutation: UpdateUserSettingsDocument,
    variables: {
      patch,
    },
  })
}

const skipTour = (signupFlow: string) => {
  // Skip tour and welcome if user comes through doc invitation or access link
  return ['docAccessLink', 'docInvitation'].includes(signupFlow)
}

const getDefaultRedirectForFirstWorkspace = ({
  data,
  redirectTo,
}: {
  data?: SignupData
  redirectTo?: string
}) => {
  let defaultRedirect = redirectTo ? redirectTo : getDefaultSignupRedirect()

  if (data) {
    const { welcomeDocId, newWorkspace, signupFlow } = data

    if (welcomeDocId) {
      // The welcomeDocId is not persisted on the BE, so store it in local
      // storage here for possible use on initial sign up
      try {
        localStore.setItem(
          USER_SETTINGS_CONSTANTS.welcomeDocId,
          JSON.stringify(welcomeDocId)
        )
      } catch (err) {
        // Owell, we tried
      }
    }

    if (skipTour(signupFlow)) {
      return defaultRedirect
    }

    // This is a new workspace, and we should give them the chance to set the workspace name
    if (newWorkspace) {
      defaultRedirect = '/welcome?newWorkspace=true'
    }
  }
  return defaultRedirect
}
export const receiveMessage = ({
  event,
  errorCallback,
  redirectTo,
  doneCallback,
}: ReceiveMessageParams): void => {
  const userMetadataStore = getUserMetadataStore()
  const context = userMetadataStore.get()

  const { data } = event

  if (data.type === WindowPostMessageTypes.LOGIN_COMPLETE) {
    let defaultRedirect = redirectTo ? redirectTo : '/'
    identifyLoggedinUser(data?.payload?.data)
    analytics.track(
      SegmentEvents.LOGIN,
      {
        provider: 'google',
      },
      { context }
    )
    if (!redirectTo && doneCallback) {
      doneCallback()
      return
    }

    // Handle the scenario where an orgless user joins a workspace for the first time
    const isFirstWorkspaceForUser = data?.payload?.data?.isFirstWorkspace
    if (isFirstWorkspaceForUser && data?.payload?.data) {
      defaultRedirect = getDefaultRedirectForFirstWorkspace({
        data: data.payload?.data,
        redirectTo,
      })
    }

    window.location.href = defaultRedirect
  }
  if (data.type === WindowPostMessageTypes.SIGNUP_COMPLETE) {
    identifyLoggedinUser(data?.payload?.data)
    analytics.track(
      SegmentEvents.SIGNUP,
      {
        flow: data?.payload?.data?.signupFlow,
        provider: 'google',
      },
      { context }
    )

    const userId = data?.payload?.data?.userId
    const redirectPromise =
      userId && config.IS_USER_TESTING_SESSION
        ? setUserReferrer(userId, 'USER_TESTING')
        : Promise.resolve()

    if (userId && isNavigatorLanguageGA()) {
      /// Only set locale override if it's a GA language
      setUserLocale()
    }

    let defaultRedirect = redirectTo ? redirectTo : '/'

    const isFirstWorkspaceForUser = data?.payload?.data?.isFirstWorkspace
    // If a user already has a workspace, and is joining another one, or,
    // if for some reason we explicitly set `isFirstWorkspace` to false in
    // signup-flow.ts, as in the case of `DocInvitationSignupFlow` and
    // `DocAccessLinkSignupFlow`, then we never want to direct them to /?getStarted=true
    if (!redirectTo && doneCallback && !isFirstWorkspaceForUser) {
      doneCallback()
      return
    }

    if (isFirstWorkspaceForUser && data?.payload?.data) {
      sendGTMSignupCompleteEvent()
      const { signupFlow } = data.payload.data
      if (skipTour(signupFlow) && doneCallback) {
        doneCallback()
        return
      }
      defaultRedirect = getDefaultRedirectForFirstWorkspace({
        data: data.payload?.data,
        redirectTo,
      })
    }

    redirectPromise.then(() => {
      window.location.href = defaultRedirect
    })
  }
  if (data.type === WindowPostMessageTypes.LOGIN_FAILURE) {
    const code = event?.data?.payload?.code
    if (!errorCallback) {
      console.error('No error callback defined')
      return
    }
    if (!code) {
      console.error('No code defined')
      return
    }
    errorCallback(code)
  }
  // if we trust the sender and the source is our popup
}

export enum LoginScenarios {
  ACCEPT_DOC_INVITATION = 'accept-doc-invitation',
  ACCEPT_WORKSPACE_INVITATION = 'accept-workspace-invitation',
  DOC_ACCESS_LINK = 'doc-access-link',
  JOIN_WITH_CODE = 'join-with-code',
  DEFAULT_LOGIN = 'login',
  SIGNUP = 'signup',
}

// TODO: We should just use scenario in the backend as a way
// to identify flow and then get rid of this mapping
export const ScenarioToFlowMap: Record<LoginScenarios, string> = {
  'accept-doc-invitation': 'docInvitation',
  'accept-workspace-invitation': 'workspaceInvitation',
  'doc-access-link': 'docAccessLink',
  'join-with-code': 'joinLink',
  login: 'signup',
  signup: 'signup',
}

export type SignupParams = {
  email?: string
  docId?: string
  inviteCode?: string
  workspaceId?: string
  referredByUserId?: string
  partnerStackClickId?: string
  partnerStackPartnerId?: string
  shareToken?: string
}

const getLoginUrl = (type: LoginScenarios, params?: SignupParams) => {
  switch (type) {
    case LoginScenarios.JOIN_WITH_CODE: {
      const { workspaceId, inviteCode, referredByUserId } =
        params as SignupParams
      const url: URL = new URL(
        `${
          config.API_HOST || 'https://gamma.app'
        }/organizations/${workspaceId}/join/${inviteCode}`
      )
      if (referredByUserId) {
        url.searchParams.set('referredByUserId', referredByUserId)
      }
      return url.toString()
    }
    case LoginScenarios.ACCEPT_DOC_INVITATION: {
      const { docId, email, inviteCode } = params as SignupParams
      return `${config.API_HOST}/invitations/docs/${docId}/?inviteCode=${inviteCode}&email=${email}`
    }
    case LoginScenarios.ACCEPT_WORKSPACE_INVITATION: {
      const { workspaceId, email, inviteCode } = params as SignupParams
      return `${config.API_HOST}/invitations/workspaces/${workspaceId}?inviteCode=${inviteCode}&email=${email}`
    }
    case LoginScenarios.DOC_ACCESS_LINK: {
      const { docId, shareToken } = params as SignupParams
      return `${config.API_HOST}/access-links/docs/${docId}/?token=${shareToken}`
    }
    case LoginScenarios.SIGNUP: {
      const {
        inviteCode,
        referredByUserId,
        partnerStackClickId,
        partnerStackPartnerId,
      } = params as SignupParams
      const url: URL = new URL(
        `${config.API_HOST || 'https://gamma.app'}/signup`
      )
      if (inviteCode) {
        url.searchParams.set('inviteCode', inviteCode)
      }
      if (referredByUserId) {
        url.searchParams.set('referredByUserId', referredByUserId)
      }
      if (partnerStackClickId) {
        url.searchParams.set('partnerStackClickId', partnerStackClickId)
      }
      if (partnerStackPartnerId) {
        url.searchParams.set('partnerStackPartnerId', partnerStackPartnerId)
      }
      return url.toString()
    }
    default:
      return `${config.API_HOST}/login`
  }
}

type OpenLoginWindowParams = {
  type: LoginScenarios
  params?: SignupParams
  errorCallback?: (code: ErrorCode) => void
  redirectTo?: string
  partnerStackClickId?: string
  partnerStackPartnerId?: string
  doneCallback?: () => void
}

export const openLoginWindow = ({
  type,
  params,
  errorCallback,
  redirectTo,
  doneCallback,
}: OpenLoginWindowParams) => {
  const url = getLoginUrl(type, params)

  const handleMessage = (event: MessageEvent) => {
    receiveMessage({ event, errorCallback, redirectTo, doneCallback })
  }

  // window features
  const strWindowFeatures =
    'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'

  if (windowObjectReference === null || windowObjectReference.closed) {
    /* if the pointer to the window object in memory does not exist
      or if such pointer exists but the window was closed */
    windowObjectReference = window.open(url, type, strWindowFeatures)
  } else if (previousUrl !== url) {
    /* if the resource to load is different,
      then we load it in the already opened secondary window and then
      we bring such window back on top/in front of its parent window. */
    windowObjectReference = window.open(url, type, strWindowFeatures)
    if (windowObjectReference !== null) {
      windowObjectReference.focus()
    }
  } else {
    /* else the window reference must exist and the window
      is not closed; therefore, we can bring it back on top of any other
      window with the focus() method. There would be no need to re-create
      the window or to reload the referenced resource. */
    windowObjectReference.focus()
  }

  // add the listener for receiving a message from the popup
  getLoginBroadcastChannel().addEventListener('message', handleMessage)
  // assign the previous URL
  previousUrl = url
}
