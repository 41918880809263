import { Switch } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Trans } from '@lingui/macro'
import { Editor, NodeViewProps } from '@tiptap/core'
import { useCallback } from 'react'

import { CardStyleRow } from './CardStyleRow'

type CardWidthSelectorProps = {
  editor: Editor
  getPos: NodeViewProps['getPos']
  isFullWidth: boolean
}
export const CardWidthSelector = ({
  editor,
  getPos,
  isFullWidth,
}: CardWidthSelectorProps) => {
  const toggleFullWidth = useCallback(() => {
    editor.commands.updateAttributesAtPos(getPos(), {
      cardSize: isFullWidth ? 'contained' : 'full',
    })
  }, [editor, getPos, isFullWidth])

  return (
    <CardStyleRow
      icon={regular('rectangle-wide')}
      label={<Trans>Full-bleed card</Trans>}
    >
      <Switch isChecked={isFullWidth} onChange={toggleFullWidth} />
    </CardStyleRow>
  )
}
