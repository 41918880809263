import { Extension } from '@tiptap/core'
import { Plugin } from 'prosemirror-state'

import { ExtensionPriorityMap } from '../../constants'
import { uploadImageForCardAccentLayoutItem } from '../../media/Upload/insertFiles'
import {
  checkCardAccentDropTarget,
  handleImageCardAccentDrop,
} from './cardLayoutDragAndDropUtils'

/**
 * This extension handles drag and drop images on card accents.  This is its
 * own extension because it needs to be higher priority than the regular CardLayoutItem
 * which handles drag and drop to create a card accent layout.
 */
export const ReplaceCardAccentExtension = Extension.create({
  name: 'replaceCardAccent',

  priority: ExtensionPriorityMap.ReplaceCardAccent,

  addProseMirrorPlugins() {
    const editor = this.editor
    return [
      new Plugin({
        props: {
          handleDOMEvents: {
            // this handles image file drops that need to be uploaded
            drop(view, event: DragEvent): boolean {
              const tr = view.state.tr
              const dropTarget = checkCardAccentDropTarget(view, event)
              if (dropTarget && dropTarget.isImageUpload) {
                event.preventDefault()
                uploadImageForCardAccentLayoutItem(
                  editor,
                  tr,
                  dropTarget.file,
                  dropTarget.pos
                )
                view.dispatch(tr)
                return true
              }

              return false
            },
          },
          // this handles drops from existing ProseMirror content
          handleDrop: handleImageCardAccentDrop,
        },
      }),
    ]
  },
})
