import { Flex, FlexProps, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { memo } from 'react'

type OfflineBoxProps = {
  label: JSX.Element
  isConnected?: boolean
} & FlexProps

export const OfflineInfoBox = memo(
  ({ label, isConnected = true, ...rest }: OfflineBoxProps) => {
    if (isConnected) return null

    return (
      <Flex
        bg="gray.50"
        p={4}
        borderRadius="lg"
        textAlign="center"
        alignItems="center"
        direction="column"
        {...rest}
      >
        <Text fontSize="sm" fontWeight="bold">
          <Trans>You're offline</Trans>
        </Text>
        <Text fontSize="sm">{label}</Text>
      </Flex>
    )
  }
)

OfflineInfoBox.displayName = 'OfflineInfoBox'
