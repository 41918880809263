import { MenuGroup, SimpleGrid } from '@chakra-ui/react'

import { AddColorButton } from './AddColorButton'
import { ColorIcon } from './ColorIcon'
import { NamedColor } from './types'

export type ColorPickerMenuSectionProps = {
  label: string
  colors: NamedColor[]
  setColor: (color: string | null) => void
  currentColor: string | null
  isDefault?: boolean
  handleAddColorButtonClick?: () => void
  addColorLabel?: string
}

export const ColorPickerMenuSection = ({
  label,
  colors,
  setColor,
  currentColor,
  isDefault,
  handleAddColorButtonClick,
  addColorLabel = 'Add color',
}: ColorPickerMenuSectionProps) => {
  return (
    <MenuGroup title={label}>
      <SimpleGrid gap={1.5} columns={6} p={2}>
        {colors.map(({ hex, name }, i) => {
          return (
            <ColorIcon
              name={name}
              color={hex}
              key={i}
              setColor={setColor}
              isActive={
                isDefault ? currentColor === null : currentColor === hex
              }
            />
          )
        })}
        {handleAddColorButtonClick && (
          <AddColorButton
            onClick={handleAddColorButtonClick}
            label={addColorLabel}
          />
        )}
      </SimpleGrid>
    </MenuGroup>
  )
}
