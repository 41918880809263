import { Grid, GridProps } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'
import React from 'react'

export interface DocumentGridProps extends GridProps {
  children?: React.ReactNode
}

export const DocumentGrid = ({ children, ...rest }: DocumentGridProps) => {
  return (
    <Grid
      templateColumns={{
        base: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)',
        md: 'repeat(2, 1fr)',
        lg: 'repeat(3, 1fr)',
        xl: 'repeat(4, 1fr)',
        '2xl': 'repeat(5, 1fr)',
      }}
      gap={6}
      {...rest}
    >
      <AnimatePresence>{children}</AnimatePresence>
    </Grid>
  )
}
