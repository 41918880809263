import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'

import {
  DropdownChoice,
  DropdownOptionControl,
} from '../components/DropdownOptionControl'
import { SmartLayoutOption, SmartLayoutOptionControlProps } from '../types'
import { ImageShape } from './types'

const getShapeOptions = (): Record<ImageShape, DropdownChoice> => ({
  square: {
    name: t`Square`,
    icon: regular('square'),
  },
  circle: {
    name: t`Circle`,
    icon: regular('circle'),
  },
  landscape: {
    name: t`Landscape`,
    icon: regular('rectangle-wide'),
  },
  portrait: {
    name: t`Portrait`,
    icon: regular('rectangle-vertical'),
  },
})

export const ImageShapeStyles = {
  square: {
    aspectRatio: '1 / 1',
  },
  circle: {
    borderRadius: 'full',
    aspectRatio: '1 / 1',
  },
  landscape: {
    aspectRatio: '1.618 / 1',
  },
  portrait: {
    aspectRatio: '4 / 5',
  },
}

const ImageShapeOptionControl = ({
  value,
  updateValue,
  editor,
  option,
}: SmartLayoutOptionControlProps<ImageShape | null>) => {
  return (
    <DropdownOptionControl
      value={value}
      updateValue={updateValue}
      editor={editor}
      choices={getShapeOptions()}
      option={option}
      defaultIcon={regular('square')}
    />
  )
}

export const ImageShapeOption: SmartLayoutOption<ImageShape | null> = {
  key: 'imageShape',
  defaultValue: null,
  Control: ImageShapeOptionControl,
  name: () => t`Image shape`,
}
