import { HStack, IconButton, Image, Text, VStack } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { Mark, Node } from 'prosemirror-model'

import { ClickableButtonSx } from 'modules/theming/styles/box'
import { preventDefaultToAvoidBlur } from 'utils/handlers'
import { getLinkDomain } from 'utils/link'

import { MediaEmbedAttrs } from '../types'
import { getMediaSourceUrl } from '../utils'
import { getEmbedProvider } from './utils'

type EmbedDescriptionProps = {
  node: Node | Mark
  disableOpenButton?: boolean
}

export const EmbedDescription = ({
  node,
  disableOpenButton = false,
}: EmbedDescriptionProps) => {
  const { meta, source } = node.attrs as MediaEmbedAttrs
  const sourceUrl = getMediaSourceUrl(node.attrs)
  const provider = getEmbedProvider(source)
  const isGeneric = source === 'embed.custom' || source === 'video.custom'
  const icon = meta?.icon || provider.image?.src
  const site =
    meta?.site || (isGeneric ? getLinkDomain(sourceUrl) : provider.label())

  return (
    <VStack
      spacing={'0.25em'}
      align="flex-start"
      color="black"
      w="100%"
      fontSize="0.9em"
    >
      <HStack
        align="center"
        spacing={'0.25em'}
        w="100%"
        color="var(--body-color-muted)"
      >
        <EmbedIcon icon={icon} />
        <Text noOfLines={1} flex={1} data-testid="site-name" fontSize="0.9em">
          {site}
        </Text>
        {provider.downloadable && sourceUrl && (
          <GammaTooltip label={<Trans>Download</Trans>} placement="top">
            <IconButton
              aria-label={t`Download`}
              icon={<FontAwesomeIcon icon={regular('download')} />}
              onClick={() => {
                // In Chrome this still just opens in new tab, but in other browsers it will actually download
                const link = document.createElement('a')
                link.href = sourceUrl
                link.target = '_blank'
                link.download = meta?.title || 'file.pdf'
                link.dispatchEvent(new MouseEvent('click'))
              }}
              variant="unstyled"
              sx={ClickableButtonSx}
              fontSize="0.7em"
              zIndex={2}
              onMouseDown={preventDefaultToAvoidBlur}
            />
          </GammaTooltip>
        )}
        {!disableOpenButton && sourceUrl && (
          <GammaTooltip label={<Trans>Open in new tab</Trans>} placement="top">
            <IconButton
              aria-label={t`Open in new tab`}
              icon={<FontAwesomeIcon icon={regular('external-link')} />}
              onClick={() => {
                window.open(sourceUrl, '_blank')
              }}
              variant="unstyled"
              zIndex={2}
              fontSize="0.7em"
              onMouseDown={preventDefaultToAvoidBlur}
              sx={ClickableButtonSx}
            />
          </GammaTooltip>
        )}
      </HStack>
      <Text
        fontWeight="bold"
        noOfLines={1}
        maxW="90%"
        data-testid="title"
        color="var(--body-color)"
      >
        {meta?.title}
      </Text>
      {meta?.description && (
        <Text
          noOfLines={[2, 3]}
          whiteSpace="normal"
          maxW="100%"
          color="var(--body-color-muted)"
        >
          {meta.description}
        </Text>
      )}
    </VStack>
  )
}

export const EmbedIcon = ({ icon, ...props }) => {
  return icon ? (
    <Image src={icon} h="1.5em" borderRadius="sm" {...props} />
  ) : (
    <Text as="span" {...props}>
      <FontAwesomeIcon icon={regular('browser')} />
    </Text>
  )
}
