import {
  MarkRange,
  NodeRange,
  getMarkType,
  isTextSelection,
} from '@tiptap/core'
import groupBy from 'lodash/groupBy'
import { Node } from 'prosemirror-model'
import {
  Plugin,
  Selection,
  Transaction,
  EditorState,
  PluginKey,
} from 'prosemirror-state'
import { AddMarkStep, RemoveMarkStep } from 'prosemirror-transform'

import {
  generateFootnoteId,
  getExpandedFootnoteId,
  isFootnoteSelected,
  setFootnoteExpanded,
} from './FootnoteState'
import { INNER_EDITOR_META_KEY } from './InnerEditorNodeView'

const closeFootnotesWhenMenusOpen = (selection: Selection) => {
  // Ignore selection changes on the inner editor
  if (selection.$anchor.doc.type.name === 'footnote') return

  // If theres a non-empty selection, close open footnotes to avoid overlapping text formatting menu
  if (
    !selection.empty &&
    !isFootnoteSelected(selection) &&
    isTextSelection(selection) &&
    getExpandedFootnoteId() // Only if there's already a footnote open, since setFootnoteExpanded has side effects
  ) {
    setFootnoteExpanded(null, false)
  }
}

type NodeRangeWithParent = NodeRange & { parent: Node | null }
type MarkRangeWithParent = MarkRange & { parent: Node | null }

const fixMismatchedIds = (
  transactions: readonly Transaction[],
  _oldState: EditorState,
  newState: EditorState,
  newTr: Transaction
): void => {
  if (!transactions.some((tr) => tr.docChanged)) return

  const { doc } = newState
  const footnoteNodes: NodeRangeWithParent[] = []
  const footnoteMarks: MarkRangeWithParent[] = []
  // Find all the footnote nodes and marks in the memo
  // Based on https://github.com/ueberdosis/tiptap/blob/main/packages/core/src/helpers/getMarksBetween.ts
  doc.nodesBetween(0, newState.doc.content.size, (node, pos, parent) => {
    if (node.type.name === 'footnote') {
      footnoteNodes.push({
        from: pos,
        to: pos + node.nodeSize,
        node,
        parent,
      })
    }
    node.marks.forEach((mark) => {
      if (mark.type.name === 'footnoteLabel') {
        footnoteMarks.push({
          from: pos,
          to: pos + node.nodeSize,
          mark,
          parent,
        })
      }
    })
  })

  // If a mark ID refers to a node that no longer exists, or a node in a different block, remove it
  const orphanedMarks = footnoteMarks.filter(
    ({ mark, parent: markParent }) =>
      !footnoteNodes.find(
        ({ node, parent: nodeParent }) =>
          node.attrs.noteId === mark.attrs.noteId && nodeParent === markParent
      )
  )
  orphanedMarks.forEach(({ from, to, mark }) =>
    newTr.removeMark(from, to, mark)
  )

  const createFootnoteMark = (id: string) =>
    getMarkType('footnoteLabel', newState.schema).create({ noteId: id })

  // If a mark has no ID, see if there's markless node to pair it with
  // This can happen when copy pasting a mark
  footnoteMarks
    .filter(({ mark }) => !mark.attrs.noteId)
    .forEach(({ mark, from, to }) => {
      // Find the first footnote node that doesn't have a mark
      const unpairedNode = footnoteNodes.find(({ node, from: nodePos }) => {
        const isAfterMark = nodePos >= to
        const isUnpaired = !footnoteMarks.find(
          ({ mark: pairedMark }) => pairedMark.attrs.noteId == node.attrs.noteId
        )
        return isAfterMark && isUnpaired
      })
      if (unpairedNode) {
        newTr
          .removeMark(from, to, mark)
          .addMark(from, to, createFootnoteMark(unpairedNode.node.attrs.noteId))
      }
    })

  // If multiple nodes have the same ID, give each dupe node/mark pair a new ID
  // This can happen when duplicating a card
  const nodesById = groupBy(footnoteNodes, ({ node }) => node.attrs.noteId)
  Object.entries(nodesById).forEach(([id, nodes]) => {
    if (nodes.length == 1 || !id) return
    const marks = footnoteMarks.filter(({ mark }) => mark.attrs.noteId === id)

    nodes.slice(1).forEach(({ from }, index) => {
      const newId = generateFootnoteId()
      newTr.setNodeMarkup(from, undefined, { noteId: newId })
      // If there's a matched mark, update it too
      const matchedMark = marks[index + 1] // Because we're skipping the first node
      if (matchedMark) {
        newTr.removeMark(matchedMark.from, matchedMark.to, matchedMark.mark)
        newTr.addMark(
          matchedMark.from,
          matchedMark.to,
          createFootnoteMark(newId)
        )
      }
    })
  })
}

const preventLeakyMarks = (
  transactions: readonly Transaction[],
  _oldState: EditorState,
  newState: EditorState,
  newTr: Transaction
) => {
  transactions.forEach((tr) => {
    const changeMarkSteps = tr.steps.filter(
      (s) => s instanceof AddMarkStep || s instanceof RemoveMarkStep
    ) as (AddMarkStep | RemoveMarkStep)[]
    if (
      changeMarkSteps.length == 0 ||
      tr.getMeta(INNER_EDITOR_META_KEY) || // Allow marks from inside the footnote editor
      tr.getMeta('appendedTransaction') // Prevents a crash with Tiptap autolink triggering preventLeakyMarks. We want to avoid this ever triggering in an infinite loop.
    )
      return

    // Find any marks from outside the footnote editor that added marks inside a footnote
    changeMarkSteps.forEach((step) => {
      const { from, to, mark } = step
      newState.doc.nodesBetween(from, to, (node, pos) => {
        if (node.type.name === 'footnote' && pos < from) {
          // Reverse the marks in our appended transaction
          // This is safe even if the footnote contains the same mark
          // (for example, reversing a bold mark applied across a note that also
          // contained bolded text inside) because it only removes this exact mark
          // (by referential equality), not all marks of this type
          // https://prosemirror.net/docs/ref/#transform.Transform.removeMark
          if (step instanceof AddMarkStep) {
            newTr.removeMark(from, to, mark)
          } else if (step instanceof RemoveMarkStep) {
            newTr.addMark(from, to, mark)
          }
        }
      })
    })
  })
}

export const FootnotePlugin = new Plugin({
  key: new PluginKey('footnote'),
  appendTransaction: (transactions, oldState, newState) => {
    const { selection } = newState
    // On selection change, check if we should close the formatting menu
    if (!selection.eq(oldState.selection))
      closeFootnotesWhenMenusOpen(selection)

    const newTr = newState.tr

    // Prevent marks that go around a footnote from leaking inside of it
    preventLeakyMarks(transactions, oldState, newState, newTr)

    // Fix marks that aren't connected to a footnote node,
    // or duplicates of the same ID
    fixMismatchedIds(transactions, oldState, newState, newTr)

    if (newTr.docChanged) {
      console.debug('[FootnotePlugin] Applied steps', newTr.steps)
      return newTr
    } else {
      return null
    }
  },
})
