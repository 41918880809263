/**
 * Styles that are applied to the doc editor root
 */
import { SystemStyleObject } from '@chakra-ui/system'

import { AutocompleteStyles } from '../extensions/AI/Autocomplete/AutocompleteStyles'
import { SalEditorStyles } from '../extensions/AI/Sal/salEditorStyles'
import { cardNotesEditorStyles } from '../extensions/Card/CardNotes/cardNotesEditorStyles'
import { cursorStyles } from './cursorStyles'
import { spotlightStyles } from './spotlightStyles'

export const editorStyles: SystemStyleObject = {
  '.ProseMirror': {
    '&.spotlight-active': spotlightStyles,

    ...SalEditorStyles,
    ...cardNotesEditorStyles,
    ...cursorStyles,
    ...AutocompleteStyles,

    // PresentMode
    '.card-present-mode': {
      '&--doc': {
        transform: 'translateY(0)',
        position: 'relative',
        zIndex: 1,
      },
      '&--presentCollapsed': {
        transform: 'translateY(0)',
        position: 'relative',
        zIndex: 1,
      },
      '&--presentPrev': {
        transform: 'translateY(-100%)',
        position: 'fixed',
        zIndex: 2,
      },
      '&--presentCurrent': {
        transform: 'translateY(0)',
        position: 'fixed',
        zIndex: 3,
      },
      '&--presentNext': {
        transform: 'translateY(100%)',
        position: 'fixed',
        zIndex: 2,
      },
      '&--presentParent': {
        transform: 'translateY(0)',
        position: 'fixed',
        zIndex: 2,
      },
    },

    // Card hover
    '.document-content.is-doc-mode': {
      '.card-wrapper': {
        '&:hover': {
          '.card-divider': {
            opacity: 1,
          },
          '.manage-card-controls': {
            opacity: 1,
          },
        },
      },
    },
    '.document-content': {
      '.card-wrapper': {
        '&:hover': {
          '.card-footer-reactions': {
            opacity: 1,
          },
          '.card-collapsed': {
            '.card-footer-reactions': {
              display: 'none',
            },
          },
        },
      },
    },
  },

  '.is-selecting-node, .sal-selection-node, .sal-selection-card': {
    // Use a pseudo element to avoid clashing with existing shadows
    '[data-selection-ring]': {
      _before: {
        // Go under other UI elements like container drag handle
        content: '""',
        position: 'absolute',
        inset: 0,
        borderRadius: 'inherit',
        outline: '2px solid',
        outlineColor: 'trueblue.300',
        pointerEvents: 'none',
        zIndex: 1,
      },
    },
    // Render the ring over the content, instead of just outside it
    // Needed for blocks like images that rely on overflow: hidden
    '[data-selection-ring="inside"]': {
      _before: {
        // NB: if you increase this value, also update the z-index for the Filmstrip
        zIndex: 10,
        outlineOffset: '-2px',
      },
    },

    // Render the ring offset from the content
    // Use for text blocks where the outline would otherwise touch text
    '[data-selection-ring="padded"]': {
      _before: {
        outlineOffset: '0.25em',
      },
    },

    // Prevent it from cascading to inner elements
    '.react-renderer [data-selection-ring]': {
      _before: {
        content: 'none',
      },
    },
  },

  '.is-selecting-around': {
    '[data-selection-background]': {
      _after: {
        // Go over other elements
        content: '""',
        position: 'absolute',
        inset: 0,
        // Match the selection color on highlighted text
        backgroundColor: 'var(--chakra-ring-color)',
        borderRadius: 'inherit',
        opacity: 0.75,
      },
    },
    // Prevent it from cascading to inner elements
    '.react-renderer:not(.is-selecting-around) [data-selection-ring]': {
      _after: {
        content: 'none',
      },
    },
  },

  '.ProseMirror-dropcursor': {
    transitionProperty: 'inset',
    transitionDuration: 'fast',
    borderRadius: 'md',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    outline: '1px solid',
    outlineColor: 'whiteAlpha.800',
    pointerEvents: 'none',
    backdropFilter: 'blur(4px)',

    // Modeled after tooltips
    '&::after': {
      backgroundColor: 'gray.700',
      color: 'whiteAlpha.900',
      px: '8px',
      py: '2px',
      borderRadius: 'sm',
      fontWeight: 'medium',
      shadow: 'md',
      fontSize: 'sm',
      w: 'max-content',
      pointerEvents: 'none',
      display: 'none',
    },

    '&[data-type="column"]::after': {
      content: '"Drop to add column"',
      position: 'absolute',
      top: '-2em',
      display: 'block',
    },
  },
}
