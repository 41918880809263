import {
  Badge,
  Button,
  ButtonGroup,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Trans } from '@lingui/macro'
import { Editor } from '@tiptap/core'
import { useCallback, useRef, useState } from 'react'

import { config } from 'config'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { ExportButton } from 'modules/sharing/components/ExportButton'
import {
  EventBusEvent,
  TiptapEventBus as tiptapEventEmitter,
} from 'modules/tiptap_editor/eventBus'

type ImageType = 'png' | 'jpeg'

export const ExportCardModal = ({
  isOpen,
  onClose,
  cardId,
  editor,
}: {
  isOpen: boolean
  onClose: () => void
  cardId: string
  editor: Editor
}) => {
  const analytics = useAnalytics()
  const [isDownloadingPNG, setIsDownloadingPNG] = useState<boolean>(false)
  const [isDownloadingJPG, setIsDownloadingJPG] = useState<boolean>(false)
  const toast = useToast()
  const toastId = useRef<string | null>(null)

  const showGeneratingToast = useCallback(() => {
    if (toastId.current) {
      toast.update(toastId.current, {
        status: 'success',
        title: <Trans>Your card export is ready</Trans>,
        description: (
          <Text>
            <Trans>A download should start automatically.</Trans>
          </Text>
        ),
        duration: 3000,
        isClosable: true,
      })
    }
  }, [toast])

  const showErrorToast = useCallback(() => {
    if (toastId.current) {
      toast.update(toastId.current, {
        title: <Trans>Error exporting card.</Trans>,
        status: 'error',
        duration: null,
        isClosable: true,
      })
    }
  }, [toast])

  const onDownloadClick = useCallback(
    async (imageType: ImageType) => {
      switch (imageType) {
        case 'png':
          setIsDownloadingPNG(true)
          break
        case 'jpeg':
          setIsDownloadingJPG(true)
      }

      const docJson = editor.getJSON()
      const imageDownloadEndpoint = `${config.API_HOST}/export/cards/${cardId}/image`
      // @ts-ignore
      toastId.current = toast({
        id: `export-toast-${cardId}-${imageType}`,
        isClosable: false,
        position: 'top',
        duration: null,
        status: 'loading',
        variant: 'light',
        title: <Trans>Exporting card...</Trans>,
      })
      try {
        const response = await fetch(imageDownloadEndpoint, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            docContent: docJson,
            fileType: imageType,
          }),
        })
        const blob = await response.blob()
        if (!response.ok) {
          throw new Error('Export failed.')
        }
        const blobUrl = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        const fileName = decodeURIComponent(
          (response.headers.get('Content-Disposition') || '')
            .split(';')
            .find((n) => n.includes('filename='))
            ?.replace('filename=', '')
            .trim() || `${cardId}.${imageType}`
        )
        a.download = fileName
        a.href = blobUrl
        document.body.appendChild(a)
        a.click()
        a.remove()
        analytics?.track(SegmentEvents.CARD_EXPORTED, {
          cardId,
          format: 'image',
          fileType: imageType,
        })
        // Min delay so loading state doesn't flash on/off
        setTimeout(() => {
          switch (imageType) {
            case 'png':
              setIsDownloadingPNG(false)
              break
            case 'jpeg':
              setIsDownloadingJPG(false)
          }
          showGeneratingToast()
        }, 300)
      } catch (e) {
        showErrorToast()
      } finally {
        setIsDownloadingPNG(false)
        setIsDownloadingJPG(false)
      }
    },
    [analytics, cardId, editor, showErrorToast, showGeneratingToast, toast]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Trans>Export card</Trans>
          <Badge colorScheme="green" fontSize="lg" ml={2}>
            <Trans>Beta</Trans>
          </Badge>
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody pt="6">
          <VStack spacing={4} align="start">
            <Stack spacing={2} w="100%">
              <ExportButton
                onClick={() => onDownloadClick('png')}
                exportInProgress={isDownloadingPNG}
                exportInProgressNote={
                  <Trans>It's safe to close this panel.</Trans>
                }
                accentColor={'purple'}
                icon={regular('image')}
                formatLabel={'PNG'}
              />
              {/* <ExportButton
              onClick={() => onDownloadClick('jpeg')}
              exportInProgress={isDownloadingJPG}
              accentColor={'pink'}
              icon={regular('image')}
              formatLabel={'JPG'}
            /> */}
            </Stack>
            <Text fontSize="sm" color="gray.600">
              <Trans>
                Tip: you can control card sizing and backdrops in{' '}
                <Button
                  variant="link"
                  as="span"
                  size="sm"
                  cursor="pointer"
                  onClick={() => {
                    onClose()
                    tiptapEventEmitter.emit(
                      EventBusEvent.OPEN_PAGE_SETUP,
                      'cards'
                    )
                  }}
                >
                  page setup
                </Button>
                .
              </Trans>
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              onClick={() => {
                onClose()
              }}
            >
              <Trans>Done</Trans>
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
