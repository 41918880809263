import { findParentNodeClosestToPos, NodeWithPos } from '@tiptap/core'
import { Node, ResolvedPos, Schema, Slice } from 'prosemirror-model'
import { EditorView } from 'prosemirror-view'

import { ImageAttrs } from 'modules/media/types/Image'
import { findNodeAndParents } from 'modules/tiptap_editor/utils'
import { findInBetween } from 'utils/dom'

import { sliceSingleNode } from '../Clipboard/defaultHandlePaste'
import {
  isSmartLayoutCellNode,
  isSmartLayoutNode,
} from './isSmartLayoutCellNode'
import { SmartLayoutAttrs } from './types'
import { getSmartLayoutVariant } from './variants'

export const getSmartLayoutOptions = (attrs: SmartLayoutAttrs) => {
  const variant = getSmartLayoutVariant(attrs.variantKey)
  const options: Record<string, any> = {}
  variant.options.forEach(({ key, defaultValue }) => {
    options[key] = attrs.options[key] ?? defaultValue
  })
  return options
}

export const getSmartLayoutDirection = (attrs: SmartLayoutAttrs) => {
  const variant = getSmartLayoutVariant(attrs.variantKey)
  const options = getSmartLayoutOptions(attrs)
  return variant.addDirection?.(options) || 'right'
}

export const canSmartLayoutContainSlice = (slice: Slice, schema: Schema) =>
  schema.nodes.smartLayout.validContent(slice.content)

export type SmartLayoutDropTarget = {
  pos: number
  node: Node
  side: 'right' | 'left' | 'top' | 'bottom'
  rect: DOMRect
}

/**
 * Checks drop targets
 */
export const checkSmartLayoutImageDropTarget = (
  view: EditorView,
  event: DragEvent,
  slice?: Slice
) => {
  const coordPos = view.posAtCoords({
    left: event.clientX,
    top: event.clientY,
  })
  if (!coordPos) {
    return null
  }
  const $inside = view.state.doc.resolve(coordPos.inside)
  const smartLayoutCell = findNodeAndParents($inside, isSmartLayoutCellNode)[0]
  const smartLayoutNode = findNodeAndParents($inside, isSmartLayoutNode)[0]
  const node = slice && sliceSingleNode(slice)

  if ((!smartLayoutCell && !smartLayoutNode) || !node) {
    return null
  }

  if (node.type.name !== 'image') {
    // can only drop an image
    return null
  }

  const found = findInBetween(event.target as HTMLElement, view.dom, (e) =>
    e.hasAttribute('data-smart-layout-image-drop-target')
  )
  if (found) {
    const { src, source, query, name, uploadStatus } = node.attrs as ImageAttrs
    const targetRect = found.getBoundingClientRect()
    return {
      pos: coordPos.inside,
      node,
      rect: targetRect,
      image: {
        src,
        source,
        query,
        name,
        uploadStatus,
      },
    }
  }

  return null
}

export const checkSmartLayoutDropTarget = (
  view: EditorView,
  event: DragEvent,
  slice?: Slice
): SmartLayoutDropTarget | null => {
  if (!slice || !canSmartLayoutContainSlice(slice, view.state.schema)) {
    return null
  }
  const pos = view.posAtCoords({
    left: event.clientX,
    top: event.clientY,
  })
  if (!pos || pos.inside == -1) return null

  const { doc } = view.state
  let target: NodeWithPos | undefined
  const posNode = doc.nodeAt(pos.pos)
  const insideNode = doc.nodeAt(pos.inside)
  if (insideNode && isSmartLayoutCellNode(insideNode)) {
    // Hover directly on a cell
    target = { node: insideNode, pos: pos.inside }
  } else if (posNode && isSmartLayoutCellNode(posNode)) {
    // Hover between two cells
    target = { node: posNode, pos: pos.pos }
  } else {
    // Hover within the children of a cell
    const $pos = doc.resolve(pos.inside)
    target = findParentNodeClosestToPos($pos, isSmartLayoutCellNode)
  }

  const dom = target && (view.nodeDOM(target.pos) as HTMLElement | null)
  const box = dom?.querySelector('[data-content-reference]')
  const targetRect = box?.getBoundingClientRect()

  if (!target || !targetRect) {
    return null
  }

  const $cellPos = doc.resolve(target.pos)
  const layout = $cellPos.parent
  if (!layout || !isSmartLayoutNode(layout)) {
    return null
  }
  const direction = getSmartLayoutDirection(layout.attrs)
  const side =
    direction === 'right'
      ? event.clientX > targetRect.left + targetRect.width / 2
        ? 'right'
        : 'left'
      : event.clientY > targetRect.top + targetRect.height / 2
      ? 'bottom'
      : 'top'
  return {
    pos: target.pos,
    node: target.node,
    rect: targetRect,
    side,
  }
}

export const selectParentLayout = (editor, $pos: ResolvedPos) => {
  const parentLayout = findParentNodeClosestToPos($pos, isSmartLayoutNode)
  if (!parentLayout) return
  editor.commands.selectNodeAtPos(parentLayout.pos)
}
