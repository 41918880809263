import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'

import { selectPreviewEnabled } from 'modules/preview/reducer'
import { useAppSelector } from 'modules/redux'
import { selectDoc } from 'modules/tiptap_editor/reducer'
import { getRelativeOrAbsoluteUrl, isLinkToCurrentPage } from 'utils/publishing'

import { useShouldUsePublishedVersion } from '../PublishingContext'
import { urlMatchesSiteManagerPreviewRoute } from '../utils'

/**
 * This hook handles converting the URL to a relative one if we're in SSR mode.
 * It also provides a click handler for SSR preview mode.
 */
export const useSiteEnabledLink = ({
  url,
  nonPublishedUrl,
}: {
  url: string
  nonPublishedUrl?: string
}) => {
  const { push } = useRouter()
  const doc = useAppSelector(selectDoc)
  const shouldUsePublishedVersion = useShouldUsePublishedVersion()
  const previewEnabled = useAppSelector(selectPreviewEnabled)
  const linkUrl = shouldUsePublishedVersion
    ? getRelativeOrAbsoluteUrl({ url, doc, previewEnabled })
    : nonPublishedUrl || url

  const isCurrentPage = isLinkToCurrentPage(url, doc)

  const handleClick = useCallback(
    (ev: React.MouseEvent) => {
      if (urlMatchesSiteManagerPreviewRoute(linkUrl)) {
        ev.preventDefault()
        push(linkUrl)
      }
    },
    [linkUrl, push]
  )

  return useMemo(() => {
    return {
      linkUrl,
      handleClick: previewEnabled ? handleClick : undefined,
      isCurrentPage,
    }
  }, [handleClick, linkUrl, previewEnabled, isCurrentPage])
}
