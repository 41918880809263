import {
  HStack,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useCallback } from 'react'

import { DEFAULT_THUMB_HEIGHT_EM } from '../../../extensions/media/Gallery/constants'
import { DeleteNodeButton } from '../buttons/DeleteNodeButton'
import { FormattingMenuDivider } from './FormattingMenuDivider'
import { NodeFormattingMenuProps } from './types'

export const GalleryFormattingMenu = ({
  selection,
  editor,
}: NodeFormattingMenuProps) => {
  const isEmpty = selection.node.childCount === 0
  const thumbSize = selection.node.attrs.thumbHeight || DEFAULT_THUMB_HEIGHT_EM
  const setThumbSize = useCallback(
    (size: number) => {
      editor
        .chain()
        .updateAttributes('gallery', {
          thumbHeight: size,
        })
        // For some reason, changing the attributes shifts the selection inside
        // This prevents it and keeps the selection on the gallery
        .setNodeSelection(selection.from)
        .focus()
        .run()
    },
    [editor, selection.from]
  )

  return (
    <HStack spacing={2} mr={2}>
      {!isEmpty && (
        <>
          <Text fontSize="sm" fontWeight="600">
            <Trans>Size</Trans>
          </Text>
          <Slider
            aria-label="gallery-slider"
            min={5}
            max={15}
            step={0.25}
            value={thumbSize}
            onChange={setThumbSize}
            w="120px"
            focusThumbOnChange={false}
            colorScheme="trueblue"
            size="sm"
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb background="trueblue.500" />
          </Slider>
          <FormattingMenuDivider />
        </>
      )}
      {/* <FullWidthButton editor={editor} selection={selection} /> */}
      <DeleteNodeButton editor={editor} />
    </HStack>
  )
}
