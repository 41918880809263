import {
  findParentNode,
  findParentNodeClosestToPos,
  mergeAttributes,
  Node,
} from '@tiptap/core'
import { ParseRule } from 'prosemirror-model'

import { ignoreDataMutation } from 'modules/tiptap_editor/utils/ignoreMutation'

import { ReactNodeViewRenderer } from '../../react'
import { configureJSONAttribute, isTreeEmpty } from '../../utils'
import { deleteLayoutCell } from '../Layout/commands'
import { attrsOrDecorationsChanged } from '../updateFns'
import { DefaultCellContent } from './constants'
import { SmartLayoutPlugin } from './SmartLayoutPlugin'
import { SmartLayoutView } from './SmartLayoutView'
import { SmartLayoutCellAttrs } from './types'
import { getSmartLayoutVariant, getSmartLayoutVariants } from './variants'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    smartLayout: {
      insertSmartLayoutCell: (pos: number) => ReturnType
      handleSmartLayoutDelete: (forward?: boolean) => ReturnType
    }
  }
}

export const SmartLayout = Node.create({
  name: 'smartLayout',
  group: 'cardBlock layoutBlock calloutBlock',
  content: 'smartLayoutCell+',
  isolating: true,
  containerHandle: true,
  defining: true,

  addAttributes() {
    return {
      variantKey: {
        // Mark this attr as required for FixRequiredAttrs extension
        // This will prevent ProseMirror from trying to intelligently create a smart layout
        // e.g. when dragging a smart layout cell out into a card
        default: undefined,
      },
      options: {
        default: {},
        ...configureJSONAttribute('options'),
      },
      fullWidthBlock: {
        default: false,
      },
    }
  },

  parseHTML() {
    const variantRules: ParseRule[] = Object.values(getSmartLayoutVariants())
      .filter((variant) => variant.htmlTag)
      .map((variant) => ({
        tag: variant.htmlTag,
        getAttrs: (el: HTMLElement) => {
          const attrs = {
            variantKey: variant.key,
            options: variant.defaultOptions || {},
          }
          variant.options.forEach((option) => {
            const value = el.getAttribute(option.key)
            if (value) {
              try {
                // Try to parse the value as JSON, if it's a number or object
                attrs.options[option.key] = JSON.parse(value)
              } catch (err) {
                // Strings will fail this, so just use the string value
              }
              return
            }

            if (option.getDefaultOnParse) {
              attrs.options[option.key] = option.getDefaultOnParse(el)
              return
            }
          })
          return attrs
        },
      }))

    return [
      {
        tag: 'div[class=smart-layout]',
      },
      ...variantRules,
    ]
  },

  renderHTMLforAI({ node }) {
    const variant = getSmartLayoutVariant(node.attrs.variantKey)
    const options = {}
    variant.options.forEach((option) => {
      // Include the default value so when we export HTML, it has the full set
      const val = node.attrs.options[option.key] ?? option.defaultValue
      // Stringify if needed
      options[option.key] = typeof val === 'string' ? val : JSON.stringify(val)
    })
    return [variant.htmlTag || variant.key, options, 0]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, { class: 'smart-layout' }),
      0,
    ]
  },

  addNodeView() {
    return ReactNodeViewRenderer(SmartLayoutView, {
      update: attrsOrDecorationsChanged,
      ignoreMutation: ignoreDataMutation,
    })
  },

  addProseMirrorPlugins() {
    return [SmartLayoutPlugin(this.editor)]
  },

  addCommands() {
    return {
      insertSmartLayoutCell:
        (pos) =>
        ({ chain, state }) => {
          const $pos = state.doc.resolve(pos)
          const parentLayout = findParentNodeClosestToPos(
            $pos,
            (n) => n.type.name === 'smartLayout'
          )
          if (!parentLayout || !parentLayout.node.lastChild) {
            return false
          }
          const lastCellAttrs = parentLayout.node.lastChild
            .attrs as SmartLayoutCellAttrs
          const variant = getSmartLayoutVariant(
            parentLayout.node.attrs.variantKey
          )
          const defaultContent = variant.defaultContent || DefaultCellContent
          chain()
            .insertContentAt(pos, {
              type: 'smartLayoutCell',
              content: defaultContent,
              attrs: {
                image: {
                  source: lastCellAttrs.image?.source,
                },
              },
            })
            .selectInsertedNode()
            .run()
          return true
        },
      // Not used anymore, but keeping around in case we want to restore this behavior later
      handleSmartLayoutDelete:
        (forward = true) =>
        ({ dispatch, state, tr, chain }) => {
          if (!dispatch) return true

          const parentCell = findParentNode(
            (n) => n.type.name === 'smartLayoutCell'
          )(state.selection)

          const parentLayout = findParentNode(
            (n) => n.type.name === 'smartLayout'
          )(state.selection)

          if (!parentCell || !parentLayout || !isTreeEmpty(parentCell.node))
            return false

          if (parentLayout.node.childCount == 1) {
            // If this is the last cell, unwrap the whole layout
            chain()
              .selectNodeAtPos(parentLayout.pos)
              .deleteSelectionAndSelectNear(forward ? 1 : -1)
              .run()
          } else {
            const $parentCell = tr.doc.resolve(parentCell.pos)
            deleteLayoutCell(tr, $parentCell, forward)
          }

          return true
        },
    }
  },
})
