import {
  Avatar,
  Box,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Spacer,
  Stack,
  Text,
  useClipboard,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import capitalize from 'lodash/capitalize'
import React, { MouseEvent } from 'react'

import { Comment, CommentStatus, User } from 'modules/api'
import { normalizeDate } from 'utils/date'

import { CommentStatusButton } from './CommentStatusButton'

type CommentHeaderProps = {
  isInPanel?: boolean
  comment: Comment
  selfUser: Comment['user']
  isSourceContentAvailable: boolean
  showCopyCommentLink: boolean
  showLinkToTargetContent: boolean
  hasCommentBeenUpdated: boolean
  onTargetClick?: (e: MouseEvent) => void
  commentUrl: string
  isReply: boolean
  userCanComment: boolean
  handleCommentStatusUpdate: (oldStatus: CommentStatus) => void
  toastPosition: 'top' | 'bottom'
  isEditing: boolean
  handleEditCommentClick: () => void
  handleDeleteCommentClick: () => void
}

export const CommentHeader: React.FC<CommentHeaderProps> = ({
  comment,
  selfUser,
  isInPanel,
  isSourceContentAvailable,
  showCopyCommentLink,
  showLinkToTargetContent,
  hasCommentBeenUpdated,
  onTargetClick,
  commentUrl,
  isReply,
  userCanComment,
  handleCommentStatusUpdate,
  toastPosition,
  isEditing,
  handleEditCommentClick,
  handleDeleteCommentClick,
}) => {
  const {
    user,
    createdTime,
    updatedTime,
    status = CommentStatus.Open,
  } = comment
  const { email, displayName, profileImageUrl } = user as User
  const isSelf = selfUser?.id === user?.id
  const { onCopy } = useClipboard(commentUrl)
  const deleteColor = useColorModeValue('red.500', 'red.300')
  const toast = useToast()
  return (
    <HStack
      spacing={2}
      align="top"
      mb={3}
      _before={
        isInPanel
          ? {
              content: `''`,
              position: 'absolute',
              w: '9px',
              h: '9px',
              mt: '15px',
              left: isReply ? '12px' : '36px',
              bg: 'gray.100',
              borderRadius: 'full',
            }
          : {}
      }
    >
      <GammaTooltip label={email} aria-label={email}>
        <Avatar
          mt={1}
          size="sm"
          name={displayName}
          src={profileImageUrl}
          ignoreFallback={true}
        />
      </GammaTooltip>
      <Stack align="center" alignItems="flex-start" spacing={0} mb={1}>
        <Text fontWeight="600" mb={0} fontSize="sm">
          {displayName} {isSelf ? <Trans>(You)</Trans> : null}
        </Text>
        <Flex direction="row" w="100%" alignItems="flex-start">
          <Box flex="1">
            <GammaTooltip
              isDisabled={!hasCommentBeenUpdated}
              label={`Updated ${normalizeDate(updatedTime)}`}
              placement="top"
            >
              <HStack>
                <Text
                  my={'0 !important'}
                  className="comment-date"
                  fontSize="xs"
                  color="gray.500"
                >
                  {capitalize(normalizeDate(createdTime))}
                </Text>
                {hasCommentBeenUpdated && (
                  <Box color="gray.500">
                    <FontAwesomeIcon size="xs" icon={regular('square-pen')} />
                  </Box>
                )}
              </HStack>
            </GammaTooltip>
          </Box>
        </Flex>
      </Stack>
      <Spacer />
      <HStack spacing={1}>
        {showLinkToTargetContent && (
          <GammaTooltip
            label={
              isSourceContentAvailable ? (
                <Trans>Go to</Trans>
              ) : (
                <Trans>Source content no longer exists</Trans>
              )
            }
            shouldWrapChildren // https://github.com/chakra-ui/chakra-ui/issues/500#issuecomment-916782006
          >
            <IconButton
              size="sm"
              borderRadius="full"
              variant={'ghost'}
              isDisabled={!isSourceContentAvailable}
              aria-label={
                isSourceContentAvailable
                  ? t`Go to`
                  : t`Source content no longer exists`
              }
              onClick={onTargetClick}
              icon={<FontAwesomeIcon icon={regular('arrow-circle-right')} />}
            />
          </GammaTooltip>
        )}

        {!isReply && userCanComment && (
          <CommentStatusButton
            status={status}
            handleCommentStatusUpdate={() =>
              handleCommentStatusUpdate(comment.status as CommentStatus)
            }
          />
        )}

        <Menu isLazy={true}>
          <MenuButton
            as={IconButton}
            size="sm"
            variant="ghost"
            borderRadius="full"
            data-testid="comment-header-menu"
            icon={<FontAwesomeIcon fixedWidth icon={regular('ellipsis-h')} />}
          >
            <Trans>Actions</Trans>
          </MenuButton>
          <Portal>
            <MenuList zIndex="popover" data-testid="comment-header-menulist">
              {showCopyCommentLink && (
                <MenuItem
                  icon={<FontAwesomeIcon icon={regular('link')} />}
                  onClick={() => {
                    onCopy()
                    toast({
                      title: t`URL copied.`,
                      status: 'success',
                      duration: 3000,
                      position: toastPosition,
                    })
                  }}
                >
                  <Trans>Copy comment link</Trans>
                </MenuItem>
              )}

              {userCanComment && !isEditing && isSelf && (
                <>
                  <MenuDivider />
                  <MenuItem
                    icon={<FontAwesomeIcon icon={regular('edit')} />}
                    onClick={handleEditCommentClick}
                  >
                    <Trans>Edit</Trans>
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={regular('trash')} />}
                    color={deleteColor}
                    onClick={() => {
                      handleDeleteCommentClick()
                    }}
                    data-testid="delete-thread"
                  >
                    {isReply ? (
                      <Trans>Delete reply</Trans>
                    ) : (
                      <Trans>Delete thread</Trans>
                    )}
                  </MenuItem>
                </>
              )}
            </MenuList>
          </Portal>
        </Menu>
      </HStack>
    </HStack>
  )
}
