import { Box } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/core'
import React from 'react'

import { config } from 'config'
import { useAppSelector } from 'modules/redux'
import { editorHasFocus } from 'modules/tiptap_editor/utils'

import { selectEditable } from '../../../reducer'
import { isFocusedAndEditable } from '../../selection/FocusedNodes'
import { MediaEmbedAttrs } from '../types'

type ZoomClickCaptureProps = {
  enterZoom: () => void
} & React.ComponentProps<typeof Box>

export const ZoomClickCapture = ({
  enterZoom,
  ...boxProps
}: ZoomClickCaptureProps) => {
  const isEditable = useAppSelector(selectEditable)
  const clickShouldZoom = !isEditable
  const doubleClickShouldZoom = isEditable
  if (config.GAMMA_PUPPETEER_SERVICE) {
    return null
  }
  return (
    <Box
      // Fill the parent and eat clicks
      inset={0}
      w="100%"
      h="100%"
      position="absolute"
      zIndex={1}
      cursor={clickShouldZoom ? 'zoom-in' : 'default'}
      onDoubleClick={doubleClickShouldZoom ? enterZoom : undefined}
      onClick={clickShouldZoom ? enterZoom : undefined}
      data-drag-handle
      data-testid="zoom-click-capture"
      // Should be invisible, but still get click events
      opacity={0}
      {...boxProps}
    />
  )
}

type ClickCaptureProps = {
  frameRef: React.RefObject<HTMLIFrameElement>
} & Pick<NodeViewProps, 'decorations' | 'node' | 'editor'>

export const ClickCapture = ({
  decorations,
  node,
  editor,
}: ClickCaptureProps) => {
  // Treat it as focused even if the editor is blurred,
  // which is the case when you're focused inside the iframe
  // Todo: use `frameRef` to confirm focus is in this particular iframe
  const isFocused = isFocusedAndEditable(decorations) || !editorHasFocus(editor)
  const isEditable = useAppSelector(selectEditable)
  const { displayStyle } = node.attrs as MediaEmbedAttrs
  const captureClicks = isEditable && !isFocused && displayStyle === 'inline'

  return (
    <Box
      position="absolute"
      inset={0}
      zIndex={1} // Capture clicks when the video is not selected and you're editing
      pointerEvents={captureClicks ? 'auto' : 'none'}
      cursor="default"
    />
  )
}
