import { useUpdateEffect } from '@chakra-ui/hooks'
import { Editor } from '@tiptap/core'
import throttle from 'lodash/throttle'
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  InitialMoveable,
  makeMoveable,
  Resizable,
  ResizableProps,
} from 'react-moveable'

import {
  CONTENT_WIDTH_PX,
  BASE_FONT_SIZE,
  MIN_WIDTH_OR_HEIGHT_PIXELS,
} from 'modules/tiptap_editor/extensions/media/constants'
import { useWindowResizing } from 'utils/hooks'
import { useContainerResizing } from 'utils/hooks/useContainerResizing'

import { ResizeAttrs } from './types'

const wrapperClassName = 'resizeable-control-wrapper'

const Moveable = makeMoveable<ResizableProps>([Resizable])

// Enforce both a min width and min height (based on the width)
const getMinWidth = (width: number, target: HTMLElement | SVGElement) => {
  const minWidthComputed =
    MIN_WIDTH_OR_HEIGHT_PIXELS *
    Math.max(target.clientWidth / target.clientHeight, 1)

  return Math.max(width, minWidthComputed)
}

export function useResizeable<T extends HTMLElement | null>(editor: Editor) {
  const [isAnimating, setAnimating] = useState(false)
  const isWindowResizing = useWindowResizing()
  const ref = useRef<T>(null)
  const isContainerResizing = useContainerResizing(ref)
  const [isResizing, setIsResizing] = useState(false) // If we are currently resizing

  const sx = {
    '.moveable-control': {
      // Force hide all controls while the window or animations are happening
      display:
        isWindowResizing || isContainerResizing || isAnimating ? 'none' : '',
    },
  }

  const onLayoutAnimationStart = useCallback(() => {
    editor.commands.forceHideBubbleMenu?.(true)
    setAnimating(true)
  }, [editor])

  const onLayoutAnimationComplete = useCallback(() => {
    editor.commands.forceHideBubbleMenu?.(false)
    setAnimating(false)
  }, [editor])

  useUpdateEffect(() => {
    // Hide the bubble menu while resizing
    editor.commands.forceHideBubbleMenu?.(isResizing || isContainerResizing)
  }, [editor, isResizing, isContainerResizing])

  return {
    ref,
    isResizing,
    setIsResizing,
    isAnimating,
    resizeableSx: sx,
    onLayoutAnimationStart,
    onLayoutAnimationComplete,
  }
}

const OFFSET_WIDTH = 3
const RESIZER_WIDTH_AND_HEIGHT = 8

/**
 * Styles for the controls added by the moveable library.
 * See https://github.com/daybrush/moveable/blob/master/handbook/handbook.md#toc-custom-css
 *
 * Some of these are just overrides for the default values the library uses, like z-index
 * See those defaults here: https://github.com/daybrush/moveable/blob/6a6bc858afc7edc90212fba8b46b7bdf1c572afd/packages/react-moveable/src/react-moveable/consts.ts#L35-L152
 */
export const ResizeableStyles = {
  [`.${wrapperClassName}`]: {
    zIndex: 11,
    '.moveable-control': {
      bg: 'white',
      border: '2px solid var(--chakra-colors-trueblue-300)',
      w: `${RESIZER_WIDTH_AND_HEIGHT}px`,
      h: `${RESIZER_WIDTH_AND_HEIGHT}px`,
      opacity: 1,
      zIndex: 10,
      borderRadius: 0,
      _hover: { opacity: 1 },
      transitionProperty: 'opacity',
      transitionDuration: 'normal',
      '&.moveable-ne, &.moveable-sw': {
        cursor: 'nesw-resize',
      },
      '&.moveable-nw, &.moveable-se': {
        cursor: 'nwse-resize',
      },

      '&.moveable-se, &.moveable-ne, &.moveable-e': {
        marginLeft: `-${RESIZER_WIDTH_AND_HEIGHT - OFFSET_WIDTH}px`,
      },
      '&.moveable-sw, &.moveable-nw, &.moveable-w': {
        marginLeft: `-${OFFSET_WIDTH}px`,
      },
      '&.moveable-ne, &.moveable-nw, &.moveable-n': {
        marginTop: `-${OFFSET_WIDTH}px`,
      },
      '&.moveable-se, &.moveable-sw, &.moveable-s': {
        marginTop: `-${RESIZER_WIDTH_AND_HEIGHT - OFFSET_WIDTH}px`,
      },
      '&.moveable-e, &.moveable-w': {
        cursor: 'ew-resize',
        marginTop: `-${RESIZER_WIDTH_AND_HEIGHT / 2}px`,
      },
      '&.moveable-n, &.moveable-s': {
        cursor: 'ns-resize',
        marginLeft: `-${RESIZER_WIDTH_AND_HEIGHT / 2}px`,
      },
    },
    '.moveable-line': {
      display: 'none',
    },
  },
}

type ResizableControlsProps = {
  updateResizeAttrs: (
    resizeAttrs: Partial<ResizeAttrs>,
    setFullWidth?: boolean
  ) => void
  imageWrapperRef: MutableRefObject<HTMLImageElement | HTMLDivElement | null>
  setIsResizing: (isResizing: boolean) => void
  refreshDeps: any[]
}

export const ResizableControls = ({
  imageWrapperRef,
  setIsResizing,
  updateResizeAttrs,
  refreshDeps,
}: ResizableControlsProps) => {
  const moveableInstance = useRef<InitialMoveable | null>(null)

  useEffect(() => {
    requestAnimationFrame(() => {
      moveableInstance.current?.updateRect()
      moveableInstance.current?.updateTarget()
    })
  }, [refreshDeps])

  // Refresh the resizable target on resize and drop
  useEffect(() => {
    const refresh = () => {
      moveableInstance.current?.updateTarget()
    }
    const refreshThrottled = throttle(refresh, 250)

    document.addEventListener('drop', refresh)
    window.addEventListener('resize', refreshThrottled)
    return () => {
      document.removeEventListener('drop', refresh)
      window.removeEventListener('resize', refreshThrottled)
    }
  }, [])

  return (
    <Moveable
      ref={(instance) => {
        moveableInstance.current = instance
      }}
      className={wrapperClassName}
      target={imageWrapperRef.current}
      renderDirections={['se', 'nw', 'sw', 'ne', 'n', 's', 'e', 'w']}
      keepRatio={true}
      draggable={false}
      resizable={true}
      origin={false}
      onResizeStart={() => {
        setIsResizing(true)
      }}
      onResize={({ target, width }) => {
        target.style.width = `${getMinWidth(width, target)}px`
      }}
      onResizeEnd={({ target }) => {
        // When we resize an image, we want to normalize the width independent of the font size,
        // which can change as a function of the screen size and mode.
        const newWidth = target.clientWidth
        const zoomFactor =
          parseFloat(getComputedStyle(target).fontSize) / BASE_FONT_SIZE
        const normalizedWidth = newWidth / zoomFactor

        updateResizeAttrs({
          width: normalizedWidth,
          isAuto: false,
        })
        setIsResizing(false)
        target!.style.width = ''
      }}
    />
  )
}
