import { mergeAttributes, Node } from '@tiptap/core'

import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'

import { attrsOrDecorationsChanged } from '../updateFns'
import { ContributorsThumbnailView, ContributorsView } from './ContributorsView'

export const Contributors = Node.create({
  name: 'contributors',
  group: 'block',
  atom: true,
  selectable: true,
  dynamic: true,

  parseHTML() {
    return [
      {
        tag: 'div[class=contributors]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { class: 'contributors' })]
  },

  addNodeView() {
    if (this.editor.isThumbnail) {
      return ReactNodeViewRenderer(ContributorsThumbnailView, {
        update: attrsOrDecorationsChanged,
      })
    }

    return ReactNodeViewRenderer(ContributorsView, {
      update: attrsOrDecorationsChanged,
    })
  },
})
