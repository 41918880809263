import { Box, Text } from '@chakra-ui/react'
import { GammaTooltip } from '@gamma-app/ui'
import { Trans } from '@lingui/macro'

const TOOLTIP_HOVER_HEIGHT = 20

export const CardHeightGuide = () => {
  return (
    <Box
      position="absolute"
      inset="0"
      pointerEvents="none"
      overflow="hidden"
      // The guide isn't accurate when the window is very narrow, so better to hide than mislead
      // future: consider text instead of wrapping it when you have fixed size on in doc mode
      display={{
        base: 'none',
        lg: 'block',
      }}
    >
      <Box
        position="absolute"
        top="var(--card-min-height)"
        left="0"
        w="100%"
        h="1px"
        borderBottom="2px dashed"
        borderBottomColor="var(--body-color)"
        // SVG gives us more control over dashes, but harder to customize the color
        // backgroundImage={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FFF' stroke-width='4' stroke-dasharray='3%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`}
        opacity="0.15"
      >
        <GuideHoverTarget left={0} />
        <GuideHoverTarget right={0} />
      </Box>
    </Box>
  )
}

const GuideHoverTarget = (boxProps) => {
  return (
    <GammaTooltip
      placement="top"
      w="250px"
      label={
        <Trans>
          <Text>
            Content below this line may be cut off when presenting or exporting
          </Text>
          <Text color="gray.500">You can change this in page setup</Text>
        </Trans>
      }
    >
      <Box
        position="absolute"
        w="var(--card-inner-padding-x)"
        h={`${TOOLTIP_HOVER_HEIGHT}px`}
        top={`-${TOOLTIP_HOVER_HEIGHT / 2}px`}
        pointerEvents="auto"
        cursor="help"
        {...boxProps}
      ></Box>
    </GammaTooltip>
  )
}
