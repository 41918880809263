import { ButtonGroup, HStack, IconButton, Kbd, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Trans } from '@lingui/macro'
import { NodeViewProps } from '@tiptap/core'

import { CreditCostIcon } from 'modules/credits/components/CreditCostIcon'
import { useFeatureFlag } from 'modules/featureFlags'
import { useLocalizedFunction } from 'modules/i18n/hooks/useLocalizedFunction'
import { NodeInsertMethods } from 'modules/segment'
import {
  checkCommandDisabled,
  CommandInfo,
  trackItemInserted,
} from 'modules/tiptap_editor/commands'
import { AUTOCOMPLETE_INPUT_SHORTCUT } from 'modules/tiptap_editor/extensions/AI/Autocomplete/constants'
import { isFootnoteEditor } from 'modules/tiptap_editor/extensions/Footnote/utils'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { CommandsList } from '../../../commands/commandsList'

const PROMPT_SHORTCUTS = [
  'image.custom',
  'table2',
  'columns',
  'insertCardInside',
  'embed.custom',
]

const getPromptCommands = () => {
  return PROMPT_SHORTCUTS.map((key) =>
    CommandsList.find((cmd) => cmd.key === key)
  ).filter(Boolean) as CommandInfo[]
}

// The placeholder that appears on a new line prompting you to add blocks
export const SlashMenuPrompt = ({ editor }: NodeViewProps) => {
  const canAutocomplete =
    useFeatureFlag('aiAutocomplete') && !isFootnoteEditor(editor)
  const PROMPT_COMMANDS = useLocalizedFunction(getPromptCommands)
  const { selection } = editor.state
  if (!selection.empty) return null

  return (
    <HStack
      spacing={4}
      color="gray.400"
      data-print-hidden
      position="absolute"
      inset="0"
      contentEditable={false}
      overflow="hidden"
      fontFamily="Inter, sans-serif"
      fontSize="0.8em"
      onMouseDown={preventDefaultToAvoidBlur}
      pointerEvents="none"
      userSelect="none"
      data-guider-highlight="slash-menu-prompt"
      className="slash-menu-prompt"
    >
      <Text whiteSpace="nowrap">
        {canAutocomplete ? (
          <Trans>
            Type <Kbd>/</Kbd> for blocks or{' '}
            <Kbd>{AUTOCOMPLETE_INPUT_SHORTCUT}</Kbd> to continue with AI
          </Trans>
        ) : (
          <Trans>
            Type <Kbd>/</Kbd> for blocks
          </Trans>
        )}
      </Text>
      <ButtonGroup
        isAttached
        variant="ghost"
        size="xs"
        spacing={1}
        pointerEvents="auto"
        colorScheme="gray"
        opacity="0.8"
        transitionProperty="opacity"
        transitionDuration="normal"
        _hover={{
          opacity: 1,
        }}
      >
        {PROMPT_COMMANDS.map((command) => {
          const { name, shortcut, icon, execute, key, creditType } = command
          if (checkCommandDisabled(editor, command)) return false
          return (
            <GammaTooltip
              placement="top"
              shortcut={shortcut}
              label={
                <>
                  {name()} {creditType && <CreditCostIcon type={creditType} />}
                </>
              }
              key={key}
            >
              <IconButton
                aria-label={name()}
                onClick={() => {
                  trackItemInserted(command, NodeInsertMethods.BLOCK_PROMPT)
                  execute(editor)
                }}
                icon={<FontAwesomeIcon icon={icon} />}
              />
            </GammaTooltip>
          )
        })}
      </ButtonGroup>
    </HStack>
  )
}
