import { findParentNode, Node } from '@tiptap/core'
import { Fragment, Node as ProsemirrorNode } from 'prosemirror-model'

import { configureIdAttribute } from 'modules/tiptap_editor/plugins/uniqueAttribute/configureIdAttribute'
import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'
import { fragmentToArray } from 'modules/tiptap_editor/utils'
import {
  findSelectionInsideNode,
  getInsertedNodePos,
} from 'modules/tiptap_editor/utils/selection/findSelectionInsideNode'
import { wrappingTransformInputRule } from 'modules/tiptap_editor/utils/wrappingTransformInputRule'

import {
  UnwrapNodeAnnotationEvent,
  WrapNodesAnnotationEvent,
} from '../Annotatable/AnnotationExtension/types'
import { ExtensionPriorityMap } from '../constants'
import { fontSizeFromNode, getFontSizeOption } from '../Font/utils'
import { attrsOrDecorationsChanged } from '../updateFns'
import { TogglePlugin } from './TogglePlugin'
import { ToggleSummary } from './ToggleSummary'
import { ToggleView } from './ToggleView'
import { generateToggleId, UniqueToggleId } from './UniqueToggleId'
import { isToggleNode, isToggleOpen, setToggleOpen } from './utils'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    toggle: {
      unwrapToggle: () => ReturnType
      wrapWithToggle: (summaryText?: string, open?: boolean) => ReturnType
      joinBackwardInToggle: () => ReturnType
      enterInToggle: () => ReturnType
    }
  }
}

const toggleRegex = /^\s*([+])\s$/

export const Toggle = Node.create({
  name: 'toggle',
  group: 'layoutBlock calloutBlock cardBlock smartLayoutBlock tableBlock',
  content: 'toggleSummary (block | layoutBlock)+',
  selectable: false,

  isolating: true, // Ensures we can gap cursor on the edges
  priority: ExtensionPriorityMap.Toggle,

  expandable: true,

  addNodeView() {
    return ReactNodeViewRenderer(ToggleView, {
      update: attrsOrDecorationsChanged,
    })
  },

  addProseMirrorPlugins() {
    return [TogglePlugin()]
  },

  addAttributes() {
    return {
      id: configureIdAttribute(generateToggleId),
    }
  },

  addInputRules() {
    return [
      wrappingTransformInputRule({
        find: toggleRegex,
        innerType: this.editor.schema.nodes.toggleSummary,
        outerType: this.type,
        getInnerAttributes: (node: ProsemirrorNode) => {
          const fontSize = fontSizeFromNode(node)
          return { fontSize }
        },
        getOuterAttributes: () => {
          const id = generateToggleId()
          setToggleOpen(id, true)
          return { id }
        },
      }),
    ]
  },

  addCommands() {
    return {
      unwrapToggle:
        () =>
        ({ state, editor, tr }) => {
          if (!editor.isActive('toggleSummary')) {
            return false
          }

          const { selection, schema } = state
          const toggle = findParentNode(isToggleNode)(selection)
          if (
            !toggle ||
            toggle.start + 1 !== selection.from || // Only run if your selection is at the start of the toggle summary (+1 from the toggle itself)
            !selection.empty
          ) {
            return false
          }

          // Convert the summary to regular text
          const content = fragmentToArray(toggle.node.content)
          const summary = content[0]
          const { type, level } = getFontSizeOption(summary.attrs.fontSize)
          const newNode =
            type === 'heading'
              ? schema.nodes.heading.create({ level }, summary.content)
              : schema.nodes.paragraph.create(
                  { fontSize: summary.attrs.fontSize },
                  summary.content
                )
          const newContent = [newNode].concat(content.slice(1))

          tr.replaceWith(
            toggle.pos,
            toggle.pos + toggle.node.nodeSize,
            Fragment.from(newContent)
          ).setMeta('annotationEvent', <UnwrapNodeAnnotationEvent>{
            type: 'unwrap-node',
            pos: toggle.pos,
          })
          const $pos = tr.doc.resolve(toggle.pos)
          const sel = findSelectionInsideNode($pos)
          if (sel) {
            tr.setSelection(sel)
          }
          return true
        },
      // this command fixes joinBackward which when done with only one content node in a toggle
      // breaks the schema definition of a toggle.  for joinBackwards to succeed we need to first
      // insert an empty paragraph and then joinBackward
      joinBackwardInToggle:
        () =>
        ({ state, editor, chain }) => {
          if (editor.isActive('toggleSummary')) {
            // must be in toggle and not in summary
            return false
          }
          const { selection } = state
          const toggle = findParentNode(isToggleNode)(selection)
          if (!toggle) {
            // must be in a toggle
            return false
          }

          // find direction children of toggle node
          const children = fragmentToArray(toggle.node.content)
          const content = children.slice(1)

          if (
            !selection.empty ||
            selection.$from.parentOffset !== 0 ||
            content.length !== 1
          ) {
            // must be at the start of the toggle and only have one child
            return false
          }

          chain()
            .command(({ tr }) => {
              // get position at end of toggle node's content
              const insertPos = tr.doc.resolve(toggle.pos + 1).end()
              // transaction to insert empty paragraph at end of $toggle node
              tr.insert(insertPos, editor.schema.nodes.paragraph.create())
              return true
            })
            .joinBackward()

          return true
        },
      wrapWithToggle:
        (summaryText, open = true) =>
        ({ state, tr }) => {
          const { doc, selection, schema } = state

          // Find all the blocks that the selection spans
          const blockRange = selection.$from.blockRange(selection.$to)
          if (!blockRange) return false
          const blocks: ProsemirrorNode[] = []
          doc.nodesBetween(
            selection.from,
            selection.to,
            (node, _pos, parent) => {
              if (parent === blockRange.parent) {
                blocks.push(node)
                return false
              }
              return
            }
          )

          // Decide which node to use as the summary, or make an empty one
          let summary: ProsemirrorNode
          let detailsInput: ProsemirrorNode[] = []
          // If the first block is a short piece of text, use that
          if (
            !summaryText &&
            blocks[0].isTextblock &&
            blocks[0].textContent.length < 100
          ) {
            const fontSize = fontSizeFromNode(blocks[0])
            summary = schema.nodes.toggleSummary.create(
              { fontSize },
              blocks[0].content
            )
            detailsInput = blocks.slice(1)
          } else {
            const summaryContent = summaryText ? schema.text(summaryText) : null
            summary = schema.nodes.toggleSummary.create(null, summaryContent)
            detailsInput = blocks
          }

          // Create a toggle
          const id = generateToggleId()
          if (open) {
            setToggleOpen(id, true)
          }
          const toggle = this.editor.schema.nodes.toggle.createAndFill(
            { id },
            Fragment.fromArray([summary, ...detailsInput])
          )
          if (!toggle) return false
          const { start, end } = blockRange
          tr.replaceRangeWith(start, end, toggle).setMeta('annotationEvent', <
            WrapNodesAnnotationEvent
          >{
            type: 'wrap-nodes',
            start,
            end,
            level: 1,
          })

          // Select at the end of the toggle
          const $pos = getInsertedNodePos(tr)
          if (!$pos) {
            return false
          }
          const sel = findSelectionInsideNode($pos, -1)
          if (sel) {
            tr.setSelection(sel)
          }
          return true
        },
      // Don't split summary blocks on enter if they're collapsed
      // because the content confusingly disappears
      enterInToggle:
        () =>
        ({ editor, state, chain }) => {
          if (!editor.isActive('toggle')) {
            return false
          }

          const toggle = findParentNode(isToggleNode)(state.selection)
          if (!toggle || isToggleOpen(toggle.node.attrs.id)) return false

          const { selection } = editor.state
          const { $from } = selection
          const isEndOfLine = $from.parentOffset === $from.parent.nodeSize - 2

          if (isEndOfLine) {
            // Add line after
            const afterParentPos = toggle.pos + toggle.node.nodeSize
            chain()
              .insertContentAt(afterParentPos, { type: 'paragraph' })
              .selectInsertedNode()
              .run()
            return true
          }

          setToggleOpen(toggle.node.attrs.id, true)
          return true
        },
    }
  },

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => editor.commands.enterInToggle(),
      Backspace: ({ editor }) =>
        editor.commands.first(({ commands }) => [
          () => commands.unwrapToggle(),
          () => commands.joinBackwardInToggle(),
        ]),
    }
  },

  addExtensions() {
    return [UniqueToggleId, ToggleSummary]
  },

  renderHTML({ HTMLAttributes }) {
    return ['details', HTMLAttributes, 0]
  },

  parseHTML() {
    return [
      {
        tag: 'details',
      },
    ]
  },
})
