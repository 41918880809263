import { ExtendedRegExpMatchArray } from '@tiptap/core'
import { NodeType } from 'prosemirror-model'

import { nodePasteRule } from 'modules/tiptap_editor/utils/nodePasteRule'
import { GAMMA_DOC_REGEX, getDocIdFromUrl, parseUrlHash } from 'utils/url'

type MatchResult = {
  index: number
  text: string
  match: RegExpMatchArray
}

type GetAttributesArgs = {
  match: ExtendedRegExpMatchArray
  docId?: string | null
  cardId?: string | null
}

type MentionPasteRuleArgs = {
  filterFn: (
    docId: string | null,
    cardId: string | null,
    r: MatchResult
  ) => boolean
  getAttributesFn: (args: GetAttributesArgs) => Record<string, any>
}

// Paste rule for capturing links to Gamma docs (see regex above)
export const getMentionPasteRule =
  ({ filterFn, getAttributesFn }: MentionPasteRuleArgs) =>
  (type: NodeType) =>
    nodePasteRule({
      find: (text: string) => {
        const matches = [...text.matchAll(GAMMA_DOC_REGEX)]

        if (!matches) {
          return []
        }

        const result = matches
          .map((regexpMatch) => {
            return {
              index: regexpMatch.index ?? -1,
              text: regexpMatch[0],
              match: regexpMatch,
            }
          })
          .filter((r) => {
            const resultDocId = getDocIdFromUrl(r.text)
            const { cardId } = parseUrlHash(r.text)
            return filterFn(resultDocId, cardId, r)
          })
        return result
      },
      type,
      getAttributes: (match) => {
        const id = getDocIdFromUrl(match[0])
        const { cardId } = parseUrlHash(match[0])
        return getAttributesFn({ match, docId: id, cardId })
      },
    })
