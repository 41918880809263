import { Box, Button } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t } from '@lingui/macro'
import { NodeViewProps } from '@tiptap/react'
import { round } from 'lodash'
import React, { memo, useCallback, useEffect } from 'react'

import { useAppSelector } from 'modules/redux'
import { CLICKABLE_BOX_CLASS } from 'modules/theming/styles/box'
import { getThemeBase } from 'modules/theming/themeBases'
import { useShouldRenderMobileVersion } from 'modules/tiptap_editor/hooks'
import { NodeViewContent } from 'modules/tiptap_editor/react'
import { selectTheme } from 'modules/tiptap_editor/reducer'
import { findSelectionInsideNode } from 'modules/tiptap_editor/utils/selection/findSelectionInsideNode'
import { preventDefaultToAvoidBlur } from 'utils/handlers'
import { useSSRMounted } from 'utils/hooks'

import { AnnotatableNodeViewWrapper } from '../../Annotatable'
import {
  findTableFocusDeco,
  findTableHoverDeco,
} from '../../block/BlockHoverPlugin'
import { ContainerDragHandle } from '../../DragDrop/ContainerDragHandle/ContainerDragHandle'
import { TableMap } from '../prosemirror-table'
import { canAddCol, createColumnWidths } from '../prosemirror-table/columnUtils'

const MAX_MOBILE_TABLE_WIDTH = '200vw'
type ColGroupProps = {
  cols: number
  colWidths: string[]
}
const ColGroup: React.FC<ColGroupProps> = ({ colWidths }) => {
  return (
    <colgroup>
      <col style={{ width: '0%' }}></col>
      {colWidths.map((width, ind) => (
        <col
          key={ind}
          className="col-width-control"
          style={{ width: `${width}%` }}
        />
      ))}
    </colgroup>
  )
}

ColGroup.displayName = 'ColGroup'

export const TableView = (nodeViewProps: NodeViewProps) => {
  const isMobileDevice = useShouldRenderMobileVersion()
  const { node, editor, getPos, decorations, updateAttributes } = nodeViewProps
  // the table name is hard coded in to avoid a circular dependency
  const { colMinPercent, newColSize } = editor.schema.nodes['table'].spec
  const canAddColEnd = canAddCol(
    node.attrs.colWidths,
    newColSize,
    colMinPercent
  )
  const tableMap = TableMap.get(node)
  const isFocused = decorations.some(
    (decoration) => decoration.spec.isFocusedInside
  )
  const selectedColumn = decorations.find(
    (decoration) => decoration.spec.isColumnSelected
  )?.spec.colNumber
  const { colWidths } = node.attrs
  const theme = useAppSelector(selectTheme)
  const themeBase = getThemeBase(theme)
  const ssrMounted = useSSRMounted()

  useEffect(() => {
    if (tableMap.width !== colWidths.length) {
      // has to be in a set timeout to prevent an infinite dispatch loop
      // with updateAttributes
      setTimeout(() => {
        updateAttributes({
          colWidths: createColumnWidths(tableMap.width),
        })
      }, 0)
    }
  }, [tableMap.width, colWidths, updateAttributes])

  const getColumnPosition = useCallback(
    (colNumber: number) => {
      // tableMap positions are relative to the inside of the table
      // https://github.com/ProseMirror/prosemirror-tables#class-tablemap
      // getPos() give us the spot right before table, +1 goes in
      const tableStart = getPos() + 1
      return tableStart + tableMap.positionAt(0, colNumber, node)
    },
    [getPos, node, tableMap]
  )

  const addColumnAfter = useCallback(
    (colNumber: number) => {
      const newColPos = getColumnPosition(colNumber + 1) + 1 // + 1 to put focus inside the <td>

      editor
        .chain()
        .addColumnAfter(colNumber) // Add after it
        .command(({ tr }) => {
          const sel = findSelectionInsideNode(tr.doc.resolve(newColPos))
          if (sel) {
            tr.setSelection(sel)
          }
          return true
        })
        .run()
    },
    [editor, getColumnPosition]
  )

  const addColumnEnd = useCallback(() => {
    const lastCol = tableMap.width - 1
    addColumnAfter(lastCol)
  }, [addColumnAfter, tableMap.width])

  const addRowEnd = () => {
    const tableStart = getPos() + 1
    const lastRow = tableMap.height - 1
    const lastCol = tableMap.width - 1

    const lastCellPos = tableStart + tableMap.positionAt(lastRow, lastCol, node)

    editor
      .chain()
      .focus(lastCellPos) // Select the last row
      .addRowAfter() // Add after it
      .run()

    requestAnimationFrame(() =>
      editor.chain().focus(lastCellPos).goToNextCell().run()
    )
  }

  const selectTable = () => {
    const firstColPos = getColumnPosition(0)
    editor.chain().focus().selectTable(firstColPos).run()
  }

  // TODO work with
  const width = round(
    node.attrs.colWidths.reduce((a, b) => a + b, 0),
    20
  )

  if (!ssrMounted) {
    // Our tables do not render symantic HTML, so avoid rendering them in the SSR context
    return null
  }

  return (
    <AnnotatableNodeViewWrapper as="div" {...nodeViewProps} {...node.attrs}>
      <Box
        w="fit-content"
        overflowX={isMobileDevice ? 'auto' : undefined}
        position="relative"
        width="100%"
        className={cx('table-wrapper')}
        data-selection-ring
        sx={themeBase.tableSx}
      >
        <table
          style={{
            width: isMobileDevice ? 'max-content' : `${width}%`,
            minWidth: isMobileDevice ? '100%' : undefined,
            // Set max width of tables, so you don't end up scrolling
            // all the way into oblivion
            maxWidth: isMobileDevice ? MAX_MOBILE_TABLE_WIDTH : undefined,
            tableLayout: isMobileDevice ? 'auto' : 'fixed',
            position: 'relative',
          }}
        >
          <ColGroup cols={tableMap.width} colWidths={colWidths} />
          <thead>
            <ColumnControls
              numColumns={tableMap.width}
              selectedColumn={selectedColumn}
              getColumnPosition={getColumnPosition}
              {...nodeViewProps}
            />
          </thead>
          <NodeViewContent
            as="tbody"
            className="table-content"
          ></NodeViewContent>
        </table>
        <ContainerDragHandle
          {...nodeViewProps}
          data-testid="select-table-button"
          onClick={selectTable}
          label={t`Select table`}
        />
        {/* Floating controls */}
        {isFocused && (
          <Box contentEditable={false} suppressContentEditableWarning>
            <GammaTooltip placement="right" label={t`Add column`}>
              <Button
                position="absolute"
                left="100%"
                top="0"
                height="100%"
                onClick={addColumnEnd}
                onMouseDown={preventDefaultToAvoidBlur}
                minWidth="0"
                width="auto"
                isDisabled={!canAddColEnd}
                zIndex={1}
                data-testid="add-col-end-button"
                size="xs"
                shadow="sm"
                variant="unstyled"
                color="var(--body-color)"
                className={CLICKABLE_BOX_CLASS}
                backgroundColor="var(--clickable-background-color)"
                ml={1}
                w={5}
              >
                <FontAwesomeIcon icon={regular('plus')} />
              </Button>
            </GammaTooltip>
            <GammaTooltip placement="bottom" label={t`Add row`}>
              <Button
                position="absolute"
                left="0"
                top="100%"
                width="100%"
                onClick={addRowEnd}
                onMouseDown={preventDefaultToAvoidBlur}
                minHeight="0"
                height={6}
                // go over ContainerDragHandle
                zIndex={4}
                data-testid="add-row-end-button"
                size="xs"
                shadow="sm"
                variant="unstyled"
                color="var(--body-color)"
                className={CLICKABLE_BOX_CLASS}
                mt={1}
              >
                <FontAwesomeIcon icon={regular('plus')} />
              </Button>
            </GammaTooltip>
          </Box>
        )}
      </Box>
    </AnnotatableNodeViewWrapper>
  )
}

type ColumnControlsProps = {
  numColumns: number
  getColumnPosition: (colNumber: number) => number
  selectedColumn: number | undefined
} & NodeViewProps

const ColumnControls = ({
  numColumns,
  selectedColumn,
  getColumnPosition,
  ...nodeViewProps
}: ColumnControlsProps) => {
  const { editor } = nodeViewProps
  const selectColumn = useCallback(
    (colNumber: number) => {
      const colPos = getColumnPosition(colNumber)
      editor.chain().focus().selectColumn(colPos).run()
    },
    [editor, getColumnPosition]
  )

  // prevent partially rendered column controls when streaming
  if (!numColumns) {
    return null
  }

  const cols = Array.from(Array(numColumns).keys())

  return (
    <tr contentEditable={false} suppressContentEditableWarning>
      <th style={{ padding: 0 }}></th>
      {cols.map((colNumber) => (
        <SelectColumnHeader
          key={colNumber}
          colNumber={colNumber}
          selectColumn={selectColumn}
          isSelected={colNumber === selectedColumn}
          {...nodeViewProps}
        />
      ))}
    </tr>
  )
}

type SelectColumnHeaderProps = {
  colNumber: number
  selectColumn: (colNumber: number) => void
  isSelected: boolean
} & NodeViewProps

const SelectColumnHeader = memo(
  ({ colNumber, selectColumn, ...nodeViewProps }: SelectColumnHeaderProps) => {
    const { decorations } = nodeViewProps
    const { colHover } = findTableHoverDeco(decorations)
    const { colFocus } = findTableFocusDeco(decorations)

    const handleClick = useCallback(
      () => selectColumn(colNumber),
      [colNumber, selectColumn]
    )
    return (
      <th
        key={colNumber}
        style={{ position: 'relative', padding: 0, border: 0 }}
        className="table-col-control"
      >
        <ContainerDragHandle
          {...nodeViewProps}
          handlePlacement="top"
          label={t`Select column`}
          zIndex="3"
          isVisible={colHover === colNumber || colFocus === colNumber}
          onClick={handleClick}
          data-test-column-control={colNumber}
        />
      </th>
    )
  }
)

SelectColumnHeader.displayName = 'SelectColumnHeader'
