import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'
import { Editor } from '@tiptap/core'

import { FeatureFlags } from 'modules/featureFlags'
import { TextFormattingCommand } from 'modules/tiptap_editor/components/menus/FormattingMenus/TextFormattingCommands'
import { TiptapEventBus } from 'modules/tiptap_editor/eventBus'

export interface RewriteCommand extends TextFormattingCommand {
  description?: () => string
  type: 'rephrase' | 'visualize' | 'reformat'
  apply?: (editor: Editor) => Promise<void> | void
  featureFlag?: keyof FeatureFlags
}

type RewriteType = {
  label: string
  featureFlag?: keyof FeatureFlags
}

export const getRewriteTypes = (): Record<string, RewriteType> => {
  return {
    rephrase: { label: t`Rephrase` },
    visualize: { label: t`Visualize` },
    reformat: { label: t`Reformat` },
  }
}

export const TextRewriteCommands: RewriteCommand[] = [
  {
    key: 'rephrase.better',
    type: 'rephrase',
    name: () => t`More engaging`,
    icon: regular('typewriter'),
    featureFlag: 'aiRewrite',
    description: () => t`Suggest a more compelling framing`,
    apply: () => {
      TiptapEventBus.emit('queueDesignPartnerTask', {
        message: t`Suggest a more engaging way to say this`,
      })
    },
  },
  {
    key: 'rephrase.expand',
    type: 'rephrase',
    name: () => t`Expand text`,
    icon: regular('up-down'),
    description: () =>
      t`Take a short summary or bullets and make it longer automatically`,
    apply: () => {
      TiptapEventBus.emit('queueDesignPartnerTask', {
        message: t`Please expand this into multiple paragraphs`,
      })
    },
    featureFlag: 'aiRewrite',
  },
  {
    key: 'rephrase.condense',
    type: 'rephrase',
    name: () => t`Condense text`,
    icon: regular('arrows-to-line'),
    description: () => t`Take long text and make it more concise`,
    apply: () => {
      TiptapEventBus.emit('queueDesignPartnerTask', {
        message: t`Please make this short and concise`,
      })
    },
    featureFlag: 'aiRewrite',
  },
  {
    key: 'visualize.keypoints',
    type: 'visualize',
    name: () => t`Visualize key points`,
    description: () => t`Automatically highlight the main ideas`,
    icon: regular('list-timeline'),
    node: 'smartLayout',
    apply: () => {
      TiptapEventBus.emit('queueDesignPartnerTask', {
        message: t`Visualize the key points`,
      })
    },
  },
  {
    key: 'visualize.images',
    type: 'visualize',
    name: () => t`Suggest images`,
    description: () => t`Find pictures relevant to this text`,
    icon: regular('images'),
    apply: () => {
      TiptapEventBus.emit('queueDesignPartnerTask', {
        message: t`Suggest some images for this text`,
      })
    },
  },
  {
    key: 'visualize.timeline',
    type: 'visualize',
    name: () => t`Visualize as timeline`,
    description: () => t`Convert to a timeline`,
    icon: regular('timeline'),
    apply: () => {
      TiptapEventBus.emit('queueDesignPartnerTask', {
        message: t`Turn this into a timeline`,
      })
    },
  },
  {
    key: 'reformat.columns',
    type: 'reformat',
    name: () => t`Split into columns`,
    description: () => t`Convert to a two-column layout`,
    icon: regular('table-columns'),
    node: 'gridLayout',
    apply: () => {
      TiptapEventBus.emit('queueDesignPartnerTask', {
        message: t`Split this into two columns`,
      })
    },
  },
  {
    key: 'reformat.table',
    type: 'reformat',
    name: () => t`Format as table`,
    description: () => t`Turn text into a table`,
    icon: regular('table'),
    node: 'table',
    apply: () => {
      TiptapEventBus.emit('queueDesignPartnerTask', {
        message: t`Turn this into a table`,
      })
    },
  },
  {
    key: 'reformat.toggles',
    type: 'reformat',
    name: () => t`Summarize as toggles`,
    icon: regular('chevrons-down'),
    node: 'toggle',
    description: () => t`Generate headings and collapse details under them`,
    apply: () => {
      TiptapEventBus.emit('queueDesignPartnerTask', {
        message: t`Summarize this into toggles for me.`,
      })
    },
  },
  // {
  //   key: 'reformat.cards',
  //   type: 'reformat',
  //   name: () => t`Split into cards`,
  //   description: () =>
  //     t`Split your text into multiple cards with AI-generated titles`,
  //   icon: regular('rectangle-history'),
  //   node: 'card',
  //   apply: (editor) => {
  //     const { selection, schema, doc } = editor.state
  //     const { from, to } = selection
  //     const selectedText = rangeToMarkdown(doc, from, to)
  //     const relativeRange = absoluteToRelativeRange(editor.state, {
  //       from,
  //       to,
  //     })
  //     return summarizeToCards(selectedText, schema).then((slice) => {
  //       const range = relativeToAbsoluteRange(editor.state, relativeRange)
  //       if (!range) {
  //         throw Error('Error finding replaced content')
  //       }
  //       const cards = slice.content.toJSON()
  //       trackItemInserted(
  //         getCommandsMap().insertCardInside,
  //         NodeInsertMethods.SUMMARIZE
  //       )
  //       editor
  //         .chain()
  //         .insertContentAt(range, cards, { updateSelection: false })
  //         .run()
  //     })
  //   },
  //   checkDisabled() {
  //     return true
  //   },
  // },
]
