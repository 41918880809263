import { ButtonGroup } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'
import { Attrs } from 'prosemirror-model'
import { useCallback } from 'react'

import { SmartLayoutCellAttrs } from 'modules/tiptap_editor/extensions/SmartLayout/types'
import { getSmartLayoutVariant } from 'modules/tiptap_editor/extensions/SmartLayout/variants'

import { isSVG } from '../../../extensions/media/utils'
import { isSmartLayoutNode } from '../../../extensions/SmartLayout/isSmartLayoutCellNode'
import { getSmartLayoutDirection } from '../../../extensions/SmartLayout/utils'
import { DeleteNodeButton } from '../buttons/DeleteNodeButton'
import { DuplicateNodeButton } from '../buttons/DuplicateNodeButton'
import { SelectParentButton } from '../buttons/SelectParentButton'
import { ToolbarButton } from '../buttons/ToolbarButton'
import { ChangeFocusPoint } from './ChangeFocusPoint'
import { FormattingMenuDivider } from './FormattingMenuDivider'
import { NodeFormattingMenuProps } from './types'

export const SmartLayoutCellFormattingMenu = ({
  editor,
  selection,
}: NodeFormattingMenuProps) => {
  const { node, from: pos } = selection
  const parentLayout = selection.$from.parent

  const addColumnBefore = useCallback(() => {
    editor.chain().insertSmartLayoutCell(selection.from).run()
  }, [editor, selection.from])
  const addColumnAfter = useCallback(() => {
    editor
      .chain()
      .insertSmartLayoutCell(selection.from + selection.node.nodeSize)
      .run()
  }, [editor, selection.from, selection.node])

  const { options, image } = selection.node.attrs as SmartLayoutCellAttrs

  const updateAttributes = useCallback(
    (attrs: Attrs) => {
      editor.commands.updateNestedAttributesAtPos(selection.from, attrs)
    },
    [editor, selection.from]
  )

  if (!parentLayout || !isSmartLayoutNode(parentLayout)) {
    console.error('SmartLayoutCellFormattingMenu: parent is not a smartLayout')
    return null
  }
  const addDirection = getSmartLayoutDirection(parentLayout.attrs)
  const parentVariant = getSmartLayoutVariant(parentLayout.attrs.variantKey)
  const isImageSVG = isSVG(image?.src || image?.tempUrl)
  const canChangeFocusPoint =
    parentVariant.key === 'imagesText' && !isImageSVG && image?.src

  return (
    <ButtonGroup spacing={1} size="sm" alignItems="center">
      <SelectParentButton
        editor={editor}
        label={t`Edit layout...`}
        icon={parentVariant.icon}
      />
      <FormattingMenuDivider />
      {canChangeFocusPoint && (
        <>
          <ChangeFocusPoint editor={editor} pos={pos} node={node} />
          <FormattingMenuDivider />
        </>
      )}
      <ToolbarButton
        label={t`Add item before`}
        iconRotation={addDirection === 'bottom' ? undefined : 270}
        icon={regular('diagram-cells')}
        onClick={addColumnBefore}
        testId="add-col-before-button"
      />
      <ToolbarButton
        label={t`Add item after`}
        icon={regular('diagram-cells')}
        iconRotation={addDirection === 'bottom' ? 180 : 90}
        onClick={addColumnAfter}
        testId="add-col-after-button"
      />
      <DuplicateNodeButton editor={editor} selection={selection} />
      {parentVariant.cellOptions.length > 0 ? <FormattingMenuDivider /> : null}
      <ButtonGroup size="sm">
        {parentVariant.cellOptions.map((option) => {
          if (
            option.checkEnabled &&
            !option.checkEnabled(node.attrs as SmartLayoutCellAttrs)
          ) {
            return null
          }
          const value = options[option.key] ?? option.defaultValue
          const updateValue = (newValue: any) => {
            updateAttributes({
              options: { ...options, [option.key]: newValue },
            })
          }
          return (
            <option.Control
              key={option.key}
              value={value}
              updateValue={updateValue}
              editor={editor}
              option={option}
            />
          )
        })}
      </ButtonGroup>
      <FormattingMenuDivider />
      <DeleteNodeButton editor={editor} />
    </ButtonGroup>
  )
}
