import TiptapHighlight from '@tiptap/extension-highlight'
import { mergeAttributes } from '@tiptap/react'

import { isColorDark } from 'utils/color'

import { ExtensionPriorityMap } from '../constants'
import { ValidTextColors } from './textColorStyles'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    highlightColor: {
      // Deprecated. Old highlight colors were a semantic variant. New ones are a hex color.
      setHighlightVariant: (variant: string) => ReturnType
      setHighlightColor: (hex: string) => ReturnType
    }
  }
}

export const Highlight = TiptapHighlight.extend({
  name: 'highlight',
  priority: ExtensionPriorityMap.Highlight,
  excludes: 'textColor highlight',
  addAttributes() {
    return {
      // Deprecated. Old highlight colors were a semantic variant. New ones are a hex color.
      variant: {},
      hex: {},
    }
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setHighlightColor:
        (hex) =>
        ({ commands }) => {
          return commands.setMark(this.name, { hex, variant: null })
        },
      setHighlightVariant:
        (variant) =>
        ({ commands }) => {
          return commands.setMark(this.name, { variant, hex: null })
        },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'mark[class=highlight]',
      },
      {
        tag: '*[highlight]',
        getAttrs(elt: HTMLElement) {
          const color = elt.getAttribute('highlight')
          if (!color) {
            return false
          }
          // If it's not a semantic variant, use it as a hex color directly
          if (!ValidTextColors.includes(color)) {
            return {
              hex: color,
            }
          }
          return {
            variant: color,
          }
        },
        // This lets us match a tag like <h1 color="red">. It will run before the H1 rule due to the higher
        // priority, and consuming: false means the H1 rule can still match it.
        consuming: false,
        priority: 60,
      },
    ]
  },

  renderHTML({ HTMLAttributes, mark }) {
    const isDark = mark.attrs.hex && isColorDark(mark.attrs.hex)
    const styles = [
      `--highlight-color: ${mark.attrs.hex}`,
      `color: var(${
        isDark ? '--highlight-color-dark' : '--highlight-color-light'
      })`,
    ].join('; ')
    return [
      'mark',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: 'highlight',
        style: styles,
      }),
    ]
  },

  renderHTMLforAI({ mark }) {
    return [
      'span',
      {
        color: mark.attrs.hex ?? mark.attrs.variant,
      },
    ]
  },
}).configure({ multicolor: true })
