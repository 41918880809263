import { Editor, Extension, Range } from '@tiptap/core'
import { Slice } from 'prosemirror-model'
import { EditorState, Plugin, PluginKey, Transaction } from 'prosemirror-state'

import { transformPastedHTML } from 'modules/tiptap_editor/extensions/Clipboard/transformPastedHTML'
import { traverseJSONContent } from 'modules/tiptap_editor/utils'
import { createSelectionNearLastTo } from 'modules/tiptap_editor/utils/selection/findSelectionNearOrGapCursor'

import { getDocFlags } from '../DocFlags/docFlags'
import { defaultHandlePaste } from './defaultHandlePaste'
import { handleLinkPaste } from './handleLinkPaste'
import { handleMarkdownPaste, parseMarkdownToSlice } from './markdown'
import { SplitOptions } from './splitCards'
import { transformOutsidePastedContent } from './transformOutsidePastedContent'
import { serializeFragment } from './utils'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    clipboard: {
      insertMarkdownAt: (
        range: Range,
        text: string,
        splitCardOptions?: SplitOptions
      ) => ReturnType
    }
  }
}

// Extension for copying and pasting between Gamma and other apps like Google Docs
// Examples: https://codesandbox.io/s/bullet-examples-re319z?file=/index.html
export const Clipboard = Extension.create({
  name: 'clipboard',

  addProseMirrorPlugins() {
    const editor: Editor = this.editor
    const schema = editor.state.schema

    let dragging: { slice: Slice; move: boolean } | null = null
    return [
      new Plugin({
        key: new PluginKey('clipboard'),
        /**
         * NOTE(jordan) This is the only reasonable way I found to fix the selection after a cut
         * since the cut event handler happens before PM's cut.  We have to look through all transactions
         * and use the `uiEvent` == "cut" to determine if a cut happened and to fix the selection in
         * an appended transaction
         */
        appendTransaction(
          transactions: Transaction[],
          _oldState: EditorState,
          newState: EditorState
        ) {
          const cutTransaction = transactions.find(
            (t) => t.getMeta('uiEvent') === 'cut'
          )
          if (!cutTransaction) {
            return null
          }

          const sel = createSelectionNearLastTo(cutTransaction)
          return sel ? newState.tr.setSelection(sel) : null
        },
        props: {
          handleDOMEvents: {
            drop(view) {
              // capture view.dragging since `transformPasted` gets called in `editHandlers.drop` after
              // `view.dragging` is nulled out
              dragging = view.dragging
              requestAnimationFrame(() => {
                dragging = null
              })
              return
            },
          },
          // @ts-ignore - the Typescript wants a full DOMSerializer, but per the docs we
          // only need serializeFragment: https://prosemirror.net/docs/ref/#view.EditorProps.clipboardSerializer
          clipboardSerializer: {
            serializeFragment: (fragment) =>
              serializeFragment(fragment, this.editor.schema),
          },
          // Takes incoming HTML from other tools (eg Google Docs) and parses it
          transformPastedHTML,
          // Takes the slice ProseMirror parses from the HTML and transforms it further
          // This runs after transformPastedHTML
          transformPasted: (slice): Slice => {
            const hasSlice = !!dragging?.slice
            let result =
              // hasSlice is true when the same editor programmatically sets the drag data
              hasSlice ? slice : transformOutsidePastedContent(slice, schema)

            result = transformNonCardLayoutContent(editor, result)
            return result
          },
          // Parse plain text
          handlePaste: (view, event, slice) => {
            return (
              handleLinkPaste(editor, event, slice) ||
              handleMarkdownPaste(editor, event) ||
              defaultHandlePaste(view, event, slice)
            )
          },
        },
      }),
    ]
  },

  addCommands() {
    return {
      insertMarkdownAt:
        (range, text) =>
        ({ state, tr }) => {
          try {
            const slice = parseMarkdownToSlice(text, state.schema)
            tr.replaceRange(range.from, range.to, slice)
            return true
          } catch (err) {
            console.warn('(caught) [Clipboard] insertMarkdownAt error', err)
            return false
          }
        },
    }
  },
})

const transformNonCardLayoutContent = (editor: Editor, slice: Slice) => {
  const existingDocFlags = getDocFlags(editor.state.doc)

  const content = slice.toJSON()?.content
  if (
    // content must originate from gamma doc
    !content ||
    content[0].type !== 'document'
  ) {
    return slice
  }

  const sliceDocFlags = getDocFlags(slice.content.firstChild!)
  if (
    !(existingDocFlags.cardLayoutsEnabled && !sliceDocFlags.cardLayoutsEnabled)
  ) {
    // only handle non card layouts -> card layouts paste
    return slice
  }

  traverseJSONContent(content, (node) => {
    if (node.type === 'card') {
      // wrap each card content with cardLayoutItem
      node.content = [
        {
          type: 'cardLayoutItem',
          content: node.content,
        },
      ]
    }
  })
  return Slice.fromJSON(editor.schema, {
    openStart: slice.openStart + 1,
    openEnd: slice.openEnd + 1,
    content,
  })
}
