import { Editor as CoreEditor } from '@tiptap/core'
import React, { HTMLProps } from 'react'

import { NodeViewWrapper } from '../react'
import { ReactRenderer } from '../react/ReactRenderer'
import { ReactNodeViewContext } from '../react/useReactNodeView'
import { NodeViewContent } from './NodeViewContent'

export interface EditorContentProps extends HTMLProps<HTMLDivElement> {
  editor: Editor | null
}

export interface EditorContentState {
  counter: number
}

export class Editor extends CoreEditor {
  public contentComponent: React.Component<EditorContentProps> | null = null
}

class PureSimpleEditorContent extends React.Component<
  EditorContentProps,
  EditorContentState
> {
  editorContentRef: React.RefObject<any>
  editorContentChildren: React.RefObject<any>

  RootComponent: JSX.Element | null = null

  public renderers: Map<string, ReactRenderer> = new Map()

  private mounted: boolean = false

  constructor(props: EditorContentProps) {
    super(props)
    this.editorContentRef = React.createRef()
    this.editorContentChildren = React.createRef()

    this.state = {
      counter: 0,
    }

    this.init()
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  init() {
    const { editor } = this.props
    if (!editor) return
    const { doc } = editor.state
    editor.contentComponent = this

    editor.createNodeViews()

    this.RootComponent = <RootComponent editor={editor} node={doc} />
  }

  setRenderer(id: string, renderer: ReactRenderer) {
    this.renderers.set(id, renderer)
    if (this.mounted) {
      this.setState({ counter: this.state.counter + 1 })
    }
  }

  removeRenderer(id: string) {
    this.renderers.delete(id)
    if (this.mounted) {
      this.setState({ counter: this.state.counter + 1 })
    }
  }

  render() {
    const { editor } = this.props

    if (!editor) return null

    return <div className="ProseMirror">{this.RootComponent}</div>
  }
}

export const SimpleEditorContent = React.memo(PureSimpleEditorContent)

// Just render the doc's content, which is the normally the "Document" node
// but could be other stuff if it's a footnote
const RootComponent = ({ node, editor }) => {
  return (
    <ReactNodeViewContext.Provider
      value={{
        editor,
        node,
      }}
    >
      <NodeViewWrapper>
        <NodeViewContent />
      </NodeViewWrapper>
    </ReactNodeViewContext.Provider>
  )
}
