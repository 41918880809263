import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'
import { Editor } from '@tiptap/core'
import { groupBy } from 'lodash'

import { featureFlags } from 'modules/featureFlags'
import { CommandInfo } from 'modules/tiptap_editor/commands'
import { EMPTY_NODES } from 'modules/tiptap_editor/commands/emptyNodes'
import AccentImageLeft from 'publicImages/card-templates/Accent-left.svg'
import AccentImageRight from 'publicImages/card-templates/Accent-right.svg'
import BarStatsWithText from 'publicImages/card-templates/Bar-Stats-With-Text.svg'
import BlankCard from 'publicImages/card-templates/Blank-card.svg'
import FunnelWithText from 'publicImages/card-templates/Funnel-With-Text.svg'
import SteppedPyramidWithText from 'publicImages/card-templates/Stepped-Pyramid-With-Text.svg'
import TitleCard from 'publicImages/card-templates/Title-card.svg'
import TitleWith2ColumnContent from 'publicImages/card-templates/Title-with-2-Column-Content.svg'
import TitleWith2ColumnImageLeft from 'publicImages/card-templates/Title-with-2-Column-Image-Left.svg'
import TitleWith2ColumnImageRight from 'publicImages/card-templates/Title-with-2-Column-Image-Right.svg'
import TitleWith2Images from 'publicImages/card-templates/Title-with-2-images.svg'
import TitleWith3Boxes from 'publicImages/card-templates/Title-with-3-boxes.svg'
import TitleWith3ColumnContent from 'publicImages/card-templates/Title-with-3-Column-Content.svg'
import TitleWith3Images from 'publicImages/card-templates/Title-with-3-images.svg'
import TitleWith3NestedCards from 'publicImages/card-templates/Title-with-3-nested-cards.svg'
import TitleWith3Stats from 'publicImages/card-templates/Title-with-3-stats.svg'
import TitleWith3Toggles from 'publicImages/card-templates/Title-with-3-toggles.svg'
import TitleWithContentWithButton from 'publicImages/card-templates/Title-with-Content-with-Button.svg'
import TitleWithLargeBullets from 'publicImages/card-templates/Title-with-large-bullets.svg'
import TitleWithTeamPhotos from 'publicImages/card-templates/Title-with-team-photos.svg'
import TitleWithText from 'publicImages/card-templates/Title-with-text.svg'
import TitleWithTimeline from 'publicImages/card-templates/Title-with-timeline.svg'

import { getDocFlag } from '../../DocFlags/hooks'
import { assignCardIds } from '../uniqueId'

type CardTemplateGroup = 'basic' | 'twoCol' | 'specialty'
const getCardTemplateGroups = (): Record<CardTemplateGroup, string> => {
  return {
    basic: t`One section`,
    twoCol: t`Two section`,
    specialty: t`Multi section`,
  }
}

type CardTemplateCommand = CommandInfo & {
  group: CardTemplateGroup
}

export const checkCardLayoutsDisabled = (editor: Editor) => {
  const docHasCardLayouts = getDocFlag(editor, 'cardLayoutsEnabled')
  const card1InCard2 = featureFlags.get('card1InCard2')
  return !docHasCardLayouts && !card1InCard2
}

export const getCardTemplateCommands = (): CardTemplateCommand[] => {
  return [
    {
      type: 'cardTemplate',
      key: 'blankCard',
      name: () => t`Blank card`,
      nodeName: 'card',
      shortcut: '/new',
      icon: duotone('grid-2'),
      previewImage: BlankCard,
      execute: (editor) =>
        editor.commands.insertContentAndSelect(EMPTY_NODES.blankCard),
      group: 'basic',
    },
    {
      type: 'cardTemplate',
      key: 'titleCard',
      name: () => t`Title card`,
      nodeName: 'card',
      icon: duotone('grid-2'),
      previewImage: TitleCard,
      execute: (editor) =>
        editor.commands.insertContentAndSelect(EMPTY_NODES.titleCard),
      group: 'basic',
    },
    {
      type: 'cardTemplate',
      key: 'titleWithText',
      name: () => t`Title with text`,
      nodeName: 'card',
      icon: duotone('grid-2'),
      previewImage: TitleWithText,

      execute: (editor) =>
        editor.commands.insertContentAndSelect(EMPTY_NODES.titleWithText),
      group: 'basic',
    },
    {
      type: 'cardTemplate',
      key: 'accentLeft',
      name: () => t`Accent left`,
      nodeName: 'card',
      icon: duotone('grid-2'),
      previewImage: AccentImageLeft,
      execute: (editor) =>
        editor.commands.insertContentAndSelect(EMPTY_NODES.accentLeft),

      group: 'twoCol',
      checkDisabled: checkCardLayoutsDisabled,
    },
    {
      type: 'cardTemplate',
      key: 'accentRight',
      name: () => t`Accent right`,
      nodeName: 'card',
      icon: duotone('grid-2'),
      previewImage: AccentImageRight,
      execute: (editor) =>
        editor.commands.insertContentAndSelect(EMPTY_NODES.accentRight),
      group: 'twoCol',
      checkDisabled: checkCardLayoutsDisabled,
    },
    {
      type: 'cardTemplate',
      key: 'titleWithContentAndButton',
      name: () => t`Content with button`,
      nodeName: 'card',
      icon: duotone('grid-2'),
      previewImage: TitleWithContentWithButton,
      execute: (editor) =>
        editor.commands.insertContentAndSelect(
          EMPTY_NODES.titleWithContentAndButton
        ),
      group: 'basic',
    },
    {
      type: 'cardTemplate',
      key: 'titleWith2ColumnContent',
      name: () => t`2 column content`,
      nodeName: 'card',
      icon: duotone('grid-2'),
      previewImage: TitleWith2ColumnContent,
      execute: (editor) =>
        editor.commands.insertContentAndSelect(
          EMPTY_NODES.titleWith2ColumnContent
        ),
      group: 'twoCol',
    },
    {
      type: 'cardTemplate',
      key: 'titleWith2ColumnImageLeft',
      name: () => t`2 column (image left)`,
      nodeName: 'card',
      icon: duotone('grid-2'),
      previewImage: TitleWith2ColumnImageLeft,
      execute: (editor) =>
        editor.commands.insertContentAndSelect(
          EMPTY_NODES.titleWith2ColumnImageLeft
        ),
      group: 'twoCol',
    },
    {
      type: 'cardTemplate',
      key: 'titleWith2ColumnImageRight',
      name: () => t`2 column (image right)`,
      nodeName: 'card',
      icon: duotone('grid-2'),
      previewImage: TitleWith2ColumnImageRight,
      group: 'twoCol',

      execute: (editor) =>
        editor.commands.insertContentAndSelect(
          EMPTY_NODES.titleWith2ColumnImageRight
        ),
    },
    {
      type: 'cardTemplate',
      key: 'titleWith2ImageColumns',
      name: () => t`2 image columns`,
      nodeName: 'card',
      icon: duotone('grid-2'),
      previewImage: TitleWith2Images,

      execute: (editor) =>
        editor.commands.insertContentAndSelect(
          EMPTY_NODES.titleWith2ImageColumns
        ),
      group: 'twoCol',
    },
    {
      type: 'cardTemplate',
      key: 'titleWith3ColumnContent',
      name: () => t`3 column content`,
      nodeName: 'card',
      icon: duotone('grid-2'),
      previewImage: TitleWith3ColumnContent,

      execute: (editor) =>
        editor.commands.insertContentAndSelect(
          EMPTY_NODES.titleWith3ColumnContent
        ),
      group: 'specialty',
    },
    {
      type: 'cardTemplate',
      key: 'titleWith3ImageColumns',
      name: () => t`3 image columns`,
      nodeName: 'card',
      icon: duotone('grid-2'),
      previewImage: TitleWith3Images,

      execute: (editor) =>
        editor.commands.insertContentAndSelect(
          EMPTY_NODES.titleWith3ImageColumns
        ),
      group: 'specialty',
    },
    {
      type: 'cardTemplate',
      key: 'titleWith3NestedCards',
      name: () => t`3 nested cards`,
      nodeName: 'card',
      icon: duotone('grid-2'),
      previewImage: TitleWith3NestedCards,
      execute: (editor) => {
        editor
          .chain()
          .insertContentAndSelect(
            assignCardIds(EMPTY_NODES.titleWith3NestedCards)
          )
          .run()
      },
      group: 'specialty',
    },
    {
      type: 'cardTemplate',
      key: 'titleWith3Toggles',
      name: () => t`3 toggles`,
      nodeName: 'card',
      icon: duotone('grid-2'),
      previewImage: TitleWith3Toggles,

      execute: (editor) =>
        editor.commands.insertContentAndSelect(EMPTY_NODES.titleWith3Toggles),
      group: 'specialty',
    },
    {
      type: 'cardTemplate',
      key: 'titleWithTimeline',
      name: () => t`Timeline`,
      nodeName: 'card',
      icon: duotone('grid-2'),
      previewImage: TitleWithTimeline,
      execute: (editor) =>
        editor.commands.insertContentAndSelect(EMPTY_NODES.titleWithTimeline),
      group: 'specialty',
    },
    {
      type: 'cardTemplate',
      key: 'titleWithLargeBulletList',
      name: () => t`Large bullet list`,
      nodeName: 'card',
      icon: duotone('grid-2'),
      previewImage: TitleWithLargeBullets,
      execute: (editor) =>
        editor.commands.insertContentAndSelect(
          EMPTY_NODES.titleWithLargeBulletList
        ),
      group: 'specialty',
    },
    {
      type: 'cardTemplate',
      key: 'titleWith3Boxes',
      name: () => t`3 boxes`,
      nodeName: 'card',
      icon: duotone('grid-2'),
      previewImage: TitleWith3Boxes,
      execute: (editor) =>
        editor.commands.insertContentAndSelect(EMPTY_NODES.titleWith3Boxes),
      group: 'specialty',
    },
    {
      type: 'cardTemplate',
      key: 'titleWithTeamPhotos',
      name: () => t`Team photos`,
      nodeName: 'card',
      icon: duotone('grid-2'),
      previewImage: TitleWithTeamPhotos,
      execute: (editor) =>
        editor.commands.insertContentAndSelect(EMPTY_NODES.titleWithTeamPhotos),
      group: 'specialty',
    },
    {
      type: 'cardTemplate',
      key: 'titleWith3Stats',
      name: () => t`Big numbers`,
      nodeName: 'card',
      icon: duotone('grid-2'),
      featureFlag: 'statsSmartLayouts',
      previewImage: TitleWith3Stats,
      execute: (editor) =>
        editor.commands.insertContentAndSelect(EMPTY_NODES.titleWith3Stats),
      group: 'specialty',
    },
    {
      type: 'cardTemplate',
      key: 'barStatsWithText',
      name: () => t`Bar stats with text`,
      featureFlag: 'statsSmartLayouts',
      nodeName: 'card',
      icon: duotone('grid-2'),
      previewImage: BarStatsWithText,
      execute: (editor) =>
        editor.commands.insertContentAndSelect(EMPTY_NODES.barStatsWithText),
      group: 'twoCol',
    },
    {
      type: 'cardTemplate',
      key: 'funnelWithText',
      name: () => t`Funnel with text`,
      featureFlag: 'pyramidSmartLayouts',
      nodeName: 'card',
      icon: duotone('grid-2'),
      previewImage: FunnelWithText,
      execute: (editor) =>
        editor.commands.insertContentAndSelect(EMPTY_NODES.funnelWithText),
      group: 'twoCol',
    },
    {
      type: 'cardTemplate',
      key: 'steppedPyramidWithText',
      name: () => t`Stepped pyramid with text`,
      featureFlag: 'pyramidSmartLayouts',
      nodeName: 'card',
      icon: duotone('grid-2'),
      previewImage: SteppedPyramidWithText,
      execute: (editor) =>
        editor.commands.insertContentAndSelect(
          EMPTY_NODES.steppedPyramidWithText
        ),
      group: 'twoCol',
    },
  ]
}

export const getGroupedCardTemplates = () =>
  Object.entries(groupBy(getCardTemplateCommands(), 'group')).map(
    ([group, items]) => ({
      subcategory: getCardTemplateGroups()[group],
      items: items,
    })
  )
