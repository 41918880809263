import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'

import { ToolbarButton } from 'modules/tiptap_editor/components/menus/buttons/ToolbarButton'

import { SmartLayoutOption, SmartLayoutOptionControlProps } from '../../types'
import { checkStacked } from './StackedPyramid'

const OutsideTextOptionControl = ({
  value,
  updateValue,
  option,
}: SmartLayoutOptionControlProps<boolean>) => {
  return (
    <ToolbarButton
      label={option.name()}
      onClick={() => updateValue(!value)}
      isActive={value}
      icon={regular('paragraph-left')}
    />
  )
}

export const OutsideTextOption: SmartLayoutOption<boolean> = {
  key: 'outsideText',
  defaultValue: true,
  Control: OutsideTextOptionControl,
  checkEnabled: (_, deco) => !!deco && !checkStacked(deco),
  name: () => t`Outside text`,
}
