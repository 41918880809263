import {
  duotone,
  regular,
} from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'
import { Editor } from '@tiptap/core'
import { sortBy } from 'lodash'
import keyBy from 'lodash/keyBy'

import { featureFlags } from 'modules/featureFlags'
import { EMPTY_NODES } from 'modules/tiptap_editor/commands/emptyNodes'

import { AICommands } from '../extensions/AI/commands'
import { getCalloutBoxCommands } from '../extensions/CalloutBox/options'
import { getCardTemplateCommands } from '../extensions/Card/cardTemplates'
import { DrawingCommands } from '../extensions/Drawing/templates'
import {
  generateFootnoteId,
  setFootnoteExpanded,
} from '../extensions/Footnote/FootnoteState'
import { isFootnoteEditor } from '../extensions/Footnote/utils'
import { ListVariant } from '../extensions/lists/ListTypes'
import { MediaCommands } from '../extensions/media/MediaCommands'
import {
  SmartLayoutCommands,
  SmartLayoutTemplateCommands,
} from '../extensions/SmartLayout/commands'
import { Title, TitleLevel } from '../extensions/Title/Title'
import { ToggleCommands } from '../extensions/Toggle/commands'
import { canInsertNodeAtSelection } from '../utils'
import { CommandInfo } from './types'
import { setDraggingContent } from './utils'

export const CommandsList: CommandInfo[] = [
  // CARDS
  {
    key: 'insertCardAfter',
    name: () => {
      return t`New card below`
    },
    nodeName: 'card',
    icon: duotone('diagram-cells'),
    execute: (editor) => editor.commands.insertCardAfter(),
    // Don't enable if cards aren't enabled here (e.g. inside a footnote)
    checkDisabled: (editor) => !editor.schema.nodes.card,
    keywords: ['card', 'below', 'after', 'new'],
  },
  {
    key: 'insertCardInside',
    name: () => t`Nested card`,
    nodeName: 'card',
    icon: duotone('diagram-subtask'),
    execute: (editor) => editor.commands.insertNestedCard(),
    // Don't enable if cards aren't enabled here (e.g. inside a footnote)
    checkDisabled: (editor) => !editor.schema.nodes.card,
    keywords: ['nested', 'card', 'sub'],
  },
  {
    key: 'splitCard',
    name: () => t`Split card here`,
    nodeName: 'card',
    icon: duotone('page-break'),
    shortcut: '***',
    execute: (editor) => editor.commands.splitCardAtSelection(),
    checkDisabled: (editor) => !editor.schema.nodes.card,
    dragStartFn: (editor) => {
      // This one is a special snowflake. First, we'll create an empty paragraph node
      setDraggingContent(editor, EMPTY_NODES.normalText)
    },
    dragEndFn: (editor) => {
      // Then, we'll run the split command once the drag interaction has completed
      editor.chain().splitCardAtSelection().focusDelayed().run()
    },
    keywords: ['split'],
  },
  ...getCardTemplateCommands(),
  {
    key: 'title',
    name: () => t`Title`,
    nodeName: 'title',
    icon: regular('text'),
    shortcut: '/title',
    execute: (editor) => {
      editor
        .chain()
        .focus()
        .setNode(Title.name, { level: TitleLevel.DocTitle })
        .run()
    },
    keywords: ['title', 'big'],
  },
  {
    key: 'h1',
    name: () => t`Heading 1`,
    nodeName: 'heading',
    icon: duotone('h1'),
    shortcut: '# Heading 1',
    execute: (editor) => {
      editor
        .chain()
        .focus()
        .setHeading({
          level: 1,
        })
        .run()
    },
    keywords: ['heading', 'h1'],
  },
  {
    key: 'smallText',
    name: () => t`Small text`,
    nodeName: 'paragraph',
    icon: duotone('text'),
    shortcut: '/sm',
    execute: (editor) => {
      editor.chain().focus().setFontSize('sm').run()
    },
    keywords: ['sm', 'small', 'paragraph'],
  },
  {
    key: 'normalText',
    name: () => t`Normal text`,
    nodeName: 'paragraph',
    icon: duotone('text'),
    shortcut: '/md',
    execute: (editor) => {
      editor.chain().focus().setFontSize('md').run()
    },
    keywords: ['md', 'normal', 'paragraph', 'default', 'body'],
  },
  {
    key: 'largeText',
    name: () => t`Large text`,
    nodeName: 'paragraph',
    icon: duotone('text'),
    shortcut: '/lg',
    execute: (editor) => {
      editor.chain().focus().setFontSize('lg').run()
    },
    keywords: ['lg', 'large', 'paragraph'],
  },
  {
    key: 'h2',
    name: () => t`Heading 2`,
    nodeName: 'heading',
    icon: duotone('h2'),
    shortcut: '## Heading 2',
    execute: (editor) => {
      editor
        .chain()
        .focus()
        .setHeading({
          level: 2,
        })
        .run()
    },
    keywords: ['heading', 'h2'],
  },
  {
    key: 'h3',
    name: () => t`Heading 3`,
    nodeName: 'heading',
    icon: duotone('h3'),
    shortcut: '### Heading 3',
    execute: (editor) => {
      editor
        .chain()
        .focus()
        .setHeading({
          level: 3,
        })
        .run()
    },
    keywords: ['heading', 'h3'],
  },
  {
    key: 'h4',
    name: () => t`Heading 4`,
    nodeName: 'heading',
    icon: duotone('h4'),
    shortcut: '#### Heading 4',
    execute: (editor) => {
      editor
        .chain()
        .focus()
        .setHeading({
          level: 4,
        })
        .run()
    },
    keywords: ['heading', 'h4'],
  },
  {
    key: 'openEmojiPicker',
    name: () => t`Emoji`,
    nodeName: 'emoji',
    icon: regular('face-smile'),
    execute: (editor) => editor.commands.openEmojiPicker(),
    keywords: ['icon', 'symbol', 'emoji'],
  },
  {
    key: 'bulletedList',
    name: () => t`Bulleted list`,
    nodeName: ListVariant.Bullet,
    icon: duotone('list-ul'),
    shortcut: '- Item',
    execute: (editor) => {
      editor
        .chain()
        .setListItems({ variant: ListVariant.Bullet })
        .selectInsertedNode()
        .run()
    },
    keywords: ['bullets', 'unordered', 'ul', 'list'],
  },
  {
    key: 'numberedList',
    name: () => t`Numbered list`,
    nodeName: ListVariant.Numbered,
    icon: duotone('list-ol'),
    shortcut: '1. Item',
    execute: (editor) => {
      editor
        .chain()
        .setListItems({ variant: ListVariant.Numbered })
        .selectInsertedNode()
        .run()
    },
    keywords: ['numbers', 'numbered', 'ordered', 'ol', 'list'],
  },
  {
    key: 'todoList',
    name: () => t`Todo list`,
    nodeName: ListVariant.Todo,
    icon: duotone('tasks'),
    shortcut: '[] Item',
    execute: (editor) => {
      editor
        .chain()
        .setListItems({ variant: ListVariant.Todo })
        .selectInsertedNode()
        .run()
    },
    keywords: ['todo', 'task', 'checkbox', 'list', 'checklist'],
  },
  {
    key: 'blockquote',
    name: () => t`Blockquote`,
    nodeName: 'blockquote',
    icon: duotone('block-quote'),
    shortcut: '> Quote',
    execute: (editor) => editor.chain().focus().toggleBlockquote().run(),
    keywords: ['quote', 'blockquote'],
  },
  ...getCalloutBoxCommands(),
  {
    key: 'buttonGroup',
    name: () => t`Button`,
    nodeName: 'buttonGroup',
    icon: duotone('hand-pointer'),
    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.buttonGroup),
    description: () =>
      t`A clickable button that can link to other pages, or an email`,
    keywords: ['button', 'link', 'click', 'call to action'],
  },
  {
    key: 'codeBlock',
    name: () => t`Code block`,
    nodeName: 'codeBlock',
    icon: duotone('code'),
    // shortcut: '```code```', // This doesn't work yet
    execute: (editor) => {
      editor.chain().focus().setCodeBlock().run()
    },
    keywords: ['code', 'block', 'codeblock'],
  },
  {
    key: 'mathBlock',
    name: () => t`Math block`,
    nodeName: 'math_display',
    featureFlag: 'math',
    icon: duotone('function'),
    keywords: ['math', 'equation', 'latex', 'katex'],
    execute: (editor) =>
      editor.chain().focus().insertContent(EMPTY_NODES.mathBlock).run(),
    dragEndFn: () => {},
    description: () => t`Write equations using Katex syntax`,
  },
  {
    key: 'mathInline',
    name: () => t`Inline math`,
    featureFlag: 'math',
    nodeName: 'math_inline',
    icon: duotone('sigma'),
    keywords: ['math', 'equation', 'latex', 'katex'],
    execute: (editor) => editor.chain().focus().insertMathInline().run(),
    shortcut: '$x^2$ ',
  },
  {
    key: 'table2',
    name: () => t`2x2 table`,
    keywords: ['table', 'grid', 'data'],
    nodeName: 'table',
    icon: duotone('table'),
    execute: (editor) =>
      editor.commands.insertTable({ rows: 2, cols: 2, withHeaderRow: false }),
    // Passing a no-op prevents onFocusDragEnd getting called from within onItemDragEnd in InsertWidgetButtons
    // onFocusDragEnd can cause the selection to be incorrect for tables when dragging between cards
  },
  {
    key: 'table3',
    name: () => t`3x3 table`,
    keywords: ['table', 'grid', 'data'],
    nodeName: 'table',
    icon: duotone('table'),
    execute: (editor) =>
      editor.commands.insertTable({ rows: 3, cols: 3, withHeaderRow: false }),
  },
  {
    key: 'table4',
    name: () => t`4x4 table`,
    keywords: ['table', 'grid', 'data'],
    nodeName: 'table',
    icon: duotone('table'),
    execute: (editor) =>
      editor.commands.insertTable({ rows: 4, cols: 4, withHeaderRow: false }),
  },
  {
    key: 'columns2',
    name: () => t`2 columns`,
    keywords: ['columns', 'layout', 'grid', 'two'],
    nodeName: 'gridLayout',
    icon: duotone('columns-3'),
    execute: (editor) => editor.commands.insertLayout(2),
  },
  {
    key: 'columns3',
    name: () => t`3 columns`,
    keywords: ['columns', 'layout', 'grid', 'three'],
    nodeName: 'gridLayout',
    icon: duotone('columns-3'),
    execute: (editor) => editor.commands.insertLayout(3),
  },
  {
    key: 'columns4',
    name: () => t`4 columns`,
    keywords: ['columns', 'layout', 'grid', 'four'],
    nodeName: 'gridLayout',
    icon: duotone('columns-3'),
    execute: (editor) => editor.commands.insertLayout(4),
  },
  {
    key: 'contributors',
    name: () => t`Contributors`,
    nodeName: 'contributors',
    icon: duotone('circle-user'),
    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.contributors),
    keywords: ['contributors', 'authors', 'credits'],
  },
  {
    key: 'divider',
    name: () => t`Divider`,
    nodeName: 'divider',
    icon: duotone('horizontal-rule'),
    keywords: ['hr', 'rule', 'line', 'divider'],
    shortcut: '---',
    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.divider),
  },
  {
    key: 'tableOfContents',
    name: () => t`Table of contents`,
    featureFlag: 'tableOfContentsBlock',
    nodeName: 'tableOfContents',
    icon: duotone('bars-staggered'),
    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.tableOfContents, -1),
    keywords: ['toc', 'contents'],
  },
  {
    key: 'gallery',
    name: () => t`Gallery`,
    description: () =>
      t`Combine images, videos, and embeds in a zoomable carousel`,
    nodeName: 'gallery',
    icon: duotone('grid-2'),
    keywords: ['gallery', 'image', 'layout', 'filmstrip', 'carousel'],
    execute: (editor) =>
      editor.commands.insertContentAndSelect(EMPTY_NODES.gallery),
  },
  {
    key: 'footnote',
    name: () => t`Footnote`,
    nodeName: 'footnote',
    shortcut: '^note^',
    icon: duotone('superscript'),
    execute: (editor) => {
      const noteId = generateFootnoteId()
      const newNode = { ...EMPTY_NODES.footnote, attrs: { noteId } }
      setFootnoteExpanded(noteId, true)
      editor.commands.insertContent(newNode)
    },
    dragStartFn: (editor) => {
      const noteId = generateFootnoteId()
      const newNode = { ...EMPTY_NODES.footnote, attrs: { noteId } }
      setFootnoteExpanded(noteId, true)
      setDraggingContent(editor, newNode)
    },
    checkDisabled: isFootnoteEditor, // Dont allow footnotes inside footnotes
    keywords: ['footnote', 'note', 'reference'],
  },
  ...MediaCommands,
  ...SmartLayoutCommands,
  ...SmartLayoutTemplateCommands,
  ...DrawingCommands,
  ...ToggleCommands,
  ...AICommands,
]

export const getSortedCommands = () =>
  sortBy(CommandsList, (command) => command.priority)

export const getCommandsMap = () => keyBy(CommandsList, 'key')

export const checkCommandDisabled = (
  editor: Editor,
  command: CommandInfo,
  checkPosition = true
) => {
  if (command.featureFlag && !featureFlags.get(command.featureFlag)) {
    return true
  }

  if (command.checkDisabled?.(editor)) {
    return true
  }
  return checkPosition
    ? !canInsertNodeAtSelection(editor, command.nodeName)
    : false
}
