import { useOutsideClick } from '@chakra-ui/react'
import { Editor, isTextSelection } from '@tiptap/core'
import { useCallback } from 'react'

import { useAppSelector } from 'modules/redux'
import { selectExpandedNoteId } from 'modules/tiptap_editor/reducer'

import { setFootnoteExpandedDebounced } from './FootnoteState'

export const useToggleFootnoteOnClick = (
  editor: Editor,
  noteId: string,
  footnoteRef?: React.RefObject<HTMLDivElement>
): { onClick: (forceExpand?: boolean) => void } => {
  const expandedNoteId = useAppSelector(selectExpandedNoteId)

  useOutsideClick({
    // @ts-ignore
    ref: footnoteRef,
    // Only detect outside click if the footnote is expanded
    enabled: Boolean(footnoteRef && expandedNoteId === noteId),
    handler(e) {
      // Ignore clicks on the footnote "activators"
      if (
        (e.target as HTMLElement)?.closest('[data-footnote-view]') ||
        (e.target as HTMLElement)?.closest('[data-footnote-label]')
      ) {
        return
      }
      toggleExpanded(false)
    },
  })

  const toggleExpanded = useCallback(
    (forceExpand?: boolean) => {
      const selection = editor.state.selection
      const isFormatMenuOpen = isTextSelection(selection) && !selection.empty
      const isFocusedInsideFootnote =
        document.activeElement?.closest('.footnote-popover') ||
        Boolean(document.activeElement?.closest('[data-in-editor-focus]')) // Prevent closing if you're in a drawer or menu
      const expand =
        forceExpand !== undefined ? forceExpand : expandedNoteId !== noteId

      // Don't collapse if you're actively editing and mouse out, need to use X
      if (isFocusedInsideFootnote) return

      // Don't expand if you have a formatting menu open
      if (expand && !isFormatMenuOpen) {
        setFootnoteExpandedDebounced(noteId, true)
      } else if (expand === false) {
        setFootnoteExpandedDebounced(noteId, false)
      }
      // If null, do nothing - the node was just created and hasn't been hovered yet
    },
    [editor.state.selection, expandedNoteId, noteId]
  )

  return {
    onClick: toggleExpanded,
  }
}
