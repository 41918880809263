import { Box, Flex, FlexProps, keyframes } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'

import { getThemeCSSVars } from 'modules/theming/styles/variables'
import { getThemeBase } from 'modules/theming/themeBases'
import { Theme } from 'modules/theming/types'
import { getContainerBackgroundColor } from 'modules/tiptap_editor/styles/containerStyles'
import { isPuppeteer } from 'utils/export'

import { CARD_BODY_CLASS, EXPAND_CARD_TRANSITION_TIME } from '../constants'
import { CardAttributes } from '../types'

const nestedCardTransition = keyframes`
  0% {
    margin-inline: 0;
  }
  100% {
    margin-inline: var(--nested-card-margin);
  }
`

// Make room for the card menu and the top edge of the card
const FIRST_CARD_PADDING = ['0.5rem', '2.5rem']

type Card2BodyProps = {
  attrs: CardAttributes
  isFullBleed: boolean
  isFirstCard: boolean
  isFocused: boolean
  isLastCard: boolean
  isNested: boolean
  isPresentMode: boolean
  isDarkOverride?: boolean
  isEditable: boolean
  isMobileDevice: boolean
  isPublished?: boolean
  isThumbnail?: boolean
  theme: Theme
  children: React.ReactNode
  showPresentBackdrop?: boolean
}

export const Card2Body: React.FC<Card2BodyProps> = ({
  attrs,
  isFullBleed,
  isNested,
  isPresentMode,
  isEditable,
  isMobileDevice,
  isFirstCard,
  isLastCard,
  isFocused,
  isDarkOverride,
  isThumbnail,
  isPublished,
  showPresentBackdrop,
  theme,
  children,
}) => {
  const { id: cardId, container } = attrs
  const baseTheme = getThemeBase(theme)
  const cardColor = getContainerBackgroundColor(theme, container)
  const { borderRadius: cardBorderRadius, ...themeCardStyles } = {
    // Make expanded nested cards stand out against their parent by using the same border/shadow they have when collapsed
    ...(isNested ? baseTheme.clickableSx : undefined),
    ...baseTheme.cardSx,
  }
  const isPresentModeTopLevel = isPresentMode && !isNested
  const themeCssVars = getThemeCSSVars(
    theme,
    isDarkOverride,
    container.background ? cardColor : undefined
  )

  const shouldMakeRoomForToolbar =
    !isMobileDevice && !isPresentMode && !isThumbnail

  const positionProps: FlexProps = isFullBleed
    ? {
        width: isNested ? 'var(--card-width)' : '100%',
      }
    : {
        mt:
          isFirstCard && shouldMakeRoomForToolbar && !isPublished
            ? FIRST_CARD_PADDING
            : undefined,
        mb:
          isLastCard && shouldMakeRoomForToolbar && !isEditable
            ? FIRST_CARD_PADDING
            : undefined,
        // See nestedCardTransition for the application of mx
        zIndex: isNested ? 1 : undefined, // Go over adjacent backgrounds
        width: `var(--card-width)`,
      }
  const presentModeProps: FlexProps = isPresentModeTopLevel
    ? {
        minW: showPresentBackdrop ? 'auto' : '100%', // Use minW to support transitioning between DOC/PRESENT modes
        maxW: 'var(--card-max-width)',
        minH: 'var(--card-min-height)', // Use minH to support transitioning between DOC/PRESENT modes
        my: 'auto',
      }
    : {
        minW: '0%', // Use minW to support transitioning between DOC/PRESENT modes
        minH: 'var(--card-min-height)', // Use minH to support transitioning between DOC/PRESENT modes
      }

  // Disable animations in PPT/PDF export
  const expandTransitionTime = isPuppeteer ? 0 : EXPAND_CARD_TRANSITION_TIME

  return (
    <Flex
      className={cx(CARD_BODY_CLASS)}
      data-card-body={cardId}
      data-selection-ring
      data-guider-highlight="card-body"
      data-content-reference // Used for drag previews
      data-hide-comments={isPresentMode && !showPresentBackdrop} // todo: consider making this its own page setting
      // Note: these animations can be overridden by the .disable-card-size-animations class in animations.scss
      transitionProperty="min-height, min-width, width, font-size, padding"
      transitionDuration={`${expandTransitionTime}ms`}
      fontSize="var(--font-size)"
      animation={
        isNested
          ? `${nestedCardTransition} ${expandTransitionTime}ms ease-out forwards`
          : undefined
      }
      position="relative"
      {...positionProps}
      {...presentModeProps}
      css={{
        // If we're full bleed, the override should trump the theme's card border radius
        '--card-border-radius': `var(--border-radius-override, ${cardBorderRadius})`,
        ...themeCssVars,
      }}
      justify="center" // On wide screens, keep the layout centered horizontally
      borderRadius="var(--card-border-radius)"
      pl={isFullBleed && !isNested ? 'var(--doc-padding-left)' : undefined}
      pr={isFullBleed && !isNested ? 'var(--doc-padding-right)' : undefined}
    >
      {/* Card color and glassiness. This needs to be its own div for the glassy effect with backdrop-filter: blur to work on nested cards. */}
      <Box
        position="absolute"
        inset="0"
        sx={themeCardStyles}
        borderRadius="inherit"
        contentEditable={false}
        zIndex={isPresentMode ? -1 : undefined} // Under the scrollbars
        outline={isFocused ? `2px solid var(--chakra-ring-color)` : undefined}
        outlineOffset={isFullBleed && !isPresentMode ? '-3px' : undefined}
      />
      {children}
    </Flex>
  )
}
