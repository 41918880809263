import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view'

import {
  CardLayoutResizingPluginKey,
  CardLayoutResizingState,
} from './CardLayoutResizingState'
import { handleMouseDown, handleMouseLeave, handleMouseMove } from './input'

export function createCardLayoutResizingPlugin() {
  const plugin = new Plugin({
    key: CardLayoutResizingPluginKey,
    state: {
      init() {
        return new CardLayoutResizingState()
      },
      apply(tr, resizeState, _oldEditorState, newEditorState) {
        return resizeState.apply(tr, newEditorState)
      },
    },
    props: {
      attributes(state) {
        const pluginState = CardLayoutResizingPluginKey.getState(state)
        const activeHandle = pluginState!.getActiveHandleAbs(state)
        return activeHandle !== null
          ? { class: 'resize-cursor' }
          : { class: '' }
      },

      handleDOMEvents: {
        mousemove(view: EditorView, event: MouseEvent) {
          handleMouseMove(view, event)
          return false
        },
        mouseleave(view: EditorView) {
          handleMouseLeave(view)
          return false
        },
        mousedown(view: EditorView, event: MouseEvent) {
          handleMouseDown(view, event)
          return false
        },
      },

      decorations(state) {
        const pluginState = CardLayoutResizingPluginKey.getState(state)!
        const activeHandle = pluginState.getActiveHandleAbs(state)
        if (activeHandle !== null) {
          const decorations: Decoration[] = []
          try {
            const dom = document.createElement('div')
            dom.className = `column-resize-handle ${pluginState.side}`

            decorations.push(Decoration.widget(activeHandle + 1, dom))
            const node = state.doc.nodeAt(activeHandle)!

            // add class to cardLayoutItem node to move the mt from :first-child to second
            // since the div.column-resize-handle is now the first child
            decorations.push(
              Decoration.node(activeHandle, activeHandle + node.nodeSize, {
                class: 'has-column-resize-handle',
              })
            )

            return DecorationSet.create(state.doc, decorations)
          } catch (e) {
            console.error(`(caught) cardLayoutResizing error: ${e.message}`)
            return DecorationSet.empty
          }
        }
        return
      },

      nodeViews: {},
    },
  })
  return plugin
}
