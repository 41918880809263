import { ColorButton } from 'modules/tiptap_editor/components/menus/buttons/ColorButton'

import { SmartLayoutOption, SmartLayoutOptionControlProps } from '../types'

const ColorOptionControl = ({
  value,
  updateValue,
  editor,
}: SmartLayoutOptionControlProps<string | null>) => {
  return (
    <ColorButton
      color={value}
      setColor={(color) => updateValue(color, true)}
      editor={editor}
      analyticsSource="SmartLayout"
    />
  )
}

export const ColorOption: SmartLayoutOption<string | null> = {
  key: 'color',
  Control: ColorOptionControl,
  name: () => 'Color',
}
