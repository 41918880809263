import { Editor, isNodeSelection } from '@tiptap/core'
import { GapCursor } from 'prosemirror-gapcursor'
import { Mark, Node, Slice } from 'prosemirror-model'
import { TextSelection } from 'prosemirror-state'

import { isNodeEmpty } from 'modules/tiptap_editor/utils'
import { isImageUrl, isValidUrl, startsWithHttp } from 'utils/link'
import { GAMMA_DOC_REGEX } from 'utils/url'

import { createSelectionNearLastTo } from '../../utils/selection/findSelectionNearOrGapCursor'
import { Link } from '../Link'
import { fetchUrlThenUpdateMarks, linkHasMetadata } from '../Link/utils'

// dont import gallery/utils for circular dependency reasons
const isGalleryNode = (node: Node) => node.type.name === 'gallery'

export const handleLinkPaste = (
  editor: Editor,
  event: ClipboardEvent,
  slice: Slice
) => {
  // Check if the slice contains links already (e.g. copying rich text from a webpage)
  // and if so, just fetch metadata asynchronously
  const links = findLinksInSlice(slice)
  if (links.length > 0) {
    links.forEach(({ mark }) => {
      if (linkHasMetadata(mark)) return
      fetchUrlThenUpdateMarks(mark.attrs.href, editor)
    })
    // Let other paste handlers run, the rest of this is only meant to capture a single link being pasted
    return false
  }

  // Check if the pasted content is a link
  const text = event.clipboardData?.getData('text/plain')
  if (!text || !textLooksLikeLink(text)) {
    return false
  }

  const { selection } = editor.state
  if (selection instanceof TextSelection && !selection.empty) {
    event.preventDefault()
    addMarkOnPaste(editor, text)
    return true
  }

  if (text.match(GAMMA_DOC_REGEX) || isImageUrl(text)) {
    // Let default handlers run input rules if it's a Gamma link
    return false
  }
  const isGapCursor = selection instanceof GapCursor
  const isEmptyTextblock =
    selection.empty &&
    ['paragraph', 'heading', 'title'].includes(
      selection.$from.parent.type.name
    ) &&
    isNodeEmpty(selection.$from.parent)
  const isInsideGallery = isGalleryNode(selection.$from.parent)
  const isGallerySelected =
    isNodeSelection(selection) && isGalleryNode(selection.node)
  if (
    !(isEmptyTextblock || isInsideGallery || isGallerySelected || isGapCursor)
  ) {
    return false
  }
  const parentPos = selection.from - 1
  const replaceRange = isGallerySelected
    ? { from: selection.to - 1, to: selection.to - 1 }
    : isEmptyTextblock
    ? { from: parentPos, to: parentPos + selection.$from.parent.nodeSize }
    : { from: selection.from, to: selection.to }

  // Immediately create an embed with this URL
  editor
    .chain()
    .setTextSelection(replaceRange)
    .insertEmbedAndFetchMetadata(text, undefined, true)
    .command(({ tr }) => {
      const sel = createSelectionNearLastTo(tr)
      if (sel) {
        tr.setSelection(sel)
      }
      return true
    })
    .run()
  return true
}

export const textLooksLikeLink = (text: string) =>
  startsWithHttp(text) && !text.includes('\n') && isValidUrl(text)

export const sliceLooksLikeLink = (slice: Slice) =>
  slice.content.childCount === 1 &&
  slice.content.firstChild!.isTextblock &&
  textLooksLikeLink(slice.content.firstChild!.textContent!)

// Replace Tiptap's pasteHandler https://github.com/ueberdosis/tiptap/blob/main/packages/extension-link/src/helpers/pasteHandler.ts
// which doesn't handle checking whether the pasted content can handle the mark (eg an image or gallery)
const addMarkOnPaste = (editor: Editor, text: string) => {
  editor.commands.setMark(Link.name, {
    href: text,
  })
  fetchUrlThenUpdateMarks(text, editor)
}

const findLinksInSlice = (slice: Slice) => {
  const links: { mark: Mark; pos: number }[] = []
  slice.content.descendants((node, pos) => {
    node.marks
      .filter((mark) => mark.type.name === 'link')
      .forEach((mark) => {
        links.push({ mark, pos })
      })
  })
  return links
}
