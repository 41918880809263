import { Editor } from '@tiptap/core'
import debounce from 'lodash/debounce'
import { Selection } from 'prosemirror-state'
import { useEffect, useState } from 'react'

// Simplified version of useSyncEditor to listen for selection changes
export const useSyncEditorSelection = (editor: Editor | undefined) => {
  const [selection, setSelection] = useState<Selection | null>(null)

  useEffect(() => {
    if (!editor) return

    const onSelectionUpdate = ({
      editor: editorInstance,
    }: {
      editor: Editor
    }) => {
      setSelection(editorInstance.state.selection)
    }

    onSelectionUpdate({ editor })

    const debouncedOnSelectionUpdate = debounce(onSelectionUpdate, 200, {
      leading: true,
    })

    editor.on('selectionUpdate', debouncedOnSelectionUpdate)

    return () => {
      editor.off('selectionUpdate', debouncedOnSelectionUpdate)
    }
  }, [editor])

  return {
    selection,
  }
}
