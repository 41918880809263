import { NodeViewProps } from '@tiptap/react'

import { NodeViewContent } from '../../react'
import { AnnotatableNodeViewWrapper } from '../Annotatable'

const counterStyleFns = [
  (n) => n.toString(),
  (n) => String.fromCharCode(96 + n),
  (n) => convertToRoman(n).toLowerCase(),
]

export const NumberedView = (nodeViewProps: NodeViewProps) => {
  const { decorations, node } = nodeViewProps
  const { indent, fontSize } = node.attrs
  const listNumber = decorations.find((d) => d.spec.listNumber)?.spec.listNumber

  const counterStyleFn =
    counterStyleFns[indent % counterStyleFns.length] || counterStyleFns[0]
  const counter = counterStyleFn(listNumber)

  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps}>
      <NodeViewContent
        as="li"
        data-list-counter={counter}
        data-font-size={fontSize}
      />
    </AnnotatableNodeViewWrapper>
  )
}

// https://stackoverflow.com/questions/9083037/convert-a-number-into-a-roman-numeral-in-javascript
function convertToRoman(num) {
  const roman = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  }
  let str = ''

  for (const i of Object.keys(roman)) {
    const q = Math.floor(num / roman[i])
    num -= q * roman[i]
    str += i.repeat(q)
  }

  return str
}
