import {
  ButtonGroup,
  Collapse,
  FlexProps,
  IconButton,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react'
import { DarkModeProvider } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { Editor } from '@tiptap/core'

import { useIsThemeDark } from 'modules/theming/hooks'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { CardLayout } from '../types'
import LayoutBehindIcon from './icons/layout-behind.svg'
import LayoutLeftIcon from './icons/layout-left.svg'
import LayoutRightIcon from './icons/layout-right.svg'
import LayoutTopIcon from './icons/layout-top.svg'

type ChooseLayoutPlaceholderProps = {
  isVisible: boolean
  setLayout: (l: CardLayout) => void
  editor: Editor
} & FlexProps

export const ChooseLayoutPlaceholder: React.FC<ChooseLayoutPlaceholderProps> =
  ({ isVisible, setLayout, editor }) => {
    const isDark = useIsThemeDark()
    const invertFilter = isDark ? 'invert(.8) brightness(1.2)' : 'invert(0)'

    return (
      <DarkModeProvider isDark={isDark}>
        <Collapse
          in={isVisible}
          onAnimationComplete={() => {
            editor.commands.refreshBubbleMenu?.()
          }}
        >
          <Stack
            overflow="hidden"
            mx={16}
            mb={12}
            contentEditable={false}
            onMouseDown={preventDefaultToAvoidBlur}
            cursor="default"
          >
            <Text
              color="var(--body-color-muted)"
              fontSize="sm"
              fontFamily="Inter"
              fontWeight="normal"
            >
              <Trans>Or choose a layout</Trans>
            </Text>
            <ButtonGroup spacing={1}>
              <IconButton
                aria-label={t`Set layout to top`}
                h="auto"
                p={1}
                variant="ghost"
                onClick={() => setLayout('top')}
                icon={<Image src={LayoutTopIcon.src} filter={invertFilter} />}
              />
              <IconButton
                aria-label={t`Set layout to left`}
                h="auto"
                p={1}
                variant="ghost"
                onClick={() => setLayout('left')}
                icon={<Image src={LayoutLeftIcon.src} filter={invertFilter} />}
              />
              <IconButton
                aria-label={t`Set layout to right`}
                h="auto"
                p={1}
                variant="ghost"
                onClick={() => setLayout('right')}
                icon={<Image src={LayoutRightIcon.src} filter={invertFilter} />}
              />
              <IconButton
                aria-label={t`Set layout to behind`}
                h="auto"
                p={1}
                variant="ghost"
                onClick={() => setLayout('behind')}
                icon={
                  <Image src={LayoutBehindIcon.src} filter={invertFilter} />
                }
              />
            </ButtonGroup>
          </Stack>
        </Collapse>
      </DarkModeProvider>
    )
  }
