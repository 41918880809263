import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'

import { FeatureFlags } from 'modules/featureFlags'
import { NodeInsertMethods } from 'modules/segment'
import { areListNodesSelected } from 'modules/tiptap_editor/extensions/lists/utils'
import { ListToSmartLayoutCommands } from 'modules/tiptap_editor/extensions/SmartLayout/commands'

import { getCommandsMap, trackItemInserted } from '../../../commands'
import { canWrapSelection } from '../../../utils'
import { TextConvertCommand } from './types'

type ConvertGroup = {
  label: string
  featureFlag?: keyof FeatureFlags
}

export const getConvertCommandGroups = (): Record<
  TextConvertCommand['group'],
  ConvertGroup
> => {
  return {
    list: { label: t`Transform list` },
    wrap: { label: t`Wrap inside` },
  }
}

export const getTextConvertCommands = (): TextConvertCommand[] => {
  const COMMANDS_MAP = getCommandsMap()
  return [
    {
      key: 'toggle',
      name: () => t`Toggle`,
      icon: regular('play'),
      node: 'toggle',
      description: () =>
        t`Turn this into a collapsible section. Highlight key points and hide details`,
      apply: (editor) => {
        trackItemInserted(
          COMMANDS_MAP.toggle,
          NodeInsertMethods.FORMATTING_MENU
        )
        editor.chain().wrapWithToggle().run()
      },
      group: 'wrap',
    },
    {
      key: 'convertToNestedCard',
      name: () => t`Nested card`,
      node: 'card',
      description: () =>
        t`Put this content into its own card. Good for bigger, self-contained ideas`,
      icon: regular('diagram-subtask'),
      apply: (editor) => {
        trackItemInserted(
          COMMANDS_MAP.insertCardInside,
          NodeInsertMethods.FORMATTING_MENU
        )
        editor.chain().convertToNestedCard().run()
      },
      checkDisabled: (editor) =>
        !editor.schema.nodes.card || !canWrapSelection(editor, 'card'),
      group: 'wrap',
    },
    {
      key: 'blockquote',
      name: () => t`Blockquote`,
      node: 'blockquote',
      description: () => t`Pull out quotes or text for emphasis`,
      icon: regular('block-quote'),
      shortcut: '> Quote',
      apply: (editor) => editor.chain().wrapWithAnnotations('blockquote').run(),
      checkDisabled: (editor) => !canWrapSelection(editor, 'blockquote'),
      group: 'wrap',
    },
    {
      key: 'calloutBox',
      name: () => t`Info box`,
      node: 'calloutBox',
      description: () => t`Call out important info with a box and icon`,
      icon: regular('info-circle'),
      apply: (editor) =>
        editor
          .chain()
          .wrapWithAnnotations('calloutBox', { variant: 'info' })
          .run(),
      checkDisabled: (editor) => !canWrapSelection(editor, 'calloutBox'),
      group: 'wrap',
    },
    {
      key: 'listToCards',
      name: () => t`Cards from outline`,
      icon: regular('rectangle-history'),
      description: () => t`Turn bullet points into cards`,
      group: 'list',
      apply: (editor) => editor.commands.listToCards(),
      checkDisabled: (editor) => !areListNodesSelected(editor),
    },
    ...ListToSmartLayoutCommands,
  ]
}
