import { Spinner, Text } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React, { Suspense } from 'react'

type EmojiToastProps = {
  isMobile: boolean
  emoji: string
}

export const EmojiToast: React.FC<EmojiToastProps> = ({ isMobile, emoji }) => {
  const reactionAddedString = t`reaction added`
  return (
    <Suspense fallback={<Spinner size="sm" />}>
      <Text
        as="span"
        sx={{
          '.emoji-mart-emoji': {
            fontSize: '1em', // Get the font size from the surrounding Text
            span: {
              cursor: 'inherit',
              fontSize: '1em !important',
            },
          },
        }}
      >
        {/** Emoji.size is expected to be a number. We override it above as 1em */}
        <em-emoji size={0} native={true} id={emoji} /> {reactionAddedString}.
      </Text>
    </Suspense>
  )
}
