import { duotone, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'

import { CommandInfo } from 'modules/tiptap_editor/commands/types'
import { AUTOCOMPLETE_INPUT_SHORTCUT } from 'modules/tiptap_editor/extensions/AI/Autocomplete/constants'

export const AICommands: CommandInfo[] = [
  {
    key: 'autoComplete',
    name: () => t`Continue writing with AI`,
    shortcut: AUTOCOMPLETE_INPUT_SHORTCUT,
    nodeName: 'paragraph',
    icon: solid('sparkle'),
    keywords: [
      'continue',
      'autocomplete',
      'typeahead',
      'complete',
      'continue',
      'ai',
    ],
    execute: (editor) => {
      editor.commands.autoComplete()
    },
    featureFlag: 'aiAutocomplete',
    creditType: 'autocomplete',
  },
]
