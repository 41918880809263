import { Alert, AlertDescription, AlertIcon, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

import { MediaProviderPanelProps } from 'modules/media/types/MediaProvider'
import { useAppSelector } from 'modules/redux'
import { isThemeDark } from 'modules/theming'
import { AccentImageGrid } from 'modules/theming/components/AccentImageGrid'
import { Theme } from 'modules/theming/types'
import { selectTheme } from 'modules/tiptap_editor/reducer'

export const AccentImagePanel = ({
  currentAttributes,
  updateAttributes,
}: MediaProviderPanelProps) => {
  const theme: Theme = useAppSelector(selectTheme)
  const { accentBackgrounds } = theme.config

  return (
    <VStack align="stretch">
      {accentBackgrounds && (
        <AccentImageGrid
          currentAttributes={currentAttributes}
          accentImages={accentBackgrounds}
          isDark={isThemeDark(theme)}
          handleItemClick={updateAttributes}
        />
      )}

      <Alert status="info">
        <AlertIcon />
        <AlertDescription>
          <Trans>Accent images can be managed in the theme editor.</Trans>
        </AlertDescription>
      </Alert>
    </VStack>
  )
}
