import { Editor, findChildren } from '@tiptap/core'
import isEqual from 'lodash/isEqual'

import { useAppSelector } from 'modules/redux'
import { EditorModeEnum } from 'modules/tiptap_editor/types'
import { findParentNodes } from 'modules/tiptap_editor/utils'

import { selectLocalCollaboratorSpotlight, selectMode } from '../../reducer'
import { BackgroundOptions } from '../../styles/types'
import { isCardNode } from '../Card/utils'

export const usePresentingCardBackground = (editor: Editor) => {
  return useAppSelector<BackgroundOptions | null>((state) => {
    // Bail early in doc mode
    const mode = selectMode(state)
    if (mode === EditorModeEnum.DOC_VIEW) {
      return null
    }

    const localSpotlight = selectLocalCollaboratorSpotlight(state)
    if (!localSpotlight || !localSpotlight?.cardId) {
      return null
    }

    const [presentingCard] = findChildren(
      editor.state.doc,
      (n) => isCardNode(n) && n.attrs.id === localSpotlight.cardId
    )

    if (!presentingCard) {
      console.warn(
        `[usePresentingCardBackground] Unexpectedly couldnt find presentingCard ${localSpotlight.cardId}`
      )
      return null
    }

    const cardNode = findParentNodes(
      editor.state.doc.resolve(presentingCard.pos + 1),
      (n) => isCardNode(n) && n.attrs.background && !n.attrs.background.inside
    ).pop() // Nested cards inherit

    if (!cardNode) {
      console.warn(
        `[usePresentingCardBackground] Unexpectedly couldnt find card at pos ${presentingCard.pos}`
      )
      return null
    }

    return cardNode.node.attrs.background
  }, isEqual)
}
