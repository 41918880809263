import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'

import { ToolbarButton } from 'modules/tiptap_editor/components/menus/buttons/ToolbarButton'

import { SmartLayoutOption, SmartLayoutOptionControlProps } from '../types'

const HasLineOptionControl = ({
  value,
  updateValue,
  option,
}: SmartLayoutOptionControlProps<boolean>) => {
  return (
    <ToolbarButton
      label={option.name()}
      onClick={() => updateValue(!value)}
      isActive={value}
      icon={regular('hyphen')}
    />
  )
}

export const HasLineOption: SmartLayoutOption<boolean> = {
  key: 'hasLine',
  defaultValue: true,
  Control: HasLineOptionControl,
  name: () => t`Show line?`,
}
