import { useMemo } from 'react'

import { useAppSelector } from 'modules/redux'
import { getThemeCSSVars } from 'modules/theming/styles/variables'
import { getThemeBase } from 'modules/theming/themeBases'
import { selectTheme } from 'modules/tiptap_editor/reducer'

import { SmartLayoutAttrs, SmartLayoutCellAttrs } from '../types'

export const useCellColor = (
  layoutOptions: SmartLayoutAttrs['options'],
  cellOptions?: SmartLayoutCellAttrs['options']
) => {
  const theme = useAppSelector(selectTheme)
  const base = getThemeBase(theme)
  const color: string | undefined = cellOptions?.color ?? layoutOptions.color
  const colorVars = useMemo(() => {
    if (!color || base.hasTransparentSmartLayoutBoxes) return undefined
    return getThemeCSSVars(theme, undefined, undefined, color)
  }, [theme, color, base.hasTransparentSmartLayoutBoxes])
  return { color, colorVars }
}
