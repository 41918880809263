import { AvatarGroup, Flex, Text } from '@chakra-ui/react'
import { AvatarOverflowList, TooltipAvatar } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { NodeViewProps } from '@tiptap/react'
import { useMemo } from 'react'

import { config } from 'config'
import { formatDateRelative } from 'modules/i18n/utils/date'
import { useAppSelector } from 'modules/redux'
import { ThumbnailSkeleton } from 'modules/tiptap_editor/components/thumbnail'
import { NodeViewWrapper } from 'modules/tiptap_editor/react/NodeViewWrapper'
import { selectDoc, selectDocEditors } from 'modules/tiptap_editor/reducer'
import { useGammaBreakpointValue } from 'utils/breakpoints/useGammaBreakpointValue'
import { useSSRMounted } from 'utils/hooks'

import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { useCurrentTime } from './useCurrentTime'

export const ContributorsView = (nodeViewProps: NodeViewProps) => {
  const MAX_EDITORS =
    useGammaBreakpointValue({
      base: 4,
      md: 8,
    }) || 8

  const ssrMounted = useSSRMounted()
  const contributors = useAppSelector(selectDocEditors) || []
  const doc = useAppSelector(selectDoc)
  const lastEditedTimeToDisplay = doc?.editedTime
  const currTime = useCurrentTime(lastEditedTimeToDisplay).toISOString()
  const overflowCount = contributors.length - MAX_EDITORS
  const horizontalAlign = nodeViewProps.node.attrs.horizontalAlign ?? 'left'
  const flexDirection = useMemo(
    () =>
      ({
        left: 'row',
        center: 'column',
        right: 'row-reverse',
      }[horizontalAlign]),
    [horizontalAlign]
  )

  return (
    <>
      {contributors.length > 0 && (
        <AnnotatableNodeViewWrapper {...nodeViewProps} as="div">
          <Flex
            userSelect="none"
            contentEditable={false}
            justify="flex-start"
            align="center"
            direction={flexDirection}
            borderRadius="var(--box-border-radius)"
            data-selection-ring="padded"
            data-selection-background
          >
            <AvatarGroup
              sx={{
                '.avatar-overflow-activator': {
                  height: '1.6em',
                  width: '1.6em',
                  minH: '1.6em',
                  minW: '1.6em',
                  fontSize: 'inherit',
                  span: {
                    fontSize: '0.68em',
                  },
                },
              }}
            >
              {contributors.slice(0, MAX_EDITORS).map((contributor) => (
                <TooltipAvatar
                  // width/height/fontSize props needed for zooming in the editor to work
                  width="1.6em"
                  height="1.6em"
                  fontSize="inherit"
                  key={contributor.user.id}
                  shadow="base"
                  sx={{
                    '@media print': {
                      shadow: 'none',
                    },
                  }}
                  borderWidth="1px"
                  name={
                    ssrMounted
                      ? t`${
                          contributor.user.displayName
                        } edited ${formatDateRelative(
                          contributor.lastEdited,
                          currTime
                        )} ago`
                      : ' '
                  }
                  src={contributor.user.profileImageUrl}
                />
              ))}
              {overflowCount > 0 && (
                <AvatarOverflowList
                  tooltipLabel={t`See everyone`}
                  count={overflowCount}
                  // Map over contributors to match the data to avatar's expected data shape
                  avatars={contributors.map((c) => ({
                    name: c.user.displayName || '',
                    id: c.user.id,
                    profileImageUrl: c.user.profileImageUrl || '',
                    color: 'white',
                  }))}
                  disabled={true}
                />
              )}
            </AvatarGroup>
            <Flex
              direction={'column'}
              ml={horizontalAlign === 'left' ? '0.5em' : 0}
              mr={horizontalAlign === 'right' ? '0.5em' : 0}
              lineHeight="1.4"
              textAlign={horizontalAlign}
            >
              <Text className="contributors-list">
                {contributors.length === 1 ? (
                  <Trans>by {contributors[0].user.displayName}</Trans>
                ) : (
                  <Trans>{contributors.length} Contributors</Trans>
                )}
              </Text>
              {!config.GAMMA_PUPPETEER_SERVICE && lastEditedTimeToDisplay && (
                <Text fontSize="0.75em">
                  {ssrMounted ? (
                    <Trans>
                      Last edited{' '}
                      {formatDateRelative(lastEditedTimeToDisplay, currTime)}{' '}
                      ago
                    </Trans>
                  ) : (
                    ' '
                  )}
                </Text>
              )}
            </Flex>
          </Flex>
        </AnnotatableNodeViewWrapper>
      )}
    </>
  )
}

export const ContributorsThumbnailView = (nodeViewProps: NodeViewProps) => {
  const horizontalAlign = nodeViewProps.node.attrs.horizontalAlign ?? 'left'
  const flexDirection = useMemo(
    () =>
      ({ left: 'row', center: 'column', right: 'row-reverse' }[
        horizontalAlign
      ]),
    [horizontalAlign]
  )

  return (
    <NodeViewWrapper>
      <Flex direction={flexDirection} alignItems="center" gap="1em">
        <ThumbnailSkeleton borderRadius="full" boxSize="4em" />
        <ThumbnailSkeleton h="2em" w="14em" />
      </Flex>
    </NodeViewWrapper>
  )
}
