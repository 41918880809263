import { memoize } from 'lodash'
import { useEffect, useRef, useState } from 'react'

type ObserverRect = Omit<DOMRectReadOnly, 'toJSON'>

type RectCb = (rect: ObserverRect) => void

class ElementResizeObserver {
  private observedElements: Map<Element, RectCb> = new Map()

  private nextTickCallbacks: Map<Element, () => void> = new Map()

  private frameId: ReturnType<typeof requestAnimationFrame> | null = null

  observer: ResizeObserver

  constructor() {
    this.observer = new ResizeObserver((entries) => this.handleResize(entries))
  }

  observe(el: Element, cb: RectCb) {
    this.observer.observe(el)
    this.observedElements.set(el, cb)
  }

  unobserve(el: Element) {
    this.observer.unobserve(el)
    this.observedElements.delete(el)
    this.nextTickCallbacks.delete(el)
  }

  private handleResize(entries: ResizeObserverEntry[]) {
    let shouldProcess = false
    for (const entry of entries) {
      const callback = this.observedElements.get(entry.target)
      if (callback) {
        shouldProcess = true
        this.nextTickCallbacks.set(entry.target, () => {
          callback(entry.contentRect)
        })
      }
    }

    if (shouldProcess) {
      this.queueProcess()
    }
  }

  private queueProcess() {
    if (this.frameId) {
      cancelAnimationFrame(this.frameId)
      this.frameId = null
    }

    this.frameId = requestAnimationFrame(() => {
      for (const fn of this.nextTickCallbacks.values()) {
        fn()
      }
      this.frameId = null
    })
  }
}

export const getElementResizeObserver = memoize(
  () => new ElementResizeObserver()
)

const defaultState: ObserverRect = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
}

export function useElementSize<T extends HTMLElement = any>() {
  const ref = useRef<T>(null)
  const [{ width, height }, setRect] = useState<ObserverRect>(defaultState)

  useEffect(() => {
    const el = ref.current
    if (el) {
      getElementResizeObserver().observe(el, setRect)
    }

    return () => {
      if (el) {
        getElementResizeObserver().unobserve(el)
      }
    }
  }, [])

  return { ref, width, height }
}
