import { Extension } from '@tiptap/core'
import isPlainObject from 'lodash/isPlainObject'

import { DocumentAttributes } from 'modules/tiptap_editor/extensions/Document/DocumentAttrs/attributes'

type AttributesAtPos = {
  0: DocumentAttributes
  [key: string]: Record<string, any>
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    updateAttributesAtPos: {
      updateAttributesAtPos: <I extends number>(
        pos: I,
        attrs: Partial<AttributesAtPos[I]>
      ) => ReturnType // When pos is 0, attrs should be DocumentAttrs
    }
    updateNestedAttributesAtPos: {
      updateNestedAttributesAtPos: <I extends number>(
        pos: number,
        attrs: Partial<AttributesAtPos[I]>
      ) => ReturnType // For other pos values, attrs can be Record<string, any>
    }
  }
}

export const UpdateAttributes = Extension.create({
  name: 'updateAttributesAtPos',

  addCommands() {
    return {
      updateAttributesAtPos:
        (pos: number, attrs) =>
        ({ tr }) => {
          Object.entries(attrs).forEach(([key, val]) => {
            tr.setNodeAttribute(pos, key, val)
          })
          return true
        },
      // Update attributes by merging in their existing value first
      // Used for attrs like background that contain multiple properties
      // Note: this is only a "shallow" patch (one level deep)
      updateNestedAttributesAtPos:
        (pos, attrs) =>
        ({ tr }) => {
          const node = tr.doc.nodeAt(pos)
          if (!node) {
            return false
          }
          Object.entries(attrs).forEach(([key, val]) => {
            const patchVal = isPlainObject(val)
              ? {
                  ...node.attrs[key],
                  ...val,
                }
              : val
            tr.setNodeAttribute(pos, key, patchVal)
          })
          return true
        },
    }
  },
})
