import memoize from 'fast-memoize'

import {
  getContainerBackgroundColor,
  getContainerOptions,
} from 'modules/tiptap_editor/styles/containerStyles'
import {
  colorWithLightness,
  colorWithOpacity,
  isColorDark,
  lightenColor,
  makeColorReadable,
} from 'utils/color'

import {
  generateLinearGradientCSS,
  lightenedGradient,
  readableGradient,
} from '../components/LinearGradientPicker/LinearGradientPicker'
import {
  DEFAULT_BODY_COLOR,
  DEFAULT_BODY_COLOR_DARK,
  DEFAULT_FONTS,
  DEFAULT_HEADING_COLOR,
  DEFAULT_HEADING_COLOR_DARK,
} from '../constants'
import { getThemeBase } from '../themeBases'
import { Theme } from '../types'
import { getFontName, getFontWeight } from '../utils/fonts'
import {
  getAccentColor,
  getAccentColorPalette,
  isThemeDark,
} from '../utils/utils'

const DEFAULT_CONTRAST_RATIO = 4.5 // WCAG2 standard
const CONTRAST_RATIO_CHANGED = 6 // A higher ratio if you change the card color
const CONTRAST_RATIO_HEADING = 3 // Headings are bigger so we can tolerate less contrast
const CONTRAST_RATIO_CHANGED_HEADING = 4.5

// Don't use this function directly - use the memoized getThemeCSSVars below
// In Card1, you can override a card to be light/dark
// In Card2, you can override it to a custom color
const generateThemeCSSVars = (
  theme: Theme,
  isDarkOverride?: boolean,
  cardColorOverride?: string,
  boxColorOverride?: string
) => {
  const themeContainer = getContainerOptions(theme)
  const { bodyFont, headingFont, fonts } = theme
  // Retrieve font names
  const bodyFontName = getFontName(fonts, bodyFont) || DEFAULT_FONTS.bodyFont
  const headingFontName =
    getFontName(fonts, headingFont) || DEFAULT_FONTS.headingFont
  const accentColor = getAccentColor(theme)
  const isDark =
    isDarkOverride ??
    (boxColorOverride ? isColorDark(boxColorOverride) : undefined) ??
    (cardColorOverride ? isColorDark(cardColorOverride) : isThemeDark(theme))

  const {
    bodyColor = isDark ? DEFAULT_BODY_COLOR_DARK : DEFAULT_BODY_COLOR,
    headingColor = isDark ? DEFAULT_HEADING_COLOR_DARK : DEFAULT_HEADING_COLOR,
    headingGradient,
    accentGradient,
    disableReadabilityAdjustment,
  } = theme.config

  const cardColor =
    cardColorOverride ??
    getContainerBackgroundColor(theme, {
      ...themeContainer,
      isDark,
    })

  const isColorOverride =
    isDarkOverride !== undefined ||
    cardColorOverride !== undefined ||
    boxColorOverride !== undefined
  const shouldAdjustReadability =
    !disableReadabilityAdjustment || isColorOverride
  const contrastRatio = isColorOverride
    ? CONTRAST_RATIO_CHANGED
    : DEFAULT_CONTRAST_RATIO
  // Headings and links use a lower ratio to preserve the brand
  const headingContrastRatio = isColorOverride
    ? CONTRAST_RATIO_CHANGED_HEADING
    : CONTRAST_RATIO_HEADING
  const contrastColor = boxColorOverride ?? cardColor

  const linkColor = !shouldAdjustReadability
    ? accentColor
    : makeColorReadable(accentColor, contrastColor, headingContrastRatio, false)
  const bodyColorReadable = !shouldAdjustReadability
    ? bodyColor
    : makeColorReadable(
        bodyColor,
        contrastColor,
        contrastRatio,
        isColorOverride
      )
  const headingColorReadable = !shouldAdjustReadability
    ? headingColor
    : makeColorReadable(
        headingColor,
        contrastColor,
        headingContrastRatio,
        isColorOverride
      )
  const accentPalette = getAccentColorPalette(theme).map((color) =>
    !shouldAdjustReadability
      ? color
      : makeColorReadable(color, contrastColor, contrastRatio, false)
  )
  const themeBase = getThemeBase(theme)
  const baseCSSVars =
    themeBase.getCSSVars?.({
      cardColor,
      accentColor,
      boxColor: boxColorOverride,
      accentPalette,
    }) || {}

  return {
    // Fonts
    '--body-font': `"${bodyFontName}"`,
    '--heading-font': `"${headingFontName}"`,
    '--heading-font-weight': getFontWeight(theme, 'heading'),
    '--body-font-weight': getFontWeight(theme, 'body'),

    // Colors
    '--accent-color': accentColor,
    '--accent-gradient-background': generateLinearGradientCSS(
      lightenedGradient(accentGradient, isDark ? 0.25 : 0.9)
    ),
    '--accent-color-background': isDark
      ? colorWithLightness(accentColor, 0.25)
      : colorWithLightness(accentColor, 0.9),
    '--accent-color-border': isDark
      ? colorWithLightness(accentColor, 0.3)
      : colorWithLightness(accentColor, 0.8),

    '--accent-color-background-muted': isDark
      ? colorWithLightness(accentColor, 0.15)
      : colorWithLightness(accentColor, 0.95),
    '--accent-color-100': colorWithLightness(accentColor, 0.9),
    '--accent-color-500': colorWithLightness(accentColor, 0.5),
    '--accent-gradient': generateLinearGradientCSS(
      !shouldAdjustReadability
        ? accentGradient
        : readableGradient(
            accentGradient,
            contrastColor,
            headingContrastRatio,
            false
          )
    ),
    '--expandable-hover-background': colorWithOpacity(linkColor, 0.1),

    '--link-color': linkColor,
    '--link-color-hover': lightenColor(linkColor, -10),
    '--body-color': bodyColorReadable,
    '--body-color-muted': colorWithOpacity(bodyColorReadable, 0.5),
    '--body-color-inverted': isDark ? '#000' : '#fff',
    '--link-color-inverted': isColorDark(linkColor) ? '#fff' : '#000',
    '--highlight-color-dark': isDark
      ? 'var(--body-color)'
      : 'var(--body-color-inverted)',
    '--highlight-color-light': isDark
      ? 'var(--body-color-inverted)'
      : 'var(--body-color)',
    '--heading-color': headingColorReadable,
    '--heading-gradient': generateLinearGradientCSS(
      !shouldAdjustReadability
        ? headingGradient
        : readableGradient(
            headingGradient,
            contrastColor,
            headingContrastRatio,
            isColorOverride
          )
    ),
    // The background color of the nearest containing block. Blocks within the card like columns might override this.
    '--card-color': cardColor,
    '--card-color-mask': colorWithOpacity(
      cardColor,
      isColorDark(cardColor) ? 0.8 : 0.85
    ),
    '--link-box-shadow': `0 0 0px 3px ${colorWithOpacity(linkColor, 1)}`,
    ...baseCSSVars,
  }
}

export const getThemeCSSVars = memoize(generateThemeCSSVars)
