import { cx } from '@chakra-ui/utils'
import { NodeViewProps } from '@tiptap/react'

import { HEADING_CLASS } from 'modules/theming/styles/heading'
import { AnnotatableNodeViewWrapper } from 'modules/tiptap_editor/extensions/Annotatable'
import { NodeViewContent } from 'modules/tiptap_editor/react'
import { docTitleOrPlaceholder } from 'utils/nouns'

import { getAlignStyles } from '../HorizontalAlign/HorizontalAlign'

export const TitleView = (nodeViewProps: NodeViewProps) => {
  const { node } = nodeViewProps
  const { horizontalAlign } = node.attrs
  return (
    <AnnotatableNodeViewWrapper
      {...nodeViewProps}
      style={{
        display: 'flex',
        flexDirection: 'column',
        ...getAlignStyles(horizontalAlign),
      }}
    >
      <NodeViewContent
        className={cx('title', HEADING_CLASS)}
        placeholder={docTitleOrPlaceholder()}
        style={{
          minWidth: 'min(100%, 8em)', // Ensure room for placeholder
        }}
      />
    </AnnotatableNodeViewWrapper>
  )
}
