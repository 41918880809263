import { Link } from '@chakra-ui/react'
import { useMemo } from 'react'

import { ThemeFont } from 'modules/api'
import { useShouldUsePublishedVersion } from 'modules/sites/PublishingContext'
import EudoxusFontLinks from 'scss/fonts/Eudoxus-Sans'

import { Theme } from '../types'

interface FontLoaderFont extends ThemeFont {}

type FontLoaderProps = {
  fonts?: FontLoaderFont[]
}

export const FontLoader = ({ fonts }: FontLoaderProps) => {
  const shouldUsePublishedVersion = useShouldUsePublishedVersion()
  const hasEudoxus = fonts?.find((f) => f.id === 'Eudoxus Sans')
  const links = useMemo(() => {
    return fonts?.filter((f) => f.url) || []
  }, [fonts])

  return (
    <>
      {hasEudoxus ? (
        <EudoxusFontLinks
          shouldUsePublishedVersion={shouldUsePublishedVersion}
        />
      ) : null}
      {links.map((font) => (
        <Link
          as="link"
          key={font.id}
          href={font.url}
          rel="stylesheet"
          data-gamma-font={font.id}
        />
      ))}
    </>
  )
}

type ThemeFontLoaderProps = {
  theme: Theme
}

export const ThemeFontLoader = ({ theme }: ThemeFontLoaderProps) => {
  return <FontLoader fonts={theme?.fonts} />
}
