import { ChatMessage, ChatRole } from 'modules/api'

import { ChatCompletionParams } from '../openai'
import { BaseChatCompletionPrompt } from './BaseChatCompletionPrompt'

export class FrontendChatCompletionPrompt<
  T extends string = string
> extends BaseChatCompletionPrompt<T> {
  protected variables: T[]
  protected systemPrompt: string
  protected userPrompt: string

  constructor({
    variables,
    systemPrompt,
    userPrompt,
    params: params,
  }: {
    variables: T[]
    params?: ChatCompletionParams
    systemPrompt: string
    userPrompt: string
  }) {
    super({ params: params })
    this.variables = variables
    this.systemPrompt = systemPrompt
    this.userPrompt = userPrompt
  }

  getSystemChatMessage(variables: Record<T, any>): ChatMessage {
    return {
      role: ChatRole.System,
      content: injectVariables(this.systemPrompt, variables),
    }
  }

  getUserChatMessage(variables: Record<T, any>): ChatMessage {
    return {
      role: ChatRole.User,
      content: injectVariables(this.userPrompt, variables),
    }
  }
}

function injectVariables(
  template: string,
  variables: { [key: string]: any }
): string {
  let result = template
  for (const key in variables) {
    result = result.replaceAll(`{{${key}}}`, variables[key])
  }
  return result
}
