import { Box } from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { memo } from 'react'

export const CardLinkIcon = memo(
  ({ isLocked, isDark = false }: { isLocked: boolean; isDark?: boolean }) => {
    return (
      <Box as="span" className="fa-layers fa-fw">
        <FontAwesomeIcon icon={regular('link')} />
        {isLocked && (
          <>
            <FontAwesomeIcon
              icon={solid('circle')}
              color={isDark ? 'var(--chakra-colors-gray-900)' : 'white'}
              transform="shrink-5 right-6 down-4"
            />
            <FontAwesomeIcon
              icon={solid('lock')}
              transform="shrink-8 right-6 down-4"
            />
          </>
        )}
      </Box>
    )
  }
)

CardLinkIcon.displayName = 'CardLinkIcon'
