import { JSONContent } from '@tiptap/core'
import { Schema, Slice } from 'prosemirror-model'

import { migrateNativeEmojisInJSONContent } from 'modules/tiptap_editor/extensions/Emoji/migrate'

import { splitCards, SplitOptions } from './splitCards'

export const transformOutsidePastedContent = (
  slice: Slice,
  schema: Schema,
  splitCardOptions?: SplitOptions
): Slice => {
  let content = slice.toJSON()?.content
  migrateNativeEmojisInJSONContent(content)

  // no content
  if (!content || content.length === 0) {
    return slice
  }

  if (
    content.length === 1 &&
    (content[0].type === 'document' || content[0].type === 'card')
  ) {
    // This is an internal paste (Gamma -> Gamma), so don't do anything
    return slice
  }

  if (
    // If all nodes are inline (e.g a few spans of text), we don't want to try splitting cards
    // and our fixTopLevelInlineNodes would definitely break.
    content.every((node) => schema.nodes[node.type].isInline)
  ) {
    // create new slice as migrateNativeEmojisFromJSONContent mutates the content
    return Slice.fromJSON(schema, { ...slice, content })
  }

  try {
    content = fixTopLevelInlineNodes(content, schema)
    content = fixFootnoteLineBreaks(content)
    if (splitCardOptions) {
      content = splitCards(content, splitCardOptions)
    }
    return Slice.fromJSON(schema, { ...slice, content })
  } catch (err) {
    console.error('Error transforming slice', err)
    return slice
  }
}

// Tools like Google Docs have block elements like paragraphs inside footnotes,
// which confuses ProseMirror's HTML parser: putting a paragraph inside a paragraph
// will break the first paragraph. This cleans up those broken paragraphs by
// detecting cases where a footnote is the first node of a new block and merging
// it back into the previous block.
export const fixFootnoteLineBreaks = (
  content: JSONContent[]
): JSONContent[] => {
  const newContent: (JSONContent | null)[] = [...content]
  // Loop through content
  content.forEach((node, i) => {
    if (i < 1) return
    const prev = content[i - 1]
    if (prev.type !== node.type || !prev.content) return
    const firstChild = node.content?.[0]
    if (!firstChild || firstChild.type !== 'footnote') return
    // Merge with previous
    prev.content = prev.content.concat(node.content!)
    newContent[i] = null
  })
  return newContent.filter((node) => node !== null) as JSONContent[]
}

// Sometimes when we parse from Google Docs, we find stray spans or other inline
// elements that aren't in their parent block. This can happen when a footnote or
// comment breaks a block. This catches the stragglers and merges them back into
// their original block, or throws them out if no parent block can be found.
export const fixTopLevelInlineNodes = (
  content: JSONContent[],
  schema: Schema
): JSONContent[] => {
  const blocks: JSONContent[] = []
  content.forEach((node) => {
    if (!node.type) return
    const isBlock = schema.nodes[node.type]?.isBlock
    if (isBlock) {
      // Add blocks to the list
      blocks.push(node)
    } else {
      // If we find inline elements, add them into the previous block
      const lastBlock = blocks[blocks.length - 1]
      // If there is no previous textblock, just throw out these nodes
      if (
        !lastBlock ||
        !lastBlock.content ||
        !schema.nodes[lastBlock.type!].isTextblock
      ) {
        return
      }
      if (node.type === 'text' && node.text?.trim() === '') {
        // node has no content
        // this can happen when we translate \n or \r characters into `' '` where
        // parseExternalHtml preserveWhitespace option is false
        return
      }
      lastBlock.content.push(node)
    }
  })
  return blocks
}
