import {
  Box,
  Button,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { useEffect, useRef, useState } from 'react'

import { useAppSelector } from 'modules/redux'
import { selectSite } from 'modules/sites/reducer'
import { isOtherSupportedProtocol } from 'modules/tiptap_editor/extensions/Link/utils'
import { isValidUrl, startsWithHttp } from 'utils/link'
import { generateDocUrl } from 'utils/url'

import { getLinkablePages } from './utils'

const PageItem = ({
  title,
  docId,
  updateHref,
}: {
  title: PageData['title']
  docId: PageData['docId']
  updateHref: (url: string) => void
}) => {
  const url = generateDocUrl({ docId, docTitle: title, absolute: true })
  return (
    <GammaTooltip label={title} placement="top">
      <Button
        w="100%"
        onClick={() => {
          updateHref(url)
        }}
        variant="ghost"
        colorScheme="gray"
        fontWeight="500"
        px={2}
        size="sm"
        key={docId}
        justifyContent="flex-start"
        leftIcon={<FontAwesomeIcon icon={regular('file')} />}
      >
        <Text textAlign="left" w="100%" noOfLines={1} display="inline">
          {title}
        </Text>
      </Button>
    </GammaTooltip>
  )
}

type PageData = {
  docId: string
  title?: string
}
const PagesTab = ({
  items,
  updateHref,
}: {
  items: PageData[]
  updateHref: (url: string) => void
}) => {
  const [val] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const filteredItems = items.filter((item) => {
    return item.title?.toLowerCase().includes(val.toLowerCase())
  })
  useEffect(() => {
    if (!inputRef?.current) {
      return
    }
    inputRef?.current?.focus()
  }, [])

  return (
    <Stack mx={-4}>
      {/* TODO: add search later */}
      <Text color="gray.500" fontSize="sm" fontWeight="600">
        <Trans>Recent pages</Trans>
      </Text>
      <Stack spacing={0}>
        {filteredItems.map((item) => (
          <PageItem
            updateHref={updateHref}
            title={item.title}
            docId={item.docId}
            key={item.docId}
          />
        ))}
      </Stack>
    </Stack>
  )
}

const LinkTab = ({ updateHref, initialHref }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isFocused, setIsFocused] = useState(false)
  const [val, setVal] = useState(initialHref || '')
  const onSubmit = (inputUrl: string) => {
    // Make sure it's a valid link
    if (!inputUrl) return
    const shouldPrependHttp =
      !startsWithHttp(inputUrl) && !isOtherSupportedProtocol(inputUrl)
    const url = shouldPrependHttp ? 'http://' + inputUrl : inputUrl

    if (!isValidUrl(url)) {
      return
    }
    updateHref(url)
  }

  // Focus the input when the menu is opened
  useEffect(() => {
    if (!inputRef?.current) {
      return
    }
    inputRef?.current?.focus()
  }, [])

  return (
    <Stack mx={-2}>
      <InputGroup size="sm">
        <Input
          ref={inputRef}
          size="sm"
          value={val}
          placeholder="Paste a link and hit enter"
          onChange={(e) => {
            setVal(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && val.trim().length > 0) {
              e.preventDefault()
              onSubmit(val)
            }
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        {val.length > 0 && (
          <InputRightElement bottom={0} top="auto">
            <IconButton
              aria-label={t`Submit`}
              variant="ghost"
              size="xs"
              onClick={() => onSubmit(val)}
              icon={<FontAwesomeIcon icon={regular('check')} />}
            />
          </InputRightElement>
        )}
      </InputGroup>
      {val.length > 0 && isFocused && (
        <Text w="100%" fontSize="xxs" color="gray.500">
          <Trans>Press Enter or click the submit button to save the URL.</Trans>
        </Text>
      )}
    </Stack>
  )
}
export const SiteLinksMenu = ({
  updateHref,
  initialTab,
  initialHref,
  onChange,
}: {
  updateHref: (url: string) => void
  initialTab: 'pages' | 'url'
  initialHref: string | null
  onChange: () => void
}) => {
  const site = useAppSelector(selectSite)
  const { linkablePages } = getLinkablePages({
    site,
  })
  const showPages = linkablePages?.length && linkablePages.length > 0
  const defaultIndex = initialTab === 'pages' ? 0 : 1

  return (
    <Box>
      <Tabs
        onChange={onChange}
        size="sm"
        variant="soft-rounded"
        isFitted
        colorScheme="trueblue"
        defaultIndex={defaultIndex}
      >
        <TabList>
          {showPages && (
            <Tab>
              <Trans>Pages</Trans>
            </Tab>
          )}
          <Tab>
            <Trans>URL</Trans>
          </Tab>
        </TabList>

        <TabPanels>
          {showPages && (
            <TabPanel>
              <PagesTab items={linkablePages} updateHref={updateHref} />
            </TabPanel>
          )}
          <TabPanel>
            <LinkTab updateHref={updateHref} initialHref={initialHref} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}
