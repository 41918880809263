type DesignPartnerErrorArgs = Record<string, any> & {
  chatMessage?: string
  resp?: any
  html?: string
  inputMessage?: string
  interactionId?: string
}

export class DesignPartnerError extends Error {
  opts: DesignPartnerErrorArgs

  constructor(message: string, opts: DesignPartnerErrorArgs = {}) {
    super(message)
    this.opts = opts
  }
}
DesignPartnerError.prototype.name = 'DesignPartnerError'

export class CouldNotApplySuggestionError extends DesignPartnerError {
  constructor(message: string, opts: DesignPartnerErrorArgs = {}) {
    super(message, opts)
  }
}
CouldNotApplySuggestionError.prototype.name = 'CouldNotApplySuggestionError'

export class InvalidResponseError extends DesignPartnerError {
  constructor(message: string, opts: DesignPartnerErrorArgs = {}) {
    super(message, opts)
  }
}
InvalidResponseError.prototype.name = 'InvalidResponseError'

export class NoopSuggestionError extends DesignPartnerError {
  constructor(message: string, opts: DesignPartnerErrorArgs = {}) {
    super(message, opts)
  }
}
NoopSuggestionError.prototype.name = 'NoopSuggestionError'

export class InputTooLongError extends DesignPartnerError {
  constructor(message: string, opts: DesignPartnerErrorArgs = {}) {
    super(`InputTooLongError: ${message}`, opts)
  }
}
InputTooLongError.prototype.name = 'InputTooLongError'

export class ParsedSuggestionError extends DesignPartnerError {
  constructor(message: string, opts: DesignPartnerErrorArgs = {}) {
    super(`ParsedSuggestionError: ${message}`, opts)
  }
}
NoopSuggestionError.prototype.name = 'ParsedSuggestionError'
