import { Box, Image, Link } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/react'

import { UploadStatus } from 'modules/media/types/ImageUpload'
import { NodeViewWrapper } from 'modules/tiptap_editor/react'

import {
  MediaPlaceholderBlock,
  MediaPlaceholderErrorUploadingTag,
  MediaPlaceholderSpinner,
} from '../Placeholder'
import { SimpleImageAttrs } from './SimpleImage'

export const SimpleImageView = (nodeViewProps: NodeViewProps) => {
  const { node } = nodeViewProps

  const { src, tempUrl, uploadStatus, showPlaceholder, id } =
    node.attrs as SimpleImageAttrs

  return (
    <NodeViewWrapper as="div">
      {showPlaceholder && <MediaPlaceholderBlock {...nodeViewProps} />}
      {!showPlaceholder && (src || tempUrl) && (
        <Box position="relative" w="100%">
          <Link
            className="image-link"
            href={src || tempUrl || ''}
            target="_blank"
          >
            <Image src={src || tempUrl || ''} />
          </Link>
          {/* Upload status indicators */}
          {uploadStatus == UploadStatus.Uploading && (
            <MediaPlaceholderSpinner />
          )}
          {uploadStatus == UploadStatus.Error && (
            <MediaPlaceholderErrorUploadingTag />
          )}
        </Box>
      )}
    </NodeViewWrapper>
  )
}

SimpleImageView.displayName = 'simple-image-view'
