import {
  Divider,
  Button,
  Badge,
  Stack,
  useOutsideClick,
  useColorModeValue,
  IconButton,
} from '@chakra-ui/react'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { Editor } from '@tiptap/core'
import React, { useRef } from 'react'

import { eventEmitter as cardSuggestionEventEmitter } from 'modules/ai/variations/AIEventEmitter'
import { CARD_SUGGESTION_DEFAULT_MESSAGE } from 'modules/ai/variations/useFetchCardSuggestions'
import { useAllFeatureFlags, useFeatureFlag } from 'modules/featureFlags'
import { useLocalizedFunction } from 'modules/i18n/hooks/useLocalizedFunction'
import { eventEmitter } from 'sections/docs/editor/components/DesignPartnerPanel/eventEmitter'

import { InsertWidgetButtons } from './InsertWidgetButtons'
import { getInsertableCategoriesList } from './items'

export const InsertWidgetInner = ({ editor }: { editor: Editor }) => {
  const getInsertableCategoriesListMemoized = useLocalizedFunction(
    getInsertableCategoriesList
  )
  const allFlags = useAllFeatureFlags()
  const [openButton, setOpenButton] = React.useState<string | null>(null)
  const widgetRef = useRef(null)
  const aiChatEnabled = useFeatureFlag('aiChat')
  const aiDesignerEnabled = useFeatureFlag('aiCardDesigner')

  // Colors for light and dark mode
  const colors = {
    bg: useColorModeValue('#F9FAFBDD', 'gray.900'),
    border: useColorModeValue('whiteAlpha.600', 'whiteAlpha.100'),
    iconColor: useColorModeValue('trueblue.600', 'trueblue.200'),
    dividerColor: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
  }

  useOutsideClick({
    ref: widgetRef,
    handler: (e) => {
      if ((e.target as HTMLElement).closest('[data-insert-widget-popover]')) {
        return // no op
      }
      setOpenButton(null)
    },
  })
  return (
    <>
      {aiChatEnabled && (
        <GammaTooltip label={<Trans>Edit with AI</Trans>} placement="left">
          <Stack position="relative">
            <Button
              data-guider-highlight="design-partner-button"
              position="relative"
              color={colors.iconColor}
              aria-label={t`Edit with AI`}
              bg={colors.bg}
              p={1}
              borderWidth="1px"
              borderColor={colors.border}
              borderRadius="md"
              shadow="lg"
              mb={3}
              onClick={() => {
                eventEmitter.emit('toggle', {})
              }}
              height={12}
              fontSize="lg"
            >
              <FontAwesomeIcon icon={duotone('sparkles')} />
              <Badge
                fontSize="10px"
                px="0.5"
                colorScheme="trueblue"
                position="absolute"
                bottom={1}
                right={1}
              >
                <Trans>AI</Trans>
              </Badge>
            </Button>
          </Stack>
        </GammaTooltip>
      )}
      {aiDesignerEnabled && (
        <GammaTooltip label="Design with AI" placement="left">
          <Button
            color={colors.iconColor}
            aria-label="Design with AI"
            bg={colors.bg}
            p={1}
            borderWidth="1px"
            borderColor={colors.border}
            borderRadius="md"
            shadow="xl"
            mb={3}
            onClick={() => {
              const userMessage = prompt(
                'What do you want to change?',
                CARD_SUGGESTION_DEFAULT_MESSAGE
              )
              if (!userMessage) return
              cardSuggestionEventEmitter.emit('openSuggestionEditor', {
                cardId: null,
                userMessage,
              })
            }}
          >
            <FontAwesomeIcon icon={duotone('cat-space')} />
            <Badge
              colorScheme="trueblue"
              position="absolute"
              bottom={1}
              right={1}
            >
              <Trans>AI</Trans>
            </Badge>
          </Button>
        </GammaTooltip>
      )}
      <Stack
        bg={colors.bg}
        color={colors.iconColor}
        p={1}
        borderWidth="1px"
        borderColor={colors.border}
        borderRadius="md"
        shadow="lg"
        ref={widgetRef}
        overflow="hidden"
        spacing={1}
      >
        {getInsertableCategoriesListMemoized.map((outer, index) => {
          return (
            <React.Fragment key={index}>
              {index > 0 && <Divider borderColor={colors.dividerColor} />}
              {outer.map(({ name, itemGroups, icon, featureFlag }) => {
                if (featureFlag && !allFlags[featureFlag]) return null

                return (
                  <InsertWidgetButtons
                    key={name}
                    itemGroups={itemGroups}
                    icon={icon}
                    name={name}
                    editor={editor}
                    setOpenButton={setOpenButton}
                    isOpen={openButton === name}
                  />
                )
              })}
            </React.Fragment>
          )
        })}
      </Stack>
    </>
  )
}
