import { SavedMedia } from 'modules/api'
import {
  fetchGenerateImage,
  GenerateImageOptions,
} from 'modules/media/apis/imageGenerate'
import { fetchImageSearchResults } from 'modules/media/apis/imageSearch'
import {
  ImageSearchFilterOptions,
  ImageSearchProviderKey,
  ImageSearchResult,
} from 'modules/media/types/ImageSearch'
import { isDownloadableImageSrc, isValidImageString } from 'utils/image'

type SearchInput = {
  query: string
  provider: ImageSearchProviderKey
  options: ImageSearchFilterOptions
  count?: number
}

type StoreResult = {
  image: ImageSearchResult
  isValid: boolean | undefined
}

/**
 * Requirements
 *  - if a request is already in flight for a provider / query -> return existing promise
 *  - for a specific provider, query term, get all the unused images
 *  - findImage is deduped if two consumers are calling it at the same time
 */
export class StreamImageSearchStore {
  usedImages: Set<string> = new Set()

  results: Map<string, StoreResult[]> = new Map()

  requestPromises: Map<string, Promise<ImageSearchResult[]>> = new Map()

  validImagePromises: Map<string, Promise<ImageSearchResult | null>> = new Map()

  debugRequests: string[] = []

  generateImagePromises: Map<string, Promise<SavedMedia[]>> = new Map()

  private getKey({ query, provider, options }: SearchInput) {
    return `${provider}__${query}__${JSON.stringify(options)}`
  }

  markUsed(id: string) {
    this.usedImages.add(id)
  }

  isUsed(id: string): boolean {
    return this.usedImages.has(id)
  }

  async findImage(
    input: SearchInput,
    markUsed: boolean = true
  ): Promise<ImageSearchResult | null> {
    await this.loadImageResults(input)

    const key = this.getKey(input)
    let promise: Promise<ImageSearchResult | null>
    if (this.validImagePromises.has(key)) {
      const existing = this.validImagePromises.get(key)!
      promise = existing.then(() => {
        return this.findValidImage(input)
      })
      this.validImagePromises.set(key, promise)
    } else {
      promise = this.findValidImage(input)
    }

    this.validImagePromises.set(key, promise)

    return promise.then((image) => {
      if (image && markUsed) {
        this.markUsed(image.imageUrl)
      }
      return image
    })
  }

  private async findValidImage(
    input: SearchInput
  ): Promise<ImageSearchResult | null> {
    const key = this.getKey(input)
    const results = this.results.get(key)!
    for (const result of results) {
      const { image } = result

      if (this.isUsed(image.imageUrl)) {
        continue
      }

      if (result.isValid === undefined) {
        result.isValid = await isDownloadableImageSrc(image.imageUrl)
      }

      if (result.isValid) {
        return result.image
      }
    }
    return null
  }

  private async loadImageResults(input: SearchInput): Promise<void> {
    const { query, provider, options, count } = input
    const key = this.getKey({ query, provider, options })

    if (this.results.has(key)) {
      return
    }

    if (this.requestPromises.has(key)) {
      await this.requestPromises.get(key)!
      return
    }

    this.debugRequests.push(key)
    const promise = fetchImageSearchResults({
      query,
      count,
      options: options as ImageSearchFilterOptions,
      provider,
    })
      .then((images) => {
        return images.filter((image) => {
          return isValidImageString(image.imageUrl)
        })
      })
      .then((images) => {
        const result = images.map((image) => ({
          image,
          isValid: undefined,
        }))
        this.results.set(key, result)
        console.log(
          `[AIStream] fetched images for "${query}": ${images.length}`,
          images
        )
        return images
      })

    this.requestPromises.set(key, promise)
    await promise
  }

  async generateImage(
    loadImageId: string,
    opts: GenerateImageOptions
  ): Promise<SavedMedia[]> {
    const key = `aiGenerated__${opts.prompt}__${loadImageId}`
    this.debugRequests.push(key)

    if (this.generateImagePromises.has(loadImageId)) {
      return this.generateImagePromises.get(loadImageId)!
    }

    const promise = fetchGenerateImage(opts)
    this.generateImagePromises.set(loadImageId, promise)
    return promise
  }

  debugInfo() {
    return { requests: this.debugRequests }
  }
}

let store: StreamImageSearchStore

export const getImageStore = (): StreamImageSearchStore => {
  if (!store) {
    store = new StreamImageSearchStore()
  }

  return store
}

export const resetImageStore = () => {
  store = new StreamImageSearchStore()
}
