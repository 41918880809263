import { Box, Flex, SimpleGrid } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'
import { memo } from 'react'

import { HEADING_CLASS } from 'modules/theming/styles/heading'
import { getThemeBase } from 'modules/theming/themeBases'

import { getAlignStyles } from '../../../HorizontalAlign/HorizontalAlign'
import { HorizontalAlignment } from '../../../HorizontalAlign/types'
import { EditableLabel } from '../../components/EditableLabel'
import { useCellColor } from '../../components/hooks'
import { EmptyCellContent } from '../../constants'
import { CellSizeOption, useNumColumns } from '../../options/CellSizeOption'
import { HorizontalAlignOption } from '../../options/HorizontalAlignOption'
import { ShapeSizeOption, ShapeSize } from '../../options/ShapeSizeOption'
import {
  SmartLayoutCellComponent,
  SmartLayoutVariant,
  SmartLayoutWrapperComponent,
} from '../../types'

export const PlainStatsWrapper: SmartLayoutWrapperComponent = memo(
  ({ children, options, containerSize, numCells }) => {
    const { columns } = useNumColumns({ containerSize, options, numCells })
    return (
      <SimpleGrid
        columns={columns}
        rowGap="3em"
        columnGap="1.5em"
        data-selection-ring="padded"
        pos="relative"
        borderRadius="var(--box-border-radius)"
      >
        {children}
      </SimpleGrid>
    )
  }
)
PlainStatsWrapper.displayName = 'PlainStatsWrapper'

const NUMBER_SIZES: Record<ShapeSize, number> = {
  sm: 2,
  md: 3,
  lg: 4.5,
  full: 5.5,
}

export const PlainStatsCell: SmartLayoutCellComponent = memo((props) => {
  const {
    theme,
    children,
    layoutOptions,
    cellOptions,
    label,
    isContentEmpty,
    isLayoutFocused,
    updateAttributes,
    dragHandle,
  } = props
  const base = getThemeBase(theme)
  // If this cell has a color set, we need recalculate CSS vars for lines/borders and text colors
  const { colorVars } = useCellColor(layoutOptions, cellOptions)

  const horizontalAlign: HorizontalAlignment = layoutOptions.horizontalAlign
  const alignSx = getAlignStyles(horizontalAlign || 'center')
  const numberSize = NUMBER_SIZES[layoutOptions.shapeSize || 'lg']

  return (
    <Flex
      borderRadius="var(--box-border-radius)"
      direction={'column'}
      align="center"
      justify="start"
      sx={alignSx}
      data-selection-ring="padded"
      data-content-reference
      pos="relative"
      gap={isContentEmpty && !isLayoutFocused ? 0 : '0.75em'}
    >
      <Flex
        direction="column"
        align="center"
        justify="center"
        width="100%"
        pos="relative"
        sx={colorVars}
        contentEditable={false}
        py="0.5em"
      >
        <EditableLabel
          value={label || ''}
          updateAttributes={updateAttributes}
          className={cx(HEADING_CLASS)}
          fontSize={`${numberSize}em`}
          lineHeight={1}
          display="flex"
          alignItems="center"
          sx={getAlignStyles('center')}
          style={{
            width: '100%',
          }}
          placeholder="—"
        />
      </Flex>
      <Box
        w="100%"
        data-content-empty={isContentEmpty}
        sx={base.smartLayoutContentSx}
      >
        {children}
      </Box>
      {dragHandle}
    </Flex>
  )
})
PlainStatsCell.displayName = 'PlainStatsCell'

export const PlainStats: SmartLayoutVariant = {
  key: 'stats',
  name: () => t`Stats`,
  commandName: () => t`Stats layout (blank)`,
  icon: regular('percent'),
  options: [
    CellSizeOption,
    {
      ...ShapeSizeOption,
      name: () => t`Number size`,
      icon: regular('text-size'),
    },
    HorizontalAlignOption,
  ],
  cellOptions: [],
  Wrapper: PlainStatsWrapper,
  Cell: PlainStatsCell,
  defaultContent: EmptyCellContent,
  defaultAttrs: {
    label: '50%',
  },
  addDirection() {
    return 'right'
  },
  keywords: ['stats'],
  htmlTag: 'stats',
  featureFlag: 'statsSmartLayouts',
}
