import { Box, Flex, HStack } from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { findIndex, last } from 'lodash'
import React, { useEffect, useRef } from 'react'

import { keyboardHandler } from 'modules/keyboard'
import { isPuppeteer } from 'utils/export'
import { stopPropagation } from 'utils/handlers'

import { OVERLAY_PADDING, ZoomTransition } from './constants'
import { ZoomableOverlay, ZoomableOverlayProps } from './ZoomableOverlay'

const MotionFlex = motion(Flex)

type CarouselChild = {
  id: string
  thumbnail: React.ReactNode
  full: React.ReactNode
}

type ZoomableCarouselProps = Pick<
  ZoomableOverlayProps,
  'isZoomed' | 'editor' | 'exitZoom'
> & {
  children: CarouselChild[]
  zoomedId: string | null
  setZoomedId: (id: string | null) => void
}

export const CAROUSEL_THUMBNAIL_HEIGHT = '80px'
export const CAROUSEL_THUMBNAIL_WIDTH = '200px'

export const ZoomableCarousel = ({
  children,
  zoomedId,
  setZoomedId,
  isZoomed,
  exitZoom,
  editor,
}: ZoomableCarouselProps) => {
  const currentIndex = findIndex(children, (c) => c.id === zoomedId)
  const current = children[currentIndex]
  const thumbnailsRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!isZoomed || children.length < 2) return
    const keydownListener = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowLeft':
          setZoomedId((children[currentIndex - 1] || last(children)).id)
          return true
        case 'ArrowRight':
          setZoomedId((children[currentIndex + 1] || children[0]).id)
          return true
        default:
          return false
      }
    }
    return keyboardHandler.on('keydown', 'ZOOMABLE_OVERLAY', keydownListener)
  }, [exitZoom, isZoomed, setZoomedId, children, currentIndex])

  useEffect(() => {
    if (!thumbnailsRef.current) return
    thumbnailsRef.current
      .querySelector(`[data-thumb-id="${zoomedId}"]`)
      ?.scrollIntoView({ inline: 'center', behavior: 'smooth' })
  }, [zoomedId])

  if (isPuppeteer) {
    return null
  }

  return (
    <ZoomableOverlay isZoomed={isZoomed} exitZoom={exitZoom} editor={editor}>
      <Flex
        flex={1}
        w="100%"
        justify="center"
        align="center"
        data-testid="zoomable-carousel-expanded"
      >
        <AnimatePresence>
          {current && (
            <MotionFlex
              key={current.id}
              css={{
                '--media-maxH': `calc(var(--overlay-maxH) - ${CAROUSEL_THUMBNAIL_HEIGHT} - ${OVERLAY_PADDING})`,
              }}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              position="absolute"
              transition={ZoomTransition}
              w="100%"
              h="100%"
            >
              {current.full}
            </MotionFlex>
          )}
        </AnimatePresence>
      </Flex>
      <Flex
        overflowX="auto"
        mt={OVERLAY_PADDING}
        sx={{
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <HStack
          spacing={4}
          onClick={stopPropagation}
          data-testid="zoomable-carousel-thumbnails"
          ref={thumbnailsRef}
        >
          {children.map((child) => {
            const isExpanded = child.id === zoomedId
            return (
              <Box
                key={child.id}
                border={
                  isExpanded ? '2px solid white' : '2px solid transparent'
                }
                boxSizing="content-box"
                borderRadius="md"
                overflow="hidden"
                onClick={() => setZoomedId(child.id)}
                transitionProperty="border"
                transitionDuration="normal"
                h={CAROUSEL_THUMBNAIL_HEIGHT}
                css={{
                  '--media-maxH': CAROUSEL_THUMBNAIL_HEIGHT,
                  '--media-maxW': CAROUSEL_THUMBNAIL_WIDTH,
                  // Don't shrink on narrow screens
                  flexShrink: 0,
                }}
                data-thumb-id={child.id}
                cursor="pointer"
                backgroundColor="gray.100"
              >
                {child.thumbnail}
              </Box>
            )
          })}
        </HStack>
      </Flex>
    </ZoomableOverlay>
  )
}
