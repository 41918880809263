import { cx } from '@chakra-ui/utils'
import {
  AnyExtension,
  Editor,
  getRenderedAttributes,
  NodeViewProps,
} from '@tiptap/core'
import {
  Mark as ProseMirrorMark,
  Node as ProseMirrorNode,
} from 'prosemirror-model'
import { DecorationAttrs } from 'prosemirror-view'
import React, { PropsWithChildren, ReactElement, ReactNode } from 'react'

import { ReactRenderer } from '../react/ReactRenderer'
import { useReactNodeView } from '../react/useReactNodeView'
import { findExtensionFromNodeType } from '../utils'
import { getDecorationsForNode } from '../utils/nodeHelpers'
import { HTMLRenderer, RenderHTMLSpec } from './types'

export interface NodeViewContentProps {
  [key: string]: any
  as?: React.ElementType
}

const getReactSafePropsFromHtmlElement = (el: HTMLElement) => {
  const attributes: Record<string, string | boolean | undefined> = {}
  for (const attr of el.attributes) {
    const name = attr.name
    if (name === 'class') {
      attributes.className = attr.value
    } else if (name === 'contenteditable' && attr.value === 'false') {
      // This is gross, but no-op if contenteditable is false. This seems to fix the following errors
      // when loading the CardLayoutPlugin. In the order in which they appear:
      // 1. Warning: Invalid DOM property `contenteditable`. Did you mean `contentEditable`?
      // 2. `Prop `contenteditable` did not match. Server: "null" Client: "false"`
    } else {
      attributes[name] = attr.value
    }
  }
  return attributes
}

export const getRendererForNode = (
  nodeOrMark: ProseMirrorNode | ProseMirrorMark,
  pos: number,
  editor: NodeViewProps['editor'],
  children?: ReactElement // Used for marks in lieu of NodeViewContent
) => {
  const Renderer = Array.from(
    // @ts-ignore
    editor.contentComponent.renderers.values()
  ).find((r: ReactRenderer): r is ReactRenderer => r.props.node === nodeOrMark)

  if (Renderer) {
    const Tag = Renderer.element.nodeName.toLowerCase() || 'div'
    const { style, ...props } = getReactSafePropsFromHtmlElement(
      Renderer.element as HTMLElement
    )

    return (
      // @ts-ignore
      <Tag
        key={Renderer.id}
        data-pos={pos}
        {...props}
        // If props includes a "style" attribute, it will be in normal HTML style tag format
        // like style="color: red", not the React object format. To avoid React trying to parse it and erroring,
        // we use capital STYLE instead. https://stackoverflow.com/a/67375810
        STYLE={`white-space: inherit;${style}`}
      >
        <Renderer.component {...Renderer.props}>{children}</Renderer.component>
      </Tag>
    )
  }
  return null
}

const getHtmlRenderer = (extension: AnyExtension): HTMLRenderer | undefined => {
  return extension.config.renderHTMLforSSR ?? extension.config.renderHTML
}

/**
 * Given attributes from HTML spec, make them React safe
 */
const getSafePropsFromHtmlSpecAttributes = (
  nProps?: Record<string, string | boolean | undefined>
) => {
  const props = { ...nProps }
  // If a node/mark (like highlight) sets the "style" attribute, it will be in normal HTML style tag format
  // like style="color: red", not the React object format. To avoid React trying to parse it and erroring,
  // we use capital STYLE instead. https://stackoverflow.com/a/67375810
  if (props?.style) {
    props.STYLE = props.style
    delete props.style
  }
  // If a node/mark sets the "class" attribute, we need to rename it to "className" so React doesn't error.
  if (props?.class) {
    props.className = props.class
    delete props.class
  }

  if (props?.colspan) {
    props.colSpan = props.colspan
    delete props.colspan
  }

  if (props?.rowspan) {
    props.rowSpan = props.rowspan
    delete props.rowspan
  }

  // TODO: Look into adding something like this if we keep getting errors? https://github.com/hatashiro/react-attr-converter
  return props
}

/**
 * For a given instance of a node or mark, get the rendered HTML
 * for that node or mark so we can create a React component from it.
 */
const getRenderedHTML = (
  editor: Editor,
  nodeOrMark: ProseMirrorNode | ProseMirrorMark
): RenderHTMLSpec => {
  const extension = findExtensionFromNodeType(editor, nodeOrMark.type)
  if (!extension) return []

  const extensionAttributes = editor.extensionManager.attributes.filter(
    (attribute) => attribute.type === nodeOrMark.type.name
  )
  const HTMLAttributes = getRenderedAttributes(nodeOrMark, extensionAttributes)
  let extensionToUse = extension
  while (!getHtmlRenderer(extensionToUse) && extensionToUse.parent) {
    extensionToUse = extensionToUse.parent
  }

  // When we extend/configure a Tiptap extension, we want to use the closest
  // ancestor's renderHTML, but with the options of the child extension
  const renderHTML = getHtmlRenderer(extensionToUse)?.bind(extension)
  if (!renderHTML) return []

  const spec: RenderHTMLSpec = renderHTML({
    HTMLAttributes,
    node: nodeOrMark,
    mark: nodeOrMark,
    editor, // only available in renderHTMLforSSR
  })
  const [type, nProps, children] = spec

  const props = getSafePropsFromHtmlSpecAttributes(nProps)
  return [type, props, children]
}

export const NodeViewContent = React.memo(
  (props: PropsWithChildren<NodeViewContentProps>) => {
    const { node, editor, getPos } = useReactNodeView()

    if (!node || !editor) {
      return null
    }

    const Tag = props.as || 'div'
    const TagInner =
      node.isInline || node instanceof ProseMirrorMark ? 'span' : 'div'

    const nodeList: ReactElement<any, any>[] = []

    const basePos = typeof getPos === 'function' ? getPos() : null

    const getReactElementForNode = (
      n: ProseMirrorNode,
      pos: number,
      key: string
    ) => {
      const absPos = (basePos === null ? 0 : basePos) + pos + 1
      // If the node has a nodeview, use that to render
      const renderer = getRendererForNode(n, absPos, editor)
      if (renderer) {
        return renderer
      }

      const wrapNodeInMarks = (comp: JSX.Element) => {
        return Array.from(n.marks)
          .reverse() // Reverse to that the last mark in the array is the deepest child
          .reduce((acc, mark) => {
            const markRenderer = getRendererForNode(mark, absPos, editor, acc)
            if (markRenderer) {
              return markRenderer
            }
            const [type, nProps, _children] =
              getRenderedHTML(editor, mark) || []
            if (type) {
              return React.createElement(type, { key, ...nProps }, acc)
            }
            return acc
          }, comp)
      }

      const decorations =
        basePos === null ? [] : getDecorationsForNode(editor, absPos)

      // If there's no nodeview, use the renderHTML to make a DOMOutputSpec
      const [type, nProps, children] = getRenderedHTML(editor, n)

      if (type && nProps) {
        let childrenToUse: React.ReactNode = null
        // See https://prosemirror.net/docs/ref/#model.DOMOutputSpec
        if (children === 0 && n.firstChild) {
          // This code path is used for rendering nodes without a nodeview that have content, like a table cell
          const renderedChildren: ReactNode[] = []
          n.forEach((child, offset, idx) => {
            renderedChildren.push(
              getReactElementForNode(child, pos + offset, `${key}_${idx}`)
            )
          })
          childrenToUse = renderedChildren
          console.debug('[SSR NodeViewContent] HOLE', renderedChildren)
        } else if (Array.isArray(children)) {
          console.debug('[SSR NodeViewContent] children array', children)
          childrenToUse = [...children]
        } else if (children) {
          console.debug('[SSR NodeViewContent] children plain', children)
          childrenToUse = children
        }

        const { nodeName: _nodeName, ...decorationAttrs } = decorations
          // @ts-ignore
          .map((d) => d.type?.attrs as DecorationAttrs)
          .reduce((acc, attrs) => {
            return { ...acc, ...attrs }
          }, {})

        const propsToUse = {
          ...nProps,
          ...decorationAttrs,
          className: cx(decorationAttrs.class, nProps.class),
          ['data-pos']: absPos,
        }

        // Hack to remove `class` which gets added in Decoration.node calls inside the BlockClass extension:
        // https://github.com/gamma-app/gamma/blob/edc285825a0f1cec80c31878d09bc652542f0aea/packages/client/src/modules/tiptap_editor/extensions/BlockClass.ts#L64
        // This affects nodes that don't have a nodeview, like Emoji.
        delete propsToUse.class

        console.debug(
          '[SSR NodeViewContent] non-nodeview:',
          key,
          propsToUse,
          children
        )
        const reactEl = React.createElement(
          type,
          { key, ...propsToUse },
          childrenToUse
        )
        return wrapNodeInMarks(reactEl)
      }

      if (n.isText) {
        return wrapNodeInMarks(
          <React.Fragment key={key}>{n.textContent}</React.Fragment>
        )
      }

      return null
    }

    node.forEach((n, pos) => {
      const idx = nodeList.length + 1
      const reactElement = getReactElementForNode(n, pos, `${idx}`)
      if (reactElement) {
        nodeList.push(reactElement)
      } else {
        console.warn(
          '%c [Simple NodeViewContent] UNKNOWN NODE $$$$$$$$$$$$$$$$$$$$$$$$$',
          'background-color: aqua; font-weight: bold',
          { unknownNode: n, parentNode: node }
        )
      }
    })

    return (
      <Tag
        {...props}
        data-node-view-content=""
        style={{
          whiteSpace: 'pre-wrap',
          ...props.style,
        }}
      >
        <TagInner
          data-node-view-content-inner={node.type.name}
          style={{
            whiteSpace: 'inherit',
          }}
        >
          {nodeList.length === 0
            ? null
            : nodeList.length === 1
            ? nodeList[0]
            : nodeList}
        </TagInner>
      </Tag>
    )
  }
)

NodeViewContent.displayName = 'NodeViewContent'
