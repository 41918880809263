import { useMemo } from 'react'

import { useIsThemeDark } from 'modules/theming/hooks'

type ColorKey = 'bg' | 'color' | 'hoverBg' | 'hoverColor' | 'borderColor'
type ColorProps = {
  [key in ColorKey]: string
}

/*
  Source of truth for drag handle colors.
*/
export const getColorsForDragHandle = (
  isDark: boolean,
  isSelected: boolean = false
): ColorProps => {
  const sharedColors = {
    borderColor: isDark
      ? 'var(--chakra-colors-whiteAlpha-300)'
      : 'var(--chakra-colors-blackAlpha-200)',
  }
  if (isSelected) {
    return {
      ...sharedColors,
      bg: isDark
        ? 'var(--chakra-colors-trueblue-300)'
        : 'var(--chakra-colors-trueblue-50)',
      color: isDark
        ? 'var(--chakra-colors-trueblue-900)'
        : 'var(--chakra-colors-trueblue-300)',
      hoverBg: isDark
        ? 'var(--chakra-colors-trueblue-200)'
        : 'var(--chakra-colors-trueblue-100)',
      hoverColor: isDark
        ? 'var(--chakra-colors-trueblue-900)'
        : 'var(--chakra-colors-trueblue-600)',
    }
  } else {
    return {
      ...sharedColors,
      bg: isDark
        ? 'var(--chakra-colors-gradient-dark)'
        : 'var(--chakra-colors-whiteAlpha-900)',
      color: isDark
        ? 'var(--chakra-colors-gray-100)'
        : 'var(--chakra-colors-gray-500)',
      hoverBg: isDark
        ? 'var(--chakra-colors-gray-800)'
        : 'var(--chakra-colors-gray-100)',
      hoverColor: isDark
        ? 'var(--chakra-colors-trueblue-200)'
        : 'var(--chakra-colors-gray-800)',
    }
  }
}

export const getGlobalHandleCSSVars = (isDark: boolean) => {
  const colors = getColorsForDragHandle(isDark)
  return {
    '--global-drag-handle-background': colors.bg,
    '--global-drag-handle-color': colors.color,
    '--global-drag-handle-hover-background': colors.hoverBg,
    '--global-drag-handle-hover-color': colors.hoverColor,
    '--global-drag-handle-border': colors.borderColor,
  }
}

export const getGlobalHandleCSS = (
  isDark: boolean,
  isSelected: boolean = false
): ColorProps => {
  if (isSelected) {
    return getColorsForDragHandle(isDark, true)
  } else {
    return {
      bg: 'var(--global-drag-handle-background)',
      color: 'var(--global-drag-handle-color)',
      hoverBg: 'var(--global-drag-handle-hover-background)',
      hoverColor: 'var(--global-drag-handle-hover-color)',
      borderColor: 'var(--global-drag-handle-border)',
    }
  }
}

export const useGetColorsForDragHandle = (isSelected: boolean = false) => {
  const isDark = useIsThemeDark()
  return useMemo(
    () => getGlobalHandleCSS(isDark, isSelected),
    [isDark, isSelected]
  )
}
