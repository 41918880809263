import { Box, Flex } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { DarkModeProvider } from '@gamma-app/ui'
import { NodeViewProps } from '@tiptap/react'
import { motion } from 'framer-motion'
import { useCallback, useState } from 'react'

import { useFeatureFlag } from 'modules/featureFlags'
import { useSendSlideToSlideEvent } from 'modules/performance/slideToSlidePerf'
import { useAppDispatch, useAppStore } from 'modules/redux'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { CardViewedDebugger } from 'modules/tiptap_editor/TimeOnCard/CardViewedDebugger'
import { preventDefaultToAllowDrop } from 'utils/handlers'

import { CardStyleDrawer } from '../../components/drawers/CardStyleDrawer'
import { NodeViewContent } from '../../react'
import {
  selectAnimationsEnabled,
  selectIsAllowedToEdit,
  selectIsEditingInSlideView,
  setFollowingAttached,
  setIsEditingInSlideView,
} from '../../reducer'
import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { ContainerDragHandle } from '../DragDrop/ContainerDragHandle/ContainerDragHandle'
import { setCardCollapsed } from './CardCollapse'
import { CardDivider } from './CardDivider'
import { CardBackground, CardContainer, useCardStyles } from './CardStyles'
import { CollapsedCardPreview } from './CollapsedCardPreview'
import {
  BETWEEN_CARDS_FRAMER_TRANSITION,
  CARD_BODY_CLASS,
  CARD_CONTAINER_CLASS,
  CARD_CONTENT_CLASS,
  CARD_PRESENTING_CLASS,
  CARD_WRAPPER_CLASS,
  EXPAND_CARD_TRANSITION_TIME,
  TOOLBAR_HEIGHT,
} from './constants'
import { useCardState } from './hooks/useCardState'
import { PresentVariants } from './hooks/usePresentVariant'
import { ManageCardControls } from './ManageCardControls'
import { CardAttributes } from './types'

const MotionBox = motion(Box)

/**
 * The React Component TipTap uses to render a card node view.
 * Read about it's API here: https://www.tiptap.dev/guide/node-views/react#render-a-react-component
 */
export const Card = (nodeViewProps: NodeViewProps) => {
  /**
   *  *********************** WARNING! *******************************
   * Excess re-renders of this component can cause performance issues.
   * This is especially noticeable when switching between DOC<>SLIDE views.
   * If you're adding new hooks or selectors that observe state changes, make
   * sure to QA entering & exiting present mode. Each card should not render more
   * than once.
   *  *********************** WARNING! *******************************
   */
  const dispatch = useAppDispatch()
  const store = useAppStore()
  const { getPos, node, editor, updateAttributes } = nodeViewProps
  const { id: cardId, background: cardBackground } =
    node.attrs as CardAttributes
  const docId = editor.gammaDocId
  const analytics = useAnalytics()
  const animationsEnabled = selectAnimationsEnabled(store.getState())

  const presentModeFlat = useFeatureFlag('presentModeFlat')
  const debugCardViewed = useFeatureFlag('debugCardViewed')
  const renderCollapsedCards = useFeatureFlag('renderCollapsedCards')
  // Temporary for debugging screenshot issues
  const debugScreenshot = 'debugScreenshot' in window // Set in screenshot.tsx

  const cardState = useCardState(nodeViewProps)
  const {
    isCollapsed,
    isPresentMode,
    isPresenting,
    isPresentingCurrent,
    isNested,
    isFocused,
    isEditable,
    isExpandableSelected,
    isMobileDevice,
  } = cardState

  // Slide to Slide RUM
  const realUserMetricsEnabled = useFeatureFlag('realUserMetrics')
  useSendSlideToSlideEvent(
    realUserMetricsEnabled,
    cardId,
    cardState.presentVariant,
    cardState.prevPresentVariant
  )

  const [isStyleDrawerOpen, setIsStyleDrawerOpen] = useState<boolean>(false)
  const [styleDrawerTabIndex, setStyleDrawerTabIndex] = useState<number>(0)
  const openStyleDrawer = useCallback((index: number) => {
    setIsStyleDrawerOpen(true)
    setStyleDrawerTabIndex(index)
  }, [])

  const expandIfCollapsed = useCallback(
    (ev) => {
      if (!isCollapsed) return
      ev.stopPropagation()
      if (isPresentMode && !isEditable) {
        dispatch(setFollowingAttached({ attached: false }))
        editor.commands.descendIntoCurrentCard(getPos(), 'push')
        analytics?.track(SegmentEvents.CARD_EXPANDED, {
          is_present_mode: true,
          method: 'click',
        })
      } else {
        setCardCollapsed(cardId, false)
        setTimeout(() => {
          if (!isEditable || editor.isDestroyed) return
          // Select just inside the opened card
          const pos = getPos()
          editor.commands.selectInsideNodeAtPos(pos)
        }, 50) // Wait until it's opened so contentEditable is true
        analytics?.track(SegmentEvents.CARD_EXPANDED, {
          is_present_mode: false,
          method: 'click',
        })
      }
    },
    [
      editor,
      dispatch,
      cardId,
      getPos,
      isCollapsed,
      isEditable,
      isPresentMode,
      analytics,
    ]
  )

  // Card styles
  const cardStyles = useCardStyles({
    node,
    ...cardState,
  })
  const { isDark } = cardStyles

  console.debug(
    `%c[CardComponent] Card id ${cardId} is rerendering`,
    'background-color: aqua',
    {
      cardState,
      cardStyles,
    }
  )
  const editCardInPresentMode = useCallback(() => {
    const isAllowedToEdit = selectIsAllowedToEdit(store.getState())
    if (!isAllowedToEdit) return
    dispatch(setIsEditingInSlideView({ isEditingInSlideView: true }))
    // Detach so they can edit without being dragged around while following
    dispatch(setFollowingAttached({ attached: false }))
    editor.commands.turnOffSpotlight()
  }, [editor, dispatch, store])

  const nestedPresentingNestedCard =
    isPresentMode && isNested && !presentModeFlat

  const shouldMakeRoomForToolbar =
    !isMobileDevice &&
    !cardStyles.isFullWidth &&
    !isCollapsed &&
    (nestedPresentingNestedCard ||
      (!isPresentMode &&
        cardState.isFirstCard &&
        !cardStyles.isBackgroundOutside))

  return (
    // CARD WRAPPER: this node is *almost* the top level of the Card, but the actual root is the .react-renderer just outside this which has decoration classes
    // Diagram of these components: https://miro.com/app/board/o9J_l8hO-qI=/
    <AnnotatableNodeViewWrapper
      as="div"
      className={CARD_WRAPPER_CLASS}
      style={{ minHeight: isPresentingCurrent ? '100vh' : 0 }} // Prevents all the cards from going behind this when you enter present mode
      hideComments={!isCollapsed}
      readOnly={isCollapsed}
      {...nodeViewProps}
    >
      <MotionBox
        className={cx(
          isCollapsed ? 'card-collapsed' : 'card-expanded',
          isPresentingCurrent && CARD_PRESENTING_CLASS
        )}
        data-card-id={cardId}
        data-card-scroll-element // The element that scrolls in present mode (used for PDF export)
        width="100%"
        // Animate side to side in present mode
        transition={animationsEnabled ? BETWEEN_CARDS_FRAMER_TRANSITION : {}}
        transitionProperty="transform, padding-left, padding-right"
        custom={[cardState.mode, cardId]}
        animate={cardState.presentVariant}
        data-animate-value={cardState.presentVariant}
        variants={PresentVariants}
        visibility={cardState.isPresentingParent ? 'hidden' : 'visible'}
        top={0}
        left={0}
        // Scroll internally in present mode
        overflowY={isPresentingCurrent ? 'auto' : undefined}
        overflowX={isPresentingCurrent ? 'hidden' : undefined}
        height="100%"
        onDragOver={preventDefaultToAllowDrop} // Allows drops on the edges of a card, or between them https://stackoverflow.com/a/21341021
        onClick={(e) => {
          // In present mode, clicking on the card edges (outside of the card-body)
          // should act like hitting escape (exit editing in present mode, then ascend)
          if (!isPresentingCurrent || !e.target) return
          if (e.target.closest(`[data-card-body="${cardId}"]`)) return

          if (selectIsEditingInSlideView(store.getState())) {
            dispatch(setIsEditingInSlideView({ isEditingInSlideView: false }))
          } else if (isNested) {
            editor.commands.ascendUpToParentCard()
            analytics?.track(SegmentEvents.CARD_COLLAPSED, {
              is_present_mode: true,
              method: 'outside_click',
            })
          }
        }}
      >
        <Flex
          data-testid={`card-${cardId}`}
          // Center the content inside
          justify="center"
          align="center"
          direction="column"
          minH={isPresenting ? 'var(--100vh)' : '0vh'}
          paddingTop={shouldMakeRoomForToolbar ? TOOLBAR_HEIGHT : undefined}
          paddingBottom={
            isPresentMode && shouldMakeRoomForToolbar
              ? TOOLBAR_HEIGHT
              : undefined
          }
          position="relative" // Required for container to fill the full height of this on long cards
          // Click to expand collapsed cards
          cursor={isCollapsed ? 'pointer' : !isEditable ? 'default' : undefined}
          onClick={expandIfCollapsed}
          data-content-reference={cardStyles.isFullWidth ? true : undefined}
        >
          {cardStyles.isFullWidth && (
            <Flex position="absolute" w={'var(--editor-width)'} h="100%">
              <CardBackground {...cardState} {...cardStyles} />
              <CardContainer {...cardState} {...cardStyles} />
            </Flex>
          )}
          {/* CARD BODY: includes the content, template picker, ... menu, and other actions like comments that dock to the content. */}
          <DarkModeProvider isDark={isDark || false}>
            <Box
              padding={cardStyles.outerPadding}
              width={isCollapsed ? '100%' : `${cardStyles.cardWidth}rem`}
              transitionProperty="width"
              transitionDuration={
                animationsEnabled
                  ? `${EXPAND_CARD_TRANSITION_TIME}ms`
                  : undefined
              }
              maxW={isCollapsed ? '100%' : 'var(--editor-width)'} // Max-width is a kludge for a) responsiveness, b) keeping cards inside parent while transitioning
              data-content-reference={cardStyles.isFullWidth ? undefined : true}
            >
              <Flex
                data-card-body={cardId} // Spotlight uses this to wiggle the card
                className={cx(
                  CARD_BODY_CLASS,
                  isExpandableSelected && 'expandable-selected'
                )}
                position="relative"
                justify="center"
                align="center"
                borderRadius={cardStyles.containerStyles.borderRadius}
                _hover={
                  isCollapsed
                    ? {
                        boxShadow: 'md',
                        [`.${CARD_CONTAINER_CLASS}`]: {
                          filter: isDark
                            ? 'brightness(1.2)'
                            : 'brightness(0.9)',
                        },
                      }
                    : {}
                }
                transitionProperty="box-shadow"
                transitionDuration="normal"
              >
                <ContainerDragHandle
                  {...nodeViewProps}
                  zIndex={3}
                  data-testid="card-drag-handle"
                  left={isNested && isCollapsed ? -6 : 2}
                  top={isNested && isCollapsed ? 0 : 3}
                />
                {(debugCardViewed || debugScreenshot) && (
                  <>
                    <Flex
                      left={2}
                      top={2}
                      zIndex="overlay"
                      position="absolute"
                      opacity={0.5}
                      contentEditable={false}
                    >
                      <Box bg="yellow" px={2} mr={2} borderRadius="md">
                        {cardId}
                      </Box>
                      <Box bg="trueblue.200" px={2} mr={2} borderRadius="md">
                        f:{getPos()} t:{getPos() + node.nodeSize}
                      </Box>
                      <CardViewedDebugger
                        cardId={cardId}
                        px={2}
                        borderRadius="md"
                      />
                    </Flex>
                  </>
                )}
                {!cardStyles.isFullWidth && (
                  <>
                    <CardBackground {...cardState} {...cardStyles} />
                    <CardContainer {...cardState} {...cardStyles} />
                  </>
                )}
                {/* CARD CONTENT. Position relative is needed for this to stack over the container background. */}
                <Box
                  w="100%"
                  position="relative"
                  // Handle a weird Safari bug where content goes behind the container. This is hard for me to repro but this fix works in inspector and seems pretty safe to apply narrowly here so gonna try it. https://stackoverflow.com/a/9540460
                  transform={isPresentingCurrent ? 'translateZ(0)' : undefined}
                  onDoubleClick={
                    isPresentMode && !isEditable
                      ? editCardInPresentMode
                      : undefined
                  }
                >
                  <Box
                    margin={cardStyles.innerPadding}
                    // Disable editing when collapsed, spellcheck when not focused
                    spellCheck={isFocused}
                    color={cardStyles.foregroundColor}
                  >
                    <CollapsedCardPreview
                      isCollapsed={isCollapsed}
                      cardId={cardId}
                      node={node}
                    />
                    {(!isCollapsed || renderCollapsedCards) && (
                      <Box
                        fontSize={cardStyles.fontSize}
                        opacity={isCollapsed ? 0 : 1}
                        height={isCollapsed ? '0px' : 'auto'}
                        transitionProperty="font-size, opacity, height"
                        transitionDuration={
                          animationsEnabled
                            ? `${EXPAND_CARD_TRANSITION_TIME}ms`
                            : undefined
                        }
                        css={{
                          ...cardStyles.cssVars,
                          '--comment-padding': `${cardStyles.innerPaddingX}rem`,
                          '--font-size': cardStyles.fontSize,
                        }}
                      >
                        <NodeViewContent
                          className={cx(
                            CARD_CONTENT_CLASS,
                            isDark && 'is-dark',
                            isFocused && 'is-focused',
                            cardStyles.isFullWidth && 'is-full-width'
                          )}
                          style={isCollapsed ? { display: 'none' } : {}}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
                <ManageCardControls
                  docId={docId}
                  openStyleDrawer={openStyleDrawer}
                  hasCardBackground={cardStyles.hasCardBackground}
                  {...cardState}
                  {...nodeViewProps}
                />
              </Flex>
            </Box>
          </DarkModeProvider>
          <CardDivider
            {...nodeViewProps}
            isNested={isNested}
            display={
              isEditable && !isCollapsed && !isNested && !isPresentMode
                ? 'flex'
                : 'none'
            }
          />
        </Flex>
        <CardStyleDrawer
          editor={editor}
          isOpen={isStyleDrawerOpen}
          isNested={isNested}
          setIsStyleDrawerOpen={setIsStyleDrawerOpen}
          tabIndex={styleDrawerTabIndex}
          setTabIndex={setStyleDrawerTabIndex}
          updateAttributes={updateAttributes}
          cardBackground={cardBackground}
          cardContainer={cardStyles.containerOptions}
        />
      </MotionBox>
    </AnnotatableNodeViewWrapper>
  )
}

/**
 * Checks if a click happened inside CARD_BODY but no CARD_CONTENT
 * this is the only place a gapcursor should be activated
 */
export const isCardEdge = (element) => {
  if (!element?.parentElement) {
    return false
  }

  if (element.classList.contains(CARD_BODY_CLASS)) {
    return true
  }

  return element
    .closest(`.${CARD_CONTENT_CLASS}, .${CARD_BODY_CLASS}`)
    ?.classList.contains(CARD_BODY_CLASS)
}
