import { Box, ButtonGroup, Flex, IconButton } from '@chakra-ui/react'
import { GammaTooltip } from '@gamma-app/ui'
import { t } from '@lingui/macro'
import { Editor, NodeViewProps } from '@tiptap/core'
import capitalize from 'lodash/capitalize'
import { useCallback } from 'react'

import { useGetRandomAccentBackground } from '../../hooks/useGetRandomAccentBackground'
import { CardLayout, DisplayLayout } from '../../types'

const layouts: CardLayout[] = ['blank', 'top', 'left', 'right', 'behind']

const getLayoutLabel = (layout: CardLayout) => {
  return layout === 'blank'
    ? t`No layout`
    : layout === 'behind'
    ? t`Background layout`
    : t`${capitalize(layout)} layout`
}

type CardLayoutSelectorProps = {
  editor: Editor
  layout: DisplayLayout
  getPos: NodeViewProps['getPos']
  edgeLayoutsEnabled: boolean
}
export const CardLayoutSelector = ({
  layout,
  editor,
  getPos,
  edgeLayoutsEnabled,
}: CardLayoutSelectorProps) => {
  const getRandomAccentBackground = useGetRandomAccentBackground()

  const setLayout = useCallback(
    (l: CardLayout) => {
      if (l === 'blank') {
        editor.commands.setCardLayout(getPos(), l)
      } else {
        editor.commands.setCardLayout(getPos(), l, getRandomAccentBackground)
      }
    },
    [editor, getPos, getRandomAccentBackground]
  )

  // TODO: i18n
  const filteredLayouts = layouts.filter(
    (l) => edgeLayoutsEnabled || ['blank', 'behind'].includes(l)
  )
  return (
    <ButtonGroup mb={2}>
      {filteredLayouts.map((l) => {
        const label = getLayoutLabel(l)
        return (
          <GammaTooltip key={l} label={label} placement="top">
            <IconButton
              aria-label={label}
              flex={1}
              variant="plain"
              size="md"
              onClick={() => setLayout(l)}
              icon={<CardLayoutIcon layout={l} isSelected={layout === l} />}
              boxShadow="none"
              background={layout === l ? 'trueblue.50' : 'white'}
            />
          </GammaTooltip>
        )
      })}
    </ButtonGroup>
  )
}

export const CardLayoutIcon = ({
  layout,
  isSelected,
}: {
  layout: CardLayout
  isSelected: boolean
}) => {
  const color = isSelected ? 'trueblue.400' : 'gray.300'
  let layoutIcon: JSX.Element | null = null
  if (layout === 'top') {
    layoutIcon = <Box height="0.375rem" width="100%" background={color} />
  } else if (layout === 'left') {
    layoutIcon = (
      <Flex height="100%" minH="100%">
        <Box width="0.625rem" minH="100%" background={color} />
        <Box flex="1" minH="100%" />
      </Flex>
    )
  } else if (layout === 'right') {
    layoutIcon = (
      <Flex height="100%" minH="100%">
        <Box flex="1" minH="100%" />
        <Box width="0.625rem" minH="100%" background={color} />
      </Flex>
    )
  }

  return (
    <Box
      boxSizing="border-box"
      height="1.25rem"
      width="1.75rem"
      border="2px solid"
      borderRadius="sm"
      borderColor={color}
      background={layout === 'behind' ? color : 'none'}
    >
      {layoutIcon}
    </Box>
  )
}
