import { Box, BoxProps, Flex } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { NodeViewProps } from '@tiptap/react'
import { AnimatePresence, motion } from 'framer-motion'
import isHotkey from 'is-hotkey'
import React, { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { keyboardHandler } from 'modules/keyboard'
import { useAppSelector } from 'modules/redux'
import { isThemeDark } from 'modules/theming'
import { ThemeFontLoader } from 'modules/theming/components/FontLoader'
import { getThemeStylesheet } from 'modules/theming/styles'
import { ThemeSVGDefs } from 'modules/theming/styles/ThemeSVGDefs'
import {
  useCanWithSelectDoc,
  useShouldRenderMobileVersion,
} from 'modules/tiptap_editor/hooks'
import { NodeViewContent, NodeViewWrapper } from 'modules/tiptap_editor/react'
import { blurEditorAndChildren } from 'modules/tiptap_editor/utils/focus'
import { isMobileOrTabletDevice } from 'utils/deviceDetection'
import { preventDefaultToAllowDrop } from 'utils/handlers'
import { THUMBNAIL_RESIZE_PARAMS } from 'utils/image'

import {
  selectEditable,
  selectIsAnyCommentOpen,
  selectMode,
  selectNumberOfCards,
  selectPresentingCardId,
  selectTheme,
} from '../../reducer'
import {
  getBackgroundProps,
  getDocOrThemeBackground,
} from '../../styles/backgroundStyles'
import { BackgroundType } from '../../styles/types'
import { EditorModeEnum } from '../../types'
import {
  BETWEEN_CARDS_FRAMER_TRANSITION,
  CARD_BODY_CLASS,
} from '../Card/constants'
import {
  DOCUMENT_BOTTOM_PADDING_COMMENT_OPEN,
  DOCUMENT_BOTTOM_PADDING_DEFAULT,
} from './constants'
import { CustomerLogo, CustomerLogoMobile } from './CustomerLogo'
import { DocumentAttributes } from './DocumentAttrs/attributes'
import { usePresentingCardBackground } from './usePresentingCardBackground'
import { DOC_Z_INDEXES } from './zIndexes'

const MotionBox = motion(Box)

/**
 * This hook helps us know when we've fully entered or fully left present mode
 * by keeping track of the most recent value in a ref. This is necessary because
 * 2 separate variables change between DOC<>SLIDE mode (the mode and the presentingCardId)
 *
 * isPresentModeReady tells us immediately when we have present mode and a presentingCardId
 * isPresentModeLagging tells us the same thing but doesnt change until both values have flipped
 *
 * Note that the mode always changes first, which allows us to derive the direction were going.
 * See similar logic for each card where we determine the present variant in usePresentVariant.ts
 *
 * |------ isPresentMode ------|------ presentingCardId ------|--------- status ---------|
 * |-------------------------------------------------------------------------------------|
 * |------     true      ------|------      string      ------|--------- PRESENT --------|  <-- isPresentModeReady
 * |------     true      ------|------      empty       ------|-- SWITCHING TO PRESENT --|  <-- isSwitchingToPresentMode
 * |------     false     ------|------      string      ------|--- SWITCHING TO DOC  ----|  <-- isSwitchingToDocMode
 * |------     false     ------|------      empty       ------|---------   DOC   --------|  <-- isDocModeReady
 * |-------------------------------------------------------------------------------------|
 */
const useEffectivePresentMode = (
  isPresentMode: boolean,
  presentingCardId: string | undefined | null
) => {
  const isPresentModeReady = Boolean(isPresentMode && presentingCardId)
  const isSwitchingToPresentMode = Boolean(isPresentMode && !presentingCardId)
  const isSwitchingToDocMode = Boolean(!isPresentMode && presentingCardId)
  const isDocModeReady = Boolean(!isPresentMode && !presentingCardId)
  const isPresentModeReadyPrev = useRef(isPresentModeReady)

  useEffect(() => {
    isPresentModeReadyPrev.current = isPresentModeReady
  }, [isPresentModeReady, isDocModeReady])

  const isPresentModeLagging =
    // If we are switching states, return the last known one
    isSwitchingToPresentMode || isSwitchingToDocMode
      ? isPresentModeReadyPrev.current
      : isPresentModeReady

  return [isPresentModeLagging, isPresentModeReady]
}

const NUM_CARDS_COMMENT_ANIMATION_THRESHOLD = 50

export const Doc = ({ editor, node, updateAttributes }: NodeViewProps) => {
  const mode = useSelector(selectMode)
  const isEditable = useAppSelector(selectEditable)
  const isAnyCommentOpen = useSelector(selectIsAnyCommentOpen)
  const presentingCardId = useSelector(selectPresentingCardId)
  const numCards = useAppSelector(selectNumberOfCards)
  const theme = useAppSelector(selectTheme)
  const isPresentMode = mode === EditorModeEnum.SLIDE_VIEW
  const presentingCardBackground = usePresentingCardBackground(editor)
  const { background: docBackground } = node.attrs as DocumentAttributes
  const isMobileDevice = useShouldRenderMobileVersion()

  const [isPresentModeReadyLagging, isPresentModeReady] =
    useEffectivePresentMode(isPresentMode, presentingCardId)

  // Keep the gammaDocId and document's node.attrs.docId in sync
  // this is necessary for understanding cut / copy provenance and whether
  // something in the paste buffer came from this document.
  // The current serializeForClipboard takes the document attributes and serializes
  // them in the `text/html` clipboard data
  useEffect(() => {
    if (!node.attrs.docId && editor.gammaDocId) {
      updateAttributes({
        ...node.attrs,
        docId: editor.gammaDocId,
      })
    }
  }, [node.attrs, editor, updateAttributes])

  // Theming and background
  const themeStyles = getThemeStylesheet(theme, isEditable)
  const baseStyles = {
    counterReset: 'footnote',
  }
  const isDark = isThemeDark(theme)
  const docOrThemeBackground = getDocOrThemeBackground(theme, docBackground)
  const docModeBackgroundProps = getBackgroundProps(
    docOrThemeBackground,
    isDark,
    editor.isThumbnail ? THUMBNAIL_RESIZE_PARAMS : undefined
  )

  // In present mode, prefer the card background, if present
  const presentModeBackgroundProps = getBackgroundProps(
    isPresentMode &&
      presentingCardBackground &&
      presentingCardBackground.type !== BackgroundType.NONE
      ? presentingCardBackground
      : docOrThemeBackground,
    isDark,
    editor.isThumbnail ? THUMBNAIL_RESIZE_PARAMS : undefined
  )

  console.debug(
    `%c[DocComponent] Doc is rerendering`,
    'background-color: chartreuse',
    {
      isPresentModeReady,
      isPresentModeReadyLagging,
      presentingCardId,
    }
  )

  const computedDocStyles = {
    width: '100%',
    '[data-animate-value="doc"]': {
      // Use lagging present mode to change display only once the state change has settled
      display: isPresentModeReadyLagging ? 'none' : undefined,
    },
  }

  // Blur the editor when clicking outside or hitting Esc
  const blurOnOutsideClick = useCallback(
    (ev: React.MouseEvent) => {
      const target = ev.target as HTMLElement
      if (
        target.closest(`.${CARD_BODY_CLASS}`) &&
        !target.getAttribute('data-outside-card-body')
      ) {
        return false
      }
      blurEditorAndChildren(editor)
      return true
    },
    [editor]
  )
  useEffect(() => {
    const keydownListener = (e: KeyboardEvent) => {
      if (isHotkey('Esc')(e) && editor.isFocused) {
        editor.commands.blur()
        e.preventDefault()
        return true
      }
      return false
    }
    return keyboardHandler.on('keydown', 'DOC_BLUR', keydownListener)
  }, [editor])
  const userCanComment = useCanWithSelectDoc('comment')

  return (
    <NodeViewWrapper
      as="div"
      data-testid="doc-node-root"
      style={{ height: '100%' }}
    >
      <ThemeFontLoader theme={theme} />
      <ThemeSVGDefs theme={theme} />
      <Box
        sx={baseStyles}
        // Blur the editor if you click outside a card
        onMouseDown={blurOnOutsideClick}
        h="100%"
      >
        <Flex
          className="doc-content-wrapper"
          direction="column"
          align="center"
          pos="relative"
          pb={
            isMobileDevice || isPresentMode || !userCanComment
              ? '0px'
              : isAnyCommentOpen
              ? DOCUMENT_BOTTOM_PADDING_COMMENT_OPEN
              : // DocWizardFooter has a negative margin to compensate for this.
                DOCUMENT_BOTTOM_PADDING_DEFAULT
          }
          sx={themeStyles}
          onDragOver={preventDefaultToAllowDrop} // Allows drops on the edges of the doc - https://stackoverflow.com/a/21341021
          minH="calc(var(--100vh) - var(--editor-padding-top, 0px))"
        >
          {/* This stays visible even in present mode because it's underneath, and the present bg will fade in over it */}
          <DocBackground
            {...docModeBackgroundProps}
            isMobileDevice={isMobileDevice}
          />
          <AnimatePresence>
            <MotionBox
              key={'presenting-background-' + presentingCardId}
              className="motion-present-mode-bg"
              data-doc-background-element-present-mode
              position="fixed"
              zIndex={DOC_Z_INDEXES.presentBg} // Go over "doc" cards that aren't rendered in present mode
              left={0}
              right={0}
              h="100%"
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 1,
              }}
              // Only show the background when were fully in present mode.
              // This means for doc mode OR while switching, it is hidden
              visibility={isPresentModeReady ? 'visible' : 'hidden'}
              transition={BETWEEN_CARDS_FRAMER_TRANSITION}
              {...presentModeBackgroundProps}
            />
          </AnimatePresence>
          <Flex
            sx={computedDocStyles}
            justify="center"
            className={cx(
              'document-content',
              isPresentMode ? 'is-present-mode' : 'is-doc-mode'
            )}
            pt="var(--doc-padding-top)" // Padding over the doc background but around the content, for site header
          >
            <NodeViewContent style={{ width: '100%' }} />
          </Flex>
        </Flex>
      </Box>
      {isMobileDevice && <CustomerLogoMobile />}
    </NodeViewWrapper>
  )
}

export const DocBackground = (
  props: BoxProps & {
    isMobileDevice: boolean
  }
) => {
  const { isMobileDevice, ...rest } = props
  const mobileProps = isMobileOrTabletDevice()
    ? ({
        style: {
          // Prevent swiping the background up/down
          touchAction: 'none',
        },
        /**
         * Use a fixed position background for mobile devices because they dont support background-attachment: fixed
         * See https://css-tricks.com/the-fixed-background-attachment-hack/
         * Note: For now, were only using this approach on mobile because it covers the scrollbar,
         * and breaks editors in modals/drawers (eg snapshot viewer).
         */
        position: 'fixed',
        top: 0,
        left: 0,
      } as BoxProps)
    : {}

  return (
    <Flex
      data-doc-background-element
      data-testid="doc-background"
      position="absolute"
      w="var(--editor-width)"
      h="100%"
      contentEditable={false}
      {...rest}
      {...mobileProps}
      backgroundAttachment="fixed"
    >
      {!isMobileDevice && <CustomerLogo />}
    </Flex>
  )
}
