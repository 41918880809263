import { Text } from '@chakra-ui/react'
import { mergeAttributes, Mark } from '@tiptap/core'
import { useCallback } from 'react'

import { useShouldUsePublishedVersion } from 'modules/sites/PublishingContext'
import { ExtensionPriorityMap } from 'modules/tiptap_editor/extensions/constants'
import { NodeViewWrapper, NodeViewContent } from 'modules/tiptap_editor/react'

import { addMarkViewPlugin, MarkViewProps } from '../../react/addMarkViewPlugin'
import {
  addBookmarkStorage,
  bookmarkNode,
  getAttrsFromBookmark,
} from '../AI/Sal/utils/bookmark'
import { useToggleFootnoteOnClick } from './hooks'

export const FootnoteLabel = Mark.create({
  name: 'footnoteLabel',
  inclusive: false,
  excludes: 'link underline',
  priority: ExtensionPriorityMap.FootnoteLabel,

  parseHTML() {
    return [
      {
        tag: 'span[class=footnote-label][bookmark]',
        getAttrs: getAttrsFromBookmark(this.storage),
      },
      {
        tag: 'span[class=footnote-label]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(HTMLAttributes, {
        class: 'footnote-label',
      }),
      0,
    ]
  },

  renderHTMLforAI({ mark }) {
    return [
      'span',
      {
        class: 'footnote-label',
        bookmark: bookmarkNode(this.storage, mark),
      },
      0,
    ]
  },

  addStorage: addBookmarkStorage,

  addAttributes() {
    return {
      noteId: {
        // Prevents noteId from coming with copy/paste operations. We'll generate a new one on paste using `fixOrphanedMarks`
        rendered: false,
      },
    }
  },

  addProseMirrorPlugins() {
    return [addMarkViewPlugin(this, FootnoteLabelView)]
  },
})

const FootnoteLabelView = ({ node, editor, children }: MarkViewProps) => {
  const { noteId } = node.attrs
  const shouldUsePublishedVersion = useShouldUsePublishedVersion()

  // On hover, mark the note as expanded
  const { onClick } = useToggleFootnoteOnClick(editor, noteId)

  const onFootnoteClick = useCallback(() => {
    onClick()
  }, [onClick])

  return (
    <NodeViewWrapper as="span" className="footnote-label-view">
      <Text
        as="span"
        className="footnote-label"
        onClick={onFootnoteClick}
        data-footnote-label
        cursor="pointer"
      >
        {/* In SSR, NodeViewContent won't work inside a mark, so we pass children directly instead */}
        {shouldUsePublishedVersion ? children : <NodeViewContent as="span" />}
      </Text>
    </NodeViewWrapper>
  )
}
