import { Editor } from '@tiptap/core'
import { range } from 'lodash'
import { Node } from 'prosemirror-model'
import { Plugin, PluginKey } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import { isListNode } from './ListTypes'

const indents = range(10)
const START_FROM = 1

// Continue numbering past atom nodes like images, plus these specific blocks
const CONTINUE_NUMBERING_BLOCKS = ['blockquote', 'card', 'sectionBreak']

const shouldContinueNumbering = (node: Node, indent: number) => {
  return (
    node.type.name === 'numbered' ||
    (isListNode(node) && node.attrs.indent > indent) ||
    node.isAtom ||
    CONTINUE_NUMBERING_BLOCKS.includes(node.type.name)
  )
}

export const ListPlugin = (_editor: Editor) =>
  new Plugin({
    key: new PluginKey('ListPlugin'),
    props: {
      decorations: ({ doc }) => {
        const decorations: Decoration[] = []

        // For each parent node (e.g. card or layout cell), track the list counters for each indent level. Uses a Map so we can use the parent node itself as a key.
        const countersByParent = new Map<Node, Record<number, number>>()

        const getCounter = (parent: Node, indent: number) =>
          countersByParent.get(parent)?.[indent] || START_FROM
        const setCounter = (parent: Node, indent: number, value: number) => {
          const counters = countersByParent.get(parent)
          try {
            counters![indent] = value
          } catch (err) {
            console.error('(caught) [ListPlugin] setCounter error', err)
          }
        }
        const resetCounters = (parent: Node, aboveIndent = 0) => {
          const counters = countersByParent.get(parent) || {}
          indents
            .filter((i) => i >= aboveIndent)
            .forEach((i) => {
              counters[i] = START_FROM
            })
          countersByParent.set(parent, counters)
        }

        let lastIndent = 0

        const decorate = (node: Node, pos: number, parent: Node | null) => {
          // If the block starts with colored text, color the bullet to match
          if (isListNode(node) && node.firstChild) {
            const textColor = node.firstChild.marks.find(
              (m) => m.type.name === 'textColor'
            )

            if (textColor?.attrs.variant) {
              decorations.push(
                Decoration.node(pos, pos + node.nodeSize, {
                  'data-text-color': textColor.attrs.variant,
                })
              )
            }
            if (textColor?.attrs.hex) {
              decorations.push(
                Decoration.node(pos, pos + node.nodeSize, {
                  style: `--text-color: ${textColor.attrs.hex}`,
                })
              )
            }
          }
          // Add decorations for each numbered list item with the counter

          if (!parent) {
            return
          }
          // Whenever we see a non-list text block like a paragraph, reset numbering
          if (!shouldContinueNumbering(node, lastIndent)) {
            resetCounters(parent)
          }

          if (node.type.name !== 'numbered') return

          // If this is a new parent node, start numbering from 1
          if (!countersByParent.get(parent)) {
            resetCounters(parent)
          }

          const indent = node.attrs.indent
          lastIndent = indent
          const counter = getCounter(parent, indent)
          setCounter(parent, indent, counter + 1)
          resetCounters(parent, indent + 1) // Reset nested child counters so that new indents always start from 1

          decorations.push(
            Decoration.node(
              pos,
              pos + node.nodeSize,
              {}, // classes
              {
                listNumber: counter,
              }
            )
          )
        }
        doc.descendants(decorate)
        return decorations.length == 0
          ? DecorationSet.empty
          : DecorationSet.create(doc, decorations)
      },
    },
  })
