import { Center, CenterProps, Flex, FlexProps, Text } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { useCallback, useMemo } from 'react'

import { HEADING_CLASS } from 'modules/theming/styles/heading'
import { getThemeBase } from 'modules/theming/themeBases'

import { getFontSizeOptions } from '../../Font/constants'
import { getFontSizeStyles } from '../../Font/fontStyles'
import { SmartLayoutCellProps } from '../types'
import { selectParentLayout } from '../utils'
import { useCellColor } from './hooks'

const BULLET_FONT_SIZE = 'h3'
const BULLET_PADDING = 0.375

type BulletMarkerProps = SmartLayoutCellProps & {
  alignText: boolean
  positionProps?: FlexProps
  markerProps?: CenterProps
  markerFontSize?: string
}

export const BulletMarker = ({
  index,
  theme,
  firstChildSize,
  layoutOptions,
  cellOptions,
  children,
  positionProps,
  markerProps,
  alignText,
  getPos,
  editor,
  markerFontSize,
}: BulletMarkerProps) => {
  const sizerFontStyles = getFontSizeStyles(firstChildSize)

  // Different layouts have different options for numbering - either on/off, or with direction
  const isNumbered: boolean = layoutOptions.numbered ?? true
  const bulletFontSize =
    getFontSizeOptions()[markerFontSize || BULLET_FONT_SIZE]?.size
  const bulletSize = `${
    (isNumbered ? bulletFontSize : 1) + BULLET_PADDING * 2
  }em`
  const base = getThemeBase(theme)
  const selectLayout = useCallback(() => {
    if (!editor.isEditable) return
    const $pos = editor.state.doc.resolve(getPos())
    selectParentLayout(editor, $pos)
  }, [getPos, editor])

  const markerFontStyles = useMemo(
    () => getFontSizeStyles(markerFontSize || BULLET_FONT_SIZE),
    [markerFontSize]
  )

  // If this cell has a color set, we need recalculate CSS vars for lines/borders and text colors
  const { colorVars } = useCellColor(layoutOptions, cellOptions)

  return (
    <Flex
      my={alignText ? 'var(--block-margin)' : 0}
      {...positionProps}
      contentEditable={false}
      userSelect="none"
      onMouseDown={selectLayout}
      cursor="default"
      css={colorVars}
    >
      {/* Ensure that the bullet is centered on the first line of text, by giving it height equal to the font size of the first child, and putting it inside a margin that matches our normal block margin  */}
      <Center sx={sizerFontStyles} h={`${sizerFontStyles.lineHeight}em`}>
        {/* Within that block, reset the font size inside the bullet to be relative to the base font size */}
        <Center
          fontSize="var(--font-size)"
          h={bulletSize}
          w={bulletSize}
          sx={base.smartLayoutBoxSx || base.boxSx}
          {...markerProps}
        >
          <Text
            sx={markerFontStyles}
            className={cx(HEADING_CLASS)}
            textAlign="center" // Hint to PPT export
          >
            {isNumbered ? index + 1 : ''}
          </Text>
        </Center>
      </Center>
      {/* Add-ons like a line */}
      {children}
    </Flex>
  )
}
