import React, { ReactElement } from 'react'

import { useSSRMounted } from 'utils/hooks/useSSRMounted'

import { useEditorId } from './useEditorId'
import { useOnEditorRendered } from './useOnEditorRendered'

type OnEditorRenderedProps = {
  children: ReactElement
  editorId?: string
}
export const OnEditorRendered: React.FC<OnEditorRenderedProps> = ({
  editorId,
  children,
}) => {
  const ssrMounted = useSSRMounted()
  const contextEditorId = useEditorId()
  const editorIdToUse = editorId || contextEditorId
  const isRendered = useOnEditorRendered(editorIdToUse) && ssrMounted

  return isRendered ? children : null
}
