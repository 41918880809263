import { mergeAttributes, Node } from '@tiptap/core'

import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'
import { blockInputRule } from 'modules/tiptap_editor/utils/inputRules'

import { DividerView } from './DividerView'

// including `—-` for tiptap's typography emdash extension which replaces -- with —
const DIVIDER_REGEX = /^(---|___|—-)/

export const Divider = Node.create({
  name: 'divider',
  group: 'block cardNotesBlock',
  atom: true,
  selectable: true,
  draggable: true,

  parseHTML() {
    return [
      {
        tag: 'hr[class="divider"]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['hr', mergeAttributes(HTMLAttributes, { class: 'divider' })]
  },

  addNodeView() {
    return ReactNodeViewRenderer(DividerView)
  },

  addInputRules() {
    return [blockInputRule({ find: DIVIDER_REGEX, type: this.type })]
  },
})
