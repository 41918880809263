import { Flex, IconButton } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { NodeViewProps } from '@tiptap/core'
import { useCallback } from 'react'

import { useAppSelector } from 'modules/redux'
import { useShouldRenderMobileVersion } from 'modules/tiptap_editor/hooks'

import { NodeViewContent } from '../../react'
import { selectContentEditable } from '../../reducer'
import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { isNodeViewEmpty } from '../EmptyNodes'
import { getFlexAlign } from '../HorizontalAlign/HorizontalAlign'
import { MAX_BUTTONS } from './constants'

export const ButtonGroupView = (nodeViewProps: NodeViewProps) => {
  const { node } = nodeViewProps
  const { horizontalAlign } = node.attrs
  const isVertical = useShouldRenderMobileVersion()
  const direction = isVertical ? 'column' : 'row'

  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps}>
      <Flex
        w="100%"
        data-selection-ring="padded"
        borderRadius="var(--box-border-radius)"
        direction={direction}
        justify={getFlexAlign(horizontalAlign) || 'var(--flex-align)'}
        sx={getButtonGroupSx(isVertical)}
        gap="0.5em"
      >
        <NodeViewContent />
        <AddButtonButton
          {...nodeViewProps}
          direction={direction}
          maxButtons={MAX_BUTTONS}
        />
      </Flex>
    </AnnotatableNodeViewWrapper>
  )
}

export const getButtonGroupSx = (isVertical: boolean) => ({
  '> [data-node-view-content]': {
    display: 'contents',
    '> [data-node-view-content-inner]': {
      display: 'contents',
    },
  },
  '--button-width': isVertical ? '100%' : 'auto',
  '.node-button': {
    width: 'var(--button-width)',
    '.button': {
      width: '100%',
    },
  },
  _hover: {
    '.add-button': {
      opacity: 1,
    },
  },
})

export const AddButtonButton = ({
  editor,
  getPos,
  decorations,
  node,
  direction,
  maxButtons,
  tooltip,
}: NodeViewProps & {
  direction: 'row' | 'column' | 'row-reverse'
  maxButtons?: number
  tooltip?: React.ReactNode
}) => {
  const isEditable = useAppSelector(selectContentEditable)
  const isEmpty = isNodeViewEmpty(decorations)
  const canAdd = maxButtons ? node.childCount < maxButtons : true

  const onClick = useCallback(() => {
    editor.commands.addButtonToGroup(getPos())
  }, [editor, getPos])
  if (!canAdd || !isEditable) return null

  return (
    <Flex position="relative">
      <GammaTooltip
        label={tooltip || <Trans>Add a button</Trans>}
        placement="top"
      >
        <IconButton
          icon={<FontAwesomeIcon icon={regular('plus')} />}
          aria-label={t`Add button`}
          size="xs"
          variant="plain"
          isRound
          onClick={onClick}
          opacity={isEmpty && isEditable ? 0.5 : 0}
          className="add-button"
          transitionProperty="opacity"
          transitionDuration="normal"
          position={isEmpty ? 'static' : 'absolute'}
          left={direction === 'column' ? '50%' : 0}
          top={'50%'}
          transform={
            isEmpty
              ? undefined
              : direction === 'column'
              ? 'translateX(-50%)'
              : direction === 'row-reverse'
              ? 'translateY(-50%) translateX(-100%)'
              : 'translateY(-50%)'
          }
        />
      </GammaTooltip>
    </Flex>
  )
}
