import { cx } from '@chakra-ui/utils'
import { t } from '@lingui/macro'
import { NodeViewProps } from '@tiptap/core'

import { useAppSelector } from 'modules/redux'
import { NodeViewContent } from 'modules/tiptap_editor/react'
import { selectEditable, selectTheme } from 'modules/tiptap_editor/reducer'

import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { ContainerDragHandle } from '../DragDrop/ContainerDragHandle/ContainerDragHandle'
import { findSmartLayoutDecoration } from './decoration'
import { SmartLayoutAttrs } from './types'
import { getSmartLayoutOptions } from './utils'
import { getSmartLayoutVariant } from './variants'

export const SmartLayoutView = (nodeViewProps: NodeViewProps) => {
  const { node, decorations } = nodeViewProps
  const attrs = node.attrs as SmartLayoutAttrs
  // Use the Wrapper component specific to this variant
  const variant = getSmartLayoutVariant(attrs.variantKey)
  const { Wrapper } = variant
  // Decoration has info like # of cells that we pass to the Wrapper
  const deco = findSmartLayoutDecoration(decorations)
  const options = getSmartLayoutOptions(attrs)
  const isEditable = useAppSelector(selectEditable)

  const theme = useAppSelector(selectTheme)
  return (
    <AnnotatableNodeViewWrapper
      {...nodeViewProps}
      className={cx(isEditable && 'is-editable')}
      style={{ position: 'relative' }}
    >
      <Wrapper {...deco} options={options} theme={theme} variant={variant}>
        <NodeViewContent data-node-view-content-outer="smartLayout" />
      </Wrapper>

      <ContainerDragHandle
        {...nodeViewProps}
        label={t`Select layout`}
        data-testid="smart-layout-drag-handle"
      />
    </AnnotatableNodeViewWrapper>
  )
}
