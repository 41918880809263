import { CSSObject } from '@chakra-ui/styled-system'

import { ColorAttrs } from '../../components/panels/ColorAttrs'

// These colors are deprecated. We now store a hex color directly via TextColorMenu.
// but we're keeping this for legacy text colors.
export type TextColorOptions = {
  // New colors
  red: ColorAttrs
  yellow: ColorAttrs
  orange: ColorAttrs
  green: ColorAttrs
  blue: ColorAttrs
  purple: ColorAttrs
  gray: ColorAttrs
}

type TextColors = {
  light: TextColorOptions
  dark: TextColorOptions
}

// https://gamma.app/docs/fbv4sdzczgin8ph#card-dekblkko9tn15m7
export const textColors: TextColors = {
  light: {
    gray: { hex: '#7A7A7A', name: 'Gray' },
    red: { hex: '#EB0000', name: 'Red' },
    orange: { hex: '#BD6F00', name: 'Orange' },
    yellow: { hex: '#B29500', name: 'Yellow' },
    green: { hex: '#008545', name: 'Green' },
    blue: { hex: '#006ED6', name: 'Blue' },
    purple: { hex: '#7B57FF', name: 'Purple' },
  },
  dark: {
    gray: { hex: '#AFAFAF', name: 'Gray' },
    red: { hex: '#EF8784', name: 'Red' },
    orange: { hex: '#F5C274', name: 'Orange' },
    yellow: { hex: '#F5F380', name: 'Yellow' },
    green: { hex: '#8CE29F', name: 'Green' },
    blue: { hex: '#8DD4FB', name: 'Blue' },
    purple: { hex: '#A08CF8', name: 'Purple' },
  },
}

export const ValidTextColors = Object.keys(textColors.light)

// Legacy variant-based text colors thatdepend on card light/dark
// new colors don't use any of this
const getTextColorStyles = (
  colorMode: 'light' | 'dark',
  selectorFn?: (key: string) => string
) => {
  return Object.entries(textColors[colorMode]).reduce(
    (styles, [key, value]) => {
      const style: CSSObject = {
        '--text-color': value.hex,
      }
      const selector = selectorFn ? selectorFn(key) : `&[variant="${key}"]`
      styles[selector] = style
      return styles
    },
    {}
  )
}

// New hex-based text colors are all set by the --text-color CSS variable
// in the TextColor plugin and ListPlugin (for bullets)
// We fall back to body-color just in case somehow those variables aren't set
const textColorVar = 'var(--text-color, var(--body-color))'

export const textColorStyles = {
  default: {
    // The text color mark
    'span.textColor': {
      ...getTextColorStyles('light'),
      color: textColorVar,
    },
    // When lists start with a text color, ListPlugin adds a [data-text-color] class to the block
    '.block-numbered': {
      ...getTextColorStyles(
        'light',
        (key) => `&[data-text-color="${key}"] li::before`
      ),
      'li::before': {
        color: textColorVar,
      },
    },
    '.block-bullet': {
      ...getTextColorStyles(
        'light',
        (key) => `&[data-text-color="${key}"] li::marker`
      ),
      'li::marker': {
        color: textColorVar,
      },
    },
  },
  dark: {
    'span.textColor': {
      ...getTextColorStyles('dark'),
      color: textColorVar,
    },
    '.block-numbered': {
      ...getTextColorStyles(
        'dark',
        (key) => `&[data-text-color="${key}"] li::before`
      ),
      'li::before': {
        color: textColorVar,
      },
    },
    '.block-bullet': {
      ...getTextColorStyles(
        'dark',
        (key) => `&[data-text-color="${key}"] li::marker`
      ),
      'li::marker': {
        color: textColorVar,
      },
    },
  },
}
