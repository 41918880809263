import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  ButtonGroup,
  Flex,
  FlexProps,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  Portal,
  useColorModeValue,
} from '@chakra-ui/react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { JSONContent, NodeViewProps } from '@tiptap/core'
import { memo, useCallback } from 'react'

import { useFeatureFlag } from 'modules/featureFlags'
import { NodeInsertMethods } from 'modules/segment'
import {
  getCommandsMap,
  trackItemInserted,
} from 'modules/tiptap_editor/commands'
import { EMPTY_NODES } from 'modules/tiptap_editor/commands/emptyNodes'
import { TiptapEventBus } from 'modules/tiptap_editor/eventBus'
import { useShouldRenderMobileVersion } from 'modules/tiptap_editor/hooks'
import { preventDefaultAndStopPropagation } from 'utils/handlers'

import { CardTemplatesMenuList } from './cardTemplates/CardTemplatesList'
import { assignCardIds } from './uniqueId'

type CardDividerProps = Pick<NodeViewProps, 'editor' | 'getPos'> & {
  display: FlexProps['display']
  isNested: boolean
}

export const CardDivider = memo(function CardDivider({
  editor,
  getPos,
  display,
  isNested,
}: CardDividerProps) {
  const isMobile = useShouldRenderMobileVersion()
  const aiRewriteEnabled = useFeatureFlag('aiRewrite') && !isMobile
  const appendCard = useCallback(
    (cardTemplateKey?: string) => {
      const COMMANDS_MAP = getCommandsMap()
      const start = getPos()
      const thisNode = editor.view.state.doc.nodeAt(start)
      if (!thisNode) {
        console.error('[CardDivider] Cannot append card. Node unknown')
        return
      }
      const end = start + thisNode.nodeSize
      let cardToAdd: JSONContent = EMPTY_NODES.blankCard
      if (cardTemplateKey) {
        cardToAdd = EMPTY_NODES[cardTemplateKey]
      }

      const method = cardTemplateKey
        ? NodeInsertMethods.CARD_DIVIDER_DROPDOWN
        : NodeInsertMethods.CARD_DIVIDER
      const item = cardTemplateKey
        ? COMMANDS_MAP[cardTemplateKey]
        : COMMANDS_MAP.blankCard

      trackItemInserted(item, method)

      editor
        .chain()
        .insertContentAt(end, assignCardIds(cardToAdd))
        .focus()
        .selectInsertedNode()
        .run()
    },
    [editor, getPos]
  )

  const dividerColor = useColorModeValue('gray.100', 'gray.700')

  return (
    <Menu isLazy>
      {({ isOpen }) => (
        <Flex
          contentEditable={false}
          suppressContentEditableWarning={true}
          className="card-divider"
          data-is-nested-card={String(isNested)}
          // Should be centered below the card
          position="absolute"
          zIndex={2} // Go over cards (1)
          bottom={-4}
          w="100%"
          justify="center"
          align="center"
          display={display}
          opacity={isOpen ? 1 : 0} // Hover CSS sets this to 1 in editorStyles.ts
          transitionProperty="opacity"
          transitionDuration="normal"
          data-print-hidden
          data-id="card-divider"
        >
          <ButtonGroup
            size="sm"
            isAttached
            data-guider-highlight="add-card-button"
            className="card-divider-button-group"
            variant="plain"
            shadow="md"
            borderRadius="md"
            opacity="1" // Hover CSS sets this to 1 in editorStyles.ts
            transitionProperty="opacity"
            transitionDuration="normal"
          >
            <GammaTooltip label={<Trans>Add blank card</Trans>}>
              <IconButton
                className="insert-card-button"
                variant="plain"
                aria-label={t`Insert card`}
                icon={<FontAwesomeIcon icon={solid('plus')} />}
                onClick={() => appendCard()}
                onMouseDown={preventDefaultAndStopPropagation}
                size="sm"
                shadow="none"
                borderRadius="md"
                minW={12}
                transitionProperty="none"
                borderColor={dividerColor}
              />
            </GammaTooltip>
            {aiRewriteEnabled && (
              <GammaTooltip label={<Trans>Add card with AI</Trans>}>
                <IconButton
                  borderLeft="none"
                  className="insert-ai-card-button"
                  variant="plain"
                  aria-label={t`Insert card`}
                  icon={
                    <HStack spacing={0}>
                      <FontAwesomeIcon icon={solid('sparkles')} />
                    </HStack>
                  }
                  onClick={() => {
                    const placeholder = '' // Hint to translators
                    TiptapEventBus.emit('queueDesignPartnerTask', {
                      message: t`Add a new card about ${placeholder}`,
                      submit: false,
                    })
                  }}
                  onMouseDown={preventDefaultAndStopPropagation}
                  size="sm"
                  shadow="none"
                  borderRadius="md"
                  minW={12}
                  transitionProperty="none"
                  borderColor={dividerColor}
                />
              </GammaTooltip>
            )}

            <GammaTooltip label={<Trans>Add from template</Trans>}>
              <MenuButton
                borderLeft="none"
                as={IconButton}
                color="gray.400"
                icon={<ChevronDownIcon />}
                shadow="none"
                fontSize="lg"
                onMouseDown={preventDefaultAndStopPropagation}
                maxW="6"
                borderRadius="md"
                minW="6"
                borderColor={dividerColor}
                transitionProperty="none"
                data-guider-highlight="add-card-template-button"
              />
            </GammaTooltip>
            <Portal>
              <CardTemplatesMenuList editor={editor} appendCard={appendCard} />
            </Portal>
          </ButtonGroup>
        </Flex>
      )}
    </Menu>
  )
})
