import { Editor, JSONContent, Range } from '@tiptap/core'
import { Fragment, Slice } from 'prosemirror-model'

import { AiModificationsPluginKey } from 'modules/tiptap_editor/extensions/AI/AiModifications/AiModificationsState'
import { parseExternalHtml } from 'modules/tiptap_editor/extensions/Clipboard/parseExternalHtml'
import { RelativeRange } from 'modules/tiptap_editor/utils/relativePosition'

import { ModificationContent } from './DesignPartnerModification'

export const getPreviewJsonFromCardId = (
  docJson: JSONContent,
  cardId: string
): JSONContent | null => {
  if (!docJson.content || !docJson.content[0].content) {
    return null
  }
  const card = docJson.content[0].content.find(
    (c: JSONContent) => c.attrs && c.attrs.id === cardId
  )
  if (!card) {
    return null
  }

  docJson.content[0].content = [card]
  return docJson
}

export const modificationContentToSlice = (
  editor: Editor,
  content: ModificationContent
): Slice => {
  let slice: Slice | null = null
  if (content.type === 'html') {
    const parsed = parseExternalHtml(content.html, editor.schema)
    slice = parsed.slice
  } else if (content.type === 'json') {
    // convert JSONContent to a Prosemirror slice
    const fragment = Fragment.fromJSON(editor.schema, content.json)
    slice = new Slice(fragment, 0, 0)
  } else {
    throw new Error(
      // @ts-expect-error
      `Unsupported Modification content type ${content.type}`
    )
  }
  return slice
}

/**
 * Finds or creates an entry mapping interaction -> RelativeRange
 */
export const getOrSetModifiedRangeId = (
  editor: Editor,
  rangeId: string,
  range: RelativeRange
): string => {
  const pluginState = AiModificationsPluginKey.getState(editor.state)
  if (!pluginState) {
    return rangeId
  }

  const existing = pluginState?.getExistingRangeId(editor.state, range) || null
  if (existing) {
    return existing
  }

  editor.commands.setInteractionRange?.(rangeId, range)
  return rangeId
}

export const getModificationRange = (
  editor: Editor,
  rangeId: string
): Range | null => {
  const pluginState = AiModificationsPluginKey.getState(editor.state)
  if (!pluginState) {
    null
  }
  return pluginState?.getRange(editor.state, rangeId) || null
}
