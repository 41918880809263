import { DOMParser as ProseMirrorDOMParser, Schema } from 'prosemirror-model'

import { SplitOptions } from './splitCards'
import { transformOutsidePastedContent } from './transformOutsidePastedContent'
import { transformPastedHTML } from './transformPastedHTML'

// Takes HTML from outside the editor, or copied from the editor
// and parses into a Slice for inserting back into the editor

export const parseExternalHtml = (
  html: string,
  schema: Schema,
  splitCardOptions: SplitOptions | undefined = undefined,
  preserveWhitespace = false
) => {
  const transformedHtml = transformPastedHTML(html)
  const dom = new DOMParser().parseFromString(transformedHtml, 'text/html')
  const slice = ProseMirrorDOMParser.fromSchema(schema).parseSlice(dom, {
    preserveWhitespace,
  })
  const transformedSlice = transformOutsidePastedContent(
    slice,
    schema,
    splitCardOptions
  )
  return {
    content: transformedSlice.content,
    dom: dom.body,
    // Note: prefer using the slice for replace operations since it handles inline content better
    slice: transformedSlice,
  }
}
