import { mergeAttributes, Node } from '@tiptap/core'

import { getStore } from 'modules/redux'

import { InnerEditorNodeViewRenderer } from '../../Footnote/InnerEditorNodeView'
import { CardNotesNodeView } from './CardNotesNodeView'
import { CardNotesSelectionPlugin } from './CardNotesSelectionPlugin'

export const CardNotes = Node.create({
  name: 'cardNotes',
  content: 'cardNotesBlock+',

  parseHTML() {
    return [
      {
        tag: 'div[class=card-notes]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { class: 'card-notes' }), 0]
  },

  renderText() {
    // So card notes text won't render as card titles
    return ''
  },

  addProseMirrorPlugins() {
    const store = getStore()
    return [CardNotesSelectionPlugin(this.editor, store)]
  },

  addNodeView() {
    return InnerEditorNodeViewRenderer(CardNotesNodeView, {
      nodeName: this.name,
      preventNodeTypes: ['doc', 'document', 'card'],
    })
  },
})
