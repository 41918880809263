import { mergeAttributes, Node } from '@tiptap/core'

import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'

import { configureJSONAttribute } from '../../utils'
import { attrsOrDecorationsChanged } from '../updateFns'
import { Doc as DocView } from './Doc'
import { defaultAttrs } from './DocumentAttrs/attributes'

export const DocRoot = Node.create({
  name: 'doc',
  topNode: true,
  content: 'document',
})

export const Document = Node.create<any>({
  name: 'document',
  content: 'card+',
  isolating: true,
  defining: true,
  selectable: false,

  addAttributes() {
    return {
      docId: {},
      // Deprecated - was previously a mix of strings ("classic") or and theme JSON objects
      // Leaving this tombstone as a reminder that we shouldn't reuse this attr name
      // theme: {},
      background: {
        default: defaultAttrs.background,
        ...configureJSONAttribute('background'),
      },
      docFlags: {
        default: defaultAttrs.docFlags,
        ...configureJSONAttribute('docFlags'),
      },
      format: {},
      customCode: {
        default: defaultAttrs.customCode,
        ...configureJSONAttribute('customCode'),
      },
      settings: {
        default: defaultAttrs.settings,
        ...configureJSONAttribute('settings'),
      },
      generateStatus: {
        default: defaultAttrs.generateStatus,
      },
      generateInfo: {
        default: defaultAttrs.generateInfo,
        ...configureJSONAttribute('generateInfo'),
      },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(DocView, {
      update: attrsOrDecorationsChanged,
      ignoreMutation: ({ mutation }) => {
        const element =
          mutation.target instanceof HTMLElement
            ? mutation.target
            : mutation.target.parentElement // This happens with text nodes

        // Note that selection is an added type from tiptap. The native MutationRecordType is
        //   type MutationRecordType = "attributes" | "characterData" | "childList";

        // See https://github.com/ueberdosis/tiptap/blob/4014af4c21edaa895eee7e6fb34b922de83da132/packages/core/src/NodeView.ts#L186
        const isMutationOutsideDocContent =
          mutation.type !== 'selection' && isElementOutsideDocContent(element)

        console.debug(
          '[Document.ignoreMutation] isMutationOutsideDocContent:',
          isMutationOutsideDocContent
        )

        if (isMutationOutsideDocContent) {
          // Ignore non-selection mutations on the doc content wrapper elements
          return true
        }
        return false
      },
    })
  },

  parseHTML() {
    return [
      {
        tag: 'div[class=gamma-doc]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { class: 'gamma-doc' }), 0]
  },
})

// Return true if we know for sure that the provided
// element is inside the Doc's nodeviewcontent
const isElementOutsideDocContent = (element: HTMLElement | null) => {
  const el = element?.parentElement

  if (!el) return false

  return !el.closest(`.document-content`)
}
