import { Button, HStack, IconButton } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Trans, t } from '@lingui/macro'
import { Editor } from '@tiptap/core'
import { useCallback } from 'react'

import { eventEmitter as cardAccentEventEmitter } from 'modules/tiptap_editor/extensions/Card/CardLayout/CardAccent/CardAccentEventEmitter'
import { BackgroundType } from 'modules/tiptap_editor/styles/types'

import { CardStyleRow } from './CardStyleRow'

type CardAccentImageSelectorProps = {
  editor: Editor
  accentPos: number | null
  cardId: string
  hasAccentImage: boolean
  isBehindLayout: boolean
}
export const CardAccentImageSelector = ({
  editor,
  accentPos,
  cardId,
  hasAccentImage,
  isBehindLayout,
}: CardAccentImageSelectorProps) => {
  const handleDeleteClick = useCallback(() => {
    if (!accentPos) return
    editor.commands.updateAttributesAtPos(accentPos, {
      background: { type: BackgroundType.NONE },
    })
  }, [editor, accentPos])

  const handleAddOrEditClick = useCallback(() => {
    // Open the EditBackgroundDrawer that lives inside the CardAccentLayoutItemView
    cardAccentEventEmitter.emit('toggleEditBackgroundDrawer', {
      openDrawer: true,
      cardId,
    })
  }, [cardId])

  return (
    <>
      <CardStyleRow
        label={
          isBehindLayout ? (
            <Trans>Background image</Trans>
          ) : (
            <Trans>Accent image</Trans>
          )
        }
        icon={regular('image')}
      >
        <HStack spacing={1}>
          <>
            <Button
              size="sm"
              variant="link"
              onClick={handleAddOrEditClick}
              colorScheme="black"
            >
              {hasAccentImage ? <Trans>Edit</Trans> : <Trans>+ Add</Trans>}
            </Button>
            {hasAccentImage && (
              <GammaTooltip
                label={<Trans>Remove accent image</Trans>}
                placement="top"
              >
                <IconButton
                  aria-label={t`Remove accent image`}
                  size="sm"
                  variant="link"
                  onClick={handleDeleteClick}
                  colorScheme="red"
                  icon={<FontAwesomeIcon icon={regular('trash')} />}
                />
              </GammaTooltip>
            )}
          </>
        </HStack>
      </CardStyleRow>
    </>
  )
}
