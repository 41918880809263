import { Box } from '@chakra-ui/react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Mention = 'card' | 'doc' | 'user'

const mentionIcons: Record<Mention, IconProp> = {
  card: regular('rectangle'),
  doc: regular('rectangle-history'),
  user: regular('at'),
}

export const MentionThumbnailSkeleton = ({ type }: { type: Mention }) => {
  const icon = mentionIcons[type]
  return (
    <Box
      as="span"
      display="inline-flex"
      className="mention"
      minW="5em"
      opacity="0.6"
      borderRadius="sm"
      color="gray.800"
    >
      <Box display="inline-flex" py="0.125em">
        <FontAwesomeIcon
          icon={icon}
          style={{ marginLeft: '0.5em', alignSelf: 'center' }}
        />
      </Box>
    </Box>
  )
}
