import { Editor } from '@tiptap/core'
import { Transaction } from 'prosemirror-state'

import {
  BackgroundOptions,
  BackgroundType,
} from 'modules/tiptap_editor/styles/types'

import { CardAttributes, CardLayout } from '../types'
import {
  findLayoutPreset,
  ensureCardLayoutItems,
  getCardLayoutItems,
} from './cardLayoutUtils'

export const setCardLayoutCommand = (
  editor: Editor,
  tr: Transaction,
  cardPos: number,
  layout: CardLayout,
  getBgFn?: () => BackgroundOptions
) => {
  const { state } = editor.view
  const cardNode = state.doc.nodeAt(cardPos)
  if (cardNode?.type.name !== 'card') {
    return false
  }
  const cardAttrs = cardNode.attrs as CardAttributes
  const preset = findLayoutPreset(layout)

  // it's important to start only with the correct layout items for the prev layout
  ensureCardLayoutItems(tr, cardPos, editor.schema, preset)
  if (layout === cardAttrs.layout) {
    // no-op we aren't actually changing
    return true
  }

  // set the layout attribute on cardPos
  tr.setNodeAttribute(cardPos, 'layout', layout)

  // Assign an accent image from the theme if there are any and the current background is blank
  const { accent } = getCardLayoutItems(tr, cardPos)
  if (
    getBgFn &&
    accent &&
    accent.node.attrs.background.type === BackgroundType.NONE
  ) {
    const background = getBgFn()
    if (background) {
      tr.setNodeAttribute(accent.pos, 'background', background)
    }
  }
  // handle resetting the card position
  const { layoutTemplateColumns } = cardNode.attrs
  const prevLayout = cardAttrs.layout
  if (
    layoutTemplateColumns &&
    ((prevLayout === 'left' && layout === 'right') ||
      (prevLayout === 'right' && layout === 'left'))
  ) {
    tr.setNodeAttribute(
      cardPos,
      'layoutTemplateColumns',
      layoutTemplateColumns.split(' ').reverse().join(' ')
    )
  }

  return true
}
