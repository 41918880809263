import { Box, Flex, IconButton, Text } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { NodeViewProps } from '@tiptap/core'
import { useCallback } from 'react'

import { useAppSelector } from 'modules/redux'
import { getThemeBase } from 'modules/theming/themeBases'
import { NodeViewContent } from 'modules/tiptap_editor/react'
import { selectEditable, selectTheme } from 'modules/tiptap_editor/reducer'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { AnnotatableNodeViewWrapper } from '../Annotatable/components/AnnotatableNodeViewWrapper'
import { ContainerDragHandle } from '../DragDrop/ContainerDragHandle/ContainerDragHandle'
import { findFirstChildFontSizeDeco } from '../Font/FontSizePlugin'
import { isFocusedAndEditable } from '../selection/FocusedNodes'
import { findSmartLayoutCellDecoration } from './decoration'
import { SmartLayoutCellAttrs } from './types'
import { getSmartLayoutOptions } from './utils'
import { getSmartLayoutVariant } from './variants'

export const SmartLayoutCellView = (nodeViewProps: NodeViewProps) => {
  const { node, decorations, editor, getPos } = nodeViewProps
  const { options, ...attrs } = node.attrs as SmartLayoutCellAttrs
  // Decoration has layout-level info like attrs and # of cells
  const deco = findSmartLayoutCellDecoration(decorations)
  const variant = getSmartLayoutVariant(deco.smartLayoutAttrs.variantKey)
  const { Cell } = variant

  const theme = useAppSelector(selectTheme)
  const themeBase = getThemeBase(theme)
  const smartLayoutOptions = getSmartLayoutOptions(deco.smartLayoutAttrs)
  const { firstChildSize } = findFirstChildFontSizeDeco(decorations)
  const isLast = deco.index === deco.numCells - 1
  const isEditable = useAppSelector(selectEditable)
  const isFocused = isFocusedAndEditable(decorations)

  const addCell = useCallback(() => {
    const pos = getPos()
    // Refetch the node because it might have changed without this view rerendering
    const nodeNow = editor.state.doc.nodeAt(getPos())
    if (!nodeNow) return
    editor.commands.insertSmartLayoutCell(pos + nodeNow.nodeSize)
  }, [editor, getPos])
  const addButtonPos = variant.addDirection
    ? variant.addDirection(smartLayoutOptions)
    : 'right'
  const addButtonProps =
    addButtonPos === 'bottom'
      ? { bottom: -3, right: 0, width: '100%', height: 6, justify: 'center' }
      : { right: -3, bottom: 0, height: '100%', width: 6, align: 'center' }

  const selectCell = useCallback(() => {
    if (!isEditable) return
    editor.commands.selectNodeAtPos(getPos())
  }, [editor, getPos, isEditable])

  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps}>
      <Box
        display="contents"
        position="relative"
        sx={themeBase.smartLayoutCellSx}
        data-spotlight-parent
      >
        <Cell
          cellOptions={options}
          layoutOptions={smartLayoutOptions}
          theme={theme}
          {...deco}
          {...attrs}
          {...nodeViewProps}
          firstChildSize={firstChildSize}
          isFocused={isFocused}
          selectCell={selectCell}
          variant={variant}
          dragHandle={
            <ContainerDragHandle
              {...nodeViewProps}
              handlePlacement="top"
              data-testid="smart-layout-cell-drag-handle"
              label={
                <Flex direction="column" align="center">
                  <Trans>
                    Select item<Text color="gray.500">Drag to rearrange</Text>
                  </Trans>
                </Flex>
              }
            />
          }
        >
          <NodeViewContent
            style={{ maxWidth: '100%', wordBreak: 'break-word' }}
          />
          {isEditable && isLast && (
            <Flex
              position="absolute"
              {...addButtonProps}
              opacity="0"
              transitionProperty="opacity"
              transitionDuration="normal"
              zIndex="1"
              className="add-button"
            >
              <GammaTooltip label={<Trans>Add item</Trans>} placement="top">
                <IconButton
                  icon={<FontAwesomeIcon icon={regular('plus')} />}
                  aria-label={t`Add item`}
                  position="absolute"
                  size="xs"
                  variant="plain"
                  isRound
                  onClick={addCell}
                  onMouseDown={preventDefaultToAvoidBlur}
                />
              </GammaTooltip>
            </Flex>
          )}
        </Cell>
      </Box>
    </AnnotatableNodeViewWrapper>
  )
}
