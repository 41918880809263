import { EditorState } from 'prosemirror-state'

import { CardIds, CardIdMap } from '../../../types'
import { findParentNodes } from '../../../utils'
import { isCardNode } from '../../../utils/nodeHelpers'

export const generateCardIdMap = (
  state: EditorState
): { cardIds: CardIds; cardIdMap: CardIdMap } => {
  const cardIds: string[] = []
  const cardIdMap: CardIdMap = {
    tree: {
      /**
       * A map of all cardIds relative to each other
       * E.g.
       * {
       *   card1: {
       *     card1A: {}
       *   },
       *   card2: {
       *     card2A: {
       *       card2A.1: {}
       *       card2A.2: {}
       *     },
       *     card2B: {}
       *   }
       * }
       */
    },
    parents: {
      /**
       * A map of every single card in the tree's parents.
       * Provides a specific keypath to any card in the tree.
       * E.g.
       *
       * {
       *   card1: []
       *   card1A: [card1]
       *   card2: []
       *   card2A: [card2]
       *   card2A.1: [card2, card2A]
       *   card2A.2: [card2, card2A]
       *   card2B: [card2]
       * }
       */
    },
    treeArray: [
      /**
       * An array of all cards in the tree, with their children
       *
       * E.g.
       *
       * [
       *   {
       *     id: "card1",
       *     children: [
       *       {
       *         id: "card1A",
       *         children: [],
       *       },
       *     ],
       *   },
       *   {
       *     id: "card2",
       *     children: [
       *       {
       *         id: "card2A",
       *         children: [
       *           {
       *             id: "card2A.1",
       *             children: [],
       *           },
       *           {
       *              id: "card2A.2",
       *              children: [],
       *           },
       *         ],
       *       },
       *       {
       *         id: "card2B",
       *         children: [],
       *        },
       *     ],
       *   },
       * ]
       */
    ],
  }
  state.doc.descendants((n, pos) => {
    if (!isCardNode(n)) return
    const $pos = state.doc.resolve(pos)
    const cardId = n.attrs.id as string
    cardIds.push(cardId)

    const parentCards: string[] = findParentNodes($pos, isCardNode)
      .reverse()
      .map(({ node }) => node.attrs.id)

    const keypathToCard = [...parentCards, cardId]
    keypathToCard.reduce((acc, id) => {
      acc[id] = acc[id] || {}
      return acc[id]
    }, cardIdMap.tree)
    cardIdMap.parents[cardId] = parentCards

    keypathToCard.reduce((acc, id) => {
      const parent = acc.find((c) => c.id === id)
      if (!parent) {
        acc.push({ id: cardId, children: [] })
        return acc
      }
      return parent.children
    }, cardIdMap.treeArray)
  })

  return { cardIds, cardIdMap }
}
