import { Extension } from '@tiptap/core'
import { nanoid } from 'nanoid'
import { Node } from 'prosemirror-model'

export const getAttrsFromBookmark =
  (storage: Extension['storage']) => (el: HTMLElement) => {
    const bookmark = el.getAttribute('bookmark')
    if (!bookmark) return false
    // Future idea: put all the bookmarks in the Sal extension
    // Not possible now because parseHTML doesn't have access to editor, only to storage.
    // const node = editor.storage[SalExtension.name].bookmarks[bookmark]
    const node = storage.bookmarks[bookmark]
    if (!node) {
      // This node had a bookmark attr, but we don't see a match in storage
      return false
    }
    return node.attrs
  }

export const getContentFromBookmark =
  (storage: Extension['storage']) => (el: HTMLElement) => {
    const bookmark = el.getAttribute('bookmark')
    console.log(
      'getContentFromBookmark',
      bookmark,
      storage.bookmarks[bookmark!]
    )
    if (!bookmark) return false
    const node = storage.bookmarks[bookmark]
    if (!node) {
      // This is a new card we didn't send in - created by the AI
      // todo: we could create a card here from the title attr
      return false
    }
    return node.content
  }

export const addBookmarkStorage = () => {
  return {
    bookmarks: {},
  }
}

export const bookmarkNode = (
  storage: Extension['storage'],
  node: Node
): string => {
  const id = nanoid(5)
  storage.bookmarks[id] = node
  return id
}
