export const MAX_WIDTH = '34em'

// When using inside text, the minimum width of the smallest step (to leave room for text inside)
export const MIN_PCT_INSIDE = 45

// If the pyramid takes up < 50% of the container, it should stack vertically
export const MIN_CONTAINER_SIZE = 0.5

// Between steps, and between the steps and the line
export const PYRAMID_GAP = '0.25em'
export const STAIRCASE_GAP = '0.5em'
