import { Button, HStack, IconButton } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { Editor, NodeViewProps } from '@tiptap/core'
import { useCallback } from 'react'

import { BackgroundType } from 'modules/tiptap_editor/styles/types'

import { CardStyleRow } from './CardStyleRow'

type CardBackdropSelectorProps = {
  editor: Editor
  getPos: NodeViewProps['getPos']
  onAddOrEditClick: () => void
  isDisabled: boolean
  hasBackdrop: boolean
}
export const CardBackdropSelector = ({
  editor,
  getPos,
  onAddOrEditClick,
  isDisabled,
  hasBackdrop,
}: CardBackdropSelectorProps) => {
  const removeCardBackdrop = useCallback(() => {
    editor.commands.updateAttributesAtPos(getPos(), {
      background: { type: BackgroundType.NONE },
    })
  }, [editor, getPos])

  return (
    <>
      <CardStyleRow
        icon={regular('panorama')}
        label={<Trans>Backdrop</Trans>}
        color={isDisabled ? 'gray.500' : undefined}
      >
        {isDisabled && (
          <GammaTooltip
            placement="top"
            label={<Trans>Full width hides the backdrop</Trans>}
            isDisabled={!isDisabled}
          >
            <FontAwesomeIcon fixedWidth icon={regular('eye-slash')} />
          </GammaTooltip>
        )}

        <HStack spacing={1}>
          {!isDisabled && (
            <>
              <Button
                size="sm"
                variant="link"
                onClick={onAddOrEditClick}
                colorScheme="black"
              >
                {hasBackdrop ? <Trans>Edit</Trans> : <Trans>+ Add</Trans>}
              </Button>
              {hasBackdrop && (
                <GammaTooltip
                  label={<Trans>Remove backdrop</Trans>}
                  placement="top"
                >
                  <IconButton
                    aria-label={t`Remove backdrop`}
                    size="sm"
                    variant="link"
                    onClick={removeCardBackdrop}
                    colorScheme="red"
                    icon={<FontAwesomeIcon icon={regular('trash')} />}
                  />
                </GammaTooltip>
              )}
            </>
          )}
        </HStack>
      </CardStyleRow>
    </>
  )
}
