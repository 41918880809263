import { Node } from 'prosemirror-model'

/**
 * Must enumerate all feature flags here
 */
export type DocFlags = {
  cardLayoutsEnabled: boolean
}
export const DOC_FLAG_DEFAULTS: Readonly<DocFlags> = {
  cardLayoutsEnabled: false,
}

export const getDocFlags = (docOrDocument?: Node): DocFlags => {
  // this function can take either the doc or document
  if (!docOrDocument) {
    return { ...DOC_FLAG_DEFAULTS }
  }

  let document: Node | null = null
  if (docOrDocument.type.name === 'doc') {
    document = docOrDocument.nodeAt(0)
  } else if (docOrDocument.type.name === 'document') {
    document = docOrDocument
  }

  if (!document) {
    console.debug(
      '[getDocFlags] could not find document node, returning defaults'
    )
    return { ...DOC_FLAG_DEFAULTS }
  }

  return {
    ...DOC_FLAG_DEFAULTS,
    ...document.attrs.docFlags,
  }
}
