import { Editor } from '@tiptap/core'

import { replaceState } from 'modules/history'
import { getTopCenterPosPct } from 'modules/tiptap_editor/utils'
import { updateCardHash } from 'modules/tiptap_editor/utils/url'

export const navigateToCardLink = (
  editor: Editor,
  event: MouseEvent | React.MouseEvent,
  cardId?: string | null
) => {
  if (!cardId) return

  const topPosPct = getTopCenterPosPct(editor, 100)
  if (topPosPct) {
    replaceState({
      data: { fromPos: topPosPct.pos, fromPct: topPosPct.pct },
      emitChange: false,
    })
  }

  updateCardHash({
    cardId,
    method: 'push',
    data: { fromPos: null, fromPct: null },
  })
}
