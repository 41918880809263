import { Box, Flex, SimpleGrid } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'
import { memo } from 'react'

import { HEADING_CLASS } from 'modules/theming/styles/heading'
import { getThemeBase } from 'modules/theming/themeBases'

import { getAlignStyles } from '../../../HorizontalAlign/HorizontalAlign'
import { HorizontalAlignment } from '../../../HorizontalAlign/types'
import { EditableLabel } from '../../components/EditableLabel'
import { useCellColor } from '../../components/hooks'
import { EmptyCellContent } from '../../constants'
import { CellSizeOption, useNumColumns } from '../../options/CellSizeOption'
import { HorizontalAlignOption } from '../../options/HorizontalAlignOption'
import {
  SmartLayoutCellComponent,
  SmartLayoutVariant,
  SmartLayoutWrapperComponent,
} from '../../types'
import { getStatsPercent } from './utils'

export const BarStatsWrapper: SmartLayoutWrapperComponent = memo(
  ({ children, options, containerSize, numCells }) => {
    const { columns } = useNumColumns({ containerSize, options, numCells })

    return (
      <SimpleGrid
        columns={columns}
        rowGap="3em"
        columnGap="1.5em"
        data-selection-ring="padded"
        pos="relative"
        borderRadius="var(--box-border-radius)"
      >
        {children}
      </SimpleGrid>
    )
  }
)
BarStatsWrapper.displayName = 'BarStatsWrapper'

export const BarStatsCell: SmartLayoutCellComponent = memo((props) => {
  const {
    theme,
    children,
    layoutOptions,
    cellOptions,
    label,
    updateAttributes,
    isContentEmpty,
    isLayoutFocused,
    dragHandle,
  } = props
  const base = getThemeBase(theme)
  // If this cell has a color set, we need recalculate CSS vars for lines/borders and text colors
  const { colorVars } = useCellColor(layoutOptions, cellOptions)
  const horizontalAlign: HorizontalAlignment = layoutOptions.horizontalAlign
  const alignStyles = getAlignStyles(horizontalAlign || 'left')
  const sx = {
    ...alignStyles,
    ...base.smartLayoutContentSx,
  }
  const percentFilled = getStatsPercent(props)

  return (
    <Flex
      borderRadius="var(--box-border-radius)"
      direction={'column'}
      align="center"
      justify="start"
      sx={sx}
      data-selection-ring="padded"
      data-content-reference
      pos="relative"
      gap={isContentEmpty && !isLayoutFocused ? 0 : '0.75em'}
    >
      <Flex
        direction="row"
        align="center"
        justify="stretch"
        width="100%"
        pos="relative"
        sx={colorVars}
        contentEditable={false}
        gap="0.75em"
      >
        <Box flex="1" sx={base.smartLayoutBoxSx || base.boxSx} my="0.5em">
          <Box
            w={`${percentFilled * 100}%`}
            sx={base.cssAccentSx}
            borderRadius="inherit"
            h="1.25em"
          />
        </Box>
        <EditableLabel
          value={label || ''}
          updateAttributes={updateAttributes}
          className={cx(HEADING_CLASS)}
          lineHeight={1}
          display="flex"
          alignItems="center"
          sx={alignStyles}
          fontSize="1.25em"
          inputProps={{
            textAlign: 'right',
            width: '4em',
            py: 0,
          }}
          minW="1em"
        />
      </Flex>
      <Box w="100%" data-content-empty={isContentEmpty}>
        {children}
      </Box>
      {dragHandle}
    </Flex>
  )
})
BarStatsCell.displayName = 'BarStatsCell'

export const BarStats: SmartLayoutVariant = {
  key: 'barStats',
  name: () => t`Bar stats`,
  commandName: () => t`Bar stats layout (blank)`,
  icon: regular('bars-progress'),
  options: [CellSizeOption, HorizontalAlignOption],
  cellOptions: [],
  Wrapper: BarStatsWrapper,
  Cell: BarStatsCell,
  defaultContent: EmptyCellContent,
  defaultAttrs: {
    label: '50%',
  },
  addDirection() {
    return 'right'
  },
  keywords: ['stats', 'bar', 'progress', 'number', 'percentage'],
  htmlTag: 'barStats',
  featureFlag: 'statsSmartLayouts',
}
