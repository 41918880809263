import {
  AvatarGroup,
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import { TooltipAvatar } from '@gamma-app/ui'
import { Trans } from '@lingui/macro'
import DOMPurify from 'isomorphic-dompurify'
import { MouseEvent, MutableRefObject } from 'react'

import placeholderBackground from 'gamma_components/placeholderBackground.svg'
import { SearchDocResultFragment } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { PseudoSearchDocResultFragment } from 'modules/search/types'
import { normalizeDate } from 'utils/date'
import { isMobileDevice } from 'utils/deviceDetection'
import { generateDocUrl } from 'utils/url'

type DocSearchResultItemProps = {
  result: SearchDocResultFragment | PseudoSearchDocResultFragment
  isActive?: boolean
  focusedItemEl?: MutableRefObject<HTMLDivElement | null>
  onMouseEnter?: (e: MouseEvent) => void
  context?: 'globalSearch' | 'mentionsList'
}

export const DocSearchResultItem = ({
  result,
  isActive,
  focusedItemEl,
  onMouseEnter,
  context = 'globalSearch',
}: DocSearchResultItemProps) => {
  const screenshotsEnabled = useFeatureFlag('screenshotsEnabled')
  const { title, text, previewUrl, editors, editedTime, id, createdBy } = result
  const isGlobalSearchContext = context === 'globalSearch'
  const isMentionsListContext = context === 'mentionsList'

  return (
    <LinkBox>
      <HStack
        ref={isActive ? focusedItemEl : null}
        className="search-result"
        wordBreak="break-word"
        onMouseEnter={onMouseEnter}
        p={isMentionsListContext ? 0 : [2, 3]}
        py={isMentionsListContext ? 0.5 : undefined}
        align="flex-start"
        borderRadius="md"
        transitionProperty="common"
        transitionDuration="normal"
        role="group"
        bg={isActive ? 'gray.100' : 'transparent'}
        cursor="pointer"
        color="gray.800"
        borderWidth="1px"
        borderColor={isActive ? 'gray.200' : 'transparent'}
        spacing={{ base: 0, sm: 4 }}
        sx={{
          em: {
            bg: 'var(--chakra-colors-trueblue-100)',
            fontStyle: 'normal',
            borderRadius: 'base',
            display: 'inline-block',
            padding: '0px 2px',
            margin: '0px -2px',
          },
        }}
      >
        <Image
          display={{ base: 'none', xs: 'block' }}
          h="100%"
          src={screenshotsEnabled ? previewUrl : placeholderBackground.src}
          w={{ base: '0px', sm: '25%' }}
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          shadow="lg"
          fallbackSrc={placeholderBackground.src}
        />
        <Stack spacing={isMobileDevice() ? 1 : 2} flex="1">
          <LinkOverlay
            href={
              isGlobalSearchContext ? generateDocUrl({ docId: id }) : undefined
            }
          >
            <Text
              fontSize="md"
              fontWeight="600"
              noOfLines={isMentionsListContext ? 1 : 2}
              dangerouslySetInnerHTML={{
                __html: title ? DOMPurify.sanitize(title) : '',
              }}
            />
            {createdBy?.displayName && (
              <Text fontSize="xs" color="gray.600" fontWeight="500">
                <Trans>Created by {createdBy?.displayName}</Trans>
              </Text>
            )}
          </LinkOverlay>
          <Text
            fontSize={isMentionsListContext ? 'xs' : 'sm'}
            noOfLines={2}
            whiteSpace="normal"
            wordBreak="break-word"
            dangerouslySetInnerHTML={{
              __html: text ? DOMPurify.sanitize(text) : '',
            }}
          />
          <HStack>
            {editors && editors.length > 0 && (
              <AvatarGroup size="xs" fontSize="xs" max={5}>
                {editors?.map(({ user }) => {
                  const { id: userId, displayName, profileImageUrl } = user
                  return (
                    <TooltipAvatar
                      label={displayName}
                      key={userId}
                      src={profileImageUrl}
                      shadow="base"
                    />
                  )
                })}
              </AvatarGroup>
            )}
            {editedTime && (
              <Text fontSize="xs" color="gray.600" fontWeight="500">
                <Trans>Edited {normalizeDate(editedTime)}</Trans>
              </Text>
            )}
          </HStack>
        </Stack>
      </HStack>
    </LinkBox>
  )
}
