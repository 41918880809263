// A version of the pyramid layout where the labels are below the pyramid,
// used in mobile or narrow columns

import { Box, Flex } from '@chakra-ui/react'
import { range } from 'lodash'
import { memo } from 'react'

import { getThemeBase } from 'modules/theming/themeBases'
import { useShouldRenderMobileVersion } from 'modules/tiptap_editor/hooks'
import { isMobileDevice } from 'utils/deviceDetection'

import { getAlignStyles } from '../../../HorizontalAlign/HorizontalAlign'
import { BulletMarker } from '../../components/BulletMarker'
import { CELL_PADDING } from '../../constants'
import {
  SmartLayoutCellComponent,
  SmartLayoutDecorationSpec,
  SmartLayoutWrapperProps,
} from '../../types'
import { MIN_CONTAINER_SIZE, PYRAMID_GAP } from './constants'
import { PyramidLabel, PyramidStep, PyramidStepProps } from './Pyramid'

// On narrow screens like mobile, or in colummns/card layouts, the pyramid should wrap to have text underneath
export const useShouldStackContent = (containerSize: number) => {
  const isNarrowScreen = useShouldRenderMobileVersion()
  const shouldStack = containerSize < MIN_CONTAINER_SIZE || isNarrowScreen
  return shouldStack
}

export const checkStacked = (deco: SmartLayoutDecorationSpec) =>
  isMobileDevice() || deco.containerSize < MIN_CONTAINER_SIZE

type StackedPyramidWrapperProps = SmartLayoutWrapperProps & {
  Step?: React.FC<PyramidStepProps>
}

// If the pyramid is stacked, the wrapper renders the steps and then the
// cells take care of the captions
export const StackedPyramidWrapper = memo(
  ({ Step = PyramidStep, ...props }: StackedPyramidWrapperProps) => {
    const { children, numCells, options, theme } = props
    const alignment = options.horizontalAlign || 'center'
    const alignSx = getAlignStyles(alignment)
    const base = getThemeBase(theme)

    const steps = range(numCells).map((i) => {
      return (
        <Step
          key={i}
          index={i}
          numCells={numCells}
          base={base}
          isOutside={true}
          isNumbered={true}
          isStacked={true}
          isFunnel={props.variant.key === 'funnel'}
          alignment={alignment}
        >
          <PyramidLabel index={i} />
        </Step>
      )
    })

    return (
      <Flex direction="column" data-selection-ring align="stretch" gap="1em">
        {/* Pyramid */}
        <Flex
          direction="column"
          sx={alignSx}
          contentEditable={false}
          gap={PYRAMID_GAP}
        >
          {steps}
        </Flex>
        {/* Labels below */}
        <Flex gap="0.5em" direction={'column'}>
          {children}
        </Flex>
      </Flex>
    )
  }
)
StackedPyramidWrapper.displayName = 'StackedPyramidWrapper'

export const StackedPyramidCell: SmartLayoutCellComponent = memo((props) => {
  const { children, layoutOptions, ...markerProps } = props
  const { theme } = markerProps
  const base = getThemeBase(theme)
  return (
    <Flex
      direction="row"
      align="flex-start"
      gap={CELL_PADDING}
      w="100%"
      data-selection-ring
      data-content-reference
      pos="relative"
    >
      <BulletMarker
        {...markerProps}
        layoutOptions={layoutOptions}
        alignText
        markerFontSize="h4"
      ></BulletMarker>
      <Box
        flex="1"
        minW="0"
        sx={base.smartLayoutContentSx}
        pt="var(--block-margin)"
      >
        {children}
      </Box>
    </Flex>
  )
})
StackedPyramidCell.displayName = 'StackedPyramidCell'
