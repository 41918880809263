import {
  HStack,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t } from '@lingui/macro'
import { useCallback } from 'react'

import { SmartLayoutOption, SmartLayoutOptionControlProps } from '../types'

export type ShapeSize = 'sm' | 'md' | 'lg' | 'full'

const ShapeSizes: {
  label: () => string
  key: ShapeSize
}[] = [
  {
    label: () => t`Small`,
    key: 'sm',
  },
  {
    label: () => t`Medium`,
    key: 'md',
  },
  {
    label: () => t`Large`,
    key: 'lg',
  },
  {
    label: () => t`Full`,
    key: 'full',
  },
]

const ShapeSizeOptionControl = ({
  value,
  updateValue,
  option,
}: SmartLayoutOptionControlProps<ShapeSize | null>) => {
  let index = ShapeSizes.findIndex((s) => s.key === value)
  if (index === -1) {
    index = 3 // Show full if it's unset
  }
  const { label } = ShapeSizes[index]
  const onChange = useCallback(
    (pos: number) => {
      const { key } = ShapeSizes[pos]
      updateValue(key)
    },
    [updateValue]
  )

  return (
    <HStack spacing={2} mr={2}>
      <GammaTooltip
        placement="top"
        label={
          <VStack spacing={0}>
            <Text color="gray.400">{option.name()}</Text>
            <Text>{label()}</Text>
          </VStack>
        }
      >
        <Text fontSize="sm" fontWeight="600">
          <FontAwesomeIcon icon={option.icon!} fixedWidth />
        </Text>
      </GammaTooltip>
      <Slider
        min={0}
        max={ShapeSizes.length - 1}
        step={1}
        value={index}
        onChange={onChange}
        w="60px"
        focusThumbOnChange={false}
        colorScheme="trueblue"
        size="sm"
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <GammaTooltip
          placement="top"
          label={
            <VStack spacing={0}>
              <Text color="gray.400">{option.name!()}</Text>
              <Text>{label()}</Text>
            </VStack>
          }
        >
          <SliderThumb background="trueblue.500" />
        </GammaTooltip>
      </Slider>
    </HStack>
  )
}

export const ShapeSizeOption: SmartLayoutOption<ShapeSize | null> = {
  key: 'shapeSize',
  defaultValue: null,
  Control: ShapeSizeOptionControl,
  name: () => t`Shape size`,
  icon: regular('arrow-up-big-small'),
}
