import { ButtonGroup, HStack, Link, Text, useToast } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { undo } from '@gamma-app/y-prosemirror'
import { getCardTitle } from '@gammatech/lib/dist/prosemirror-helpers'
import { t, Trans } from '@lingui/macro'
import { useCallback } from 'react'

import { eventEmitter as cardSuggestionEventEmitter } from 'modules/ai/variations/AIEventEmitter'
import { CARD_SUGGESTION_DEFAULT_MESSAGE } from 'modules/ai/variations/useFetchCardSuggestions'
import { useFeatureFlag } from 'modules/featureFlags'
import { useModalDisclosure } from 'modules/modal_state/hooks/useModalDisclosure'
import { useCardState } from 'modules/tiptap_editor/extensions/Card/hooks/useCardState'

import { DeleteNodeButton } from '../buttons/DeleteNodeButton'
import { ToolbarButton } from '../buttons/ToolbarButton'
import { ExportCardModal } from '../modals/ExportCardModal'
import { CopyLinkButton } from './CardFormattingMenu/CopyLinkButton'
import { NodeFormattingMenuProps } from './types'

export const CardFormattingMenu = ({
  editor,
  selection,
  decorations,
}: NodeFormattingMenuProps) => {
  const { node, from: pos } = selection
  const { attrs, isNested, isFirstCard } = useCardState({
    node,
    decorations,
    editor,
  })
  const {
    isOpen: isExportCardOpen,
    onOpen: onExportCardOpen,
    onClose: onExportCardClose,
  } = useModalDisclosure({
    id: 'downloadCardAsImageDisclosure',
  })
  const canExportCard = useFeatureFlag('exportCard')

  const duplicateCard = useCallback(() => {
    editor?.commands.duplicateCard(pos)
  }, [editor, pos])

  const mergeIntoParent = useCallback(() => {
    editor?.commands.unnestCard(pos)
  }, [editor, pos])

  const mergeIntoPrevious = useCallback(() => {
    editor?.chain().mergeCardsAtPos(pos).focus().run()
  }, [editor, pos])

  const toast = useToast()

  const deleteCard = useCallback(() => {
    // Try to get the card title. `node`'s content may not be up to date,
    // so we fetch it using the pos right at delete time
    const nodeNow = editor.state.doc.nodeAt(pos)
    const title = nodeNow && getCardTitle(nodeNow.toJSON())
    const displayTitle = title ? `"${title}"` : 'card'
    editor.chain().deleteCard(pos).focus().run()

    const deletedString = t`Deleted ${displayTitle}.`
    const undoString = t`Undo`
    const toastId = toast({
      title: (
        <HStack>
          <Text noOfLines={1}>{deletedString}</Text>
          <Link
            textDecoration="underline"
            onClick={() => {
              undo(editor.state)
              toast.close(toastId)
            }}
          >
            {undoString}
          </Link>
        </HStack>
      ),
      status: 'info',
      duration: 3000,
      position: 'top',
    })
  }, [editor, pos, toast])

  const cardDesignerEnabled = useFeatureFlag('aiCardDesigner')
  const openCardSuggestionEditor = useCallback(() => {
    const cardId = selection.node?.attrs.id
    if (!cardId) return

    const userMessage = prompt(
      'What do you want to change?',
      CARD_SUGGESTION_DEFAULT_MESSAGE
    )
    if (!userMessage) return

    cardSuggestionEventEmitter.emit('openSuggestionEditor', {
      cardId,
      userMessage,
    })
  }, [selection])

  return (
    <>
      <ButtonGroup spacing={1} size="sm" alignItems="center">
        <ToolbarButton
          label={<Trans>Duplicate card</Trans>}
          testId="duplicate-card"
          onClick={duplicateCard}
          icon={regular('clone')}
        />
        {isNested && (
          <ToolbarButton
            label={<Trans>Merge into parent card</Trans>}
            testId="merge-into-parent-card"
            onClick={mergeIntoParent}
            icon={regular('arrow-up-left')}
          />
        )}
        {!isNested && !isFirstCard && (
          <ToolbarButton
            label={<Trans>Merge into card above</Trans>}
            testId="merge-into-card-above"
            onClick={mergeIntoPrevious}
            icon={regular('arrow-up')}
          />
        )}
        {cardDesignerEnabled && (
          <ToolbarButton
            onClick={openCardSuggestionEditor}
            icon={regular('cat-space')}
            label="Design with AI"
          />
        )}
        <CopyLinkButton attrs={attrs} />
        {canExportCard && (
          <>
            <ToolbarButton
              label={<Trans>Export card</Trans>}
              testId="download-card"
              onClick={onExportCardOpen}
              icon={regular('arrow-down-to-bracket')}
            />
            <ExportCardModal
              isOpen={isExportCardOpen}
              onClose={onExportCardClose}
              cardId={attrs.id}
              editor={editor}
            />
          </>
        )}
        <DeleteNodeButton editor={editor} handleDelete={deleteCard} />
      </ButtonGroup>
    </>
  )
}
