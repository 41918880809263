import { Flex, IconButton, SimpleGrid } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { NodeViewProps } from '@tiptap/core'
import { useCallback, useEffect } from 'react'

import { useShouldRenderMobileVersion } from 'modules/tiptap_editor/hooks'
import { useGammaBreakpointValue } from 'utils/breakpoints/useGammaBreakpointValue'

import { useAppSelector } from '../../../redux'
import { NodeViewContent } from '../../react'
import { selectContentEditable } from '../../reducer'
import { AnnotatableNodeViewWrapper } from '../Annotatable'
import { ContainerDragHandle } from '../DragDrop/ContainerDragHandle/ContainerDragHandle'
import { createColumnWidths } from '../tables/prosemirror-table/columnUtils'
import { MAX_COLUMNS } from './constants'
import { getLayoutChildren } from './utils'

const useFixLayoutColumns = ({
  getPos,
  updateAttributes,
  editor,
  node,
}: NodeViewProps) => {
  const $layout = editor.state.doc.resolve(getPos())

  const children = getLayoutChildren($layout)
  useEffect(() => {
    if (children.length !== node.attrs.colWidths.length) {
      // has to be in a set timeout to prevent an infinite dispatch loop
      // with updateAttributes
      setTimeout(() => {
        updateAttributes({
          colWidths: createColumnWidths(children.length),
        })
      }, 0)
    }
  }, [node, children, updateAttributes])
}

export const LayoutView = (nodeViewProps: NodeViewProps) => {
  const { node, editor, getPos } = nodeViewProps
  const numCells = node.content.childCount
  const isEditable = useAppSelector(selectContentEditable)
  const isMobileDevice = useShouldRenderMobileVersion()

  useFixLayoutColumns(nodeViewProps)
  // Wrap on small screen sizes
  const isEven = numCells % 2 == 0
  const maxColumns =
    useGammaBreakpointValue({
      base: 1,
      sm: isEven ? 2 : 1,
      md: isEven ? 2 : 3,
      lg: 4,
    }) || 4
  const columns = Math.min(maxColumns, numCells)

  const addColumn = useCallback(() => {
    editor.commands.addLayoutCell(getPos())
  }, [editor, getPos])

  const canAddColumn = numCells < MAX_COLUMNS && isEditable
  const templateColumns = isMobileDevice
    ? '100%'
    : (node.attrs.colWidths || []).map((w) => `${w}%`).join(' ')

  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps}>
      <SimpleGrid
        className={cx('grid-col-controls', isEditable && 'is-editable')}
        columns={columns}
        gap="var(--grid-border)"
        position="relative"
        gridTemplateColumns={templateColumns}
        data-selection-ring
      >
        <NodeViewContent className="layout-content"></NodeViewContent>
        <ContainerDragHandle
          {...nodeViewProps}
          label={t`Select layout`}
          data-testid="layout-drag-handle"
        />
        <Flex
          position="absolute"
          top="0"
          h="100%"
          align="center"
          w={6}
          right={-3}
          className="add-column"
          opacity="0"
          transitionProperty="opacity"
          transitionDuration="normal"
        >
          {canAddColumn && (
            <GammaTooltip label={<Trans>Add column</Trans>} placement="top">
              <IconButton
                icon={<FontAwesomeIcon icon={regular('plus')} />}
                aria-label={t`Add column`}
                position="absolute"
                size="xs"
                variant="plain"
                isRound
                zIndex="1"
                onClick={addColumn}
              />
            </GammaTooltip>
          )}
        </Flex>
      </SimpleGrid>
    </AnnotatableNodeViewWrapper>
  )
}
