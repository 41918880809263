import { Extension, NodeViewProps } from '@tiptap/core'
import { Node } from 'prosemirror-model'
import { NodeSelection, Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import { editorHasFocus } from '../../utils'
import { isGalleryNode } from '../media/Gallery'
import { TableMap } from '../tables/prosemirror-table'
import { CellSelection2 } from '../tables/prosemirror-table/CellSelection'
import { isTableNode } from '../tables/utils/nodes'

// Applying too many of these decorations (eg all paragraphs) seems to cause intermittent flickering
// We weren't doing anything with the decoration except on specific nodes anyway, so this relies on a whitelist
// https://linear.app/gamma-app/issue/G-1089/flickering-when-using-arrow-keys-up-and-down
const shouldDecorateFocusedNode = (node: Node) =>
  node.isLeaf || // All leaf nodes (eg image, video, mention) should get this, plus the following blocks
  [
    'paragraph',
    'table',
    'tableRow',
    'tableCell',
    'gridLayout',
    'gridCell',
    'heading',
    'title',
    'card',
    'cardLayoutItem',
    'cardAccentLayoutItem',
    'button',
    'buttonGroup',
    'gallery',
    'blockquote',
    'calloutBox',
    'smartLayout',
    'smartLayoutCell',
    'toggle',
    'navbar',
    'navbarLinks',
  ].includes(node.type.name)

const shouldDecorateSelectingAroundNode = (node: Node) =>
  node.isAtom || ['gallery', 'card'].includes(node.type.name)

// The focusedNode decoration only gets applied when:
// 1. The selection is on, inside, or around the node
// 2. It's in the list of nodes above
// 3. The editor is editable
// 4. The editor has focused
export const isFocusedAndEditable = (
  decorations: NodeViewProps['decorations']
) => decorations.some((decoration) => decoration.spec.focusedNode)

export const isSelected = (decorations: NodeViewProps['decorations']) =>
  decorations.some((decoration) => decoration.spec.isSelected)

export const isSelectingNode = (decorations: NodeViewProps['decorations']) =>
  decorations.some((decoration) => decoration.spec.isSelectingNode)

/**
 * Extension from https://github.com/PierBover/prosemirror-cookbook
 * Finds all nodes that contain the current input cursor or selection
 */

export const FocusedNodes = Extension.create({
  name: 'focusedNodes',

  addProseMirrorPlugins() {
    const editor = this.editor

    return [
      new Plugin({
        props: {
          decorations(state) {
            if (!editor.isEditable || !editorHasFocus(editor)) return
            const childFrameHasFocus =
              document.activeElement?.tagName === 'IFRAME'
            if (childFrameHasFocus) return
            const selection = state.selection
            const decorations = [] as Decoration[]

            state.doc.nodesBetween(
              selection.from,
              selection.to,
              (node, position, parent): boolean | void => {
                if (shouldDecorateFocusedNode(node)) {
                  const isRowSelected =
                    selection instanceof CellSelection2 &&
                    node.type.name === 'tableRow' &&
                    selection.getTableNode() === parent &&
                    selection.isRowSelection() &&
                    !selection.isColSelection()
                  const isTableSelected =
                    selection instanceof CellSelection2 &&
                    isTableNode(node) &&
                    selection.getTableNode() === node &&
                    selection.isSelectingAllCells()

                  const spec = {
                    focusedNode: true, // True whether selection is inside or around. One of the two below will always be true.
                    isSelected: selection.from <= position,
                    isFocusedInside:
                      selection.from > position ||
                      selection.to < position + node.nodeSize,
                    isSelectingNode:
                      (selection instanceof NodeSelection &&
                        selection.node === node) ||
                      isRowSelected ||
                      isTableSelected,
                    isSelectingAround:
                      shouldDecorateSelectingAroundNode(node) &&
                      selection.from <= position &&
                      selection.to >= position + node.nodeSize &&
                      !(selection instanceof NodeSelection),
                  }
                  const className = [
                    spec.focusedNode ? 'is-focused' : '',
                    spec.isSelected ? 'is-selected' : '',
                    spec.isFocusedInside ? 'is-focused-inside' : '',
                    spec.isSelectingNode ? 'is-selecting-node' : '',
                    spec.isSelectingAround ? 'is-selecting-around' : '',
                  ].join(' ')
                  decorations.push(
                    Decoration.node(
                      position,
                      position + node.nodeSize,
                      {
                        class: className,
                      },
                      spec
                    )
                  )
                }

                if (isGalleryNode(node) && selection.from <= position) {
                  // Don't decorate children of a gallery node if the whole gallery is selected
                  if (selection.from <= position) {
                    return false // This will prevent iterating down into the children
                  }
                }

                if (
                  node.type.name === 'table' &&
                  selection instanceof CellSelection2 &&
                  selection.isColSelection() &&
                  !selection.isSelectingAllCells()
                ) {
                  const cellPos = selection.$anchorCell.pos
                  const tableMap = TableMap.get(node)
                  const { left } = tableMap.findCell(cellPos - (position + 1))
                  decorations.push(
                    Decoration.node(
                      position,
                      position + node.nodeSize,
                      {},
                      { isColumnSelected: true, colNumber: left }
                    )
                  )
                }
              }
            )

            return DecorationSet.create(state.doc, decorations)
          },
        },
      }),
    ]
  },
})
