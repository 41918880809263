import {
  CommandProps,
  Extension,
  findChildren,
  findParentNode,
  InputRule,
  NodeWithPos,
} from '@tiptap/core'
import { cloneDeep } from 'lodash'
import { wrapIn } from 'prosemirror-commands'
import { NodeSelection } from 'prosemirror-state'

import { ACCENT_IMAGE_SOURCE_KEY } from 'modules/theming'
import { UniqueAttributePluginKey } from 'modules/tiptap_editor/plugins'
import {
  BackgroundOptions,
  BackgroundType,
} from 'modules/tiptap_editor/styles/types'
import { findDirectChildren } from 'modules/tiptap_editor/utils'
import {
  findSelectionInsideNode,
  getInsertedNodePos,
} from 'modules/tiptap_editor/utils/selection/findSelectionInsideNode'
import { findSelectionNearOrGapCursor } from 'modules/tiptap_editor/utils/selection/findSelectionNearOrGapCursor'

import { EMPTY_NODES } from '../../commands/emptyNodes'
import { isCardNode } from '../../utils/nodeHelpers'
import { pruneCardIds } from '../../utils/transform'
import {
  FilmstripCutAnnotationEvent,
  MergeCardsAnnotationEvent,
  MoveAnnotationEvent,
  RearrangeCardsEvent,
  SplitCardAnnotationEvent,
} from '../Annotatable/AnnotationExtension/types'
import { computeInsertNestedCardMoves } from '../Annotatable/utils'
import { FindParentNodeResult } from '../Layout'
import {
  getCardLayoutItems,
  isAccentCardLayoutItem,
} from './CardLayout/cardLayoutUtils'
import { CARD_DEPTH, CARD_NODE_NAME } from './constants'
import {
  findContentWrapperFromCard,
  isAtContentWrapperEdge,
  mergeCardBackward,
  mergeCardForward,
} from './mergeCardUtils'
import { findTopLevelCardsWithPos } from './utils'

import { Card, isCardEmpty } from './index'

type RearrangeArgs = {
  from: number
  to: number
  position: 'above' | 'below' | 'inside'
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    cardCommands: {
      splitCardAtSelection: () => ReturnType
      insertNestedCard: () => ReturnType
      insertCardAfter: () => ReturnType
      convertToNestedCard: () => ReturnType
      unnestCard: (pos: number) => ReturnType
      mergeCardsAtPos: (pos: number) => ReturnType
      mergeCardsOnDelete: (forward: boolean) => ReturnType
      deleteCardIfEmpty: (forward: boolean) => ReturnType
      rearrangeCards: (opts: RearrangeArgs) => ReturnType
      rearrangeCardsById: (
        orderedIds: string[],
        idsToMove: string[]
      ) => ReturnType

      duplicateCard: (pos: number) => ReturnType
      deleteCard: (pos: number) => ReturnType
      updateThemeAccentImages: (
        accentBackgrounds?: BackgroundOptions[],
        replaceAll?: boolean
      ) => ReturnType
      deleteCardsById: (ids: string[], isCut?: boolean) => ReturnType
      deleteCardsAfter: (cardId: string | null) => ReturnType
    }
  }
}

const SPLIT_REGEX = /^(\*\*\*)/

export const CardCommands = Extension.create({
  name: 'cardCommands',

  addCommands() {
    return {
      splitCardAtSelection: () => (props) => {
        splitCardAtSelection(props)
        return true
      },
      insertNestedCard:
        () =>
        ({ state, chain, dispatch, editor }) => {
          if (!dispatch) return true
          const { from, $from } = state.selection
          chain()
            .insertContentAndSelect(EMPTY_NODES.insertCardInside)
            .command(({ tr }) => {
              const toMove = computeInsertNestedCardMoves({
                editor,
                // we want the parent node, not the text selection which is at `from`
                pos: from - $from.parentOffset - 1,
                tr,
              })
              if (toMove.length) {
                // queue up move instructions until the
                requestAnimationFrame(() => {
                  editor.commands.moveAnnotations?.(toMove)
                })
              }
              return true
            })
            .run()
          return true
        },
      convertToNestedCard:
        () =>
        ({ state, dispatch, commands }) => {
          if (!dispatch) return true
          const result = wrapIn(state.schema.nodes[Card.name])(state, dispatch)
          // this works because state.tr and commands tr are the same instance
          commands.selectInsertedNode()
          return result
        },
      unnestCard:
        (pos) =>
        ({ state, dispatch, chain, tr }) => {
          const card = state.doc.nodeAt(pos)
          if (!card || !isCardNode(card)) return false
          if (!dispatch) return true

          const contentWrapper = findContentWrapperFromCard(tr, pos)
          if (!contentWrapper) {
            return false
          }

          // if we're unnesting a card cardLayoutItems, use the cardLayout
          const from = pos
          const to = pos + card.nodeSize

          // TODO test this
          chain()
            .insertContentAt({ from, to }, contentWrapper.node.toJSON().content)
            .selectInsertedNode()
            .command(({ tr }) => {
              // use merge cards here instead of unwrap node, becuase we are moving
              // content from a contentWrapper to an insertPos
              tr.setMeta('annotationEvent', <MergeCardsAnnotationEvent>{
                type: 'merge-cards',
                contentPos: contentWrapper.pos,
                insertPos: from,
              })
              return true
            })
            .run()
          return true
        },
      mergeCardsAtPos:
        (joinPos) =>
        ({ tr }) => {
          // this works because tr and commands tr are the same instance
          const parentCard = tr.doc.nodeAt(joinPos)
          if (!parentCard || !isCardNode(parentCard)) {
            return false
          }

          return mergeCardBackward(joinPos, tr)
        },
      mergeCardsOnDelete:
        (forward) =>
        ({ tr, state, dispatch }) => {
          if (!dispatch) return true
          const { selection } = state
          const parentCard = findParentNode(isCardNode)(selection)
          if (!parentCard || !selection.empty) return false

          try {
            const isAtEdge = isAtContentWrapperEdge(selection, forward)
            if (!isAtEdge) {
              return false
            }

            return forward
              ? mergeCardForward(parentCard.pos, tr, state)
              : mergeCardBackward(parentCard.pos, tr)
          } catch (err) {
            // We should never crash the app if join failed, so just log the error and move on
            console.debug('[CardCommands] mergeCardsOnDelete failed', err)
            return false
          }
        },
      insertCardAfter:
        () =>
        ({ state, chain, dispatch }) => {
          if (!dispatch) return true
          const { selection } = state

          const parentCard = findParentNode(isCardNode)(selection)
          if (!parentCard) return true

          const { pos, node } = parentCard
          const end = pos + node.nodeSize
          const newCard = EMPTY_NODES.insertCardInside
          chain()
            .insertContentAt(end, newCard)
            .selectInsertedNode()
            .scrollIntoView()
            .run()
          return true
        },
      deleteCardIfEmpty:
        (forward) =>
        ({ tr, dispatch, state }) => {
          if (!dispatch) return true
          let didDelete = false

          const { from, to } = state.selection
          state.doc.nodesBetween(from, to, (node, pos) => {
            // Find cards in/around the selection
            if (
              isCardNode(node) &&
              isCardEmpty(node) &&
              // Don't delete the first card, nowhere to backspace to
              pos > CARD_DEPTH
            ) {
              const isNestedCard = tr.doc.resolve(pos)!.depth > 1

              tr.deleteRange(pos, pos + node.nodeSize)

              /**
               * if it's a nested card we put cursor at pos and let the bias (forward -> 1, backward -> -1) determine the cursor
               * if it's not a nested card we need to subtract 1 to put at end of prev card or add 1 to put at beginning of new card
               */
              const newSelPos = pos + (isNestedCard ? 0 : forward ? 1 : -1)
              const sel = findSelectionNearOrGapCursor(
                tr.doc.resolve(newSelPos),
                forward ? 1 : -1
              )
              if (sel) {
                tr.setSelection(sel)
              }

              didDelete = true
            }
          })

          return didDelete
        },
      deleteCard:
        (pos: number) =>
        ({ state, dispatch, tr }) => {
          if (!dispatch) return true

          const $pos = state.doc.resolve(pos)
          const isNestedCard = $pos.depth > 1
          const node = $pos.nodeAfter

          if (!node || !isCardNode(node)) {
            console.error(
              '[CardExtension.deleteCard] Cannot delete card. Node unknown'
            )
            return false
          }

          tr.delete(pos, pos + node.nodeSize)
          const sel = findSelectionNearOrGapCursor(
            tr.doc.resolve(pos - (isNestedCard ? 0 : 1)),
            -1
          )
          if (sel) {
            tr.setSelection(sel)
          }

          return true
        },

      duplicateCard:
        (pos: number) =>
        ({ state, chain, editor }) => {
          const node = state.doc.nodeAt(pos)
          if (!node || !isCardNode(node)) {
            console.error(
              '[CardExtension.duplicateCard] Cannot duplicate card. Node unknown'
            )
            return false
          }
          const end = pos + node.nodeSize

          // The UniqueId extension SHOULD (does) handle removing cardIds for us,
          // but it appears sometimes there is a race condition which
          // causes our error checker in generateCardIdMap to fire before
          // the new card id is generated.
          // Delete them here with our prune utility avoid that error.

          // Note that Node.toJSON does NOT create a copy and thus is not safe to mutate,
          // so we make sure to cloneDeep it here
          // See https://github.com/ProseMirror/prosemirror-model/blob/95298fb02744e1a8f41eae50f8a6afde583a8817/src/node.js#L339-L350
          const newNode = pruneCardIds(cloneDeep(node.toJSON()))

          chain()
            .insertContentAt(end, newNode, { updateSelection: false })
            .command(({ tr }) => {
              tr.setMeta(UniqueAttributePluginKey, true)
              return true
            })
            .run()

          // this is needed now because duplicating with the formatting menu
          // tends to select the entire contents of the first card
          setTimeout(() => {
            editor.chain().selectInsideNodeAtPos(end).focus().run()
          }, 200)

          return true
        },

      rearrangeCards:
        ({ from, to, position }: RearrangeArgs) =>
        ({ view, state, tr }) => {
          const $to = state.doc.resolve(to)
          const $from = state.doc.resolve(from)
          const fromEndPos = $from.pos + $from.nodeAfter!.nodeSize
          const sel = new NodeSelection($from)
          const slice = sel.content()

          // figure out insertPos
          let insertPosRaw: number

          if (position === 'below') {
            insertPosRaw = $to.pos + $to.nodeAfter!.nodeSize
          } else if (position === 'above') {
            insertPosRaw = $to.pos
          } else {
            insertPosRaw = $to.pos + $to.nodeAfter!.nodeSize - 1
          }
          // inserting content at the same position
          // tell tiptap not to dispatch the transaction
          if (insertPosRaw === $from.pos) {
            tr.setMeta('preventDispatch', true)
            return true
          }

          tr.delete(sel.from, sel.to)
          const insertPos = tr.mapping.map(insertPosRaw)
          tr.replaceRangeWith(insertPos, insertPos, slice.content.firstChild!)

          const $pos = tr.doc.resolve(insertPos + 1)

          tr.setSelection(new NodeSelection($pos))
          const event: MoveAnnotationEvent = {
            type: 'move',
            insertPos,
            insertPosRaw,
            pos: $from.pos,
            end: fromEndPos,
          }
          tr.setMeta('annotationEvent', event)
          view.focus()

          return true
        },

      rearrangeCardsById:
        (orderedCardIds: string[], cardIdsToMove: string[]) =>
        ({ dispatch, tr, editor }) => {
          if (!dispatch) {
            return true
          }

          const allCards = findTopLevelCardsWithPos(editor.state.doc)
          if (!allCards) {
            return false
          }

          const allCardIds = allCards.map((card) => card.node.attrs.id)

          const cardsToMove = cardIdsToMove
            .filter((id) => allCardIds.includes(id))
            .map((id) => allCards.find((card) => card.node.attrs.id === id))
            .filter((c): c is NodeWithPos => Boolean(c))

          // Save the original positions of the moving cards for the annotation event
          const idsToMoveWithOldPos: { pos: number; id: string }[] = cardsToMove
            .map((c) => {
              return {
                pos: c.pos,
                id: c.node.attrs.id,
              }
            })
            .filter(({ id }) => {
              return id && typeof id === 'string'
            })

          const firstCardIdToMove = cardsToMove[0]?.node.attrs.id
          if (!firstCardIdToMove) {
            return true
          }

          // find the index of where the first card to move "should be"
          const firstCardIdToMoveIndex = Math.max(
            orderedCardIds.findIndex((a) => a === firstCardIdToMove),
            0
          )

          const insertAfterCardId = orderedCardIds[firstCardIdToMoveIndex - 1]
          const insertAfterCard = allCards.find(
            (card) => card.node.attrs.id === insertAfterCardId
          )

          const insertPos =
            firstCardIdToMoveIndex > 0 && insertAfterCard
              ? insertAfterCard.pos + insertAfterCard.node.nodeSize
              : allCards[firstCardIdToMoveIndex].pos

          if (insertPos === undefined) {
            return true
          }

          const revCards = [...cardsToMove].reverse()
          revCards.forEach((card) => {
            if (!card) {
              return
            }
            tr.replaceWith(insertPos, insertPos, card.node)
          })

          cardsToMove.forEach((card) => {
            if (!card) {
              return
            }
            const cardPos = tr.mapping.map(card.pos)
            tr.delete(cardPos, cardPos + card.node.nodeSize)
          })

          // Also save the updated positions of the moving cards for the annotation event
          const updatedCards = findTopLevelCardsWithPos(tr.doc)
          if (!updatedCards) {
            return true
          }
          const idsToMoveWithNewPos = updatedCards
            .map((a) => {
              return {
                pos: a.pos,
                id: a.node.attrs!.id as string,
              }
            })
            .filter(({ id }) => cardIdsToMove.includes(id))

          const rearrangedCardPositions: RearrangeCardsEvent['rearrangedCardPositions'] =
            idsToMoveWithNewPos
              .map(({ id, pos: newPos }) => {
                const oldPos = idsToMoveWithOldPos.find((a) => a.id === id)?.pos
                if (!oldPos) {
                  return null
                }
                return {
                  id,
                  oldPos,
                  newPos,
                }
              })
              .filter(
                (
                  a
                ): a is RearrangeCardsEvent['rearrangedCardPositions'][number] =>
                  Boolean(a)
              )

          const event: RearrangeCardsEvent = {
            type: 'rearrange-cards',
            rearrangedCardPositions,
            insertPos,
          }
          tr.setMeta('annotationEvent', event)

          return true
        },

      updateThemeAccentImages:
        (accentBackgrounds?: BackgroundOptions[], replaceAll = false) =>
        ({ editor, commands }) => {
          const isThemeWithoutAccentBackgrounds =
            !accentBackgrounds || accentBackgrounds.length === 0
          let index = 0
          editor.state.doc.descendants((node, pos) => {
            const isAccentLayoutItem = isAccentCardLayoutItem(node)
            if (isAccentLayoutItem || isCardNode(node)) {
              // Only replace all or replace empty backgrounds for accent card layout items
              const replaceAllAccentImages = replaceAll && isAccentLayoutItem
              const replaceEmptyBackground =
                isAccentLayoutItem &&
                node.attrs.background.type === BackgroundType.NONE
              if (
                node.attrs.background.source === ACCENT_IMAGE_SOURCE_KEY ||
                replaceAllAccentImages ||
                replaceEmptyBackground
              ) {
                if (isThemeWithoutAccentBackgrounds) {
                  commands.updateAttributesAtPos(pos, {
                    background: {
                      type: isAccentLayoutItem
                        ? BackgroundType.NONE
                        : undefined,
                    },
                  })
                } else {
                  commands.updateAttributesAtPos(pos, {
                    background:
                      accentBackgrounds[index % accentBackgrounds.length],
                  })
                  index++
                }
              }
            }
          })
          return true
        },

      deleteCardsById:
        (ids: string[], isCut: boolean = false) =>
        ({ editor, dispatch, tr }) => {
          if (!dispatch) {
            return true
          }

          const cardsWithPos = findChildren(
            editor.state.doc,
            (c) => isCardNode(c) && ids.includes(c.attrs.id)
          )

          if (!cardsWithPos.length) {
            return false
          }

          const deletedCards = cardsWithPos.map((a, index) => {
            return {
              pos: a.pos, // these are the positions of the cards before any deletions
              cardIndex: index,
            }
          })

          cardsWithPos.forEach(({ node, pos }) => {
            tr = tr.delete(
              tr.mapping.map(pos),
              tr.mapping.map(pos + node.nodeSize)
            )
          })

          // Put the selection at the same position of the first deleted card
          const cardsWithPosStart = cardsWithPos[0].pos

          const sel = findSelectionInsideNode(tr.doc.resolve(cardsWithPosStart))

          if (sel) {
            tr = tr.setSelection(sel)
          }

          if (isCut) {
            tr.setMeta('annotationEvent', <FilmstripCutAnnotationEvent>{
              type: 'filmstrip-cut',
              deleted: deletedCards,
            })
          }

          return true
        },

      deleteCardsAfter:
        (cardId: string | null) =>
        ({ editor, dispatch, commands }) => {
          if (!dispatch) {
            return true
          }

          const allCards = findDirectChildren(
            editor.state.doc.firstChild!,
            (n) => isCardNode(n)
          ).map((c) => c.node.attrs.id)

          if (cardId === null) {
            // just delete them all
            return commands.deleteCardsById(allCards)
          }

          const ind = allCards.indexOf(cardId)
          const toDelete = ind > -1 ? allCards.slice(ind + 1) : []
          if (toDelete.length === 0) {
            // nothing to do
            return true
          }

          return commands.deleteCardsById(toDelete)
        },
    }
  },

  addInputRules() {
    return [
      new InputRule({
        find: SPLIT_REGEX,
        handler: ({ state, range }) => {
          const { tr } = state
          splitCardAtSelection({
            tr: tr.deleteRange(range.from, range.to),
            dispatch: true,
          })
        },
      }),
    ]
  },
})

type SplitCardProps = {
  tr: CommandProps['tr']
  dispatch: CommandProps['dispatch'] | boolean
}

/**
 * Card splitting can happen in two ways currently:
 *  1. Regex InputRule -> /^(\*\*\*)/.  NOTE: the regex must happen at beginning of line
 *  2. Slash command (/split).  This can happen, beginning, middle or end of a line
 *
 *
 * Example using `/split`
 *
 * [  beforeLen  ]      [afterLen]
 * This is before /split and after
 *                     ^
 *                     enter pressed and split happens
 *
 * The result should be
 * <Card>
 *   <p>this is before</p>
 * </Card>
 * <Card>
 *   <p>| and after</p>
 *      ^
 *      cursor
 * </Card>
 *
 * Example using `/split` or `***` on an empty line
 *
 * RESULT:
 * <Card>
 *   <p></p>
 * </Card>
 * <Card>
 *   <p>|</p>
 *      ^
 *      cursor
 *   <p>next content</p>
 * </Card>
 *
 * Because of how `tr.split()` works, there may be extraneous blocks of empty text in the previous and/or new cards
 * This function handles cleaning that up.
 *
 * TO FIX THIS:
 *  if [beforeLen] is 0
 *   -> delete the empty block at the previous card
 *
 *  if [afterLen] is 0
 *   -> delete the empty block at the start of the next card
 *
 */
const splitCardAtSelection = ({ tr, dispatch }: SplitCardProps): void => {
  if (!dispatch) return
  const parentCard = findParentNode(isCardNode)(
    tr.selection
  ) as FindParentNodeResult
  if (!parentCard) {
    console.error("Couldn't find parent card while splitting", tr.selection)
    return
  }

  // This fix is to ensure that the accent item is before the body item
  // so that we can split the card and not have the accent image get taken with it
  const { accent, body } = getCardLayoutItems(tr, parentCard.pos)
  const isAccentAfter = accent && body && accent.pos > body.pos
  if (isAccentAfter) {
    // move the accent item to body.pos
    tr.delete(accent.pos, accent.pos + accent.node.nodeSize)
    tr.insert(body.pos, accent.node)
  }

  // dereference selection here since it will have been mapped from the above tr.delete/tr.insert
  const { selection } = tr
  const { $from } = selection
  const beforeLen = selection.$from.parentOffset
  const parentPos = selection.from - beforeLen - 1
  const afterLen =
    selection.$from.node().content.size - selection.$from.parentOffset

  tr.setMeta(UniqueAttributePluginKey, true) // Force UniqueAttribute extension to assign new ID to card node
  tr.setMeta('annotationEvent', <SplitCardAnnotationEvent>{
    type: 'split-card',
    // use before() here because we have a text selection, and we want the position of the node
    splitPos: $from.before(),
  })

  const { node, depth } = parentCard
  const { type, attrs } = node
  const splitDepth = $from.depth - depth + 1
  // https://prosemirror.net/docs/ref/#transform.Transform.split
  tr.split($from.pos, splitDepth, [
    {
      type,
      attrs: {
        ...attrs,
        // force new card to be layout blank
        layout: 'blank',
      },
    },
  ])

  let insertCardPos = getInsertedNodePos(tr, CARD_NODE_NAME)!.pos
  const newCardChildCount = tr.doc.nodeAt(insertCardPos)!.childCount
  if (beforeLen === 0) {
    tr.delete(parentPos, parentPos + tr.doc.nodeAt(parentPos)!.nodeSize)
    // if we deleted something we need to map over ONLY the most recent delete
    // to find the right cardInsertPos
    insertCardPos = tr.steps[tr.steps.length - 1].getMap().map(insertCardPos)
  }

  if (afterLen === 0 && newCardChildCount > 1) {
    const newPos = insertCardPos + 1
    tr.delete(newPos, newPos + tr.doc.nodeAt(newPos)!.nodeSize)
  }

  const newSel = findSelectionNearOrGapCursor(tr.doc.resolve(insertCardPos + 1))
  if (newSel) {
    tr.setSelection(newSel).scrollIntoView()
  }

  return
}
