import { Editor, findParentNodeClosestToPos } from '@tiptap/core'
import { ResolvedPos, Slice } from 'prosemirror-model'
import { NodeSelection } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'

import {
  BackgroundOptions,
  BackgroundType,
} from 'modules/tiptap_editor/styles/types'
import { findNodeAndParents, rectAtPos } from 'modules/tiptap_editor/utils'
import { isCardNode } from 'modules/tiptap_editor/utils/nodeHelpers'
import { isImageMimeType } from 'utils/image'

import { sliceSingleNode } from '../../Clipboard/defaultHandlePaste'
import { getDataTransferFiles } from '../../media/Upload/uploadPlugin'
import { CardAttributes, CardLayout } from '../types'
import { setCardLayoutCommand } from './cardLayoutCommands'
import { getCardLayoutItems, isAccentCardLayoutItem } from './cardLayoutUtils'

export type CardAccentDropTarget = {
  pos: number
  rect: DOMRect
  layout?: CardLayout
} & (
  | {
      isImageUpload: true
      file: File
    }
  | {
      isImageUpload: false
      background: Partial<BackgroundOptions>
    }
)

export const findParentCardAccentLayoutItem = ($pos: ResolvedPos) =>
  findParentNodeClosestToPos($pos, isAccentCardLayoutItem)

export const checkCardAccentDropTarget = (
  view: EditorView,
  event: DragEvent,
  slice?: Slice
): CardAccentDropTarget | null => {
  const coordPos = view.posAtCoords({
    left: event.clientX,
    top: event.clientY,
  })
  if (!coordPos || coordPos.inside < 0) {
    return null
  }
  const { doc } = view.state
  const { inside } = coordPos
  const $pos = doc.resolve(inside)
  const cardAccent = findNodeAndParents($pos, isAccentCardLayoutItem)
  const card = findNodeAndParents($pos, isCardNode)[0]
  const node = slice && sliceSingleNode(slice)
  const rect = rectAtPos(inside, view)

  if (cardAccent.length === 0 || !rect || !card) {
    return null
  }

  if (!node) {
    const files = getDataTransferFiles(event).filter((file) =>
      isImageMimeType(file.type)
    )
    if (files.length !== 1) {
      return null
    }

    return {
      pos: inside,
      rect,
      isImageUpload: true,
      file: files[0],
    }
  }

  if (node && node.type.name === 'image') {
    return {
      pos: inside,
      rect,
      isImageUpload: false,
      background: {
        type: BackgroundType.IMAGE,
        source: node.attrs.source,
        image: node.attrs,
      },
    }
  }
  return null
}

export const handleImageCardAccentDrop = (
  view: EditorView,
  e: DragEvent,
  slice?: Slice
) => {
  let dropTarget: CardAccentDropTarget | null

  try {
    dropTarget = checkCardAccentDropTarget(view, e as DragEvent, slice)
    if (!dropTarget) {
      return false
    }
  } catch (err) {
    console.error(
      '(caught) [CardLayoutPlugin] handleDrop checkGalleryDropTarget error:',
      err
    )
    // return false here so that the other dropHandlers can run
    return false
  }

  if (!dropTarget || dropTarget.isImageUpload) {
    return false
  }

  const tr = view.state.tr
  tr.setNodeAttribute(dropTarget.pos, 'background', dropTarget.background)
  tr.deleteSelection()

  view.dispatch(tr)
  return true
}

export const checkCreateCardAccentDropTarget = (
  view: EditorView,
  event: DragEvent,
  slice?: Slice
): (CardAccentDropTarget & { layout: CardLayout }) | null => {
  const coordPos = view.posAtCoords({
    left: event.clientX,
    top: event.clientY,
  })
  if (!coordPos || coordPos.inside < 0) {
    return null
  }
  const { doc } = view.state
  const { inside } = coordPos
  const $pos = doc.resolve(inside)
  const insideNode = doc.nodeAt(inside)
  if (!insideNode) {
    return null
  }
  const [card, ...parentCards] = findNodeAndParents($pos, isCardNode)
  const node = slice && sliceSingleNode(slice)
  const rect = rectAtPos(inside, view)
  const isCardLayoutItem = insideNode.type.name === 'cardLayoutItem'

  if (!card || !rect || !isCardLayoutItem || parentCards.length > 0) {
    return null
  }

  // we only look for drops when the card is blank or behind layout
  // for top/left/right we already look for drops on the cardAccentLayoutItem
  const cardAttrs = card.node.attrs as CardAttributes
  const result: { rect: DOMRect; layout: CardLayout } | null =
    cardAttrs.layout === 'blank'
      ? getRectAndLayoutForBlankLayout(event, rect)
      : cardAttrs.layout === 'behind'
      ? getRectAndLayoutForBehindLayout(event, rect)
      : null

  if (!result) {
    return null
  }

  if (!node) {
    const files = getDataTransferFiles(event).filter((file) =>
      isImageMimeType(file.type)
    )
    if (files.length !== 1) {
      return null
    }

    return {
      pos: card.pos,
      isImageUpload: true,
      file: files[0],
      ...result,
    }
  }

  if (node && node.type.name === 'image') {
    return {
      pos: card.pos,
      ...result,
      isImageUpload: false,
      background: {
        type: BackgroundType.IMAGE,
        source: node.attrs.source,
        image: node.attrs,
      },
    }
  }
  return null
}

const getRectAndLayoutForBehindLayout = (
  event: DragEvent,
  rect: DOMRect
): { layout: CardLayout; rect: DOMRect } | null => {
  const PADDING = 80
  if (
    event.clientY < rect.top + PADDING ||
    event.clientX < rect.left + PADDING ||
    event.clientX > rect.right - PADDING
  ) {
    return {
      layout: 'behind',
      rect,
    }
  }
  return null
}

const getRectAndLayoutForBlankLayout = (
  event: DragEvent,
  rect: DOMRect
): { layout: CardLayout; rect: DOMRect } | null => {
  let rectToShow: DOMRect = rect
  let side: CardLayout | undefined = undefined
  const TOP_THRESHOLD = Math.min(rect.height / 3, 100)
  // if the card is really short 100 is too much, and makes drop left + right tough
  if (event.clientX < rect.left + rect.width / 4) {
    side = 'left'
    rectToShow = {
      ...rect.toJSON(),
      left: rect.left,
      right: rect.left + rect.width / 3,
      top: rect.top,
      bottom: rect.bottom,
    }
  } else if (event.clientX > rect.right - rect.width / 4) {
    side = 'right'
    rectToShow = {
      ...rect.toJSON(),
      right: rect.right,
      left: rect.right - rect.width / 3,
      top: rect.top,
      bottom: rect.bottom,
    }
  } else if (event.clientY < rect.top + TOP_THRESHOLD) {
    side = 'top'
    rectToShow = {
      ...rect.toJSON(),
      left: rect.left,
      right: rect.right,
      top: rect.top,
      bottom: rect.top + Math.min(rect.height / 3, 200),
    }
  } else {
    return null
  }

  return { rect: rectToShow, layout: side }
}

/**
 * This handles drops from images in the editor, not image uploads
 */
export const handleImageDropCreateAccent = (
  editor: Editor,
  view: EditorView,
  e: DragEvent,
  slice?: Slice
) => {
  let dropTarget: CardAccentDropTarget | null

  try {
    dropTarget = checkCreateCardAccentDropTarget(view, e as DragEvent, slice)
    if (!dropTarget) {
      return false
    }
  } catch (err) {
    console.error(
      '(caught) [handleImageDropCreateAccent] handleDrop checkCreateCardAccentDropTarget error:',
      err
    )
    // return false here so that the other dropHandlers can run
    return false
  }

  if (!dropTarget || dropTarget.isImageUpload || !dropTarget.layout) {
    return false
  }
  const background = dropTarget.background

  const tr = view.state.tr
  tr.deleteSelection()
  setCardLayoutCommand(editor, tr, dropTarget.pos, dropTarget.layout)
  const { accent } = getCardLayoutItems(tr, dropTarget.pos)
  if (accent) {
    tr.setNodeAttribute(accent.pos, 'background', background)
    tr.setSelection(NodeSelection.create(tr.doc, accent.pos))
  }

  view.dispatch(tr)
  return true
}
