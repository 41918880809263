import { Box, Flex } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { NodeViewProps } from '@tiptap/react'
import { motion } from 'framer-motion'

import { config } from 'config'
import { useAppSelector } from 'modules/redux'
import { CLICKABLE_BOX_CLASS } from 'modules/theming/styles/box'
import { useShouldRenderMobileVersion } from 'modules/tiptap_editor/hooks'

import { selectEditable } from '../../../reducer'
import { AnnotatableNodeViewWrapper } from '../../Annotatable/components/AnnotatableNodeViewWrapper'
import { isFocusedAndEditable } from '../../selection/FocusedNodes'
import { isNodeViewInGallery } from '../Gallery'
import { WebEmbedAttrs } from '../types'
import {
  getZoomLayoutId,
  useMediaZoom,
  ZoomableOverlay,
  ZoomClickCapture,
  ZoomTransition,
} from '../Zoomable'
import { EmbedPlayer } from './EmbedPlayer'
import {
  EmbedPreview,
  MiniEmbedPreview,
  ThumbnailEmbedPreview,
} from './EmbedPreview'
import { getEmbedProvider } from './utils'

const MotionBox = motion(Box)
const MotionFlex = motion(Flex)

export const EmbedView = (nodeViewProps: NodeViewProps) => {
  const { node, editor, decorations } = nodeViewProps
  const { source, embed, displayStyle, id, sourceUrl } =
    node.attrs as WebEmbedAttrs
  const provider = getEmbedProvider(source)
  const { isZoomed, enterZoom, exitZoom } = useMediaZoom(id)
  const useHtmlEmbed = provider.preferHtml && embed?.html
  const inGallery = isNodeViewInGallery(nodeViewProps)

  const showInline =
    // Never show inline iframe within the context of puppeteer, because there's
    // no guarantee what will load in the iframe!
    !config.GAMMA_PUPPETEER_SERVICE &&
    (useHtmlEmbed || displayStyle === 'inline') &&
    !inGallery

  const isFocused = isFocusedAndEditable(decorations) || !editor.view.hasFocus()
  const isEditable = useAppSelector(selectEditable)
  const isMobileDevice = useShouldRenderMobileVersion()
  const renderZoomableOverlay = !inGallery && !editor.isThumbnail

  return (
    <AnnotatableNodeViewWrapper {...nodeViewProps}>
      <MotionBox
        layoutDependency={isZoomed}
        layoutId={!editor.isThumbnail ? getZoomLayoutId(id) : undefined}
        transition={ZoomTransition}
        h="100%"
        className={cx(
          'embed-preview',
          !useHtmlEmbed &&
            !(provider.inlineInteractive && showInline) &&
            CLICKABLE_BOX_CLASS
        )}
        data-export-as-image
        data-export-link={sourceUrl}
        position="relative"
      >
        {showInline && editor.isThumbnail ? (
          <ThumbnailEmbedPreview {...nodeViewProps} />
        ) : inGallery ? (
          <MiniEmbedPreview {...nodeViewProps} />
        ) : showInline ? (
          <EmbedPlayer {...nodeViewProps} useStealthNav={true} />
        ) : (
          <EmbedPreview {...nodeViewProps} />
        )}
        {provider.inlineInteractive && !isMobileDevice && showInline ? (
          <Box
            position="absolute"
            inset={0}
            zIndex={1} // Capture clicks when the embed is not selected and you're editing
            pointerEvents={
              isEditable && !isFocused && displayStyle === 'inline'
                ? 'auto'
                : 'none'
            }
            cursor="default"
          />
        ) : (
          <ZoomClickCapture enterZoom={enterZoom} />
        )}
      </MotionBox>
      {renderZoomableOverlay && (
        <ZoomableOverlay
          isZoomed={isZoomed}
          exitZoom={exitZoom}
          editor={editor}
        >
          <MotionFlex
            layoutDependency={isZoomed}
            layoutId={getZoomLayoutId(id)}
            transition={ZoomTransition}
            h="100%"
            w="100%"
            direction="column"
            justify="center"
            align="center"
          >
            <EmbedPlayer {...nodeViewProps} isZoomed />
          </MotionFlex>
        </ZoomableOverlay>
      )}
    </AnnotatableNodeViewWrapper>
  )
}
