import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'
import { memo } from 'react'

import { SmartLayoutCellComponent, SmartLayoutVariant } from '../../types'
import { Pyramid, PyramidCell } from './Pyramid'

const FunnelCell: SmartLayoutCellComponent = memo((props) => {
  return <PyramidCell {...props} isFunnel={true} />
})
FunnelCell.displayName = 'FunnelCell'

export const Funnel: SmartLayoutVariant = {
  ...Pyramid,
  key: 'funnel',
  name: () => t`Funnel`,
  commandName: () => t`Funnel layout (blank)`,
  icon: regular('filter-list'),
  htmlTag: 'funnel',
  Cell: FunnelCell,
}
