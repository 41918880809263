export const cursorStyles = {
  '&[contenteditable="true"]': {
    '&:focus-within': {
      '.ProseMirror-gapcursor': {
        display: 'block',
        height: '1.5em',
        position: 'relative',
        '&:after': {
          width: '0px',
          height: '100%',
          borderTop: '0px',
          borderLeft: '1px solid black',
        },
      },
      '.grid-cell': {
        '.ProseMirror-gapcursor:first-child': {
          marginTop: '1em',
        },
        '.ProseMirror-gapcursor:last-child': {
          marginBottom: '1em',
        },
      },
      '.block-table td': {
        '.ProseMirror-gapcursor:first-child': {
          marginTop: '.75em',
        },
        '.ProseMirror-gapcursor:last-child': {
          marginBottom: '.75em',
        },
      },

      '.is-dark': {
        '.ProseMirror-gapcursor:after': {
          borderLeftColor: 'white',
        },
      },
    },
  },

  '.collaboration-cursor__caret': {
    position: 'relative',
    marginLeft: '-1px',
    marginRight: '-1px',
    borderLeft: '1px solid #0d0d0d',
    borderRight: '1px solid #0d0d0d',
    wordBreak: 'normal',
    pointerEvents: 'none',
    textIndent: 0,
  },
  /* Render the username above the caret */
  '.collaboration-cursor__label': {
    position: 'absolute',
    top: '-1.4em',
    left: '-1px',
    fontSize: '12px',
    fontStyle: 'normal',
    fontFamily: "'Inter', sans-serif",
    fontWeight: '600',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textTransform: 'none',
    userSelect: 'none',
    color: '#0d0d0d',
    padding: '0.1rem 0.3rem',
    borderRadius: '3px 3px 3px 0',
    whiteSpace: 'nowrap',
    zIndex: 1,
    pointerEvents: 'auto',
    spellCheck: 'false',
    _hover: {
      opacity: 0.1,
    },
  },

  '&[contenteditable="false"]': {
    '.ProseMirror-gapcursor': {
      display: 'none',
    },

    '.collaboration-cursor__caret:not(.streaming-cursor)': {
      display: 'none',
    },
  },
}
