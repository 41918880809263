import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { EXPORT_ASPECT_RATIOS } from '@gammatech/lib/dist/cards/aspectRatios'
import { t } from '@lingui/macro'

export type CardDimensionsKey = keyof typeof EXPORT_ASPECT_RATIOS

export type CardDimensionsOption = {
  label: () => string
  description: () => string
  icon: FontAwesomeIconProps['icon']
  aspectRatio: number | null // w/h
  defaultVerticalAlign: 'top' | 'center' | 'bottom'
}

export const CardDimensionOptions: Record<
  CardDimensionsKey,
  CardDimensionsOption
> = {
  fluid: {
    label: () => t`Fluid`,
    icon: regular('arrows-from-dotted-line'),
    description: () => t`Best for most use cases`,
    aspectRatio: null,
    defaultVerticalAlign: 'center',
  },
  '16x9': {
    label: () => t`16:9`,
    icon: regular('rectangle-wide'),
    description: () => t`Good for exporting to PowerPoint or Google Slides`,
    aspectRatio: EXPORT_ASPECT_RATIOS['16x9'],
    defaultVerticalAlign: 'center',
  },
  a4: {
    label: () => t`A4 (landscape)`,
    icon: regular('rectangle-landscape'),
    description: () => t`Good for printing`,
    aspectRatio: EXPORT_ASPECT_RATIOS.a4,
    defaultVerticalAlign: 'center',
  },
  letter: {
    label: () => t`Letter (landscape)`,
    icon: regular('rectangle-landscape'),
    description: () => t`Good for printing`,
    aspectRatio: EXPORT_ASPECT_RATIOS.letter,
    defaultVerticalAlign: 'center',
  },
  square: {
    label: () => t`Square`,
    icon: regular('square'),
    description: () => t`Good for social media like Instagram or Linkedin`,
    aspectRatio: EXPORT_ASPECT_RATIOS.square,
    defaultVerticalAlign: 'center',
  },
}

export const getCardDimensions = (
  key: CardDimensionsKey | undefined
): CardDimensionsOption => {
  if (!key) return CardDimensionOptions.fluid
  return CardDimensionOptions[key] || CardDimensionOptions.fluid
}
