import { Box, Divider } from '@chakra-ui/react'

import { NodeViewWrapper } from 'modules/tiptap_editor/react/'

export const DividerView = () => {
  return (
    <NodeViewWrapper as="div">
      <Box
        contentEditable={false}
        borderRadius="var(--box-border-radius)"
        data-selection-ring="padded"
        data-selection-background
        data-drag-handle
        py={'0.25em'}
        position="relative"
        // to center the drag handle with the line
        transform="translateY(0.25em)"
      >
        <Divider
          borderColor="var(--body-color)"
          borderBottomWidth={'0.125em'}
          // To undo styles set in default theme here: https://github.com/chakra-ui/chakra-ui/blob/c469c38c6cdf774e20be45f9f8defa29b319bb89/packages/components/theme/src/components/divider.ts#L4
          opacity={0.5}
        />
      </Box>
    </NodeViewWrapper>
  )
}
