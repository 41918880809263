import {
  Image,
  MenuGroup,
  MenuItem,
  MenuList,
  useColorModeValue,
} from '@chakra-ui/react'
import { Editor } from '@tiptap/core'
import { memo } from 'react'

import { checkCommandDisabled } from 'modules/tiptap_editor/commands'

import { getGroupedCardTemplates } from './commands'

type CardTemplatesMenuListProps = {
  editor: Editor
  appendCard: (cardTemplateKey?: string) => void
}

export const CardTemplatesMenuList = memo(function CardTemplatesMenuList({
  editor,
  appendCard,
}: CardTemplatesMenuListProps) {
  const invertFilter = useColorModeValue(
    'invert(0)',
    'invert(.7) brightness(1.2)'
  )

  return (
    <MenuList
      data-guider-highlight="add-card-template-menu"
      maxH="400px"
      overflowY="auto"
    >
      {getGroupedCardTemplates().map(({ subcategory, items }) => {
        const enabledItems = items.filter(
          (item) => !checkCommandDisabled(editor, item, false)
        )
        if (enabledItems.length === 0) {
          return
        }
        return (
          <MenuGroup title={subcategory} key={subcategory}>
            {enabledItems.map((item) => {
              return (
                <MenuItem
                  key={item.name()}
                  icon={
                    (item?.previewImage?.src && (
                      <Image
                        src={item.previewImage.src}
                        alt={item.name()}
                        filter={invertFilter}
                      />
                    )) ||
                    undefined
                  }
                  onClick={() => {
                    appendCard(item.key)
                  }}
                >
                  {item.name()}
                </MenuItem>
              )
            })}
          </MenuGroup>
        )
      })}
    </MenuList>
  )
})
