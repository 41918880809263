import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'
import React, { memo } from 'react'

import { ThemeBase } from 'modules/theming'
import { HEADING_CLASS } from 'modules/theming/styles/heading'
import { getThemeBase } from 'modules/theming/themeBases'
import { useElementSize } from 'utils/breakpoints/useElementSize'

import { getAlignStyles } from '../../../HorizontalAlign/HorizontalAlign'
import { CELL_PADDING, EmptyCellContent } from '../../constants'
import { NumberedOption } from '../../options/NumberedOption'
import {
  SmartLayoutCellProps,
  SmartLayoutOption,
  SmartLayoutVariant,
  SmartLayoutWrapperComponent,
} from '../../types'
import {
  MAX_WIDTH,
  MIN_PCT_INSIDE,
  PYRAMID_GAP,
  STAIRCASE_GAP,
} from './constants'
import { OutsideTextOption } from './OutsideTextOption'
import {
  StackedPyramidCell,
  StackedPyramidWrapper,
  useShouldStackContent,
} from './StackedPyramid'

const PyramidWrapper: SmartLayoutWrapperComponent = memo((props) => {
  const shouldStack = useShouldStackContent(props.containerSize)

  if (shouldStack) {
    return <StackedPyramidWrapper {...props} />
  }

  return (
    // Grid ensures all steps are the same height
    <SimpleGrid
      columns={1}
      autoRows="1fr"
      data-selection-ring
      gap={PYRAMID_GAP}
    >
      {props.children}
    </SimpleGrid>
  )
})
PyramidWrapper.displayName = 'PyramidWrapper'

type PyramidCellProps = {
  Step?: React.FC<PyramidStepProps>
  isFunnel?: boolean
  isStepped?: boolean
  alignment?: 'left' | 'center' | 'right'
} & SmartLayoutCellProps

// Generic component that powers Pyramids, Funnels, and Staircases
export const PyramidCell = memo(
  ({
    Step = PyramidStep,
    isFunnel = false,
    isStepped = false,
    alignment = 'center',
    ...props
  }: PyramidCellProps) => {
    const { children, layoutOptions, index, numCells, dragHandle } = props

    const shouldStack = useShouldStackContent(props.layoutContainerSize)
    if (shouldStack) {
      return <StackedPyramidCell {...props} />
    }

    const base = getThemeBase(props.theme)
    const isOutside: boolean = layoutOptions.outsideText
    const isNumbered: boolean = layoutOptions.numbered
    const labelSx = {
      ...getAlignStyles(alignment === 'right' ? 'right' : 'left'),
      ...base.smartLayoutContentSx,
    }
    const lineProps = isStepped
      ? { bottom: '-1px' }
      : isFunnel
      ? { top: `calc(-1px - ${PYRAMID_GAP} / 2 )` }
      : { bottom: `calc(-1px - ${PYRAMID_GAP} / 2 )` }
    const isLast = isFunnel ? index === 0 : index === numCells - 1
    const lineGap = isStepped ? STAIRCASE_GAP : PYRAMID_GAP

    return (
      <Flex
        data-selection-ring
        data-content-reference
        pos="relative"
        w="100%"
        direction={alignment === 'right' ? 'row-reverse' : 'row'}
        justify={!isOutside && alignment === 'center' ? 'center' : undefined}
      >
        <Step
          numCells={numCells}
          index={index}
          isFunnel={isFunnel}
          isOutside={isOutside}
          isNumbered={isNumbered}
          base={base}
          alignment={alignment}
          selectCell={props.selectCell}
        >
          {isOutside ? (
            isNumbered ? (
              <PyramidLabel index={index} />
            ) : null
          ) : (
            children
          )}
          {dragHandle}
        </Step>
        {isOutside && (
          <Flex
            direction="column"
            justify="center"
            minW={0}
            flex={1}
            px={CELL_PADDING} // Between line and text
            py={CELL_PADDING}
            pos="relative"
            sx={labelSx}
          >
            {children}
            {!isLast && (
              <Box
                contentEditable={false}
                position="absolute"
                {...lineProps}
                left={lineGap}
                right={lineGap}
                h="var(--line-thickness)"
                transform="scaleY(0.5)" // Prevents weird rounding bug when we have very small px values
                sx={base.smartLayoutLineSx}
              />
            )}
          </Flex>
        )}
      </Flex>
    )
  }
)
PyramidCell.displayName = 'PyramidCell'

type PyramidLabelProps = {
  index: number
}

export const PyramidLabel = memo(({ index }: PyramidLabelProps) => {
  return (
    <Text
      className={cx(HEADING_CLASS)}
      fontSize="1.25em"
      display="flex"
      sx={getAlignStyles('center')}
      pos="relative" // Go over the abs positioned steps
      contentEditable={false}
    >
      {index + 1}
    </Text>
  )
})
PyramidLabel.displayName = 'PyramidLabel'

export type PyramidStepProps = {
  numCells: number
  index: number
  isFunnel: boolean
  isOutside: boolean
  isStacked?: boolean
  isNumbered: boolean
  base: ThemeBase
  alignment: 'left' | 'center' | 'right'
  selectCell?: () => void
  children: React.ReactNode
}

export const PyramidStep = memo(
  ({
    numCells,
    index,
    isFunnel,
    isOutside,
    isStacked,
    isNumbered,
    children,
    base,
    alignment,
    selectCell,
  }: PyramidStepProps) => {
    const num = isFunnel ? numCells - index - 1 : index
    const { width, height, ref: resizeRef } = useElementSize()

    // Calculate the width of the step, given its position in the stack and the min/max width
    const minWidthPct = isOutside ? 0 : MIN_PCT_INSIDE
    const maxWidthPct = isOutside && !isStacked ? 50 : 100
    const narrowWidthPct = getWidthPct(numCells, num, minWidthPct)
    const baseWidthPct = getWidthPct(numCells, num + 1, minWidthPct) * 0.99 // Hack to account for the gap
    const blockWidthPct = baseWidthPct * (maxWidthPct / 100)
    const marginWidthPct = (maxWidthPct - blockWidthPct) / 2
    const topSize = isFunnel ? 1 : narrowWidthPct / baseWidthPct
    const bottomSize = isFunnel ? narrowWidthPct / baseWidthPct : 1
    const contentSize = (narrowWidthPct + baseWidthPct) / 2 / baseWidthPct

    const alignSx = getAlignStyles(alignment)
    const stepProps = isStacked
      ? undefined
      : isOutside
      ? {
          ml: alignment === 'center' ? `${marginWidthPct}%` : undefined,
        }
      : {
          maxW: `calc(${MAX_WIDTH} * ${blockWidthPct / 100})`,
        }

    const shouldPadNumber = isNumbered && isOutside && num === 0

    return (
      <Flex
        direction="column"
        ref={resizeRef}
        pos="relative"
        w={`${blockWidthPct}%`}
        sx={alignSx}
        onClick={isOutside ? selectCell : undefined}
        contentEditable={!isOutside}
        {...stepProps}
      >
        <TrapezoidSVG
          width={width}
          height={height}
          alignment={alignment}
          topSize={topSize}
          bottomSize={bottomSize}
          pathSx={base.svgShapeSx}
        />
        <Flex
          direction="column"
          justify="center"
          h="100%"
          p={isOutside ? '0.5em' : CELL_PADDING}
          boxSizing="border-box"
          sx={{ ...base.smartLayoutContentSx, ...alignSx }}
          w={`${contentSize * 100}%`}
          minW="3em"
          mt={shouldPadNumber && !isFunnel ? '0.6em' : undefined}
          mb={shouldPadNumber && isFunnel ? '0.6em' : undefined}
        >
          {children}
        </Flex>
      </Flex>
    )
  }
)
PyramidStep.displayName = 'PyramidStep'

type TrapezoidSVGProps = {
  width: number
  height: number
  topSize: number
  bottomSize: number
  alignment: 'left' | 'center' | 'right'
  pathSx: any
}

const TrapezoidSVG = memo(
  ({
    width,
    height,
    topSize,
    bottomSize,
    alignment,
    pathSx,
  }: TrapezoidSVGProps) => {
    const topDistance = (width * (1 - topSize)) / 2
    const bottomDistance = (width * (1 - bottomSize)) / 2
    const trapezoidPath =
      alignment === 'left'
        ? `
    M 0 0
    L ${width - topDistance * 2} 0
    L ${width - bottomDistance * 2} ${height}
    L 0 ${height}
    L 0 0 Z`
        : alignment === 'right'
        ? `
    M ${topDistance * 2} 0
    L ${width} 0
    L ${width} ${height}
    L ${bottomDistance * 2} ${height}
    L ${topDistance * 2} 0 Z`
        : `
    M ${topDistance} 0
    L ${width - topDistance} 0
    L ${width - bottomDistance} ${height}
    L ${bottomDistance} ${height}
    L ${topDistance} 0 Z`

    return (
      <Box
        as="svg"
        pos="absolute"
        inset="0"
        overflow="visible"
        contentEditable={false}
        w={`${width}px`}
        h={`${height}px`}
      >
        <Box as={'path'} d={trapezoidPath} sx={pathSx} />
      </Box>
    )
  }
)
TrapezoidSVG.displayName = 'TrapezoidSVG'

export const getWidthPct = (
  numCells: number,
  index: number,
  minWidth: number
) => {
  return minWidth + (index / numCells) * (100 - minWidth)
}

const NumberedIfOutsideOption: SmartLayoutOption = {
  ...NumberedOption,
  checkEnabled: (attrs) => attrs.options.outsideText,
}

export const Pyramid: SmartLayoutVariant = {
  key: 'pyramid',
  name: () => t`Pyramid`,
  commandName: () => t`Pyramid layout (blank)`,
  icon: regular('chart-pyramid'),
  options: [OutsideTextOption, NumberedIfOutsideOption],
  cellOptions: [],
  Wrapper: PyramidWrapper,
  Cell: PyramidCell,
  defaultContent: EmptyCellContent,
  addDirection() {
    return 'bottom'
  },
  htmlTag: 'pyramid',
  featureFlag: 'pyramidSmartLayouts',
}
