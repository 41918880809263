import { EventEmitter } from 'utils/EventEmitter'

type CardAccentEvents = {
  toggleEditBackgroundDrawer: {
    openDrawer: boolean
    cardId: string
  }
}

export const eventEmitter = new EventEmitter<CardAccentEvents>()
