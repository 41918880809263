import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'

import { ToolbarButton } from 'modules/tiptap_editor/components/menus/buttons/ToolbarButton'

import { SmartLayoutOption, SmartLayoutOptionControlProps } from '../types'

const TwoSidedOptionControl = ({
  value,
  updateValue,
  option,
}: SmartLayoutOptionControlProps<boolean>) => {
  return (
    <ToolbarButton
      label={option.name()}
      onClick={() => updateValue(!value)}
      isActive={value}
      icon={regular('timeline')}
      testId="smart-layout-two-sided-option"
    />
  )
}

export const TwoSidedOption: SmartLayoutOption<boolean> = {
  key: 'twoSided',
  defaultValue: true,
  Control: TwoSidedOptionControl,
  name: () => t`Two sided`,
}
