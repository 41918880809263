/**
 * Things a task is responsible for
 * 1. input to prepareClassifyExamples
 *
 * Provide information for classification
 * - key
 * - description
 * - classifyExamples
 * - classifyReply
 *
 * checkEnabled
 */

import { Editor } from '@tiptap/core'
import { Selection } from 'prosemirror-state'

import { ClassificationResult } from 'modules/ai/chat/tasks/classify'
import { AddMessageFn, ChatTrackingFns, Messages } from 'modules/ai/chat/types'
import { ChatCompletionProvider } from 'modules/api'
import { Theme } from 'modules/theming'
import { CardAttributes } from 'modules/tiptap_editor/extensions/Card/types'
import { RelativeRange } from 'modules/tiptap_editor/utils/relativePosition'

import { ThemeModificationController } from '../useThemeModificationController'

export type TaskContext = {
  interactionId: string
  startTime: number
  message: string
  editorContext: TaskEditorContext
  docId: string
}

export type TaskTarget = {
  range: RelativeRange
  html: string
}

export type TaskEditorContext = {
  card: TaskTarget & {
    cardId: string
    attrs: CardAttributes
  }
  cardContent: TaskTarget
  blockRange: TaskTarget
  selectionRange: TaskTarget
  defaultRange: TaskTarget
  selection: Selection
  outline: string
  theme: Theme
  selectedDefaultRange: 'cardContent' | 'blockRange' | 'selectionRange'
}

export type DesignPartnerTaskRunOptions = {
  editor: Editor
  context: TaskContext
  addMessage: AddMessageFn
  classificationResult: ClassificationResult
  history?: Messages[]
  trackFns: ChatTrackingFns
  themeModificationController: ThemeModificationController
  provider: ChatCompletionProvider
}

export type DesignPartnerTaskParams = ClassifyableTask

export abstract class DesignPartnerTask implements ClassifyableTask {
  key: string
  description?: string
  classifyExamples?: string[]
  classifyReply?: string
  checkEnabled: ClassifyableTask['checkEnabled']

  abstract run(opts: DesignPartnerTaskRunOptions): Promise<void>

  constructor({
    key,
    description,
    classifyExamples,
    classifyReply,
    checkEnabled,
  }: DesignPartnerTaskParams) {
    this.key = key
    this.description = description
    this.classifyExamples = classifyExamples
    this.classifyReply = classifyReply
    this.checkEnabled = checkEnabled
  }
}

export interface ClassifyableTask {
  key: string
  description?: string
  classifyExamples?: string[]
  classifyReply?: string
  checkEnabled?(editorContext: TaskEditorContext): boolean
}
