import { max } from 'lodash'
import { Node, ResolvedPos } from 'prosemirror-model'
import { EditorState, NodeSelection } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import { fragmentToArray, isTreeEmpty } from 'modules/tiptap_editor/utils'

import {
  NORMAL_CONTENT_WIDTH_CHARS,
  WIDE_CONTENT_WIDTH_CHARS,
} from '../Card/Card2/getCardSizeCSSVars'
import { SIDE_BODY_WIDTH } from '../Card/CardLayout/layoutPresets'
import { CardAttributes } from '../Card/types'
import { DocumentAttributes } from '../Document/DocumentAttrs/attributes'
import { LayoutAttrs } from '../Layout'
import { SmartLayout } from './SmartLayout'
import {
  SmartLayoutAttrs,
  SmartLayoutCellDecorationSpec,
  SmartLayoutDecorationSpec,
} from './types'
import { parseLabelNumber } from './variants/stats/utils'

type SmartLayoutCellDecoration = Decoration & {
  spec: SmartLayoutCellDecorationSpec
}

type SmartLayoutDecoration = Decoration & { spec: SmartLayoutDecorationSpec }

export const decorateLayouts = (state: EditorState) => {
  const { doc } = state
  const decorations: Decoration[] = []
  const decorate = (node: Node, pos: number) => {
    if (node.type.name !== SmartLayout.name) {
      return
    }

    const maxLabelValue = max(
      fragmentToArray(node.content).map((n) => parseLabelNumber(n.attrs.label))
    )
    const containerSize = getContainerSize(doc.resolve(pos))

    const isLayoutFocused =
      (state.selection instanceof NodeSelection &&
        state.selection.node === node) ||
      (state.selection.from > pos && state.selection.to < pos + node.nodeSize)

    // Decorate the layout itself so the Wrapper component can rerender when the number of children changes
    const layoutDeco: SmartLayoutDecorationSpec = {
      isSmartLayoutDecoration: true,
      numCells: node.childCount,
      containerSize,
    }
    decorations.push(Decoration.node(pos, pos + node.nodeSize, {}, layoutDeco))

    // Decorate each cell so their node views have access to the parent's attributes/child count, and rerender when they change
    node.forEach((child, offset, index) => {
      const childPos = pos + 1 + offset
      const cellDeco: SmartLayoutCellDecorationSpec = {
        isSmartLayoutCellDecoration: true,
        index,
        numCells: node.childCount,
        maxLabelValue,
        smartLayoutAttrs: node.attrs as SmartLayoutAttrs,
        layoutContainerSize: containerSize,
        isContentEmpty: isTreeEmpty(child),
        isLayoutFocused,
      }
      decorations.push(
        Decoration.node(childPos, childPos + child.nodeSize, {}, cellDeco)
      )
    })

    return false // No need to descend further
  }

  doc.descendants(decorate)
  return DecorationSet.create(doc, decorations)
}

export const findSmartLayoutDecoration = (
  decorations: Decoration[]
): SmartLayoutDecorationSpec => {
  const deco = decorations.find(
    (d): d is SmartLayoutDecoration => d.spec.isSmartLayoutDecoration
  )
  if (!deco) {
    console.error(
      '[SmartLayout] Decoration not found. This should never happen!',
      decorations
    )
    return {
      isSmartLayoutDecoration: true,
      numCells: 0,
      containerSize: 1,
    }
  }
  return deco.spec
}

export const findSmartLayoutCellDecoration = (
  decorations: Decoration[]
): SmartLayoutCellDecorationSpec => {
  const deco = decorations.find(
    (d): d is SmartLayoutCellDecoration => d.spec.isSmartLayoutCellDecoration
  )
  if (!deco) {
    console.error(
      '[SmartLayoutCell] Decoration not found. This should never happen!',
      decorations
    )
    return {
      isSmartLayoutCellDecoration: true,
      numCells: 0,
      index: 0,
      smartLayoutAttrs: {
        options: {},
        fullWidthBlock: false,
      },
      layoutContainerSize: 1,
      isContentEmpty: false,
      isLayoutFocused: false,
    }
  }
  return deco.spec
}

// Returns a number from 0-1 representing the % of the card width that the layout has available,
// after accounting for parent layouts like a card layout or columns
// can be >1 if it's in a wide card
const getContainerSize = ($pos: ResolvedPos) => {
  let containerWidth = 1

  try {
    for (let depth = $pos.depth; depth > 0; depth--) {
      const parent = $pos.node(depth)
      const parentIndex = $pos.index(depth - 1)

      if (parent.type.name === 'gridCell') {
        const layout = $pos.node(depth - 1)
        const widths = (layout.attrs as LayoutAttrs).colWidths
        if (widths && widths[parentIndex]) {
          containerWidth *= parseInt(widths[parentIndex]) / 100
        }
      }

      if (parent.type.name === 'card') {
        const { layout, layoutTemplateColumns, container } =
          parent.attrs as CardAttributes
        if (layout === 'left') {
          const bodyWidth = layoutTemplateColumns
            ? parseInt(layoutTemplateColumns.split(' ')[1]) / 100
            : SIDE_BODY_WIDTH
          containerWidth *= bodyWidth
        }
        if (layout === 'right') {
          const bodyWidth = layoutTemplateColumns
            ? parseInt(layoutTemplateColumns.split(' ')[0]) / 100
            : SIDE_BODY_WIDTH
          containerWidth *= bodyWidth
        }
        const docAttrs = $pos.doc.firstChild!.attrs as DocumentAttributes
        if (
          (container.width ?? docAttrs.settings.defaultContentWidth) === 'lg'
        ) {
          containerWidth *=
            WIDE_CONTENT_WIDTH_CHARS / NORMAL_CONTENT_WIDTH_CHARS
        }
        break // Once we get to a card boundary, break out
      }
    }
  } catch (err) {
    console.error('[SmartLayout] Error getting container size decoration', err)
  }

  return containerWidth
}
