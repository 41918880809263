const DIM_OPACITY = 0.5

// These styles will only apply when the card notes panel is open
export const cardNotesEditorStyles = {
  // Animate card opacity when entering card notes editing mode
  '.block-card': {
    transitionProperty: 'opacity',
    transitionDuration: 'normal',
  },

  // Styles in here will only apply when card notes panel is open
  '&.card-notes-active': {
    // Dim inactive cards
    '.block-card:not(.card-notes-active-parent-card)': {
      opacity: DIM_OPACITY,
    },
    // Don't dim any nested cards inside the active card
    '.card-notes-active-parent-card .block-card': {
      opacity: 'revert',
    },

    '.card-notes-active-parent-card > [data-node-view-wrapper] > .card-wrapper > .card-body':
      {
        _before: {
          content: "''",
          position: 'absolute',
          inset: '0px',
          zIndex: 1,
          boxShadow: '0px 0px 0px 5px var(--chakra-colors-trueblue-100)',
          borderRadius: 'inherit',
          pointerEvents: 'none',
        },
      },
  },
}
