import {
  Editor,
  Extension,
  findParentNodeClosestToPos,
  KeyboardShortcutCommand,
} from '@tiptap/core'
import { GapCursor } from 'prosemirror-gapcursor'
import { ResolvedPos } from 'prosemirror-model'
import { NodeSelection, Selection, TextSelection } from 'prosemirror-state'

import { findIsolatingSelectionPos } from 'modules/tiptap_editor/utils/selection/isolating'

import { isCardNode } from '../../utils/nodeHelpers'
import {
  JoinBackwardAnnotationEvent,
  JoinForwardAnnotationEvent,
  SplitBlockAnnotationEvent,
} from '../Annotatable/AnnotationExtension/types'
import { CARD_DEPTH } from '../Card/constants'
import { ExtensionPriorityMap } from '../constants'
import { mathBackspaceCmd } from '../Math/prosemirror-math'

export const getTopOfCard = (
  editor: Editor,
  $pos: ResolvedPos
): number | undefined => {
  const card = findParentNodeClosestToPos($pos, isCardNode)
  if (!card) return

  return Selection.near(editor.state.doc.resolve(card.start)).from
}

// Helpers for getting top or bottom positions of the top of card or doc
const getStartOfIsolatingNodeOrDoc = (
  $pos: ResolvedPos
): number | undefined => {
  const from = findIsolatingSelectionPos($pos, -1)
  return $pos.pos === from ? CARD_DEPTH : from
}

const getBottomOfIsolatingNodeOrDoc = (
  editor: Editor,
  $pos: ResolvedPos
): number => {
  const endPos = findIsolatingSelectionPos($pos, 1)
  if ($pos.pos === endPos) {
    return editor.state.doc.content.size - CARD_DEPTH
  } else {
    return endPos
  }
}

type KeyMapOverrideOptions = {
  addSelectionKeyMaps: boolean
}
/**
 * Specifically overrides Keymap defaults from TipTap:
 *  https://github.com/ueberdosis/tiptap/blob/236cb1cf06f1254d1d1f6d74db32f6af53d72aa1/packages/core/src/extensions/keymap.ts
 */
export const KeyMapOverride = Extension.create<KeyMapOverrideOptions>({
  name: 'KeyMapOverride',
  priority: ExtensionPriorityMap.KeyMapOverride,
  addOptions() {
    return {
      addSelectionKeyMaps: false,
    }
  },
  addKeyboardShortcuts() {
    const handleEnter = () =>
      this.editor.commands.first(({ commands }) => [
        () => commands.newlineInCode(),
        () => commands.createParagraphNear(),
        () => commands.liftEmptyBlock(),
        ({ view, state, tr }) => {
          tr.setMeta('annotationEvent', <SplitBlockAnnotationEvent>{
            type: 'split-block',
            splitPos: state.selection.from,
            atBeginning: view.endOfTextblock('backward'),
          })
          return commands.splitBlock()
        },
      ])

    // NOTE: undoInputRule is bound in UndoInputRuleKeymap since it must be a higher priority than this extension
    const handleBackspace = () =>
      this.editor.commands.first(({ commands }) => [
        () => commands.handleCardAccentDelete(),
        () => commands.deleteSelectionAndSelectNear(-1),
        () => commands.deleteSelection(),
        ({ view, state, dispatch }) => mathBackspaceCmd(state, dispatch, view),
        () => commands.unwrapQuoteOnDelete(),
        ({ view, state, tr }) => {
          const ret = commands.joinBackward()
          if (ret) {
            tr.setMeta('annotationEvent', <JoinBackwardAnnotationEvent>{
              type: 'join-backward',
              joinPos: state.selection.from,
              atBeginning: view.endOfTextblock('backward'),
            })
          }
          return ret
        },
        () => commands.selectNodeBackward(),
        // Commands below here should only run if we're at the start of an isolating node
        // (e.g.a card or layout) where we want to customize backspace behavior
        () => commands.handleLayoutDelete(false),
        // Disabling because backspacing a smart layout cell felt too easily to do by accident
        // () => commands.handleSmartLayoutDelete(false),
        () => commands.handleButtonDelete(true),
        () => commands.deleteCardIfEmpty(false),
        () => commands.mergeCardsOnDelete(false),
      ])

    const handleDelete = () =>
      this.editor.commands.first(({ commands }) => [
        () => commands.handleCardAccentDelete(),
        () => commands.deleteSelectionAndSelectNear(),
        () => commands.deleteSelection(),
        ({ view, state, tr }) => {
          const ret = commands.joinForward()
          if (ret) {
            tr.setMeta('annotationEvent', <JoinForwardAnnotationEvent>{
              type: 'join-forward',
              joinPos: state.selection.from,
              atEnd: view.endOfTextblock('forward'),
            })
          }
          return ret
        },
        () => commands.selectNodeForward(),
        // Commands below here should only run if we're at the end of an isolating node
        // (e.g.a card or layout) where we want to customize delete behavior
        () => commands.handleLayoutDelete(true),
        () => commands.handleSmartLayoutDelete(true),
        () => commands.handleButtonDelete(true),
        () => commands.deleteCardIfEmpty(true),
        () => commands.mergeCardsOnDelete(true),
      ])

    const handleSpace = () =>
      this.editor.commands.first(({ commands }) => [
        () => commands.handleSpaceToZoom(),
      ])

    let selectionKeyMapOverrides: { [key: string]: KeyboardShortcutCommand } =
      {}

    if (this.options.addSelectionKeyMaps) {
      selectionKeyMapOverrides = {
        // Override Cmd-a to select all content in a card
        'Mod-a': ({ editor }) => {
          const { selection } = editor.state

          if (
            selection instanceof NodeSelection &&
            selection.node.type.spec.isolating
          ) {
            // selecting an isolating node already, cmd-a should select doc
            return editor.commands.selectAll()
          }

          const newFrom = findIsolatingSelectionPos(
            editor.state.selection.$from,
            -1
          )
          const newTo = findIsolatingSelectionPos(editor.state.selection.$to, 1)

          if (selection.from === newFrom && selection.to === newTo) {
            return editor.commands.selectAll()
          }

          editor.commands.command(({ tr }) => {
            // manually create TextSeletion to allow from and from+1 of text content
            tr.setSelection(TextSelection.create(tr.doc, newFrom, newTo))
            return true
          })

          return true
        },

        'Mod-ArrowUp': ({ editor }) => {
          // Go to top of card, or doc if already at the top of the card
          const startPos = getStartOfIsolatingNodeOrDoc(
            editor.state.selection.$from
          )
          if (!startPos) return false

          // Check if gap cursor can be inserted
          const $startPos = editor.state.doc.resolve(startPos)
          // @ts-ignore
          if (GapCursor.valid($startPos)) {
            const gapCursor = new GapCursor($startPos)
            editor.view.dispatch(
              editor.state.tr.setSelection(gapCursor).scrollIntoView()
            )
            return true
          }

          return editor
            .chain()
            .setTextSelection(startPos)
            .scrollIntoView()
            .run()
        },

        'Mod-Shift-ArrowUp': ({ editor }) => {
          // Select to top of card, or doc if already at the top of the card
          const startPos = getStartOfIsolatingNodeOrDoc(
            editor.state.selection.$from
          )
          if (!startPos) return false

          return editor
            .chain()
            .setTextSelection({
              from: startPos,
              to: editor.state.selection.to,
            })
            .scrollIntoView()
            .run()
        },

        'Mod-ArrowDown': ({ editor }) => {
          // Go to bottom of card, or doc if already at the bottom of the card
          const endPos = getBottomOfIsolatingNodeOrDoc(
            editor,
            editor.state.selection.$to
          )

          const $endPos = editor.state.doc.resolve(endPos)
          // @ts-ignore
          if (GapCursor.valid($endPos)) {
            const gapCursor = new GapCursor($endPos)
            editor.view.dispatch(
              editor.state.tr.setSelection(gapCursor).scrollIntoView()
            )
            return true
          }
          return editor.chain().setTextSelection(endPos).scrollIntoView().run()
        },

        'Mod-Shift-ArrowDown': ({ editor }) => {
          // Select to bottom of card, or doc if already at the bottom of the card
          const endPos = getBottomOfIsolatingNodeOrDoc(
            editor,
            editor.state.selection.$to
          )

          return editor
            .chain()
            .setTextSelection({
              from: editor.state.selection.from,
              to: endPos,
            })
            .scrollIntoView()
            .run()
        },

        // Enable selecting all document text with shift-mod-a
        'Shift-Mod-a': ({ editor }) => editor.commands.selectAll(),
      }
    }

    return {
      ...selectionKeyMapOverrides,
      Enter: handleEnter,
      Backspace: handleBackspace,
      'Mod-Backspace': handleBackspace,
      'Shift-Backspace': handleBackspace,
      Delete: handleDelete,
      'Mod-Delete': handleDelete,
      Space: handleSpace,
    }
  },
})
