import { Switch } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Trans } from '@lingui/macro'
import { Editor, NodeViewProps } from '@tiptap/core'
import { useCallback } from 'react'

import { CardStyleRow } from './CardStyleRow'

type CardContentWidthSelectorProps = {
  editor: Editor
  getPos: NodeViewProps['getPos']
  isWideContent: boolean
}
export const CardContentWidthSelector = ({
  editor,
  getPos,
  isWideContent,
}: CardContentWidthSelectorProps) => {
  const toggleContentWidth = useCallback(() => {
    editor.commands.updateNestedAttributesAtPos(getPos(), {
      container: {
        width: isWideContent ? 'md' : 'lg',
      },
    })
  }, [editor, getPos, isWideContent])

  return (
    <CardStyleRow
      icon={regular('arrows-left-right')}
      label={<Trans>Wide content</Trans>}
    >
      <Switch isChecked={isWideContent} onChange={toggleContentWidth} />
    </CardStyleRow>
  )
}
