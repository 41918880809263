import { Box, Flex, FlexProps, useDisclosure } from '@chakra-ui/react'
import { NodeViewProps } from '@tiptap/react'
import { useCallback, useEffect, useState } from 'react'

import { useAppSelector } from 'modules/redux'
import { Theme } from 'modules/theming'
import { EditBackgroundDrawer } from 'modules/tiptap_editor/extensions/Card/Card2/EditBackgroundDrawer/EditBackgroundDrawer'
import { NodeViewContent, NodeViewWrapper } from 'modules/tiptap_editor/react'
import { selectEditable, selectTheme } from 'modules/tiptap_editor/reducer'
import { getBackgroundProps } from 'modules/tiptap_editor/styles/backgroundStyles'
import { BackgroundType } from 'modules/tiptap_editor/styles/types'
import { THUMBNAIL_RESIZE_PARAMS } from 'utils/image'

import { BackgroundMask } from '../../Card2/BackgroundMask'
import { getBgBorderRadius } from '../CardBodyLayoutItemView'
import { findCardLayoutDecorationSpec } from '../CardLayoutPlugin'
import {
  findLayoutPreset,
  replaceCardLayoutWithCardAccentLayoutItem,
} from '../cardLayoutUtils'
import { CardLayoutItemAttrs } from '../types'
import { eventEmitter as cardAccentEventEmitter } from './CardAccentEventEmitter'
import { CardLayoutAccentImageSelector } from './CardLayoutAccentImageSelector'

const SIDE_LAYOUT_RESIZE_PARAMS = {
  width: 1200,
}

const TOP_LAYOUT_RESIZE_PARAMS = {
  height: 600,
}

const useConvertToCardAccentNodeType = ({
  editor,
  node,
  getPos,
}: NodeViewProps) => {
  useEffect(() => {
    if (node.type.name === 'cardLayoutItem' && node.attrs.itemId === 'accent') {
      setTimeout(() => {
        editor.commands.command(({ tr }) => {
          replaceCardLayoutWithCardAccentLayoutItem(
            tr,
            getPos(),
            node,
            editor.schema
          )
          return true
        })
      })
    }
  }, [editor, getPos, node])
}

export const CardAccentLayoutItemView = (nodeViewProps: NodeViewProps) => {
  const { node, decorations, editor, getPos } = nodeViewProps
  const attrs = node.attrs as CardLayoutItemAttrs
  useConvertToCardAccentNodeType(nodeViewProps)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [styleDrawerTabIndex, setStyleDrawerTabIndex] = useState<number>(0)

  const { background } = attrs

  const selectCardAccent = useCallback(() => {
    editor.commands.selectNodeAtPos(getPos())
  }, [editor, getPos])

  const onEditImage = useCallback(() => {
    selectCardAccent()
    onOpen()
  }, [onOpen, selectCardAccent])

  const cardLayoutDecoSpec = findCardLayoutDecorationSpec(decorations)
  const cardId = cardLayoutDecoSpec?.cardId
  const layout = cardLayoutDecoSpec?.layout
  const isBehindLayout = layout === 'behind'
  const resizeParams = editor.isThumbnail
    ? THUMBNAIL_RESIZE_PARAMS
    : layout === 'right' || layout === 'left' || layout === 'mobileStacked'
    ? SIDE_LAYOUT_RESIZE_PARAMS
    : undefined
  const bgProps = getBackgroundProps(background, false, resizeParams)
  const layoutPreset = findLayoutPreset(layout)
  const shouldShow = !!layoutPreset.items[attrs.itemId]

  const borderProps = getBgBorderRadius(
    cardLayoutDecoSpec!.layout,
    attrs.itemId,
    cardLayoutDecoSpec!.cardSize === 'full'
  )
  const alignProps: Partial<FlexProps> = {
    flexDirection: 'column',
    justifyContent: 'center',
  }
  // TODO maybe find a better way to get this information here
  const noImage =
    background.type === 'none' ||
    (background.type === BackgroundType.IMAGE && !background.image) ||
    (background.type === BackgroundType.IMAGE &&
      !background.image?.src &&
      !background.image?.tempUrl)

  const isLoading = background.image?.loadImageStatus === 'queued'
  const isEditable = useAppSelector(selectEditable)

  const updateCardLayoutItemAttributes = useCallback(
    (a: Record<string, any>) => {
      editor.commands.updateNestedAttributesAtPos(getPos(), a)
    },
    [editor, getPos]
  )

  const theme: Theme = useAppSelector(selectTheme)
  const colorizeProps =
    theme.config.accentBackgroundsColorize &&
    getBackgroundProps(theme.config.accentBackgroundsColorize, false)

  useEffect(() => {
    return cardAccentEventEmitter.on(
      'toggleEditBackgroundDrawer',
      ({ openDrawer, cardId: id }) => {
        if (openDrawer && id === cardId) {
          onOpen()
        }
      }
    )
  }, [cardId, onOpen])

  if (!shouldShow) {
    return null
  }

  return (
    <NodeViewWrapper as="div">
      <Flex
        data-content-reference
        data-change-focus-point-el
        className="card-layout-cell-bg"
        w="100%"
        h="100%"
        position="relative"
        data-selection-ring
        data-selection-background
        data-card-layout={cardLayoutDecoSpec?.layout}
        {...bgProps}
        {...borderProps}
        {...alignProps}
        onClick={selectCardAccent}
      >
        {colorizeProps && (
          <Box
            position="absolute"
            inset="0"
            borderRadius="inherit"
            {...colorizeProps}
            mixBlendMode="color"
          />
        )}
        {isBehindLayout && <BackgroundMask background={background} />}
        {isEditable && (
          <EditBackgroundDrawer
            editor={editor}
            isOpen={isOpen}
            onClose={onClose}
            tabIndex={styleDrawerTabIndex}
            setTabIndex={setStyleDrawerTabIndex}
            updateNestedAttributes={updateCardLayoutItemAttributes}
            background={attrs?.background}
            enableMask={isBehindLayout}
            isAccentImageDrawer
          />
        )}

        <CardLayoutAccentImageSelector
          isLoading={isLoading}
          editor={editor}
          hasImage={!noImage}
          image={background.image}
          onEditImage={isEditable ? onEditImage : undefined}
          {...borderProps}
        />
      </Flex>
    </NodeViewWrapper>
  )
}
