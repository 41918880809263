import { t, Trans } from '@lingui/macro'

import { GAMMA_PROPER_NOUN } from 'modules/i18n/properNouns'
export enum ErrorCode {
  invalid_signup_code = 'invalid_signup_code',
  invalid_reset_code = 'invalid_reset_code',
  invalid_user = 'invalid_user',
  other_workspace_member = 'other_workspace_member',
}

type ErrorCodeText = {
  title: React.ReactNode
  description: React.ReactNode
}
export const ERROR_MAP: Record<ErrorCode, ErrorCodeText> = {
  [ErrorCode.invalid_signup_code]: {
    title: (
      <Trans>Hmm... We couldn't sign you up for {GAMMA_PROPER_NOUN}.</Trans>
    ),
    description: (
      <Trans>
        The link that you followed is no longer valid. Please try signing up
        again.
      </Trans>
    ),
  },

  [ErrorCode.invalid_reset_code]: {
    title: <Trans>Hmm... We couldn't reset your password.</Trans>,
    description: (
      <Trans>
        The link that you followed is no longer valid. Please try resetting your
        password again.
      </Trans>
    ),
  },

  [ErrorCode.invalid_user]: {
    title: <Trans>Hmm... We couldn't sign you in.</Trans>,
    description: (
      <Trans>
        You do not have access to {GAMMA_PROPER_NOUN}. Ask your workspace
        administrator to send you a join link
      </Trans>
    ),
  },

  [ErrorCode.other_workspace_member]: {
    title: <Trans>Hmm... We can't add you to this workspace.</Trans>,
    description: (
      <Trans>
        {GAMMA_PROPER_NOUN} currently only supports membership in a single
        workspace. Let us know at support@gamma.app if you need access to more
        than one workspace
      </Trans>
    ),
  },
}
