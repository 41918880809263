import { Box } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { Editor } from '@tiptap/core'
import React, { memo, useCallback, useEffect } from 'react'

import { useHealthCheck } from 'modules/api'
import { OfflineInfoBox } from 'modules/offline'
import { ACCENT_IMAGE_SOURCE_KEY } from 'modules/theming'
import {
  MediaSourcesMap,
  MediaSourceType,
  NO_BACKGROUND_SOURCE_KEY,
} from 'modules/tiptap_editor/extensions/media/MediaSources'

import { BackgroundOptions, BackgroundType } from '../../styles/types'
import { MediaDrawerMenu } from '../drawers/MediaDrawer/MediaDrawerMenu'

type BackgroundPanelProps = {
  editor?: Editor
  defaultMessage: React.ReactNode
  isDark?: boolean
  currentBackground: BackgroundOptions
  updateCurrentBackground: (b: BackgroundOptions) => void
  setCurrentSourceKey: (s: string) => void
  currentSourceKey: string
  currentSource: MediaSourceType
  isValidSource: (s: MediaSourceType) => boolean
}

const computeBackgroundSource = (
  attrs: BackgroundOptions
): string | undefined => {
  if (!attrs.source && attrs.type === BackgroundType.IMAGE) {
    return attrs.image?.source
  }

  return attrs.source
}

export const BackgroundPanel = memo(
  ({
    editor,
    defaultMessage,
    isDark,
    setCurrentSourceKey,
    currentSourceKey,
    currentSource,
    updateCurrentBackground,
    currentBackground,
    isValidSource,
  }: BackgroundPanelProps) => {
    const { isConnected } = useHealthCheck()

    const updateBackgroundAttributes = (attrs: Record<string, any>) => {
      if (!currentSource.backgroundType) {
        return
      }
      if (currentSource.key === ACCENT_IMAGE_SOURCE_KEY) {
        // For accent images, attrs will be the entire background
        updateCurrentBackground(attrs as BackgroundOptions)
      } else {
        updateCurrentBackground({
          type: currentSource.backgroundType,
          [currentSource.backgroundType]: {
            ...attrs,
          },
          source: currentSourceKey,
        })
      }
    }

    const getBackgroundAttributes = (
      bg: BackgroundOptions
    ): BackgroundOptions | Record<string, any> => {
      if (!currentSource.backgroundType) {
        return {}
      }
      if (currentSource.key === ACCENT_IMAGE_SOURCE_KEY) {
        return bg
      }
      return bg[currentSource.backgroundType] || {}
    }

    const resetBackground = useCallback(
      (sourceKey: string) => {
        const source = MediaSourcesMap[sourceKey]
        if (!source.backgroundType) {
          return
        }
        updateCurrentBackground({
          type: source.backgroundType,
          source: sourceKey,
          // Reset the background data for this type by setting it to undefined
          [source.backgroundType]: undefined,
        })
      },
      [updateCurrentBackground]
    )

    const resetToPlaceholder = useCallback(
      () => resetBackground(currentSourceKey),
      [currentSourceKey, resetBackground]
    )

    const onChange = useCallback(
      (value: string) => {
        if (value === NO_BACKGROUND_SOURCE_KEY) {
          resetBackground(NO_BACKGROUND_SOURCE_KEY)
        }
        setCurrentSourceKey(value)
      },
      [setCurrentSourceKey, resetBackground]
    )

    // prioritize background.image.source when type === 'image'
    const bgSource = computeBackgroundSource(currentBackground)
    useEffect(() => {
      // Set source picker value on first load, and any time it changes (collaboratively)
      if (bgSource && MediaSourcesMap[bgSource]) {
        setCurrentSourceKey(bgSource)
      }
    }, [bgSource, setCurrentSourceKey])

    const isConnectedOrAvailableOffline = Boolean(
      isConnected || currentSource?.availableOffline
    )

    return (
      <>
        <OfflineInfoBox
          isConnected={isConnectedOrAvailableOffline}
          label={
            <Trans>
              Some background options will not be available until you reconnect.
            </Trans>
          }
          mb={3}
          my={6}
        />
        <MediaDrawerMenu
          isValidSource={isValidSource}
          onChange={onChange}
          currentSource={currentSource}
        />
        {currentSource && (
          <Box
            opacity={isConnectedOrAvailableOffline ? 1 : 0.4}
            pointerEvents={isConnectedOrAvailableOffline ? 'initial' : 'none'}
          >
            <currentSource.Panel
              editor={editor}
              updateAttributes={updateBackgroundAttributes}
              currentAttributes={getBackgroundAttributes(currentBackground)}
              resetToPlaceholder={resetToPlaceholder}
              editType="background"
              isDark={isDark}
            />
          </Box>
        )}
        {currentSourceKey === NO_BACKGROUND_SOURCE_KEY && defaultMessage}
      </>
    )
  }
)

BackgroundPanel.displayName = 'BackgroundPanel'
