import { mergeAttributes, Node, NodeViewProps } from '@tiptap/core'

import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'

import { attrsOrDecorationsChanged } from '../updateFns'
import { listFilteredInputRule } from './List'
import { ListVariant } from './ListTypes'
import { TodoView } from './TodoView'

const todoRegex = /^\s*\[[ ]?\]\s$/
const doneRegex = /^\s*\[x\]\s$/

export interface TodoOptions {
  HTMLAttributes: Record<string, any>
}

export const Todo = Node.create<TodoOptions>({
  name: ListVariant.Todo,
  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },
  content: 'inline*',
  defining: true,
  group: 'block cardNotesBlock',
  selectable: false,
  allowFontSizes: 'body',

  addAttributes() {
    return {
      indent: {
        default: 0,
      },
      checked: {
        default: false,
      },
      fontSize: {
        default: null,
      },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(TodoView, {
      as: 'ul',
      update: attrsOrDecorationsChanged,
      updateWrapperEl: (el: HTMLElement, props: NodeViewProps) => {
        el.setAttribute('indent', props.node.attrs.indent)
      },
    })
  },

  addInputRules() {
    return [
      listFilteredInputRule(
        {
          find: todoRegex,
          type: this.type,
          getAttributes: {
            checked: false,
          },
        },
        this.editor.state.schema
      ),
      listFilteredInputRule(
        {
          find: doneRegex,
          type: this.type,
          getAttributes: {
            checked: true,
          },
        },
        this.editor.state.schema
      ),
    ]
  },

  addKeyboardShortcuts() {
    return {
      'Mod-,': ({ editor }) =>
        editor.commands.setListItems({ variant: ListVariant.Todo }),
      // Google Docs shortcut
      'Mod-Shift-9': ({ editor }) =>
        editor.commands.setListItems({ variant: ListVariant.Todo }),
    }
  },

  parseHTML() {
    return [{ tag: `li[variant="${this.name}"]` }]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'li',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        variant: this.name,
      }),
      0,
    ]
  },
})
