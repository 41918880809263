import { TogglePanelConfig } from 'modules/panels/hooks'
import { EventEmitter } from 'utils/EventEmitter'

type DesignPartnerEvents = {
  toggle: {
    config?: TogglePanelConfig
  }
}

export const eventEmitter = new EventEmitter<DesignPartnerEvents>()
