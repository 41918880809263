import { Editor } from '@tiptap/core'

import { CardAccentLayoutItemAttrs } from 'modules/tiptap_editor/extensions/Card/CardLayout/types'
import { CardAttributes } from 'modules/tiptap_editor/extensions/Card/types'
import { BackgroundType } from 'modules/tiptap_editor/styles/types'

// Return focus to the editor on close so that you can apply shortcuts or type more
// This is needed because Chakra menus alway steal focus
export const focusEditorOnMenuClose = (editor: Editor) => {
  setTimeout(() => {
    editor.commands.focus()
  })
}

// For card and card layout formatting menus
export const handleBackgroundResetOrUpdate = (
  editor: Editor,
  pos: number,
  attrs: Partial<CardAttributes | CardAccentLayoutItemAttrs>
) => {
  if (attrs.background?.type === BackgroundType.NONE) {
    // reset by replacing the entire background
    editor.commands.updateAttributesAtPos(pos, attrs)
  } else {
    editor.commands.updateNestedAttributesAtPos(pos, attrs)
  }
}
