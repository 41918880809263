import { isElementEmpty } from 'modules/tiptap_editor/extensions/Clipboard/utils'

import { transformGoogleDocDOM, transformOfficeExportDOM } from './docImport'
import { querySelectorArray } from './domUtils'
import { flattenListTree } from './list'

export const transformPastedHTML = (html: string): string => {
  try {
    // Parse the HTML
    const dom = new DOMParser().parseFromString(html, 'text/html')

    querySelectorArray(dom, 'li', true).forEach(flattenListTree)
    querySelectorArray(dom, 'img').forEach(pullImageOutOfTextBlock)
    transformOfficeExportDOM(dom)
    transformGoogleDocDOM(dom)
    // This should run last, since other steps might create empty paragraphs
    querySelectorArray(dom, 'p').forEach(removeEmptyParagraphs)

    return dom.body.innerHTML
  } catch (err) {
    console.error('Error transforming pasted HTML', err)
    return html // Return the original, untransformed
  }
}

// Tools like Google Docs put images inside of paragraphs, which confuses ProseMirror's parser
// since our schema has them as block-level. This pulls them out into their own blocks.
const pullImageOutOfTextBlock = (img: Element) => {
  const textBlock = img.closest('p, h1, h2, h3, h4, h5, h6, ul, ol, li')
  if (!textBlock) return
  textBlock.after(img)
}

const removeEmptyParagraphs = (elt: HTMLElement) => {
  if (isElementEmpty(elt)) {
    // Don't remove empty paragraphs if they're the only child of their parent
    if (elt.parentElement?.childElementCount === 1) {
      return
    }
    elt.remove()
  }
}
