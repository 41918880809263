import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { t } from '@lingui/macro'

import { ButtonVariant } from './types'

export const MAX_BUTTONS = 3

export const getButtonVariants = (): {
  name: string
  key: ButtonVariant
  icon: FontAwesomeIconProps['icon']
}[] => [
  {
    name: t`Solid button`,
    key: 'solid',
    icon: solid('rectangle-wide'),
  },
  {
    name: t`Outlined button`,
    key: 'outline',
    icon: regular('rectangle-wide'),
  },
  {
    name: t`Link button`,
    key: 'link',
    icon: regular('link'),
  },
]

export const BUTTON_GROUP_RULES: Record<
  string,
  {
    alignEnabled: boolean
    variants: ButtonVariant[]
    defaultVariant: ButtonVariant
  }
> = {
  buttonGroup: {
    alignEnabled: true,
    variants: ['solid', 'outline'],
    defaultVariant: 'solid',
  },
  navbarButtons: {
    alignEnabled: false,
    variants: ['solid', 'outline'],
    defaultVariant: 'solid',
  },
  navbarLinks: {
    alignEnabled: true,
    variants: ['link'],
    defaultVariant: 'link',
  },
}
