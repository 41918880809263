import {
  Badge,
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip, SectionTitle, useGammaTooltipHider } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { Editor } from '@tiptap/core'
import {
  DragEventHandler,
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
} from 'react'

import { NodeInsertMethods } from 'modules/segment'
import {
  checkCommandDisabled,
  CommandInfo,
  setDraggingContent,
  trackItemInserted,
} from 'modules/tiptap_editor/commands'
import { assignCardIds } from 'modules/tiptap_editor/extensions/Card/uniqueId'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { EMPTY_NODES } from '../../../commands/emptyNodes'
import { editorHasFocus } from '../../../utils'
import { useDragAndMouseDownMonitor } from './hooks'
import { InsertableCategoryInfo } from './items'

const iconShadow = '0px 1px 0px rgba(0,0,0,0)'
const POPOVER_CLASS = 'insert-widget-popover'

export const CardItemRow = ({ item }: { item: CommandInfo }) => {
  const colors = {
    iconColor: useColorModeValue('trueblue.600', 'trueblue.100'),
    bgColor: useColorModeValue('gray.50', 'gray.700'),
    textColor: useColorModeValue('gray.800', 'gray.100'),
    invertFilter: useColorModeValue('invert(0)', 'invert(.7) brightness(1.2)'),
    iconBgColor: useColorModeValue('white', 'gray.800'),
  }

  const { previewImage, shortcut, name } = item
  return (
    <HStack spacing={4}>
      {previewImage?.src && (
        <Flex
          w="110px"
          flex="1 0 auto"
          bg={colors.iconBgColor}
          borderRadius="md"
        >
          <Image
            filter={colors.invertFilter}
            src={previewImage.src}
            w="100%"
            alt={name()}
          />
        </Flex>
      )}
      <Text w="100%" fontWeight="600" fontSize="sm" color={colors.textColor}>
        {name()}
        {shortcut && (
          <Text fontSize="xs" color="gray.500" fontWeight="500">
            {shortcut}
          </Text>
        )}
      </Text>
    </HStack>
  )
}

const InsertWidgetItemRow = ({
  item,
  onDragStart,
  onDragEnd,
  onClick,
}: {
  item: CommandInfo
  onDragStart: DragEventHandler<HTMLDivElement>
  onDragEnd: DragEventHandler<HTMLDivElement>
  onClick: MouseEventHandler<HTMLDivElement>
}) => {
  const {
    isDragging,
    isMouseDown,
    handleDragStart,
    handleDragEnd,
    handleClick,
    onMouseDown,
    onMouseUp,
  } = useDragAndMouseDownMonitor(onDragStart, onDragEnd, onClick)
  const {
    key,
    name,
    description,
    type,
    image,
    shortcut,
    icon,
    previewImage,
    nodeName,
    badge,
  } = item

  const showIcon = type !== 'cardTemplate'

  const colors = {
    iconColor: useColorModeValue('trueblue.600', 'trueblue.100'),
    bgColor: useColorModeValue('gray.50', 'gray.900'),
    textColor: useColorModeValue('gray.800', 'gray.100'),
    borderColor: useColorModeValue('gray.200', 'gray.700'),
  }

  return (
    <GammaTooltip
      // Since clicking does nothing, leave the tooltip open on mousedown/click
      closeOnMouseDown={true}
      closeOnClick={true}
      // Once dragging starts, override tooltip to be closed
      isOpen={isDragging ? false : undefined}
      label={
        <Box as="span" display="inline">
          <FontAwesomeIcon icon={regular('hand')} />{' '}
          <Text display="inline">
            <Trans>Drag to insert</Trans>
          </Text>
        </Box>
      }
      aria-label={t`Drag to insert`}
      placement="top"
    >
      <HStack
        data-testid={`${key}-insert-button`}
        draggable={true}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onClick={handleClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseUp}
        bg={colors.bgColor}
        p={2}
        px={3}
        spacing={4}
        align="start"
        shadow="sm"
        borderRadius="xl"
        _hover={{
          transform: 'scale(1.05) rotate(-0.25deg)',
          shadow: 'lg',
        }}
        cursor="grab"
        transitionProperty="common"
        transitionDuration="normal"
        border="1px"
        borderColor={colors.borderColor}
        transform={`rotate(${
          isMouseDown && !isDragging ? -1 : 0
        }deg) translate(0, 0);`}
      >
        {nodeName === 'card' ? (
          <CardItemRow item={item} />
        ) : (
          <Stack
            spacing={0}
            textAlign="left"
            whiteSpace="break-spaces"
            flex="1"
          >
            <HStack>
              <Text color={colors.textColor} fontWeight="600" fontSize="sm">
                {name()}
              </Text>
              {badge && <Badge colorScheme="green">{badge()}</Badge>}
            </HStack>
            {description && (
              <Text fontSize="xs" color="gray.500" fontWeight="500" mt={1}>
                {description()}
              </Text>
            )}
            {shortcut && (
              <Text fontSize="xs" color="gray.500" fontWeight="500">
                {shortcut}
              </Text>
            )}
            {previewImage && (
              <Image
                src={previewImage.src}
                w="fit-content"
                maxH="4em"
                // Prevents the image itself from being draggable on Windows
                pointerEvents="none"
              />
            )}
          </Stack>
        )}
        {showIcon && (
          <Flex
            justifyContent="center"
            alignItems="flex-start"
            color={colors.iconColor}
            py={1}
          >
            {image ? (
              <Image
                src={image.src}
                borderRadius="sm"
                h="1.25em"
                w="1.25em"
                objectFit="contain"
              />
            ) : (
              <FontAwesomeIcon icon={icon} />
            )}
          </Flex>
        )}
      </HStack>
    </GammaTooltip>
  )
}
export const InsertWidgetButtons = ({
  icon,
  name,
  itemGroups,
  editor,
  setOpenButton,
  isOpen,
}: InsertableCategoryInfo & {
  editor: Editor
  setOpenButton: React.Dispatch<
    React.SetStateAction<InsertableCategoryInfo['name'] | null>
  >
  isOpen: boolean
}) => {
  const popoverContentRef = useRef<HTMLDivElement>(null)
  const { GammaTooltipHiderContext, hideTooltips } = useGammaTooltipHider()

  const colors = {
    iconColor: useColorModeValue('trueblue.600', 'trueblue.200'),
    hoverColor: useColorModeValue('gray.100', 'whiteAlpha.100'),
    activeColor: useColorModeValue('gray.200', 'whiteAlpha.200'),
    popoverBorderColor: useColorModeValue('whiteAlpha.600', 'whiteAlpha.300'),
    popoverBackgroundColor: useColorModeValue('#F9FAFBFA', 'blackAlpha.900'),
  }

  const closeFn = useCallback(() => {
    setOpenButton(null)
  }, [setOpenButton])

  useEffect(() => {
    if (isOpen && popoverContentRef.current) {
      popoverContentRef.current.scrollTo({ top: 0 })
    }
  }, [isOpen])
  return (
    <Popover
      placement="start"
      isOpen={isOpen}
      isLazy
      lazyBehavior="keepMounted"
      returnFocusOnClose={false}
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: true,
          options: { padding: 20 },
        },
      ]}
      // eslint-disable-next-line
      autoFocus={false}
    >
      <GammaTooltip
        label={name}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, -2],
            },
          },
        ]}
        shouldWrapChildren
        aria-label={name}
        placement="left"
        portalProps={{
          appendToParentPortal: true,
        }}
      >
        <PopoverTrigger>
          <IconButton
            aria-label={name}
            data-testid={`${name
              .toLowerCase()
              .split(' ')
              .join('-')}-widget-inner-button`}
            size="md"
            icon={<FontAwesomeIcon icon={icon} />}
            color={colors.iconColor}
            fontWeight="500"
            borderRadius="md"
            backdropFilter="blur(20px)"
            variant="undefined"
            bg={isOpen ? colors.activeColor : undefined}
            _hover={{
              bg: isOpen ? colors.activeColor : colors.hoverColor,
            }}
            textShadow={iconShadow}
            onMouseDown={(e) => {
              if (editor.isFocused) {
                preventDefaultToAvoidBlur(e)
              }
              setOpenButton((prev) => {
                return prev === name ? null : name
              })
              return true
            }}
          />
        </PopoverTrigger>
      </GammaTooltip>

      <Portal>
        <PopoverContent
          bg={colors.popoverBackgroundColor}
          borderWidth="1px"
          borderColor={colors.popoverBorderColor}
          borderRadius="xl"
          shadow="xl"
          // Show the Popover buttons with a higher zIndex than the widget toolbar
          zIndex="overlay"
          overflow="hidden"
          maxH="80vh"
          p={0}
          className={POPOVER_CLASS}
        >
          <Flex
            overflow="hidden auto"
            direction="column"
            h="100%"
            ref={popoverContentRef}
            flex={1}
            data-insert-widget-popover
          >
            <GammaTooltipHiderContext>
              {itemGroups.map(({ subcategory, items }, itemGroupIndex) => {
                if (itemGroups.length == 1) {
                  return (
                    <Stack key={itemGroupIndex} p={2}>
                      {items.map((item) => {
                        if (checkCommandDisabled(editor, item, false))
                          return null
                        return (
                          <InsertWidgetItemRow
                            item={item}
                            key={item.key}
                            onDragStart={(ev) =>
                              onItemDragStart(
                                item,
                                editor,
                                closeFn,
                                hideTooltips
                              )
                            }
                            onDragEnd={(e) => onItemDragEnd(item, editor, e)}
                            onClick={() => onItemClick(item, editor, closeFn)}
                          />
                        )
                      })}
                    </Stack>
                  )
                } else {
                  return (
                    <Stack
                      p={2}
                      key={itemGroupIndex}
                      mt={itemGroupIndex > 0 ? 1 : 0}
                    >
                      <SectionTitle flex="1" textAlign="left">
                        {subcategory}
                      </SectionTitle>
                      {items.map((item) => {
                        if (checkCommandDisabled(editor, item, false))
                          return null
                        return (
                          <InsertWidgetItemRow
                            item={item}
                            key={item.key}
                            onClick={(ev) => onItemClick(item, editor, closeFn)}
                            onDragStart={(ev) =>
                              onItemDragStart(
                                item,
                                editor,
                                closeFn,
                                hideTooltips
                              )
                            }
                            onDragEnd={(e) => onItemDragEnd(item, editor, e)}
                          />
                        )
                      })}
                    </Stack>
                  )
                }
              })}
            </GammaTooltipHiderContext>
          </Flex>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export const onItemClick = (
  item: CommandInfo,
  editor: Editor,
  closeFn: () => void
) => {
  if (
    !editorHasFocus(editor) ||
    checkCommandDisabled(editor, item) ||
    !editor.state.selection.empty
  ) {
    return
  }
  closeFn()
  trackItemInserted(item, NodeInsertMethods.INSERT_WIDGET)
  item.execute(editor)
}

export const onItemDragEnd = (
  item: CommandInfo,
  editor: Editor,
  e: MouseEvent
) => {
  e.preventDefault()
  e.stopPropagation()
  const { dragEndFn } = item
  try {
    if (dragEndFn) {
      dragEndFn(editor)
    }
    trackItemInserted(item, NodeInsertMethods.INSERT_WIDGET)
  } catch (e) {
    console.error('[InsertWidget] dragEndFn error:', e)
  }
}

export const onItemDragStart = (
  item: CommandInfo,
  editor: Editor,
  closeFn: () => void,
  afterDragStart?: () => void
) => {
  setTimeout(() => {
    closeFn()
  })
  const nodeToAdd =
    item.nodeName === 'card'
      ? assignCardIds(EMPTY_NODES[item.key])
      : EMPTY_NODES[item.key]
  const dragStartFn = item.dragStartFn
    ? item.dragStartFn
    : nodeToAdd
    ? () => setDraggingContent(editor, nodeToAdd)
    : undefined

  if (!dragStartFn) {
    console.error('[InsertWidget] dragStartFn undefined', item)
    return
  }
  dragStartFn(editor)
  afterDragStart?.()
}
