import { JSONContent } from '@tiptap/core'
import { clamp, flatten } from 'lodash'

const MAX_LEVEL = 3

export type SplitOptions = {
  splitOnHeadings: boolean
  splitOnDividers: boolean
  nested: boolean
}

export const splitCards = (
  content: JSONContent[],
  options: Partial<SplitOptions>
): JSONContent[] => {
  const root: JSONContent = {
    type: 'document',
    content: [],
  }
  const addNodeAtLevel = (node: JSONContent, level: number) => {
    let card = root
    for (let l = 0; l < level; l++) {
      card.content ||= []
      let newCard = card.content[card.content.length - 1]
      if (!newCard || newCard.type !== 'card') {
        newCard = {
          type: 'card',
          content: [],
        }
        card.content.push(newCard)
      }
      card = newCard
    }
    // console.debug('addNodeAtLevel', { level, node, card })
    card.content?.push(node)
  }

  // The nesting depth in Gamma, capped at MAX_LEVEL and always increasing by 1
  let gammaLevel = 1
  // The heading level in the doc. Could go beyond MAX_LEVEL or jump eg h1 to h3
  let docsLevel: number | null = null
  for (let i = 0; i < content.length; i++) {
    const node = content[i]

    if (
      options.splitOnHeadings &&
      node.type === 'heading' &&
      node.content?.length
    ) {
      const { level } = node.attrs || {}
      // The first heading should start a new card
      if (docsLevel == null) {
        docsLevel = level
      } else if (level > docsLevel) {
        gammaLevel += 1
        docsLevel = level
      } else if (level < docsLevel) {
        gammaLevel = level
        docsLevel = level
      }
      if (gammaLevel > 1 && !options.nested) {
        // If we aren't doing nested cards, always reset to leve l1
        gammaLevel = 1
        docsLevel = 1
        addNodeAtLevel(node, gammaLevel)
        continue
      }
      gammaLevel = clamp(gammaLevel, 1, MAX_LEVEL)

      // Create a new card at the parent level
      addNodeAtLevel(
        {
          type: 'card',
          content: [],
        },
        gammaLevel - 1
      )
    } else if (options.splitOnDividers && node.type === 'divider') {
      // Start a new card at the same level
      addNodeAtLevel(
        {
          type: 'card',
          content: [],
        },
        gammaLevel - 1
      )
      // Skip adding this node
      continue
    }
    addNodeAtLevel(node, gammaLevel)
  }

  // If we have a card that's just other cards, "unwrap" it
  // so that they all go up a level.
  const unwrapped = flatten(unwrapCard(root))
  console.debug('splitCards', root, unwrapped)
  return unwrapped
}

const unwrapCard = (card: JSONContent): JSONContent[] => {
  if (card.content?.every((node) => node.type === 'card')) {
    return flatten(card.content.map(unwrapCard))
  } else {
    return [card]
  }
}
