import DOMPurify from 'dompurify'

let windowObject

if (typeof window === 'undefined' && process.env.SCHEMA_BUILD !== 'true') {
  // See https://github.com/cure53/DOMPurify#running-dompurify-on-the-server
  const jsdom = require('jsdom')
  windowObject = new jsdom.JSDOM('').window
}

// SSR & CSR compatible DOMPurify instance
export const domPurify = DOMPurify(windowObject)
