import { Range } from '@tiptap/core'
import { Slice } from 'prosemirror-model'
import { EditorState, PluginKey, Transaction } from 'prosemirror-state'

import { findParentNodes } from 'modules/tiptap_editor/utils'
import { isCardNode } from 'modules/tiptap_editor/utils/nodeHelpers'
import {
  absoluteToRelativeRange,
  RelativeRange,
  relativeToAbsoluteRange,
} from 'modules/tiptap_editor/utils/relativePosition'

export const AiModificationsPluginKey = new PluginKey<AiModificationsState>(
  'aiModifications'
)
export interface SetInteractionRangeAction {
  type: 'setInteractionRange'
  rangeId: string
  range: RelativeRange
}

export type ApplyingAiModificationEvent = {
  rangeId: string
}
type AiModificationActions = SetInteractionRangeAction

export class AiModificationsState {
  queuedMoveRanges: { [rangeId: string]: Range } = {}

  ranges: { [rangeId: string]: RelativeRange } = {}

  constructor() {}

  getExistingRangeId(state: EditorState, range: RelativeRange): string | null {
    const absRange = relativeToAbsoluteRange(state, range)
    if (!absRange) {
      return null
    }

    const found = Object.entries(this.ranges).find(([_id, check]) => {
      const toCheckRange = relativeToAbsoluteRange(state, check)
      if (!toCheckRange) {
        return false
      }
      if (
        absRange.from === toCheckRange.from &&
        absRange.to === toCheckRange.to
      ) {
        return true
      }
      return false
    })

    return found ? found[0] : null
  }

  getRangeParentCardId(state: EditorState, rangeId: string): string | null {
    const relativeRange = this.ranges[rangeId]
    if (!relativeRange) {
      return null
    }
    const absRange = relativeToAbsoluteRange(state, relativeRange)
    if (!absRange) {
      return null
    }

    const pos = absRange.from
    const nodeAtPos = state.doc.nodeAt(pos)
    const $pos = state.doc.resolve(pos)
    const parentCard =
      nodeAtPos && isCardNode(nodeAtPos)
        ? { node: nodeAtPos, pos, start: $pos.start, depth: $pos.depth }
        : findParentNodes($pos, isCardNode)[0]

    if (!parentCard) {
      return null
    }

    return parentCard.node.attrs.id
  }

  getRange(state: EditorState, rangeId: string): Range | null {
    const queued = this.queuedMoveRanges[rangeId]
    if (queued) {
      return queued
    }

    const relativeRange = this.ranges[rangeId]
    if (!relativeRange) {
      return null
    }
    const res = relativeToAbsoluteRange(state, relativeRange)
    return res
  }

  apply(tr: Transaction, state: EditorState): this {
    const event = tr.getMeta('aiModificationAction') as
      | AiModificationActions
      | undefined
    if (event) {
      switch (event.type) {
        case 'setInteractionRange':
          this.setInteractionRange(event, state)
          break
      }
    }

    const applyingAiModification = tr.getMeta('applyingAiModification') as
      | ApplyingAiModificationEvent
      | undefined

    if (applyingAiModification) {
      this.handleRangeMapping(state, tr, applyingAiModification.rangeId)
      return this
    }
    // handle the case where a applyAiModification transaction has emojis
    // handle the following migrateNativeEmoji appended transaction
    const prevTr = tr.getMeta('appendedTransaction') as Transaction | undefined

    if (prevTr) {
      this.handleAppendedTransaction(state, tr, prevTr)
      return this
    }

    return this
  }

  private handleAppendedTransaction(
    state: EditorState,
    tr: Transaction,
    prevTr: Transaction
  ) {
    if (prevTr.getMeta('applyingAiModification')) {
      const aiModificationEvent = prevTr.getMeta('applyingAiModification') as
        | ApplyingAiModificationEvent
        | undefined

      if (!aiModificationEvent) {
        return
      }

      // map with the appended transaction
      this.handleRangeMapping(state, tr, aiModificationEvent.rangeId)
    }
  }

  private handleRangeMapping(
    state: EditorState,
    tr: Transaction,
    rangeId: string
  ): void {
    const range = this.getRange(state, rangeId)
    if (!range) {
      console.warn(
        `[AiModificationState] Could not find range for rangeId=${rangeId}`
      )
      return
    }
    const newRange = {
      from: range.from,
      to: tr.mapping.map(range.to),
    }
    if (range.from === newRange.to && range.to === newRange.to) {
      return
    }

    this.enqueueMoveInstructions(rangeId, newRange)
  }

  private enqueueMoveInstructions(rangeId: string, newRange: Range) {
    this.queuedMoveRanges[rangeId] = newRange
  }

  public flushMoveInstructionQueue(state: EditorState) {
    for (const [key, value] of Object.entries(this.queuedMoveRanges)) {
      this.ranges[key] = absoluteToRelativeRange(state, value)
    }
    this.queuedMoveRanges = {}
  }

  protected setInteractionRange(
    action: SetInteractionRangeAction,
    _state: EditorState
  ) {
    this.ranges[action.rangeId] = action.range
  }
}
