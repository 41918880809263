import { t } from '@lingui/macro'
import { Editor } from '@tiptap/core'

import {
  DesignPartnerTask,
  DesignPartnerTaskParams,
  DesignPartnerTaskRunOptions,
  TaskContext,
  TaskEditorContext,
  TaskTarget,
} from 'modules/ai/chat/Task'
import { determineGpt35ModelToRun } from 'modules/ai/openai'
import { BackendChatCompletionPrompt } from 'modules/ai/prompt/BackendChatCompletionPrompt'
import {
  ChatCompletionGenerateResult,
  runChatCompletionPrompt,
} from 'modules/ai/prompt/ChatCompletionPrompt'
import { transformHtmlFromAI } from 'modules/ai/transform/transformHtmlFromAI'
import { ThemeModificationController } from 'modules/ai/useThemeModificationController'
import { featureFlags } from 'modules/featureFlags/FeatureFlagProvider'

import {
  CouldNotApplySuggestionError,
  InvalidResponseError,
  NoopSuggestionError,
  ParsedSuggestionError,
} from '../../errors'
import { DesignPartnerModification } from '../../modifications/DesignPartnerModification'
import { ReplaceContentModification } from '../../modifications/ReplaceContentModification'
import {
  getModificationRange,
  getOrSetModifiedRangeId,
} from '../../modifications/utils'
import {
  ChatTrackingFns,
  DesignPartnerSuggestion,
  Messages,
  ModifyContentSuggestion,
} from '../../types'
import { containsHtml } from '../utils/containsHtml'
import { getMessageHistory } from '../utils/messageHistory'

type SuggestPrompt = BackendChatCompletionPrompt<'html' | 'message' | 'outline'>

type ParsedSuggestionResponse = {
  suggestion?: string
  message: string
  raw: string
  shouldRetry?: boolean
}

type SuggestionResponseParser = (
  response: string,
  variables: any
) => ParsedSuggestionResponse

export const CONTENT_FAILURE_MESSAGE = () =>
  t`😔 Sorry, I tried this but it didn't work. Could you try asking in a different way?`

export type ParsedSuggestResponseWithContext = {
  editor: Editor
  context: TaskContext
  rangeId: string
  response: {
    raw: string
    suggestion?: string
    message: string
  }
  themeModificationController: ThemeModificationController
}

export class SuggestContentTask<
  P extends SuggestPrompt = SuggestPrompt
> extends DesignPartnerTask {
  prompt: P

  parseResponse: SuggestionResponseParser

  getTaskTarget?: (editorContext: TaskEditorContext) => TaskTarget

  constructor(
    opts: DesignPartnerTaskParams & {
      prompt: P
      getTaskTarget?: (editorContext: TaskEditorContext) => TaskTarget
      parseResponse?: SuggestionResponseParser
    }
  ) {
    super(opts)
    this.prompt = opts.prompt
    this.parseResponse = opts.parseResponse || extractSuggestionFromResponse
    this.getTaskTarget = opts.getTaskTarget
  }

  async getResponseMessages({
    editor,
    context,
    rangeId,
    response: { raw, suggestion: html, message },
  }: ParsedSuggestResponseWithContext): Promise<{
    messages: Messages[]
    suggestionToApply?: DesignPartnerSuggestion
  }> {
    if (!html) {
      throw new InvalidResponseError(
        '[SuggestContentTask] No html provided in prompt response',
        {
          chatMessage: message,
          inputMessage: context.message,
          resp: raw,
          interactionId: context.interactionId,
        }
      )
    }
    // Todo: if modified content is the same as the original, don't suggest it,
    // just show the failure message instead
    const transformedHtml = await transformHtmlFromAI(html, {
      loadImages: true,
    })
    console.debug('[AIChat SuggestContentTask] modify suggestion', {
      transformedHtml,
      html,
    })
    const modification: DesignPartnerModification =
      new ReplaceContentModification(editor, {
        description: t`Suggested`,
        content: {
          type: 'html',
          html: transformedHtml,
        },
        rangeId,
      })

    const suggested: ModifyContentSuggestion = {
      label: t`Suggested`,
      modification,
      jsonContentPreview: modification.getPreviewJson(editor),
    }
    const original = this.getOriginalModifyContentSuggestion(
      editor,
      context,
      rangeId
    )

    const reply: Messages = {
      from: 'Designer',
      type: 'suggestContent',
      resp: raw,
      message: message || t`How about this?`,
      suggestions: [suggested, original],
      interactionId: context.interactionId,
    }

    return {
      messages: [reply],
      suggestionToApply: suggested,
    }
  }

  async runPrompt({
    input,
    context,
    variables,
    trackFns,
    retries = 2,
  }: {
    input: ChatCompletionGenerateResult
    context: TaskContext
    variables: Record<string, any>
    trackFns: ChatTrackingFns
    retries?: number
  }): Promise<{ resp: string; parsed: ParsedSuggestionResponse }> {
    const resp = await runChatCompletionPrompt(input, context.interactionId, {
      timeout: featureFlags.get('aiRequestTimeouts').suggestContent,
    })

    if (!resp) {
      throw new InvalidResponseError("Couldn't get response from prompt", {
        resp,
        inputMessage: context.message,
        interactionId: context.interactionId,
      })
    }

    const parsed = this.parseResponse(resp, variables)

    if (parsed.shouldRetry) {
      if (retries <= 0) {
        throw new ParsedSuggestionError(
          'Could not parse response properly after retries',
          {
            chatMessage: CONTENT_FAILURE_MESSAGE(),
            inputMessage: context.message,
            resp,
            interactionId: context.interactionId,
          }
        )
      }
      trackFns.trackRequestRetry({
        interactionId: context.interactionId,
        retriesRemaining: retries,
        resp,
      })
      return this.runPrompt({
        input,
        context,
        retries: retries - 1,
        trackFns,
        variables,
      })
    }
    return { parsed, resp }
  }

  async run({
    editor,
    context,
    addMessage,
    history,
    trackFns,
    themeModificationController,
    provider,
  }: DesignPartnerTaskRunOptions) {
    const { html, range } = this.determineTaskTarget(context.editorContext)

    trackFns.trackInputContent({ taskContext: context, inputContent: html })

    const variables = {
      message: context.message,
      html,
      outline: context.editorContext.outline,
    }

    // once the task has started we know the target range and html we will use from
    // here on out
    const rangeId = getOrSetModifiedRangeId(
      editor,
      context.interactionId,
      range
    )
    const suggestHistory = getMessageHistory(history || [])
    const input = this.prompt.prepare({
      variables,
      history: suggestHistory,
      interactionId: context.interactionId,
      params: {
        provider,
      },
    })
    console.debug('[AIChat SuggestContentTask] run', {
      html: variables.html,
      input,
      suggestHistory,
      range: getModificationRange(editor, rangeId),
    })

    // determine which model should be run, based on the max context window versus the length
    // of input.messages
    const inputWithModel = await determineGpt35ModelToRun(input)
    const { parsed, resp } = await this.runPrompt({
      input: inputWithModel,
      context,
      variables,
      trackFns,
      retries: 1,
    })

    const { messages, suggestionToApply } = await this.getResponseMessages({
      editor,
      context,
      rangeId,
      response: parsed,
      themeModificationController,
    })
    console.debug('[AIChat SuggestContentTask] completed', {
      suggestion: parsed.suggestion,
      resp,
      parsed,
      messages,
      suggestionToApply,
    })

    this.autoApplySuggestion({
      context,
      editor,
      suggestionToApply,
      resp,
      trackFns,
      themeModificationController,
    })

    messages.forEach((message) => addMessage(message))
    trackFns.trackRequestComplete({
      taskContext: context,
      outputMessage: parsed.message,
      outputContent: parsed.suggestion,
      resp,
    })
  }

  protected determineTaskTarget(editorContext: TaskEditorContext): TaskTarget {
    if (this.getTaskTarget) {
      return this.getTaskTarget(editorContext)
    }

    return editorContext.defaultRange
  }

  protected getOriginalModifyContentSuggestion(
    editor: Editor,
    context: TaskContext,
    rangeId: string
  ): ModifyContentSuggestion {
    const modification = new ReplaceContentModification(editor, {
      description: t`Original`,
      content: {
        type: 'html',
        html: this.determineTaskTarget(context.editorContext).html,
      },
      rangeId,
    })

    return {
      label: t`Original`,
      modification,
      jsonContentPreview: modification.getPreviewJson(editor),
    }
  }

  protected autoApplySuggestion({
    context,
    editor,
    suggestionToApply,
    resp,
    trackFns,
    themeModificationController,
  }: {
    context: TaskContext
    editor: Editor
    suggestionToApply: DesignPartnerSuggestion | undefined
    resp: string
    trackFns: ChatTrackingFns
    themeModificationController: ThemeModificationController
  }) {
    if (!suggestionToApply) {
      return
    }

    if (
      suggestionToApply.modification.isApplied(
        editor,
        themeModificationController
      )
    ) {
      console.debug(
        '[SuggestContentTask] suggestion does not change the content',
        suggestionToApply
      )
      throw new NoopSuggestionError('Suggestion does not change content', {
        resp,
        interactionId: context.interactionId,
        chatMessage: CONTENT_FAILURE_MESSAGE(),
      })
    }

    // try to apply suggestion, if the suggestion is already active then we
    try {
      const { modification } = suggestionToApply
      modification.apply(editor, themeModificationController)
      trackFns.trackSuggestionApplied({
        interactionId: context.interactionId,
        suggestionContent: modification.getContentForTracking(),
        suggestionLabel: modification.description,
        suggestionType: modification.type,
        autoApplied: true,
      })
    } catch (e) {
      throw new CouldNotApplySuggestionError('Could not apply suggestion', {
        interactionId: context.interactionId,
      })
    }
  }
}

// Example of an incomplete response (2 parts, missing closing ticks)
/**
`Sure thing! Here are some suggestions based on your request:

\`\`\`
UNSPLASH: abstract background, cool colors, geometric shapes
GOOGLE: abstract background, cool colors, geometric shapes
`
 */
const EXTRACT_CODE_REGEX = /(.*?)```[A-Za-z]*\n(.*?)\n```(.*?)$/ims
export const extractSuggestionFromResponse = (
  response: string
): ParsedSuggestionResponse => {
  const match = response.match(EXTRACT_CODE_REGEX)
  let messageParts: string[] = []
  let suggestion: string | undefined = undefined

  if (match) {
    messageParts = [match[1], match[3]]
    suggestion = match[2]
  } else {
    const responseParts = response.split('```')

    if (responseParts.length === 0) {
      throw new Error('Could not extract code from response')
    }

    if (responseParts.length === 1) {
      // 1 means the response did not include any code fence blocks
      // We'll catch this case above in getResponseMessages
      if (containsHtml(responseParts[0])) {
        return {
          suggestion: undefined,
          raw: response,
          message: response,
          shouldRetry: true,
        }
      }
      return {
        suggestion: undefined,
        raw: response,
        message: response.replaceAll(/(HTML|code)/gi, 'content'),
      }
    }

    // 2 parts means the response was truncated (no closing ticks for code fence block)
    // Try and use it anyway
    messageParts = [responseParts[0]]
    suggestion = responseParts[1].trim()
  }

  const message = messageParts
    .map((msg) => msg.trim())
    .filter((msg) => msg.length > 0)
    .join('\n\n')
    .replaceAll(/(HTML|code)/gi, 'content')
    .replaceAll(/(slide)/gi, 'card')

  return {
    suggestion,
    message,
    raw: response,
  }
}
