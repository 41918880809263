import { Editor, NodeViewProps } from '@tiptap/core'

import { useAppSelector } from 'modules/redux'
import { getThemeCardColor, Theme } from 'modules/theming'
import { selectTheme } from 'modules/tiptap_editor/reducer'
import { getBackgroundColor } from 'modules/tiptap_editor/styles/backgroundStyles'
import { getContainerBackgroundColor } from 'modules/tiptap_editor/styles/containerStyles'
import {
  BackgroundType,
  ContainerOptions,
} from 'modules/tiptap_editor/styles/types'

import { CardAttributes } from '../../types'

export type CardColorSelectorProps = {
  editor: Editor
  attrs: CardAttributes
  getPos: NodeViewProps['getPos']
}
export const useCardColorSelector = ({
  editor,
  attrs,
  getPos,
}: CardColorSelectorProps) => {
  const theme: Theme = useAppSelector(selectTheme)
  const currentColor = getBackgroundColor(attrs.container?.background)
  const themeCardColor =
    getThemeCardColor(theme) ?? getContainerBackgroundColor(theme)

  const setCardColor = (color: string | null) => {
    const { container } = attrs
    const containerPatch: Partial<ContainerOptions> = color
      ? {
          background: {
            type: BackgroundType.COLOR,
            color: { hex: color },
          },
        }
      : {
          background: undefined,
        }
    if (container.isDark !== undefined) {
      // When you change the color, remove the legacy isDark property from Card1
      containerPatch.isDark = undefined
    }
    editor.commands.updateNestedAttributesAtPos(getPos(), {
      container: {
        ...container,
        ...containerPatch,
      },
    })
  }

  return {
    currentColor,
    themeCardColor,
    setCardColor,
  }
}
