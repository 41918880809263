import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Trans } from '@lingui/macro'
import { Editor } from '@tiptap/core'
import { useCallback } from 'react'

import {
  BackgroundOptions,
  MaskOptions,
} from 'modules/tiptap_editor/styles/types'

import { DEFAULT_MASK } from '../BackgroundMask'
import { MASK_EFFECT_OPTIONS } from '../EditBackgroundDrawer/BackgroundMaskPanel'
import { CardStyleRow } from './CardStyleRow'

export type CardAccentBackgroundOverlaySelectorProps = {
  editor: Editor
  accentPos: number | null
  accentBackground?: BackgroundOptions
  effect: MaskOptions['effect']
}
export const CardAccentBackgroundOverlaySelector = ({
  editor,
  accentPos,
  accentBackground,
  effect,
}: CardAccentBackgroundOverlaySelectorProps) => {
  const onChange = useCallback(
    (val: string) => {
      if (!accentPos || !accentBackground) return
      const newBackground = {
        background: {
          mask: { ...accentBackground.mask, effect: val },
        },
      }
      editor.commands.updateNestedAttributesAtPos(accentPos, newBackground)
    },
    [editor, accentBackground, accentPos]
  )

  const currentEffectValue = effect ?? DEFAULT_MASK.effect!
  const currentEffectLabel =
    MASK_EFFECT_OPTIONS.find((o) => o.value === effect)?.label ?? 'Faded'

  return (
    <CardStyleRow
      label={<Trans>Overlay effect</Trans>}
      icon={regular('circle-half-stroke')}
    >
      <Menu isLazy>
        <MenuButton
          as={Button}
          variant="plain"
          size="sm"
          minW="128px"
          boxShadow="none"
          textAlign="left"
          rightIcon={<ChevronDownIcon />}
        >
          {currentEffectLabel}
        </MenuButton>
        <MenuList>
          <MenuOptionGroup
            value={currentEffectValue}
            type="radio"
            onChange={onChange}
          >
            {MASK_EFFECT_OPTIONS.map(({ value, label }) => (
              <MenuItemOption key={value} value={value}>
                {label}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </CardStyleRow>
  )
}
