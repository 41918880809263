import { Extension, findParentNode, InputRule } from '@tiptap/core'
import { TextSelection } from 'prosemirror-state'

import { ChatCompletionParams } from 'modules/ai/openai'
import { runChatCompletionPrompt } from 'modules/ai/prompt/ChatCompletionPrompt'
import { stringToTokens, tokensToString } from 'modules/ai/tokens'
import {
  AIRequestProps,
  generateAIInteractionId,
  trackAIRequestComplete,
  trackAIRequestSent,
} from 'modules/ai/track'
import { checkCredits, deductCredits } from 'modules/credits/mutations'
import { featureFlags } from 'modules/featureFlags'
import { getStore } from 'modules/redux'
import { selectDoc } from 'modules/tiptap_editor/reducer'
import {
  absoluteToRelativePos,
  relativeToAbsolutePos,
} from 'modules/tiptap_editor/utils/relativePosition'
import { createSelectionNearLastTo } from 'modules/tiptap_editor/utils/selection/findSelectionNearOrGapCursor'

import { isCardNode } from '../../Card/utils'
import { rangeToMarkdown } from '../../Clipboard/markdown'
import {
  AutocompleteInsertEvent,
  AutocompleteLoadEvent,
  AutocompletePlugin,
  AutocompleteResetEvent,
} from './AutocompletePlugin'
import { AutocompletePrompt } from './AutocompletePrompt'
// import { generateOutlineMarkdown } from './Outline'

const AUTOCOMPLETE_REGEX = /(\+\+\+)/

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    autocomplete: {
      autoComplete: (params?: ChatCompletionParams) => ReturnType
      autoOutline: () => ReturnType
    }
  }
}

export const INSERT_FADE_TIME = 4000 // ms
const LOOKBACK_LENGTH = 1000
const MAX_LOOKBACK_TOKENS = 200

export const Autocomplete = Extension.create({
  name: 'autocomplete',
  addProseMirrorPlugins() {
    return [AutocompletePlugin(this.editor)]
  },
  addCommands() {
    return {
      // Not sured anywhere, and needs refactoring to use GPT3.5 if we ever do use it
      // but keeping it around for the future
      autoOutline:
        () =>
        ({ editor, state, tr: initialTr }) => {
          const { selection, doc } = state
          const { $from, from } = selection
          const insertPosRel = absoluteToRelativePos(state, $from.after())

          // Use the existing text in the card to generate the outline. Designed to run on the title card with extra description text.
          const parentCard = findParentNode(isCardNode)(selection)
          if (!parentCard || !insertPosRel) return false
          const prevText = rangeToMarkdown(doc, parentCard.pos, from)

          initialTr.setMeta('autocompleteEvent', <AutocompleteLoadEvent>{
            type: 'load',
          })

          // TODO bring back using chatCompletion
          // generateOutlineMarkdown(prevText)
          //   .then((md) => {
          //     const insertPos = relativeToAbsolutePos(state, insertPosRel)
          //     if (!md || !insertPos) {
          //       throw new Error('No outline or insertPos')
          //     }
          //     // This range will be updated to match the actual area we selected
          //     const insertRange = { from: insertPos, to: insertPos }
          //     editor
          //       .chain()
          //       .insertMarkdownAt(insertRange, md)
          //       .command(({ tr }) => {
          //         const sel = createSelectionNearLastTo(tr)
          //         if (!sel) return true
          //         insertRange.to = sel.to
          //         tr.setSelection(sel).scrollIntoView()
          //         return true
          //       })
          //       .setMeta('autocompleteEvent', <AutocompleteInsertEvent>{
          //         range: insertRange,
          //         type: 'insert',
          //       })
          //       .run()

          //     setTimeout(() => {
          //       editor.commands.setMeta('autocompleteEvent', <
          //         AutocompleteResetEvent
          //       >{
          //         type: 'reset',
          //       })
          //     }, INSERT_FADE_TIME)
          //   })
          //   .catch((reason) => {
          //     console.warn('Error generating outline', reason)
          //     editor.commands.setMeta('autocompleteEvent', <
          //       AutocompleteResetEvent
          //     >{
          //       type: 'reset',
          //     })
          //   })
          return true
        },

      autoComplete:
        (params = {}) =>
        ({ editor, state, tr: initialTr }) => {
          const { selection, doc } = state
          const { from } = selection
          const insertPosRel = absoluteToRelativePos(state, from)
          const canAutocomplete =
            featureFlags.get('aiAutocomplete') &&
            selection.empty &&
            selection instanceof TextSelection &&
            insertPosRel

          if (!canAutocomplete) {
            return false
          }

          const reduxState = getStore().getState()
          const docWorkspace = selectDoc(reduxState)?.organization
          const hasCredits = checkCredits('autocomplete', docWorkspace)
          if (!hasCredits) {
            return false
          }

          // Get the previous text to use as a context
          // Capped at MAX_LOOKBACK_TOKENS to control our costs
          const prevMd = rangeToMarkdown(
            doc,
            Math.max(selection.from - LOOKBACK_LENGTH, 0),
            selection.from
          )
          const prevTokens = stringToTokens(prevMd).slice(-MAX_LOOKBACK_TOKENS)
          const prevMdMax = tokensToString(prevTokens)
          const prevText = doc.textBetween(selection.from - 10, selection.to)
          initialTr.setMeta('autocompleteEvent', <AutocompleteLoadEvent>{
            type: 'load',
          })

          const interactionId = generateAIInteractionId()
          const input = AutocompletePrompt.prepare({
            interactionId,
            variables: {
              text: prevMdMax,
            },
            params,
          })
          const startTime = performance.now()
          const requestData: AIRequestProps = {
            interactionId,
            interface: 'autocomplete',
            inputContent: prevMd,
          }
          trackAIRequestSent(requestData)
          runChatCompletionPrompt(input, interactionId, {
            timeout: 5000,
            retries: 1,
          })
            .then((newCompletion) => {
              const insertPos = relativeToAbsolutePos(state, insertPosRel)
              if (!newCompletion || !insertPos) {
                throw new Error('No completion or insertPos')
              }
              // Trim leading and trailing spaces
              newCompletion = newCompletion.trim()

              // This range will be updated to match the actual area we selected
              const insertRange = { from: insertPos, to: insertPos }

              const chain = editor
                .chain()
                .insertMarkdownAt(insertRange, newCompletion)
                .command(({ tr }) => {
                  const sel = createSelectionNearLastTo(tr)
                  if (!sel) return true
                  insertRange.to = sel.to
                  tr.setSelection(sel).scrollIntoView()
                  return true
                })
                .setMeta('autocompleteEvent', <AutocompleteInsertEvent>{
                  range: insertRange,
                  type: 'insert',
                })

              // Keep a space right before the insert
              const $insertPos = state.doc.resolve(insertPos)
              // console.log('Inserting', { prevText, prevMd, $insertPos })
              if (
                $insertPos.nodeBefore?.isText &&
                !(prevText.endsWith(' ') || prevText.endsWith('\n'))
              ) {
                chain.insertContentAt(insertPos, ' ', {
                  updateSelection: false,
                })
              }

              chain.run()
              trackAIRequestComplete({
                ...requestData,
                outputContent: newCompletion,
                latency: performance.now() - startTime,
                timeToComplete: performance.now() - startTime,
              })
              deductCredits('autocomplete', docWorkspace)

              setTimeout(() => {
                editor.commands.setMeta('autocompleteEvent', <
                  AutocompleteResetEvent
                >{
                  type: 'reset',
                })
              }, INSERT_FADE_TIME)
            })
            .catch((reason) => {
              console.warn('Error fetching autocomplete', reason)
              editor.commands.setMeta('autocompleteEvent', <
                AutocompleteResetEvent
              >{
                type: 'reset',
              })
            })

          return true
        },
    }
  },
  addInputRules() {
    return [
      new InputRule({
        find: AUTOCOMPLETE_REGEX,
        handler: ({ range, chain }) => {
          chain().setTextSelection(range).deleteSelection().autoComplete().run()
        },
      }),
    ]
  },
})
