import { AI_PARSED_ATTRIBUTE } from 'modules/ai/transform/constants'
import {
  getLoadImageAttrs,
  loadImageFromAttrs,
} from 'modules/ai/transform/getImagesFromAI'
import { TransformImageOptions } from 'modules/ai/transform/types'
import { querySelectorArray } from 'modules/tiptap_editor/extensions/Clipboard/domUtils'
import { getCurrentWorkspaceId } from 'modules/user'

const IMG_SELECTOR = 'img[searches], img[query]'

// Take the HTML generated by AI, and pre-process it
// before it runs through our clipboard handlers
// This is necessary for async logic like image uploads, because ProseMirror's
// parsers are all synchronous and don't have the document context.
export const transformHtmlFromAI = async (
  html: string,
  options: {
    removeImagesFromLayouts?: boolean
    loadImages?: boolean
    imageOptions?: TransformImageOptions
  } = {}
) => {
  try {
    // Parse the HTML
    const dom = new DOMParser().parseFromString(html, 'text/html')
    stripStyle(dom) //we don't use the style attribute for much other than text-align, so we want to remove any excess style attributes Sal may have added

    if (options.removeImagesFromLayouts) {
      removeImagesFromLayouts(dom)
    }

    const workspaceId = getCurrentWorkspaceId()!

    const loadImagePromises = querySelectorArray(dom, IMG_SELECTOR).map(
      async (elt) => {
        if (elt.getAttribute('bookmark')) {
          return
        }

        // We're moving our prompts to use "query", but keep supporting "searches" until they're all moved over
        let query =
          elt.getAttribute('query') || elt.getAttribute('searches') || ''

        if (
          options.imageOptions &&
          options.imageOptions.provider === 'aiGenerated' &&
          options.imageOptions.generateStyle
        ) {
          query = `${query}, ${options.imageOptions.generateStyle}`
        }

        const attrs = getLoadImageAttrs({
          query,
          provider: options.imageOptions?.provider || 'web',
          license: options.imageOptions?.license,
        })

        const loadedImageAttrs = options.loadImages
          ? await loadImageFromAttrs({
              loadImageId: attrs.loadImageId!,
              loadImageParams: attrs.loadImageParams!,
              context: {
                workspaceId,
              },
            })
          : {}

        elt.setAttribute(
          AI_PARSED_ATTRIBUTE,
          JSON.stringify({
            ...attrs,
            ...loadedImageAttrs,
          })
        )
      }
    )

    await Promise.all(loadImagePromises)

    querySelectorArray(dom, 'images').forEach(attachSmartLayoutImages)
    return dom.body.innerHTML
  } catch (err) {
    console.error('Error transforming AI HTML', err)
    return html // Return the original, untransformed
  }
}

// GPT likes to put images inside tables and timelines, even though we ask it not to.
// This prevents that and also saves us from running searches when we would just throw
// away the output.
const REMOVE_IMAGES_FROM_LAYOUTS_SELECTOR = `:is(boxes, timeline, table, bullets, arrows) img`
const removeImagesFromLayouts = (dom: Document) => {
  dom.querySelectorAll(REMOVE_IMAGES_FROM_LAYOUTS_SELECTOR).forEach((img) => {
    img.remove()
  })
}

//Strips style attributes from the DOM, but preserves text-align
const stripStyle = (dom: Document) => {
  const elements = dom.getElementsByTagName('*')
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i] as HTMLElement
    const textAlign = element.style.textAlign //extract text-align attribute in case Sal added it as a style attribute
    element.removeAttribute('style')
    if (textAlign) {
      element.style.textAlign = textAlign
    }
  }
}

const attachSmartLayoutImages = (elt: HTMLElement): void => {
  // We've already processed the img tags inside
  // Now, we want to hoist them up to the smart layout element
  const images = querySelectorArray(elt, 'div > img:first-child')
  images.forEach((img) => {
    const divElt = img.parentElement
    if (!divElt) return
    const imgAttrs = JSON.parse(img.getAttribute(AI_PARSED_ATTRIBUTE) || '{}')
    divElt.setAttribute('data-image', JSON.stringify(imgAttrs))
    img.remove()
  })
}
