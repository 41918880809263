import {
  AspectRatio,
  AvatarGroup,
  Box,
  Flex,
  GridItem,
  GridItemProps,
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { LinkTagGroup, TagConfig, TooltipAvatar } from '@gamma-app/ui'
import { t } from '@lingui/macro'
import React, {
  HTMLAttributeAnchorTarget,
  MouseEvent,
  MutableRefObject,
  useCallback,
  useState,
} from 'react'

import { formatDateRelative } from 'modules/i18n/utils/date'
import { isMobileDevice } from 'utils/deviceDetection'
import { useSSRMounted } from 'utils/hooks'
import { docTitleOrPlaceholder } from 'utils/nouns'

import { CreatedByPanel } from '../CreatedByPanel'
import { DocumentGridItemMenu } from './DocumentGridItemMenu'
import { FavoriteButton } from './FavoriteButton'

export interface Editor {
  user: {
    id: string
    displayName?: string
    profileImageUrl?: string
  }
  lastViewed?
  lastEdited?
}

const LinkWrapper = ({
  href,
  NextLink,
  target,
  children,
}: {
  href: string
  NextLink: React.ElementType | null
  target?: HTMLAttributeAnchorTarget
  children: React.ReactNode
}) => {
  if (NextLink) {
    return (
      <NextLink href={href} passHref target={target} legacyBehavior>
        <LinkOverlay outline="none">{children}</LinkOverlay>
      </NextLink>
    )
  }
  return (
    <LinkOverlay outline="none" href={href} target={target}>
      {children}
    </LinkOverlay>
  )
}

export interface DocumentGridItemProps extends GridItemProps {
  title: string
  NextLink: React.ElementType | null
  href: string
  id: string
  createdByYou?: boolean
  createdByName?: string
  createdByEmail?: string
  createdByProfileImageUrl?: string
  createdTime: string // needs to be passed to parseISO
  thumbnailImageUrl?: {
    src?: string
    fallbackSrc?: string
  }
  timestampDescription?: React.ReactNode
  editors?: Editor[]
  isDuplicateEnabled?: boolean
  isRenameEnabled?: boolean
  isShareEnabled?: boolean
  isTrashAndRestoreEnabled?: boolean
  isRemoveFromChannelEnabled?: boolean
  isTrashed?: boolean
  isFavorited?: boolean
  onDuplicate?: () => void
  onFavorite?: () => void
  onUnfavorite?: () => void
  onTrash?: () => void
  onPermanentDelete?: () => void
  onRestore?: () => void
  onShareClick?: () => void
  onRename?: () => void
  onRemoveFromChannel?: () => void
  cardThumbnails?: Array<{
    id?: string
    previewUrl?: string
    archived?: boolean
  }>

  context?: 'popover' | 'grid'
  tags?: TagConfig[]
  menuEnabled?: boolean
  openInNewWindow?: boolean
  activity?: JSX.Element
}

export const DocumentGridItemThumbnailScrubber = ({
  cardThumbnails,
  hoveredIndex,
}: {
  cardThumbnails: Array<{
    id?: string
    previewUrl?: string
    archived?: boolean
  }>
  hoveredIndex: number
}) => {
  return (
    <HStack
      justifyContent="flex-start"
      alignItems="top"
      w="100%"
      position="absolute"
      zIndex={2}
      top={0}
      spacing={0}
    >
      {cardThumbnails.map((cardThumbnail, index) => (
        <Image
          className="thumbnail-scrubber"
          key={cardThumbnail.id}
          src={cardThumbnail.previewUrl}
          fit="cover"
          position="absolute"
          left={0}
          pointerEvents="none"
          transform={`translate(calc(calc(${index} * 100%) - ${hoveredIndex} * 100%),0px)`}
        />
      ))}
    </HStack>
  )
}

export const DocumentGridItem = React.forwardRef(
  (
    {
      id,
      title,
      thumbnailImageUrl,
      NextLink,
      href,
      createdByYou = false,
      createdByName,
      createdByEmail,
      createdTime,
      createdByProfileImageUrl,
      timestampDescription,
      editors,
      isDuplicateEnabled = true,
      isRenameEnabled = false,
      isShareEnabled = false,
      isTrashAndRestoreEnabled = true,
      isRemoveFromChannelEnabled = false,
      isTrashed = false,
      isFavorited = false,
      onDuplicate,
      onFavorite,
      onUnfavorite,
      onTrash,
      onPermanentDelete,
      onRestore,
      onShareClick,
      onRename,
      onRemoveFromChannel,
      cardThumbnails,
      context = 'grid',
      tags = [],
      menuEnabled = false,
      openInNewWindow = false,
      activity = undefined,
      ...rest
    }: DocumentGridItemProps,
    ref: MutableRefObject<HTMLDivElement>
  ) => {
    const ssrMounted = useSSRMounted()
    const linkTarget = openInNewWindow ? '_blank' : undefined
    // The avatar group goes in a different place depending on whether or not thumbnailImageUrl is provided
    const avatarGroup = editors && editors.length > 0 && (
      <AvatarGroup size="xs" max={5} fontSize="xs">
        {editors.map((editor) => (
          <TooltipAvatar
            key={`${id}-${editor.user.id}`}
            shadow="md"
            borderWidth="1px"
            name={
              ssrMounted
                ? t`${editor.user.displayName} edited ${formatDateRelative(
                    editor.lastEdited
                  )} ago`
                : ' '
            }
            src={editor.user.profileImageUrl}
            tooltipProps={{
              placement: 'top',
            }}
          />
        ))}
      </AvatarGroup>
    )
    const [hoveredIndex, setHoveredIndex] = useState(0)
    const onMouseMove = useCallback(
      (e: MouseEvent) => {
        if (!cardThumbnails) {
          return
        }
        const rect = (e.currentTarget as HTMLElement).getBoundingClientRect()
        const offset = e.pageX - rect.left
        const ratio = offset / rect.width
        const indexAtPercent = Math.floor(cardThumbnails.length * ratio)
        setHoveredIndex(indexAtPercent)
      },
      [cardThumbnails]
    )

    return (
      <LinkBox
        overflow="hidden"
        key={id}
        ref={ref}
        data-doc-grid-item-id={id}
        bg="linen.50"
        borderRadius="5px"
        shadow="md"
        as={GridItem}
        _focusWithin={{ shadow: 'outline', _hover: { shadow: 'outline' } }}
        cursor="pointer"
        _hover={{
          shadow: 'lg',
          bgColor: context === 'popover' ? 'white' : 'gray.100',
        }}
        transition="box-shadow .2s ease"
        role="group"
        onMouseMove={onMouseMove}
        className="highlight-mask"
        {...rest}
      >
        <Flex
          direction="column"
          wordBreak="break-word"
          alignContent="space-between"
          height="100%"
        >
          {thumbnailImageUrl && (
            <LinkWrapper NextLink={NextLink} href={href} target={linkTarget}>
              <AspectRatio ratio={40 / 21} bgColor="gray.100">
                <Flex borderBottomWidth="1px" borderBottomColor="gray.100">
                  <DocumentGridItemThumbnailScrubber
                    cardThumbnails={cardThumbnails || []}
                    hoveredIndex={hoveredIndex}
                  />
                  <Image
                    src={thumbnailImageUrl?.src}
                    fallbackSrc={thumbnailImageUrl?.fallbackSrc}
                    fit="cover"
                  />
                  {Boolean(avatarGroup) && (
                    <Box
                      position="absolute"
                      right={0}
                      bottom={0}
                      borderRadius="full"
                      p={3}
                      zIndex={1}
                    >
                      {avatarGroup}
                    </Box>
                  )}
                </Flex>
              </AspectRatio>
            </LinkWrapper>
          )}
          <Flex
            p={3}
            position="relative"
            direction="column"
            justifyContent={'space-between'}
            h="100%"
            alignItems="flex-start"
          >
            <LinkWrapper NextLink={NextLink} href={href} target={linkTarget}>
              <Text noOfLines={2} h={12} mb={2}>
                {docTitleOrPlaceholder(title)}
              </Text>
            </LinkWrapper>

            <Flex direction="column" w="100%">
              {tags && tags.length > 0 && NextLink && (
                <LinkTagGroup
                  tags={tags}
                  NextLink={NextLink}
                  overflowButtonLabel={`See all folders`}
                />
              )}
              <Flex mt={2} align="flex-start" w="100%" justify="space-between">
                {activity && activity}
                {createdByName && (
                  <CreatedByPanel
                    createdByYou={createdByYou}
                    createdByName={createdByName}
                    createdByProfileImageUrl={createdByProfileImageUrl}
                    timestampDescription={timestampDescription}
                  />
                )}
                <Spacer />
                {menuEnabled && (
                  <DocumentGridItemMenu
                    href={href}
                    title={docTitleOrPlaceholder(title)}
                    createdByName={createdByName}
                    createdByEmail={createdByEmail}
                    createdTime={createdTime}
                    isDuplicateEnabled={isDuplicateEnabled}
                    isRenameEnabled={isRenameEnabled}
                    isShareEnabled={isShareEnabled}
                    isTrashAndRestoreEnabled={isTrashAndRestoreEnabled}
                    isRemoveFromChannelEnabled={isRemoveFromChannelEnabled}
                    isTrashed={isTrashed}
                    isFavorited={isFavorited}
                    onDuplicate={onDuplicate}
                    onFavorite={onFavorite}
                    onUnfavorite={onUnfavorite}
                    onTrash={onTrash}
                    onPermanentDelete={onPermanentDelete}
                    onRestore={onRestore}
                    onShareClick={onShareClick}
                    onRename={onRename}
                    onRemoveFromChannel={onRemoveFromChannel}
                  />
                )}
              </Flex>
            </Flex>
          </Flex>
          {Boolean(onFavorite) && Boolean(onUnfavorite) && (
            <Box position="absolute" top={3} right={3} zIndex={3}>
              <FavoriteButton
                isFavorited={isFavorited}
                onFavorite={onFavorite}
                onUnfavorite={onUnfavorite}
                opacity={isFavorited ? 1 : isMobileDevice() ? 1 : 0}
                // GroupHover is super janky on mobile, so we have to disable it
                _groupHover={!isMobileDevice() ? { opacity: 1 } : undefined}
                _focus={{
                  opacity: 1,
                  shadow: 'outline',
                }}
              />
            </Box>
          )}
        </Flex>
      </LinkBox>
    )
  }
)

DocumentGridItem.displayName = 'DocumentGridItem'
