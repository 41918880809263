import { Flex } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from '@lingui/macro'
import { memo } from 'react'

import { getAlignStyles } from '../../../HorizontalAlign/HorizontalAlign'
import { CELL_PADDING } from '../../constants'
import { HorizontalAlignOption } from '../../options/HorizontalAlignOption'
import {
  SmartLayoutCellComponent,
  SmartLayoutVariant,
  SmartLayoutWrapperComponent,
} from '../../types'
import { MAX_WIDTH, MIN_PCT_INSIDE } from './constants'
import { getWidthPct, Pyramid, PyramidCell, PyramidStepProps } from './Pyramid'
import { StackedPyramidWrapper, useShouldStackContent } from './StackedPyramid'

const StaircaseWrapper: SmartLayoutWrapperComponent = memo((props) => {
  const { children, options, containerSize } = props
  const alignStyles = getAlignStyles(options.horizontalAlign || 'left')

  const shouldStack = useShouldStackContent(containerSize)
  if (shouldStack) {
    return <StackedPyramidWrapper {...props} Step={StaircaseStep} />
  }

  return (
    <Flex direction="column" data-selection-ring gap="0.5em" sx={alignStyles}>
      {children}
    </Flex>
  )
})
StaircaseWrapper.displayName = 'StaircaseWrapper'

const StaircaseStep = memo(
  ({
    numCells,
    index,
    isOutside,
    isStacked,
    children,
    base,
    alignment,
    selectCell,
  }: PyramidStepProps) => {
    const alignSx = getAlignStyles(alignment)
    const stepSx = {
      ...alignSx,
      ...(base.smartLayoutBoxSx || base.boxSx),
    }
    const minWidthPct = isOutside ? 0 : MIN_PCT_INSIDE
    const maxWidthPct = isOutside && !isStacked ? 50 : 100
    const baseWidthPct = getWidthPct(numCells, index + 1, minWidthPct)
    const blockWidthPct = baseWidthPct * (maxWidthPct / 100)
    const marginWidthPct = (maxWidthPct - blockWidthPct) / 2

    const stepProps = isOutside
      ? {
          ml: alignment === 'center' ? `${marginWidthPct}%` : undefined,
        }
      : {
          maxW: `calc(${MAX_WIDTH} * ${blockWidthPct / 100})`,
        }

    return (
      <Flex
        py={isOutside ? '0.5em' : CELL_PADDING}
        px={CELL_PADDING}
        sx={stepSx}
        w={`${blockWidthPct}%`}
        boxSizing="border-box"
        {...stepProps}
        direction="column"
        align="center"
        justify="center"
        contentEditable={!isOutside}
        onClick={isOutside ? selectCell : undefined}
        pos="relative"
      >
        {children}
      </Flex>
    )
  }
)
StaircaseStep.displayName = 'StaircaseStep'

const StaircaseCell: SmartLayoutCellComponent = memo((props) => {
  const alignment = props.layoutOptions.horizontalAlign || 'left'

  return (
    <PyramidCell
      {...props}
      Step={StaircaseStep}
      isFunnel={false}
      isStepped={true}
      alignment={alignment}
    />
  )
})
StaircaseCell.displayName = 'StaircaseCell'

export const Staircase: SmartLayoutVariant = {
  ...Pyramid,
  key: 'staircase',
  name: () => t`Staircase`,
  commandName: () => t`Staircase layout (blank)`,
  options: [...Pyramid.options, HorizontalAlignOption],
  icon: regular('stairs'),
  Wrapper: StaircaseWrapper,
  Cell: StaircaseCell,
  htmlTag: 'stairs',
}
