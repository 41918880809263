import { mergeAttributes } from '@tiptap/core'
import ImageNode from '@tiptap/extension-image'

import { UploadStatus } from 'modules/media/types/ImageUpload'
import { ReactNodeViewRenderer } from 'modules/tiptap_editor/react'

import { ExtensionPriorityMap } from '../../constants'
import { attrsOrDecorationsChanged } from '../../updateFns'
import { SimpleImageView } from './SimpleImageView'

export type SimpleImageAttrs = {
  id: string
  src?: string | null
  tempUrl?: string | null
  uploadStatus?: UploadStatus | null
  source?: string
  showPlaceholder?: boolean
}

export const SimpleImage = ImageNode.extend({
  selectable: true,
  draggable: true,
  group: 'block media',
  priority: ExtensionPriorityMap.Image,

  addOptions() {
    return {
      ...this.parent?.(), // Extend parent options
      placeholders: {},
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(SimpleImageView, {
      update: attrsOrDecorationsChanged,
    })
  },

  parseHTML() {
    return [
      {
        tag: 'img[src], img[tempUrl]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'img',
      mergeAttributes(HTMLAttributes, { class: 'gamma-simple-image' }),
    ]
  },

  addAttributes() {
    return {
      src: {},
      tempUrl: {},
      uploadStatus: {},
      source: {},
      showPlaceholder: {},
    }
  },
})
