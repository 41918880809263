/**
 * This file handles two operations
 * getLoadImageAttrs - Creating the ImageAttrs `loadImageId` `loadImageParams` and `loadImageStatus` for AI Html
 * loadImageFromAttrs - Given the attributes created by getLoadImageAttrs, perform the image search / generate
 * and returns the new image attributes.
 */
import { generateAIInteractionId } from 'modules/ai/track'
import { getImageStore } from 'modules/ai/transform/StreamImageSearchStore'
import { GenerateImageOptions } from 'modules/media/apis/imageGenerate'
import { ImageSearchProvidersToMediaProviderKey } from 'modules/media/providers/ImageSearchProviders'
import {
  ImageAttrs,
  ImageLicense,
  LoadImageParams,
} from 'modules/media/types/Image'
import {
  ImageSearchFilterOptions,
  ImageSearchProviderKey,
} from 'modules/media/types/ImageSearch'
import { UploadStatus } from 'modules/media/types/ImageUpload'
import { imageResultToImageAttrs } from 'modules/media/utils/imageResultUtils'
import { generateMediaId } from 'modules/tiptap_editor/extensions/media/UniqueMediaId'

export const getLoadImageAttrs = ({
  query,
  provider,
  license,
}: {
  query: string
  provider: ImageSearchProviderKey
  license: ImageLicense | undefined
}): Pick<ImageAttrs, 'loadImageId' | 'loadImageParams' | 'loadImageStatus'> => {
  if (!query) {
    return {
      loadImageStatus: 'error',
    }
  }

  // If the AI suggests multiple queries with a comma, only use the first one for searching
  if (provider !== 'aiGenerated') {
    query = query.split(', ')[0]
  }

  const params: ImageAttrs['loadImageParams'] = {
    provider,
    query,
    options:
      provider === 'aiGenerated'
        ? {}
        : {
            license,
          },
  }

  return {
    // we dont return showPlaceholder here because AccessoryImages do not need showPlaceholder
    // the lack of an image src will show the placeholder
    loadImageParams: params,
    loadImageStatus: 'queued' as const,
    loadImageId: generateMediaId(),
  }
}

export const loadImageFromAttrs = async ({
  loadImageId,
  loadImageParams,
  context,
  markUsed = true,
}: {
  loadImageId: string
  loadImageParams: LoadImageParams
  context: {
    themeId?: string
    docId?: string
    workspaceId: string
    interactionId?: string
  }
  markUsed?: boolean
}): Promise<ImageAttrs> => {
  const { provider, query, options } = loadImageParams

  const { interactionId, ...rest } = context

  try {
    if (provider === 'aiGenerated') {
      const results = await getImageStore().generateImage(loadImageId, {
        ...(options as GenerateImageOptions),
        interactionId: interactionId || generateAIInteractionId(),
        ...rest,
        prompt: query,
        // NOTE: count should probably always be 1 unless we want to allow selecting image
        count: 1,
      })
      if (!results[0]) {
        return {
          loadImageStatus: 'error',
        }
      }

      return {
        ...results[0].attrs,
        tempUrl: null,
        loadImageStatus: 'done',
        uploadStatus: UploadStatus.Queued,
      }
    } else {
      const result = await getImageStore().findImage(
        {
          query,
          provider: loadImageParams.provider,
          options: loadImageParams.options as ImageSearchFilterOptions,
        },
        markUsed
      )
      if (!result) {
        return {
          // attach query + provider so that user can do followup search
          query,
          source: ImageSearchProvidersToMediaProviderKey[provider],
          loadImageStatus: 'error',
        }
      }

      const imageAttrs: ImageAttrs = {
        ...imageResultToImageAttrs(result, query, provider),
        loadImageStatus: 'done',
        uploadStatus: UploadStatus.Queued,
      }
      return imageAttrs
    }
  } catch (e) {
    console.error(
      `[AIStream] An error occurred while fetching image: ${e.message}`,
      e.stack
    )
    return {
      loadImageStatus: 'error',
    }
  }
}
